import { useEffect, useRef, useState } from "react";
import { IBppFormSecondSectionInterface } from "../../../interfaces/forms/BPPForm/bpp-form-second-section.interface";
import { IBPPFormThirdSectionInterface } from "../../../interfaces/forms/BPPForm/bpp-form-third-section.interface";
import { IBppFormFourthSectionInterface } from "../../../interfaces/forms/BPPForm/bpp-form-fourth-section.interface";
import { IBppFormFifthSectionInterface } from "../../../interfaces/forms/BPPForm/bpp-form-fifth-section.interface";
import { IBppFormSixthSectionInterface } from "../../../interfaces/forms/BPPForm/bpp-form-sixth-section.interface";
import { IBppFormThirteenthSectionInterface } from "../../../interfaces/forms/BPPForm/bpp-form-thirteenth-section.interface";
import { IBppFormSeventhSectionInterface } from "../../../interfaces/forms/BPPForm/bpp-form-seventh-section.interface";
import { IBppFormEighthSectionInterface } from "../../../interfaces/forms/BPPForm/bpp-form-eighth-section.interface";
import { IBppFormNinthSectionInterface } from "../../../interfaces/forms/BPPForm/bpp-form-ninth-section.interface";
import { IBppFormTenthSectionInterface } from "../../../interfaces/forms/BPPForm/bpp-form-tenth-section.interface";
import { IBppFormEleventhSectionInterface } from "../../../interfaces/forms/BPPForm/bpp-form-eleventh-section.interface";
import { IBppFormTwelfthSectionInterface } from "../../../interfaces/forms/BPPForm/bpp-form-twelfth-section.interface";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import shared from "../PDF-Shared-style.module.scss";
import classes from "./BPP-Form-PDF-style.module.scss";
import { IBppFormFifteenthSectionInterface } from "../../../interfaces/forms/BPPForm/bpp-form-fifteenth-section.interface";
import { IBppFormFourteenthSectionInterface } from "../../../interfaces/forms/BPPForm/bpp-form-fourteenth-section.interface";
import { settingsService } from "src/services/settings.service";
import { appendSupportingDocumentsToPdfHelper } from "src/context/SelectedForm/selected-form-context.helper";
import { useSelectedFormContext } from "src/context/SelectedForm/selected-form-context";
import { AxiosError } from "axios";
import { setLoading, temporaryError } from "src/context/shared/shared-context.helper";
import { GetCheckbox, GetFixedWidthCheckbox, GetYesNoCheckboxGroup } from "../PDFCheckbox";
import './FormPDFTemplate.css'
import { IBPPFormPersonalPropertySegmentsInterface } from "src/interfaces/forms/BPPForm/bpp-form-personal-property-segments.interface";
import { BppPdfAssetsTable } from "./BppPdfAssetsTable";
import { ScheduleETable1 } from "./Tables/ScheduleE/ScheduleETable1";
import { ScheduleETable2 } from "./Tables/ScheduleE/ScheduleETable2";
import { ScheduleA } from "./Tables/ScheduleA";
import { ScheduleB } from "./Tables/ScheduleB";
import { ScheduleC } from "./Tables/ScheduleC";
import { ScheduleD } from "./Tables/ScheduleD";
import { ScheduleF } from "./Tables/ScheduleF";
import { IFormFilesInterface } from "src/interfaces/forms/Shared/form-files.interface";

interface IFormFields {
  formDataFieldsSecondSection?: IBppFormSecondSectionInterface;
  formDataFieldsThirdSection?: IBPPFormThirdSectionInterface;
  formDataFieldsFourthSection?: IBppFormFourthSectionInterface;
  formDataFieldsFifthSection?: IBppFormFifthSectionInterface;
  formDataFieldsSixthSection?: IBppFormSixthSectionInterface;
  formDataFieldsSeventhSection?: IBppFormSeventhSectionInterface;
  formDataFieldsEighthSection?: IBppFormEighthSectionInterface;
  formDataFieldsNinthSection?: IBppFormNinthSectionInterface;
  formDataFieldsTenthSection?: IBppFormTenthSectionInterface;
  formDataFieldsEleventhSection?: IBppFormEleventhSectionInterface;
  formDataFieldsTwelfthSection?: IBppFormTwelfthSectionInterface;
  formDataFieldsThirteenthSection?: IBppFormThirteenthSectionInterface;
  formDataFieldsFourteenthSection?: IBppFormFourteenthSectionInterface;
  formDataFieldsFifteenSection?: IBppFormFifteenthSectionInterface;
  supportingDocuments?: IFormFilesInterface[];
  accessCode: string;
  assets?: IBPPFormPersonalPropertySegmentsInterface[],
  getPdfBase64Url?: (url: string) => void;
}

const template = (context: any) => {
  if (context.pageNum === 1) {
    return '';
  }
  context.element.style.paddingTop = '16px';
  return `
    <div class="form-pdf-template">
      <div class="form-pdf-header">
        <div class="form-pdf-header-text">
          Business Personal Property Rendition of Taxable Property
        </div>
        <div class="form-pdf-header-text">
          Form 50-144
        </div>
      </div>
      <div class="form-pdf-footer-container">
        <div class="form-pdf-footer">
          <hr class="form-pdf-footer-hr"/>
          <div class="form-pdf-footer-text">
            For additional copies, visit: <b>comptroller.texas.gov/taxes/property-tax</b>
          </div>
          <div class="form-pdf-footer-text">
            Page ${context.pageNum}
          </div>
        </div>
      </div>
    </div>
  `
}

export default function BPPFormPDFView(props: IFormFields): JSX.Element {
  const {
    formDataFieldsSecondSection,
    formDataFieldsThirdSection,
    formDataFieldsFourthSection,
    formDataFieldsFifthSection,
    formDataFieldsSixthSection,
    formDataFieldsSeventhSection,
    formDataFieldsEighthSection,
    formDataFieldsNinthSection,
    formDataFieldsTenthSection,
    formDataFieldsEleventhSection,
    formDataFieldsTwelfthSection,
    formDataFieldsThirteenthSection,
    formDataFieldsFifteenSection,
    supportingDocuments = [],
    accessCode,
    getPdfBase64Url,
    assets,
  } = props;

  const templateRef = useRef(null);
  const [templateBase64, setTemplateBase64] = useState("");
  const { selectedFormDispatch } = useSelectedFormContext();
  const settings = settingsService().settings;

  useEffect(() => {
    drawDOM(templateRef.current as any, {
      paperSize: "A4",
      keepTogether: '.keepTogether',
      template,
      margin: { bottom: '16px' },
      repeatHeaders: true,
    })
      .then((group) => {
        if (templateRef.current) {
          const myReference = templateRef.current as any;
          myReference.style.display = "none";
        }
        return exportPDF(group);
      })
      .then((dataUri) => {
        return appendSupportingDocumentsToPdfHelper(selectedFormDispatch, accessCode, dataUri, supportingDocuments)
        .then((dataUri) => {
          setTemplateBase64(dataUri);
          if (getPdfBase64Url) {
            getPdfBase64Url(dataUri);
          }
        })
        .catch((error: AxiosError) => {
                setLoading(selectedFormDispatch);
                temporaryError(selectedFormDispatch, error.response?.data)
            }
        );
      })

  }, []);

  return (
    <>
      {templateRef && templateBase64 && (
        <iframe
          style={{ width: "100%", height: "100%" }}
          src={templateBase64}
        />
      )}
      <div ref={templateRef} className={`${shared.FormContainer} ${shared.Form}`}>
        <div className={shared.PrintTogether}>
          <div className={`${shared.Row} ${shared.AlignCenter}`}>
            <div className={`${shared.Column10} ${shared.FormTitle}`}>
              Business Personal Property Rendition of Taxable Property
            </div>
            <div className={`${shared.Column2} ${shared.FormVersion} ${shared.Font10}`}>Form 50-144</div>
          </div>

          <div className={`${shared.Row} ${shared.SpaceBetween}`}>
            <div className={`text-danger font-weight-bold ${shared.Column7} ${shared.Font10}`}>
              C O N F I D E N T I A L
            </div>
            <div className={shared.Column4}>
              <div className={shared.Value}>
                {formDataFieldsSecondSection?.TaxYear}
              </div>
              <div className={shared.ValueLabelBelow}>Tax Year</div>
            </div>
          </div>

          <div className={shared.Row}>
            <div className={shared.Column8}>
              <div className={shared.Value}>{settings?.clientDisplayName}</div>
              <div className={shared.ValueLabelBelow}>
                Appraisal District&apos;s Name
              </div>
            </div>

            <div className={shared.Column4}>
              <div className={shared.Value}>
                {formDataFieldsSecondSection?.AccountNumber}
              </div>
              <div className={shared.ValueLabelBelow}>
                Appraisal District Account Number <i>(if known)</i>
              </div>
            </div>
          </div>

          <div className={shared.Row}>
            <div className={shared.Column12}>
              <hr className={shared.Hr}/>
            </div>
          </div>

          <div className={shared.Row}>
            <div className={shared.Column12}>
              <p>
                <b>GENERAL INFORMATION:</b> This form is to render tangible
                personal property used for the production of income that you own or
                manage and control as a fiduciary on Jan. 1 of this year (Tax Code
                Section 22.01).
                <br/>
                <b>FILING INSTRUCTIONS:</b> This document and all supporting
                documentation must be filed with the appraisal district office in
                the county in which the property is taxable.
                <br/>
                <b>
                  Do not file this document with the Texas Comptroller of Public
                  Accounts.
                </b>
              </p>
            </div>
          </div>

          <div className={shared.Step}>
            SECTION 1: Business and Situs Information (Required)
          </div>
          <div className={shared.Row}>
            <div className={shared.Column6}>
              <div className={shared.Value}>
                {formDataFieldsSecondSection?.BusinessName}
              </div>
              <div className={shared.ValueLabelBelow}>Business Name</div>
            </div>
            <div className={shared.Column6}>
              <div className={shared.Value}>
                {formDataFieldsSecondSection?.BusinessOwnerName}
              </div>
              <div className={shared.ValueLabelBelow}>Business Owner</div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              <div className={shared.Value}>
                {formDataFieldsSecondSection?.PropertyAddressLine1} &nbsp;
                {formDataFieldsSecondSection?.PropertyAddressLine2 && (
                  <span>
                    {formDataFieldsSecondSection?.PropertyAddressLine2}{" "}
                    &nbsp;
                  </span>
                )}
                {formDataFieldsSecondSection?.PropertyAddressCity} &nbsp;
                {formDataFieldsSecondSection?.PropertyAddressState}
                {formDataFieldsSecondSection?.PropertyAddressZip && ", "}
                {formDataFieldsSecondSection?.PropertyAddressZip} &nbsp;
              </div>
              <div className={shared.ValueLabelBelow}>
                Property Location Address, City, State, ZIP Code
              </div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column8}>
              <div className={shared.Value}>
                {formDataFieldsSecondSection?.BusinessEmail}
              </div>
              <div className={shared.ValueLabelBelow}>Email Address</div>
            </div>
            <div className={shared.Column4}>
              <div className={shared.Value}>
                {formDataFieldsSecondSection?.BusinessPhoneNumber}
              </div>
              <div className={shared.ValueLabelBelow}>
                Phone <i>(area code and number)</i>
              </div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column7}>
              <div>
                <span>Ownership Type <i>(Optional)</i>: &nbsp;&nbsp;</span>
                {GetCheckbox(formDataFieldsSecondSection?.individual, "Individual")}
                &nbsp;&nbsp;
                {GetCheckbox(formDataFieldsSecondSection?.corporation, "Corporation")}
                &nbsp;&nbsp;
                {GetCheckbox(formDataFieldsSecondSection?.partnership, "Partnership")}
                &nbsp;&nbsp;
                {GetCheckbox(formDataFieldsSecondSection?.other, "Other:")}
                &nbsp;
              </div>
            </div>
            <div className={`${shared.Value} ${shared.Column5}`}>
                {formDataFieldsSecondSection?.OwnershipTypeOther}
            </div>
          </div>

          <div className={shared.Step}>SECTION 2: Representation</div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              <div>
                Please indicate if you are filling out this form as
              </div>
              <div>
                {GetCheckbox(formDataFieldsThirdSection?.FilingAs?.
                ["Owner, employee, or employee of an affiliated entity of the owner"],
                  "Owner, employee, or employee of an affiliated entity of the owner")}
                &nbsp;&nbsp;
                {GetCheckbox(formDataFieldsThirdSection?.FilingAs?.["Authorized Agent"], "Authorized Agent")}
                &nbsp;&nbsp;
                {GetCheckbox(formDataFieldsThirdSection?.FilingAs?.["Fiduciary"], "Fiduciary")}
                &nbsp;&nbsp;
                {GetCheckbox(formDataFieldsThirdSection?.FilingAs?.["Secured Party"], "Secured Party")}
              </div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              <div className={shared.Value}>
                {formDataFieldsThirdSection?.RepresentationFirstName}
                {formDataFieldsThirdSection?.RepresentationLastName}
              </div>
              <div className={shared.ValueLabelBelow}>
                Name of Owner, Authorized Agent, Fiduciary or Secured Party
              </div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column8}>
              <div className={shared.Value}>
                {formDataFieldsThirdSection?.RepresentationAddressLine1}{" "} &nbsp;
                {formDataFieldsThirdSection?.RepresentationAddressLine2 && (
                  <span>
                    {formDataFieldsThirdSection?.RepresentationAddressLine2}{" "} &nbsp;
                  </span>
                )}
                {formDataFieldsThirdSection?.RepresentationAddressCity}{" "} &nbsp;
                {formDataFieldsThirdSection?.RepresentationAddressState}
                {formDataFieldsSecondSection?.PropertyAddressZip && ", "}
                {formDataFieldsThirdSection?.RepresentationAddressZip}
              </div>
              <div className={shared.ValueLabelBelow}>
                Mailing Address, City, State, ZIP Code
              </div>
            </div>
            <div className={shared.Column4}>
              <div className={shared.Value}>
                {formDataFieldsThirdSection?.RepresentationPhoneNumber}
              </div>
              <div className={shared.ValueLabelBelow}>
                Phone <i>(area code and number)</i>
              </div>
            </div>
          </div>
          <div className={`${shared.Row} ${shared.PrintTogether}`}>
            <div className={shared.Column9}>
              Are you a secured party with a security interest in the property
              subject to this rendition and with a historical cost new of more
              than $50,000 as defined by Tax Code Section 22.01 (c-1) and (c-2)?
            </div>
            <div className={`${shared.Column2} ${shared.TextRight}`}>
              {GetYesNoCheckboxGroup(formDataFieldsThirdSection?.IsSecuredParty)}
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              If yes, attach a document signed by the property owner indicating
              consent for you to file the rendition. Without the authorization,
              the rendition is not valid and cannot be processed.
            </div>
          </div>

          <div className={shared.Step}>
            SECTION 3: Affirmation of Prior Year Rendition (Check only if
            applicable and your assets were exactly the same as last year&apos;s
            rendition form.)
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              <div>
                <span>
                  {GetFixedWidthCheckbox(formDataFieldsFourthSection?.AssetsWereSameAsPreviousYear)}
                </span>
                <span>
                  By checking this box, I affirm that the information contained in
                  the most recent rendition statement filed for a prior tax year (the &ensp;
                </span>
                <span className={`${shared.Value} ${shared.Width10} ${shared.InlineBlock}`}>
                  {/* {formDataFieldsFourthSection?.PriorTaxYear} */}
                </span>
                <span>
                  &ensp;tax year) continues to be complete and accurate for the current tax year.
                </span>
              </div>
            </div>
          </div>

          <div className={shared.Step}>
            SECTION 4: Business Information (Optional)
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              <div>Please address all that apply:</div>
              <div>
                <span>Business Type: &nbsp;&nbsp;&nbsp;</span>
                {GetCheckbox(formDataFieldsFifthSection?.BusinessType?.Manufacturing, "Manufacturing")}
                &nbsp;&nbsp;
                {GetCheckbox(formDataFieldsFifthSection?.BusinessType?.Wholesale, "Wholesale")}
                &nbsp;&nbsp;
                {GetCheckbox(formDataFieldsFifthSection?.BusinessType?.Retail, "Retail")}
                &nbsp;&nbsp;
                {GetCheckbox(formDataFieldsFifthSection?.BusinessType?.Service, "Service")}
                &nbsp;&nbsp;
                {GetCheckbox(formDataFieldsFifthSection?.BusinessType?.NewBusiness, "New Business")}
              </div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column8}>
              <div className={shared.Value}>
                {formDataFieldsFifthSection?.BusinessDescription}
              </div>
              <div className={shared.ValueLabelBelow}>Business Description</div>
            </div>
            <div className={shared.Column4}>
              <div className={shared.Value}>
                {formDataFieldsFifthSection?.SquareFeetOccupied}
              </div>
              <div className={shared.ValueLabelBelow}>Square Feet Occupied</div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={`${shared.ChildRow} ${shared.Column8}`}>
              <div className={shared.Column6}>
                <div className={shared.Value}>
                  {formDataFieldsFifthSection?.BusinessSoldDate}
                </div>
                <div className={shared.ValueLabelBelow}>Business Sold Date</div>
              </div>
              <div className={shared.Column6}>
                <div className={shared.Value}>
                  {formDataFieldsFifthSection?.BusinessStartDateAtLocation}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Business Start Date at Location
                </div>
              </div>
            </div>
            <div className={shared.Column4}>
              <div className={shared.Value}>
                {formDataFieldsFifthSection?.SalesTaxPermitNumber}
              </div>
              <div className={shared.ValueLabelBelow}>Sales Tax Permit Number</div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column8}>
              <div className={shared.Value}>
                {formDataFieldsFifthSection?.NewOwner}
              </div>
              <div className={shared.ValueLabelBelow}>New Owner</div>
            </div>
            <div className={shared.Column4}>
              <div className={shared.Value}>
                {formDataFieldsFifthSection?.BusinessMovedDate}
              </div>
              <div className={shared.ValueLabelBelow}>Business Moved Date</div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={`${shared.ChildRow} ${shared.Column8}`}>
              <div className={shared.Column6}>
                <div className={shared.Value}>
                  {formDataFieldsFifthSection?.NewLocationAddressLine1}{" "}
                  &nbsp;
                  {formDataFieldsFifthSection?.NewLocationAddressLine2 && (
                    <span>
                      {formDataFieldsFifthSection?.NewLocationAddressLine2}{" "}&nbsp;
                    </span>
                  )}
                </div>
                <div className={shared.ValueLabelBelow}>New Location</div>
              </div>
              <div className={shared.Column6}>
                <div className={shared.Value}>
                  {formDataFieldsFifthSection?.NewLocationAddressCity}{" "}
                  &nbsp;
                  {formDataFieldsFifthSection?.NewLocationAddressState},
                  &nbsp;
                  {formDataFieldsFifthSection?.NewLocationAddressZip}{" "}
                  &nbsp;
                </div>
                <div className={shared.ValueLabelBelow}>City, State, Zip Code</div>
              </div>
            </div>
            <div className={shared.Column4}>
              <div className={shared.Value}>
                {formDataFieldsFifthSection?.BusinessClosedDate}
              </div>
              <div className={shared.ValueLabelBelow}>Business Closed Date</div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column6}>
              <div>
                <span>
                  Did assets remain in place as of Jan. 1?
                </span>
                &emsp;
                <span>
                  {GetYesNoCheckboxGroup(formDataFieldsFifthSection?.AssetsRemainedInPlace)}
                </span>
              </div>
            </div>
          </div>

          <div className={shared.Step}>SECTION 5: Market Value</div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              <div>
                <span>
                  Check the total market value of your property &nbsp;&nbsp;
                </span>
                {GetCheckbox(formDataFieldsSixthSection?.MarketValue == "Under20K", "Under $20,000")}
                &nbsp;&nbsp;
                {GetCheckbox(formDataFieldsSixthSection?.MarketValue == "Over20K", "$20,000 or more")}
              </div>
              <div>
                  If Under $20,000, please complete only Schedule A and if applicable, Schedule F.
                  Otherwise, complete Schedule(s) B, C, D, E and/or F, as applicable.
              </div>
            </div>
          </div>

          <div className={shared.Step}>SECTION 6: Affirmation and Signature</div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              <b>
                If you make a false statement on this form, you could be found guilty of a
                Class A misdemeanor or a state jail felony under Penal Code Section 37.10.
              </b>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              <div className={shared.PrintedName}>
                I, {" "}
                <div className={shared.Width80}>
                  {" "}
                  <div className={shared.Value}>
                    {formDataFieldsFifteenSection?.Signature}
                  </div>
                  <div className={`${shared.ValueLabelBelow} ${shared.TextCenter}`}>
                    Printed Name of Authorized Individual
                  </div>
                </div>
                , swear or affirm that the information provided in this report is true and accurate to the best of my
                knowledge and belief; and that I am authorized as required by law to file and sign this report.
              </div>
              <div>
                <b>NOTE:</b> If the person filing and signing this report is not the property owner, an employee of
                the property owner, an employee of a property owner signing on behalf of an affiliated entity of the
                property owner or a secured party as defined by Tax Code Section 22.01, the signature below must be notarized.
              </div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column8}>
              <div className={shared.Value}>
                {formDataFieldsFifteenSection?.Signature}
              </div>
              <div className={shared.ValueLabelBelow}>Signature of Authorized Individual</div>
            </div>
            <div className={shared.Column4}>
              <div className={shared.Value}>
                {formDataFieldsFifteenSection?.SignatureDate
                  ? new Date(
                      formDataFieldsFifteenSection?.SignatureDate
                    ).toDateString()
                  : formDataFieldsFifteenSection?.SignatureDate}
              </div>
              <div className={shared.ValueLabelBelow}>Date</div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column4}></div>
            <div className={`${shared.Column8} ${shared.TextRight}`}>
              <div>
                <span>Subscribed and sworn before me this &nbsp;</span>
                <span className={`${shared.Value} ${shared.Width10} ${shared.InlineBlock}`} />
                <span>&nbsp;day of&nbsp;</span>
                <span className={`${shared.Value} ${shared.Width30} ${shared.InlineBlock}`} />
                <span>&nbsp;,&nbsp;</span>
                <span className={`${shared.Value} ${shared.Width15} ${shared.InlineBlock}`} />
                <span>.</span>
              </div>
            </div>
          </div>
          <br/>
          <div className={shared.Row}>
            <div className={shared.Column4}/>
            <div className={shared.Column8}>
              <div className={shared.Value}>
                {formDataFieldsFifteenSection?.Signature}
              </div>
              <div className={shared.ValueLabelBelow}>Notary Public, State of Texas</div>
            </div>
          </div>
          <div className={shared.PageFooter}>
            <div className={shared.Row}>
              <div className={shared.Column12}>
                <hr className={shared.Hr}/>
              </div>
            </div>
            <div className={shared.Row}>
              <div className={`${shared.Column6} ${shared.TextLeft} ${shared.Footer}`}>
                Form developed by: Texas Comptroller of Public Accounts, Property
                Tax Assistance Division
              </div>
              <div className={`${shared.Column6} ${shared.TextRight} ${shared.Footer}`}>
                For additional copies, visit:{" "}
                <b>comptroller.texas.gov/taxes/property-tax</b>
              </div>
            </div>
            <div className={shared.Row}>
              <div className={`${shared.Column12} ${shared.TextRight}`}>
                50-144 · 10-22/26
              </div>
            </div>
          </div>
        </div>
        <div className={shared.PageBreak}></div>
        <div className={`keepTogether ${classes.ScheduleSection} ${shared.PrintTogether}`}>
          <div className={shared.Row}>
            <div className={shared.Column6}>
              Did you timely apply for a Sept. 1 inventory date? <i>(Optional)</i>
            </div>
            <div className={`${shared.Column2} ${shared.TextRight}`}>
              {GetYesNoCheckboxGroup(formDataFieldsThirteenthSection?.TimelyAppliedForSep1InventoryDate)}
            </div>
            <div className={shared.Column3}></div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column6}>
              Does your inventory involve interstate/foreign commerce issues? <i>(Optional)</i>
            </div>
            <div className={`${shared.Column2} ${shared.TextRight}`}>
              {GetYesNoCheckboxGroup(formDataFieldsThirteenthSection?.InventoryInvolvedInterstateCommerceIssues)}
            </div>
            <div className={shared.Column3}></div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column6}>
              Does your inventory involve freeport goods? <i>(Optional)</i>
            </div>
            <div className={`${shared.Column2} ${shared.TextRight}`}>
              {GetYesNoCheckboxGroup(formDataFieldsThirteenthSection?.InventoryInvolvedFreeportGoods)}
            </div>
            <div className={shared.Column3}></div>
          </div>
        </div>

        <ScheduleA formDataFieldsSeventhSection={formDataFieldsSeventhSection} />

        <br />

        <ScheduleB formDataFieldsEighthSection={formDataFieldsEighthSection} />

        <br />

        <ScheduleC formDataFieldsNinthSection={formDataFieldsNinthSection} />

        <br />

        <ScheduleD formDataFieldsTenthSection={formDataFieldsTenthSection} />

        <br />

        <ScheduleETable1 formDataFieldsEleventhSection={formDataFieldsEleventhSection} />

        <ScheduleETable2 formDataFieldsEleventhSection={formDataFieldsEleventhSection} />

        <ScheduleF formDataFieldsTwelfthSection={formDataFieldsTwelfthSection} />

        <div className={shared.PageBreak}></div>

        {assets && (
          <>
            <BppPdfAssetsTable assets={assets} />
            <div className={shared.PageBreak}></div>
          </>
        )}

        <div className={shared.PrintTogether}>
          <div className={shared.Row}>
            <table className={`${shared.Column12} ${classes.ImportantInformationTable} ${shared.PrintTogether}`}>
              <tr>
                <th className={shared.Width50}>
                  <div className={`${shared.FormTitle} ${shared.TextCenter}`}>Important Information</div>
                </th>
                <th className={shared.Width50}>
                  <div className={`${shared.FormTitle} ${shared.TextCenter}`}>Definitions</div>
                </th>
              </tr>
              <tr>
                <td className={`${shared.Width50} ${classes.LeftPart}`}>
                  <p>
                    <b>
                      GENERAL INFORMATION: &nbsp;
                    </b>
                    This form is for use in rendering, pursuant to Tax Code
                    Section 22.01, tangible personal property used for the production of income that
                    you own or manage and control as a fiduciary on Jan. 1 of this year. This report is
                    confidential and not open to public inspection; disclosure is permitted pursuant to
                    the terms of Tax Code Section 22.27.
                  </p>
                  <p>
                    <b>
                      FILING INSTRUCTIONS: &nbsp;
                    </b>
                    This document and all supporting documentation must be
                    filed with the appraisal district office in the county in which the property is taxable.
                    <b>Do not file this document with the Texas Comptroller of Public Accounts.</b> Contact
                    information for appraisal district offices may be found on the Comptroller&apos;s website.
                  </p>
                  <p>
                    <b>
                      DEADLINES: &nbsp;
                    </b>
                    Rendition statements and property report deadlines depend on property
                    type. The statements and reports must be delivered to the chief appraiser after Jan. 1
                    and no later than the deadline indicated below. On written request by the property
                    owner, the chief appraiser shall extend a deadline for filing a rendition statement or
                    property report to May 15. The chief appraiser may further extend the deadline an
                    additional 15 days upon good cause shown in writing by the property owner.
                  </p>
                  <table className={`${shared.Blue} ${classes.DeadlinesTable}`}>
                    <tr>
                      <th className={`${classes.BlueHeader} ${shared.Width50}`}>Rendition Statements and Reports</th>
                      <th className={`${classes.BlueHeader} ${shared.Width20}`}>Deadlines</th>
                      <th className={`${classes.BlueHeader} ${shared.Width30}`}>Allowed Extension(s)</th>
                    </tr>
                    <tr>
                      <td>Property generally</td>
                      <td><b>April 15</b></td>
                      <td>
                        <ul>
                          <li>
                            May 15 upon written request
                          </li>
                          <li>
                            Additional 15 days for good cause shown
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Property regulated by the Public Utility
                        Commission of Texas, the Railroad Commission
                        of Texas, the federal Surface Transportation
                        Board or the Federal Energy Regulatory
                        Commission. Tax Code Section 22.23(d).
                      </td>
                      <td><b>April 30</b></td>
                      <td>
                        <ul>
                          <li>
                            May 15 upon written request
                          </li>
                          <li>
                            Additional 15 days for good cause shown
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </table>
                  <p>
                    <b>
                      EXEMPTION: &nbsp;
                    </b>
                    A person is entitled to an exemption from taxation of the tangible personal property that
                    is held or used for the production of income if it has less than $2,500
                    of taxable value (Tax Code Section 11.145).
                    <br/>
                    If an exemption is denied or terminated on a property, the owner must render it for
                    taxation within 30 days from the denial or termination. (Tax Code sections 21.01(a)
                    and 22.02).
                  </p>
                  <p>
                    <b>
                      PENALTIES: &nbsp;
                    </b>
                    The chief appraiser must impose a penalty on a person who fails to
                    timely file a required rendition statement or property report in an amount equal to
                    10 percent of the total amount of taxes imposed on the property for that year by
                    taxing units participating in the appraisal district. The chief appraiser must impose
                    an additional penalty on the person equal to 50 percent of the total amount of
                    taxes imposed on the property for the tax year of the statement or report by the
                    taxing units participating in the appraisal district if it is finally determined by a court
                    that:
                    <ol className={shared.OrderedList}>
                      <li>
                        the person filed a false statement or report with the intent to commit fraud
                        or to evade the tax; or
                      </li>
                      <li>
                        the person alters, destroys or conceals any record, document or thing, or
                        presents to the chief appraiser any altered or fraudulent record, document
                        or thing, or otherwise engages in fraudulent conduct, for the purpose
                        of affecting the course or outcome of an inspection, investigation,
                        determination or other proceeding before the appraisal district.
                      </li>
                    </ol>
                  </p>
                </td>
                <td className={`${shared.Width50} ${classes.RightPart}`}>
                  <p>
                    <b>
                      Address Where Taxable: &nbsp;
                    </b>
                    In some instances, personal property that is only
                    temporarily at its current address may be taxable at another location (taxable situs).
                    If you know that this is the case, please list the address where taxable.
                  </p>
                  <p>
                    <b>
                    Consigned Goods: &nbsp;
                    </b>
                    Personal property owned by another person that you are selling
                    by arrangement with that person. If you have consigned goods, report the name
                    and address of the owner in the appropriate blank.
                  </p>
                  <p>
                    <b>
                    Estimate of Quantity: &nbsp;
                    </b>
                    For each type or category listed, the number of items or
                    other relevant measure of quantity (e.g., gallons, bushels, tons, pounds, board feet).
                  </p>
                  <p>
                    <b>
                    Fiduciary: &nbsp;
                    </b>
                    A person or institution who manages property for another and who
                    must exercise a standard of care in such management activity imposed by law or
                    contract.
                  </p>
                  <p>
                    <b>
                    Good Faith Estimate of Market Value: &nbsp;
                    </b>
                    Your best estimate of what the property
                    would have sold for in U.S. dollars on Jan. 1 of the current tax year if it had been on
                    the market for a reasonable length of time and neither you nor the purchaser was
                    forced to buy or sell. For inventory, it is the price for which the property would have
                    sold as a unit to a purchaser who would continue the business.
                  </p>
                  <p>
                    <b>
                    Historical Cost When New: &nbsp;
                    </b>
                    What you paid for the property when it was new or, if
                    you bought the property used, what the original buyer paid when it was new. If you
                    bought the property used and do not know what the original buyer paid, state what
                    you paid with a note that you purchased it used.
                  </p>
                  <p>
                    <b>
                    Inventory: &nbsp;
                    </b>
                    Personal property that is held for sale in the ordinary course of a trade
                    or business.
                  </p>
                  <p>
                    <b>
                    Personal Property: &nbsp;
                    </b>
                    Every kind of property that is not real property; generally,
                    property that is movable without damage to itself or the associated real property.
                  </p>
                  <p>
                    <b>
                    Property Address: &nbsp;
                    </b>
                    The physical address of the personal property on Jan. 1 of the
                    current tax year. Normally, the property is taxable by the taxing unit where the
                    property is located.
                  </p>
                  <p>
                    <b>
                    Secured Party: &nbsp;
                    </b>
                    A person in whose favor a security interest is created or provided
                    for under a security agreement; see Business and Commerce Code Section 9.102 for
                    further details.
                  </p>
                  <p>
                    <b>
                    Security Interest: &nbsp;
                    </b>
                    An interest in personal property or fixtures which secured
                    payment or performance of an obligation see Business and Commerce Code Section
                    1.201 for further details.
                  </p>
                  <p>
                    <b>
                    Type/Category: &nbsp;
                    </b>
                    Functionally similar personal property groups. Examples are:
                    furniture, fixtures, machinery, equipment, vehicles and supplies. Narrower
                    groupings such as personal computers, milling equipment, freezer cases and
                    forklifts should be used, if possible. A person is not required to render for taxation
                    personal property appraised under Tax Code Section 23.24.
                  </p>
                  <p>
                    <b>
                    Year Acquired: &nbsp;
                    </b>
                    The year that you purchased the property, or otherwise acquired.
                  </p>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div className={shared.PageBreak}></div>
      </div>
    </>
  );
}
