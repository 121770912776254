import {
    CalendarChangeEvent,
    DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import {
    Checkbox,
    CheckboxChangeEvent,
    Input,
    InputChangeEvent,
    TextAreaChangeEvent
} from "@progress/kendo-react-inputs";
import {Label, Error} from "@progress/kendo-react-labels";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import classes from "./BPPForm.module.scss";
import { useEffect, useRef, useState} from "react";
import {DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import {EmailInput} from "../CustomInputs/EmailInputs/EmailInput";
import {PhoneNumberInput} from "../CustomInputs/PhoneNumberInputs/PhoneNumberInput";
import {FormValidation} from "../InputValidator";
import {CustomDatePicker} from "../CustomInputs/CustomDatePickers/CustomDatePicker";
import {ZipCodeInput} from "../CustomInputs/ZipCodeInput";
import {StatesInput} from "../CustomInputs/StatesInput";
import { CustomOnlyYearCalendarHelper } from "src/pages/Shared/DatePickers/CustomCalendars";
import SectionTitle from "../Shared/SectionTitle";
import { settingsService } from "src/services/settings.service";
import {
    IBppFormSecondSectionInterface
} from "../../../../../interfaces/forms/BPPForm/bpp-form-second-section.interface";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IBppFormSecondSectionInterface) => void;
    formDataFields?:  IBppFormSecondSectionInterface,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void,
    allSectionTouched: boolean;
}

export default function BPPFormSecondSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, isAdminPreview, setFormIsValid } = props;
    const settings = settingsService().settings;

    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={2} name={"Owner Information"}/>;
    const formRef = useRef<HTMLFormElement>(null);

    function getDateValue() {
        if (!formDataFields?.TaxYear) {
            let year = formSettings?.renditionYear?.toString() ?? settings.onlineFormsYear;
            setFormDataFields({
                ...formDataFields,
                TaxYear: year
            })
            return new Date(new Date().setFullYear(+year,1,1));
        }
        return new Date(new Date().setFullYear(parseInt(formDataFields?.TaxYear),1,1));
    }

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | CalendarChangeEvent,
        field: string
    )
    {
        if (field == "other" && event.value == false){
            setFormDataFields({
                ...formDataFields,
                [field]: event.value,
                OwnershipTypeOther: ""
            });
        } else if (field == "IsNewProperty") {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value,
                AccountNumber: ""
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    const saveAsPropertyAddressHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        setFormDataFields({
            ...formDataFields,
            BusinessAddressLine1: formDataFields?.PropertyAddressLine1,
            BusinessAddressLine2: formDataFields?.PropertyAddressLine2,
            BusinessAddressCity : formDataFields?.PropertyAddressCity,
            BusinessAddressState: formDataFields?.PropertyAddressState,
            BusinessAddressZip: formDataFields?.PropertyAddressZip,
        });
    }

    const saveAsBusinessAddressHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        setFormDataFields({
            ...formDataFields,
            PropertyAddressLine1: formDataFields?.BusinessAddressLine1,
            PropertyAddressLine2: formDataFields?.BusinessAddressLine2,
            PropertyAddressCity : formDataFields?.BusinessAddressCity,
            PropertyAddressState: formDataFields?.BusinessAddressState,
            PropertyAddressZip: formDataFields?.BusinessAddressZip,
        });
    }
    const { CustomOnlyYearCalendar } = CustomOnlyYearCalendarHelper();

    function changeValidator() {
        if(formRef.current) {
            const valid =
                formRef.current.checkValidity() &&
                emailInputValid &&
                phoneNumberInputValid &&
                businessZipCode &&
                propertyZipCode &&
                taxYearInputValid &&
                businessStateInputValid &&
                propertyStateInputValid;

            setIsValid(valid);
            setFormIsValid(valid);
        }
    }

    function onTouch(){
        if(!isFormTouched){
            setIsFormTouched(true);
        }
    }

    const [emailInputValid, setEmailInputValid] = useState<boolean>(false);
    const [phoneNumberInputValid, setPhoneNumberInputValid] = useState<boolean>(false);
    const [businessZipCode, setBusinessZipCode] = useState<boolean>(false);
    const [propertyZipCode, setPropertyZipCode] = useState<boolean>(false);
    const [taxYearInputValid, setTaxYearInputValid] = useState<boolean>(false);
    const [businessStateInputValid, setBusinessStateInputValid] = useState<boolean>(false);
    const [propertyStateInputValid, setPropertyStateInputValid] = useState<boolean>(false);
    const { validationMessage, inputValidator, setStyles } = FormValidation(formRef);


    useEffect(() => {
        changeValidator();
    }, [
        formDataFields,
        formRef.current,
        phoneNumberInputValid,
        emailInputValid,
        propertyZipCode,
        businessZipCode,
        taxYearInputValid,
        businessStateInputValid,
        propertyStateInputValid]);

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem className={classes.accordion} title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                <p>General Information</p>
                            </div>
                            <div className={`${classes.itemFields} ${classes.itemFieldsLeft}`}>
                                <div className={`${classes.fieldsGroup} ${classes.fieldsGroupLeft}`}>
                                    <Label className={classes.Label}>
                                        Tax Year
                                        <i className="fa fa-asterisk text-danger"></i>
                                    </Label>
                                    <div className="inputsGroup">
                                        <CustomDatePicker
                                            fieldName={"TaxYear"}
                                            required={true}
                                            disabled={true}
                                            formDataFields={formDataFields}
                                            setFormDataFields={setFormDataFields}
                                            onlyYear={true}
                                            CustomCalendar={CustomOnlyYearCalendar}
                                            setIsValidField={setTaxYearInputValid}
                                            validField={taxYearInputValid}
                                            defaultValueDate={getDateValue()}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroup}>
                                    <Label className={classes.Label}>
                                        Ownership Type (Optional)
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            name={"individual"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.individual}
                                            label={"Individual"}
                                            onChange={(e)=> changeValueHandler(e, "individual")}
                                        />
                                        <Checkbox
                                            name={"corporation"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.corporation}
                                            label={"Corporation"}
                                            onChange={(e)=> changeValueHandler(e, "corporation")}

                                        />
                                        <Checkbox
                                            name={"partnership"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.partnership}
                                            label={"Partnership"}
                                            onChange={(e)=> changeValueHandler(e, "partnership")}
                                        />
                                        <Checkbox
                                            name={"other"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.other}
                                            label={"Other"}
                                            onChange={(e)=> changeValueHandler(e, "other")}
                                        />
                                        {formDataFields?.other &&
                                            <div className="inputsGroup">
                                                <Label className={classes.Label}>
                                                    Ownership Type - Other
                                                    <i className="fa fa-asterisk text-danger"></i>
                                                </Label>
                                                <Input
                                                    className={setStyles("OwnershipTypeOther")}
                                                    name={"OwnershipTypeOther"}
                                                    required={formDataFields?.other}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.OwnershipTypeOther}
                                                    onChange={(e)=> changeValueHandler(e, "OwnershipTypeOther")}
                                                />
                                                {!inputValidator("OwnershipTypeOther") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div> }
                                    </div>
                                </div>
                                <div className={`${classes.fieldsGroup} ${classes.fieldsGroupLeft}`}>
                                    <Label className={classes.Label}>
                                        Account Number (If you do not know your Property ID, please put N/A) 
                                        {!formDataFields?.IsNewProperty && <i className="fa fa-asterisk text-danger"></i>}
                                    </Label>
                                    <div className="inputsGroup">
                                        { !formDataFields?.IsNewProperty &&
                                             <>
                                                <Input
                                                    className={setStyles("AccountNumber")}
                                                    required={!formDataFields?.IsNewProperty}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.AccountNumber}
                                                    name={"AccountNumber"}
                                                    onChange={(e)=> {
                                                        changeValueHandler(e, "AccountNumber")}}
                                                />
                                                {!inputValidator("AccountNumber") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                             </>
                                        }
                                        <Checkbox
                                            className={classes.kTextbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.IsNewProperty}
                                            label={"Is this new property?"}
                                            onChange={(e)=> changeValueHandler(e, "IsNewProperty")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                <p>Business Information</p>
                            </div>
                            <div className={classes.itemFields}>
                                <div className={`${classes.fieldsGroup} ${classes.fieldsGroupW49}`}>
                                    <Label className={classes.Label}>
                                        Business Name
                                        <i className="fa fa-asterisk text-danger"></i>
                                    </Label>
                                    <div className="inputsGroup">
                                        <Input
                                            className={setStyles("BusinessName")}
                                            name={"BusinessName"}
                                            required={true}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.BusinessName}
                                            onChange={(e)=> changeValueHandler(e, "BusinessName")}
                                        />
                                        {!inputValidator("BusinessName") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                </div>
                                <div className={`${classes.fieldsGroup} ${classes.fieldsGroupW49}`}>
                                    <Label className={classes.Label}>
                                        Business Owner
                                        <i className="fa fa-asterisk text-danger"></i>
                                    </Label>
                                    <div className="inputsGroup">
                                        <Input
                                            className={setStyles("BusinessOwnerName")}
                                            name={"BusinessOwnerName"}
                                            required={true}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.BusinessOwnerName}
                                            onChange={(e)=> changeValueHandler(e, "BusinessOwnerName")}
                                        />
                                        {!inputValidator("BusinessOwnerName") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                </div>
                                <div className={`${classes.fieldsGroup} ${classes.fieldsGroupW49}`}>
                                    <Label className={classes.Label}>
                                        Business Email
                                        {settings.emailAddressRequired && (
                                          <i className="fa fa-asterisk text-danger"></i>
                                        )}
                                    </Label>
                                    <div className={`${classes.fieldsGroup} `}>
                                        <EmailInput
                                            required={settings.emailAddressRequired}
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"BusinessEmail"}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                            setIsValidField={setEmailInputValid}
                                            validField={emailInputValid}
                                        />
                                        {!inputValidator("BusinessEmail") && !formDataFields?.["BusinessEmail"] && (
                                          <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                </div>
                                <div className={`${classes.fieldsGroup} ${classes.fieldsGroupW49}`}>
                                    <Label className={classes.Label}>
                                        Phone Number
                                    </Label>
                                    <div className="inputsGroup">
                                        <PhoneNumberInput
                                            required={false}
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"Business-Phone-Number"}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                            setIsValidField={setPhoneNumberInputValid}
                                            validField={phoneNumberInputValid}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.addressSection}>
                            <div className={classes.item}>
                                <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                    <p>Business Mailing Address</p>
                                    { (!isAdminPreview && !formSettings?.isReadOnly) &&
                                        <button className={`${classes.button} ${classes.buttonAddress}`} onClick={e =>saveAsPropertyAddressHandler(e)}>
                                            Same as Property Address
                                        </button> }
                                </div>
                                <div className={classes.itemFields}>
                                    <div className={`${classes.fieldsGroup} w-100`}>
                                        <Label className={classes.Label}>
                                            Mailing Address Line 1
                                            <i className="fa fa-asterisk text-danger"></i>
                                        </Label>
                                        <div className="inputsGroup">
                                            <Input
                                                className={setStyles("BusinessAddressLine1")}
                                                name={"BusinessAddressLine1"}
                                                required={true}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.BusinessAddressLine1}
                                                onChange={(e)=> changeValueHandler(e, "BusinessAddressLine1")}
                                            />
                                            {!inputValidator("BusinessAddressLine1") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.itemFields}>
                                    <div className={`${classes.fieldsGroup} w-100`}>
                                        <Label className={classes.Label}>
                                            Mailing Address Line 2
                                        </Label>
                                        <div className="inputsGroup">
                                            <Input
                                                className={classes.kTextbox}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.["BusinessAddressLine2"]}
                                                onChange={(e)=> changeValueHandler(e, "BusinessAddressLine2")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.itemFields}>
                                    <div className={`${classes.fieldsGroupW30}`}>
                                        <Label className={classes.Label}>
                                            City
                                            <i className="fa fa-asterisk text-danger"></i>
                                        </Label>
                                        <div className="inputsGroup">
                                            <Input
                                                className={setStyles("BusinessAddressCity")}
                                                name={"BusinessAddressCity"}
                                                required={true}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.["BusinessAddressCity"]}
                                                onChange={(e)=> changeValueHandler(e, "BusinessAddressCity")}
                                            />
                                            {!inputValidator("BusinessAddressCity") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`${classes.fieldsGroupW30}`}>
                                        <Label className={classes.Label}>
                                            State
                                            <i className="fa fa-asterisk text-danger"></i>
                                        </Label>
                                        <div className="inputsGroup">
                                            <StatesInput
                                                setFormDataFields={setFormDataFields}
                                                fieldName={"BusinessAddressState"}
                                                required={true}
                                                validField={businessStateInputValid}
                                                setIsValidField={setBusinessStateInputValid}
                                                formDataFields={formDataFields}
                                                isReadOnly={formSettings?.isReadOnly}
                                            />
                                        </div>
                                    </div>
                                    <div className={`${classes.fieldsGroupW30}`}>
                                        <Label className={classes.Label}>
                                            Zip Code
                                            <i className="fa fa-asterisk text-danger"></i>
                                        </Label>
                                        <div className="inputsGroup">
                                            <ZipCodeInput
                                                setFormDataFields={setFormDataFields}
                                                fieldName={"BusinessAddressZip"}
                                                required={true}
                                                formDataFields={formDataFields}
                                                isReadOnly={formSettings?.isReadOnly}
                                                setIsValidField={setBusinessZipCode}
                                                validField={businessZipCode}
                                            />
                                            {!inputValidator("BusinessAddressZip") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.item}>
                                <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                    <p>Business Property Address</p>
                                    { (!isAdminPreview && !formSettings?.isReadOnly) &&
                                        <button className={`${classes.button} ${classes.buttonAddress}`} onClick={e => saveAsBusinessAddressHandler(e)}>
                                            Same as Mailing Address
                                        </button> }
                                </div>
                                <div className={classes.itemFields}>
                                    <div className={`${classes.fieldsGroup} w-100`}>
                                        <Label className={classes.Label}>
                                            Property Address Line 1
                                            <i className="fa fa-asterisk text-danger"></i>
                                        </Label>
                                        <div className="inputsGroup">
                                            <Input
                                                className={setStyles("PropertyAddressLine1")}
                                                name={"PropertyAddressLine1"}
                                                required={true}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.["PropertyAddressLine1"]}
                                                onChange={(e)=> changeValueHandler(e, "PropertyAddressLine1")}
                                            />
                                            {!inputValidator("PropertyAddressLine1") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.itemFields}>
                                    <div className={`${classes.fieldsGroup} w-100`}>
                                        <Label className={classes.Label}>
                                            Property Address Line 2
                                        </Label>
                                        <div className="inputsGroup">
                                            <Input
                                                className={classes.kTextbox}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.PropertyAddressLine2}
                                                onChange={(e)=> changeValueHandler(e, "PropertyAddressLine2")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.itemFields}>
                                    <div className={`${classes.fieldsGroupW30}`}>
                                        <Label className={classes.Label}>
                                            City
                                            <i className="fa fa-asterisk text-danger"></i>
                                        </Label>
                                        <div className="inputsGroup">
                                            <Input
                                                className={setStyles("PropertyAddressCity")}
                                                name={"PropertyAddressCity"}
                                                required={true}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.PropertyAddressCity}
                                                onChange={(e)=> changeValueHandler(e, "PropertyAddressCity")}
                                            />
                                            {!inputValidator("PropertyAddressCity") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                    </div>
                                    <div className={`${classes.fieldsGroupW30}`}>
                                        <Label className={classes.Label}>
                                            State
                                            <i className="fa fa-asterisk text-danger"></i>
                                        </Label>
                                        <div className="inputsGroup">
                                            <StatesInput
                                                setFormDataFields={setFormDataFields}
                                                fieldName={"PropertyAddressState"}
                                                required={true}
                                                validField={propertyStateInputValid}
                                                setIsValidField={setPropertyStateInputValid}
                                                formDataFields={formDataFields}
                                                isReadOnly={formSettings?.isReadOnly}
                                            />
                                        </div>
                                    </div>
                                    <div className={`${classes.fieldsGroupW30}`}>
                                        <Label className={classes.Label}>
                                            Zip Code
                                            <i className="fa fa-asterisk text-danger"></i>
                                        </Label>
                                        <div className="inputsGroup">
                                            <ZipCodeInput
                                                setFormDataFields={setFormDataFields}
                                                fieldName={"PropertyAddressZip"}
                                                required={true}
                                                formDataFields={formDataFields}
                                                isReadOnly={formSettings?.isReadOnly}
                                                validField={propertyZipCode}
                                                setIsValidField={setPropertyZipCode}
                                            />
                                            {!inputValidator("PropertyAddressZip") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
