import styles from "./FormHeader.module.scss";
import { InitialSelectedFormStateType } from "src/context/SelectedForm/selected-form-reducer";
import { TimelinePopupButton } from "../TimelinePopupButton/TimelinePopupButton";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { IFormEntryModel } from "src/interfaces/form-entry.interface";
import { useFormContext } from "src/context/Form/form-context";
import { useSelectedFormContext } from "src/context/SelectedForm/selected-form-context";
import {useEffect} from "react";
import { RequestHelpButton } from "../RequestHelp/RequestHelpButton";
import { settingsService } from "src/services/settings.service";

export interface FormHeaderProps {
  formName: string,
  isAuthorized?: boolean;
  isAdminPreview?: boolean;
  formSettings?: IFormSettingModel;
  formEntry?: IFormEntryModel;
  isFormValid?: boolean;
  onPrint?: () => void;
  onSave?: () => void;
  onSubmit?: () => void;
  showSaveOnSubmitted?: boolean
}

export const FormHeader = (props: FormHeaderProps) => {
  const {
    formName,
    isAuthorized,
    isAdminPreview,
    formSettings,
    onPrint,
    formEntry,
    isFormValid,
    onSave,
    onSubmit,
    showSaveOnSubmitted
  } = props;

  const { formState: { forms } } = useFormContext();
  const { selectedFormState: { formTimelines } } = useSelectedFormContext();
  const { settings } = settingsService();

  const timelines = formTimelines?.length ? formTimelines : null;
  const displayName = forms.find(f => f.name === formName)?.fullName;

  return (
    <>
      <div className={styles.formTitle}>
        <span>
          <i className="fa fa-file-o fa-fw"></i>{" "}
          {displayName}
        </span>
        {isAuthorized && (
          <span className="buttons-group">
            {!isAdminPreview && settings.enablePoMessaging && (
              <RequestHelpButton />
            )}
            {timelines && !isAdminPreview && (
              <TimelinePopupButton timelines={timelines} />
            )}
            {!isAdminPreview && (
              <button className="button" onClick={onPrint}>
                <i className="fa fa-print fa-fw"></i>
                Print
              </button>
            )}
            { (!formSettings?.isReadOnly || !formEntry?.isUploadReadOnly) && !isAdminPreview && (
              <>

                { ( formEntry?.submissionStatus != "Submitted" || !!showSaveOnSubmitted) &&
                  <button className="button" onClick={onSave}>
                    <i className="fa fa-save fa-fw"></i>
                    Save Progress
                  </button>
                }

                {!formSettings?.isReadOnly && formEntry?.submissionStatus != "Submitted" && (
                  <button
                    disabled={!isFormValid}
                    className="button"
                    onClick={onSubmit}
                  >
                    <i className="fa fa-tasks fa-fw"></i>
                    Review & Submit
                  </button>
                )}
              </>
            )}
          </span>
        )}
      </div>

      {!isAuthorized && (
        <div className="alert alert-danger h3">
          <p className={`${styles.alert} ${styles.alertDanger}`}>
            THIS FORM IS READ-ONLY. PLEASE SIGN IN OR REGISTER TO FILL OUT THE
            FORM.
          </p>
        </div>
      )}
    </>
  );
};
