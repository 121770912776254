export type IExemptionPropertyDamagedFormThirdSection = {
    AuthorizedRepresentativeType?: string;
    AuthorizedRepresentativeTypeOther?: string;
    AuthorizedRepresentativeName?: string;
    AuthorizedRepresentativePersonalId?: string;
    AuthorizedRepresentativeTitle?: string;
    AuthorizedRepresentativePhoneNumber?: string;
    AuthorizedRepresentativeEmailAddress?: string;
    AuthorizedRepresentativeAddressLine1?: string;
    AuthorizedRepresentativeAddressLine2?: string;
    AuthorizedRepresentativeAddressCity?: string;
    AuthorizedRepresentativeAddressState?: string;
    AuthorizedRepresentativeAddressZip?: string;
}

export const ExemptionPropertyDamagedFormThirdSectionInterface = (): IExemptionPropertyDamagedFormThirdSection => ({
    AuthorizedRepresentativeType: undefined,
    AuthorizedRepresentativeTypeOther: undefined,
    AuthorizedRepresentativeName: undefined,
    AuthorizedRepresentativePersonalId: undefined,
    AuthorizedRepresentativeTitle: undefined,
    AuthorizedRepresentativePhoneNumber: undefined,
    AuthorizedRepresentativeEmailAddress: undefined,
    AuthorizedRepresentativeAddressLine1: undefined,
    AuthorizedRepresentativeAddressLine2: undefined,
    AuthorizedRepresentativeAddressCity: undefined,
    AuthorizedRepresentativeAddressState: undefined,
    AuthorizedRepresentativeAddressZip: undefined,
})