import {useNavigate, useSearchParams} from "react-router-dom";
import { useSelectedFormContext } from "../../../../../context/SelectedForm/selected-form-context";
import { useEffect, useState, useMemo } from "react";
import { IFormFiledInterface } from "../../../../../interfaces/form-filed.interface";
import { IFormSettingModel } from "../../../../../interfaces/form-settings.interface";
import { IFormEntryModel } from "../../../../../interfaces/form-entry.interface";
import classes from "./HomesteadExemption.module.scss";
import {
  getHarrisSelectedFormDataWithPrefilledDataHelper,
  getSelectedFormDataHelper,
  getSelectedFormEntryDataHelper, saveFormHelper
} from "../../../../../context/SelectedForm/selected-form-context.helper";
import HomesteadExemptionFirstSection from "./HomesteadExemptionFirstSection";
import {
  HomesteadExemptionFormFirstSectionInitialState,
  IHomesteadExemptionFirstSectionInterface
} from "../../../../../interfaces/forms/HomesteadExemptionForm/homestead-exemption-first-section.interface";
import {HomesteadExemptionFormName} from "../../../../../constants/form-names-constants";
import { useFormContext } from "../../../../../context/Form/form-context";
import {
  onLoadForms
} from "../../../../../context/Form/form-helper";
import {
  HomesteadExemptionFormSecondSectionInitialState,
  IHomesteadExemptionSecondSectionInterface
} from "../../../../../interfaces/forms/HomesteadExemptionForm/homestead-exemption-second-section.interface";
import { useFormSectionDataFields } from "../useFormSectionDataFields";
import HomesteadExemptionSecondSection from "./HomesteadExemptionSecondSection";
import HomesteadExemptionThirdSection from "./HomesteadExemptionThirdSection";
import {
  HomesteadExemptionFormThirdSectionInitialState,
  IHomesteadExemptionThirdSectionInterface
} from "../../../../../interfaces/forms/HomesteadExemptionForm/homestead-exemption-third-section.interface";
import {
  HomesteadExemptionFormFourthSectionInitialState,
  IHomesteadExemptionFourthSectionInterface
} from "../../../../../interfaces/forms/HomesteadExemptionForm/homestead-exemption-fourth-section.interface";
import HomesteadExemptionFourthSection from "./HomesteadExemptionFourthSection";
import {
  HomesteadExemptionFormFifthSectionInitialState,
  IHomesteadExemptionFifthSectionInterface
} from "../../../../../interfaces/forms/HomesteadExemptionForm/homestead-exemption-fifth-section.interface";
import HomesteadExemptionFifthSection from "./HomesteadExemptionFifthSection";
import {
  HomesteadExemptionFormSixthSectionInitialState,
  IHomesteadExemptionSixthSectionInterface
} from "../../../../../interfaces/forms/HomesteadExemptionForm/homestead-exemption-sixth-section.interface";
import HomesteadExemptionSixthSection from "./HomesteadExemptionSixthSection";
import HomesteadExemptionSeventhForm from "./HomesteadExemptionSeventhForm";
import { Dialog } from "@progress/kendo-react-dialogs";
import HomesteadExemptionFormPDFView
  from "../../../../Shared/HomesteadExemptionFormPdfView/HomesteadExemptionFormPDFView";
import { SelectedFormActionsEnum } from "../../../../../context/SelectedForm/selected-form-actions";
import { FormActionsEnum } from "../../../../../context/Form/form-actions";
import { CustomLoader } from "../../../../../components";
import { useCallbackPrompt } from "../../../../../hooks/useCallbackPrompt";
import UnsavedFormDialog from "src/components/CustomDialogs/UnsavedFormDialog/UnsavedFormDialog";
import AuthService from 'src/services/authentication.service';
import {settingsService} from "../../../../../services/settings.service";
import {globalConfig} from "../../../../../configuration/config";
import {initiatedByOnlineForms, initiatedByPropertySearch} from "../../../../../constants/form-entry-constants";
import { FormHeader } from "../Shared/FormHeader/FormHeader";
import { submitted } from "src/constants/submission-form-status-constants";
import { useIsFormActive } from "../useIsFormActive";

interface FormEntry {
  formEntryId?: number;
  isAdminPreview?: boolean;
  formId?: number;
}

export default function HomesteadExemptionForm(props: FormEntry): JSX.Element {
  const { isAuthorized } = AuthService.getInstance();
  const settings = settingsService().settings;
  const [searchParams] = useSearchParams();
  const { isAdminPreview, formEntryId, formId } = props;
  const { formState, formDispatch } = useFormContext();
  const { selectedFormDispatch, selectedFormState } = useSelectedFormContext();
  const { loading } = formState;
  const { isLoading } = selectedFormState;
  const [formDataFields, setFormDataFields] = useState<Array<IFormFiledInterface>>();
  const [formEntry, setFormEntry] = useState<IFormEntryModel>();
  const formSettings = useMemo(() => {
    let formSettings = selectedFormState.formSettings ?? undefined;
    if (
        !isAuthorized ||
        isAdminPreview ||
        selectedFormState.formEntry?.submissionStatus == submitted
     ) {
      formSettings = { ...formSettings, isReadOnly: true };
     }
     return formSettings;
  }, [selectedFormState.formSettings, selectedFormState.formEntry, isAuthorized, isAdminPreview]);

  const [formFirstSectionDataFields, setFormFirstSectionDataFields] = useState<IHomesteadExemptionFirstSectionInterface>();
  const [formSecondSectionDataFields, setFormSecondSectionDataFields] = useState<IHomesteadExemptionSecondSectionInterface>();
  const [formThirdSectionDataFields, setFormThirdSectionDataFields] = useState<IHomesteadExemptionThirdSectionInterface>();
  const [formFourthSectionDataFields, setFormFourthSectionDataFields] = useState<IHomesteadExemptionFourthSectionInterface>();
  const [formFifthSectionDataFields, setFormFifthSectionDataFields] = useState<IHomesteadExemptionFifthSectionInterface>();
  const [formSixthSectionDataFields, setFormSixthSectionDataFields] = useState<IHomesteadExemptionSixthSectionInterface>();

  const allDocuments = useMemo(() => {
    return {
      AllAttachedDocuments: [
        ...(formSecondSectionDataFields?.Affidavit || []),
        ...(formSecondSectionDataFields?.OneHundredPercentDisabledVeteranDocumentation || []),
        ...(formThirdSectionDataFields?.UtilityBill || []),
        ...(formThirdSectionDataFields?.PropertyOwnerPhotoId || []),
        ...(formThirdSectionDataFields?.PropertyOwnerSpousePhotoId || []),
        ...(formFourthSectionDataFields?.HeirPropertyDocuments || []),
        ...(formFourthSectionDataFields?.OtherPropertyOwnersDocuments || []),
        ...(formFourthSectionDataFields?.ManufacturedHomeSupportingDocuments || []),
        ...(formFifthSectionDataFields?.AdditionalDocuments || []),
      ]
    }
  }, [formSecondSectionDataFields, formThirdSectionDataFields, formFourthSectionDataFields, formFifthSectionDataFields]);

  const [firstSectionIsValid, setFirstSectionIsValid] = useState<boolean>(false);
  const [secondSectionIsValid, setSecondSectionIsValid] = useState<boolean>(false);
  const [thirdSectionIsValid, setThirdSectionIsValid] = useState<boolean>(false);
  const [fourthSectionIsValid, setFourthSectionIsValid] = useState<boolean>(false);
  const [fifthSectionIsValid, setFifthSectionIsValid] = useState<boolean>(false);
  const [sixthSectionIsValid, setSixthSectionIsValid] = useState<boolean>(false);

  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const { isFormActive } = useIsFormActive(
    isAuthorized,
    isAdminPreview,
    formSettings,
    "AdditionalDocuments",
    selectedFormState.formFields,
    formFifthSectionDataFields?.AdditionalDocuments
  );
  const [showPrompt, confirmNavigation, cancelNavigation, setDisablePrompt] = useCallbackPrompt(isFormActive);

  useEffect(() => {
    setIsFormValid(
      firstSectionIsValid &&
      secondSectionIsValid &&
      thirdSectionIsValid &&
      fourthSectionIsValid &&
      fifthSectionIsValid &&
      sixthSectionIsValid)
  }, [
    firstSectionIsValid,
    secondSectionIsValid,
    thirdSectionIsValid,
    fourthSectionIsValid,
    fifthSectionIsValid,
    sixthSectionIsValid,]);

  useEffect(() => {
    return () => {
      selectedFormDispatch({
        type: SelectedFormActionsEnum.CLEAR,
        payload: {
          errorMessage: undefined,
          isLoading: undefined,
          formFields: undefined,
          successMessage: undefined,
          form: undefined,
          formSettings: undefined,
          formEntry: undefined,
          status: SelectedFormActionsEnum.CLEAR
        },
      });
    }
  }, []);

  useEffect(() => {
    if (!formState.forms.length) {
      onLoadForms(formDispatch);
    }
  }, []);

  useEffect(() => {
    const stateFormId = formState?.forms?.find(x => x.name == HomesteadExemptionFormName)?.id ?? formId;

    if (stateFormId && isAuthorized) {
      if (formEntryId) {
        getSelectedFormEntryDataHelper(selectedFormDispatch, stateFormId, formEntryId);
      } else if (settings.isHarrisClient && isAuthorized){
        GetFormDataWithSettingsForHarrisClient(stateFormId);
      } else {
        getSelectedFormDataHelper(selectedFormDispatch, stateFormId);
      }
    }
  }, [formState.forms]);

  function GetFormDataWithSettingsForHarrisClient(stateFormId: number){
    const propertySearchRoot = globalConfig.get().propertySearchRoot;
    const referrer = sessionStorage.getItem('referrer');
    const viewMode = searchParams.get("viewMode");

    if (referrer?.includes(propertySearchRoot)) {
      sessionStorage.setItem('referrer', referrer);

      const propertyId = searchParams.get("propertyId");
      const yearString = searchParams.get("year");
      const ownerId = searchParams.get("ownerId");

      if (stateFormId && propertyId && yearString && ownerId) {
        if(!!viewMode) {
          setDisablePrompt(true);
        }

        getHarrisSelectedFormDataWithPrefilledDataHelper(
            selectedFormDispatch,
            stateFormId,
            HomesteadExemptionFormName,
            propertyId,
            parseInt(yearString),
            ownerId,
            initiatedByPropertySearch,
            navigate);
      } else {
        getSelectedFormDataHelper(selectedFormDispatch, stateFormId, initiatedByPropertySearch);
        //TODO: throw error.
      }
    } else {
      getSelectedFormDataHelper(selectedFormDispatch, stateFormId, initiatedByOnlineForms);
    }
  }

  useEffect(() => {
    if (selectedFormState?.formEntry) {
      if (selectedFormState.form?.name) {
        setFormEntry({
          ...selectedFormState?.formEntry,
          formName: selectedFormState.form?.name
        });
      } else {
        setFormEntry(selectedFormState.formEntry)
      }
    }
  }, [selectedFormState.formEntry, selectedFormState.form]);

  function ClearData() {
    setFormDataFields([]);
    setFormEntry(undefined);
    setFormFirstSectionDataFields({});
    setFormSecondSectionDataFields({});
    setFormThirdSectionDataFields({});
    setFormFourthSectionDataFields({});
    setFormFifthSectionDataFields({});
    setFormSixthSectionDataFields({});
    setFirstSectionIsValid(false);
    setSecondSectionIsValid(false);
    setThirdSectionIsValid(false);
    setFourthSectionIsValid(false);
    setFifthSectionIsValid(false);
    setSixthSectionIsValid(false);

    setDisablePrompt(false);
  }

  useEffect(() => {
    if (selectedFormState.status === SelectedFormActionsEnum.CLEAR) {
      ClearData();
      return;
    }

    setFormDataFields(selectedFormState.formFields);
    const FirstSection: IHomesteadExemptionFirstSectionInterface = HomesteadExemptionFormFirstSectionInitialState();
    const SecondSection: IHomesteadExemptionSecondSectionInterface = HomesteadExemptionFormSecondSectionInitialState();
    const ThirdSection: IHomesteadExemptionThirdSectionInterface = HomesteadExemptionFormThirdSectionInitialState();
    const FourthSection: IHomesteadExemptionFourthSectionInterface = HomesteadExemptionFormFourthSectionInitialState();
    const FifthSection: IHomesteadExemptionFifthSectionInterface = HomesteadExemptionFormFifthSectionInitialState();
    const SixthSection: IHomesteadExemptionSixthSectionInterface = HomesteadExemptionFormSixthSectionInitialState();

    selectedFormState?.formFields.forEach((f) => {
      let value;
      switch (f.type) {
        case "boolean":
        case "object":
          value = JSON.parse(f.value);
          break;
        case "string":
          value = f.value?.trim();
          break;
        case "number":
          value = +f.value;
          break;
      }

      switch(true) {
        case f.name in FirstSection:
          (FirstSection as any)[f.name] = value;
          break;
        case f.name in SecondSection:
          (SecondSection as any)[f.name] = value;
          break;
        case f.name in ThirdSection:
          (ThirdSection as any)[f.name] = value;
          break;
        case f.name in FourthSection:
          (FourthSection as any)[f.name] = value;
          break;
        case f.name in FifthSection:
          (FifthSection as any)[f.name] = value;
          break;
        case f.name in SixthSection:
          (SixthSection as any)[f.name] = value;
          break;
      }
    });

    if (selectedFormState.formFields.length > 0) {
      setFormFirstSectionDataFields(FirstSection);
      setFormSecondSectionDataFields(SecondSection);
      setFormThirdSectionDataFields(ThirdSection);
      setFormFourthSectionDataFields(FourthSection);
      setFormFifthSectionDataFields(FifthSection);
      setFormSixthSectionDataFields(SixthSection);
    }
  }, [selectedFormState.formFields]);

  useFormSectionDataFields({
    sectionFields: formFirstSectionDataFields,
    formDataFields: formDataFields,
    setFormDataFields: setFormDataFields,
    formId: formEntry?.formId,
    formEntryId: formEntry?.id
  });

  useFormSectionDataFields({
    sectionFields: formSecondSectionDataFields,
    formDataFields: formDataFields,
    setFormDataFields: setFormDataFields,
    formId: formEntry?.formId,
    formEntryId: formEntry?.id
  });

  useFormSectionDataFields({
    sectionFields: formThirdSectionDataFields,
    formDataFields: formDataFields,
    setFormDataFields: setFormDataFields,
    formId: formEntry?.formId,
    formEntryId: formEntry?.id
  });

  useFormSectionDataFields({
    sectionFields: formFourthSectionDataFields,
    formDataFields: formDataFields,
    setFormDataFields: setFormDataFields,
    formId: formEntry?.formId,
    formEntryId: formEntry?.id
  });

  useFormSectionDataFields({
    sectionFields: formFifthSectionDataFields,
    formDataFields: formDataFields,
    setFormDataFields: setFormDataFields,
    formId: formEntry?.formId,
    formEntryId: formEntry?.id
  });

  useFormSectionDataFields({
    sectionFields: formSixthSectionDataFields,
    formDataFields: formDataFields,
    setFormDataFields: setFormDataFields,
    formId: formEntry?.formId,
    formEntryId: formEntry?.id
  });

  useFormSectionDataFields({
    sectionFields: allDocuments,
    formDataFields: formDataFields,
    setFormDataFields: setFormDataFields,
    formId: formEntry?.formId,
    formEntryId: formEntry?.id
  });

  const navigate = useNavigate();

  function saveHandler() {
    setDisablePrompt(true)
    const temp = {
      ...formEntry,
      formName: selectedFormState.form?.name,
      submissionStatus: "Saved",
    } as IFormEntryModel;
    setFormEntry(temp);
    if (selectedFormState.form?.name) {
      saveFormHelper(selectedFormDispatch, selectedFormState.form?.name, temp, formDataFields!, navigate);
    }
  }

  function setFormEntryBase64Url(url: string) {
    setFormEntry({ ...formEntry, pdfBase64Url: url } as IFormEntryModel);
  }

  function submitHandler() {
    const temp = {
      ...formEntry,
      submissionStatus: "Submitted",
    } as IFormEntryModel;

    setFormEntry(temp);
    setDisablePrompt(true);

    if (selectedFormState.form?.name) {
      saveFormHelper(selectedFormDispatch, selectedFormState.form?.name, temp, formDataFields!, navigate)
        .then(() => setVisible(false));
    }
  }

  const [visible, setVisible] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const toggleSubmitDialog = () => {
    setSubmit(!submit);
    setVisible(!visible);
  };

  const dialogTitle = (
    <div>
      {submit && formEntry?.submissionStatus != "Submitted" && (
        <button className="button" onClick={submitHandler}>
          submit form
        </button>
      )}
    </div>
  );

  return <>
    <div className={classes.form}>
      {visible && (
        <Dialog
          className="customFormDialog"
          title={dialogTitle}
          onClose={submit ? toggleSubmitDialog : toggleDialog}
          width={"70vw"}
          height={"90vh"}
        >
          <HomesteadExemptionFormPDFView
            getPdfBase64Url={setFormEntryBase64Url}
            formDataFieldsFirstSection={formFirstSectionDataFields}
            formDataFieldsSecondSection={formSecondSectionDataFields}
            formDataFieldsThirdSection={formThirdSectionDataFields}
            formDataFieldsFourthSection={formFourthSectionDataFields}
            formDataFieldsFifthSection={formFifthSectionDataFields}
            formDataFieldsSixthSection={formSixthSectionDataFields}
          />
        </Dialog>
      )}

      <FormHeader
        formName={HomesteadExemptionFormName}
        isAdminPreview={isAdminPreview}
        isAuthorized={isAuthorized}
        formEntry={formEntry}
        formSettings={formSettings}
        isFormValid={isFormValid}
        onPrint={() => toggleDialog()}
        onSave={() => saveHandler()}
        onSubmit={() => toggleSubmitDialog()}
      />

      <HomesteadExemptionFirstSection
        isAdminPreview={isAdminPreview}
        formSettings={formSettings}
        formDataFields={formFirstSectionDataFields}
        setFormDataFields={setFormFirstSectionDataFields}
        setFormIsValid={setFirstSectionIsValid}
      />

      <HomesteadExemptionSecondSection
        isAdminPreview={isAdminPreview}
        formSettings={formSettings}
        formDataFields={formSecondSectionDataFields}
        setFormDataFields={setFormSecondSectionDataFields}
        setFormIsValid={setSecondSectionIsValid}
        formEntry={formEntry}
        selectedFormDispatch={selectedFormDispatch}
      />

      <HomesteadExemptionThirdSection
        isAdminPreview={isAdminPreview}
        formSettings={formSettings}
        formDataFields={formThirdSectionDataFields}
        setFormDataFields={setFormThirdSectionDataFields}
        setFormIsValid={setThirdSectionIsValid}
        formEntry={formEntry}
        selectedFormDispatch={selectedFormDispatch}
      />

      <HomesteadExemptionFourthSection
        isAdminPreview={isAdminPreview}
        formSettings={formSettings}
        formDataFields={formFourthSectionDataFields}
        formDataFieldsThirdSection={formThirdSectionDataFields}
        setFormDataFields={setFormFourthSectionDataFields}
        setFormIsValid={setFourthSectionIsValid}
        formEntry={formEntry}
        selectedFormDispatch={selectedFormDispatch}
      />

      <HomesteadExemptionFifthSection
        isAdminPreview={isAdminPreview}
        formSettings={formSettings}
        formDataFields={formFifthSectionDataFields}
        setFormDataFields={setFormFifthSectionDataFields}
        setFormIsValid={setFifthSectionIsValid}
        formEntry={formEntry}
        selectedFormDispatch={selectedFormDispatch}
      />

      <HomesteadExemptionSixthSection
        isAdminPreview={isAdminPreview}
        formSettings={formSettings}
        formDataFields={formSixthSectionDataFields}
        setFormDataFields={setFormSixthSectionDataFields}
        setFormIsValid={setSixthSectionIsValid}
      />

      <HomesteadExemptionSeventhForm />

      {(!isFormValid && !formSettings?.isReadOnly) &&
        <div className={classes.invalidForm}>
          <h2>Please Fill All Required Fields in Steps:</h2>
          {!firstSectionIsValid &&
            <div className={classes.item}>
              1.   General Information
            </div>}
          {!secondSectionIsValid &&
            <div className={classes.item}>
              2.   Exemption(s) Requested (Select all that apply.)
            </div>}
          {!thirdSectionIsValid &&
            <div className={classes.item}>
              3.   Applicant Information
            </div>}
          {!fourthSectionIsValid &&
            <div className={classes.item}>
              4.   Property Information
            </div>}
          {!fifthSectionIsValid &&
            <div className={classes.item}>
              5.   Additional Information and Documentation
            </div>}
          {!sixthSectionIsValid &&
            <div className={classes.item}>
              6.   Signature
            </div>}
        </div>}

      {isAuthorized && !isAdminPreview &&
        !formSettings?.isReadOnly &&
        formEntry?.submissionStatus != "Submitted" && (
          <span className="buttons-group">
            <button disabled={!isFormValid}
              className="button"
              onClick={() => toggleSubmitDialog()}
            >
              <i className="fa fa-tasks fa-fw"></i>
              Review & Submit
            </button>
          </span>
        )}
    </div>
    {(loading || isLoading) && <CustomLoader />}
    {showPrompt && isAuthorized && (!loading && !isLoading) && (
      <UnsavedFormDialog
        onConfirm={confirmNavigation}
        onCancel={cancelNavigation}
      />
    )}
  </>
}
