import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "src/pages/Home/components/Forms/ExemptionPropertyDamagedForm/ExemptionPropertyDamaged.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { Label } from "@progress/kendo-react-labels";
import { CustomDatePicker } from "../CustomInputs/CustomDatePickers/CustomDatePicker";
import { CustomOnlyYearCalendarHelper } from "src/pages/Shared/DatePickers/CustomCalendars";
import { IExemptionPropertyDamagedFormFirstSection } from "src/interfaces/forms/ExemptionPropertyDamagedByDisasterForm/exemption-property-damaged-first-section.interface";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IExemptionPropertyDamagedFormFirstSection) => void;
    formDataFields?:  IExemptionPropertyDamagedFormFirstSection,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function ExemptionPropertyDamagedFormFirstSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, setFormIsValid } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const [taxYearInputValid, setTaxYearInputValid] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={1} name={"Property Owner/Applicant"}/>;
    const { CustomOnlyYearCalendar } = CustomOnlyYearCalendarHelper();

    function changeValidator() {
        if (formRef.current) {
            const isValid = formRef.current.checkValidity() &&
                taxYearInputValid;
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if(!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current, taxYearInputValid]);

    return (
       <>
            <div className={classes.information}>
                <div>
                    <p>
                        <b>GENERAL INFORMATION:</b> 
                        Property owners use this form to claim a temporary property tax exemption for property 
                        in a governor-declared disaster area with at least 15 percent damage. (Tax Code Section 11.35)
                    </p>
                </div>
                <div>
                    <p>
                        <b>FILING INSTRUCTIONS:</b> File this form and all supporting documentation with the appraisal 
                        district office in each county where property is located no later than the 105th day after the 
                        date the governor declares the area to be a disaster area.
                        <b>Do <u>not</u> file this document with the Texas Comptroller of Public Accounts.</b>
                    </p>
                </div>
            </div>
            <PanelBar keepItemsMounted={true}>
                <PanelBarItem title={title} expanded={true}>
                    <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                        <div className="container">
                            <div className={classes.item}>
                                <div className={`${classes.itemFields} ${classes.itemFieldsLeft} ${classes.bordered}`}>
                                    <div className={`${classes.fieldsGroup} ${classes.fieldsGroupLeft}`}>
                                        <Label className={classes.Label}>
                                            Tax Year
                                            {!formDataFields?.TaxYear && (
                                                <i className={"fa fa-asterisk text-danger"}></i>
                                            )}
                                        </Label>
                                        <div className="inputsGroup">
                                            <CustomDatePicker
                                                fieldName={"TaxYear"}
                                                required={true}
                                                disabled={formSettings?.isReadOnly}
                                                formDataFields={formDataFields}
                                                setFormDataFields={setFormDataFields}
                                                onlyYear={true}
                                                CustomCalendar={CustomOnlyYearCalendar}
                                                setIsValidField={setTaxYearInputValid}
                                                validField={taxYearInputValid}
                                                defaultValueDate={formDataFields?.TaxYear}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                   </form>
                </PanelBarItem>
            </PanelBar>
       </>
    )
}