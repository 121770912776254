import {IFormFiledInterface} from "../../../../interfaces/form-filed.interface";
import {useEffect, SetStateAction, Dispatch} from "react";
import {IBppFormSeventhSectionInterface} from "../../../../interfaces/forms/BPPForm/bpp-form-seventh-section.interface";
import {IBppFormEighthSectionInterface} from "../../../../interfaces/forms/BPPForm/bpp-form-eighth-section.interface";
import {IBppFormNinthSectionInterface} from "../../../../interfaces/forms/BPPForm/bpp-form-ninth-section.interface";
import {IBppFormTenthSectionInterface} from "../../../../interfaces/forms/BPPForm/bpp-form-tenth-section.interface";
import {
    IBppFormEleventhSectionInterface
} from "../../../../interfaces/forms/BPPForm/bpp-form-eleventh-section.interface";
import {IBppFormTwelfthSectionInterface} from "../../../../interfaces/forms/BPPForm/bpp-form-twelfth-section.interface";
import {IBppFormSecondSectionInterface} from "../../../../interfaces/forms/BPPForm/bpp-form-second-section.interface";
import {IBPPFormThirdSectionInterface} from "../../../../interfaces/forms/BPPForm/bpp-form-third-section.interface";
import {IBppFormFourthSectionInterface} from "../../../../interfaces/forms/BPPForm/bpp-form-fourth-section.interface";
import {IBppFormFifthSectionInterface} from "../../../../interfaces/forms/BPPForm/bpp-form-fifth-section.interface";
import {IBppFormSixthSectionInterface} from "../../../../interfaces/forms/BPPForm/bpp-form-sixth-section.interface";
import {
    IBppFormThirteenthSectionInterface
} from "../../../../interfaces/forms/BPPForm/bpp-form-thirteenth-section.interface";
import {
    IBppFormFourteenthSectionInterface
} from "../../../../interfaces/forms/BPPForm/bpp-form-fourteenth-section.interface";
import {
    IBppFormFifteenthSectionInterface
} from "../../../../interfaces/forms/BPPForm/bpp-form-fifteenth-section.interface";
import {
    IBPPFormPersonalPropertySegmentsInterface
} from "../../../../interfaces/forms/BPPForm/bpp-form-personal-property-segments.interface";
import {IAssetsInterface} from "../../../../interfaces/forms/BPPForm/assets-interface";
import {IDeletedAssetsInterface} from "../../../../interfaces/forms/BPPForm/deleted-assets-interface";
import { IFormFilesInterface } from "src/interfaces/forms/Shared/form-files.interface";

export interface IUseFormSectionDataFields{
    setFormDataFields?: Dispatch<SetStateAction<IFormFiledInterface[] | undefined>>//(items: Array<IFormFiledInterface>) => void,
    formDataFields?: Array<IFormFiledInterface>,
    sectionFields?: any,
    formId?: number,
    formEntryId?: number
}

export function useFormSectionDataFields(props:IUseFormSectionDataFields){
    const {setFormDataFields, sectionFields, formId, formEntryId} = props;
    useEffect(() => {
        if(!setFormDataFields) {
            return;
        }

        setFormDataFields((formDataFields) => {
            let temp: Array<IFormFiledInterface> = formDataFields
            ? JSON.parse(JSON.stringify(formDataFields))
            : [];
            for (const i in sectionFields) {
                if ((sectionFields as any)[i] != null) {
                    const isItemExist = temp.some((x) => x?.name == i);
                    if (isItemExist) {
                        const updatedItem = temp[temp.findIndex((x) => x.name == i)];
                        if (typeof (sectionFields as any)[i] == "object") {
                            updatedItem.value = JSON.stringify(
                                sectionFields
                                    ? (sectionFields as any)[i]!
                                    : [i]
                            );
                        } else {
                            updatedItem.value = (
                                sectionFields
                                    ? (sectionFields as any)[i]!
                                    : [i]
                            ).toString();
                        }
                        temp[temp.findIndex((x) => x.name == i)] = updatedItem;
                    } else {
                        if (typeof (sectionFields as any)[i] == "object") {
                            temp.push({
                                name: i,
                                value: JSON.stringify(
                                    sectionFields
                                        ? (sectionFields as any)[i]!
                                        : [i]
                                ),
                                type: typeof (sectionFields as any)[i],
                                formId: formId,
                                formEntryId: formEntryId,
                            });
                        } else {
                            temp.push({
                                name: i,
                                value: (sectionFields
                                        ? (sectionFields as any)[i]!
                                        : [i]
                                ).toString(),
                                type: typeof (sectionFields as any)[i],
                                formId: formId,
                                formEntryId: formEntryId,
                            });
                        }
                    }
                } else {
                    temp = temp.filter(x=> x.name != i);
                }
            }

            return temp;
        });
    }, [sectionFields, formEntryId, formEntryId]);
}

export interface GetFormFieldFormSectionsType{
    formFields:  IFormFiledInterface[],
    secondSection?: IBppFormSecondSectionInterface,
    thirdSection?: IBPPFormThirdSectionInterface,
    fourthSection?: IBppFormFourthSectionInterface,
    fifthSection?: IBppFormFifthSectionInterface,
    sixthSection?: IBppFormSixthSectionInterface,
    scheduleA?: IBppFormSeventhSectionInterface,
    scheduleB?: IBppFormEighthSectionInterface,
    scheduleC?: IBppFormNinthSectionInterface,
    scheduleD?:  IBppFormTenthSectionInterface,
    scheduleE?: IBppFormEleventhSectionInterface,
    scheduleF?: IBppFormTwelfthSectionInterface,
    thirteenthSection?: IBppFormThirteenthSectionInterface,
    fourteenthSection?: IBppFormFourteenthSectionInterface,
    fifteenthSection?: IBppFormFifteenthSectionInterface,
    formId?: number,
    formEntryId?: number,
    assetsList?: IAssetsInterface,
    deletedAssetsList?: IDeletedAssetsInterface,
    allDocuments: IFormFilesInterface[];
}

export function GetFormFieldFormSections(props: GetFormFieldFormSectionsType): IFormFiledInterface[]{
    let formDataFields : Array<IFormFiledInterface> = props.formFields;

    formDataFields = setDataFields({
        formDataFields: formDataFields,
        sectionFields: props.secondSection,
        formEntryId: props.formEntryId,
        formId: props.formId});

    formDataFields = setDataFields({
        formDataFields: formDataFields,
        sectionFields: props.thirdSection,
        formEntryId: props.formEntryId,
        formId: props.formId});

    formDataFields = setDataFields({
        formDataFields: formDataFields,
        sectionFields: props.fourthSection,
        formEntryId: props.formEntryId,
        formId: props.formId});

    formDataFields = setDataFields({
        formDataFields: formDataFields,
        sectionFields: props.fifthSection,
        formEntryId: props.formEntryId,
        formId: props.formId});


    formDataFields = setDataFields({
        formDataFields: formDataFields,
        sectionFields: props.sixthSection,
        formEntryId: props.formEntryId,
        formId: props.formId});


    formDataFields = setDataFields({
        formDataFields: formDataFields,
        sectionFields: props.scheduleA,
        formEntryId: props.formEntryId,
        formId: props.formId});

    formDataFields = setDataFields({
        formDataFields: formDataFields,
        sectionFields: props.scheduleB,
        formEntryId: props.formEntryId,
        formId: props.formId});

    formDataFields = setDataFields({
        formDataFields: formDataFields,
        sectionFields: props.scheduleC,
        formEntryId: props.formEntryId,
        formId: props.formId});

    formDataFields = setDataFields({
        formDataFields: formDataFields,
        sectionFields: props.scheduleD,
        formEntryId: props.formEntryId,
        formId: props.formId});

    formDataFields = setDataFields({
        formDataFields: formDataFields,
        sectionFields: props.scheduleE,
        formEntryId: props.formEntryId,
        formId: props.formId});

    formDataFields = setDataFields({
        formDataFields: formDataFields,
        sectionFields: props.scheduleF,
        formEntryId: props.formEntryId,
        formId: props.formId});

    formDataFields = setDataFields({
        formDataFields: formDataFields,
        sectionFields: props.thirteenthSection,
        formEntryId: props.formEntryId,
        formId: props.formId});

    formDataFields = setDataFields({
        formDataFields: formDataFields,
        sectionFields: props.fourteenthSection,
        formEntryId: props.formEntryId,
        formId: props.formId});

    formDataFields = setDataFields({
        formDataFields: formDataFields,
        sectionFields: props.fifteenthSection,
        formEntryId: props.formEntryId,
        formId: props.formId});

    if(props.assetsList) {
        formDataFields = setDataFields({
            formDataFields: formDataFields,
            sectionFields: props.assetsList,
            formEntryId: props.formEntryId,
            formId: props.formId});

    }

    if(props.deletedAssetsList) {
        formDataFields = setDataFields({
            formDataFields: formDataFields,
            sectionFields: props.deletedAssetsList,
            formEntryId: props.formEntryId,
            formId: props.formId});

    }

    formDataFields = setDataFields({
        formDataFields: formDataFields,
        sectionFields: { AllAttachedDocuments: props.allDocuments },
        formEntryId: props.formEntryId,
        formId: props.formId,
    })

    return formDataFields;
}

export interface SetDataFieldsType{
    formDataFields: Array<IFormFiledInterface>,
    formId?: number,
    formEntryId?: number,
    sectionFields: any,
}

export function setDataFields(props:SetDataFieldsType): IFormFiledInterface[] {
    const {formDataFields, sectionFields, formId, formEntryId} = props;
    let temp: Array<IFormFiledInterface> = formDataFields;
    for (const i in sectionFields) {
        if ((sectionFields as any)[i] != null) {
            const isItemExist = temp.some((x) => x?.name == i);
            if (isItemExist) {
                const updatedItem = temp[temp.findIndex((x) => x.name == i)];
                if (typeof (sectionFields as any)[i] == "object") {
                    updatedItem.value = JSON.stringify(
                        sectionFields
                            ? (sectionFields as any)[i]!
                            : [i]
                    );
                } else {
                    updatedItem.value = (
                        sectionFields
                            ? (sectionFields as any)[i]!
                            : [i]
                    ).toString();
                }
                temp[temp.findIndex((x) => x.name == i)] = updatedItem;
            } else {
                if (typeof (sectionFields as any)[i] == "object") {
                    temp.push({
                        name: i,
                        value: JSON.stringify(
                            sectionFields
                                ? (sectionFields as any)[i]!
                                : [i]
                        ),
                        type: typeof (sectionFields as any)[i],
                        formId: formId,
                        formEntryId: formEntryId,
                    });
                } else {
                    temp.push({
                        name: i,
                        value: (sectionFields
                                ? (sectionFields as any)[i]!
                                : [i]
                        ).toString(),
                        type: typeof (sectionFields as any)[i],
                        formId: formId,
                        formEntryId: formEntryId,
                    });
                }
            }
        } else {
            temp = temp.filter(x=> x.name != i);
        }
    }

    return temp;
}
