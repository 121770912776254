import {IForm} from "../../interfaces/form.interface";
import {GenericActionType} from "../generic-actions.type";
import {Dispatch} from "react";
import {SelectedFormActionsEnum} from "./selected-form-actions";
import {IFormSettingModel} from "../../interfaces/form-settings.interface";
import {IFormEntryModel} from "../../interfaces/form-entry.interface";
import {IFormFiledInterface} from "../../interfaces/form-filed.interface";
import {
    IBPPFormPersonalPropertySegmentsInterface
} from "../../interfaces/forms/BPPForm/bpp-form-personal-property-segments.interface";
import { ITimeline } from "src/interfaces/timeline.interface";

export interface SelectedFormContextType {
    selectedFormState: InitialSelectedFormStateType;
    selectedFormDispatch: Dispatch<GenericActionType<SelectedFormType>>;
}

export interface InitialSelectedFormStateType {
    errorMessage: string | null;
    isLoading: boolean;
    formFields: IFormFiledInterface[];
    prefilledFormFields: IFormFiledInterface[];
    pacsFormAssets: IBPPFormPersonalPropertySegmentsInterface[];
    successMessage: string | null;
    form: IForm | null;
    formTimelines: ITimeline[] | null;
    formSettings: IFormSettingModel | null;
    formEntry: IFormEntryModel | null;
    status: string | null,
}

export interface SelectedFormType {
    errorMessage?: string;
    isLoading?: boolean;
    formFields?: IFormFiledInterface[];
    prefilledFormFields?: IFormFiledInterface[];
    pacsFormAssets?: IBPPFormPersonalPropertySegmentsInterface[];
    successMessage?: string;
    form?: IForm;
    formSettings?: IFormSettingModel;
    formEntry?: IFormEntryModel;
    formTimelines?: ITimeline[];
    status?: string;
}

export const initialState: InitialSelectedFormStateType = {
    errorMessage: "",
    isLoading: false,
    formFields: [],
    prefilledFormFields: [],
    pacsFormAssets: [],
    successMessage: null,
    form: null,
    formTimelines: null,
    formSettings: null,
    formEntry: null,
    status: null
};

export const SelectedFormReducer = (
    initialState: InitialSelectedFormStateType,
    action: GenericActionType<SelectedFormType>
): InitialSelectedFormStateType => {
    switch (action.type) {
        case SelectedFormActionsEnum.IS_LOADING:
            return {
                ...initialState,
                isLoading: action.isLoading ? action.isLoading : false,
            };

        case SelectedFormActionsEnum.FORM_SETTINGS:
            return {
                ...initialState,
                formSettings: action.payload?.formSettings
                    ? action.payload?.formSettings
                    : null,
                status: SelectedFormActionsEnum.FORM_SETTINGS
            };

        case SelectedFormActionsEnum.FORM:
            return {
                ...initialState,
                form: action.payload?.form
                    ? action.payload?.form
                    : null,
                status: SelectedFormActionsEnum.FORM
            };

        case SelectedFormActionsEnum.FORM_TIMELINES:
            return {
                ...initialState,
                formTimelines: action.payload?.formTimelines
                    ? action.payload.formTimelines
                    : null,
                status: SelectedFormActionsEnum.FORM_TIMELINES
            }

        case SelectedFormActionsEnum.FORM_ENTRY:
            return {
                ...initialState,
                formEntry: action.payload?.formEntry
                    ? action.payload?.formEntry
                    : null,
                status: SelectedFormActionsEnum.FORM_ENTRY
            };
        case SelectedFormActionsEnum.FORM_FIELDS:
            return {
                ...initialState,
                formFields: action.payload?.formFields
                    ? action.payload?.formFields
                    : [],
                status: SelectedFormActionsEnum.FORM_FIELDS
            };

        case SelectedFormActionsEnum.PACS_FORM_ASSETS:
            return {
                ...initialState,
                pacsFormAssets: action.payload?.pacsFormAssets
                    ? action.payload?.pacsFormAssets
                    : [],
                status: SelectedFormActionsEnum.PACS_FORM_ASSETS
            };
        case SelectedFormActionsEnum.PREFILLED_DATA:
            return {
                ...initialState,
                pacsFormAssets: action.payload?.pacsFormAssets
                    ? action.payload?.pacsFormAssets
                    : [],
                formFields: action.payload?.formFields
                    ? action.payload?.formFields
                    : [],
                status: SelectedFormActionsEnum.PREFILLED_DATA
            };

        case SelectedFormActionsEnum.CLEAR:
            return {
                errorMessage: "",
                isLoading: false,
                formSettings: null,
                successMessage: null,
                form: null,
                formTimelines: null,
                formEntry: null,
                formFields: [],
                prefilledFormFields: [],
                pacsFormAssets: [],
                status: SelectedFormActionsEnum.CLEAR
            };
        case SelectedFormActionsEnum.ERROR:
            return {
                ...initialState,
                isLoading: false,
                errorMessage: action.error ? action.error : null,
                status: SelectedFormActionsEnum.ERROR
            };
        case SelectedFormActionsEnum.SUCCESS_MESSAGE:
            return {
                ...initialState,
                isLoading: false,
                successMessage: action.success ?? null,
                status: SelectedFormActionsEnum.SUCCESS_MESSAGE
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};