import {Input, InputChangeEvent} from "@progress/kendo-react-inputs";
import classes from "../custom-field.module.scss";
import {useEffect, useState} from "react";
import {Error} from "@progress/kendo-react-labels";
import {EmailInputErrorsHandler} from "./UpdateEmailErrorsState";
interface EmailInput {
    isReadOnly?: boolean,
    setFormDataFields: (form: any) => void,
    formDataFields?: any,
    fieldName: string,
    placeholder?: string,
    required: boolean,
    setIsValidField?: (item: boolean) => void;
    validField?: boolean;
}


export function EmailInput(props: EmailInput) :JSX.Element {
    const { isReadOnly, formDataFields, setFormDataFields, fieldName, 
        required, setIsValidField, validField, placeholder} = props;
    const [errors, setErrors] = useState<{required: boolean, pattern: boolean}>({
        pattern: required,
        required: required,
    });

    const changeValueHandler = (
        event: InputChangeEvent,
        field: string) => {

        EmailInputErrorsHandler({
            required: required,
            event: event,
            setErrors: setErrors,
            errors: errors
        })

        setFormDataFields({
            ...formDataFields,
            [field]: event.value
        });
    }

    useEffect(() => {
        if((errors.pattern || errors.required) && setIsValidField){
            setIsValidField(false);
        }  else if(!required && setIsValidField){
            setIsValidField(true);
        } else if (setIsValidField){
            setIsValidField(true);
        }
    }, [errors, formDataFields?.[fieldName]]);

    useEffect(() => {
        EmailInputErrorsHandler({
            required: required,
            event: {value: formDataFields?.[fieldName]} as any,
            setErrors: setErrors,
            errors: errors
        });
    }, [setErrors, formDataFields?.[fieldName]]);

    return <>

        <Input
            name={fieldName}
            placeholder={placeholder}
            type={"email"}
            required={required}
            className={!validField ? `${classes.input} ${classes.invalidInput}` : classes.input}
            disabled={isReadOnly}
            value={formDataFields?.[fieldName]}
            onChange={(e)=> changeValueHandler(e, fieldName)}
        />
        {formDataFields?.[fieldName] && errors.pattern && <Error>Please enter valid email!</Error>}
    </>
}
