import FadeLoader from "react-spinners/FadeLoader";
import classes from "./CustomLoader.module.scss";

export default function CustomLoader(): JSX.Element {
  return (
    <div className={classes.LoaderWrapper}>
      <FadeLoader height={5} width={9} radius="50%" margin={1} />
    </div>
  );
}
