import type { FieldRenderProps } from "@progress/kendo-react-form/dist/npm/interfaces/FieldRenderProps";
import { Input } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";

export default function ValidatedInput(
  fieldRenderProps: FieldRenderProps
): JSX.Element {
  const { validationMessage, visited, value, initValue, ...others } =
    fieldRenderProps;
  return (
    <>
      <Input value={initValue} {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </>
  );
}
