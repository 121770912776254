import {DateFormatOptions} from "@telerik/kendo-intl";

export interface IDatePickerErrorModel{
    required?: boolean,
    invalid?: boolean,
    maxDate?: boolean
}

export default function GetDatePickerFormatHelper(onlyYear: boolean): DateFormatOptions {
    return onlyYear ? { year:"numeric"} : {year:"numeric", month: "2-digit", day: "2-digit"}
}
