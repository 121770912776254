import type { AxiosError, AxiosResponse } from "axios";
import {getFormAssetsSettings, getForms} from "src/api/form-api";
import { FormActionsEnum } from "src/context/Form/form-actions";
import type { FormType } from "src/context/Form/form-reducer";
import type { GenericActionType } from "src/context/generic-actions.type";
import {setLoading, temporaryError} from "src/context/shared/shared-context.helper";
import type { IForm } from "src/interfaces/form.interface";
import {getPACSPersonalPropertyTypeCodes} from "../../api/form-entries-api";


export function onLoadForms(
  formDispatch: (value: GenericActionType<FormType>) => void
): void {
  formDispatch({ type: FormActionsEnum.IS_LOADING, isLoading: true });
  getForms()
    .then((response: AxiosResponse<IForm[]>) => {
      formDispatch({
        type: FormActionsEnum.FORMS,
        payload: {
          forms: response.data,
        },
      });
      formDispatch({ type: FormActionsEnum.IS_LOADING });
    })
    .catch((err: AxiosError) => {
      temporaryError(formDispatch, err.response?.data);
    });
}

export function getPACSPersonalPropertyTypeCodesHelper(
    adminFormDispatch: (value: GenericActionType<FormType>) => void
) {
    setLoading(adminFormDispatch, true);

    getPACSPersonalPropertyTypeCodes()
        .then((response: any) => {
            adminFormDispatch({
                type: FormActionsEnum.PERSONAL_PROPERTY_CODES,
                payload: {
                    personalPropertyCodes: response.data,
                },
            });

            setLoading(adminFormDispatch);
        })
        .catch((error: AxiosError) =>
            temporaryError(adminFormDispatch, error.response?.data)
        );
}

export function getFormAssetsSettingsHelper(
    adminFormDispatch: (value: GenericActionType<FormType>) => void,
    formId: number
) {
    setLoading(adminFormDispatch, true);

    getFormAssetsSettings(formId)
        .then((response: any) => {
            adminFormDispatch({
                type: FormActionsEnum.FORM_ASSETS_SETTINGS,
                payload: {
                    formAssetsSettings: response.data,
                },
            });

            setLoading(adminFormDispatch);
        })
        .catch((error: AxiosError) =>
            temporaryError(adminFormDispatch, error.response?.data)
        );
}

export function GetFormAssetsSettingsAndPACSPersonalPropertyTypeCodesHelper(
    adminFormDispatch: (value: GenericActionType<FormType>) => void,
    formId: number
){
    setLoading(adminFormDispatch, true);

    Promise.all([
        getFormAssetsSettings(formId),
        getPACSPersonalPropertyTypeCodes(),
    ]) .then((response) => {
        adminFormDispatch({
            type: FormActionsEnum.FORM_ASSETS_SETTINGS,
            payload: {
                formAssetsSettings: response[0].data,
            },
        });

        adminFormDispatch({
            type: FormActionsEnum.PERSONAL_PROPERTY_CODES,
            payload: {
                personalPropertyCodes: response[1].data,
            },
        });

        setLoading(adminFormDispatch);

    }).catch((error: AxiosError) => {
            temporaryError(adminFormDispatch, error.response?.data)
        }
    );
}
