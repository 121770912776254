import { IProvidingSupplementalFoodTypes,
    IFeedersAndMineralSupplementationPurposes,
    IManagingTamePastureTypes,
    ITransitionManagementOfTameGrassSpeciesPlanted } from "./providing-supplemental-food.interface-types";

export interface IWildlifeManagementFourthSectionSupplementalFood {
    SupplementalFoodTypes?: IProvidingSupplementalFoodTypes;
    FoodPlots?: boolean;
    FoodPlotsSize?: string;
    FoodPlotsFenced?: boolean;
    FoodPlotsPlantingsCoolSeasonAnnualCrops?: boolean;
    FoodPlotsPlantingsCoolSeasonAnnualCropsDescription?: string;
    FoodPlotsPlantingsWarmSeasonAnnualCrops?: boolean;
    FoodPlotsPlantingsWarmSeasonAnnualCropsDescription?: string;
    FoodPlotsPlantingsAnnualMixOfNativePlants?: boolean;
    FoodPlotsPlantingsAnnualMixOfNativePlantsDescription?: string;
    FoodPlotsPlantingsPerennialMixOfNativePlants?: boolean;
    FoodPlotsPlantingsPerennialMixOfNativePlantsDescription?: string;
    FoodPlotsAdditionalInformation?: string;
    Irrigated?: boolean;
    FeedersAndMineralSupplementation?: boolean;
    FeedersAndMineralSupplementationPurpose?: IFeedersAndMineralSupplementationPurposes;
    FeedersAndMineralSupplementationTargetedWildlifeSpecies?: string;
    FeedersAndMineralSupplementationFeedType?: string;
    FeedersAndMineralSupplementationFeederType?: string;
    FeedersAndMineralSupplementationMethodOfMineralDispencing?: string;
    FeedersAndMineralSupplementationMineralType?: string;
    FeedersAndMineralSupplementationNumberOfFeeders?: number;
    FeedersAndMineralSupplementationNumberOfMineralLocations?: number;
    FeedersAndMineralSupplementationYearRound?: boolean;
    FeedersAndMineralSupplementationStateWhen?: string;
    FeedersAndMineralSupplementationAdditionalInformation?: string;
    ManagingTamePasture?: boolean;
    ManagingTamePastureTypes?: IManagingTamePastureTypes;
    ManagingTamePastureAdditionalInformation?: string;
    TransitionManagementOfTameGrass?: boolean;
    TransitionManagementOfTameGrassOverseed25Percent?: boolean;
    TransitionManagementOfTameGrassSpeciesPlanted?: ITransitionManagementOfTameGrassSpeciesPlanted;
    TransitionManagementOfTameGrassSpeciesPlantedOther?: string;
    TransitionManagementOfTameGrassAdditionalInformation?: string;
}

export const WildlifeManagementFourthSectionSupplementalFoodInterface = (): IWildlifeManagementFourthSectionSupplementalFood => ({
    SupplementalFoodTypes: undefined,
    FoodPlots: undefined,
    FoodPlotsSize: undefined,
    FoodPlotsFenced: undefined,
    FoodPlotsPlantingsCoolSeasonAnnualCrops: undefined,
    FoodPlotsPlantingsCoolSeasonAnnualCropsDescription: undefined,
    FoodPlotsPlantingsWarmSeasonAnnualCrops: undefined,
    FoodPlotsPlantingsWarmSeasonAnnualCropsDescription: undefined,
    FoodPlotsPlantingsAnnualMixOfNativePlants: undefined,
    FoodPlotsPlantingsAnnualMixOfNativePlantsDescription: undefined,
    FoodPlotsPlantingsPerennialMixOfNativePlants: undefined,
    FoodPlotsPlantingsPerennialMixOfNativePlantsDescription: undefined,
    FoodPlotsAdditionalInformation: undefined,
    Irrigated: undefined,
    FeedersAndMineralSupplementation: undefined,
    FeedersAndMineralSupplementationPurpose: undefined,
    FeedersAndMineralSupplementationTargetedWildlifeSpecies: undefined,
    FeedersAndMineralSupplementationFeedType: undefined,
    FeedersAndMineralSupplementationFeederType: undefined,
    FeedersAndMineralSupplementationMethodOfMineralDispencing: undefined,
    FeedersAndMineralSupplementationMineralType: undefined,
    FeedersAndMineralSupplementationNumberOfFeeders: undefined,
    FeedersAndMineralSupplementationNumberOfMineralLocations: undefined,
    FeedersAndMineralSupplementationYearRound: undefined,
    FeedersAndMineralSupplementationStateWhen: undefined,
    FeedersAndMineralSupplementationAdditionalInformation: undefined,
    ManagingTamePasture: undefined,
    ManagingTamePastureTypes: undefined,
    ManagingTamePastureAdditionalInformation: undefined,
    TransitionManagementOfTameGrass: undefined,
    TransitionManagementOfTameGrassOverseed25Percent: undefined,
    TransitionManagementOfTameGrassSpeciesPlanted: undefined,
    TransitionManagementOfTameGrassSpeciesPlantedOther: undefined,
    TransitionManagementOfTameGrassAdditionalInformation: undefined
})