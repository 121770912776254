import {useEffect} from "react";
import {CustomDatePickerField} from "../../../../../Shared/DatePickers/CustomDatePickerField";

export interface IDateValueHandler<T>{
    setFormDataFields: (form: T) => void;
    formDataFields?: T,
    fieldName: string,
    disabled?: boolean,
    CustomCalendar?: any,
    onlyYear: boolean,
    required: boolean,
    defaultValueDate?: Date | string | null,
    secondName?: string,
    setIsValidField: (item: boolean) => void;
    validField: boolean,
    maxDate?: Date,
}

export function CustomDatePicker<T>(props: IDateValueHandler<T>): JSX.Element{
    const {
        setFormDataFields,
        formDataFields,
        fieldName,
        disabled,
        CustomCalendar,
        onlyYear,
        required,
        defaultValueDate,
        secondName,
        validField,
        setIsValidField,
        maxDate,
    } = props;

    useEffect(() => {
        return () => {
            setIsValidField(true);
        };
    }, []);

    function changeDateValueHandler(
        event: any) {
        if(onlyYear && Date.parse(event)){
            setFormDataFields({
                ...formDataFields,
                [fieldName]: event ? event?.getFullYear()?.toString() : null
            } as T);
        }  else if (Date.parse(event)){

            setFormDataFields({
                ...formDataFields,
                [fieldName]: Date.parse(event) ?  event?.toLocaleString() : event?.toString()
            } as T);
        }
    }

    return  <>
        <CustomDatePickerField
            fieldName={fieldName}
            required={required}
            setIsValidField={setIsValidField}
            onlyYear={onlyYear}
            onChange={changeDateValueHandler}
            disabled={disabled}
            CustomCalendar={CustomCalendar}
            maxDate={maxDate}
            secondName={secondName}
            initialValue={defaultValueDate?.toString()}
        />
    </>
}

