import type { Dispatch } from "react";
import type { GenericActionType } from "src/context/generic-actions.type";
import type { IUser } from "src/interfaces/admin/user.interface";
import type { IAlert } from "src/interfaces/alert.interface";
import { IUnreadDialogCount } from "src/interfaces/messages/dialogs/unread-dialog-count.interface";
import { SharedActionsEnum } from "./shared-actions";

export interface SharedContextType {
  sharedState: InitialSharedStateType;
  sharedDispatch: Dispatch<GenericActionType<SharedType>>;
}

export interface InitialSharedStateType {
  errorMessage: string | null;
  isLoading: boolean;
  alerts: IAlert[] | [];
  successMessage: string | null;
  user: IUser | null;
  dialogCounts: IUnreadDialogCount | null;
}

export interface SharedType {
  errorMessage?: string;
  isLoading?: boolean;
  alerts?: IAlert[];
  successMessage?: string;
  user?: IUser;
  dialogCounts?: IUnreadDialogCount;
}

export const initialState: InitialSharedStateType = {
  errorMessage: "",
  isLoading: false,
  alerts: [],
  successMessage: null,
  user: null,
  dialogCounts: null,
};

export const SharedReducer = (
  initialState: InitialSharedStateType,
  action: GenericActionType<SharedType>
): InitialSharedStateType => {
  switch (action.type) {
    case SharedActionsEnum.IS_LOADING:
      return {
        ...initialState,
        isLoading: action.isLoading ?? false,
      };
    case SharedActionsEnum.ALERTS:
      return {
        ...initialState,
        alerts: action.payload?.alerts ?? [],
      };
    case SharedActionsEnum.SUCCESS_MESSAGE:
      return {
        ...initialState,
        successMessage: action.success ?? null,
      };
    case SharedActionsEnum.USER_DATA: {
      return {
        ...initialState,
        user: action.payload?.user ?? null,
      };
    }
    case SharedActionsEnum.DIALOG_COUNTS:
      return {
        ...initialState,
        dialogCounts: action.payload?.dialogCounts ?? null,
      };
    case SharedActionsEnum.ERROR:
      return {
        ...initialState,
        isLoading: false,
        errorMessage: action.error ?? null,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
