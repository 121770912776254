import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import React, {useEffect, useRef, useState} from "react";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./AgForm.module.scss";
import {CalendarChangeEvent, DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {Error, Label} from "@progress/kendo-react-labels";
import {
    Checkbox,
    CheckboxChangeEvent,
    Input,
    InputChangeEvent,
    RadioGroupChangeEvent,
    TextAreaChangeEvent
} from "@progress/kendo-react-inputs";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import {IAgFormSecondSectionInterface} from "../../../../../interfaces/forms/AgForm/ag-form-second-section.interface";
import {FormValidation} from "../InputValidator";
import {PhoneNumberInput} from "../CustomInputs/PhoneNumberInputs/PhoneNumberInput";
import {EmailInput} from "../CustomInputs/EmailInputs/EmailInput";
import {CustomDatePicker} from "../CustomInputs/CustomDatePickers/CustomDatePicker";
import {StatesInput} from "../CustomInputs/StatesInput";
import {ZipCodeInput} from "../CustomInputs/ZipCodeInput";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IAgFormSecondSectionInterface) => void;
    formDataFields?:  IAgFormSecondSectionInterface,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function AgFormSecondSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, isAdminPreview, setFormIsValid } = props;

    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} name={"Section 1: Property Owner/Applicant"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string
    ) {
        if (field == "PropertyOwnerBirthDate"){
            setFormDataFields({
                ...formDataFields,
                [field]: event.value ? event.value.toString() : undefined
            });
        }  else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    const saveAsPhysicalAddressHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        setFormDataFields({
            ...formDataFields,
            PropertyOwnerMailingAddressLine1: formDataFields?.PropertyOwnerPhysicalAddressLine1,
            PropertyOwnerMailingAddressLine2: formDataFields?.PropertyOwnerPhysicalAddressLine2,
            PropertyOwnerMailingAddressCity: formDataFields?.PropertyOwnerPhysicalAddressCity,
            PropertyOwnerMailingAddressState: formDataFields?.PropertyOwnerPhysicalAddressState,
            PropertyOwnerMailingAddressZip: formDataFields?.PropertyOwnerPhysicalAddressZip,
        });
    }

    function changeValidator() {
        if(formRef.current) {
            const isValid = formRef.current.checkValidity() &&
                phoneNumberInputValid &&
                emailInputValid &&
                birthDateInputValid &&
                physicalStateInputValid &&
                mailingStateInputValid &&
                physicalZipCode &&
                mailingZipCode;
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if(!isFormTouched){
            setIsFormTouched(true);
        }
    }

    const [birthDateInputValid, setBirthDateInputValid] = useState<boolean>(false);
    const [emailInputValid, setEmailInputValid] = useState<boolean>(false);
    const [phoneNumberInputValid, setPhoneNumberInputValid] = useState<boolean>(false);
    const [physicalStateInputValid, setPhysicalStateInputValid] = useState<boolean>(false);
    const [mailingStateInputValid, setMailingStateInputValid] = useState<boolean>(false);
    const [physicalZipCode, setPhysicalZipCode] = useState<boolean>(false);
    const [mailingZipCode, setMailingZipCode] = useState<boolean>(false);
    const { validationMessage, inputValidator } = FormValidation(formRef);

    useEffect(() => {
        changeValidator()
    }, [
        formDataFields,
        formRef.current,
        phoneNumberInputValid,
        emailInputValid,
        birthDateInputValid,
        physicalStateInputValid,
        mailingStateInputValid,
        physicalZipCode,
        mailingZipCode
    ]);

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroup}>
                                    <Label className={classes.Label}>
                                        The applicant is the following type of property owner:
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            name={"individual"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.individual}
                                            label={"Individual"}
                                            onChange={(e)=> changeValueHandler(e, "individual")}
                                        />
                                        <Checkbox
                                            name={"corporation"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.corporation}
                                            label={"Corporation"}
                                            onChange={(e)=> changeValueHandler(e, "corporation")}

                                        />
                                        <Checkbox
                                            name={"partnership"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.partnership}
                                            label={"Partnership"}
                                            onChange={(e)=> changeValueHandler(e, "partnership")}
                                        />
                                        <Checkbox
                                            name={"other"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.other}
                                            label={"Other"}
                                            onChange={(e)=> changeValueHandler(e, "other")}
                                        />
                                        {formDataFields?.other &&
                                            <div className="inputsGroup">
                                                <Label className={classes.Label}>
                                                    Other (specify):
                                                </Label>
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"OwnershipTypeOther"}
                                                    required={formDataFields?.other}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.OwnershipTypeOther}
                                                    onChange={(e)=> changeValueHandler(e, "OwnershipTypeOther")}
                                                />
                                                {!inputValidator("OwnershipTypeOther") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div> }
                                    </div>
                                </div>
                            </div>
                            <div className={classes.itemFields}>
                                <div className={`${classes.fieldsGroup} ${classes.fieldsGroupW49}`}>
                                    <Label className={classes.Label}>
                                        Name of Property Owner
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    </Label>
                                    <div className="inputsGroup">
                                        <Input
                                            className={classes.kTextbox}
                                            name={"PropertyOwnerName"}
                                            required={true}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.PropertyOwnerName}
                                            onChange={(e)=> changeValueHandler(e, "PropertyOwnerName")}
                                        />
                                        {!inputValidator("PropertyOwnerName") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                </div>
                                <div className={`${classes.fieldsGroup} ${classes.fieldsGroupW49}`}>
                                    <Label className={classes.Label}>
                                        Email Address
                                    </Label>
                                    <div className="inputsGroup">
                                        <EmailInput
                                            required={false}
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"PropertyOwnerEmailAddress"}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                            setIsValidField={setEmailInputValid}
                                            validField={emailInputValid}
                                        />
                                    </div>
                                </div>
                                <div className={`${classes.fieldsGroup} ${classes.fieldsGroupW49}`}>
                                    <Label className={classes.Label}>
                                        Phone Number
                                    </Label>
                                    <div className={`${classes.fieldsGroup} `}>
                                        <PhoneNumberInput
                                            required={false}
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"PropertyOwnerPhoneNumber"}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                            setIsValidField={setPhoneNumberInputValid}
                                            validField={phoneNumberInputValid}
                                        />
                                    </div>
                                </div>
                                <div className={`${classes.fieldsGroup} ${classes.fieldsGroupW49}`}>
                                    <Label className={classes.Label}>
                                        Provide date of birth if property owner is an individual (Failure to provide date of birth does not affect eligibility for special appraisal.)
                                    </Label>
                                    <div className="inputsGroup">
                                        <CustomDatePicker
                                            fieldName={"PropertyOwnerBirthDate"}
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            formDataFields={formDataFields}
                                            setFormDataFields={setFormDataFields}
                                            onlyYear={false}
                                            setIsValidField={setBirthDateInputValid}
                                            validField={birthDateInputValid}
                                            defaultValueDate={formDataFields?.PropertyOwnerBirthDate}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.addressSection}>
                                <div className={classes.item}>
                                    <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                        <p>Physical Address</p>
                                    </div>
                                    <div className={classes.itemFields}>
                                        <div className={`${classes.fieldsGroupW100}`}>
                                            <Label className={classes.Label}>
                                                Address Line 1
                                            </Label>
                                            <div className="inputsGroup">
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"PropertyOwnerPhysicalAddressLine1"}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.PropertyOwnerPhysicalAddressLine1}
                                                    onChange={(e)=> changeValueHandler(e, "PropertyOwnerPhysicalAddressLine1")}
                                                />
                                                {!inputValidator("PropertyOwnerPhysicalAddressLine1") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.itemFields}>
                                        <div className={`${classes.fieldsGroupW100}`}>
                                            <Label className={classes.Label}>
                                               Address Line 2
                                            </Label>
                                            <div className="inputsGroup">
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"PropertyOwnerPhysicalAddressLine2"}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.PropertyOwnerPhysicalAddressLine2}
                                                    onChange={(e)=> changeValueHandler(e, "PropertyOwnerPhysicalAddressLine2")}
                                                />
                                                {!inputValidator("PropertyOwnerPhysicalAddressLine2") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.itemFields}>
                                        <div className={`${classes.fieldsGroupW30}`}>
                                            <Label className={classes.Label}>
                                                City
                                            </Label>
                                            <div className="inputsGroup">
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"PropertyOwnerPhysicalAddressCity"}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.PropertyOwnerPhysicalAddressCity}
                                                    onChange={(e)=> changeValueHandler(e, "PropertyOwnerPhysicalAddressCity")}
                                                />
                                                {!inputValidator("PropertyOwnerPhysicalAddressCity") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                        </div>
                                        <div className={`${classes.fieldsGroupW30}`}>
                                            <Label className={classes.Label}>
                                                State
                                            </Label>
                                            <div className="inputsGroup">
                                                <StatesInput
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"PropertyOwnerPhysicalAddressState"}
                                                    required={false}
                                                    validField={physicalStateInputValid}
                                                    setIsValidField={setPhysicalStateInputValid}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                />
                                            </div>
                                        </div>
                                        <div className={`${classes.fieldsGroupW30}`}>
                                            <Label className={classes.Label}>
                                                Zip Code
                                            </Label>
                                            <div className="inputsGroup">
                                                <ZipCodeInput
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"PropertyOwnerPhysicalAddressZip"}
                                                    required={false}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                    setIsValidField={setPhysicalZipCode}
                                                    validField={physicalZipCode}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.item}>
                                    <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                        <p>Mailing Address</p>
                                        { !formSettings?.isReadOnly && !isAdminPreview &&
                                            <button className={`${classes.button} ${classes.buttonAddress}`} onClick={e => saveAsPhysicalAddressHandler(e)}>
                                                Copy Physical Address
                                            </button> }
                                    </div>
                                    <div className={classes.itemFields}>
                                        <div className={`${classes.fieldsGroupW100}`}>
                                            <Label className={classes.Label}>
                                                Address Line 1
                                            </Label>
                                            <div className="inputsGroup">
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"PropertyOwnerMailingAddressLine1"}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.PropertyOwnerMailingAddressLine1}
                                                    onChange={(e)=> changeValueHandler(e, "PropertyOwnerMailingAddressLine1")}
                                                />
                                                {!inputValidator("PropertyOwnerMailingAddressLine1") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.itemFields}>
                                        <div className={`${classes.fieldsGroupW100}`}>
                                            <Label className={classes.Label}>
                                                Address Line 2
                                            </Label>
                                            <div className="inputsGroup">
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"PropertyOwnerMailingAddressLine2"}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.PropertyOwnerMailingAddressLine2}
                                                    onChange={(e)=> changeValueHandler(e, "PropertyOwnerMailingAddressLine2")}
                                                />
                                                {!inputValidator("PropertyOwnerMailingAddressLine2") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.itemFields}>
                                        <div className={`${classes.fieldsGroupW30}`}>
                                            <Label className={classes.Label}>
                                                City
                                            </Label>
                                            <div className="inputsGroup">
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"PropertyOwnerMailingAddressCity"}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.PropertyOwnerMailingAddressCity}
                                                    onChange={(e)=> changeValueHandler(e, "PropertyOwnerMailingAddressCity")}
                                                />
                                                {!inputValidator("PropertyOwnerMailingAddressCity") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                        </div>
                                        <div className={`${classes.fieldsGroupW30}`}>
                                            <Label className={classes.Label}>
                                                State
                                            </Label>
                                            <div className="inputsGroup">
                                                <StatesInput
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"PropertyOwnerMailingAddressState"}
                                                    required={false}
                                                    validField={mailingStateInputValid}
                                                    setIsValidField={setMailingStateInputValid}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                />
                                            </div>
                                        </div>
                                        <div className={`${classes.fieldsGroupW30}`}>
                                            <Label className={classes.Label}>
                                                Zip Code
                                            </Label>
                                            <div className="inputsGroup">
                                                <ZipCodeInput
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"PropertyOwnerMailingAddressZip"}
                                                    required={false}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                    setIsValidField={setMailingZipCode}
                                                    validField={mailingZipCode}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
