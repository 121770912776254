import {useParams} from "react-router-dom";
import HomesteadExemptionForm from "../Forms/HomesteadExemptionForm/HomesteadExemptionForm";

interface FormEntry {
    formEntryId?: number;
    isAdminPreview?: boolean;
    formId?: number;
}
export default function HomesteadExemptionFormEntry(props: FormEntry): JSX.Element {
    const { isAdminPreview, formEntryId, formId } = props;
    const { formEntryIdParams } = useParams();

    return <>
        <HomesteadExemptionForm
            formId={formId}
            isAdminPreview={isAdminPreview}
            formEntryId={formEntryIdParams ? parseInt(formEntryIdParams) : formEntryId}/>
    </>
}
