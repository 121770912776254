export const typeOfAuthorizedRepresentativeRadioButtonData = [
    { label: "Officer of the company", value: "OfficerOfTheCompany" },
    { label: "General Partner of the company", value: "GeneralPartnerOfTheCompany" },
    { label: "Attorney for the company", value: "AttorneyForTheCompany" },
    { label: "Agent for tax matters appointed under Tax Code Section 1.111 with completed and signed Form 50-162", value: "Agent" },
    { label: "Other and explain basis", value: "Other" }
];

export const defaultRadioButtonData = [
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
]