import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBox, ComboBoxChangeEvent, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import {
    CheckboxChangeEvent,
    Input,
    InputChangeEvent, NumericTextBox, NumericTextBoxChangeEvent,
    RadioGroupChangeEvent,
    TextAreaChangeEvent,
} from "@progress/kendo-react-inputs";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import {
    IAgFormSixthSectionInterface, IAgriculturalUseCategoriesOfLand
} from "../../../../../../interfaces/forms/AgForm/ag-form-sixth-section.interface";
import classes from "./AgriculturalUseCategoriesOfLandTable.module.scss";
import { CustomRowDatePicker } from "../../CustomInputs/CustomDatePickers/CustomRowDatePicker";
import { IRowState } from "../../CustomInputs/UpdateRowErrorStateHandler";
import {
    ITimberlandAppraisalFormFifthSection
} from "src/interfaces/forms/TimberlandAppraisalForm/timberland-appraisal-form-fifth-section.interface";
import { CustomOnlyYearCalendarHelper } from "src/pages/Shared/DatePickers/CustomCalendars";
import { FormValidation } from "../../InputValidator";
import { Error } from "@progress/kendo-react-labels";

interface GeneralFormType {
    formSettings: IFormSettingModel | undefined;
    items: IAgriculturalUseCategoriesOfLand[];
    onChange: (items: IAgriculturalUseCategoriesOfLand[]) => void;
    errorsArray: Array<IRowState>;
    setErrorsArray: (item: Array<IRowState>) => void;
}

export function AgriculturalUseCategoriesOfLandTable(
    props: GeneralFormType
): JSX.Element {
    const { CustomOnlyYearCalendar } = CustomOnlyYearCalendarHelper();

    const CategoriesList = [
        "Irrigated Cropland",
        "Dry Cropland",
        "Improved Pastureland",
        "Native Pastureland",
        "Orchard",
        "Wasteland",
        "Timber Production",
        "Wildlife Management",
        "Other",
        "Beekeeping",
        "CRP"
    ].sort((a, b) => a.localeCompare(b));

    const showOtherCategory = props.items.some(item => item.Category === 'Other');

    function getDateValue(item: IAgriculturalUseCategoriesOfLand): any {
        if (item.Year) {
            const date = new Date().setFullYear(item.Year);
            return item
                ? new Date(date)
                : null;
        }
    }

    function handleChange(item: IAgriculturalUseCategoriesOfLand) {
        const newItems = [ ...props.items ];
        const index = newItems.findIndex(i => i.Id === item.Id);
        newItems[index] = item;
        props.onChange(newItems);
    }

    return (
        <table className="blue">
            <thead>
            <tr>
                <th className="step">
                    Year
                </th>
                <th>
                    Agricultural Use Category of Land (list all that apply)
                </th>
                {showOtherCategory && <th>Other Category</th>}
                <th>Acres Principally Devoted to Agricultural Use</th>
            </tr>
            </thead>
            <tbody>
            {props.items.map((item) => {
                return (
                    <tr key={item.Id}>
                        <th>
                            <CustomRowDatePicker
                                fieldName={"Year"}
                                onlyYear={true}
                                CustomCalendar={CustomOnlyYearCalendar}
                                required={false}
                                group={"AgriculturalUseCategoriesOfLand"}
                                id={item.Id}
                                errorsArray={props.errorsArray}
                                setErrorsArray={props.setErrorsArray}
                                defaultValueDate={getDateValue(item)}
                                disabled={props.formSettings?.isReadOnly}
                                customChangeEventHandler={(e) => {
                                    handleChange({ ...item, Year: e.value?.getFullYear() })
                                }}
                            />
                        </th>
                        <td>
                            <ComboBox
                                className={classes.kTextbox}
                                disabled={props.formSettings?.isReadOnly}
                                data={CategoriesList}
                                value={item.Category}
                                onChange={(e) => {
                                    const newItem = { ...item, Category: e.value };
                                    if (item.Category === 'Other' && e.value != item.Category) {
                                        newItem.OtherCategory = undefined; // clear custom category
                                    }
                                    handleChange(newItem);
                                }}
                            />
                        </td>
                        {showOtherCategory && (
                            <td>
                                <OtherCategoryInput
                                    item={item}
                                    disabled={props.formSettings?.isReadOnly}
                                    onChange={(value) => handleChange({ ...item, OtherCategory: value })}
                                />
                            </td>
                        )}
                        <td>
                            <NumericTextBox
                                className={classes.kTextbox}
                                spinners={false}
                                disabled={props.formSettings?.isReadOnly}
                                value={item.Acres}
                                onChange={(e) =>
                                    handleChange({ ...item, Acres: e.value ?? undefined })
                                }
                            />
                        </td>
                    </tr>
                );
            })}
            </tbody>
        </table>
    );
}

const OtherCategoryInput = (props: {
    item: IAgriculturalUseCategoriesOfLand,
    disabled?: boolean,
    onChange: (item: string) => void
}) => {
    const { item, disabled, onChange } = props;
    const isOther = item.Category === 'Other';

    return (
        <>
            <Input
                className={`${!isOther ? classes.GreyedOut : ''} ${classes.kTextbox}`}
                required={isOther}
                disabled={disabled || !isOther}
                value={item.OtherCategory}
                onChange={(e) => onChange(e.value)}
            />
            {(isOther && !item.OtherCategory) && <Error>This field is required</Error>}
        </>
    )
}
