import AuthService from "./services/authentication.service";

export function checkLogin(authService: AuthService, data: any, token: string) {
  fetch(data.apiRoot + "auth/check-login", {
    method: "get",
    cache: "no-store",
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      authorization: `Bearer ${token}`,
    },
  })
    .then((response: any) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then(async (data: { isLogged: boolean }) => {
      if (data?.isLogged === false) {
        await authService.logout();

        await authService.login(buildReturnUrl());
      }
    });
}

export function buildReturnUrl(): string {
  const location = window.location;
  const url = `${location.protocol}//${location.host}`;
  const encodedUrl = encodeURIComponent(url);

  return encodedUrl.includes("?")
    ? `${url}&forceReturn=true`
    : `${url}?forceReturn=true`;
}
