import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import {useEffect, useRef, useState} from "react";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./DisabledVetForm.module.scss";
import { IDisabledVetSeventhSection } from "src/interfaces/forms/DisableVetForm/disable-vet-form-seventh-section";
import { CustomUpload } from "../CustomInputs/CustomUpload";
import { UploadFileInfo } from "@progress/kendo-react-upload";
import { IFormEntryModel } from "src/interfaces/form-entry.interface";
import { GenericActionType } from "src/context/generic-actions.type";
import { SelectedFormType } from "src/context/SelectedForm/selected-form-reducer";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IDisabledVetSeventhSection) => void;
    formDataFields?:  IDisabledVetSeventhSection,
    formEntry?: IFormEntryModel,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void,
    selectedFormDispatch: (value: GenericActionType<SelectedFormType>) => void,
}

export default function DisabledVetSeventhSection(props: GeneralFormType): JSX.Element {
    const {setFormDataFields, formDataFields, formSettings, formEntry, isAdminPreview, setFormIsValid, selectedFormDispatch} = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={7} name={"Additional Documentation"}/>;
    const [isAdditionalDocumentsFilesUploadValid,setIsAdditionalDocumentsFilesUploadValid] = useState<boolean>(true);
    const [additionalDocumentsFiles, setAdditionalDocumentsFiles] = useState<Array<UploadFileInfo>>([]);

    useEffect(() => {
        return () => {
            setAdditionalDocumentsFiles([]);
        }
    }, []);

    function changeValidator() {
        if (formRef.current) {
            const isValid = isAdditionalDocumentsFilesUploadValid &&
                formRef.current.checkValidity();
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch() {
        if (!isFormTouched) {
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current, isAdditionalDocumentsFilesUploadValid]);

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.information}>
                            <div>
                                <p>
                                    If any additional documentation is required by the appraisal 
                                    district, please upload that documentation here.
                                </p>
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={`${classes.itemFields}`}>
                                <div className={`${classes.fieldsGroupW100}`}>
                                    <div className="inputsGroup">
                                        <CustomUpload
                                            files={additionalDocumentsFiles}
                                            setFiles={setAdditionalDocumentsFiles}
                                            uploadIsValid={isAdditionalDocumentsFilesUploadValid}
                                            setUploadIsValid={setIsAdditionalDocumentsFilesUploadValid}
                                            formEntry={formEntry}
                                            formSettings={formSettings}
                                            isAdminPreview={isAdminPreview}
                                            setFormDataFields={setFormDataFields}
                                            formDataFields={formDataFields}
                                            fieldName={"AdditionalDocumentation"}
                                            isRequired={false}
                                            selectedFormDispatch={selectedFormDispatch}
                                            multiple={true}
                                            isLabelRequired={true}
                                            labelText={""} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
