import { createContext, useContext, useReducer } from "react";
import type { SharedContextType } from "./shared-reducer";
import { initialState, SharedReducer } from "./shared-reducer";

const SharedStateContext = createContext<SharedContextType>({
  sharedState: initialState,
  sharedDispatch: () => null,
});

export function useSharedContext(): SharedContextType {
  const context = useContext(SharedStateContext);

  if (context === undefined) {
    throw new Error("useSharedContext must be used within a SharedProvider");
  }

  return context;
}

export const SharedProvider = ({
  children
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [sharedState, sharedDispatch] = useReducer(SharedReducer, initialState);

  return (
    <SharedStateContext.Provider value={{ sharedState, sharedDispatch }}>
      {children as React.ReactNode}
    </SharedStateContext.Provider>
  );
};
