import { Dispatch } from "react";
import { GenericActionType } from "src/context/generic-actions.type";
import { IGroupWithPermission } from "src/interfaces/admin/group-with-permission.interface";
import { IPermissionsWithSelects } from "src/interfaces/admin/permission.interface";
import { ISelect } from "src/interfaces/admin/select.interface";
import { IUserGroupSelect } from "src/interfaces/admin/user-group-select.interface";
import { IUserWithPermission } from "src/interfaces/admin/user-with-permission.interface";
import { AdminPermissionActionsEnum } from "./permission-actions";

export interface ContextType {
  applicationState: InitialStateType;
  adminPermissionDispatch: Dispatch<GenericActionType<Type>>;
}

interface InitialStateType {
  selectedType: ISelect | null;
  selectedPermissionTypeIds: number[];
  selectedId: number | null;
  users: IUserWithPermission[];
  groups: IGroupWithPermission[];
  formGroups: IGroupWithPermission[];
  permissionTypes: IPermissionsWithSelects[];
  errorMessage: string | null;
  isLoading: boolean;
  userGroupSelectList: IUserGroupSelect[];
  initUserGroupSelectList: IUserGroupSelect[];
  category: string;
  successMessage: string | null;
}

export interface Type {
  selectedType?: ISelect | null;
  selectedPermissionTypeIds?: number[];
  selectedId?: number;
  users?: IUserWithPermission[];
  groups?: IGroupWithPermission[];
  formGroups?: IGroupWithPermission[];
  permissionTypes?: IPermissionsWithSelects[];
  errorMessage?: string;
  isLoading?: boolean;
  userGroupSelectList?: IUserGroupSelect[];
  initUserGroupSelectList?: IUserGroupSelect[];
  category?: string;
  successMessage?: string;
}

export const initialState: InitialStateType = {
  selectedType: null,
  selectedPermissionTypeIds: [],
  selectedId: null,
  users: [],
  groups: [],
  formGroups: [],
  permissionTypes: [],
  errorMessage: "",
  isLoading: false,
  userGroupSelectList: [],
  initUserGroupSelectList: [],
  category: "",
  successMessage: null,
};

export const AdminPermissionReducer = (
  initialState: InitialStateType,
  action: GenericActionType<Type>
): InitialStateType => {
  switch (action.type) {
    case AdminPermissionActionsEnum.IS_LOADING:
      return {
        ...initialState,
        isLoading: action.isLoading ? action.isLoading : false,
      };
    case AdminPermissionActionsEnum.SELECT_USER_OR_GROUP:
      return {
        ...initialState,
        selectedType: action.payload?.selectedType as ISelect,
        selectedPermissionTypeIds: action.payload
          ?.selectedPermissionTypeIds as number[],
        selectedId: action.payload?.selectedId ?? null,
      };
    case AdminPermissionActionsEnum.REMOVE_SELECTED_GROUP:
      return {
        ...initialState,
        selectedId: null,
        selectedType: null,
        selectedPermissionTypeIds: [],
      };
    case AdminPermissionActionsEnum.REMOVE_SELECTED_USER:
      return {
        ...initialState,
        selectedId: null,
        selectedType: null,
        selectedPermissionTypeIds: [],
      };
    case AdminPermissionActionsEnum.SET_USERS:
      return {
        ...initialState,
        users: action.payload?.users as IUserWithPermission[],
      };
    case AdminPermissionActionsEnum.SET_GROUPS:
      return {
        ...initialState,
        groups: action.payload?.groups as IGroupWithPermission[],
      };
    case AdminPermissionActionsEnum.SET_FORM_GROUPS:
      return {
        ...initialState,
        formGroups: action.payload?.formGroups as IGroupWithPermission[],
      };
    case AdminPermissionActionsEnum.SELECTED_ID:
      return {
        ...initialState,
        selectedId: action.payload?.selectedId ?? null,
      };
    case AdminPermissionActionsEnum.SET_PERMISSIONS:
      return {
        ...initialState,
        permissionTypes: action.payload
          ?.permissionTypes as IPermissionsWithSelects[],
      };
    case AdminPermissionActionsEnum.UPDATE_SELECTED_PERMISSION_IDS:
      return {
        ...initialState,
        selectedPermissionTypeIds: action.payload
          ?.selectedPermissionTypeIds as number[],
      };

    case AdminPermissionActionsEnum.GET_USER_GROUP_SELECT_LIST:
      return {
        ...initialState,
        userGroupSelectList: action.payload?.userGroupSelectList
          ? action.payload.userGroupSelectList
          : [],
      };

    case AdminPermissionActionsEnum.GET_INIT_USER_GROUP_SELECT_LIST:
      return {
        ...initialState,
        initUserGroupSelectList: action.payload?.initUserGroupSelectList
          ? action.payload.initUserGroupSelectList
          : [],
      };
    case AdminPermissionActionsEnum.CATEGORY:
      return {
        ...initialState,
        category: action.payload?.category ?? "",
      };
    case AdminPermissionActionsEnum.ERROR:
      return {
        ...initialState,
        isLoading: false,
        errorMessage: action.error ? action.error : null,
      };
    case AdminPermissionActionsEnum.SUCCESS_MESSAGE:
      return {
        ...initialState,
        isLoading: false,
        successMessage: action.success ?? null,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
