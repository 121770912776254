export interface IRowState{
    id?: number,
    state?: boolean
}

export interface IUpdateErrorsStateType{
    valid: boolean,
    setIsInputValid: (item: boolean) => void,
    setErrorsArray: (item: Array<IRowState>) => void,
    errorsArray: Array<IRowState>,
    id: number
}

export function UpdateErrorsState(props: IUpdateErrorsStateType) {
    const { valid, setIsInputValid, setErrorsArray, errorsArray, id} = props;

    if(valid){
        setIsInputValid(true);
        setErrorsArray([...errorsArray.filter(x=>x.id != id)]);
    } else {
        setIsInputValid(false);
        const index = errorsArray.findIndex(x=>x.id == id);
        if(index >= 0){
            errorsArray[index].state = false;
            setErrorsArray([...errorsArray]);
        } else {
            setErrorsArray([...errorsArray, { id: id, state: false }]);
        }
    }
}
