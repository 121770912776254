import { Calendar, DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import {
    CheckboxChangeEvent,
    Input,
    InputChangeEvent, NumericTextBox, NumericTextBoxChangeEvent,
    RadioGroupChangeEvent,
    TextAreaChangeEvent,
} from "@progress/kendo-react-inputs";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { IBppFormEleventhSectionInterface } from "src/interfaces/forms/BPPForm/bpp-form-eleventh-section.interface";
import classes from "./../BPPForm.module.scss";
import { IFormEntryModel } from "../../../../../../interfaces/form-entry.interface";
import {CustomRowDatePicker} from "../../CustomInputs/CustomDatePickers/CustomRowDatePicker";
import {IRowState} from "../../CustomInputs/UpdateRowErrorStateHandler";
import {NumberFormatOptions} from "@telerik/kendo-intl";
import {CustomOnlyYearCalendarHelper} from "../../../../../Shared/DatePickers/CustomCalendars";

interface GeneralFormType {
  formDataFields: IBppFormEleventhSectionInterface | undefined;
  formSettings: IFormSettingModel | undefined;
  group: "ScheduleE-FurnitureFixtures" | "ScheduleE-MachineryEquipment" |
         "ScheduleE-OfficeEquipment" | "ScheduleE-ComputerEquipment" |
         "ScheduleE-ManufacturingEquipment" | "ScheduleE-Other";
  name: string;
  changeValueHandler: (
    event:
      | CheckboxChangeEvent
      | DatePickerChangeEvent
      | TextAreaChangeEvent
      | DropDownListChangeEvent
      | InputChangeEvent
      | RadioGroupChangeEvent
      | NumericTextBoxChangeEvent,
    field: string,
    group: string,
    id: number
  ) => void;
  formEntry?: IFormEntryModel;
  setFormDataFields: (item: IBppFormEleventhSectionInterface) => void;
  errorsArray: Array<IRowState>;
  setErrorsArray: (item: Array<IRowState>) => void
}

const maxScheduleYearDate = new Date(new Date().getFullYear(), 1,1);

export function ScheduleETable(
  props: GeneralFormType
): JSX.Element {
    const currencyFormatOptions: NumberFormatOptions = { maximumFractionDigits: 0 };
    const { CustomOnlyYearCalendar } = CustomOnlyYearCalendarHelper(maxScheduleYearDate.getFullYear() - 1);

    function getDateValue(id: number): Date | null {
        const item = props?.formDataFields?.[props.group]?.find(x => x.Id == id)?.YearAcquired;

        if(item?.length === 4) {
            return new Date(+item,2,2);
        }

        return null;
    }

    return (
        <table className="blue">
            <thead>
                <tr>
                <th colSpan={5} className="step">
                    { props.name }
                </th>
                </tr>
                <tr>
                <th style={{ width: "15%" }}>Year Acquired</th>
                <th style={{ width: "25%" }}>
                    Historical Cost When New
                    <br />
                    (Omit Cents)
                </th>
                <th>or</th>
                <th style={{ width: "25%" }}>
                    Good Faith Estimate of Market Value
                </th>
                <th style={{ width: "30%" }}>
                    Description
                </th>
                </tr>
            </thead>
            {props.formDataFields?.[props.group]?.filter(x=> !x.HasActiveSubSegments).map(
            (item) => {
                return (
                <tr key={item.Id}>
                    <th className={classes.width20}>
                        <CustomRowDatePicker
                            setFormDataFields={props.setFormDataFields}
                            fieldName={"YearAcquired"}
                            onlyYear={true}
                            required={false}
                            group={props.group}
                            id={item.Id!}
                            errorsArray={props.errorsArray}
                            setErrorsArray={props.setErrorsArray}
                            formDataFields={props.formDataFields}
                            defaultValueDate={getDateValue(item.Id!)}
                            disabled={props.formSettings?.isReadOnly || props.formEntry?.initiatedBy === 'PropertySearch'}
                            CustomCalendar={CustomOnlyYearCalendar}
                            rowItem={item.YearAcquired}
                            maxDate={maxScheduleYearDate}
                        />
                    </th>
                    <td>
                        <NumericTextBox
                            className={classes.TableInput}
                            disabled={props.formSettings?.isReadOnly || props.formEntry?.initiatedBy === 'PropertySearch'}
                            value={item.HistoricCost}
                            onChange={(e) =>
                                props.changeValueHandler(
                                    e,
                                    "HistoricCost",
                                    props.group,
                                    item.Id!
                                )
                            }
                            format={currencyFormatOptions}
                            spinners={false}
                        />
                    </td>
                    <td></td>
                    <td>
                        <NumericTextBox
                            className={classes.TableInput}
                            disabled={props.formSettings?.isReadOnly || props.formEntry?.initiatedBy === 'PropertySearch'}
                            value={item.MarketValue}
                            onChange={(e) =>
                                props.changeValueHandler(
                                    e,
                                    "MarketValue",
                                    props.group,
                                    item.Id!
                                )
                            }
                            format={currencyFormatOptions}
                            spinners={false}
                        />
                    </td>
                    <td>
                        <Input
                            className={classes.TableInput}
                            disabled={props.formSettings?.isReadOnly || props.formEntry?.initiatedBy === 'PropertySearch'}
                            value={item.Description}
                            onChange={(e) =>
                            props.changeValueHandler(
                                e,
                                "Description",
                                props.group,
                                item.Id!
                            )
                            }
                        />
                    </td>
                </tr>
                );
            }
            )}
        </table>
    );
}
