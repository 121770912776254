import { useEffect, useRef, useState } from "react";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import shared from "../PDF-Shared-style.module.scss";
import classes from "./AddressChangeForm-PDF-style.module.scss";
import { IAddressChangeFormFirstSection } from "../../../interfaces/forms/AddressChangeForm/address-change-form-first-section.interface";
import { IAddressChangeFormFourthSection } from "../../../interfaces/forms/AddressChangeForm/address-change-form-fourth-section.interface";
import { IAddressChangeFormSecondSection } from "../../../interfaces/forms/AddressChangeForm/address-change-form-second-section.interface";
import { IAddressChangeFormThirdSection } from "../../../interfaces/forms/AddressChangeForm/address-change-form-third-section.interface";

interface IFormFields {
  formDataFieldsFirstSection?: IAddressChangeFormFirstSection;
  formDataFieldsSecondSection?: IAddressChangeFormSecondSection;
  formDataFieldsThirdSection?: IAddressChangeFormThirdSection;
  formDataFieldsFourthSection?: IAddressChangeFormFourthSection;
  getPdfBase64Url?: (url: string) => void;
}

export default function AddressChangeFormPDFView(props: IFormFields): JSX.Element {
  const {
    formDataFieldsFirstSection,
    formDataFieldsSecondSection,
    formDataFieldsThirdSection,
    formDataFieldsFourthSection,
    getPdfBase64Url,
  } = props;

  const templateRef = useRef(null);
  const [templateBase64, setTemplateBase64] = useState("");

  useEffect(() => {
    drawDOM(templateRef.current as any, { paperSize: "A4" })
      .then((group) => {
        if (templateRef.current) {
          const myReference = templateRef.current as any;
          myReference.style.display = "none";
        }
        return exportPDF(group);
      })
      .then((dataUri) => {
        setTemplateBase64(dataUri);
        if (getPdfBase64Url) {
          getPdfBase64Url(dataUri);
        }
      });
  }, []);

  return (
    <>
      {templateRef && templateBase64 && (
        <iframe
          style={{ width: "100%", height: "100%" }}
          src={templateBase64}
        />
      )}
        <div ref={templateRef} className={`${shared.FormContainer} ${shared.Form}`}>
            <div className={shared.PrintTogether}>
                <div className={`${shared.Row} ${shared.AlignCenter}`}>
                    <div className={`${shared.Column12} ${shared.H3}`}>
                        Change of Address Request Form
                    </div>
                </div>
                <div className={shared.Row}>
                    <div className={shared.Column6}>
                        <div className={shared.Value}>
                            {formDataFieldsFirstSection?.DateOfRequest}
                        </div>
                        <div className={shared.ValueLabelBelow}>
                            Date of Request
                        </div>
                    </div>
                </div>
                <br/>
                <div className={shared.TextLeft}>
                    <p><b>Account Information:</b></p>
                </div>
                <div className={shared.Row}>
                    <div className={shared.Column8}>
                        <div className={shared.Value}>
                            {formDataFieldsSecondSection?.PropertyOwnerName}
                        </div>
                        <div className={shared.ValueLabelBelow}>
                            Property Owner Name
                        </div>
                    </div>
                    <div className={shared.Column4}>
                        <div className={shared.Value}>
                            {formDataFieldsSecondSection?.PhoneNumber}
                        </div>
                        <div className={shared.ValueLabelBelow}>
                            Phone
                        </div>
                    </div>
                </div>
                <div className={shared.Row}>
                    <div className={shared.Column4}>
                        <div className={shared.Value}>
                            {formDataFieldsSecondSection?.PropertyId}
                        </div>
                        <div className={shared.ValueLabelBelow}>
                            Account Number (PID)
                        </div>
                    </div>
                    <div className={shared.Column8}>
                        <div className={shared.Value}>
                            {formDataFieldsSecondSection?.LegalDescription}
                        </div>
                        <div className={shared.ValueLabelBelow}>
                            Legal Description
                        </div>
                    </div>
                </div>
                <div className={shared.Row}>
                    <div className={shared.Column12}>
                        <div className={shared.Value}>
                            {formDataFieldsSecondSection?.PhysicalAddressLine1}{" "}
                            &nbsp;
                            {formDataFieldsSecondSection?.PhysicalAddressLine2 && (
                                <span>
                                {formDataFieldsSecondSection?.PhysicalAddressLine2}{" "}
                                &nbsp;
                                </span>
                            )}
                            {formDataFieldsSecondSection?.PhysicalAddressCity}{" "}
                            &nbsp;
                            {formDataFieldsSecondSection?.PhysicalAddressState 
                                && `${formDataFieldsSecondSection?.PhysicalAddressState},`}
                            &nbsp;
                            {formDataFieldsSecondSection?.PhysicalAddressZip}{" "}
                            &nbsp;
                        </div>
                        <div className={shared.ValueLabelBelow}>
                            Property Physical Address
                        </div>
                    </div>
                </div>
                <br/>
                <div className={shared.TextLeft}>
                    <p><b>Previous Mailing Address:</b></p>
                </div>
                <div className={shared.Row}>
                    <div className={shared.Column12}>
                        <div className={shared.Value}>
                            {formDataFieldsThirdSection?.PreviousAddressLine1}{" "}
                            &nbsp;
                            {formDataFieldsThirdSection?.PreviousAddressLine2 && (
                                <span>
                                {formDataFieldsThirdSection?.PreviousAddressLine2}{" "}
                                &nbsp;
                                </span>
                            )}
                        </div>
                        <div className={shared.ValueLabelBelow}>
                            Number & Street or P.O. Box
                        </div>
                    </div>
                </div>
                <div className={shared.Row}>
                    <div className={shared.Column12}>
                        <div className={shared.Value}>
                            {formDataFieldsThirdSection?.PreviousAddressCity}{" "}
                            &nbsp;
                            {formDataFieldsThirdSection?.PreviousAddressState 
                                && `${formDataFieldsThirdSection?.PreviousAddressState},`}
                            &nbsp;
                            {formDataFieldsThirdSection?.PreviousAddressZip}{" "}
                            &nbsp;
                        </div>
                        <div className={shared.ValueLabelBelow}>
                            City, State, Zip Code
                        </div>
                    </div>
                </div>
                <br/>
                <div className={shared.TextLeft}>
                    <p><b>New Mailing Address:</b></p>
                </div>
                <div className={shared.Row}>
                    <div className={shared.Column12}>
                        <div className={shared.Value}>
                            {formDataFieldsThirdSection?.NewAddressLine1}{" "}
                            &nbsp;
                            {formDataFieldsThirdSection?.NewAddressLine2 && (
                                <span>
                                {formDataFieldsThirdSection?.NewAddressLine2}{" "}
                                &nbsp;
                                </span>
                            )}
                        </div>
                        <div className={shared.ValueLabelBelow}>
                            Number & Street or P.O. Box
                        </div>
                    </div>
                </div>
                <div className={shared.Row}>
                    <div className={shared.Column12}>
                        <div className={shared.Value}>
                            {formDataFieldsThirdSection?.NewAddressCity}{" "}
                            &nbsp;
                            {formDataFieldsThirdSection?.NewAddressState 
                                && `${formDataFieldsThirdSection?.NewAddressState},`}
                            &nbsp;
                            {formDataFieldsThirdSection?.NewAddressZip}{" "}
                            &nbsp;
                        </div>
                        <div className={shared.ValueLabelBelow}>
                            City, State, Zip Code
                        </div>
                    </div>
                </div>
                <div className={shared.Row}>
                    <div className={shared.Column12}>
                        <div className={shared.Value}>
                            {formDataFieldsThirdSection?.ReasonForChange}
                        </div>
                        <div className={shared.ValueLabelBelow}>
                            Reason For Change
                        </div>
                    </div>
                </div>
                <br/>
                <div className={shared.TextLeft}>
                    <p><b>Owner&apos;s Statement of Authorization:</b></p>
                </div>
                <div>
                    <p>
                        By submission of this request, I certify that I am the owner 
                        of the above-named property and have the full legal authority 
                        to request to change the mailing address on the above account. 
                        I understand that by changing the address, all notices from 
                        the Appraisal District will be forwarded to the new address.
                    </p>                    
                </div>
                <div className={shared.Row}>
                    <div className={shared.Column8}>
                        <div className={shared.Value}>
                            {formDataFieldsFourthSection?.Signature}
                        </div>
                        <div className={shared.ValueLabelBelow}>
                            Signature of Property Owner
                        </div>
                    </div>
                    <div className={shared.Column4}>
                        <div className={shared.Value}>
                            {formDataFieldsFourthSection?.SignatureDate}
                        </div>
                        <div className={shared.ValueLabelBelow}>
                            Date
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}
