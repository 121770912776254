import {Calendar, CalendarCell, CalendarCellProps} from "@progress/kendo-react-dateinputs";

export const CustomOnlyYearCalendarHelper = (maxYearDate?: number) => {
    const CustomCell = (props: CalendarCellProps) => {
        return maxYearDate && (props.value.getFullYear() > maxYearDate)  ?
            null :
            <CalendarCell {...props}  />;
    };

     const CustomOnlyYearCalendar = (props:any) => {
        return (
            <Calendar
                {...props}
                bottomView={"decade"}
                topView={"decade"}
                cell={CustomCell}
            />
        );
    };

    return { CustomOnlyYearCalendar };
}


