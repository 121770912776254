import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import {useEffect, useRef, useState} from "react";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./AgForm.module.scss";
import {UploadFileInfo} from "@progress/kendo-react-upload";
import {IFormEntryModel} from "../../../../../interfaces/form-entry.interface";
import {SelectedFormType} from "../../../../../context/SelectedForm/selected-form-reducer";
import {GenericActionType} from "../../../../../context/generic-actions.type";
import {IAgFormNinthSectionInterface} from "../../../../../interfaces/forms/AgForm/ag-form-ninth-section.interface";
import { CustomUpload } from "../CustomInputs/CustomUpload";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IAgFormNinthSectionInterface) => void;
    formDataFields?:  IAgFormNinthSectionInterface,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void,
    formEntry?: IFormEntryModel,
    selectedFormDispatch: (value: GenericActionType<SelectedFormType>) => void,
}

export default function AgFormNinthSection(props: GeneralFormType): JSX.Element {
    const {setFormDataFields, formDataFields, formSettings, isAdminPreview, setFormIsValid, formEntry, selectedFormDispatch} = props;

    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} name={"Additional Supporting Documents"}/>;

    const [SupportingDocumentsFiles, setSupportingDocumentsFiles] = useState<Array<UploadFileInfo>>([]);
    const [isSupportingDocumentsUploadValid, setIsSupportingDocumentsUploadValid] = useState<boolean>(true);

    function changeValidator() {
        if (formRef.current) {
            const valid = formRef.current.checkValidity() && isSupportingDocumentsUploadValid;
            setIsValid(valid);
            setFormIsValid(valid);
        }
    }

    function onTouch() {
        if (!isFormTouched) {
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current, isSupportingDocumentsUploadValid]);

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={`${classes.fieldsGroup}`}>
                                <div className={classes.information}>
                                    Here you can upload any additional documentation that may not have been requested in the form.
                                </div>
                                <div className="inputsGroup">
                                    <CustomUpload
                                        files={SupportingDocumentsFiles}
                                        setFiles={setSupportingDocumentsFiles}
                                        uploadIsValid={isSupportingDocumentsUploadValid}
                                        setUploadIsValid={setIsSupportingDocumentsUploadValid}
                                        formEntry={formEntry}
                                        formSettings={formSettings}
                                        isAdminPreview={isAdminPreview}
                                        setFormDataFields={setFormDataFields}
                                        formDataFields={formDataFields}
                                        fieldName={"SupportingDocuments"}
                                        isRequired={!!formSettings?.isRequiredUploadAdditionalSupportingDocuments && 
                                            (!!isAdminPreview || !formSettings.isReadOnly)}
                                        selectedFormDispatch={selectedFormDispatch}
                                        multiple={true}
                                        isLabelRequired={false}
                                        labelText={""} />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
