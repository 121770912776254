import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "../WildlifeManagement.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, RadioGroupChangeEvent, Checkbox, Input } from "@progress/kendo-react-inputs";
import { FormValidation } from "../../InputValidator";
import { CalendarChangeEvent, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { IWildlifeManagementFourthSectionCensus } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-census.interface";
import { Label } from "@progress/kendo-react-labels";
import { CustomDatePicker } from "../../CustomInputs/CustomDatePickers/CustomDatePicker";
import SectionTitle from "../../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    formSettings?: IFormSettingModel,
    formDataFields?: IWildlifeManagementFourthSectionCensus;
    setFormDataFields: (form: IWildlifeManagementFourthSectionCensus) => void;
    setFormIsValid: (value: boolean) => void;
    setSectionIsValid: (value: boolean) => void;
}

export default function CensusSubSection(props: GeneralFormType): JSX.Element {
    const { formSettings, isAdminPreview, formDataFields, setFormDataFields, setFormIsValid, setSectionIsValid } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator, setStyles } = FormValidation(formRef);
    const [spotlightCountsDateAIsValid, setSpotlightCountsDateAIsValid] = 
        useState<boolean>(!formDataFields?.SpotlightCountsDateA);
    const [spotlightCountsDateBIsValid, setSpotlightCountsDateBIsValid] = 
        useState<boolean>(!formDataFields?.SpotlightCountsDateB);
    const [spotlightCountsDateCIsValid, setSpotlightCountsDateCIsValid] = 
        useState<boolean>(!formDataFields?.SpotlightCountsDateC);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={7} name={"Census"}/>;
    
    function changeValidator() {
        if (formRef.current) {
            const isValid = formRef.current.checkValidity();
            setIsValid(isValid);
            setSectionIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string,
        group?: string
    ) {
        if (group) {
            setFormDataFields({
                ...formDataFields,
                [group]: {
                    ...(formDataFields as any)?.[group],
                    [field]: event.value
                }
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    function onTouch() {
        if (!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [ formDataFields, formRef.current ]);

    return (
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.SpotlightCounts}
                                    label={"Spotlight Counts"}
                                    onChange={(e)=> changeValueHandler(e, "SpotlightCounts")}
                                />
                            </div>
                            { formDataFields?.SpotlightCounts && <> 
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>                                    
                                    <Label className={classes.Label}>
                                        Targeted Species
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"SpotlightCountsTargetedSpecies"}
                                        placeholder={"Targeted Species"}
                                        required={false}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.SpotlightCountsTargetedSpecies}
                                        onChange={(e)=> changeValueHandler(e, "SpotlightCountsTargetedSpecies")}
                                    />
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Length of Route
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"SpotlightCountsLengthOfRoute"}
                                            placeholder={"Length of Route"}
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.SpotlightCountsLengthOfRoute}
                                            onChange={(e)=> changeValueHandler(e, "SpotlightCountsLengthOfRoute")}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Visibility of Route
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"SpotlightCountsVisibilityOfRoute"}
                                            placeholder={"Visibility of Route"}
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.SpotlightCountsVisibilityOfRoute}
                                            onChange={(e)=> changeValueHandler(e, "SpotlightCountsVisibilityOfRoute")}
                                        />
                                    </div>
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW20}>
                                        <Label className={classes.Label}>
                                            Dates (3 required)
                                        </Label>
                                    </div>
                                    <div className={classes.fieldsGroupW25}>
                                        <Label className={classes.Label}>
                                            A.
                                        </Label>
                                        <CustomDatePicker
                                            fieldName={"SpotlightCountsDateA"}
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            formDataFields={formDataFields}
                                            setFormDataFields={setFormDataFields}
                                            onlyYear={false}
                                            setIsValidField={setSpotlightCountsDateAIsValid}
                                            validField={spotlightCountsDateAIsValid}
                                            defaultValueDate={formDataFields?.SpotlightCountsDateA}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW25}>
                                        <Label className={classes.Label}>
                                            B.
                                        </Label>
                                        <CustomDatePicker
                                            fieldName={"SpotlightCountsDateB"}
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            formDataFields={formDataFields}
                                            setFormDataFields={setFormDataFields}
                                            onlyYear={false}
                                            setIsValidField={setSpotlightCountsDateBIsValid}
                                            validField={spotlightCountsDateBIsValid}
                                            defaultValueDate={formDataFields?.SpotlightCountsDateB}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW25}>
                                        <Label className={classes.Label}>
                                            C.
                                        </Label>
                                        <CustomDatePicker
                                            fieldName={"SpotlightCountsDateC"}
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            formDataFields={formDataFields}
                                            setFormDataFields={setFormDataFields}
                                            onlyYear={false}
                                            setIsValidField={setSpotlightCountsDateCIsValid}
                                            validField={spotlightCountsDateCIsValid}
                                            defaultValueDate={formDataFields?.SpotlightCountsDateC}
                                        />
                                    </div>
                                </div></>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"SpotlightCountsAdditionalInformation"}
                                    placeholder={"Additional Information"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.SpotlightCountsAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "SpotlightCountsAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.StandardizedIncidentalObservations}
                                    label={"Standardized Incidental Observations"}
                                    onChange={(e)=> changeValueHandler(e, "StandardizedIncidentalObservations")}
                                />
                            </div>
                            { formDataFields?.StandardizedIncidentalObservations && <> 
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>                                    
                                    <Label className={classes.Label}>
                                        Targeted Species
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"StandardizedIncidentalObservationsTargetedSpecies"}
                                        placeholder={"Targeted Species"}
                                        required={false}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.StandardizedIncidentalObservationsTargetedSpecies}
                                        onChange={(e)=> changeValueHandler(e, "StandardizedIncidentalObservationsTargetedSpecies")}
                                    />
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.StandardizedIncidentalObservationsObservationsFrom?.Feeders}
                                            label={"Feeders"}
                                            onChange={(e)=>
                                                changeValueHandler(e, "Feeders", "StandardizedIncidentalObservationsObservationsFrom")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.StandardizedIncidentalObservationsObservationsFrom?.FoodPlots}
                                            label={"Food Plots"}
                                            onChange={(e)=> changeValueHandler(e, "FoodPlots", "StandardizedIncidentalObservationsObservationsFrom")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.StandardizedIncidentalObservationsObservationsFrom?.Blinds}
                                            label={"Blinds"}
                                            onChange={(e)=> changeValueHandler(e, "Blinds", "StandardizedIncidentalObservationsObservationsFrom")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.StandardizedIncidentalObservationsObservationsFrom?.Vehicle}
                                            label={"Vehicle"}
                                            onChange={(e)=> changeValueHandler(e, "Vehicle", "StandardizedIncidentalObservationsObservationsFrom")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.StandardizedIncidentalObservationsObservationsFrom?.Other}
                                            label={"Other"}
                                            onChange={(e)=> changeValueHandler(e, "Other", "StandardizedIncidentalObservationsObservationsFrom")}
                                        />
                                    </div>                                    
                                    { formDataFields?.StandardizedIncidentalObservationsObservationsFrom?.Other && 
                                        <div className={classes.fieldsGroupW100}>
                                            <Label className={classes.Label}>
                                                Other (describe)
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                name={"StandardizedIncidentalObservationsObservationsFromOther"}
                                                placeholder="Other (describe)"
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.StandardizedIncidentalObservationsObservationsFromOther}
                                                onChange={(e)=> changeValueHandler(e, "StandardizedIncidentalObservationsObservationsFromOther")}
                                            />
                                        </div>
                                    }
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Dates
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"StandardizedIncidentalObservationsDates"}
                                        placeholder="Dates"
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.StandardizedIncidentalObservationsDates}
                                        onChange={(e)=> changeValueHandler(e, "StandardizedIncidentalObservationsDates")}
                                    />
                                </div></>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"StandardizedIncidentalObservationsAdditionalInformation"}
                                    placeholder={"Additional Information"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.StandardizedIncidentalObservationsAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "StandardizedIncidentalObservationsAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.StandCountsOfDeer}
                                    label={"Stand counts of deer (5 one hour counts per stand required)"}
                                    onChange={(e)=> changeValueHandler(e, "StandCountsOfDeer")}
                                />
                            </div>
                            { formDataFields?.StandCountsOfDeer &&
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW20}>
                                        <Label className={classes.Label}>
                                            Number of Stands
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"StandCountsOfDeerNumberOfStands"}
                                            placeholder={"Number of Stands"}
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.StandCountsOfDeerNumberOfStands}
                                            onChange={(e)=> changeValueHandler(e, "StandCountsOfDeerNumberOfStands")}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW70}>
                                        <Label className={classes.Label}>
                                            Dates
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"StandCountsOfDeerDates"}
                                            placeholder={"Dates"}
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.StandCountsOfDeerDates}
                                            onChange={(e)=> changeValueHandler(e, "StandCountsOfDeerDates")}
                                        />
                                    </div>
                                </div>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"StandCountsOfDeerAdditionalInformation"}
                                    placeholder={"Additional Information"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.StandCountsOfDeerAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "StandCountsOfDeerAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.AerialCounts}
                                    label={"Aerial Counts"}
                                    onChange={(e)=> changeValueHandler(e, "AerialCounts")}
                                />
                            </div>
                            { formDataFields?.AerialCounts && <>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Species Counted
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"AerialCountsSpeciesCounted"}
                                        placeholder={"Species Counted"}
                                        required={false}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.AerialCountsSpeciesCounted}
                                        onChange={(e)=> changeValueHandler(e, "AerialCountsSpeciesCounted")}
                                    />
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Species:
                                        </Label>
                                        <div className={classes.inputsGroup}>
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.AerialCountsSurveyType?.Helicopter}
                                                label={"Helicopter"}
                                                onChange={(e)=> changeValueHandler(e, "Helicopter", "AerialCountsSurveyType")}
                                            />
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.AerialCountsSurveyType?.FixedWing}
                                                label={"Fixed-wing"}
                                                onChange={(e)=> changeValueHandler(e, "FixedWing", "AerialCountsSurveyType")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW75}>
                                        <Label className={classes.Label}>
                                            Species:
                                        </Label>
                                        <div className={classes.inputsGroup}>
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.AerialCountsPercentOfAreaSurveyed?.Total}
                                                label={"Total"}
                                                onChange={(e)=> changeValueHandler(e, "Total", "AerialCountsPercentOfAreaSurveyed")}
                                            />
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.AerialCountsPercentOfAreaSurveyed?.Fifty}
                                                label={"50%"}
                                                onChange={(e)=> changeValueHandler(e, "Fifty", "AerialCountsPercentOfAreaSurveyed")}
                                            />
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.AerialCountsPercentOfAreaSurveyed?.Other}
                                                label={"Other"}
                                                onChange={(e)=> changeValueHandler(e, "Other", "AerialCountsPercentOfAreaSurveyed")}
                                            />
                                        </div>
                                    </div>
                                    {formDataFields?.AerialCountsPercentOfAreaSurveyed?.Other && 
                                        <div className={classes.fieldsGroupW25}>
                                        <Label className={classes.Label}>
                                            Other (describe)
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"AerialCountsPercentOfAreaSurveyedOther"}
                                            placeholder="Other (describe)"
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.AerialCountsPercentOfAreaSurveyedOther}
                                            onChange={(e)=> changeValueHandler(e, "AerialCountsPercentOfAreaSurveyedOther")}
                                        />
                                        </div>
                                    }
                                </div></>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"AerialCountsAdditionalInformation"}
                                    placeholder={"Additional Information"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.AerialCountsAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "AerialCountsAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.TrackCounts}
                                    label={"Track Counts"}
                                    onChange={(e)=> changeValueHandler(e, "TrackCounts")}
                                />
                            </div>
                            { formDataFields?.TrackCounts && <>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW75}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.TrackCountsTypes?.Predators}
                                            label={"Predators"}
                                            onChange={(e)=> changeValueHandler(e, "Predators", "TrackCountsTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.TrackCountsTypes?.Furbearers}
                                            label={"Furbearers"}
                                            onChange={(e)=> changeValueHandler(e, "Furbearers", "TrackCountsTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.TrackCountsTypes?.Deer}
                                            label={"Deer"}
                                            onChange={(e)=> changeValueHandler(e, "Deer", "TrackCountsTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.TrackCountsTypes?.Other}
                                            label={"Other"}
                                            onChange={(e)=> changeValueHandler(e, "Other", "TrackCountsTypes")}
                                        />
                                    </div>
                                    {formDataFields?.TrackCountsTypes?.Other && 
                                        <div className={classes.fieldsGroupW25}>
                                        <Label className={classes.Label}>
                                            Other (describe)
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"TrackCountsTypeOther"}
                                            placeholder="Other (describe)"
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.TrackCountsTypeOther}
                                            onChange={(e)=> changeValueHandler(e, "TrackCountsTypeOther")}
                                        />
                                        </div>
                                    }
                                </div></>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"TrackCountsAdditionalInformation"}
                                    placeholder={"Additional Information"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.TrackCountsAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "TrackCountsAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.DaylightDeerHerdCompositionCounts}
                                    label={"Daylight Deer Herd/Wildlife Composition Counts"}
                                    onChange={(e)=> changeValueHandler(e, "DaylightDeerHerdCompositionCounts")}
                                />
                            </div>
                            { formDataFields?.DaylightDeerHerdCompositionCounts && <>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Species:
                                        </Label>
                                        <div className={classes.inputsGroup}>
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.DaylightDeerHerdCompositionCountsTypes?.Deer}
                                                label={"Deer"}
                                                onChange={(e)=> changeValueHandler(e, "Deer", "DaylightDeerHerdCompositionCountsTypes")}
                                            />
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.DaylightDeerHerdCompositionCountsTypes?.Turkey}
                                                label={"Turkey"}
                                                onChange={(e)=> changeValueHandler(e, "Turkey", "DaylightDeerHerdCompositionCountsTypes")}
                                            />
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.DaylightDeerHerdCompositionCountsTypes?.Dove}
                                                label={"Dove"}
                                                onChange={(e)=> changeValueHandler(e, "Dove", "DaylightDeerHerdCompositionCountsTypes")}
                                            />
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.DaylightDeerHerdCompositionCountsTypes?.Quail}
                                                label={"Quail"}
                                                onChange={(e)=> changeValueHandler(e, "Quail", "DaylightDeerHerdCompositionCountsTypes")}
                                            />
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.DaylightDeerHerdCompositionCountsTypes?.Other}
                                                label={"Other"}
                                                onChange={(e)=> changeValueHandler(e, "Other", "DaylightDeerHerdCompositionCountsTypes")}
                                            />
                                        </div>
                                    </div>
                                </div>                                
                                {formDataFields?.DaylightDeerHerdCompositionCountsTypes?.Other && 
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                        <Label className={classes.Label}>
                                            Other (describe)
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"DaylightDeerHerdCompositionCountsTypeOther"}
                                            placeholder="Other (describe)"
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.DaylightDeerHerdCompositionCountsTypeOther}
                                            onChange={(e)=> changeValueHandler(e, "DaylightDeerHerdCompositionCountsTypeOther")}
                                        />
                                    </div>
                                }</>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"DaylightDeerHerdCompositionCountsAdditionalInformation"}
                                    placeholder={"Additional Information"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.DaylightDeerHerdCompositionCountsAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "DaylightDeerHerdCompositionCountsAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.HarvestDataCollectionKeeping}
                                    label={"Harvest Data Collection/Record Keeping"}
                                    onChange={(e)=> changeValueHandler(e, "HarvestDataCollectionKeeping")}
                                />
                            </div>
                            { formDataFields?.HarvestDataCollectionKeeping && <>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HarvestDataCollectionKeepingSpecies?.Deer}
                                            label={"Deer"}
                                            onChange={(e)=> changeValueHandler(e, "Deer", "HarvestDataCollectionKeepingSpecies")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HarvestDataCollectionKeepingSpecies?.GameBirds}
                                            label={"Game Birds"}
                                            onChange={(e)=> changeValueHandler(e, "GameBirds", "HarvestDataCollectionKeepingSpecies")}
                                        />
                                    </div>
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HarvestDataCollectionKeepingData?.Age}
                                            label={"Age"}
                                            onChange={(e)=> changeValueHandler(e, "Age", "HarvestDataCollectionKeepingData")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HarvestDataCollectionKeepingData?.Weight}
                                            label={"Weight"}
                                            onChange={(e)=> changeValueHandler(e, "Weight", "HarvestDataCollectionKeepingData")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HarvestDataCollectionKeepingData?.Sex}
                                            label={"Sex"}
                                            onChange={(e)=> changeValueHandler(e, "Sex", "HarvestDataCollectionKeepingData")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HarvestDataCollectionKeepingData?.AntlerData}
                                            label={"Antler Data"}
                                            onChange={(e)=> changeValueHandler(e, "AntlerData", "HarvestDataCollectionKeepingData")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HarvestDataCollectionKeepingData?.HarvestDate}
                                            label={"Harvest Date"}
                                            onChange={(e)=> changeValueHandler(e, "HarvestDate", "HarvestDataCollectionKeepingData")}
                                        />
                                    </div>
                                </div></>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"HarvestDataCollectionKeepingAdditionalInformation"}
                                    placeholder={"Additional Information"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.HarvestDataCollectionKeepingAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "HarvestDataCollectionKeepingAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.BrowseUtilizationSurveys}
                                    label={"Browse Utilization Surveys (Thirty 12-foot circular plots required)"}
                                    onChange={(e)=> changeValueHandler(e, "BrowseUtilizationSurveys")}
                                />
                            </div>
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"BrowseUtilizationSurveysAdditionalInformation"}
                                    placeholder={"Additional Information"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.BrowseUtilizationSurveysAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "BrowseUtilizationSurveysAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.CensusOfEndangeredWildlife}
                                    label={"Census of endangered, threatened, or protected wildlife"}
                                    onChange={(e)=> changeValueHandler(e, "CensusOfEndangeredWildlife")}
                                />
                            </div>
                            {formDataFields?.CensusOfEndangeredWildlife && <>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Species
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"CensusOfEndangeredWildlifeSpecies"}
                                        placeholder="Species"
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.CensusOfEndangeredWildlifeSpecies}
                                        onChange={(e)=> changeValueHandler(e, "CensusOfEndangeredWildlifeSpecies")}
                                    />
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Method and Dates
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"CensusOfEndangeredWildlifeMethodAndDates"}
                                        placeholder="Method and Dates"
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.CensusOfEndangeredWildlifeMethodAndDates}
                                        onChange={(e)=> changeValueHandler(e, "CensusOfEndangeredWildlifeMethodAndDates")}
                                    />
                                </div></>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"CensusOfEndangeredWildlifeAdditionalInformation"}
                                    placeholder={"Additional Information"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.CensusOfEndangeredWildlifeAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "CensusOfEndangeredWildlifeAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.CensusOfNongameWildlife}
                                    label={"Census and monitoring of nongame wildlife species"}
                                    onChange={(e)=> changeValueHandler(e, "CensusOfNongameWildlife")}
                                />
                            </div>
                            {formDataFields?.CensusOfNongameWildlife && <>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Species
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"CensusOfNongameWildlifeSpecies"}
                                        placeholder="Species"
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.CensusOfNongameWildlifeSpecies}
                                        onChange={(e)=> changeValueHandler(e, "CensusOfNongameWildlifeSpecies")}
                                    />
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Method and Dates
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"CensusOfNongameWildlifeMethodAndDates"}
                                        placeholder="Method and Dates"
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.CensusOfNongameWildlifeMethodAndDates}
                                        onChange={(e)=> changeValueHandler(e, "CensusOfNongameWildlifeMethodAndDates")}
                                    />
                                </div></>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"CensusOfNongameWildlifeAdditionalInformation"}
                                    placeholder={"Additional Information"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.CensusOfNongameWildlifeAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "CensusOfNongameWildlifeAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.MiscellaneousCounts}
                                    label={"Miscellaneous Counts"}
                                    onChange={(e)=> changeValueHandler(e, "MiscellaneousCounts")}
                                />
                            </div>
                            { formDataFields?.MiscellaneousCounts && <> 
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>                                    
                                    <Label className={classes.Label}>
                                        Species Being Counted
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"MiscellaneousCountsSpeciesBeingCounted"}
                                        placeholder={"Species Being Counted"}
                                        required={false}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.MiscellaneousCountsSpeciesBeingCounted}
                                        onChange={(e)=> changeValueHandler(e, "MiscellaneousCountsSpeciesBeingCounted")}
                                    />
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.MiscellaneousCountsTypes?.RemoteDetection}
                                            label={"Remote detection (i.e., cameras)"}
                                            onChange={(e)=> changeValueHandler(e, "RemoteDetection", "MiscellaneousCountsTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.MiscellaneousCountsTypes?.HahnLine}
                                            label={"Hahn (walking) line"}
                                            onChange={(e)=> changeValueHandler(e, "HahnLine", "MiscellaneousCountsTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.MiscellaneousCountsTypes?.RoostCounts}
                                            label={"Roost counts"}
                                            onChange={(e)=> changeValueHandler(e, "RoostCounts", "MiscellaneousCountsTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.MiscellaneousCountsTypes?.BoomingGroundCounts}
                                            label={"Booming ground counts"}
                                            onChange={(e)=> changeValueHandler(e, "BoomingGroundCounts", "MiscellaneousCountsTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.MiscellaneousCountsTypes?.TimeAreaCounts}
                                            label={"Time/area counts"}
                                            onChange={(e)=> changeValueHandler(e, "TimeAreaCounts", "MiscellaneousCountsTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.MiscellaneousCountsTypes?.SongbirdTransectsAndCounts}
                                            label={"Songbird transects and counts"}
                                            onChange={(e)=> changeValueHandler(e, "SongbirdTransectsAndCounts", "MiscellaneousCountsTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.MiscellaneousCountsTypes?.QuailCallAndCoveyCounts}
                                            label={"Quail call and covey counts"}
                                            onChange={(e)=> changeValueHandler(e, "QuailCallAndCoveyCounts", "MiscellaneousCountsTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.MiscellaneousCountsTypes?.PointCounts}
                                            label={"Point counts"}
                                            onChange={(e)=> changeValueHandler(e, "PointCounts", "MiscellaneousCountsTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.MiscellaneousCountsTypes?.SmallMammalTraps}
                                            label={"Small mammal traps"}
                                            onChange={(e)=> changeValueHandler(e, "SmallMammalTraps", "MiscellaneousCountsTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.MiscellaneousCountsTypes?.DriftFencesAndPitfallTraps}
                                            label={"Drift fences and pitfall traps"}
                                            onChange={(e)=> changeValueHandler(e, "DriftFencesAndPitfallTraps", "MiscellaneousCountsTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.MiscellaneousCountsTypes?.BatDepartures}
                                            label={"Bat departures"}
                                            onChange={(e)=> changeValueHandler(e, "BatDepartures", "MiscellaneousCountsTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.MiscellaneousCountsTypes?.DoveCallCounts}
                                            label={"Dove call counts"}
                                            onChange={(e)=> changeValueHandler(e, "DoveCallCounts", "MiscellaneousCountsTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.MiscellaneousCountsTypes?.ChachalacaCounts}
                                            label={"Chachalaca counts"}
                                            onChange={(e)=> changeValueHandler(e, "ChachalacaCounts", "MiscellaneousCountsTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.MiscellaneousCountsTypes?.TurkeyHenPoultryCounts}
                                            label={"Turkey hen/poultry counts"}
                                            onChange={(e)=> changeValueHandler(e, "TurkeyHenPoultryCounts", "MiscellaneousCountsTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.MiscellaneousCountsTypes?.WaterfowlWaterBirdCounts}
                                            label={"Waterfowl/water bird counts"}
                                            onChange={(e)=> changeValueHandler(e, "WaterfowlWaterBirdCounts", "MiscellaneousCountsTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.MiscellaneousCountsTypes?.AlligatorNestCensusCounts}
                                            label={"Alligator nest/census counts"}
                                            onChange={(e)=> changeValueHandler(e, "AlligatorNestCensusCounts", "MiscellaneousCountsTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.MiscellaneousCountsTypes?.Other}
                                            label={"Other"}
                                            onChange={(e)=> changeValueHandler(e, "Other", "MiscellaneousCountsTypes")}
                                        />
                                    </div>
                                </div>
                                { formDataFields?.MiscellaneousCountsTypes?.Other && 
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                        <Label className={classes.Label}>
                                            Other (describe)
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"MiscellaneousCountsTypesOther"}
                                            placeholder="Other (describe)"
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.MiscellaneousCountsTypesOther}
                                            onChange={(e)=> changeValueHandler(e, "MiscellaneousCountsTypesOther")}
                                        />
                                    </div>
                                }</>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"MiscellaneousCountsAdditionalInformation"}
                                    placeholder={"Additional Information"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.MiscellaneousCountsAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "MiscellaneousCountsAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    )
}