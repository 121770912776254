import {IFormSettingModel} from "../../../../../../interfaces/form-settings.interface";
import React, {useEffect, useRef, useState} from "react";
import {
    Checkbox,
    CheckboxChangeEvent,
    InputChangeEvent, NumericTextBoxChangeEvent,
    RadioGroupChangeEvent,
    TextAreaChangeEvent
} from "@progress/kendo-react-inputs";
import {CalendarChangeEvent, DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {ComboBoxChangeEvent, DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import {IAgFormSixthSectionInterface} from "../../../../../../interfaces/forms/AgForm/ag-form-sixth-section.interface";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "../AgForm.module.scss";
import {Label} from "@progress/kendo-react-labels";
import {
    AgriculturalUseLivestockExoticsWildlifeTypeByAcresTable
} from "./AgriculturalUseLivestockExoticsWildlifeTypeByAcresTable";
import {
    AgriculturalUseLivestockExoticsWildlifeTypeByHeadTable
} from "./AgriculturalUseLivestockExoticsWildlifeTypeByHeadTable";
import {AgriculturalUseCopsByAcreTable} from "./AgriculturalUseCopsByAcreTable";
import {AgriculturalUseGovernmentalProgramsTable} from "./AgriculturalUseGovernmentalProgramsTable";
import {NonAgriculturalUseTable} from "./NonAgriculturalUseTable";
import {AgriculturalUseCategoriesOfLandTable} from "./AgriculturalUseCategoriesOfLandTable";
import {IRowState} from "../../CustomInputs/UpdateRowErrorStateHandler";
import SectionTitle from "../../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IAgFormSixthSectionInterface) => void;
    formDataFields?:  IAgFormSixthSectionInterface,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function AgFormSixthSection(props: GeneralFormType): JSX.Element {
    const {setFormDataFields, formDataFields, formSettings, setFormIsValid} = props;

    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} name={"Section 4: Property Use"}/>;

    function changeListValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | ComboBoxChangeEvent
            | NumericTextBoxChangeEvent,
        field: string,
        group: string,
        id: number
    ) {
        const newItem: IAgFormSixthSectionInterface = formDataFields
            ? JSON.parse(JSON.stringify(formDataFields))
            : [];

        let List = (newItem as any)[group]?.find((x: any) => x.Id == id);

        if(field == "Year"){
            List = {...List, [field]: event.value.getFullYear()};
        } else {
            List = {...List, [field]: event.value};
        }

        const index = (newItem as any)[group]?.findIndex((x: any) => x.Id == id);

        if (index != undefined && (newItem as any)) {
            (newItem as any)[group][index] = List;
        }

        setFormDataFields({
            ...newItem,
        });
    }

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string
    ) {
        setFormDataFields({
            ...formDataFields,
            [field]: JSON.parse(event.value)
        });
    }

    function addRowHandler(event: React.MouseEvent<HTMLElement>, group: string) {
        event.preventDefault();
        const newItem: IAgFormSixthSectionInterface = formDataFields
            ? JSON.parse(JSON.stringify(formDataFields))
            : [];
        if (!(newItem as any)[group]) {
            (newItem as any)[group] = [];
        }
        (newItem as any)[group]?.push({ Id: (newItem as any)[group]?.length });
        setFormDataFields({
            ...newItem,
        });
    }

    function removeRowHandler(
        event: React.MouseEvent<HTMLElement>,
        group: string
    ) {
        event.preventDefault();
        const newItem: IAgFormSixthSectionInterface = formDataFields
            ? JSON.parse(JSON.stringify(formDataFields))
            : [];
        if (newItem && (newItem as any)[group]) {
            (newItem as any)[group].pop();
            setFormDataFields({
                ...newItem,
            });
        }
    }

    const [dateErrorsArray, setDateErrorsArray] = useState<Array<IRowState>>([]);

    function changeValidator() {
        if(formRef.current) {
            const isValid = formRef.current.checkValidity() &&
                !dateErrorsArray.some(x=>x.state === false);

            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch() {
        if (!isFormTouched) {
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current, dateErrorsArray]);

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div>

                            <div className={classes.information}>Agricultural use includes,
                                but is not limited to, the following activities:
                                <ol className="ng-scope">
                                    <li>cultivating the soil;</li>
                                    <li>producing crops for human food, animal feed, or planting seed or for the
                                        production of fibers;
                                    </li>
                                    <li>floriculture, viticulture and horticulture;</li>
                                    <li>raising or keeping livestock;</li>
                                    <li>raising or keeping exotic animals or fowl for the production of human food
                                        or fiber, leather, pelts or other tangible products having a commercial
                                        value;
                                    </li>
                                    <li>planting cover crops or leaving land idle for the purpose of participating
                                        in a governmental program provided the land is not used for residential
                                        purposes or a purpose inconsistent with agricultural use or leaving the land
                                        idle in conjunction with normal crop or livestock rotation procedures;
                                    </li>
                                    <li>producing or harvesting logs and posts used for construction or repair of
                                        fences, pens, barns or other agricultural improvements on adjacent
                                        open-space land having the same owner and devoted to a different
                                        agricultural use;
                                    </li>
                                    <li>wildlife management;</li>
                                    <li>beekeeping.</li>
                                </ol>
                            </div>

                        </div>

                        <div>

                            <div className={classes.information}>Wildlife management is
                                defined as actively using land that at the time the wildlife-management use began,
                                was appraised as qualified open-space or
                                timberland under Tax Code, Chapter 23, Subchapter D or E, to propagate a sustaining
                                breeding, migrating or wintering population of indigenous wild
                                animals for human use, including food, medicine or recreation, in at least three of
                                the following ways:
                                <ol className="ng-scope">
                                    <li>habitat control;</li>
                                    <li>erosion control;</li>
                                    <li>predator control;</li>
                                    <li>providing supplemental supplies of water;</li>
                                    <li>providing supplement supplies of food;</li>
                                    <li>providing shelters;</li>
                                    <li>making census counts to determine population.</li>
                                </ol>
                            </div>

                        </div>

                        <div>
                            <div className={classes.information}>Wildlife management is
                                defined as actively using land to protect federally listed endangered species under
                                a federal permit if the land is included in a
                                habitat preserve subject to a conservation easement created under Natural Resources
                                Code Chapter 183 or part of a conservation development under
                                a federally approved habitat conservation plan restricting the use of the land to
                                protect federally listed endangered species or actively using land for a
                                conservation or restoration project under certain federal and state statutes. These
                                two types of wildlife management uses do not require showing a history
                                of agricultural use but do require evidence identified in step 7, questions 7 and 8.
                            </div>
                        </div>

                        <div>
                            <div className={classes.information}>Agricultural land use
                                categories include:
                                <ol className="ng-scope">
                                    <li>irrigated cropland;</li>
                                    <li>dry cropland;</li>
                                    <li>improved pastureland;</li>
                                    <li>native pastureland;</li>
                                    <li>orchard;</li>
                                    <li>wasteland;</li>
                                    <li>timber production;</li>
                                    <li>wildlife management</li>
                                    <li>other categories of land that are typical in the area.</li>
                                </ol>
                            </div>
                        </div>

                        <div>
                            <div className={classes.information}>Provide complete answers to the
                                following questions. List the agricultural use of the property according to the agricultural
                                land categories listed in the
                                preceding paragraph. Divide the total acreage according to individual uses to which the land
                                is principally devoted.
                            </div>
                        </div>
                        <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                            <p> Describe the current and past agricultural uses of this property as described above,
                                starting with the current year and working back 5 years or until 5
                                out of 7 years of agricultural use is shown. Five years of continuous agricultural use may
                                be required if the land is located within the corporate limits of
                                a</p>
                        </div>
                        <div className={classes.item}>
                            <div className="print-together">
                                <div className="row">
                                    <div className="column-12">
                                        <AgriculturalUseCategoriesOfLandTable
                                            items={formDataFields?.AgriculturalUseCategoriesOfLand || []}
                                            onChange={(items) => setFormDataFields({ ...formDataFields, AgriculturalUseCategoriesOfLand: items })}
                                            setErrorsArray={setDateErrorsArray}
                                            errorsArray={dateErrorsArray}
                                            formSettings={formSettings}
                                        />
                                        {!formSettings?.isReadOnly && (
                                            <div>
                                                <button
                                                    className={classes.LightButton}
                                                    disabled={formSettings?.isReadOnly}
                                                    onClick={(e) =>
                                                        addRowHandler(e, "AgriculturalUseCategoriesOfLand")
                                                    }
                                                >
                                                    <i className="fa fas fa-plus"></i>
                                                    Add Row
                                                </button>
                                                <button
                                                    className={classes.LightButton}
                                                    disabled={formSettings?.isReadOnly}
                                                    onClick={(e) =>
                                                        removeRowHandler(e, "AgriculturalUseCategoriesOfLand")
                                                    }
                                                >
                                                    <i className="fa fas fa-minus"></i>
                                                    Remove Row
                                                </button>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                            <div className={classes.itemFields}>
                                <div className={`${classes.fieldsGroupW49}`}>
                                    <div className="inputsGroup">
                                        <Checkbox
                                            name={"DoesRaiseLivestoc"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.DoesRaiseLivestoc}
                                            label={"Check this box if you raise livestock, exotic animals, exotic fowl or manage wildlife on the property."}
                                            onChange={(e)=> changeValueHandler(e, "DoesRaiseLivestoc")}
                                        />
                                    </div>
                                </div>
                            </div>
                            { formDataFields?.DoesRaiseLivestoc &&<>
                            <div className="print-together">
                                <Label className={classes.Label}>
                                    List the livestock or exotics raised or the type of wildlife managed and the number of acres used for this activity.
                                </Label>
                                <div className="row">
                                    <div className="column-12">
                                        <AgriculturalUseLivestockExoticsWildlifeTypeByAcresTable
                                            changeValueHandler={changeListValueHandler}
                                            formDataFields={formDataFields}
                                            formSettings={formSettings}
                                        />
                                        {!formSettings?.isReadOnly && (
                                            <div>
                                                <button
                                                    className={classes.LightButton}
                                                    disabled={formSettings?.isReadOnly}
                                                    onClick={(e) =>
                                                        addRowHandler(e, "AgriculturalUseLivestockExoticsWildlifeTypeByAcres")
                                                    }
                                                >
                                                    <i className="fa fas fa-plus"></i>
                                                    Add Row
                                                </button>
                                                <button
                                                    className={classes.LightButton}
                                                    disabled={formSettings?.isReadOnly}
                                                    onClick={(e) =>
                                                        removeRowHandler(e, "AgriculturalUseLivestockExoticsWildlifeTypeByAcres")
                                                    }
                                                >
                                                    <i className="fa fas fa-minus"></i>
                                                    Remove Row
                                                </button>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                            <div className="print-together">
                                <Label className={classes.Label}>
                                    How many head (average per year) do you raise?
                                </Label>
                                <div className="row">
                                    <div className="column-12">
                                        <AgriculturalUseLivestockExoticsWildlifeTypeByHeadTable
                                            changeValueHandler={changeListValueHandler}
                                            formDataFields={formDataFields}
                                            formSettings={formSettings}
                                        />
                                        {!formSettings?.isReadOnly && (
                                            <div>
                                                <button
                                                    className={classes.LightButton}
                                                    disabled={formSettings?.isReadOnly}
                                                    onClick={(e) =>
                                                        addRowHandler(e, "AgriculturalUseLivestockExoticsWildlifeTypeByHead")
                                                    }
                                                >
                                                    <i className="fa fas fa-plus"></i>
                                                    Add Row
                                                </button>
                                                <button
                                                    className={classes.LightButton}
                                                    disabled={formSettings?.isReadOnly}
                                                    onClick={(e) =>
                                                        removeRowHandler(e, "AgriculturalUseLivestockExoticsWildlifeTypeByHead")
                                                    }
                                                >
                                                    <i className="fa fas fa-minus"></i>
                                                    Remove Row
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            </>
                            }
                            <div className={classes.itemFields}>
                                <div className={`${classes.fieldsGroupW49}`}>
                                    <div className="inputsGroup">
                                        <Checkbox
                                            name={"DoesGrowCrops"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.DoesGrowCrops}
                                            label={"Check this box if you grow crops (including ornamental plants, flowers or grapevines)."}
                                            onChange={(e)=> changeValueHandler(e, "DoesGrowCrops")}
                                        />
                                    </div>
                                </div>
                            </div>
                            {formDataFields?.DoesGrowCrops &&
                            <div className="print-together">
                                <Label className={classes.Label}>
                                    List the crops grown and the number of acres devoted to each crop.
                                </Label>
                                <div className="row">
                                    <div className="column-12">
                                        <AgriculturalUseCopsByAcreTable
                                            changeValueHandler={changeListValueHandler}
                                            formDataFields={formDataFields}
                                            formSettings={formSettings}
                                        />
                                        {!formSettings?.isReadOnly && (
                                            <div>
                                                <button
                                                    className={classes.LightButton}
                                                    disabled={formSettings?.isReadOnly}
                                                    onClick={(e) =>
                                                        addRowHandler(e, "AgriculturalUseCopsByAcre")
                                                    }
                                                >
                                                    <i className="fa fas fa-plus"></i>
                                                    Add Row
                                                </button>
                                                <button
                                                    className={classes.LightButton}
                                                    disabled={formSettings?.isReadOnly}
                                                    onClick={(e) =>
                                                        removeRowHandler(e, "AgriculturalUseCopsByAcre")
                                                    }
                                                >
                                                    <i className="fa fas fa-minus"></i>
                                                    Remove Row
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div> }
                            <div className={classes.itemFields}>
                                <div className={`${classes.fieldsGroupW49}`}>
                                    <div className="inputsGroup">
                                        <Checkbox
                                            name={"DidPlantCoverCrops"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.DidPlantCoverCrops}
                                            label={"Check this box if you have planted cover crops or your land is lying idle because you are participating in a governmental program."}
                                            onChange={(e)=> changeValueHandler(e, "DidPlantCoverCrops")}
                                        />
                                    </div>
                                </div>
                            </div>
                            {formDataFields?.DidPlantCoverCrops &&
                            <div className="print-together">
                                <Label className={classes.Label}>
                                    List the programs and the number of acres devoted to each program.
                                </Label>
                                <div className="row">
                                    <div className="column-12">
                                        <AgriculturalUseGovernmentalProgramsTable
                                            changeValueHandler={changeListValueHandler}
                                            formDataFields={formDataFields}
                                            formSettings={formSettings}
                                        />
                                        {!formSettings?.isReadOnly && (
                                            <div>
                                                <button
                                                    className={classes.LightButton}
                                                    disabled={formSettings?.isReadOnly}
                                                    onClick={(e) =>
                                                        addRowHandler(e, "AgriculturalUseGovernmentalPrograms")
                                                    }
                                                >
                                                    <i className="fa fas fa-plus"></i>
                                                    Add Row
                                                </button>
                                                <button
                                                    className={classes.LightButton}
                                                    disabled={formSettings?.isReadOnly}
                                                    onClick={(e) =>
                                                        removeRowHandler(e, "AgriculturalUseGovernmentalPrograms")
                                                    }
                                                >
                                                    <i className="fa fas fa-minus"></i>
                                                    Remove Row
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div> }
                            <div className={classes.itemFields}>
                                <div className={`${classes.fieldsGroupW49}`}>
                                    <div className="inputsGroup">
                                        <Checkbox
                                            name={"PorpertyIsUsedForNonAgriculturalActivities"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.PorpertyIsUsedForNonAgriculturalActivities}
                                            label={"Check this box if this property is now used for any non-agricultural activities."}
                                            onChange={(e)=> changeValueHandler(e, "PorpertyIsUsedForNonAgriculturalActivities")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* todo: fix property name to IsPropertyUsedForNonAgriculturalActivities */}
                        {formDataFields?.PorpertyIsUsedForNonAgriculturalActivities &&
                        <div className="print-together">
                            <Label className={classes.Label}>
                                List all non-agricultural uses and the number of acres devoted to the use.
                            </Label>
                            <div className="row">
                                <div className="column-12">
                                    <NonAgriculturalUseTable
                                        changeValueHandler={changeListValueHandler}
                                        formDataFields={formDataFields}
                                        formSettings={formSettings}
                                    />
                                    {!formSettings?.isReadOnly && (
                                        <div>
                                            <button
                                                className={classes.LightButton}
                                                disabled={formSettings?.isReadOnly}
                                                onClick={(e) =>
                                                    addRowHandler(e, "NonAgriculturalUse")
                                                }
                                            >
                                                <i className="fa fas fa-plus"></i>
                                                Add Row
                                            </button>
                                            <button
                                                className={classes.LightButton}
                                                disabled={formSettings?.isReadOnly}
                                                onClick={(e) =>
                                                    removeRowHandler(e, "NonAgriculturalUse")
                                                }
                                            >
                                                <i className="fa fas fa-minus"></i>
                                                Remove Row
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div> }
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
