export type IExemptionPropertyDamagedFormFifthSection = {
    CanPropertyBeRepaired?: boolean;
    IfHomesteadDoIntendToReturn?: boolean;
    IsAnyPartInhabitable?: boolean;
    IsAnyPartInhabitableExplain?: string;
    IfBusinessDoIntendToReopen?: boolean;
    IsStructuralDamage?: boolean;
    IsStructuralDamageExplain?: string;
    IsNonStructuralDamageOnly?: boolean;
    IsNonStructuralDamageOnlyExplain?: string;
    EstimatedCostToRepair?: string;
    EstimatedDateRepairsBegin?: string;
    EstimatedCompletionDate?: string;
    IsStillWaitingForCostRepairAssistance?: boolean;
    InchesAboutFoundation?: string;
}

export const ExemptionPropertyDamagedFormFifthSectionInterface = (): IExemptionPropertyDamagedFormFifthSection => ({
    CanPropertyBeRepaired: undefined,
    IfHomesteadDoIntendToReturn: undefined,
    IsAnyPartInhabitable: undefined,
    IsAnyPartInhabitableExplain: undefined,
    IfBusinessDoIntendToReopen: undefined,
    IsStructuralDamage: undefined,
    IsStructuralDamageExplain: undefined,
    IsNonStructuralDamageOnly: undefined,
    IsNonStructuralDamageOnlyExplain: undefined,
    EstimatedCostToRepair: undefined,
    EstimatedDateRepairsBegin: undefined,
    EstimatedCompletionDate: undefined,
    IsStillWaitingForCostRepairAssistance: undefined,
    InchesAboutFoundation: undefined
})