import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import React, {useEffect, useRef, useState} from "react";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./AgForm.module.scss";
import {Error, Label} from "@progress/kendo-react-labels";
import {
    CheckboxChangeEvent,
    Input,
    InputChangeEvent, RadioGroup, RadioGroupChangeEvent,
    TextAreaChangeEvent
} from "@progress/kendo-react-inputs";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {IAgFormThirdSectionInterface} from "../../../../../interfaces/forms/AgForm/ag-form-third-section.interface";
import {CalendarChangeEvent, DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import {PhoneNumberInput} from "../CustomInputs/PhoneNumberInputs/PhoneNumberInput";
import {FormValidation} from "../InputValidator";
import {StatesInput} from "../CustomInputs/StatesInput";
import {ZipCodeInput} from "../CustomInputs/ZipCodeInput";
import {EmailInput} from "../CustomInputs/EmailInputs/EmailInput";
import SectionTitle from "../Shared/SectionTitle";
import { typeOfAuthorizedRepresentativeRadioButtonData } from "../Shared/RadioButtonData";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IAgFormThirdSectionInterface) => void;
    formDataFields?:  IAgFormThirdSectionInterface,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function AgFormThirdSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, setFormIsValid } = props;

    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} name={"Section 2: Authorized Representative"}/>;
    
    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string
    ) {
        setFormDataFields({
            ...formDataFields,
            [field]: event.value
        });
    }

    function changeValidator() {
        if(formRef.current) {
            setIsValid(formRef.current.checkValidity() &&
            phoneNumberInputValid &&
            mailingStateInputValid &&
            mailingZipCode );
            setFormIsValid(formRef.current.checkValidity() &&
            phoneNumberInputValid &&
            mailingStateInputValid &&
            mailingZipCode);
        }
    }

    function onTouch(){
        if(!isFormTouched){
            setIsFormTouched(true);
        }
    }

    const [mailingStateInputValid, setMailingStateInputValid] = useState<boolean>(false);
    const [mailingZipCode, setMailingZipCode] = useState<boolean>(false);
    const [phoneNumberInputValid, setPhoneNumberInputValid] = useState<boolean>(false);
    const [emailInputValid, setEmailInputValid] = useState<boolean>(false);
    const { validationMessage, inputValidator } = FormValidation(formRef);

    useEffect(() => {
        changeValidator()
    }, [
        formDataFields,
        formRef.current,
        phoneNumberInputValid,
        mailingStateInputValid,
        mailingZipCode,
    ]);

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">

                        <div className={classes.information}><b>If you
                            are an individual property owner filing this application on your own behalf, skip this
                            section; all other applicants are required to complete
                            Section 3.</b></div>

                        <div className={classes.item}>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroup}>
                                    <Label className={classes.Label}>
                                        Please indicate the basis for your authority to represent the property owner in filing this application:
                                    </Label>
                                    <div className={`${classes.inputsGroup} ${classes.fieldsGroupW100}`}>
                                        <RadioGroup
                                            name={"AuthorizedRepresentativeType"}
                                            layout={"vertical"}
                                            disabled={formSettings?.isReadOnly}
                                            data={typeOfAuthorizedRepresentativeRadioButtonData}
                                            onChange={e => changeValueHandler(e, "AuthorizedRepresentativeType")}
                                            value={formDataFields?.AuthorizedRepresentativeType}
                                        />                                            
                                        {formDataFields?.AuthorizedRepresentativeType === "Other" &&
                                            <div className={classes.paddingTop}>
                                                <Label className={classes.Label}>
                                                    Other (specify):
                                                    {!inputValidator("AuthorizedRepresentativeTypeOther") && (
                                                        <i className={"fa fa-asterisk text-danger"}></i>
                                                    )}
                                                </Label>
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"AuthorizedRepresentativeTypeOther"}
                                                    placeholder={"Explain basis"}
                                                    required={true}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.AuthorizedRepresentativeTypeOther}
                                                    onChange={(e)=> changeValueHandler(e, "AuthorizedRepresentativeTypeOther")}
                                                />
                                                {!inputValidator("AuthorizedRepresentativeTypeOther") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div> 
                                        }
                                    </div>
                                </div>
                                <div/>
                            </div>
                            <div className={classes.addressSection}>
                                <div className={classes.item}>
                                    <div className={classes.itemFields}>
                                        <div className={`${classes.fieldsGroupW100}`}>
                                            <Label className={classes.Label}>
                                                Name of Authorized Representative
                                            </Label>
                                            <div className="inputsGroup">
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"RepresentativeName"}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.RepresentativeName}
                                                    onChange={(e)=> changeValueHandler(e, "RepresentativeName")}
                                                />
                                                {!inputValidator("RepresentativeName") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.itemFields}>
                                        <div className={`${classes.fieldsGroupW100}`}>
                                            <Label className={classes.Label}>
                                                Title of Authorized Representative
                                            </Label>
                                            <div className="inputsGroup">
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"RepresentativeTitle"}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.RepresentativeTitle}
                                                    onChange={(e)=> changeValueHandler(e, "RepresentativeTitle")}
                                                />
                                                {!inputValidator("RepresentativeTitle") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.itemFields}>
                                        <div className={`${classes.fieldsGroupW100}`}>
                                            <Label className={classes.Label}>
                                                Phone Number
                                            </Label>
                                            <div className="inputsGroup">
                                                <PhoneNumberInput
                                                    required={false}
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"RepresentativePhoneNumber"}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                    setIsValidField={setPhoneNumberInputValid}
                                                    validField={phoneNumberInputValid}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.itemFields}>
                                        <div className={`${classes.fieldsGroupW100}`}>
                                            <Label className={classes.Label}>
                                                Email Address
                                            </Label>
                                            <div className="inputsGroup">
                                                <EmailInput
                                                    required={false}
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"RepresentativeEmail-Address"}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                    setIsValidField={setEmailInputValid}
                                                    validField={emailInputValid}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.item}>
                                    <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                        <p>Mailing Address</p>
                                    </div>
                                    <div className={classes.itemFields}>
                                        <div className={`${classes.fieldsGroupW100}`}>
                                            <Label className={classes.Label}>
                                                Address Line 1
                                            </Label>
                                            <div className="inputsGroup">
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"RepresentativeMailingAddressLine1"}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.RepresentativeMailingAddressLine1}
                                                    onChange={(e)=> changeValueHandler(e, "RepresentativeMailingAddressLine1")}
                                                />
                                                {!inputValidator("RepresentativeMailingAddressLine1") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.itemFields}>
                                        <div className={`${classes.fieldsGroupW100}`}>
                                            <Label className={classes.Label}>
                                                Address Line 2
                                            </Label>
                                            <div className="inputsGroup">
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"RepresentativeMailingAddressLine2"}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.RepresentativeMailingAddressLine2}
                                                    onChange={(e)=> changeValueHandler(e, "RepresentativeMailingAddressLine2")}
                                                />
                                                {!inputValidator("RepresentativeMailingAddressLine2") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.itemFields}>
                                        <div className={`${classes.fieldsGroupW30}`}>
                                            <Label className={classes.Label}>
                                                City
                                            </Label>
                                            <div className="inputsGroup">
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"RepresentativeMailingAddressCity"}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.RepresentativeMailingAddressCity}
                                                    onChange={(e)=> changeValueHandler(e, "RepresentativeMailingAddressCity")}
                                                />
                                                {!inputValidator("RepresentativeMailingAddressCity") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                        </div>
                                        <div className={`${classes.fieldsGroupW30}`}>
                                            <Label className={classes.Label}>
                                                State
                                            </Label>
                                            <div className="inputsGroup">
                                                <StatesInput
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"RepresentativeMailingAddressState"}
                                                    required={false}
                                                    validField={mailingStateInputValid}
                                                    setIsValidField={setMailingStateInputValid}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                />
                                            </div>
                                        </div>
                                        <div className={`${classes.fieldsGroupW30}`}>
                                            <Label className={classes.Label}>
                                                Zip Code
                                            </Label>
                                            <div className="inputsGroup">
                                                <ZipCodeInput
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"RepresentativeMailingAddressZip"}
                                                    required={false}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                    setIsValidField={setMailingZipCode}
                                                    validField={mailingZipCode}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
