import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import {useEffect, useRef, useState} from "react";
import {
    CheckboxChangeEvent, Input,
    InputChangeEvent, NumericTextBoxChangeEvent, 
    
    TextAreaChangeEvent
} from "@progress/kendo-react-inputs";
import {CalendarChangeEvent, DatePicker, DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./AddressChangeForm.module.scss";
import {Error, Label} from "@progress/kendo-react-labels";
import {MMddyyyyDateFormat} from "../../../../../constants/date-formats";
import {FormValidation} from "../InputValidator";
import { IAddressChangeFormFourthSection } from "../../../../../interfaces/forms/AddressChangeForm/address-change-form-fourth-section.interface";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IAddressChangeFormFourthSection) => void;
    formDataFields?:  IAddressChangeFormFourthSection,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void,
}

export default function AddressChangeFormFourthSection(props: GeneralFormType): JSX.Element {
    const {setFormDataFields, formDataFields, formSettings, setFormIsValid} = props;

    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={4} name={"Certification and Signature"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | InputChangeEvent
            | CalendarChangeEvent
            | NumericTextBoxChangeEvent,
        field: string,
    ) {
        if(field == "SignatureDate"){
            setFormDataFields({
                ...formDataFields,
                [field]: event.value?.toString()
            });
        } else if(field == "Signature") {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value?.toString(),
                ["SignatureDate"]: new Date().toDateString()
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

   function changeValidator() {
        if (formRef.current) {
            setIsValid(formRef.current.checkValidity());
            setFormIsValid(formRef.current.checkValidity());
        }
    }

    function onTouch() {
        if (!isFormTouched) {
            setIsFormTouched(true);
        }
    }

    const { validationMessage, inputValidator } = FormValidation(formRef);

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current]);

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={classes.itemFields}>
                                <Label className={`${classes.Label} ${classes.fieldsGroupW100}`}>
                                    By submission of this request, I certify that I am the owner of the above-named property 
                                    and have the full legal authority to request to change the mailing address on the above 
                                    account. I understand that by changing the address, all notices from the Appraisal District 
                                    will be forwarded to the new address.
                                </Label>
                            </div>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroupW49}>
                                    <Label className={`${classes.Label}`}>
                                        Signature of Property Owner
                                        {!formDataFields?.Signature && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                    <Input
                                        required={true}
                                        name={"Signature"}
                                        placeholder={"Signature of Property Owner"}
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.kTextbox}
                                        value={formDataFields?.Signature}
                                        onChange={e => changeValueHandler(e, "Signature")}
                                    />
                                    {!inputValidator("Signature") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={classes.fieldsGroupW30}>
                                    <Label className={`${classes.Label}`}>
                                        Date Signed
                                        {!formDataFields?.SignatureDate && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                    <DatePicker
                                        format={MMddyyyyDateFormat}
                                        name={"SignatureDate"}
                                        required={true}
                                        disabled={true}
                                        className={classes.kTextbox}
                                        value={formDataFields?.SignatureDate ? new Date(formDataFields?.SignatureDate) : undefined}
                                        onChange={e => changeValueHandler(e, "SignatureDate")}
                                    />
                                    {!inputValidator("SignatureDate") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
