import { IFormSettingModel } from "../../../../../interfaces/form-settings.interface";
import { useEffect, useRef, useState } from "react";
import {
  Checkbox,
  CheckboxChangeEvent,
  Input,
  InputChangeEvent,
  RadioGroupChangeEvent,
  TextAreaChangeEvent,
} from "@progress/kendo-react-inputs";
import {
  CalendarChangeEvent,
  DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import {
  ComboBox,
  ComboBoxChangeEvent,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { PanelBar } from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import { PanelBarItem } from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import { Error, Label } from "@progress/kendo-react-labels";
import { IHomesteadExemptionFirstSectionInterface } from "../../../../../interfaces/forms/HomesteadExemptionForm/homestead-exemption-first-section.interface";
import classes from "./HomesteadExemption.module.scss";
import { Button } from "@progress/kendo-react-buttons";
import { CheckboxGroup } from "../CustomInputs/CheckBoxes/CheckboxGroup";
import {FormValidation} from "../InputValidator";
import {StatesInput} from "../CustomInputs/StatesInput";
import {ZipCodeInput} from "../CustomInputs/ZipCodeInput";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
  isAdminPreview?: boolean;
  setFormDataFields: (form: IHomesteadExemptionFirstSectionInterface) => void;
  formDataFields?: IHomesteadExemptionFirstSectionInterface;
  formSettings?: IFormSettingModel;
  setFormIsValid: (value: boolean) => void;
}

export default function HomesteadExemptionFirstSection(props: GeneralFormType): JSX.Element {
  const { setFormDataFields, formDataFields, formSettings, isAdminPreview, setFormIsValid } = props;
  const [IsLateApplication, setIsLateApplication] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
  const [taxYears, setTaxYears] = useState<Array<number>>([]);
  const [PreviousResidenceStateInputValid, setPreviousResidenceStateInputValid] = useState<boolean>(false);
  const [PreviousResidenceZipCode, setPreviousResidenceZipCode] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);
  const { validationMessage, inputValidator } = FormValidation(formRef);
  const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={1} name={"General Information"}/>;

  useEffect(() => {
    if (formSettings) {
      const years: Array<number> = [];

      if (formSettings.taxYearCurrentAvailableToApply) {
        years.push(new Date().getFullYear());
      }

      if (formSettings.taxYearCurrentMinus1AvailableToApply) {
        years.push(new Date().getFullYear() - 1);
      }

      if (formSettings.taxYearCurrentMinus2AvailableToApply) {
        years.push(new Date().getFullYear() - 2);
      }

      if (formSettings.taxYearCurrentPlus1AvailableToApply) {
        years.push(new Date().getFullYear() + 1);
      }

      setTaxYears(years);
    }
  }, [formSettings]);

  function changeValueHandler(
    event:
      | CheckboxChangeEvent
      | DatePickerChangeEvent
      | TextAreaChangeEvent
      | DropDownListChangeEvent
      | InputChangeEvent
      | RadioGroupChangeEvent
      | CalendarChangeEvent
      | ComboBoxChangeEvent,
    field: string
  ) {
    setFormDataFields({
      ...formDataFields,
      [field]: event.value,
    });
  }

  function changeTaxYearValueHandler(
    event: CheckboxChangeEvent,
    field: string,
    value: number
  ) {
    let temp: Array<number> = formDataFields?.TaxYears
      ? JSON.parse(JSON.stringify(formDataFields?.TaxYears))
      : [];

    if (event.value === true) {
      temp.push(value);
    } else if (event.value === false) {
      temp = temp.filter((x) => x != value);
    }
    setFormDataFields({
      ...formDataFields,
      ["TaxYears"]: temp,
    });
  }

  useEffect(() => {
    if (
      !formDataFields?.PropertyIsInStockOfCoop &&
      formDataFields?.CooperativeHousingCorporation
    ) {
      setFormDataFields({
        ...formDataFields,
        CooperativeHousingCorporation: undefined,
      });
    }
  }, [formDataFields?.PropertyIsInStockOfCoop]);

  function changeValidator() {
    if (formRef.current) {
      const isValid =
          taxYearsValidator() &&
          formRef.current.checkValidity() &&
          PreviousResidenceStateInputValid &&
          PreviousResidenceZipCode;
      setIsValid(isValid);
      setFormIsValid(isValid);
    }
  }

  function onTouch() {
    if (!isFormTouched) {
      setIsFormTouched(true);
    }
  }

  const taxYearsValidator = () => {
    return !!(formDataFields?.TaxYears && formDataFields.TaxYears.length > 0);
  };

  useEffect(() => {
    changeValidator();
  }, [formDataFields, formRef.current, PreviousResidenceStateInputValid, PreviousResidenceZipCode]);

  return (
    <>
      <PanelBar keepItemsMounted={true}>
        <PanelBarItem title={title} expanded={true}>
          <form
            name={"Name"}
            className={classes.sectionForm}
            ref={formRef}
            onFocus={onTouch}
          >
            <div className="container">
              <div className={classes.item}>
                <div className="help-text help-info">
                  <div id="1HelpText" className={classes.information}>
                    <b className="ng-scope">GENERAL INFORMATION:</b> Property
                    owners applying for a residence homestead exemption file
                    this form and supporting documentation with the appraisal
                    district in each county in which the property is located
                    (Tax Code Sections 11.13, 11.131, 11.132, 11.133, 11.134 and
                    11.432).
                  </div>
                </div>
                <div className={classes.itemFields}>
                  <div className={classes.fieldsGroupW100}>
                    <Label className={classes.Label}>
                      Enter the tax year(s) for which you are filing:
                      {!taxYearsValidator() && (
                          <i className={"fa fa-asterisk text-danger"}></i>
                      )}
                    </Label>
                    <div className={classes.inputsGroup}>
                      {formSettings?.isReadOnly && (
                        <div>{formDataFields?.TaxYears?.join(", ")}</div>
                      )}
                      {taxYears
                        .sort()
                        .reverse()
                        .map((item) => {
                          return (
                            <Checkbox
                              key={item}
                              name={item.toString()}
                              className={classes.Checkbox}
                              disabled={formSettings?.isReadOnly}
                              value={formDataFields?.TaxYears?.some(
                                (x) => x == item
                              )}
                              label={item.toString()}
                              onChange={(e) =>
                                changeTaxYearValueHandler(e, "TaxYears", item)
                              }
                            />
                          );
                        })}
                      {!taxYearsValidator() && (
                        <Error>{validationMessage}</Error>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.itemFields}>
                  <Label className={classes.Label}>
                    Are you filing a late application?
                  </Label>
                  <div className={classes.dFlex}>
                    {" "}
                    <div className={classes.fieldsGroup}>
                      <div className="inputsGroup">
                        <CheckboxGroup
                          isAdminPreview={isAdminPreview}
                          setFormDataFields={setFormDataFields}
                          formDataFields={formDataFields}
                          formSettings={formSettings}
                          fieldName={"IsLateApplication"}
                          fieldSecondName={"IsLateApplicationSecond"}
                          isRequired={false}
                        />
                        {!inputValidator("IsLateApplication") &&
                          !inputValidator("IsLateApplicationSecond") && (
                            <Error>{validationMessage}</Error>
                          )}
                      </div>
                    </div>
                    {formSettings?.isLateApplication && (
                      <div
                      onClick={() => {
                        setIsLateApplication(!IsLateApplication);
                      }}
                    >
                      <i className="fa fa-question-circle"></i>
                    </div>)}
                  </div>

                  {IsLateApplication && (
                    <div className={classes.breakLine}>
                      {formSettings?.lateApplicationToolTip}
                    </div>
                  )}
                </div>

                <div className={classes.itemFields}>
                  <Label className={classes.Label}>
                    Cooperative Housing: Do you have an exclusive right to
                    occupy this property because you own stock in a cooperative
                    housing corporation?
                  </Label>
                  <div className={classes.fieldsGroup}>
                    <div className="inputsGroup">
                      <CheckboxGroup
                        isAdminPreview={isAdminPreview}
                        setFormDataFields={setFormDataFields}
                        formDataFields={formDataFields}
                        formSettings={formSettings}
                        fieldName={"PropertyIsInStockOfCoop"}
                        fieldSecondName={"PropertyIsInStockOfCoopSecond"}
                        isRequired={false}
                      />
                      {!inputValidator("PropertyIsInStockOfCoop") &&
                        !inputValidator("PropertyIsInStockOfCoopSecond") && (
                          <Error>{validationMessage}</Error>
                        )}
                    </div>
                  </div>
                  {formDataFields?.PropertyIsInStockOfCoop && (
                    <div className={classes.fieldsGroupW49}>
                      <Label className={classes.Label}>
                        State name of cooperative housing corporation
                        <i className={"fa fa-asterisk text-danger"}></i>
                      </Label>
                      <div className="inputsGroup">
                        <Input
                          required={formDataFields?.PropertyIsInStockOfCoop}
                          className="w-100"
                          name={"CooperativeHousingCorporation"}
                          value={formDataFields?.CooperativeHousingCorporation}
                          onChange={(e) =>
                            changeValueHandler(
                              e,
                              "CooperativeHousingCorporation"
                            )
                          }
                        />
                        {!inputValidator("CooperativeHousingCorporation") && (
                          <Error>{validationMessage}</Error>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className={classes.itemFields}>
                  <Label className={classes.Label}>
                    Were you receiving a homestead exemption on your previous
                    residence?
                  </Label>
                  <div className={classes.fieldsGroup}>
                    <div className="inputsGroup">
                      <CheckboxGroup
                        isAdminPreview={isAdminPreview}
                        setFormDataFields={setFormDataFields}
                        formDataFields={formDataFields}
                        formSettings={formSettings}
                        fieldName={"WasReceivingExemptionOnPreviousResidence"}
                        fieldSecondName={
                          "WasReceivingExemptionOnPreviousResidenceSecond"
                        }
                        isRequired={false}
                      />
                      {!inputValidator(
                        "WasReceivingExemptionOnPreviousResidence"
                      ) &&
                        !inputValidator(
                          "WasReceivingExemptionOnPreviousResidenceSecond"
                        ) && <Error>{validationMessage}</Error>}
                    </div>
                  </div>
                </div>
                <div className={classes.itemFields}>
                  <Label className={classes.Label}>
                    Are you transferring an exemption from a previous residence?
                  </Label>
                  <div className={classes.fieldsGroup}>
                    <div className="inputsGroup">
                      <CheckboxGroup
                        isAdminPreview={isAdminPreview}
                        setFormDataFields={setFormDataFields}
                        formDataFields={formDataFields}
                        formSettings={formSettings}
                        fieldName={"IsTransferringPreviousResidenceHomestead"}
                        fieldSecondName={
                          "IsTransferringPreviousResidenceHomesteadSecond"
                        }
                        isRequired={false}
                      />
                      {!inputValidator(
                        "IsTransferringPreviousResidenceHomestead"
                      ) &&
                        !inputValidator(
                          "IsTransferringPreviousResidenceHomesteadSecond"
                        ) && <Error>{validationMessage}</Error>}
                    </div>
                  </div>
                </div>
                <div className={classes.itemFields}>
                  <Label className={classes.Label}>
                    Are you transferring a tax limitation?
                  </Label>
                  <div className={classes.fieldsGroup}>
                    <div className="inputsGroup">
                      <CheckboxGroup
                        isAdminPreview={isAdminPreview}
                        setFormDataFields={setFormDataFields}
                        formDataFields={formDataFields}
                        formSettings={formSettings}
                        fieldName={"IsTransferringATaxLimitation"}
                        fieldSecondName={"IsTransferringATaxLimitationSecond"}
                        isRequired={false}
                      />
                      {!inputValidator("IsTransferringATaxLimitation") &&
                        !inputValidator(
                          "IsTransferringATaxLimitationSecond"
                        ) && <Error>{validationMessage}</Error>}
                    </div>
                  </div>
                </div>
                <div className={classes.item}>
                  <div
                    className={`${classes.itemTitle} ${classes.addressTitle}`}
                  >
                    <p>Previous Residence Address, City, State, Zip Code</p>
                  </div>
                  <div className={classes.itemFields}>
                    <div className={`${classes.fieldsGroupW100}`}>
                      <Label className={classes.Label}>
                        Address Line 1
                        <i className={"fa fa-asterisk text-danger"}></i>
                      </Label>
                      <div className="inputsGroup">
                        <Input
                          required={true}
                          className={classes.kTextbox}
                          name={"PreviousResidenceAddressLine1"}
                          disabled={formSettings?.isReadOnly}
                          value={formDataFields?.PreviousResidenceAddressLine1}
                          onChange={(e) => changeValueHandler(e, "PreviousResidenceAddressLine1")}
                        />
                        {!inputValidator("PreviousResidenceAddressLine1") && (
                          <Error>{validationMessage}</Error>
                        )}
                      </div>
                    </div>

                    <div className={`${classes.fieldsGroupW100}`}>
                      <Label className={classes.Label}>Address Line 2</Label>
                      <div className="inputsGroup">
                        <Input
                          className={classes.kTextbox}
                          name={"PreviousResidenceAddressLine2"}
                          disabled={formSettings?.isReadOnly}
                          value={formDataFields?.PreviousResidenceAddressLine2}
                          onChange={(e) => changeValueHandler(e, "PreviousResidenceAddressLine2")}
                        />
                        {!inputValidator("PreviousResidenceAddressLine2") && (
                          <Error>{validationMessage}</Error>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={classes.itemFields}>
                    <div className={`${classes.fieldsGroupW30}`}>
                      <Label className={classes.Label}>
                        City
                        <i className={"fa fa-asterisk text-danger"}></i>
                      </Label>
                      <div className="inputsGroup">
                        <Input
                          required={true}
                          className={classes.kTextbox}
                          name={"PreviousResidenceAddressCity"}
                          disabled={formSettings?.isReadOnly}
                          value={formDataFields?.PreviousResidenceAddressCity}
                          onChange={(e) => changeValueHandler(e, "PreviousResidenceAddressCity")}
                        />
                        {!inputValidator("PreviousResidenceAddressCity") && (
                          <Error>{validationMessage}</Error>
                        )}
                      </div>
                    </div>
                    <div className={`${classes.fieldsGroupW15}`}>
                      <Label className={classes.Label}>
                        State
                        <i className={"fa fa-asterisk text-danger"}></i>
                      </Label>
                      <div className="inputsGroup">
                        <StatesInput
                            setFormDataFields={setFormDataFields}
                            fieldName={"PreviousResidenceAddressState"}
                            required={true}
                            validField={PreviousResidenceStateInputValid}
                            setIsValidField={setPreviousResidenceStateInputValid}
                            formDataFields={formDataFields}
                            isReadOnly={formSettings?.isReadOnly}
                        />
                      </div>
                    </div>
                    <div className={`${classes.fieldsGroupW15}`}>
                      <Label className={classes.Label}>
                        Zip Code
                        <i className={"fa fa-asterisk text-danger"}></i>
                      </Label>
                      <div className="inputsGroup">
                        <ZipCodeInput
                            setFormDataFields={setFormDataFields}
                            fieldName={"PreviousResidenceAddressZip"}
                            required={true}
                            formDataFields={formDataFields}
                            isReadOnly={formSettings?.isReadOnly}
                            setIsValidField={setPreviousResidenceZipCode}
                            validField={PreviousResidenceZipCode}
                        />
                      </div>
                    </div>
                    <div className={`${classes.fieldsGroupW30}`}>
                      <Label className={classes.Label}>
                        Previous County
                        <i className={"fa fa-asterisk text-danger"}></i>
                      </Label>
                      <div className="inputsGroup">
                        <Input
                          required={true}
                          className={classes.kTextbox}
                          name={"PreviousResidenceCounty"}
                          disabled={formSettings?.isReadOnly}
                          value={formDataFields?.["PreviousResidenceCounty"]}
                          onChange={(e) =>
                            changeValueHandler(e, "PreviousResidenceCounty")
                          }
                        />
                        {!inputValidator("PreviousResidenceCounty") && (
                          <Error>{validationMessage}</Error>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </PanelBarItem>
      </PanelBar>
    </>
  );
}
