import {NumberFormatOptions} from "@telerik/kendo-intl";

export function NumberFormatHelper(value: number, locales?: string | string[], options?: NumberFormatOptions): string {
    return Intl.NumberFormat(locales, options).format(value);
}

export const USDCurrencyFormatOptions: NumberFormatOptions = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: "currency",
    currency: "USD" ,
    currencyDisplay: "symbol"
};

export const USDCurrencyWithOutFractionDigitsFormatOptions: NumberFormatOptions = {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: "currency",
    currency: "USD" ,
    currencyDisplay: "symbol"
};

export const PercentageFormatOptions: NumberFormatOptions = {
    style: "percent",
    currencyDisplay: "symbol"
};
