import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translation from "src/assets/i18n/en.json";

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

export const resources = {
  en: {
    translation,
  },
} as const;

i18n.use(initReactI18next).init({
  returnNull: false,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
  resources,
});
