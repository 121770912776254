import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./HomesteadExemption.module.scss";
import {Error, Label} from "@progress/kendo-react-labels";
import { SectionInformationTitle } from "../Shared/SectionTitle";

export default function HomesteadExemptionSeventhForm(): JSX.Element {
    const title = <SectionInformationTitle number={7} name={"Important Information"}/>;

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm}>
                    <div className={classes.container}>
                        <div className={classes.item}>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroupW100}>
                                    <Label className={classes.Label}>
                                        APPLICATION DEADLINES
                                    </Label>
                                    <div className={classes.fieldsGroupW100}>
                                        <p>
                                            Generally, the completed application and required documentation is due no later than April 30 of the year for which the exemption is requested.

                                            The due date for persons age 65 or older; disabled; or partially disabled veterans with donated homesteads to apply for the exemption is no later than the first anniversary of the qualification date.

                                            A late application for a residence homestead exemption may be filed up to two years after the deadline for filing has passed. (Tax Code Section 11.431). A late application for residence homestead exemption filed for a disabled veteran (not a surviving spouse) under Tax Code sections 11.131 or 11.132 may be filed up to 5 years after the delinquency date. Surviving spouse of a disabled veteran, who files under Tax Code sections 11.131 or 11.132, may file up to two years after the delinquency date, for a late application for residence homestead exemption.

                                            If the chief appraiser grants the exemption(s), property owner does not need to reapply annually, but must reapply if the chief appraiser requires it, unless seeking to apply the exemption to property not listed in this application.

                                            Property owners already receiving a general residence homestead exemption who turn age 65 in that next year are not required to apply for age 65 or older exemption if accurate birthdate information is included in the appraisal district records or in the information the Texas Department of Public Safety provided to the appraisal district under Transportation Code Section 521.049. (Tax Code Section 11.43(m))
                                        </p>
                                    </div>
                                </div>
                                <div className={classes.fieldsGroupW100}>
                                    <Label className={classes.Label}>
                                        REQUIRED DOCUMENTATION
                                    </Label>
                                    <div className={classes.fieldsGroupW100}>
                                        <div>
                                            <p className="ng-scope">
                                                Attach a copy of property owner’s driver’s license or state-issued
                                                personal
                                                identification certificate. The address listed on the driver’s license
                                                or state-issued
                                                personal identification certificate must correspond to the property
                                                address for
                                                which the exemption is requested. Property owners who reside in certain
                                                facilities
                                                or participate in a certain address confidentiality program may be
                                                exempt from this
                                                requirement. The chief appraiser may waive the requirements for certain
                                                active duty
                                                U.S. armed services members or their spouses or holders of certain
                                                driver’s licenses.
                                            </p>
                                            Manufactured homeowners must provide:
                                            <ul className="ng-scope">
                                                <li>a copy of the Texas Department of Housing and Community Affairs
                                                    statement of ownership showing that the applicant is the owner of
                                                    the manufactured home;
                                                </li>
                                                <li> a copy of the sales purchase agreement, other applicable contract
                                                    or agreement or payment receipt showing that the applicant is the
                                                    purchaser of the manufactured home; or
                                                </li>
                                                <li>a sworn affidavit (see Form 50-114-A) by the applicant indicating
                                                    that:
                                                    <ol>
                                                        <li>
                                                            the applicant is the owner of the manufactured home;
                                                        </li>
                                                        <li>the seller of the manufactured home did not provide the
                                                            applicant with the applicable contract or agreement; and
                                                        </li>
                                                        <li>the applicant could not locate the seller after making a
                                                            good
                                                            faith effort
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.fieldsGroupW100}>
                                    <Label className={classes.Label}>
                                        ADDITIONAL INFORMATION REQUEST
                                    </Label>
                                    <div className={classes.fieldsGroupW100}>
                                        <p>
                                            The chief appraiser may request additional information to evaluate this application. Property owner must comply within 30 days of the request or the application will be denied. The chief appraiser may extend this deadline for a single period not to exceed 15 days for good cause shown. (Tax Code Section 11.45)
                                        </p>
                                    </div>
                                </div>
                                <div className={classes.fieldsGroupW100}>
                                    <Label className={classes.Label}>
                                        DUTY TO NOTIFY
                                    </Label>
                                    <div className={classes.fieldsGroupW100}>
                                        <p>
                                            Property owner must notify the chief appraiser in writing before May 1 of the year after his or her right to this exemption ends.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroupW100}>
                                    <Label className={classes.Label}>
                                        EXEMPTION QUALIFICATIONS
                                    </Label>
                                    <div className={classes.fieldsGroupW100}>
                                        <p className="ng-scope">
                                            <b>General Residence Homestead Exemption</b> (Tax Code Section 11.13(a) and
                                            (b))
                                            A property owner who acquires property after Jan. 1 may receive the
                                            residence
                                            homestead exemption for the applicable portion of that tax year immediately
                                            on
                                            qualification of the exemption, if the previous owner did not receive the
                                            same
                                            exemption for the tax year. The property owner must occupy the property as
                                            the
                                            owner’s primary residence and the residence homestead exemption cannot be
                                            claimed by the property owner on any other property.
                                        </p>
                                    </div>
                                    <div className={classes.fieldsGroupW100}>
                                        <p className="ng-scope"><b>Disabled Person Exemption</b> (Tax Code Section
                                            11.13(c) and (d))<br/>
                                                Persons under a disability for purposes of payment of disability
                                                insurance benefits
                                                under Federal Old-Age, Survivors, and Disability Insurance.<br/>
                                                Property owners not
                                                identified on a deed or other instrument recorded in the applicable real
                                                property
                                                records as an owner of the residence homestead must provide an affidavit
                                                or
                                                other compelling evidence establishing the applicant’s ownership
                                                interest in the
                                                homestead. (See Form 50-114-A) An eligible disabled person age 65 or
                                                older may
                                                receive both exemptions in the same year, but not from the same taxing
                                                units.
                                                Contact the appraisal district for more information.
                                        </p>
                                    </div>
                                    <div className={classes.fieldsGroupW100}>
                                        <p className="ng-scope"><b>Age 65 or Older Exemption </b> (Tax Code Section
                                            11.13(c) and (d))<br/>
                                                This exemption is effective Jan. 1 of the tax year in which the property
                                                owner
                                                becomes age 65. Property owners not identified on a deed or other
                                                instrument
                                                recorded in the applicable real property records as an owner of the
                                                residence
                                                homestead must provide an affidavit or other compelling evidence
                                                establishing the
                                                applicant’s ownership interest in the homestead. (See Form 50-114-A) An
                                                eligible
                                                disabled person age 65 or older may receive both exemptions in the same
                                                year, but
                                                not from the same taxing units. Contact the appraisal district for more
                                                information.
                                        </p>
                                    </div>
                                    <div className={classes.fieldsGroupW100}>
                                        <p className="ng-scope"><b>Surviving Spouse of an Individual Who Qualified for
                                            Age 65 or Older
                                            Exemption </b> (Tax Code Section 11.13(q)): <br/>
                                            Surviving spouse of person who qualified for the age 65 or older
                                            exemption may receive this exemption if the surviving spouse was 55
                                            years of age or older when the qualifying spouse died. The property
                                            must have been the surviving spouse’s residence homestead at the time
                                            of death and remain the surviving spouse’s residence homestead. This
                                            exemption cannot be combined with an exemption under 11.13(d).
                                        </p>
                                    </div>
                                    <div className={classes.fieldsGroupW100}>
                                        <p className="ng-scope"><b>100 Percent Disabled Veterans Exemption</b> (Tax Code
                                            Section 11.131(b))<br/>
                                                Property owner who receives a 100 percent disability compensation due
                                                to a service-connected disability and a rating of 100 percent disabled
                                                or
                                                individual unemployability from the U.S. Department of Veterans Affairs
                                                or
                                                its successor. Documentation must be provided to support this exemption
                                                request.
                                        </p>
                                    </div>
                                    <div className={classes.fieldsGroupW100}>
                                        <p className="ng-scope"><b>Surviving Spouse of a Disabled Veteran Who Qualified
                                            or Would Have
                                            Qualified for the 100 Percent Disabled Veteran’s Exemption</b> (Tax Code
                                            Section 11.131(c) and (d))<br/>
                                                Surviving spouse of a disabled veteran (who qualified for an exemption
                                                under Tax Code Section 11.131(b) at the time of his or her death or
                                                would
                                                have qualified for the exemption if the exemption had been in effect on
                                                the date the disabled veteran died) who has not remarried since the
                                                death of the veteran. The property must have been the surviving spouse’s
                                                residence homestead at the time of the veteran’s death and remain the
                                                surviving spouse’s residence homestead.
                                        </p>
                                    </div>
                                    <div className={classes.fieldsGroupW100}>
                                        <p className="ng-scope"><b>Surviving Spouse of a Disabled Veteran Who Qualified
                                            or Would Have
                                            Qualified for the 100 Percent Disabled Veteran’s Exemption</b> (Tax Code
                                            Section 11.131(c) and (d))<br/>
                                                Surviving spouse of a disabled veteran (who qualified for an exemption
                                                under Tax Code Section 11.131(b) at the time of his or her death or
                                                would
                                                have qualified for the exemption if the exemption had been in effect on
                                                the date the disabled veteran died) who has not remarried since the
                                                death of the veteran. The property must have been the surviving spouse’s
                                                residence homestead at the time of the veteran’s death and remain the
                                                surviving spouse’s residence homestead.
                                        </p>
                                    </div>
                                    <div className={classes.fieldsGroupW100}>
                                        <p className="ng-scope"><b>Donated Residence Homestead of Partially Disabled
                                            Veteran</b> (Tax
                                            Code Section 11.132(b)) <br/>
                                                A disabled veteran with a disability rating of less than 100 percent
                                                with a
                                                residence homestead donated by a charitable organization at no cost or
                                                at some cost that is not more than 50 percent of the good faith estimate
                                                of
                                                the market value of the residence homestead as of the date the donation
                                                is made. Documentation must be provided to support this exemption
                                                request.
                                        </p>
                                    </div>
                                    <div className={classes.fieldsGroupW100}>
                                        <p className="ng-scope"><b>Surviving Spouse of a Disabled Veteran Who Qualified
                                            for the
                                            Donated Residence Homestead Exemption</b> (Tax Code Section 11.132(c)
                                            and (d)):
                                            <br/>
                                                Surviving spouse of a disabled veteran (who qualified for an exemption
                                                under Tax
                                                Code Section 11.132(b) at the time of his or her death) who has not
                                                remarried since
                                                the death of the disabled veteran and maintains the property as his or
                                                her residence
                                                homestead.
                                        </p>
                                    </div>
                                    <div className={classes.fieldsGroupW100}>
                                        <p className="ng-scope"><b>Surviving Spouse of a Member of Armed Services Killed
                                            in Line of Duty</b>(Tax Code Section 11.133(b) and (c))
                                            <br/>
                                                Surviving spouse of a U.S. armed services member who is killed or
                                                fatally injured
                                                in the line of duty who has not remarried since the death of the service
                                                member.
                                                Documentation must be provided to support this exemption request
                                        </p>
                                    </div>
                                    <div className={classes.fieldsGroupW100}>
                                        <p className="ng-scope"><b>Surviving Spouse of a First Responder Killed in the
                                            Line of Duty</b> (Tax
                                            Code Section 11.134)<br/>
                                                Surviving spouse of a first responder who is killed or fatally injured
                                                in the line of
                                                duty who has not remarried since the death of the first responder.
                                                Documentation
                                                must be provided to support this exemption request.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}