import React from "react";
import classes from "./BPPForm/BPPForm.module.scss";

export function FormValidation(formRef: React.RefObject<HTMLFormElement>){
    const validationMessage = "This field is required!"

    const inputValidator = (value: string) => {
        if (formRef.current) {
            const field = formRef.current.elements.namedItem(value) as any;

            if (field instanceof RadioNodeList) {
                return Array.from(field).every(f => (f as any)?.validity?.valid)
            }

            if (field?.validity?.valid) {
                return field?.validity?.valid;
            }

            return false;
        }
    };

    const inputValidatorMessage = (value: string) => {
        if (formRef.current) {
            const field = formRef.current.elements.namedItem(value) as any
            if (field?.validationMessage) {
                return field?.validationMessage;
            }
        }
    };

    const isInvalidStyles = (value: string) => {
        if (formRef.current) {
            const field = formRef.current.elements.namedItem(value) as any
            if (field?.validity?.valid) {
                return field?.validity?.valid;
            }
            return true;
        }
    };

    const setStyles = (field: string) => !isInvalidStyles(field) ? `${classes.kTextbox} ${classes.invalidState}` : classes.kTextbox;

    return {
        validationMessage,
        inputValidator,
        inputValidatorMessage,
        setStyles
    }
}


