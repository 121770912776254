export type ITimberlandAppraisalFormSecondSection = {
    PropertyOwnerType?: string;
    PropertyOwnerTypeOther?: string;
    PropertyOwnerName?: string;
    PropertyOwnerPhoneNumber?: string;
    PropertyOwnerEmailAddress?: string;
    PropertyOwnerPhysicalAddressLine1?: string;
    PropertyOwnerPhysicalAddressLine2?: string;
    PropertyOwnerPhysicalAddressCity?: string;
    PropertyOwnerPhysicalAddressState?: string;
    PropertyOwnerPhysicalAddressZip?: string;
    PropertyOwnerMailingAddressLine1?: string;
    PropertyOwnerMailingAddressLine2?: string;
    PropertyOwnerMailingAddressCity?: string;
    PropertyOwnerMailingAddressState?: string;
    PropertyOwnerMailingAddressZip?: string;
}

export const TimberlandAppraisalFormSecondSectionInterface = (): ITimberlandAppraisalFormSecondSection => ({
    PropertyOwnerType: undefined,
    PropertyOwnerTypeOther: undefined,
    PropertyOwnerName: undefined,
    PropertyOwnerPhoneNumber: undefined,
    PropertyOwnerEmailAddress: undefined,
    PropertyOwnerPhysicalAddressLine1: undefined,
    PropertyOwnerPhysicalAddressLine2: undefined,
    PropertyOwnerPhysicalAddressCity: undefined,
    PropertyOwnerPhysicalAddressState: undefined,
    PropertyOwnerPhysicalAddressZip: undefined,
    PropertyOwnerMailingAddressLine1: undefined,
    PropertyOwnerMailingAddressLine2: undefined,
    PropertyOwnerMailingAddressCity: undefined,
    PropertyOwnerMailingAddressState: undefined,
    PropertyOwnerMailingAddressZip: undefined
})