import {IBPPFormPersonalPropertySegmentsInterface} from "./bpp-form-personal-property-segments.interface";

export interface IAssetsInterface {
    AssetsList?: IBPPFormPersonalPropertySegmentsInterface[]
    CurrentYearValue: number
    CurrentAssetCount: number
    PriorYearValue: number,
    PriorAssetCount: number,
    Difference: number
    PercentageOfDifference: number
}

export const fromSegments = (
    unmappedAssets: IBPPFormPersonalPropertySegmentsInterface[],
    allAssets: IBPPFormPersonalPropertySegmentsInterface[]
): IAssetsInterface => {
    const visibleAssets = allAssets.filter(x => !x.HasActiveSubSegments);

    const activeAssets = visibleAssets.filter(asset => !asset.removed);
    const currentYearValue = activeAssets.reduce((total, asset) => (asset.MarketValue ?? 0) + total, 0);

    const priorAssets = visibleAssets.filter(asset => !asset.isNew)
    const priorYearValue = priorAssets.reduce((total, asset) => total + (asset.originalValue ?? 0), 0);

    const difference = currentYearValue - priorYearValue;
    let percentDifference = Math.abs(difference) / ((currentYearValue + priorYearValue) / 2) * 100;

    if (isNaN(percentDifference)) {
        percentDifference = 0;
    }

    return {
        AssetsList: unmappedAssets,
        CurrentAssetCount: activeAssets.length,
        CurrentYearValue: currentYearValue,
        PriorAssetCount: priorAssets.length,
        PriorYearValue: priorYearValue,
        Difference: difference,
        PercentageOfDifference: Math.round(percentDifference * 100) / 100, // round to 2 decimal places
    }
}
