import { useEffect, useRef, useState } from "react";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import shared from "../PDF-Shared-style.module.scss";
import classes from "./FreeportForm-PDF-style.module.scss";
import { settingsService } from "src/services/settings.service";
import { IFreeportFormFifthSectionInterface } from "src/interfaces/forms/FreeportForm/freeport-form-fifth-section.interface";
import { IFreeportFormFirstSectionInterface } from "src/interfaces/forms/FreeportForm/freeport-form-first-section.interface";
import { IFreeportFormFourthSectionInterface } from "src/interfaces/forms/FreeportForm/freeport-form-fourth-section.interface";
import { IFreeportFormSecondSectionInterface } from "src/interfaces/forms/FreeportForm/freeport-form-second-section.interface";
import { IFreeportFormSixthSectionInterface } from "src/interfaces/forms/FreeportForm/freeport-form-sixth-section.interface";
import { IFreeportFormThirdSectionInterface } from "src/interfaces/forms/FreeportForm/freeport-form-third-section.interface";

interface IFormFields {
  formDataFieldsFirstSection?: IFreeportFormFirstSectionInterface;
  formDataFieldsSecondSection?: IFreeportFormSecondSectionInterface;
  formDataFieldsThirdSection?: IFreeportFormThirdSectionInterface;
  formDataFieldsFourthSection?: IFreeportFormFourthSectionInterface;
  formDataFieldsFifthSection?: IFreeportFormFifthSectionInterface;
  formDataFieldsSixthSection?: IFreeportFormSixthSectionInterface;
  getPdfBase64Url?: (url: string) => void;
}

export default function FreeportFormPDFView(props: IFormFields): JSX.Element {
  const {
    formDataFieldsFirstSection,
    formDataFieldsSecondSection,
    formDataFieldsThirdSection,
    formDataFieldsFourthSection,
    formDataFieldsFifthSection,
    formDataFieldsSixthSection,
    getPdfBase64Url,
  } = props;

  const templateRef = useRef(null);
  const [templateBase64, setTemplateBase64] = useState("");
  const settings = settingsService().settings;

  useEffect(() => {
    drawDOM(templateRef.current as any, { paperSize: "A4" })
      .then((group) => {
        if (templateRef.current) {
          const myReference = templateRef.current as any;
          myReference.style.display = "none";
        }
        return exportPDF(group);
      })
      .then((dataUri) => {
        setTemplateBase64(dataUri);
        if (getPdfBase64Url) {
          getPdfBase64Url(dataUri);
        }
      });
  }, []);

  return (
    <>
      {templateRef && templateBase64 && (
        <iframe
          style={{ width: "100%", height: "100%" }}
          src={templateBase64}
        />
      )}
        <div ref={templateRef} className={`${shared.FormContainer} ${shared.Form}`}>
            <div className={shared.PrintTogether}>
                <div className={`${shared.Row} ${shared.AlignCenter}`}>
                    <div className={`${shared.Column9} ${shared.H3}`}>
                    Application for Exemption of Goods Exported from Texas
                    (Freeport Exemption)
                    </div>
                    <div className={`${shared.Column2} ${shared.TextCenter} ${shared.FormVersion}`}>Form 50-113</div>
                </div>

                <div className={`${shared.Row} ${shared.SpaceBetween}`}>
                    <div className={shared.Column8}>
                        <div className={shared.Value}>
                            {settings?.clientDisplayName}
                        </div>
                        <div className={shared.ValueLabelBelow}>Appraisal District’s Name</div>
                    </div>
                    <div className={shared.Column2}>
                        <div className={shared.Value}>
                            {formDataFieldsFirstSection?.TaxYear}
                        </div>
                        <div className={shared.ValueLabelBelow}>Tax Year</div>
                    </div>
                    <div className={`${shared.ColOffset9} ${shared.Column3}`}>
                        <div className={shared.Value}>
                            {settings.clientPhoneNumber}
                        </div>
                        <div className={shared.ValueLabelBelow}>
                            Phone (area code and number)
                        </div>
                    </div>
                    
                   
                 
                    {/* <div className={shared.Column12}>
                        <div className={shared.Value}>
                            {settings?.clientAddressLine1}{" "}
                            &nbsp;
                            {settings?.clientAddressLine2 && (
                                <span>
                                {settings?.clientAddressLine2}{" "}
                                &nbsp;
                                </span>
                            )}
                            {settings?.clientAddressCity}{" "}
                            &nbsp;
                            {settings?.clientAddressState && `${settings?.clientAddressState},`}
                            &nbsp;
                            {settings?.clientAddressZipCode} &nbsp;
                        </div>

                        <div className={shared.ValueLabelBelow}>
                            Address, City, State, ZIP Code
                        </div>
                    </div> */}
                </div>
                <br/>

                <p>
                    <b>GENERAL INSTRUCTIONS:&nbsp;&nbsp;&nbsp;</b> This application is for use in 
                    claiming a property tax exemption for freeport goods pursuant to 
                    Texas Constitution Article 8, Section 1-j and Tax Code Section 11.251. 
                </p>
                <p>
                    <b>FILING INSTRUCTIONS:&nbsp;</b> This form and all supporting documentation 
                    must be filed with the appraisal district office in each county in which the 
                    property is taxable. Do not file this document with the Texas Comptroller of Public Accounts.
                </p>
               
               

                <div className={shared.Row}>
                    <div className={`${shared.Column9} ${shared.TextSmall}`}>
                        Did the applicant own the property that is the subject of 
                        this application on Jan. 1 of the tax year?
                    </div>
                    <div className={`${shared.Column2} ${shared.TextRight}`}>
                        <i
                        className={
                            "fa " +
                            (formDataFieldsFirstSection?.ApplicantOwnedPropertyJan1 == true
                            ? "fa-check-square-o"
                            : "fa-square-o")
                        }
                        ></i>{" "}
                        &nbsp; Yes &nbsp; &nbsp;
                        <i
                        className={
                            " fa " +
                            (!formDataFieldsFirstSection?.ApplicantOwnedPropertyJan1 == false
                            ? "fa-check-square-o"
                            : "fa-square-o")
                        }
                        ></i>{" "}
                        &nbsp; No
                    </div>
                </div>

                <div className={shared.Step}>
                    SECTION 1: Property Owner/Applicant
                </div>
                <div>
                    <div className={shared.TextSmall}>
                    The applicant is the following type of property owner:
                    </div>
                    <div>
                    <span>
                        <i
                        className={
                            "fa " +
                            (formDataFieldsFirstSection?.individual
                            ? "fa-check-square-o"
                            : "fa-square-o")
                        }
                        ></i>
                        Individual
                    </span>
                    &nbsp;&nbsp;
                    <span>
                        <i
                        className={
                            "fa " +
                            (formDataFieldsFirstSection?.partnership
                            ? "fa-check-square-o"
                            : "fa-square-o")
                        }
                        ></i>
                        Partnership
                    </span>
                    &nbsp;&nbsp;
                    <span>
                        <i
                        className={
                            " fa " +
                            (formDataFieldsFirstSection?.corporation
                            ? "fa-check-square-o"
                            : "fa-square-o")
                        }
                        ></i>
                        Corporation
                    </span>
                    &nbsp;&nbsp;
                    <span>
                        <i
                        className={
                            "fa " +
                            (formDataFieldsFirstSection?.other
                            ? "fa-check-square-o"
                            : "fa-square-o")
                        }
                        ></i>
                        Other <i>(specify):</i>
                    </span>
                    &nbsp;
                    <span className={shared.Value}>
                        {formDataFieldsFirstSection?.OwnershipTypeOther}
                    </span>
                    </div>
                    <br/>

                    <div className={shared.Row}>
                        <div className={shared.Column6}>
                            <div className={shared.Value}>
                                {formDataFieldsFirstSection?.NameOfPropertyOwner}
                            </div>
                            <div className={shared.ValueLabelBelow}>
                                Name of Property Owner
                            </div>
                        </div>
                      
                        <div className={shared.Column12}>
                            <div className={shared.Value}>
                                {formDataFieldsFirstSection?.PhysicalAddressLine1}{" "}
                                &nbsp;
                                {formDataFieldsFirstSection?.PhysicalAddressLine2 && (
                                    <span>
                                    {formDataFieldsFirstSection?.PhysicalAddressLine2}{" "}
                                    &nbsp;
                                    </span>
                                )}
                                {formDataFieldsFirstSection?.PhysicalAddressCity}{" "}
                                &nbsp;
                                {formDataFieldsFirstSection?.PhysicalAddressState && `${formDataFieldsFirstSection?.PhysicalAddressState},`}
                                &nbsp;
                                {formDataFieldsFirstSection?.PhysicalAddressZip} &nbsp;
                            </div>

                            <div className={shared.ValueLabelBelow}>
                                Physical Address, City, State, ZIP Code
                            </div>
                        </div>

                        <div className={shared.Column6}>
                            <div className={shared.Value}>
                                {formDataFieldsFirstSection?.PrimaryPhoneNumber}
                            </div>
                            <div className={shared.ValueLabelBelow}>
                                Primary Phone Number (area code and number)
                            </div>
                        </div>
                        <div className={shared.Column6}>
                            <div className={shared.Value}>
                            {formDataFieldsFirstSection?.EmailAddress}
                            </div>
                            <div className={shared.ValueLabelBelow}>Email Address**</div>
                        </div>

                        <div className={shared.Column12}>
                            <div className={shared.Value}>
                                {formDataFieldsFirstSection?.MailingAddressLine1}{" "}
                                &nbsp;
                                {formDataFieldsFirstSection?.MailingAddressLine2 && (
                                    <span>
                                    {formDataFieldsFirstSection?.MailingAddressLine2}{" "}
                                    &nbsp;
                                    </span>
                                )}
                                {formDataFieldsFirstSection?.MailingAddressCity}{" "}
                                &nbsp;
                                {formDataFieldsFirstSection?.MailingAddressState && `${formDataFieldsFirstSection?.MailingAddressState},`}
                                &nbsp;
                                {formDataFieldsFirstSection?.MailingAddressZip} &nbsp;
                            </div>
                            <div className={shared.ValueLabelBelow}>
                                Mailing Address, City, State, ZIP Code (if different from the
                                physical address provided above)
                            </div>
                        </div>
                    </div>
                </div>

                <div className={shared.Step}>
                    SECTION 2: Authorized Representative
                </div>
                <div>
                    <p>
                        <b>If you are an individual property owner filing this application
                        on your own behalf, skip to Section 3; all other applicants are
                        required to complete Section 2.</b>
                    </p>
                    <div className={shared.TextSmall}>
                        Please indicate the basis for your authority to represent the
                        property owner in filing this application:
                    </div>
                    <div className={shared.Row}>
                    <span className={shared.Column4}>
                        <i
                        className={
                            "fa " +
                            (formDataFieldsSecondSection?.AuthorizedRepresentativeType === "OfficerOfTheCompany"
                            ? "fa-check-square-o"
                            : "fa-square-o")
                        }
                        ></i>
                        Officer of the company
                    </span>
                    &nbsp;&nbsp;
                    <span className={shared.Column4}>
                        <i
                        className={
                            " fa " +
                            (formDataFieldsSecondSection?.AuthorizedRepresentativeType === "GeneralPartnerOfTheCompany"
                            ? "fa-check-square-o"
                            : "fa-square-o")
                        }
                        ></i>
                        General Partner of the company
                    </span>
                    &nbsp;&nbsp;
                    <span className={shared.Column4}>
                        <i
                        className={
                            " fa " +
                            (formDataFieldsSecondSection?.AuthorizedRepresentativeType === "AttorneyForTheCompany"
                            ? "fa-check-square-o"
                            : "fa-square-o")
                        }
                        ></i>
                        Attorney for the company
                    </span>
                    </div>

                    <div className={shared.Row}>
                    <span className={shared.Column12}>
                        <i
                        className={
                            " fa " +
                            (formDataFieldsSecondSection?.AuthorizedRepresentativeType === "Agent"
                            ? "fa-check-square-o"
                            : "fa-square-o")
                        }
                        ></i>
                        Agent for tax matters appointed under Tax Code Section 1.111
                        with completed and signed Form 50-162
                    </span>
                    </div>

                    <div className={shared.Row}>
                    <div className={shared.Column12}>
                        {" "}
                        <span>
                        <i
                            className={
                            " fa " +
                            (formDataFieldsSecondSection?.AuthorizedRepresentativeType === "Other"
                                ? "fa-check-square-o"
                                : "fa-square-o")
                            }
                        ></i>
                        Other and explain basis: &nbsp; &nbsp;
                        </span>
                        <span className={shared.Value}>
                        {formDataFieldsSecondSection?.AuthorizedRepresentativeTypeOther}
                        </span>
                    </div>
                    </div>
                    <br/>

                    <div className={shared.TextSmall}>
                        Provide the following information for the individual with the legal 
                        authority to act for the property owner in this matter:
                    </div>

                    <div className={shared.Row}>
                        <div className={shared.Column6}>
                            <div className={shared.Value}>
                                {formDataFieldsSecondSection?.AuthorizedRepresentativeName}
                            </div>
                            <div className={shared.ValueLabelBelow}>
                                Name of Authorized Representative
                            </div>
                        </div>
                        <div className={shared.Column4}>
                            <div className={shared.Value}>
                            {formDataFieldsFirstSection?.PersonalId}
                            </div>
                            <div className={shared.ValueLabelBelow}>
                                Driver’s License, Personal I.D. Certificate
                                <br/>
                                Social Security Number or Federal Tax I.D. Number*
                            </div>
                        </div>
                    </div>
                    <div className={`${shared.Row} ${shared.SpaceBetween}`}>
                        <div className={shared.Column4}>
                            <div className={shared.Value}>
                                {formDataFieldsSecondSection?.AuthorizedRepresentativeTitle}
                            </div>
                            <div className={shared.ValueLabelBelow}>
                                Title of Authorized Representative
                            </div>
                        </div>
                        <div className={shared.Column4}>
                            <div className={shared.Value}>
                                {formDataFieldsSecondSection?.AuthorizedRepresentativePrimaryPhoneNumber}
                            </div>
                            <div className={shared.ValueLabelBelow}>
                                Primary Phone Number (area code and number)
                            </div>
                        </div>
                        <div className={shared.Column4}>
                            <div className={shared.Value}>
                                {formDataFieldsSecondSection?.AuthorizedRepresentativeEmail}
                            </div>
                            <div className={shared.ValueLabelBelow}>
                                Email Address
                            </div>
                        </div>

                        <div className={shared.Column12}>
                            <div className={shared.Value}>
                                {formDataFieldsSecondSection?.AuthorizedRepresentativeMailingAddressLine1}{" "}
                                &nbsp;
                                {formDataFieldsSecondSection?.AuthorizedRepresentativeMailingAddressLine2 && (
                                    <span>
                                    {formDataFieldsSecondSection?.AuthorizedRepresentativeMailingAddressLine2}{" "}
                                    &nbsp;
                                    </span>
                                )}
                                {formDataFieldsSecondSection?.AuthorizedRepresentativeMailingAddressCity}{" "}
                                &nbsp;
                                {formDataFieldsSecondSection?.AuthorizedRepresentativeMailingAddressState 
                                    && `${formDataFieldsSecondSection?.AuthorizedRepresentativeMailingAddressState},`}
                                &nbsp;
                                {formDataFieldsSecondSection?.AuthorizedRepresentativeMailingAddressZip}{" "}
                                &nbsp;
                            </div>
                            <div className={shared.ValueLabelBelow}>
                                Mailing Address, City, State, ZIP Code
                            </div>
                        </div>
                    </div>
                </div>

                <div className={shared.Step}>
                    SECTION 3: Property Description and Information
                </div>
                <div>
                    <div className={shared.TextSmall}>
                        Provide the following information regarding the location of inventory. 
                    </div>
                    <br />
                    <div className={shared.Column12}>
                        <div className={shared.Value}>
                            {formDataFieldsThirdSection?.PropertyAddressLine1}{" "}
                            &nbsp;
                            {formDataFieldsThirdSection?.PropertyAddressLine2 && (
                                <span>
                                {formDataFieldsThirdSection?.PropertyAddressLine2}{" "}
                                &nbsp;
                                </span>
                            )}
                            {formDataFieldsThirdSection?.PropertyAddressCity}{" "}
                            &nbsp;
                            {formDataFieldsThirdSection?.PropertyAddressState && `${formDataFieldsThirdSection?.PropertyAddressState},`}
                            &nbsp;
                            {formDataFieldsThirdSection?.PropertyAddressZip}{" "}
                            &nbsp;
                        </div>
                        <div className={shared.ValueLabelBelow}>
                            Address, City, State, ZIP Code
                        </div>
                    </div>                    
                    <div className={shared.Row}>
                        <div className={shared.Column9}>
                            <div className={shared.Value}>
                                {formDataFieldsThirdSection?.LegalDescription}
                            </div>
                            <div className={shared.ValueLabelBelow}>
                                Legal Description <i>(if known)</i>
                            </div>
                        </div>
                        <div className={shared.Column3}>
                            <div className={shared.Value}>
                                {formDataFieldsThirdSection?.PropertyId}
                            </div>
                            <div className={shared.ValueLabelBelow}>
                                Appraisal District Account Number <i>(if known)</i>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={shared.Step}>
                    SECTION 3: Property Information (Continued)
                </div>
                <div>
                    <div className={shared.Row}>
                        <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>1.</div>
                        <div className={`${shared.Column9} ${shared.TextSmall}`}>
                            Will portions of this inventory be transported 
                            out of state this year?
                        </div>
                        <div className={`${shared.Column2} ${shared.TextRight}`}>
                            <i
                            className={
                                "fa " +
                                (formDataFieldsFourthSection?.InventoryWillBeTransportedOutOfState == true
                                ? "fa-check-square-o"
                                : "fa-square-o")
                            }
                            ></i>{" "}
                            &nbsp; Yes &nbsp; &nbsp;
                            <i
                            className={
                                " fa " +
                                (!formDataFieldsFourthSection?.InventoryWillBeTransportedOutOfState == false
                                ? "fa-check-square-o"
                                : "fa-square-o")
                            }
                            ></i>{" "}
                            &nbsp; No
                        </div>
                    </div>

                    <div className={shared.Row}>
                        <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>2.</div>
                        <div className={`${shared.Column9} ${shared.TextSmall}`}>
                            Have you applied for Sept. 1 inventory appraisal?
                        </div>
                        <div className={`${shared.Column2} ${shared.TextRight}`}>
                            <i
                            className={
                                "fa " +
                                (formDataFieldsFourthSection?.HasAppliedForSept1InventoryAppraisal == true
                                ? "fa-check-square-o"
                                : "fa-square-o")
                            }
                            ></i>{" "}
                            &nbsp; Yes &nbsp; &nbsp;
                            <i
                            className={
                                " fa " +
                                (!formDataFieldsFourthSection?.HasAppliedForSept1InventoryAppraisal == false
                                ? "fa-check-square-o"
                                : "fa-square-o")
                            }
                            ></i>{" "}
                            &nbsp; No
                        </div>
                    </div>
                    
                    <div className={shared.Row}>
                        <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>3.</div>
                        <div className={`${shared.Column9} ${shared.TextSmall}`}>
                            Were portions of your inventory transported out of this state throughout last year?
                        </div>
                        <div className={`${shared.Column2} ${shared.TextRight}`}>
                            <i
                            className={
                                "fa " +
                                (formDataFieldsFourthSection?.PortionsOfInventoryTransportedThroughoutLastYear == true
                                ? "fa-check-square-o"
                                : "fa-square-o")
                            }
                            ></i>{" "}
                            &nbsp; Yes &nbsp; &nbsp;
                            <i
                            className={
                                " fa " +
                                (!formDataFieldsFourthSection?.PortionsOfInventoryTransportedThroughoutLastYear == false
                                ? "fa-check-square-o"
                                : "fa-square-o")
                            }
                            ></i>{" "}
                            &nbsp; No
                        </div>
                    </div>
                    <div className={shared.Row}>
                        <div className={`${shared.Column8} ${shared.TextSmall} ${shared.MarginLeft20}`}>
                            If no, because inventory was transported only part of year, give the months 
                            during which portions of your inventory were transported out of the state last year.
                        </div>
                        <div className={`${shared.Column2} ${shared.TextRight}`}>
                            <div className={shared.Value}>
                                {formDataFieldsFourthSection?.PortionsOfInventoryTransportedMonths}
                            </div>
                        </div>
                    </div>

                    <div className={shared.Row}>
                        <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>4.</div>
                        <div className={`${shared.Column6WithoutPadding} ${shared.TextSmall}`}>
                            Total cost of goods sold for the entire year ending Dec. 31,
                        </div>
                        <div className={shared.Column1SmallPadding}>
                            <div className={shared.Value}>
                                {formDataFieldsFourthSection?.TotalCostOfGoodsYear}
                            </div>
                        </div>
                        <div className={shared.Column2}></div>
                        <div className={`${shared.Column2} ${shared.TextRight}`}>
                            <div className={shared.Value}>
                                ${formDataFieldsFourthSection?.TotalCostOfGoodsSoldForYear}
                            </div>
                        </div>
                    </div>
                    <br />

                    <div className={shared.Row}>
                        <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>5.</div>
                        <div className={`${shared.Column9} ${shared.TextSmall}`}>
                            Total cost of goods sold that were shipped out of Texas within the applicable 
                            time frame after acquisition or being brought into Texas last year, less the 
                            cost of any goods, raw materials or supplies incorporated into them that were 
                            not eligible for the freeport exemption or were in Texas for more than the 
                            applicable number of days
                        </div>
                        <div className={`${shared.Column2} ${shared.TextRight}`}>
                            <div className={shared.Value}>
                                ${formDataFieldsFourthSection?.TotalCostOfGoodsShippedOutOfTexas}
                            </div>
                        </div>
                    </div>
                    <br />

                    <div className={shared.Row}>
                        <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>6.</div>
                        <div className={`${shared.Column11} ${shared.TextSmall}`}>
                            On what types of records do you base the amounts given above? &nbsp;
                            <i>(Check all that apply.)</i>
                        </div>
                    </div>
                    <div className={shared.Row}>
                        <span className={shared.Column4}>
                            <i
                            className={
                                "fa " +
                                (formDataFieldsFourthSection?.TypesOfRecords?.AuditedFinancialStatement
                                ? "fa-check-square-o"
                                : "fa-square-o")
                            }
                            ></i>
                            Audited financial statement
                        </span>
                        <span className={shared.Column4}>
                            <i
                            className={
                                " fa " +
                                (formDataFieldsFourthSection?.TypesOfRecords?.SalesRecords
                                ? "fa-check-square-o"
                                : "fa-square-o")
                            }
                            ></i>
                            Sales records
                        </span>
                        <span className={shared.Column4}>
                            <i
                            className={
                                " fa " +
                                (formDataFieldsFourthSection?.TypesOfRecords?.InternalReports
                                ? "fa-check-square-o"
                                : "fa-square-o")
                            }
                            ></i>
                            Internal reports
                        </span>
                    </div>
                    <div className={`${shared.Row} ${shared.SpaceBetween}`}>
                        <span className={shared.Column3}>
                            <i
                            className={
                                "fa " +
                                (formDataFieldsFourthSection?.TypesOfRecords?.BillsOfLading
                                ? "fa-check-square-o"
                                : "fa-square-o")
                            }
                            ></i>
                            Bills of lading
                        </span>
                        <span className={shared.Column3}>
                            <i
                            className={
                                " fa " +
                                (formDataFieldsFourthSection?.TypesOfRecords?.TexasFranchiseTaxReports
                                ? "fa-check-square-o"
                                : "fa-square-o")
                            }
                            ></i>
                            Texas franchise tax reports
                        </span>
                        <span className={shared.Column3}>
                            <i
                            className={
                                " fa " +
                                (formDataFieldsFourthSection?.TypesOfRecords?.Other
                                ? "fa-check-square-o"
                                : "fa-square-o")
                            }
                            ></i>
                            Other <i>(describe)</i>
                            <div className={shared.Value}>
                                {formDataFieldsFourthSection?.TypesOfRecordsOther}
                            </div>
                        </span>
                    </div>
                    <br />

                    <div className={shared.Row}>
                        <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>7.</div>
                        <div className={`${shared.Column9} ${shared.TextSmall}`}>
                            Percentage of last year’s value represented by freeport goods &nbsp;&nbsp;
                            <i>(line 5 divided by line 4)</i>.
                        </div>
                        <div className={`${shared.Column2} ${shared.TextRight}`}>
                            <div className={shared.Value}>
                                {formDataFieldsFourthSection?.PercentageOfLastYearsValue}%
                            </div>
                        </div>
                    </div>
                    <br />

                    <div className={shared.Row}>
                        <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>8.</div>
                        <div className={`${shared.Column9} ${shared.TextSmall}`}>
                            Will the percentage of goods transported out of Texas this year be 
                            significantly different than the percentage transported out last year?
                        </div>
                        <div className={`${shared.Column2} ${shared.TextRight}`}>
                            <i
                            className={
                                "fa " +
                                (formDataFieldsFourthSection?.PercentageOfGoodsTransportedThisYear == true
                                ? "fa-check-square-o"
                                : "fa-square-o")
                            }
                            ></i>{" "}
                            &nbsp; Yes &nbsp; &nbsp;
                            <i
                            className={
                                " fa " +
                                (!formDataFieldsFourthSection?.PercentageOfGoodsTransportedThisYear == false
                                ? "fa-check-square-o"
                                : "fa-square-o")
                            }
                            ></i>{" "}
                            &nbsp; No
                        </div>
                    </div>
                    <div className={shared.Row1}>
                        <div className={`${shared.Column9} ${shared.TextRight} ${shared.TextSmall}`}>
                            If yes, why?
                        </div>
                        <div className={`${shared.Column3} ${shared.TextRight}`}>
                            <div className={shared.Value}>
                                {formDataFieldsFourthSection?.PortionsOfInventoryTransportedThroughoutLastYearIfYes}
                            </div>
                        </div>
                    </div>
                    <br/>
                    
                    <div className={shared.Row}>
                        <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>9.</div>
                        <div className={`${shared.Column9} ${shared.TextSmall}`}>
                            What was the market value of your inventory on Jan. 1 of this year, 
                            or Sept. 1 of last year if qualified for Sept. 1 inventory appraisal?
                        </div>
                        <div className={`${shared.Column2} ${shared.TextRight}`}>
                            <div className={shared.Value}>
                                ${formDataFieldsFourthSection?.MarketValueOfInventory}
                            </div>
                        </div>
                    </div>
                    <br />
                    
                    <div className={shared.Row}>
                        <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>10.</div>
                        <div className={`${shared.Column9} ${shared.TextSmall}`}>
                            What is the value of the inventory you claim will be exempt this year?
                        </div>
                        <div className={`${shared.Column2} ${shared.TextRight}`}>
                            <div className={shared.Value}>
                                ${formDataFieldsFourthSection?.ValueOfExemptInventory}
                            </div>
                        </div>
                    </div>                 
                </div>

                <div className={shared.Step}>
                    SECTION 4: Additional Documents to be Provided
                </div>
                <div>
                    <div className={shared.TextSmall}>
                        You are required to attach to this application a description 
                        of the types of items in the inventory.
                    </div>
                </div>

                <div className={shared.Step}>
                    SECTION 5: Certification and Signature
                </div>
                <div className={shared.PageBreak}>
                    <div>
                        <b>NOTICE REGARDING PENALTIES FOR MAKING OR FILING AN APPLICATION 
                        CONTAINING A FALSE STATEMENT: If you make a false statement on 
                        this form, you could be found guilty of a Class A misdemeanor 
                        or a state jail felony under Penal Code Section 37.10.</b>
                    </div>

                    <br />
                    <div className={shared.PrintedName}>
                        I{" "}
                        <div className={shared.Width60}>
                            {" "}
                            <div className={shared.Value}>
                                {formDataFieldsSixthSection?.Signature}
                            </div>
                            <div className={`${shared.ValueLabelBelow} ${shared.TextCenter}`}>
                                Printed Name of Property Owner or Authorized Representative
                            </div>
                        </div>
                        , swear or affirm the following:
                    </div>
                    <br />
                    <div className={shared.Row}>
                        <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>1.</div>
                        <div className={`${shared.Column11} ${shared.TextSmall}`}>
                            that each fact contained in this application is true and
                            correct;
                        </div>
                    </div>

                    <div className={shared.Row}>
                        <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>2.</div>
                        <div className={`${shared.Column11} ${shared.TextSmall}`}>
                            that the property described in this application meets the
                            qualifications under Texas law for the exemption claimed.
                        </div>
                    </div>

                    <br />

                    <div className={`${shared.Row} ${shared.SpaceBetween}`}>
                    <div className={shared.Column8}>
                        <div className={shared.Value}>
                            {formDataFieldsSixthSection?.Signature}
                        </div>
                        <div className={`${shared.ValueLabelBelow} ${shared.TextCenter}`}>
                            Signature of Property Owner or Authorized Representative
                        </div>
                    </div>
                    <div className={shared.Column3}>
                        <div className={shared.Value}>
                            {formDataFieldsSixthSection?.SignatureDate ? 
                                new Date(formDataFieldsSixthSection?.SignatureDate).toDateString() : ""}
                        </div>
                        <div className={`${shared.ValueLabelBelow} ${shared.TextCenter}`}>Date</div>
                    </div>
                    </div>

                    <br />
                    <div className={shared.Row}>
                        <div className={`${shared.Column11} ${shared.TextSmall}`}>
                        *Social security number disclosure may be required for tax administration and identification. (42 U.S.C. S405©(2)*C)(i); Tax Code S11.43(f)). A driver’s license number, personal identification number or social security number disclosed in an exemption application is confidential and not open to public inspection, except as authorized but Tax Code S11.48(b). 
                        </div>
                    </div>
                    <div className={shared.Row}>
                        <div className={`${shared.Column11} ${shared.TextSmall}`}>
                        **May be confidential under Government Code S552.137; however, by including the email address on this form, you are affirmatively consenting to its release under the Public Information Act.  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}
