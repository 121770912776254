import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "src/pages/Home/components/Forms/TimberlandAppraisalForm/TimberlandAppraisal.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { DatePickerChangeEvent, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, RadioGroupChangeEvent, Input, Checkbox, RadioGroup } from "@progress/kendo-react-inputs";
import { FormValidation } from "../InputValidator";
import { Error, Label } from "@progress/kendo-react-labels";
import { EmailInput } from "../CustomInputs/EmailInputs/EmailInput";
import { PhoneNumberInput } from "../CustomInputs/PhoneNumberInputs/PhoneNumberInput";
import { StatesInput } from "../CustomInputs/StatesInput";
import { ZipCodeInput } from "../CustomInputs/ZipCodeInput";
import { ITimberlandAppraisalFormSecondSection } from "src/interfaces/forms/TimberlandAppraisalForm/timberland-appraisal-form-second-section.interface";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: ITimberlandAppraisalFormSecondSection) => void;
    formDataFields?:  ITimberlandAppraisalFormSecondSection,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function TimberlandAppraisalFormSecondSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, setFormIsValid, isAdminPreview } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const [emailInputValid, setEmailInputValid] = useState<boolean>(false);
    const [propertyOwnerPhoneNumberInputValid, setPropertyOwnerPhoneNumberInputValid] = useState<boolean>(false);
    const [propertyOwnerPhysicalAddressStateInputValid, setPropertyOwnerPhysicalAddressStateInputValid] = useState<boolean>(false);
    const [propertyOwnerPhysicalAddressZipCodeInputValid, setPropertyOwnerPhysicalAddressZipCodeInputValid] = useState<boolean>(false);
    const [propertyOwnerMailingAddressStateInputValid, setPropertyOwnerMailingAddressStateInputValid] = useState<boolean>(false);
    const [propertyOwnerMailingAddressZipCodeInputValid, setPropertyOwnerMailingAddressZipCodeInputValid] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator } = FormValidation(formRef);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={2} name={"Property Owner/Applicant"}/>;

    const typeOfPropertyOwnerRadioButtonData = [
        { label: "Individual", value: "Individual" },
        { label: "Partnership", value: "Partnership" },
        { label: "Corporation", value: "Corporation" },
        { label: "Other", value: "Other" }
    ];

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string,
        group?: string
    ) {
        if (group) {
            setFormDataFields({
                ...formDataFields,
                [group]: {
                    ...(formDataFields as any)?.[group],
                    [field]: event.value
                }
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    function changeValidator() {
        if (formRef.current) {
            let isSectionValid = 
                propertyOwnerPhoneNumberInputValid &&
                emailInputValid &&
                propertyOwnerPhysicalAddressStateInputValid &&
                propertyOwnerPhysicalAddressZipCodeInputValid &&
                propertyOwnerMailingAddressStateInputValid &&
                propertyOwnerMailingAddressZipCodeInputValid;
            const isValid = formRef.current.checkValidity() && isSectionValid;
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    const saveAsPhysicalAddressHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        setFormDataFields({
            ...formDataFields,
            PropertyOwnerMailingAddressLine1: formDataFields?.PropertyOwnerPhysicalAddressLine1,
            PropertyOwnerMailingAddressLine2: formDataFields?.PropertyOwnerPhysicalAddressLine2,
            PropertyOwnerMailingAddressCity: formDataFields?.PropertyOwnerPhysicalAddressCity,
            PropertyOwnerMailingAddressState: formDataFields?.PropertyOwnerPhysicalAddressState,
            PropertyOwnerMailingAddressZip: formDataFields?.PropertyOwnerPhysicalAddressZip,
        });
    }

    function onTouch(){
        if (!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [
        formDataFields,
        formRef.current,
        propertyOwnerPhoneNumberInputValid,
        emailInputValid,
        propertyOwnerPhysicalAddressStateInputValid,
        propertyOwnerPhysicalAddressZipCodeInputValid,
        propertyOwnerMailingAddressStateInputValid,
        propertyOwnerMailingAddressZipCodeInputValid
    ]);

    return (
       <>
            <PanelBar keepItemsMounted={true}>
                <PanelBarItem title={title} expanded={true}>
                    <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                        <div className="container">
                            <div className={classes.item}>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Indicate type of property
                                    </Label>
                                    <div className={`${classes.inputsGroup} ${classes.fieldsGroupW100}`}>
                                        <RadioGroup
                                            name={"PropertyOwnerType"}
                                            layout={"horizontal"}
                                            disabled={formSettings?.isReadOnly}
                                            data={typeOfPropertyOwnerRadioButtonData}
                                            onChange={e => changeValueHandler(e, "PropertyOwnerType")}
                                            value={formDataFields?.PropertyOwnerType}
                                        />
                                    </div>
                                    {formDataFields?.PropertyOwnerType === "Other" &&
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <Label className={classes.Label}>
                                                Other (specify):
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                name={"PropertyOwnerTypeOther"}
                                                required={true}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.PropertyOwnerTypeOther}
                                                onChange={(e)=> changeValueHandler(e, "PropertyOwnerTypeOther")}
                                            />
                                            {!inputValidator("PropertyOwnerTypeOther") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div> }
                                </div>
                            </div>
                            <div className={`${classes.item}`}>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Name of Property Owner
                                            {!formDataFields?.PropertyOwnerName && (
                                                <i className={"fa fa-asterisk text-danger"}></i>
                                            )}
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            required={true}
                                            name={"PropertyOwnerName"}
                                            placeholder={"Name of Property Owner"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.PropertyOwnerName}
                                            onChange={(e)=> changeValueHandler(e, "PropertyOwnerName")}
                                        />
                                        {!inputValidator("PropertyOwnerName") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Phone Number
                                        </Label>
                                        <PhoneNumberInput
                                            required={false}
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"PropertyOwnerPhoneNumber"}
                                            placeholder={"Phone Number"}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                            setIsValidField={setPropertyOwnerPhoneNumberInputValid}
                                            validField={propertyOwnerPhoneNumberInputValid}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Email Address
                                        </Label>
                                        <EmailInput
                                            required={false}
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"PropertyOwnerEmailAddress"}
                                            placeholder={"Email Address"}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                            setIsValidField={setEmailInputValid}
                                            validField={emailInputValid}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.item}`}>
                                <div className={`${classes.addressFields} ${classes.fieldsGroup}`}>
                                    <div className={`${classes.fieldsGroupW50} ${classes.bordered}`}>
                                        <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                            <p>Physical Address</p>
                                        </div>
                                        <Label className={classes.Label}>
                                            Address Line 1
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"PropertyOwnerPhysicalAddressLine1"}
                                            placeholder={"Address Line 1"}
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.PropertyOwnerPhysicalAddressLine1}
                                            onChange={(e)=> changeValueHandler(e, "PropertyOwnerPhysicalAddressLine1")}
                                        />
                                        <Label className={classes.Label}>
                                            Address Line 2
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"PropertyOwnerPhysicalAddressLine2"}
                                            placeholder={"Address Line 2"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.PropertyOwnerPhysicalAddressLine2}
                                            onChange={(e)=> changeValueHandler(e, "PropertyOwnerPhysicalAddressLine2")}
                                        />
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <div className={`${classes.fieldsGroupW30}`}>
                                                <Label className={classes.Label}>
                                                    City
                                                </Label>
                                                <Input
                                                    className={classes.kTextbox}
                                                    required={false}
                                                    name={"PropertyOwnerPhysicalAddressCity"}
                                                    placeholder={"City"}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.PropertyOwnerPhysicalAddressCity}
                                                    onChange={(e)=> changeValueHandler(e, "PropertyOwnerPhysicalAddressCity")}
                                                />
                                            </div>
                                            <div className={`${classes.fieldsGroupW30}`}>
                                                <Label className={classes.Label}>
                                                    State
                                                </Label>
                                                <StatesInput
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"PropertyOwnerPhysicalAddressState"}
                                                    required={false}
                                                    validField={propertyOwnerPhysicalAddressStateInputValid}
                                                    setIsValidField={setPropertyOwnerPhysicalAddressStateInputValid}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                />
                                            </div>
                                            <div className={`${classes.fieldsGroupW20}`}>
                                                <Label className={classes.Label}>
                                                    Zip Code
                                                </Label>
                                                <ZipCodeInput
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"PropertyOwnerPhysicalAddressZip"}
                                                    required={false}
                                                    placeholder={"Zip"}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                    setIsValidField={setPropertyOwnerPhysicalAddressZipCodeInputValid}
                                                    validField={propertyOwnerPhysicalAddressZipCodeInputValid}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${classes.fieldsGroupW50} ${classes.bordered}`}>
                                        <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                            <p>Mailing Address</p>
                                            { !formSettings?.isReadOnly && !isAdminPreview &&
                                            <button className={`${classes.button} ${classes.buttonAddress}`} onClick={e => saveAsPhysicalAddressHandler(e)}>
                                                Copy Physical Address
                                            </button> }
                                        </div>
                                        <Label className={classes.Label}>
                                            Address Line 1
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"PropertyOwnerMailingAddressLine1"}
                                            placeholder={"Address Line 1"}
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.PropertyOwnerMailingAddressLine1}
                                            onChange={(e)=> changeValueHandler(e, "PropertyOwnerMailingAddressLine1")}
                                        />
                                        <Label className={classes.Label}>
                                            Address Line 2
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"PropertyOwnerMailingAddressLine2"}
                                            placeholder={"Address Line 2"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.PropertyOwnerMailingAddressLine2}
                                            onChange={(e)=> changeValueHandler(e, "PropertyOwnerMailingAddressLine2")}
                                        />
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <div className={`${classes.fieldsGroupW30}`}>
                                                <Label className={classes.Label}>
                                                    City
                                                </Label>
                                                <Input
                                                    className={classes.kTextbox}
                                                    required={false}
                                                    name={"PropertyOwnerMailingAddressCity"}
                                                    placeholder={"City"}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.PropertyOwnerMailingAddressCity}
                                                    onChange={(e)=> changeValueHandler(e, "PropertyOwnerMailingAddressCity")}
                                                />
                                            </div>
                                            <div className={`${classes.fieldsGroupW30}`}>
                                                <Label className={classes.Label}>
                                                    State
                                                </Label>
                                                <StatesInput
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"PropertyOwnerMailingAddressState"}
                                                    required={false}
                                                    validField={propertyOwnerMailingAddressStateInputValid}
                                                    setIsValidField={setPropertyOwnerMailingAddressStateInputValid}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                />
                                            </div>
                                            <div className={`${classes.fieldsGroupW20}`}>
                                                <Label className={classes.Label}>
                                                    Zip Code
                                                </Label>
                                                <ZipCodeInput
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"PropertyOwnerMailingAddressZip"}
                                                    required={false}
                                                    placeholder={"Zip"}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                    setIsValidField={setPropertyOwnerMailingAddressZipCodeInputValid}
                                                    validField={propertyOwnerMailingAddressZipCodeInputValid}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                   </form>
                </PanelBarItem>
            </PanelBar>
       </>
    )
}