import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "../WildlifeManagement.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, RadioGroupChangeEvent, Checkbox, Input, NumericTextBox, NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { FormValidation } from "../../InputValidator";
import { Calendar, CalendarChangeEvent, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { IWildlifeManagementFourthSectionSupplementalShelter } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-providing-supplemental-shelter.interface";
import { Label } from "@progress/kendo-react-labels";
import { CheckboxGroup } from "../../CustomInputs/CheckBoxes/CheckboxGroup";
import SectionTitle from "../../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    formSettings?: IFormSettingModel,
    formDataFields?: IWildlifeManagementFourthSectionSupplementalShelter;
    setFormDataFields: (form: IWildlifeManagementFourthSectionSupplementalShelter) => void;
    setFormIsValid: (value: boolean) => void;
    setSectionIsValid: (value: boolean) => void;
}

export default function SupplementalShelterSubSection(props: GeneralFormType): JSX.Element {
    const { formSettings, isAdminPreview, formDataFields, setFormDataFields, setFormIsValid, setSectionIsValid } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator, setStyles } = FormValidation(formRef);
    const [fenceLineManagementInitialEstablishmentIsValid, setFenceLineManagementInitialEstablishmentIsValid] = 
        useState<boolean>(false);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={6} name={"Providing Supplemental Shelter"}/>;
    
    function changeValidator() {
        if (formRef.current) {
            const isValid = formRef.current.checkValidity();
            setIsValid(isValid);
            setSectionIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent
            | NumericTextBoxChangeEvent,
        field: string,
        group?: string
    ) {
        if (group) {
            setFormDataFields({
                ...formDataFields,
                [group]: {
                    ...(formDataFields as any)?.[group],
                    [field]: event.value
                }
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    function onTouch(){
        if (!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [ formDataFields, formRef.current ]);

    return (
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.NestBoxes}
                                    label={"Nest Boxes"}
                                    onChange={(e)=> changeValueHandler(e, "NestBoxes")}
                                />
                            </div>
                            { formDataFields?.NestBoxes && <> 
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Target Species
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"NestBoxesTargetSpecies"}
                                            required={false}
                                            placeholder={"Target Species"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.NestBoxesTargetSpecies}
                                            onChange={(e)=> changeValueHandler(e, "NestBoxesTargetSpecies")}
                                        />
                                    </div>
                                </div>
                                <div className={`${classes.itemFields} ${classes.flexRow} ${classes.fieldsGroup} ${classes.fieldsGroupW100}`}>
                                    <div className={classes.fieldsGroupW33}>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <div className={classes.fieldsGroupW50}>
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.NestBoxesCavityType}
                                                    label={"Cavity Type"}
                                                    onChange={(e)=> changeValueHandler(e, "NestBoxesCavityType")}
                                                />
                                            </div>
                                            { formDataFields?.NestBoxesCavityType &&
                                                <div className={classes.fieldsGroupW50}>
                                                    <NumericTextBox
                                                        name={"NestBoxesCavityTypeNumber"}
                                                        required={false}
                                                        disabled={formSettings?.isReadOnly}
                                                        className={!inputValidator("NestBoxesCavityTypeNumber") ? 
                                                            classes.invalidState + " " + classes.kTextbox : classes.kTextbox}
                                                        value={formDataFields?.NestBoxesCavityTypeNumber}
                                                        onChange={(e) => changeValueHandler(e, "NestBoxesCavityTypeNumber")}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={classes.fieldsGroupW33}>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <div className={classes.fieldsGroupW50}>
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.NestBoxesBatBoxes}
                                                    label={"Bat Boxes"}
                                                    onChange={(e)=> changeValueHandler(e, "NestBoxesBatBoxes")}
                                                />
                                            </div>
                                            { formDataFields?.NestBoxesBatBoxes &&
                                            <div className={classes.fieldsGroupW50}>
                                                <NumericTextBox
                                                    name={"NestBoxesBatBoxesNumber"}
                                                    required={false}
                                                    disabled={formSettings?.isReadOnly}
                                                    className={!inputValidator("NestBoxesBatBoxesNumber") ? 
                                                        classes.invalidState + " " + classes.kTextbox : classes.kTextbox}
                                                    value={formDataFields?.NestBoxesBatBoxesNumber}
                                                    onChange={(e) => changeValueHandler(e, "NestBoxesBatBoxesNumber")}
                                                />
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={classes.fieldsGroupW33}>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <div className={classes.fieldsGroupW50}>
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.NestBoxesRaptorPole}
                                                    label={"Raptor Pole"}
                                                    onChange={(e)=> changeValueHandler(e, "NestBoxesRaptorPole")}
                                                />
                                            </div>
                                            { formDataFields?.NestBoxesRaptorPole &&
                                            <div className={classes.fieldsGroupW50}>
                                                <NumericTextBox
                                                    name={"NestBoxesRaptorPoleNumber"}
                                                    required={false}
                                                    disabled={formSettings?.isReadOnly}
                                                    className={!inputValidator("NestBoxesRaptorPoleNumber") ? 
                                                        classes.invalidState + " " + classes.kTextbox : classes.kTextbox}
                                                    value={formDataFields?.NestBoxesRaptorPoleNumber}
                                                    onChange={(e) => changeValueHandler(e, "NestBoxesRaptorPoleNumber")}
                                                />
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div></>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"NestBoxesAdditionalInformation"}
                                    placeholder={"Additional Information"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.NestBoxesAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "NestBoxesAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.BrushPilesAndSlashRetention}
                                    label={"Brush Piles and Slash Retention"}
                                    onChange={(e)=> changeValueHandler(e, "BrushPilesAndSlashRetention")}
                                />
                            </div>
                            { formDataFields?.BrushPilesAndSlashRetention && <>
                                <div className={classes.fieldsGroupW50}>
                                    <Label className={classes.Label}>
                                        Type:
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.BrushPilesAndSlashRetentionTypes?.Slash}
                                            label={"Slash"}
                                            onChange={(e)=> changeValueHandler(e, "Slash", "BrushPilesAndSlashRetentionTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.BrushPilesAndSlashRetentionTypes?.BrushPiles}
                                            label={"Brush Piles"}
                                            onChange={(e)=> changeValueHandler(e, "BrushPiles", "BrushPilesAndSlashRetentionTypes")}
                                        />
                                    </div>
                                </div>
                                <div className={classes.fieldsGroupW50}>
                                    <Label className={classes.Label}>
                                        Number Per Acre
                                    </Label>
                                    <NumericTextBox
                                        name={"BrushPilesAndSlashRetentionNumberPerAcre"}
                                        required={false}
                                        disabled={formSettings?.isReadOnly}
                                        className={!inputValidator("BrushPilesAndSlashRetentionNumberPerAcre") ? 
                                            classes.invalidState + " " + classes.kTextbox : classes.kTextbox}
                                        value={formDataFields?.BrushPilesAndSlashRetentionNumberPerAcre}
                                        onChange={(e) => changeValueHandler(e, "BrushPilesAndSlashRetentionNumberPerAcre")}
                                    />
                                </div>
                                </>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"BrushPilesAndSlashRetentionAdditionalInformation"}
                                    placeholder={"Additional Information"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.BrushPilesAndSlashRetentionAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "BrushPilesAndSlashRetentionAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.FenceLineManagement}
                                    label={"Fence Line Management"}
                                    onChange={(e)=> changeValueHandler(e, "FenceLineManagement")}
                                />
                            </div>
                            { formDataFields?.FenceLineManagement && <>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup} ${classes.fieldsGroupW100}`}>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Length
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"FenceLineManagementLength"}
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.FenceLineManagementLength}
                                            onChange={(e)=> changeValueHandler(e, "FenceLineManagementLength")}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW50}>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <Label className={`${classes.Label}`}>
                                                Initial Establishment:
                                            </Label>
                                            <div className={classes.checkboxGroup}>
                                                <CheckboxGroup
                                                    isAdminPreview={isAdminPreview}
                                                    setFormDataFields={setFormDataFields}
                                                    formDataFields={formDataFields}
                                                    formSettings={formSettings}
                                                    fieldName={"FenceLineManagementInitialEstablishment"}
                                                    fieldSecondName={"FenceLineManagementInitialEstablishmentSecond"}
                                                    isRequired={false}
                                                    checkboxesIsValid={fenceLineManagementInitialEstablishmentIsValid}
                                                    setCheckboxesIsValid={setFenceLineManagementInitialEstablishmentIsValid}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.fieldsGroupW100}>
                                    <Label className={classes.Label}>
                                        Plant type established:
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.FenceLineManagementPlantTypesEstablished?.Trees}
                                            label={"Trees"}
                                            onChange={(e)=> changeValueHandler(e, "Trees", "FenceLineManagementPlantTypesEstablished")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.FenceLineManagementPlantTypesEstablished?.Shrubs}
                                            label={"Shrubs"}
                                            onChange={(e)=> changeValueHandler(e, "Shrubs", "FenceLineManagementPlantTypesEstablished")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.FenceLineManagementPlantTypesEstablished?.Forbs}
                                            label={"Forbs"}
                                            onChange={(e)=> changeValueHandler(e, "Forbs", "FenceLineManagementPlantTypesEstablished")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.FenceLineManagementPlantTypesEstablished?.Grasses}
                                            label={"Grasses"}
                                            onChange={(e)=> changeValueHandler(e, "Grasses", "FenceLineManagementPlantTypesEstablished")}
                                        />
                                    </div>
                                </div>
                                </>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"FenceLineManagementAdditionalInformation"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.FenceLineManagementAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "FenceLineManagementAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.HayMeadowPastureAndCorplandManagement}
                                    label={"Hay meadow, pasture and cropland management for wildlife"}
                                    onChange={(e)=> changeValueHandler(e, "HayMeadowPastureAndCorplandManagement")}
                                />
                            </div>
                            { formDataFields?.HayMeadowPastureAndCorplandManagement && <>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Acres Treated
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"HayMeadowPastureAndCorplandManagementAcresTreated"}
                                        required={false}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.HayMeadowPastureAndCorplandManagementAcresTreated}
                                        onChange={(e)=> changeValueHandler(e, "HayMeadowPastureAndCorplandManagementAcresTreated")}
                                    />
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Shelter establishment:
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HayMeadowPastureAndCorplandManagementShelterEstablishmentTypes?.RoadsideManagement}
                                            label={"Roadside management"}
                                            onChange={(e)=> 
                                                changeValueHandler(e, "RoadsideManagement", "HayMeadowPastureAndCorplandManagementShelterEstablishmentTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HayMeadowPastureAndCorplandManagementShelterEstablishmentTypes?.TerraceWindBreaks}
                                            label={"Terrace/wind breaks"}
                                            onChange={(e)=> 
                                                changeValueHandler(e, "TerraceWindBreaks", "HayMeadowPastureAndCorplandManagementShelterEstablishmentTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HayMeadowPastureAndCorplandManagementShelterEstablishmentTypes?.FieldBorders}
                                            label={"Field borders"}
                                            onChange={(e)=> 
                                                changeValueHandler(e, "FieldBorders", "HayMeadowPastureAndCorplandManagementShelterEstablishmentTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HayMeadowPastureAndCorplandManagementShelterEstablishmentTypes?.Shelterbelts}
                                            label={"Shelterbelts"}
                                            onChange={(e)=> 
                                                changeValueHandler(e, "Shelterbelts", "HayMeadowPastureAndCorplandManagementShelterEstablishmentTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HayMeadowPastureAndCorplandManagementShelterEstablishmentTypes?.ConservationReserveProgramLandsManagement}
                                            label={"Conservation Reserve Program lands management"}
                                            onChange={(e)=> 
                                                changeValueHandler(e, "ConservationReserveProgramLandsManagement", "HayMeadowPastureAndCorplandManagementShelterEstablishmentTypes")}
                                        />
                                    </div>
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Type of Vegetation:
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HayMeadowPastureAndCorplandManagementVegetationTypes?.Annual}
                                            label={"Annual"}
                                            onChange={(e)=> 
                                                changeValueHandler(e, "Annual", "HayMeadowPastureAndCorplandManagementVegetationTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HayMeadowPastureAndCorplandManagementVegetationTypes?.Perennial}
                                            label={"Perennial"}
                                            onChange={(e)=> 
                                                changeValueHandler(e, "Perennial", "HayMeadowPastureAndCorplandManagementVegetationTypes")}
                                        />
                                    </div>
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Species and percent of mixture
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"HayMeadowPastureAndCorplandManagementSpeciesAndPercentOfMixture"}
                                        placeholder={"Species and percent of mixture"}
                                        required={false}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.HayMeadowPastureAndCorplandManagementSpeciesAndPercentOfMixture}
                                        onChange={(e)=> changeValueHandler(e, "HayMeadowPastureAndCorplandManagementSpeciesAndPercentOfMixture")}
                                    />
                                </div>
                                </>
                            }
                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                <div className={classes.fieldsGroupW50}>
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.DeferredMowing}
                                        label={"Deferred Mowing"}
                                        onChange={(e)=> changeValueHandler(e, "DeferredMowing")}
                                    />
                                </div>
                                { formDataFields?.DeferredMowing &&
                                <div className={classes.fieldsGroupW50}>
                                    <Label className={classes.Label}>
                                        Period of Deferment
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"DeferredMowingPeriodOfDeferment"}
                                        placeholder={"Period of Deferment"}
                                        required={false}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.DeferredMowingPeriodOfDeferment}
                                        onChange={(e)=> changeValueHandler(e, "DeferredMowingPeriodOfDeferment")}
                                    />
                                </div>
                                }
                            </div>
                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                <div className={classes.fieldsGroupW50}>
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.Mowing}
                                        label={"Mowing"}
                                        onChange={(e)=> changeValueHandler(e, "Mowing")}
                                    />
                                </div>
                                { formDataFields?.Mowing &&
                                <div className={classes.fieldsGroupW50}>
                                    <Label className={classes.Label}>
                                        Acres mowed annually
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"MowingAcresMowedAnnually"}
                                        placeholder={"Acres mowed annually"}
                                        required={false}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.MowingAcresMowedAnnually}
                                        onChange={(e)=> changeValueHandler(e, "MowingAcresMowedAnnually")}
                                    />
                                </div>
                                }
                            </div>
                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.NoTillMinimumTill}
                                    label={"No Till/Minimum Till"}
                                    onChange={(e)=> changeValueHandler(e, "NoTillMinimumTill")}
                                />
                            </div>
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"HayMeadowPastureAndCorplandManagementAdditionalInformation"}
                                    placeholder={"Additional Information"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.HayMeadowPastureAndCorplandManagementAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "HayMeadowPastureAndCorplandManagementAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.HalfCuttingTreesOrShrubs}
                                    label={"Half-cutting Trees or Shrubs"}
                                    onChange={(e)=> changeValueHandler(e, "HalfCuttingTreesOrShrubs")}
                                />
                            </div>
                            { formDataFields?.HalfCuttingTreesOrShrubs && <> 
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Acreage to be treated annually
                                        </Label>
                                        <NumericTextBox
                                            name={"HalfCuttingTreesOrShrubsAcreageToBeTreatedAnnually"}
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            className={!inputValidator("HalfCuttingTreesOrShrubsAcreageToBeTreatedAnnually") ? 
                                                classes.invalidState + " " + classes.kTextbox : classes.kTextbox}
                                            value={formDataFields?.HalfCuttingTreesOrShrubsAcreageToBeTreatedAnnually}
                                            onChange={(e) => changeValueHandler(e, "HalfCuttingTreesOrShrubsAcreageToBeTreatedAnnually")}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Number of half-cuts annually
                                        </Label>
                                        <NumericTextBox
                                            name={"HalfCuttingTreesOrShrubsNumberOfHalfCutsAnnually"}
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            className={!inputValidator("HalfCuttingTreesOrShrubsNumberOfHalfCutsAnnually") ? 
                                                classes.invalidState + " " + classes.kTextbox : classes.kTextbox}
                                            value={formDataFields?.HalfCuttingTreesOrShrubsNumberOfHalfCutsAnnually}
                                            onChange={(e) => changeValueHandler(e, "HalfCuttingTreesOrShrubsNumberOfHalfCutsAnnually")}
                                        />
                                    </div>
                                </div></>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"HalfCuttingTreesOrShrubsAdditionalInformation"}
                                    placeholder={"Additional Information"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.HalfCuttingTreesOrShrubsAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "HalfCuttingTreesOrShrubsAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.WoodyPlantShrubEstablishment}
                                    label={"Woody plant/shrub establishment"}
                                    onChange={(e)=> changeValueHandler(e, "WoodyPlantShrubEstablishment")}
                                />
                            </div>
                            { formDataFields?.WoodyPlantShrubEstablishment && <>                            
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Pattern:
                                        </Label>
                                        <div className={classes.inputsGroup}>
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.WoodyPlantShrubEstablishmentPatterns?.Block}
                                                label={"Block"}
                                                onChange={(e)=> changeValueHandler(e, "Block", "WoodyPlantShrubEstablishmentPatterns")}
                                            />
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.WoodyPlantShrubEstablishmentPatterns?.Mosaic}
                                                label={"Mosaic"}
                                                onChange={(e)=> changeValueHandler(e, "Mosaic", "WoodyPlantShrubEstablishmentPatterns")}
                                            />
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.WoodyPlantShrubEstablishmentPatterns?.Strips}
                                                label={"Strips"}
                                                onChange={(e)=> changeValueHandler(e, "Strips", "WoodyPlantShrubEstablishmentPatterns")}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Width (strips)
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"WoodyPlantShrubEstablishmentStripsWidth"}
                                            required={false}
                                            placeholder={"Width (strips)"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.WoodyPlantShrubEstablishmentStripsWidth}
                                            onChange={(e)=> changeValueHandler(e, "WoodyPlantShrubEstablishmentStripsWidth")}
                                        />
                                    </div>
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Acreage or length established annually
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"WoodyPlantShrubEstablishmentAcreageOrLengthEstablishedAnnually"}
                                            required={false}
                                            placeholder={"Acreage or length established annually"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.WoodyPlantShrubEstablishmentAcreageOrLengthEstablishedAnnually}
                                            onChange={(e)=> changeValueHandler(e, "WoodyPlantShrubEstablishmentAcreageOrLengthEstablishedAnnually")}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Spacing
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"WoodyPlantShrubEstablishmentSpacing"}
                                            required={false}
                                            placeholder={"Spacing"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.WoodyPlantShrubEstablishmentSpacing}
                                            onChange={(e)=> changeValueHandler(e, "WoodyPlantShrubEstablishmentSpacing")}
                                        />
                                    </div>
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Shrub/tree species used
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"WoodyPlantShrubEstablishmentShrubsTreeSpeciesUsed"}
                                        required={false}
                                        placeholder={"Shrub/tree species used"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.WoodyPlantShrubEstablishmentShrubsTreeSpeciesUsed}
                                        onChange={(e)=> changeValueHandler(e, "WoodyPlantShrubEstablishmentShrubsTreeSpeciesUsed")}
                                    />
                                </div></>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"WoodyPlantShrubEstablishmentAdditionalInformation"}
                                    placeholder={"Additional Information"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.WoodyPlantShrubEstablishmentAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "WoodyPlantShrubEstablishmentAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.NaturalCavitySnagDevelopment}
                                    label={"Natural cavity/snag development"}
                                    onChange={(e)=> changeValueHandler(e, "NaturalCavitySnagDevelopment")}
                                />
                            </div>
                            { formDataFields?.NaturalCavitySnagDevelopment && 
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW33}>
                                        <Label className={classes.Label}>
                                            Species of snag
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"NaturalCavitySnagDevelopmentSpeciesOfSnag"}
                                            required={false}
                                            placeholder={"Species of snag"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.NaturalCavitySnagDevelopmentSpeciesOfSnag}
                                            onChange={(e)=> changeValueHandler(e, "NaturalCavitySnagDevelopmentSpeciesOfSnag")}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW33}>
                                        <Label className={classes.Label}>
                                            Size of snags
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"NaturalCavitySnagDevelopmentSizeOfSnags"}
                                            required={false}
                                            placeholder={"Size of snags"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.NaturalCavitySnagDevelopmentSizeOfSnags}
                                            onChange={(e)=> changeValueHandler(e, "NaturalCavitySnagDevelopmentSizeOfSnags")}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW33}>
                                        <Label className={classes.Label}>
                                            Number/acre
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"NaturalCavitySnagDevelopmentNumberAcre"}
                                            required={false}
                                            placeholder={"Number/acre"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.NaturalCavitySnagDevelopmentNumberAcre}
                                            onChange={(e)=> changeValueHandler(e, "NaturalCavitySnagDevelopmentNumberAcre")}
                                        />
                                    </div>
                                </div>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"NaturalCavitySnagDevelopmentAdditionalInformation"}
                                    placeholder={"Additional Information"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.NaturalCavitySnagDevelopmentAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "NaturalCavitySnagDevelopmentAdditionalInformation")}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    )
}