import shared from "./PDF-Shared-style.module.scss";

export function GetFooter(pageNumber: number) {
    return <>
        <div className={shared.PageFooter}>
            <div className={`${shared.Row} ${shared.FooterPadding}`}>
            <div className={shared.Column12}>
                <hr className={shared.Hr}/>
            </div>
            <div className={`${shared.ColumnFooterText} ${shared.TextRight} ${shared.Footer} ${shared.Font7}`}>
                For additional copies, visit:{" "} <b>comptroller.texas.gov/taxes/property-tax</b>
            </div>
            <div className={`${shared.Column2} ${shared.TextRight} ${shared.Footer} ${shared.Font7}`}>
                Page {pageNumber}
            </div>
            </div>
        </div>
    </>;
}