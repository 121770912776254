import { useTranslation } from "react-i18next";
import ConfirmDialog from "../ConfirmDialog";
import styles from "./UnsavedFormDialog.module.scss";

export interface UnsavedFormDialogPropsType {
  onConfirm: () => void;
  onCancel: () => void;
}

const UnsavedFormDialog = (props: UnsavedFormDialogPropsType) => {
  const { t } = useTranslation();

  return (
    <ConfirmDialog
      title={t("changeThePage")}
      onSubmit={props.onConfirm}
      onClose={props.onCancel}
    >
      <ul className={styles.UnsavedFormMessage}>
        <li>Your form has not been submitted to the appraisal district</li>
        <li>
          You must click the SUBMIT button in order for your form to be
          submitted to the appraisal district for review and processing
        </li>
        <li>
          If you are not ready to submit the form, you can click Save to save
          your progress
        </li>
      </ul>
    </ConfirmDialog>
  );
};

export default UnsavedFormDialog;
