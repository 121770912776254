import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useTranslation } from "react-i18next";

export interface CustomDialogPropsType {
  title?: string;
  onClose: () => void;
  onSubmit?: () => void;
  content: JSX.Element;
  actionButtons?: JSX.Element;
  isForm: boolean;
  className?: string;
  width?: number;
  height?: number;
}

const DialogWithoutPadding = (props: CustomDialogPropsType): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Dialog
      title={props.title}
      onClose={props.onClose}
      className={props.className}
      width={props.width}
      height={props.height}
    >
      {props.content}
      {!props.isForm && (
        <DialogActionsBar>
          {props.actionButtons && props.actionButtons}
          {!props.actionButtons && (
            <>
              <Button className="k-button" onClick={props.onClose}>
                {t("form.close")}
              </Button>
              <Button className="default-btn" onClick={props.onSubmit}>
                {t("form.submit")}
              </Button>
            </>
          )}
        </DialogActionsBar>
      )}
    </Dialog>
  );
};

export default DialogWithoutPadding;
