import { CheckboxChangeEvent, InputChangeEvent, NumericTextBox, NumericTextBoxChangeEvent, RadioGroupChangeEvent, TextAreaChangeEvent } from "@progress/kendo-react-inputs";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import classes from "../../BPPForm/BPPForm.module.scss";
import { ITimberlandAppraisalFormFifthSection } from "src/interfaces/forms/TimberlandAppraisalForm/timberland-appraisal-form-fifth-section.interface";
import { DatePickerChangeEvent, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";

interface GeneralFormType {
    formDataFields: ITimberlandAppraisalFormFifthSection | undefined;
    formSettings: IFormSettingModel | undefined;
    changeValueHandler: (
        event: | CheckboxChangeEvent
        | DatePickerChangeEvent
        | TextAreaChangeEvent
        | DropDownListChangeEvent
        | InputChangeEvent
        | RadioGroupChangeEvent
        | CalendarChangeEvent
        | NumericTextBoxChangeEvent,
        field: string
    ) => void;
}

export function AgriculturalUseForestTypesTable(
    props: GeneralFormType
): JSX.Element {
    return (
        <table className="blue">
            <thead>
            <tr>
                <th className="step">
                    Forest Type
                </th>
                <th>Number of Acres</th>
            </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        Pine forest <i>(Pine and other softwood trees make up at least 
                        two-thirds of the free-to-grow trees.)</i>
                    </td>
                    <td>
                        <NumericTextBox
                            name={"Acres"}
                            className={classes.kTextbox}
                            spinners={false}
                            disabled={props.formSettings?.isReadOnly}
                            value={props.formDataFields?.AgriculturalUsePineForestAcres}
                            onChange={(e) =>
                                props.changeValueHandler(e, "AgriculturalUsePineForestAcres")
                            }
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        Hardwood forest <i>(Hardwood trees are at least two-thirds 
                        of the free-to-grow trees.)</i>
                    </td>
                    <td>
                        <NumericTextBox
                            name={"Acres"}
                            className={classes.kTextbox}
                            spinners={false}
                            disabled={props.formSettings?.isReadOnly}
                            value={props.formDataFields?.AgriculturalUseHardwoodForestAcres}
                            onChange={(e) =>
                                props.changeValueHandler(e, "AgriculturalUseHardwoodForestAcres")
                            }
                        />
                    </td>
                </tr>
                <tr>
                    <td>
                        Mixed Hardwood forest <i>(Neither soft nor hardwood trees make up 
                        more than two-thirds of the free-to-grow trees.)</i>
                    </td>
                    <td>
                        <NumericTextBox
                            name={"Acres"}
                            className={classes.kTextbox}
                            spinners={false}
                            disabled={props.formSettings?.isReadOnly}
                            value={props.formDataFields?.AgriculturalUseMixedHardwoodForestAcres}
                            onChange={(e) =>
                                props.changeValueHandler(e, "AgriculturalUseMixedHardwoodForestAcres")
                            }
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    );
}
