export type IAgFormEighthSectionInterface = {
    LandWasConvertedToTemberAfter1997?: boolean,
    DateConvertedToTember?: string,
    WantsLandToBeAppraisedAs1D1?: boolean

}

export const AgFormEighthSectionInitialState = (): IAgFormEighthSectionInterface => ({
    LandWasConvertedToTemberAfter1997: undefined,
    DateConvertedToTember: undefined,
    WantsLandToBeAppraisedAs1D1: undefined,
})