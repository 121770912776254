export type IExemptionPropertyDamagedFormFourthSection = {
    TypeOfProperty?: string;
    DatePropertyPurchased?: string;
    TypeOfDisaster?: string;
    PhysicalAddressLine1?: string;
    PhysicalAddressLine2?: string;
    PhysicalAddressCity?: string;
    PhysicalAddressState?: string;
    PhysicalAddressZip?: string;
    ManufacturedHomeMake?: string;
    Model?: string;
    IdNumber?: string;
    NumberOfAcres?: string;
    AppraisalDistrictAccountNumber?: string;
}

export const ExemptionPropertyDamagedFormFourthSectionInterface = (): IExemptionPropertyDamagedFormFourthSection => ({
    TypeOfProperty: undefined,
    DatePropertyPurchased: undefined,
    TypeOfDisaster: undefined,
    PhysicalAddressLine1: undefined,
    PhysicalAddressLine2: undefined,
    PhysicalAddressCity: undefined,
    PhysicalAddressState: undefined,
    PhysicalAddressZip: undefined,
    ManufacturedHomeMake: undefined,
    Model: undefined,
    IdNumber: undefined,
    NumberOfAcres: undefined,
    AppraisalDistrictAccountNumber: undefined
})