import {IFormFilesInterface} from "../Shared/form-files.interface";

export type IAgFormSeventhSectionInterface = {
    IsUsingLandToManageWildlife?: boolean,
    WildlifeManagementPracticeA?: string,
    WildlifeManagementPracticeB?: string,
    WildlifeManagementPracticeC?: string,
    AgriculturalLandUseCategory?: string,
    wildlifeManagementPlanUsesTheAppropriateTexasParksandWildlifeDepartmen?: boolean,
    WildlifeManagementPlanDocumentation?: Array<IFormFilesInterface>,
    LandWasPartOfLargerTract?: boolean,
    PartOfLandIsManagedThroughPropertyAssociation?: boolean,
    PropertyOwnersAssociationAgreement?: Array<IFormFilesInterface>,
    PartOfLandIsInHabitatForEndangeredSpecies?: boolean,
    LandIsSubjectToPermit7or10a?: boolean,
    LandIsIncludedInHabitatPreserve?: boolean,
    ConservationEasmentEvidenceQ7?: Array<IFormFilesInterface>,
    IsComprehensiveEnvironmentalResponse?: boolean,
    IsOilPollutionAct?: boolean,
    IsFederalWaterPollutionAct?: boolean,
    IsChapter40?: boolean,
    ConservationEvidenceQ8?: Array<IFormFilesInterface>,
}

export const AgFormSeventhSectionInitialState = (): IAgFormSeventhSectionInterface => ({
    IsUsingLandToManageWildlife: undefined,
    WildlifeManagementPracticeA: undefined,
    WildlifeManagementPracticeB: undefined,
    WildlifeManagementPracticeC: undefined,
    AgriculturalLandUseCategory: undefined,
    WildlifeManagementPlanDocumentation: undefined,
    LandWasPartOfLargerTract: undefined,
    PartOfLandIsManagedThroughPropertyAssociation: undefined,
    PropertyOwnersAssociationAgreement: undefined,
    PartOfLandIsInHabitatForEndangeredSpecies: undefined,
    LandIsSubjectToPermit7or10a: undefined,
    LandIsIncludedInHabitatPreserve: undefined,
    ConservationEasmentEvidenceQ7: undefined,
    IsComprehensiveEnvironmentalResponse: undefined,
    IsOilPollutionAct: undefined,
    IsFederalWaterPollutionAct: undefined,
    IsChapter40: undefined,
    ConservationEvidenceQ8: undefined,
})