export type IFreeportFormFourthSectionInterface = {
    InventoryWillBeTransportedOutOfState?: boolean;
    HasAppliedForSept1InventoryAppraisal?: boolean;
    PortionsOfInventoryTransportedThroughoutLastYear?: boolean;
    PortionsOfInventoryTransportedMonths?: string;
    TotalCostOfGoodsYear?: number;
    TotalCostOfGoodsSoldForYear?: number;
    TotalCostOfGoodsShippedOutOfTexas?: number;
    TypesOfRecords?: ITypesOfRecords;
    TypesOfRecordsOther?: string;
    PercentageOfLastYearsValue?: number;
    PercentageOfGoodsTransportedThisYear?: boolean;
    PortionsOfInventoryTransportedThroughoutLastYearIfYes?: string;
    MarketValueOfInventory?: number;
    ValueOfExemptInventory?: number;
}

export type ITypesOfRecords = {
    AuditedFinancialStatement?: boolean,
    SalesRecords?: boolean
    InternalReports?: boolean,
    BillsOfLading?: boolean,
    TexasFranchiseTaxReports?: boolean,
    Other?: boolean
}

export const FreeportFormFourthSectionInterface = (): IFreeportFormFourthSectionInterface => ({
    InventoryWillBeTransportedOutOfState: undefined,
    HasAppliedForSept1InventoryAppraisal: undefined,
    PortionsOfInventoryTransportedThroughoutLastYear: undefined,
    PortionsOfInventoryTransportedMonths: undefined,
    TotalCostOfGoodsYear: undefined,
    TotalCostOfGoodsSoldForYear: undefined,
    TotalCostOfGoodsShippedOutOfTexas: undefined,
    TypesOfRecords: undefined,
    TypesOfRecordsOther: undefined,
    PercentageOfLastYearsValue: undefined,
    PercentageOfGoodsTransportedThisYear: undefined,
    PortionsOfInventoryTransportedThroughoutLastYearIfYes: undefined,
    MarketValueOfInventory: undefined,
    ValueOfExemptInventory: undefined
})