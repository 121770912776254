import { useEffect, useRef, useState } from "react";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import shared from "../PDF-Shared-style.module.scss";
import classes from "./TimberlandAppraisal-Form-PDF-style.module.scss";
import { settingsService } from "../../../services/settings.service";
import { ITimberlandAppraisalFormFifthSection } from "src/interfaces/forms/TimberlandAppraisalForm/timberland-appraisal-form-fifth-section.interface";
import { ITimberlandAppraisalFormFirstSection } from "src/interfaces/forms/TimberlandAppraisalForm/timberland-appraisal-form-first-section.interface";
import { ITimberlandAppraisalFormFourthSection } from "src/interfaces/forms/TimberlandAppraisalForm/timberland-appraisal-form-fourth-section.interface";
import { ITimberlandAppraisalFormThirdSection } from "src/interfaces/forms/TimberlandAppraisalForm/timberland-appraisal-form-third-section.interface";
import { ITimberlandAppraisalFormSecondSection } from "src/interfaces/forms/TimberlandAppraisalForm/timberland-appraisal-form-second-section.interface";
import { ITimberlandAppraisalFormSixthSection } from "src/interfaces/forms/TimberlandAppraisalForm/timberland-appraisal-form-sixth-section.interface";

interface IFormFields {
  formDataFieldsFirstSection?: ITimberlandAppraisalFormFirstSection;
  formDataFieldsSecondSection?: ITimberlandAppraisalFormSecondSection;
  formDataFieldsThirdSection?: ITimberlandAppraisalFormThirdSection;
  formDataFieldsFourthSection?: ITimberlandAppraisalFormFourthSection;
  formDataFieldsFifthSection?: ITimberlandAppraisalFormFifthSection;
  formDataFieldsSixthSection?: ITimberlandAppraisalFormSixthSection;
  getPdfBase64Url?: (url: string) => void;
}

export default function TimberlandAppraisalPDFView(props: IFormFields): JSX.Element {
  const {
    formDataFieldsFirstSection,
    formDataFieldsSecondSection,
    formDataFieldsThirdSection,
    formDataFieldsFourthSection,
    formDataFieldsFifthSection,
    formDataFieldsSixthSection,
    getPdfBase64Url,
  } = props;

  const templateRef = useRef(null);
  const [templateBase64, setTemplateBase64] = useState("");
  const settings = settingsService().settings;

  useEffect(() => {
    drawDOM(templateRef.current as any, { paperSize: "A4" })
      .then((group) => {
        if (templateRef.current) {
          const myReference = templateRef.current as any;
          myReference.style.display = "none";
        }
        return exportPDF(group);
      })
      .then((dataUri) => {
        setTemplateBase64(dataUri);
        if (getPdfBase64Url) {
          getPdfBase64Url(dataUri);
        }
      });
  }, []);

  return (
    <>
      {templateRef && templateBase64 && (
        <iframe
          style={{ width: "100%", height: "100%" }}
          src={templateBase64}
        />
      )}
      <div ref={templateRef} className={`${shared.FormContainer} ${shared.Form}`}>
        <div className={shared.PrintTogether}>
          <div className={`${shared.Row} ${shared.AlignCenter}`}>
            <div className={`${shared.Column9} ${shared.H3}`}>
              Application for 1-d-1 (Open-Space) Timberland Appraisal
            </div>
            <div className={`${shared.Column2} ${shared.TextCenter} ${shared.FormVersion}`}>Form 50-167</div>
          </div>

          <div className={`${shared.Row} ${shared.SpaceBetween}`}>
            <div className={`${shared.ColOffset9} ${shared.Column3}`}>
              <div className={shared.Value}>
                {formDataFieldsFirstSection?.TaxYear}
              </div>
              <div className={shared.ValueLabelBelow}>Tax Year</div>
            </div>

            <div className={shared.Column7}>
              <div className={shared.Value}>
                {settings?.clientDisplayName}
              </div>
              <div className={shared.ValueLabelBelow}>Appraisal District&apos;s Name</div>
            </div>

            <div className={shared.Column5}>
              <div className={shared.Value}>
                {formDataFieldsFourthSection?.AccountNumber}
              </div>
              <div className={shared.ValueLabelBelow}>
                Appraisal District Account Number (if known)
              </div>
            </div>
          </div>

          <p>
            <b>GENERAL INFORMATION:&nbsp;</b> : Texas Constitution, Article VIII, Section 1-d-1, 
            and Tax Code, Chapter 23, Subchapter E, provide for appraisal of open-space land 
            devoted principally to producing timber and forest products based on its capacity 
            to produce these products.
          </p>
          <p>
            <b>FILING INSTRUCTIONS:&nbsp;</b> This form must be filed with the appraisal district 
            office in each county in which the property is located.{" "}
            <b>
              {" "}
              Do not file this document with the Texas Comptroller of Public
              Accounts.
            </b>
          </p>

          <div className={shared.Step}>SECTION 1: Property Owner/Applicant</div>
          <div>
          <div className={shared.TextSmall}>
            The applicant is the following type of property owner:
            </div>
            <div>
            <span>
                <i
                className={
                    "fa " +
                    (formDataFieldsSecondSection?.PropertyOwnerType === "Individual"
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
                ></i>
                Individual
            </span>
            &nbsp;&nbsp;
            <span>
                <i
                className={
                    "fa " +
                    (formDataFieldsSecondSection?.PropertyOwnerType === "Partnership"
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
                ></i>
                Partnership
            </span>
            &nbsp;&nbsp;
            <span>
                <i
                className={
                    " fa " +
                    (formDataFieldsSecondSection?.PropertyOwnerType === "Corporation"
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
                ></i>
                Corporation
            </span>
            &nbsp;&nbsp;
            <span>
                <i
                className={
                    "fa " +
                    (formDataFieldsSecondSection?.PropertyOwnerType === "Other"
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
                ></i>
                Other <i>(specify):</i> &nbsp; &nbsp;
            </span>
            &nbsp;
            <span className={shared.Value}>
                {formDataFieldsSecondSection?.PropertyOwnerTypeOther}
            </span>
            </div>
            <br/>
            <div className={shared.Row}>
              <div className={shared.Column12}>
                <div className={shared.Value}>
                  {formDataFieldsSecondSection?.PropertyOwnerName}
                </div>
                <div className={shared.ValueLabelBelow}>Name of Property Owner</div>
              </div>
            </div>

            <div className={shared.Row}>
              <div className={shared.Column12}>
                <div className={shared.Value}>
                  {formDataFieldsSecondSection?.PropertyOwnerPhysicalAddressLine1}{" "}
                  &nbsp;
                  {formDataFieldsSecondSection?.PropertyOwnerPhysicalAddressLine2 && (
                    <span>
                      {formDataFieldsSecondSection?.PropertyOwnerPhysicalAddressLine2}{" "}
                      &nbsp;
                    </span>
                  )}
                  {formDataFieldsSecondSection?.PropertyOwnerPhysicalAddressCity}{" "}
                  &nbsp;
                  {formDataFieldsSecondSection?.PropertyOwnerPhysicalAddressState},
                  &nbsp;
                  {formDataFieldsSecondSection?.PropertyOwnerPhysicalAddressZip} &nbsp;
                </div>

                <div className={shared.ValueLabelBelow}>
                  Physical Address, City, State, ZIP Code
                </div>
              </div>
            </div>

            <div className={shared.Row}>
              <div className={shared.Column5}>
                <div className={shared.Value}>
                  {formDataFieldsSecondSection?.PropertyOwnerPhoneNumber}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Primary Phone Number<i>(area code and number)</i>
                </div>
              </div>
              <div className={shared.Column7}>
                <div className={shared.Value}>
                  {formDataFieldsSecondSection?.PropertyOwnerEmailAddress}
                </div>
                <div className={shared.ValueLabelBelow}>Email Address*</div>
              </div>
            </div>

            <div className={shared.Row}>
              <div className={shared.Column12}>
                <div className={shared.Value}>
                  {formDataFieldsSecondSection?.PropertyOwnerMailingAddressLine1}{" "}
                  &nbsp;
                  {formDataFieldsSecondSection?.PropertyOwnerMailingAddressLine2 && (
                    <span>
                      {formDataFieldsSecondSection?.PropertyOwnerMailingAddressLine2}{" "}
                      &nbsp;
                    </span>
                  )}
                  {formDataFieldsSecondSection?.PropertyOwnerMailingAddressCity}{" "}
                  &nbsp;
                  {formDataFieldsSecondSection?.PropertyOwnerMailingAddressState},
                  &nbsp;
                  {formDataFieldsSecondSection?.PropertyOwnerMailingAddressZip} &nbsp;
                </div>

                <div className={shared.ValueLabelBelow}>
                  Mailing Address, City, State, ZIP Code 
                  <i>(if different from the physical address provided above)</i>
                </div>
              </div>
            </div>
          </div>

          <div className={shared.Step}>SECTION 2: Authorized Representative</div>
          <div>
            <p>
              <b>
                If you are an individual property owner filing this application
                on your own behalf, skip to Section 3; all other applicants are
                required to complete Section 2. &nbsp;
              </b>
              Please indicate the basis for your authority to represent the property 
              owner in filing this application:
            </p>
            <div className={shared.Row}>
              <span className={shared.Column4}>
                <i
                  className={
                    "fa " +
                    (formDataFieldsThirdSection?.AuthorizedRepresentativeType === "OfficerOfTheCompany"
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>
                Officer of the company
              </span>
              &nbsp;&nbsp;
              <span className={shared.Column4}>
                <i
                  className={
                    " fa " +
                    (formDataFieldsThirdSection?.AuthorizedRepresentativeType === "GeneralPartnerOfTheCompany"
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>
                General Partner of the company
              </span>
              &nbsp;&nbsp;
              <span className={shared.Column4}>
                <i
                  className={
                    " fa " +
                    (formDataFieldsThirdSection?.AuthorizedRepresentativeType === "AttorneyForTheCompany"
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>
                Attorney for the company
              </span>
            </div>

            <div className={shared.Row}>
              <span className={shared.Column12}>
                <i
                  className={
                    " fa " +
                    (formDataFieldsThirdSection?.AuthorizedRepresentativeType === "Agent"
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>
                Agent for tax matters appointed under Tax Code Section 1.111
                with completed and signed Form 50-162
              </span>
            </div>

            <div className={shared.Row}>
              <div className={shared.Column12}>
                {" "}
                <span>
                  <i
                    className={
                      " fa " +
                      (formDataFieldsThirdSection?.AuthorizedRepresentativeType === "Other"
                        ? "fa-check-square-o"
                        : "fa-square-o")
                    }
                  ></i>
                  Other and explain basis: &nbsp; &nbsp;
                </span>
                <span className={shared.Value}>
                  {formDataFieldsThirdSection?.AuthorizedRepresentativeTypeOther}
                </span>
              </div>
            </div>

            <div className={shared.Row}>
              <div className={shared.Column12}>
                <div className={shared.Value}>
                  {formDataFieldsThirdSection?.AuthorizedRepresentativeName}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Name of Authorized Representative
                </div>
              </div>
            </div>
            <div className={`${shared.Row} ${shared.SpaceBetween}`}>
              <div className={shared.Column4}>
                <div className={shared.Value}>
                  {formDataFieldsThirdSection?.AuthorizedRepresentativeTitle}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Title of Authorized Representative
                </div>
              </div>
              <div className={shared.Column4}>
                <div className={shared.Value}>
                  {formDataFieldsThirdSection?.AuthorizedRepresentativePhoneNumber}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Primary Phone Number<i>(area code and number)</i>
                </div>
              </div>
              <div className={shared.Column4}>
                <div className={shared.Value}>
                  {formDataFieldsThirdSection?.AuthorizedRepresentativeEmailAddress}
                </div>
                <div className={shared.ValueLabelBelow}>Email Address**</div>
              </div>

              <div className={shared.Column12}>
                <div className={shared.Value}>
                  {formDataFieldsThirdSection?.AuthorizedRepresentativeMailingAddressLine1}{" "}
                  &nbsp;
                  {formDataFieldsThirdSection?.AuthorizedRepresentativeMailingAddressLine2 && (
                    <span>
                      {
                        formDataFieldsThirdSection?.AuthorizedRepresentativeMailingAddressLine2
                      }{" "}
                      &nbsp;
                    </span>
                  )}
                  {formDataFieldsThirdSection?.AuthorizedRepresentativeMailingAddressCity}{" "}
                  &nbsp;
                  {formDataFieldsThirdSection?.AuthorizedRepresentativeMailingAddressState}
                  , &nbsp;
                  {
                    formDataFieldsThirdSection?.AuthorizedRepresentativeMailingAddressZip
                  }{" "}
                  &nbsp;
                </div>
                <div className={shared.ValueLabelBelow}>
                  Mailing Address, City, State, ZIP Code
                </div>
              </div>
            </div>
          </div>

          <div className={shared.Step}>
            SECTION 3: Property Description and Information
          </div>
          <div>
            <div className={shared.Row}>              
              <div className={`${shared.Column12} ${shared.TextSmall}`}>
                Provide the descriptive information requested below for the property that is the 
                subject of this application or attach last year&apos;s tax statement, notice of 
                appraised value or other correspondence identifying the property.
              </div>
            </div>
            <div className={shared.Row}>
              <div className={shared.Column6}>
                <div className={shared.Value}>
                  {formDataFieldsFourthSection?.AccountNumber}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Account Number <i>(if known)</i>
                </div>
              </div>
              <div className={shared.Column6}>
                <div className={shared.Value}>
                  {formDataFieldsFourthSection?.NumberOfAcres}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Number of Acres <i>(subject to this application)</i>
                </div>
              </div>
            </div>
            <div className={shared.Row}>
              <div className={`${shared.Column11} ${shared.TextSmall}`}>
                Legal Description, abstract numbers, field numbers and/or plat
                numbers:
              </div>
              <div className={shared.Column11}>
                <div className={shared.TextArea}>
                  {formDataFieldsFourthSection?.LegalDescription}
                </div>
              </div>
            </div>
          </div>

          <div className={shared.PageBreak}></div>

          <div className={shared.Step}>SECTION 3: Property Description and Information (concluded)</div>
          <div>
            <div className={shared.Row}>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                Select the appropriate box in response to each question below.
              </div>
            </div>
            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>1.</div>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                Has the ownership of the property changed since Jan. 1 of last year or since 
                the last application was submitted?
              </div>
              <div className={`${shared.Column2} ${shared.TextRight}`}>
                <i
                className={
                    "fa " +
                    (formDataFieldsFourthSection?.HasPropertyOwnershipChanged == true
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                className={
                    " fa " +
                    (!formDataFieldsFourthSection?.HasPropertyOwnershipChanged == false
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
                ></i>{" "}
                &nbsp; No
              </div>
            </div>
            <div className={shared.Row}>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                If yes, the new owner must complete all applicable questions in Section 4.
              </div>
            </div>
            
            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>2.</div>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                Last year, was timberland appraisal allowed on this property by the chief 
                appraiser of this appraisal district?
              </div>
              <div className={`${shared.Column2} ${shared.TextRight}`}>
                <i
                className={
                    "fa " +
                    (formDataFieldsFourthSection?.WasAppraisalAllowed == true
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                className={
                    " fa " +
                    (!formDataFieldsFourthSection?.WasAppraisalAllowed == false
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
                ></i>{" "}
                &nbsp; No
              </div>
            </div>
            <div className={shared.Row}>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                If no, you must complete all applicable questions in Section 4.
              </div>
            </div>
            <div className={shared.Row}>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                If yes, complete only those parts of Section 4 that have changed since your 
                earlier application or any information in Section 4 requested by the chief appraiser.
              </div>
            </div>
            
            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>3.</div>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                Is this property located within the corporate limits of a city or town?
              </div>
              <div className={`${shared.Column2} ${shared.TextRight}`}>
                <i
                className={
                    "fa " +
                    (formDataFieldsFourthSection?.IsPropertyLocatedWithinCorporateLimits == true
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                className={
                    " fa " +
                    (!formDataFieldsFourthSection?.IsPropertyLocatedWithinCorporateLimits == false
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
                ></i>{" "}
                &nbsp; No
              </div>
            </div>              
          </div>
          
          <div className={shared.Step}>SECTION 4: Property Use</div>
          <div className={shared.Row}>
            <ol className={shared.OrderedList}>
              <li>
                <div className={shared.TextSmall}>
                  Describe the current and past timber production or agricultural uses of this property 
                  as described in Section 3, starting with the current year and working back 5 years or until
                  5 out of 7 years of agricultural use is shown. Use the agricultural land categories listed 
                  in the important information section of this form. Divide the total acreage according to
                  individual uses to which the land is principally devoted. Five years of continuous 
                  agricultural use may be required if the land is located within the corporate limits of a 
                  city or town.
                </div>
                <table className={shared.Blue}>
                  <tr>
                    <th className={`${shared.Width10} ${shared.TextCenter}`}>Year</th>
                    <th className={shared.TextCenter}>
                      Agricultural Use Category of Land (Lists all that apply)
                    </th>
                    <th className={`${shared.Width20} ${shared.TextCenter}`}>
                      Acres Principally Devoted to Agricultural Use
                    </th>
                  </tr>
                  {formDataFieldsFifthSection?.AgriculturalUseCategoriesOfLand?.map((item) => {
                    return (
                      <tr key={item.Id}>
                        <td className={`${shared.Width10} ${shared.TextCenter}`}>
                          {item?.Year}
                        </td>
                        <td className={shared.TextCenter}>
                          {item?.Category}
                        </td>
                        <td className={`${shared.Width20} ${shared.TextCenter}`}>
                          {item?.Acres}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </li>

              <li>
                <div className={shared.TextSmall}>
                  List the total number of acres of the property described in Section 3 in each of 
                  the following forest types:
                </div>

                <table className={shared.Blue}>
                  <tr>
                    <th className={shared.TextCenter}>
                      Forest Type
                    </th>
                    <th className={`${shared.Width20} ${shared.TextCenter}`}>Number of Acres</th>
                  </tr>
                  <tr>
                    <td className={shared.TextLeft}>
                      Pine forest <i>(Pine and other softwood trees make up at least 
                      two-thirds of the free-to-grow trees.)</i>
                    </td>
                    <td className={`${shared.Width20} ${shared.TextCenter}`}>
                      {formDataFieldsFifthSection?.AgriculturalUsePineForestAcres}
                    </td>
                  </tr>
                  <tr>
                    <td className={shared.TextLeft}>
                      Hardwood forest <i>(Hardwood trees are at least two-thirds of 
                      the free-to-grow trees.)</i>
                    </td>
                    <td className={`${shared.Width20} ${shared.TextCenter}`}>
                      {formDataFieldsFifthSection?.AgriculturalUseHardwoodForestAcres}
                    </td>
                  </tr>
                  <tr>
                    <td className={shared.TextLeft}>
                      Mixed Hardwood forest <i>(Neither soft nor hardwood trees make up 
                      more than two-thirds of the free-to-grow trees.)</i>
                    </td>
                    <td className={`${shared.Width20} ${shared.TextCenter}`}>
                      {formDataFieldsFifthSection?.AgriculturalUseMixedHardwoodForestAcres}
                    </td>
                  </tr>
                </table>
              </li>

              <li>
                <div className={shared.TextSmall}>
                  If the property is now used for any nonagricultural activities, list all 
                  nonagricultural uses and the number of acres devoted to each use. Attach 
                  a list if the space is not sufficient.
                </div>
                <table className={shared.Blue}>
                  <tr>
                    <th className={shared.TextCenter}>Nonagricultural Use</th>
                    <th className={`${shared.Width20} ${shared.TextCenter}`}>Number of Acres</th>
                  </tr>
                  {formDataFieldsFifthSection?.NonAgriculturalUse?.map((item) => {
                    return (
                      <tr key={item.Id}>
                        <td className={shared.TextCenter}>
                          {item?.Type}
                        </td>
                        <td className={`${shared.Width20} ${shared.TextCenter}`}>
                          {item?.Acres}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </li>
            </ol>
          </div>

          <div className={shared.PageBreak}></div>
          <div className={shared.Step}>SECTION 5: Certification and Signature</div>
          <div>
            <b>
              NOTICE REGARDING PENALTIES FOR MAKING OR FILING AN APPLICATION CONTAINING A 
              FALSE STATEMENT: If you make a false statement on this form, you could be found 
              guilty of a Class A misdemeanor or a state jail felony under Penal Code Section 37.10.
            </b>
          </div>

          <br />
          <div className={shared.PrintedName}>
            I, {" "}
            <div className={shared.Width60}>
              {" "}
              <div className={shared.Value}>
                {formDataFieldsSixthSection?.Signature}
              </div>
              <div className={`${shared.ValueLabelBelow} ${shared.TextCenter}`}>
                Printed Name of Property Owner or Authorized Representative
              </div>
            </div>
            , swear or affirm the following:
          </div>
          <br />
          <div className={shared.Row}>
            <ol>
              <li>
                <div className={`${shared.Column9} ${shared.TextSmall}`}>
                  that each fact contained in this application is true and correct;
                </div>
              </li>
              <li>
                <div className={`${shared.Column9} ${shared.TextSmall}`}>
                  that the property described in this application meets the qualifications 
                  under Texas law for the special appraisal claimed;
                </div>
              </li>
              <li>
                <div className={`${shared.Column9} ${shared.TextSmall}`}>
                  that I have read and understand the 
                  <i>Notice Regarding Penalties for Making or Filing an Application Containing a False Statement.”</i>
                </div>
              </li>
            </ol>              
          </div>

          <div className={`${shared.Row} ${shared.SpaceBetween}`}>
            <div className={shared.Column8}>
              <div className={shared.Value}>
                {formDataFieldsSixthSection?.Signature}
              </div>
              <div className={`${shared.ValueLabelBelow} ${shared.TextCenter}`}>
                Signature of Property Owner or Authorized Representative
              </div>
            </div>
            <div className={shared.Column3}>
              <div className={shared.Value}>
                {formDataFieldsSixthSection?.SignatureDate ? new Date(formDataFieldsSixthSection?.SignatureDate).toDateString() : ""}
              </div>
              <div className={`${shared.ValueLabelBelow} ${shared.TextCenter}`}>Date</div>
            </div>
          </div>

          <p className={shared.TextSmall}>
            ** May be confidential under Government Code §552.137; however, by including the email address on this form, 
            you are affirmatively consenting to its release under the Public Information Act.
          </p>
          
          <div className={shared.PageBreak}></div>

          <div className={shared.PrintTogether}>
            <div className={`${shared.H3} ${shared.TextCenter}`}>Important Information</div>
            <div className={shared.Row}>
              <div className={shared.Column6}>
                <p><b>GENERAL INFORMATION</b>&nbsp; 
                  While land must currently be devoted principally to the production of timber or 
                  forest products to qualify for timberland appraisal, the requirement to show a 
                  history of use for five of the previous seven years can be satisfied by timber 
                  production or by agricultural use.
                </p>
                <p>
                  Agricultural use includes, but is not limited to, the following activities: 
                  (1) cultivating the soil; (2) producing crops for human food, animal feed, or 
                  planting seed or for the production of fibers; (3) floriculture, viticulture and 
                  horticulture; (4) raising or keeping livestock; (5) raising or keeping exotic 
                  animals or fowl for the production of human food or fiber, leather, pelts or 
                  other tangible products having a commercial value; (6) planting cover crops or 
                  leaving land idle for the purpose of participating in a governmental program 
                  provided the land is not used for residential purposes or a purpose inconsistent 
                  with agricultural use or leaving the land idle in conjunction with normal crop or 
                  livestock rotation procedures; (7) producing or harvesting logs and posts used for 
                  construction or repair of fences, pens, barns or other agricultural improvements on
                  adjacent open-space land having the same owner and devoted to a different 
                  agricultural use; (8) wildlife management; and (9) beekeeping.
                </p>
                <p>
                  Agricultural land use categories include: (1) irrigated cropland; (2) dry cropland; (3)
                  improved pastureland; (4) native pastureland; (5) orchard; (6) wasteland; (7) timber
                  production; (8) wildlife management; and (9) other categories of land that are typical
                  in the area.
                </p>
                
                <p><b>APPLICATION DEADLINES</b>&nbsp;
                  The completed application must be filed with the chief appraiser before May 1 of the
                  year for which timberland appraisal is requested. If the application is approved, a new
                  application is not required in later years unless the land ownership changes, eligibility
                  ends or the chief appraiser requests a new application.
                </p>
                <p>
                  A late application may be filed up to midnight the day before the appraisal review
                  board approves appraisal records for the year, which usually occurs in July. If a late
                  application is approved, a penalty will be applied in an amount equal to 10 percent of
                  the difference between the amount of tax imposed on the property and the amount
                  that would be imposed if the property were taxed at market value
                </p>
              </div>

              <div className={shared.Column6}>
                <p><b>CHIEF APPRAISER ACTIONS</b>&nbsp;
                  The chief appraiser shall, as soon as practicable but not later than 90 days after the
                  later of the following two dates: date the applicant&apos;s land is first eligible for appraisal
                  for 1-d-1 appraisal; or the date the applicant provides the information necessary, make
                  one of the following decisions:
                </p>
                <ul>
                  <li>approve the application and grant agricultural appraisal;</li>
                  <li>disapprove it and ask for more information; or</li>
                  <li>deny the application.</li>
                </ul>

                <p><b>ADDITIONAL INFORMATION REQUEST</b>&nbsp;
                  The chief appraiser may disapprove the application and request additional information to evaluate 
                  this application. This request must be delivered via a written notice to the applicant as soon as 
                  practicable but not later than the 30th day after the application was filed with the appraisal 
                  district. The notice must specify the additional information the applicant must provide so the 
                  chief appraiser can make a determination. The applicant must provide the additional information 
                  not later than the 30th day after the date of the request or the application will be denied. 
                  The chief appraiser may extend this deadline for a single period not to exceed 15 days for good 
                  cause shown.
                </p>

                
                <p><b>DENIED APPLICATIONS</b>&nbsp;
                  The chief appraiser may deny an application. He or she must notify the applicant in
                  writing not later than the fifth day after the determination by certified mail. It must
                  state and fully explain each reason for the denial. The landowner can file a protest of
                  the denial with the appraisal review board.
                </p>

                
                <p><b>DUTY TO NOTIFY AND PENALTIES</b>&nbsp;
                  The property owner must notify the chief appraiser no later than the April 30 following 
                  the change in use or eligibility. A change of land use for all or part of the property
                  will trigger substantial additional tax, commonly called a rollback tax. Payment of a
                  penalty may also be required for failure to notify the chief appraiser of a change in
                  timber production or agricultural use or qualification. Notice must be delivered to the
                  chief appraiser if:
                </p>
                <ul>
                  <li>
                    the property stops being used for timber production (e.g., voluntarily decide to
                    stop actively managing the land to produce income);
                  </li>
                  <li>category of land use changes (e.g., from growing timber to grazing cattle); or</li>
                  <li>
                    the land is used for something other than agriculture (e.g., to build a shopping
                    center on most of the land).
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
