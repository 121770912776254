import classes from "./custom-field.module.scss";
import React, {useEffect, useState} from "react";
import {Error} from "@progress/kendo-react-labels";
import {ProvincesList} from "../../../../../constants/provinces-list-constants";
import {DropDownList, DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";

interface StatesInput {
    isReadOnly?: boolean,
    setFormDataFields: (form: any) => void;
    formDataFields?:  any,
    fieldName: string,
    required: boolean,
    setIsValidField?: (item: boolean) => void;
    validField?: boolean;
}


export function StatesInput(props: StatesInput) :JSX.Element {
    const {isReadOnly, formDataFields, setFormDataFields, fieldName, required, setIsValidField, validField} = props;
    const [errors, setErrors] = useState<{ required: boolean, pattern: boolean }>({
        pattern: required,
        required: required,
    });
    const changeValueHandler = (
        event: DropDownListChangeEvent,
        field: string) => {

        setFormDataFields({
            ...formDataFields,
            [field]: event.value
        });
    }

    useEffect(() => {
        return () => {
            if (setIsValidField){
                setIsValidField(true);
            }
        }
    },[]);

    useEffect(() => {
        if (setIsValidField && (!formDataFields?.[fieldName] || formDataFields?.[fieldName] === " ") && required) {
            setIsValidField(false);
        } else if (!required && setIsValidField) {
            setIsValidField(true);
        } else if (setIsValidField && formDataFields?.[fieldName]) {
            setIsValidField(true);
        }
    }, [errors, formDataFields?.[fieldName]]);

    return <>
        <DropDownList
            defaultItem={" "}
            disabled={isReadOnly}
            name={fieldName}
            className={!validField ? `${classes.input} ${classes.invalidInput}` : classes.input}
            data={ProvincesList.map(x => x.Text)}
            value={formDataFields?.[fieldName]}
            onChange={(e) => changeValueHandler(e, fieldName)}
        />
        {!validField && <Error>This field is required!</Error>}
    </>
}
