export type IAgFormSecondSectionInterface = {
    individual?: boolean;
    corporation?: boolean;
    partnership?: boolean;
    other?: boolean;
    OwnershipTypeOther?: string;
    PropertyOwnerName?: string;
    PropertyOwnerEmailAddress?: string;
    PropertyOwnerPhoneNumber?: string;
    PropertyOwnerBirthDate?: string;
    PropertyOwnerPhysicalAddressLine1?: string;
    PropertyOwnerPhysicalAddressLine2?: string;
    PropertyOwnerPhysicalAddressCity?: string;
    PropertyOwnerPhysicalAddressState?: string;
    PropertyOwnerPhysicalAddressZip?: string;
    PropertyOwnerMailingAddressLine1?: string;
    PropertyOwnerMailingAddressLine2?: string;
    PropertyOwnerMailingAddressCity?: string;
    PropertyOwnerMailingAddressState?: string;
    PropertyOwnerMailingAddressZip?: string;
}

export const AgFormSecondSectionInitialState = (): IAgFormSecondSectionInterface => ({
    individual: undefined,
    corporation: undefined,
    partnership: undefined,
    other: undefined,
    OwnershipTypeOther: undefined,
    PropertyOwnerName: undefined,
    PropertyOwnerEmailAddress: undefined,
    PropertyOwnerPhoneNumber: undefined,
    PropertyOwnerBirthDate: undefined,
    PropertyOwnerPhysicalAddressLine1: undefined,
    PropertyOwnerPhysicalAddressLine2: undefined,
    PropertyOwnerPhysicalAddressCity: undefined,
    PropertyOwnerPhysicalAddressState: undefined,
    PropertyOwnerPhysicalAddressZip: undefined,
    PropertyOwnerMailingAddressLine1: undefined,
    PropertyOwnerMailingAddressLine2: undefined,
    PropertyOwnerMailingAddressCity: undefined,
    PropertyOwnerMailingAddressState: undefined,
    PropertyOwnerMailingAddressZip: undefined,
})