import { useEffect, useRef, useState } from "react";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import shared from "../PDF-Shared-style.module.scss";
import classes from "./ExemptionPropertyDamaged-Form-PDF-style.module.scss";
import { settingsService } from "../../../services/settings.service";
import { IExemptionPropertyDamagedFormFifthSection } from "src/interfaces/forms/ExemptionPropertyDamagedByDisasterForm/exemption-property-damaged-fifth-section.interface";
import { IExemptionPropertyDamagedFormFirstSection } from "src/interfaces/forms/ExemptionPropertyDamagedByDisasterForm/exemption-property-damaged-first-section.interface";
import { IExemptionPropertyDamagedFormFourthSection } from "src/interfaces/forms/ExemptionPropertyDamagedByDisasterForm/exemption-property-damaged-fourth-section.interface";
import { IExemptionPropertyDamagedFormSecondSection } from "src/interfaces/forms/ExemptionPropertyDamagedByDisasterForm/exemption-property-damaged-second-section.interface";
import { IExemptionPropertyDamagedFormSeventhSection } from "src/interfaces/forms/ExemptionPropertyDamagedByDisasterForm/exemption-property-damaged-seventh-section.interface";
import { IExemptionPropertyDamagedFormSixthSection } from "src/interfaces/forms/ExemptionPropertyDamagedByDisasterForm/exemption-property-damaged-sixth-section.interface";
import { IExemptionPropertyDamagedFormThirdSection } from "src/interfaces/forms/ExemptionPropertyDamagedByDisasterForm/exemption-property-damaged-third-section.interface";

interface IFormFields {
  formDataFieldsFirstSection?: IExemptionPropertyDamagedFormFirstSection;
  formDataFieldsSecondSection?: IExemptionPropertyDamagedFormSecondSection;
  formDataFieldsThirdSection?: IExemptionPropertyDamagedFormThirdSection;
  formDataFieldsFourthSection?: IExemptionPropertyDamagedFormFourthSection;
  formDataFieldsFifthSection?: IExemptionPropertyDamagedFormFifthSection;
  formDataFieldsSixthSection?: IExemptionPropertyDamagedFormSixthSection;
  formDataFieldsSeventhSection?: IExemptionPropertyDamagedFormSeventhSection;
  getPdfBase64Url?: (url: string) => void;
}

export default function ExemptionPropertyDamagedPDFView(props: IFormFields): JSX.Element {
  const {
    formDataFieldsFirstSection,
    formDataFieldsSecondSection,
    formDataFieldsThirdSection,
    formDataFieldsFourthSection,
    formDataFieldsFifthSection,
    formDataFieldsSixthSection,
    formDataFieldsSeventhSection,
    getPdfBase64Url,
  } = props;

  const templateRef = useRef(null);
  const [templateBase64, setTemplateBase64] = useState("");
  const settings = settingsService().settings;

  useEffect(() => {
    drawDOM(templateRef.current as any, { paperSize: "A4" })
      .then((group) => {
        if (templateRef.current) {
          const myReference = templateRef.current as any;
          myReference.style.display = "none";
        }
        return exportPDF(group);
      })
      .then((dataUri) => {
        setTemplateBase64(dataUri);
        if (getPdfBase64Url) {
          getPdfBase64Url(dataUri);
        }
      });
  }, []);

  return (
    <>
      {templateRef && templateBase64 && (
        <iframe
          style={{ width: "100%", height: "100%" }}
          src={templateBase64}
        />
      )}
      <div ref={templateRef} className={`${shared.FormContainer} ${shared.Form}`}>
        <div className={shared.PrintTogether}>
          <div className={`${shared.Row} ${shared.AlignCenter}`}>
            <div className={`${shared.Column9} ${shared.H3}`}>
              Temporary Exemption Property Damaged by Disaster 
            </div>
            <div className={`${shared.Column2} ${shared.TextCenter} ${shared.FormVersion}`}>Form 50-312</div>
          </div>

          <div className={`${shared.Row} ${shared.SpaceBetween}`}>
            <div className={`${shared.ColOffset9} ${shared.Column3}`}>
              <div className={shared.Value}>
                {formDataFieldsFirstSection?.TaxYear}
              </div>
              <div className={shared.ValueLabelBelow}>Tax Year</div>
            </div>

            <div className={shared.Column6}>
              <div className={shared.Value}>
                {settings?.clientDisplayName}
              </div>
              <div className={shared.ValueLabelBelow}>Appraisal District&apos;s Name</div>
            </div>

            <div className={shared.Column5}>
              <div className={shared.Value}>
                {formDataFieldsFourthSection?.AppraisalDistrictAccountNumber}
              </div>
              <div className={shared.ValueLabelBelow}>
                Appraisal District Account Number (if known)
              </div>
            </div>
          </div>

          <p>
            <b>GENERAL INFORMATION:&nbsp;</b> : Property owners use this form to claim a 
            temporary property tax exemption for property in a governor-declared disaster area 
            with at least 15 percent damage. (Tax Code Section 11.35)
          </p>
          <p>
            <b>FILING INSTRUCTIONS:&nbsp;</b> File this form and all supporting documentation 
            with the appraisal district office in each county where property is located 
            no later than the 105th day after the date the governor declares the area to be 
            a disaster area.{" "}
            <b>
              {" "}
              Do not file this document with the Texas Comptroller of Public
              Accounts.
            </b>
          </p>

          <div className={shared.Step}>SECTION 1: Property Owner Information</div>
          <div>
            <div className={shared.Row}>
              <div className={shared.Column6}>
                <div className={shared.Value}>
                  {formDataFieldsSecondSection?.Name}
                </div>
                <div className={shared.ValueLabelBelow}>Name of Property Owner</div>
              </div>
              <div className={shared.Column6}>
                <div className={shared.Value}>
                  {formDataFieldsSecondSection?.PersonalId}
                </div>
                <div className={shared.ValueLabelBelow}>Driver&apos;s License, Personal I.D. Certificate, or Social Security Number*</div>
              </div>
            </div>

            <div className={shared.Row}>
              <div className={shared.Column4}>
                <div className={shared.Value}>
                  {formDataFieldsSecondSection?.PrimaryPhoneNumber}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Primary Phone Number<i>(area code and number)</i>
                </div>
              </div>
              <div className={shared.Column4}>
                <div className={shared.Value}>
                  {formDataFieldsSecondSection?.SecondaryContactNumber}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Secondary Contact Number<i>(area code and number)</i>
                </div>
              </div>
              <div className={shared.Column4}>
                <div className={shared.Value}>
                  {formDataFieldsSecondSection?.EmailAddress}
                </div>
                <div className={shared.ValueLabelBelow}>Email Address</div>
              </div>
            </div>

            <div className={shared.Row}>
              <div className={shared.Column12}>
                <div className={shared.Value}>
                  {formDataFieldsSecondSection?.MailingAddressLine1}{" "}
                  &nbsp;
                  {formDataFieldsSecondSection?.MailingAddressLine2 && (
                    <span>
                      {formDataFieldsSecondSection?.MailingAddressLine2}{" "}
                      &nbsp;
                    </span>
                  )}
                  {formDataFieldsSecondSection?.MailingAddressCity}{" "}
                  &nbsp;
                  {formDataFieldsSecondSection?.MailingAddressState},
                  &nbsp;
                  {formDataFieldsSecondSection?.MailingAddressZip} &nbsp;
                </div>

                <div className={shared.ValueLabelBelow}>
                  Mailing Address, City, State, ZIP Code
                </div>
              </div>
            </div>

            <div className={shared.Row}>
              <div className={shared.Column12}>
                <div className={shared.Value}>
                  {formDataFieldsSecondSection?.AlternateMailingAddressLine1}{" "}
                  &nbsp;
                  {formDataFieldsSecondSection?.AlternateMailingAddressLine2 && (
                    <span>
                      {formDataFieldsSecondSection?.AlternateMailingAddressLine2}{" "}
                      &nbsp;
                    </span>
                  )}
                  {formDataFieldsSecondSection?.AlternateMailingAddressCity}{" "}
                  &nbsp;
                  {formDataFieldsSecondSection?.AlternateMailingAddressState},
                  &nbsp;
                  {formDataFieldsSecondSection?.AlternateMailingAddressZip} &nbsp;
                </div>

                <div className={shared.ValueLabelBelow}>
                  Alternate Mailing Address, City, State, ZIP Code
                </div>
              </div>
            </div>
          </div>

          <div className={shared.Step}>SECTION 2: Authorized Representative Information</div>
          <div>
            <p>
              <b>
                If you are an individual property owner filing this application
                on your own behalf, skip to Section 3; all other applicants are
                required to complete Section 2.
              </b>
            </p>
            <div className={shared.Row}>
              <span className={shared.Column4}>
                <i
                  className={
                    "fa " +
                    (formDataFieldsThirdSection?.AuthorizedRepresentativeType === "OfficerOfTheCompany"
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>
                Officer of the company
              </span>
              &nbsp;&nbsp;
              <span className={shared.Column4}>
                <i
                  className={
                    " fa " +
                    (formDataFieldsThirdSection?.AuthorizedRepresentativeType === "GeneralPartnerOfTheCompany"
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>
                General Partner of the company
              </span>
              &nbsp;&nbsp;
              <span className={shared.Column4}>
                <i
                  className={
                    " fa " +
                    (formDataFieldsThirdSection?.AuthorizedRepresentativeType === "AttorneyForTheCompany"
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>
                Attorney for the company
              </span>
            </div>

            <div className={shared.Row}>
              <span className={shared.Column12}>
                <i
                  className={
                    " fa " +
                    (formDataFieldsThirdSection?.AuthorizedRepresentativeType === "Agent"
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>
                Agent for tax matters appointed under Tax Code Section 1.111
                with completed and signed Form 50-162
              </span>
            </div>

            <div className={shared.Row}>
              <div className={shared.Column12}>
                {" "}
                <span>
                  <i
                    className={
                      " fa " +
                      (formDataFieldsThirdSection?.AuthorizedRepresentativeType === "Other"
                        ? "fa-check-square-o"
                        : "fa-square-o")
                    }
                  ></i>
                  Other and explain basis: &nbsp; &nbsp;
                </span>
                <span className={shared.Value}>
                  {formDataFieldsThirdSection?.AuthorizedRepresentativeTypeOther}
                </span>
              </div>
            </div>

            <div className={shared.Row}>
              <div className={shared.Column6}>
                <div className={shared.Value}>
                  {formDataFieldsThirdSection?.AuthorizedRepresentativeName}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Name of Authorized Representative
                </div>
              </div>
              <div className={shared.Column6}>
                <div className={shared.Value}>
                  {formDataFieldsThirdSection?.AuthorizedRepresentativePersonalId}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Driver&apos;s License, Personal I.D. Certificate, or Social Security Number*
                </div>
              </div>
            </div>
            <div className={`${shared.Row} ${shared.SpaceBetween}`}>
              <div className={shared.Column4}>
                <div className={shared.Value}>
                  {formDataFieldsThirdSection?.AuthorizedRepresentativeTitle}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Title of Authorized Representative
                </div>
              </div>
              <div className={shared.Column4}>
                <div className={shared.Value}>
                  {formDataFieldsThirdSection?.AuthorizedRepresentativePhoneNumber}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Primary Phone Number<i>(area code and number)</i>
                </div>
              </div>
              <div className={shared.Column4}>
                <div className={shared.Value}>
                  {formDataFieldsThirdSection?.AuthorizedRepresentativeEmailAddress}
                </div>
                <div className={shared.ValueLabelBelow}>Email Address**</div>
              </div>

              <div className={shared.Column12}>
                <div className={shared.Value}>
                  {formDataFieldsThirdSection?.AuthorizedRepresentativeAddressLine1}{" "}
                  &nbsp;
                  {formDataFieldsThirdSection?.AuthorizedRepresentativeAddressLine2 && (
                    <span>
                      {
                        formDataFieldsThirdSection?.AuthorizedRepresentativeAddressLine2
                      }{" "}
                      &nbsp;
                    </span>
                  )}
                  {formDataFieldsThirdSection?.AuthorizedRepresentativeAddressCity}{" "}
                  &nbsp;
                  {formDataFieldsThirdSection?.AuthorizedRepresentativeAddressState}
                  , &nbsp;
                  {
                    formDataFieldsThirdSection?.AuthorizedRepresentativeAddressZip
                  }{" "}
                  &nbsp;
                </div>
                <div className={shared.ValueLabelBelow}>
                  Mailing Address, City, State, ZIP Code
                </div>
              </div>
            </div>
          </div>

          <div className={shared.Step}>
            SECTION 3: Property Information
          </div>
          <div>
            <div className={shared.Row}>              
              <div className={`${shared.Column3} ${shared.TextSmall}`}>
                Indicate type of property:
              </div>
            </div>
            <div className={shared.Row}>
              <span className={shared.Column4}>
                <i className={"fa " +
                  (formDataFieldsFourthSection?.TypeOfProperty === "Homestead"
                    ? "fa-check-square-o" : "fa-square-o")}></i>
                Homestead
              </span>
              <span className={shared.Column4}>
                <i className={"fa " +
                  (formDataFieldsFourthSection?.TypeOfProperty === "Residential"
                    ? "fa-check-square-o" : "fa-square-o")}></i>
                Residential
              </span>
              <span className={shared.Column4}>
                <i className={"fa " +
                  (formDataFieldsFourthSection?.TypeOfProperty === "Land"
                    ? "fa-check-square-o" : "fa-square-o")}></i>
                Land
              </span>
            </div>
            <div className={shared.Row}>
              <span className={shared.Column4}>
                <i className={"fa " +
                  (formDataFieldsFourthSection?.TypeOfProperty === "Commercial"
                    ? "fa-check-square-o" : "fa-square-o")}></i>
                Commercial
              </span>
              <span className={shared.Column4}>
                <i className={"fa " +
                  (formDataFieldsFourthSection?.TypeOfProperty === "Minerals"
                    ? "fa-check-square-o" : "fa-square-o")}></i>
                Minerals
              </span>
              <span className={shared.Column4}>
                <i className={"fa " +
                  (formDataFieldsFourthSection?.TypeOfProperty === "Agricultural"
                    ? "fa-check-square-o" : "fa-square-o")}></i>
                Agricultural
              </span>
            </div>
            <div className={shared.Row}>              
              <span className={shared.Column4}>
                <i className={"fa " +
                  (formDataFieldsFourthSection?.TypeOfProperty === "BusinessPersonalProperty"
                    ? "fa-check-square-o" : "fa-square-o")}></i>
                Business Personal Property
              </span>
            </div>
            <div className={shared.Row}>
              <span className={shared.Column5}>
                <div className={shared.Value}>
                  {formDataFieldsFourthSection?.DatePropertyPurchased}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Date you purchased this property
                </div>
              </span>
              <span className={`${shared.Column3LeftPadding} ${shared.TextRight}`}>
                <span className={shared.TextSmall}>
                  Type of Disaster:
                </span>
              </span>
              <span className={shared.Column4RightPadding}>
                <div className={shared.Value}>
                  {formDataFieldsFourthSection?.TypeOfDisaster}
                </div>
                <div className={shared.ValueLabelBelow}>
                  (As stated in governor&apos;s declaration)
                </div>
              </span>
            </div>
            <div className={shared.Row}>
              <div className={shared.Column12}>
                <div className={shared.Value}>
                  {formDataFieldsFourthSection?.PhysicalAddressLine1}{" "}
                  &nbsp;
                  {formDataFieldsFourthSection?.PhysicalAddressLine2 && (
                    <span>
                      {
                        formDataFieldsFourthSection?.PhysicalAddressLine2
                      }{" "}
                      &nbsp;
                    </span>
                  )}
                  {formDataFieldsFourthSection?.PhysicalAddressCity}{" "}
                  &nbsp;
                  {formDataFieldsFourthSection?.PhysicalAddressState}
                  , &nbsp;
                  {
                    formDataFieldsFourthSection?.PhysicalAddressZip
                  }{" "}
                  &nbsp;
                </div>
                <div className={shared.ValueLabelBelow}>
                  Physical Address <i>(i.e. street address, not P.O. Box)</i>, City, County, ZIP Code
                </div>
              </div>
            </div>
            <div className={shared.Row}>
              <div className={shared.Column4}>
                <div className={shared.Value}>
                  {formDataFieldsFourthSection?.ManufacturedHomeMake}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Manufactured Home Make
                </div>
              </div>
              <div className={shared.Column4}>
                <div className={shared.Value}>
                  {formDataFieldsFourthSection?.Model}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Model
                </div>
              </div>
              <div className={shared.Column4}>
                <div className={shared.Value}>
                  {formDataFieldsFourthSection?.IdNumber}
                </div>
                <div className={shared.ValueLabelBelow}>ID Number</div>
              </div>
            </div>
            <div className={shared.Row}>
              <div className={shared.Column9}>
                <div className={shared.TextSmall}>
                  Number of acres (or fraction of an acre, not to exceed 20 acres) 
                  you own and occupy as your principal residence:
                </div>
              </div>
              <div className={shared.Column2}>
                <div className={shared.Value}>
                  {formDataFieldsFourthSection?.NumberOfAcres}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Acres
                </div>
              </div>
            </div>
          </div>

          <div className={shared.PageBreak}></div>

          <div className={shared.Step}>SECTION 4: Damage Description</div>
          <div>
            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>1.</div>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                Can the property be repaired?
              </div>
              <div className={`${shared.Column2} ${shared.TextRight}`}>
                <i
                className={
                    "fa " +
                    (formDataFieldsFifthSection?.CanPropertyBeRepaired == true
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                className={
                    " fa " +
                    (!formDataFieldsFifthSection?.CanPropertyBeRepaired == false
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
                ></i>{" "}
                &nbsp; No
              </div>
            </div>
            
            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>2.</div>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                If this is a homestead, do you intend to return?
              </div>
              <div className={`${shared.Column2} ${shared.TextRight}`}>
                <i
                className={
                    "fa " +
                    (formDataFieldsFifthSection?.IfHomesteadDoIntendToReturn == true
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                className={
                    " fa " +
                    (!formDataFieldsFifthSection?.IfHomesteadDoIntendToReturn == false
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
                ></i>{" "}
                &nbsp; No
              </div>
            </div>
            
            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>3.</div>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                Is any part inhabitable?
              </div>
              <div className={`${shared.Column2} ${shared.TextRight}`}>
                <i
                className={
                    "fa " +
                    (formDataFieldsFifthSection?.IsAnyPartInhabitable == true
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                className={
                    " fa " +
                    (!formDataFieldsFifthSection?.IsAnyPartInhabitable == false
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
                ></i>{" "}
                &nbsp; No
              </div>
            </div>
            <div className={shared.Row}>
                <div className={`${shared.Column3} ${shared.TextSmall} ${shared.MarginLeft20}`}>
                  Explain:
                </div>
                <div className={`${shared.Column8} ${shared.TextRight}`}>
                    <div className={shared.Value}>
                        {formDataFieldsFifthSection?.IsAnyPartInhabitableExplain}
                    </div>
                </div>
            </div>
            
            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>4.</div>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                If this is a business, do you intend to reopen?
              </div>
              <div className={`${shared.Column2} ${shared.TextRight}`}>
                <i
                className={
                    "fa " +
                    (formDataFieldsFifthSection?.IfBusinessDoIntendToReopen == true
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                className={
                    " fa " +
                    (!formDataFieldsFifthSection?.IfBusinessDoIntendToReopen == false
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
                ></i>{" "}
                &nbsp; No
              </div>
            </div>
            
            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>5.</div>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                Is there structural damage?
              </div>
              <div className={`${shared.Column2} ${shared.TextRight}`}>
                <i
                className={
                    "fa " +
                    (formDataFieldsFifthSection?.IsStructuralDamage == true
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                className={
                    " fa " +
                    (!formDataFieldsFifthSection?.IsStructuralDamage == false
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
                ></i>{" "}
                &nbsp; No
              </div>
            </div>
            <div className={shared.Row}>
                <div className={`${shared.Column3} ${shared.TextSmall} ${shared.MarginLeft20}`}>
                  Explain:
                </div>
                <div className={`${shared.Column8} ${shared.TextRight}`}>
                    <div className={shared.Value}>
                        {formDataFieldsFifthSection?.IsStructuralDamageExplain}
                    </div>
                </div>
            </div>
            
            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>6.</div>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                Is there non-structural damage?
              </div>
              <div className={`${shared.Column2} ${shared.TextRight}`}>
                <i
                className={
                    "fa " +
                    (formDataFieldsFifthSection?.IsNonStructuralDamageOnly == true
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                className={
                    " fa " +
                    (!formDataFieldsFifthSection?.IsNonStructuralDamageOnly == false
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
                ></i>{" "}
                &nbsp; No
              </div>
            </div>
            <div className={shared.Row}>
                <div className={`${shared.Column3} ${shared.TextSmall} ${shared.MarginLeft20}`}>
                  Explain:
                </div>
                <div className={`${shared.Column8} ${shared.TextRight}`}>
                    <div className={shared.Value}>
                        {formDataFieldsFifthSection?.IsNonStructuralDamageOnlyExplain}
                    </div>
                </div>
            </div>
            
            <br/>
            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>7.</div>
              <span className={shared.Column3}>
                <div className={shared.Value}>
                  {formDataFieldsFifthSection?.EstimatedCostToRepair}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Estimated cost to repair
                </div>
              </span>
              <span className={shared.Column3}>
                <div className={shared.Value}>
                  {formDataFieldsFifthSection?.EstimatedDateRepairsBegin}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Estimated date repairs begin
                </div>
              </span>
              <span className={shared.Column3}>
                <div className={shared.Value}>
                  {formDataFieldsFifthSection?.EstimatedCompletionDate}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Estimated completion date
                </div>
              </span>
            </div>
            
            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>8.</div>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                Are you still waiting for assistance with cost or repair?
              </div>
              <div className={`${shared.Column2} ${shared.TextRight}`}>
                <i
                className={
                    "fa " +
                    (formDataFieldsFifthSection?.IsStillWaitingForCostRepairAssistance == true
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                className={
                    " fa " +
                    (!formDataFieldsFifthSection?.IsStillWaitingForCostRepairAssistance == false
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
                ></i>{" "}
                &nbsp; No
              </div>
            </div>               
          </div>

          <div className={shared.Step}>SECTION 6: Certification and Signature</div>
          <div className={shared.PageBreak}>
            <div>
              <b>
                If you make a false statement on this form, you could be found guilty of a 
                Class A misdemeanor or a state jail felony under Section 37.10, Penal Code. 
              </b>
            </div>

            <br />
            <div className={shared.PrintedName}>
              I, {" "}
              <div className={shared.Width60}>
                {" "}
                <div className={shared.Value}>
                  {formDataFieldsSeventhSection?.Signature}
                </div>
                <div className={`${shared.ValueLabelBelow} ${shared.TextCenter}`}>
                  Printed Name of Property Owner or Authorized Representative
                </div>
              </div>
              , swear or affirm the following:
            </div>
            <br />
            <div className={shared.Row}>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                that each fact contained in this application is true and
                correct; and that the property described in this application meets 
                the qualifications under Texas law for the exemption claimed.
              </div>
            </div>

            <div className={`${shared.Row} ${shared.SpaceBetween}`}>
              <div className={shared.Column8}>
                <div className={shared.Value}>
                  {formDataFieldsSeventhSection?.Signature}
                </div>
                <div className={`${shared.ValueLabelBelow} ${shared.TextCenter}`}>
                  Signature of Property Owner or Authorized Representative
                </div>
              </div>
              <div className={shared.Column3}>
                <div className={shared.Value}>
                  {formDataFieldsSeventhSection?.SignatureDate ? new Date(formDataFieldsSeventhSection?.SignatureDate).toDateString() : ""}
                </div>
                <div className={`${shared.ValueLabelBelow} ${shared.TextCenter}`}>Date</div>
              </div>
            </div>

            <br />
            <div className={shared.Row}>
              <div className={shared.Column1}></div>
              <div className={shared.Column8}>
                <table className={shared.Blue}>
                  <tr>
                    <th colSpan={2}>FOR APPRAISAL DISTRICT USE</th>
                  </tr>
                  <tr>
                    <td>Date inspected / appraised</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Level of Damage</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Appraiser</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Summary</td>
                    <td></td>
                  </tr>
                </table>
              </div>
              <div className={shared.Column1}></div>
            </div>
            
            <div className={shared.Row}>
              <div className={`${shared.Column12} ${shared.TextSmall}`}>
                * &nbsp; Social security number disclosure may be required for tax administration 
                and identification. (42 U.S.C. §405(c)(2)(C)(i); Tax Code §11.43(f)). A driver&apos;s 
                license number, personal identification number or social security number disclosed 
                in an exemption application is confidential and not open to public inspection, 
                except as authorized by Tax Code §11.48(b).
              </div>
            </div>
            
            <div className={shared.Row}>
              <div className={`${shared.Column12} ${shared.TextSmall}`}>
                ** &nbsp; May be confidential under Government Code §552.137.
              </div>
            </div>
          </div>

          <div className={shared.PrintTogether}>
            <div className={`${shared.H3} ${shared.TextCenter}`}>Important Information</div>
            <div className={shared.Row}>
              <div className={shared.Column6}>
                <p><b>GENERAL INFORMATION:</b>&nbsp; 
                  Property owners use this form to claim a temporary property 
                  tax exemption for property in a governor-declared disaster 
                  area with at least 15 percent damage. (Tax Code Section 11.35)
                </p>
                
                <p><b>FILING INSTRUCTIONS:</b>&nbsp;
                  File this form and all supporting documentation with the
                  appraisal district office in each county where property is located and 
                  the temporary exemption is requested. <b>Do not file this document with the 
                  Texas Comptroller of Public Accounts.</b> Contact information for appraisal 
                  district offices may be found on the Comptroller&apos;s website.
                </p>
              </div>

              <div className={shared.Column6}>
                <p><b>DEADLINE:</b>&nbsp;
                    Application and supporting documentation must be submitted to the appraisal 
                    district no later than the 105th day after the date the governor declares the
                    area as a disaster area. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
