import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "src/pages/Home/components/Forms/ExemptionPropertyDamagedForm/ExemptionPropertyDamaged.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { DatePickerChangeEvent, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, RadioGroupChangeEvent, Input } from "@progress/kendo-react-inputs";
import { FormValidation } from "../InputValidator";
import { Error, Label } from "@progress/kendo-react-labels";
import { EmailInput } from "../CustomInputs/EmailInputs/EmailInput";
import { PhoneNumberInput } from "../CustomInputs/PhoneNumberInputs/PhoneNumberInput";
import { StatesInput } from "../CustomInputs/StatesInput";
import { ZipCodeInput } from "../CustomInputs/ZipCodeInput";
import { IExemptionPropertyDamagedFormSecondSection } from "src/interfaces/forms/ExemptionPropertyDamagedByDisasterForm/exemption-property-damaged-second-section.interface";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IExemptionPropertyDamagedFormSecondSection) => void;
    formDataFields?:  IExemptionPropertyDamagedFormSecondSection,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function ExemptionPropertyDamagedFormSecondSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, setFormIsValid } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const [emailInputValid, setEmailInputValid] = useState<boolean>(false);
    const [primaryPhoneNumberInputValid, setPrimaryPhoneNumberInputValid] = useState<boolean>(false);
    const [secondaryContactNumberInputValid, setSecondaryContactNumberInputValid] = useState<boolean>(false);
    const [mailingAddressStateInputValid, setMailingAddressStateInputValid] = useState<boolean>(false);
    const [mailingAddressZipCodeInputValid, setMailingAddressZipCodeInputValid] = useState<boolean>(false);
    const [alternateMailingAddressStateInputValid, setAlternateMailingAddressStateInputValid] = useState<boolean>(false);
    const [alternateMailingAddressZipCodeInputValid, setAlternateMailingAddressZipCodeInputValid] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator } = FormValidation(formRef);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={2} name={"Property Owner Information"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string,
        group?: string
    )
    {
        if (group) {
            setFormDataFields({
                ...formDataFields,
                [group]: {
                    ...(formDataFields as any)?.[group],
                    [field]: event.value
                }
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    function changeValidator() {
        if (formRef.current) {
            let isSectionValid = 
                primaryPhoneNumberInputValid &&
                secondaryContactNumberInputValid &&
                emailInputValid &&
                mailingAddressStateInputValid &&
                mailingAddressZipCodeInputValid &&
                alternateMailingAddressStateInputValid &&
                alternateMailingAddressZipCodeInputValid;
            const isValid = formRef.current.checkValidity() && isSectionValid;
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if (!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [
        formDataFields,
        formRef.current,
        primaryPhoneNumberInputValid,
        secondaryContactNumberInputValid,
        emailInputValid,
        mailingAddressStateInputValid,
        mailingAddressZipCodeInputValid,
        alternateMailingAddressStateInputValid,
        alternateMailingAddressZipCodeInputValid
    ]);

    return (
       <>
            <PanelBar keepItemsMounted={true}>
                <PanelBarItem title={title} expanded={true}>
                    <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                        <div className="container">
                            <div className={`${classes.item} ${classes.bordered}`}>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Name
                                            {!formDataFields?.Name && (
                                                <i className={"fa fa-asterisk text-danger"}></i>
                                            )}
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            required={true}
                                            name={"Name"}
                                            placeholder={"Name"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.Name}
                                            onChange={(e)=> changeValueHandler(e, "Name")}
                                        />
                                        {!inputValidator("Name") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Driver’s License, Personal I.D. Certificate or Social Security Number
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            required={true}
                                            name={"PersonalId"}
                                            placeholder={"Driver’s License, Personal I.D. Certificate or Social Security Number"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.PersonalId}
                                            onChange={(e)=> changeValueHandler(e, "PersonalId")}
                                        />
                                        {!inputValidator("PersonalId") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW30}>
                                        <Label className={classes.Label}>
                                            Primary Phone Number (area code and number)
                                        </Label>
                                        <PhoneNumberInput
                                            required={false}
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"PrimaryPhoneNumber"}
                                            placeholder={"Primary Phone Number (area code and number)"}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                            setIsValidField={setPrimaryPhoneNumberInputValid}
                                            validField={primaryPhoneNumberInputValid}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW30}>
                                        <Label className={classes.Label}>
                                            Secondary Contact Number (area code and number)
                                        </Label>
                                        <PhoneNumberInput
                                            required={false}
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"SecondaryContactNumber"}
                                            placeholder={"Secondary Contact Number (area code and number)"}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                            setIsValidField={setSecondaryContactNumberInputValid}
                                            validField={secondaryContactNumberInputValid}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW30}>
                                        <Label className={classes.Label}>
                                            Email Address
                                        </Label>
                                        <EmailInput
                                            required={false}
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"EmailAddress"}
                                            placeholder={"Email Address"}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                            setIsValidField={setEmailInputValid}
                                            validField={emailInputValid}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.item}`}>
                                <div className={`${classes.addressFields} ${classes.fieldsGroup}`}>
                                    <div className={`${classes.fieldsGroupW50} ${classes.bordered}`}>
                                        <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                            <p>Mailing Address</p>
                                        </div>
                                        <Label className={classes.Label}>
                                            Address Line 1
                                            {!formDataFields?.MailingAddressLine1 && (
                                                <i className={"fa fa-asterisk text-danger"}></i>
                                            )}
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"MailingAddressLine1"}
                                            placeholder={"Address Line 1"}
                                            required={true}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.MailingAddressLine1}
                                            onChange={(e)=> changeValueHandler(e, "MailingAddressLine1")}
                                        />
                                        {!inputValidator("MailingAddressLine1") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                        <Label className={classes.Label}>
                                            Address Line 2
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"MailingAddressLine2"}
                                            placeholder={"Address Line 2"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.MailingAddressLine2}
                                            onChange={(e)=> changeValueHandler(e, "MailingAddressLine2")}
                                        />
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <div className={`${classes.fieldsGroupW30}`}>
                                                <Label className={classes.Label}>
                                                    City
                                                    {!formDataFields?.MailingAddressCity && (
                                                        <i className={"fa fa-asterisk text-danger"}></i>
                                                    )}
                                                </Label>
                                                <Input
                                                    className={classes.kTextbox}
                                                    required={true}
                                                    name={"MailingAddressCity"}
                                                    placeholder={"City"}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.MailingAddressCity}
                                                    onChange={(e)=> changeValueHandler(e, "MailingAddressCity")}
                                                />
                                                {!inputValidator("MailingAddressCity") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                            <div className={`${classes.fieldsGroupW30}`}>
                                                <Label className={classes.Label}>
                                                    State
                                                    {!formDataFields?.MailingAddressState && (
                                                        <i className={"fa fa-asterisk text-danger"}></i>
                                                    )}
                                                </Label>
                                                <StatesInput
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"MailingAddressState"}
                                                    required={true}
                                                    validField={mailingAddressStateInputValid}
                                                    setIsValidField={setMailingAddressStateInputValid}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                />
                                                {!inputValidator("MailingAddressState") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                            <div className={`${classes.fieldsGroupW20}`}>
                                                <Label className={classes.Label}>
                                                    Zip Code
                                                    {!formDataFields?.MailingAddressZip && (
                                                        <i className={"fa fa-asterisk text-danger"}></i>
                                                    )}
                                                </Label>
                                                <ZipCodeInput
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"MailingAddressZip"}
                                                    required={true}
                                                    placeholder={"Zip"}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                    setIsValidField={setMailingAddressZipCodeInputValid}
                                                    validField={mailingAddressZipCodeInputValid}
                                                />
                                                {!inputValidator("MailingAddressZip") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${classes.fieldsGroupW50} ${classes.bordered}`}>
                                        <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                            <p>Alternate Mailing Address</p>
                                        </div>
                                        <Label className={classes.Label}>
                                            Address Line 1
                                            {!formDataFields?.AlternateMailingAddressLine1 && (
                                                <i className={"fa fa-asterisk text-danger"}></i>
                                            )}
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"AlternateMailingAddressLine1"}
                                            placeholder={"Address Line 1"}
                                            required={true}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.AlternateMailingAddressLine1}
                                            onChange={(e)=> changeValueHandler(e, "AlternateMailingAddressLine1")}
                                        />
                                        {!inputValidator("AlternateMailingAddressLine1") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                        <Label className={classes.Label}>
                                            Address Line 2
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"AlternateMailingAddressLine2"}
                                            placeholder={"Address Line 2"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.AlternateMailingAddressLine2}
                                            onChange={(e)=> changeValueHandler(e, "AlternateMailingAddressLine2")}
                                        />
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <div className={`${classes.fieldsGroupW30}`}>
                                                <Label className={classes.Label}>
                                                    City
                                                    {!formDataFields?.AlternateMailingAddressCity && (
                                                        <i className={"fa fa-asterisk text-danger"}></i>
                                                    )}
                                                </Label>
                                                <Input
                                                    className={classes.kTextbox}
                                                    required={true}
                                                    name={"AlternateMailingAddressCity"}
                                                    placeholder={"City"}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.AlternateMailingAddressCity}
                                                    onChange={(e)=> changeValueHandler(e, "AlternateMailingAddressCity")}
                                                />
                                                {!inputValidator("AlternateMailingAddressCity") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                            <div className={`${classes.fieldsGroupW30}`}>
                                                <Label className={classes.Label}>
                                                    State
                                                    {!formDataFields?.AlternateMailingAddressState && (
                                                        <i className={"fa fa-asterisk text-danger"}></i>
                                                    )}
                                                </Label>
                                                <StatesInput
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"AlternateMailingAddressState"}
                                                    required={true}
                                                    validField={alternateMailingAddressStateInputValid}
                                                    setIsValidField={setAlternateMailingAddressStateInputValid}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                />
                                                {!inputValidator("AlternateMailingAddressState") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                            <div className={`${classes.fieldsGroupW20}`}>
                                                <Label className={classes.Label}>
                                                    Zip Code
                                                    {!formDataFields?.AlternateMailingAddressZip && (
                                                        <i className={"fa fa-asterisk text-danger"}></i>
                                                    )}
                                                </Label>
                                                <ZipCodeInput
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"AlternateMailingAddressZip"}
                                                    placeholder={"Zip"}
                                                    required={true}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                    setIsValidField={setAlternateMailingAddressZipCodeInputValid}
                                                    validField={alternateMailingAddressZipCodeInputValid}
                                                />
                                                {!inputValidator("AlternateMailingAddressZip") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                   </form>
                </PanelBarItem>
            </PanelBar>
       </>
    )
}