import { PanelBar } from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import { PanelBarItem } from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./DisabledVetForm.module.scss";
import { IFormSettingModel } from "../../../../../interfaces/form-settings.interface";
import { IFormEntryModel } from "../../../../../interfaces/form-entry.interface";
import { useEffect, useRef, useState } from "react";
import { SelectedFormType } from "../../../../../context/SelectedForm/selected-form-reducer";
import { GenericActionType } from "../../../../../context/generic-actions.type";
import { CheckboxChangeEvent, Input, InputChangeEvent, NumericTextBox, NumericTextBoxChangeEvent, RadioGroup, RadioGroupChangeEvent, TextAreaChangeEvent } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { IDisabledVetFifthSection } from "src/interfaces/forms/DisableVetForm/disable-vet-form-fifth-section";
import { DatePickerChangeEvent, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { FormValidation } from "../InputValidator";
import { CheckboxGroup } from "../CustomInputs/CheckBoxes/CheckboxGroup";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IDisabledVetFifthSection) => void;
    formDataFields?:  IDisabledVetFifthSection,
    formSettings?: IFormSettingModel,
    formEntry?: IFormEntryModel,
    setFormIsValid: (value: boolean) => void,
    selectedFormDispatch: (value: GenericActionType<SelectedFormType>) => void,
}

export default function DisabledVetFifthSection(props: GeneralFormType): JSX.Element {
    const { formDataFields, setFormDataFields, formSettings, isAdminPreview, formEntry, setFormIsValid, selectedFormDispatch } = props;
    const formRef = useRef<HTMLFormElement>(null);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const { validationMessage, inputValidator } = FormValidation(formRef);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={5} name={"Type of Exemption and Qualifications"}/>;

    const exemptionTypeRadioButtonsData = [
        { label: "Veteran classified as disabled by the Veteran’s Administration (VA) or a service branch of the U.S. armed forces", value: "Veteran" },
        { label: "Surviving spouse or child of a deceased disabled veteran", value: "SpouseOrChildOfDeceasedDisabledVeteran" },
        { label: "Surviving spouse or child of U.S. armed service member who died on active duty", value: "SpouseOrChildOfUSArmedServiceMember" }
    ]

    const disabilityTypeRadioButtonsData = [
        { label: "Loss of use of one or more limbs", value: "LossOfLimb" },
        { label: "Blindness in one or both eyes", value: "Blindness" }
    ]

    const [survivingSpouseOfVeteranValid, setSurvivingSpouseOfVeteranValid] = useState<boolean>(false);
    const [survivingSpouseRemarriedValid, setSurvivingSpouseRemarriedValid] = useState<boolean>(false);
    const [survivingSpouseOfUSServiceMemberValid, setSurvivingSpouseOfUSServiceMemberValid] = useState<boolean>(false);
    const [survivingChildValid, setSurvivingChildValid] = useState<boolean>(false);
    const [spouseSurviveVeteranValid, setSpouseSurviveVeteranValid] = useState<boolean>(false);
    const [childUnder18Valid, setChildUnder18Valid] = useState<boolean>(false);
    const [childUnmarriedValid, setChildUnmarriedValid] = useState<boolean>(false);
    
    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent
            | NumericTextBoxChangeEvent,
        field: string
    )
    {
        setFormDataFields({
            ...formDataFields,
            [field]: event.value
        });
    }

    function changeValidator() {
        if (formRef.current) {
            const isValid = formRef.current.checkValidity() &&
                survivingSpouseOfVeteranValid &&
                survivingSpouseRemarriedValid &&
                survivingSpouseOfUSServiceMemberValid &&
                survivingChildValid &&
                spouseSurviveVeteranValid &&
                childUnder18Valid &&
                childUnmarriedValid;
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if (!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [
        formDataFields,
        formRef.current,
        survivingSpouseOfVeteranValid,
        survivingSpouseRemarriedValid,
        survivingSpouseOfUSServiceMemberValid,
        survivingChildValid,
        spouseSurviveVeteranValid,
        childUnder18Valid,
        childUnmarriedValid
    ]);

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                <Label className={classes.Label}>
                                    Please indicate the basis for your authority to represent the property owner in filing this application.
                                </Label>
                                <div className={classes.inputsGroup}>
                                    <RadioGroup
                                        layout={"vertical"}
                                        name={"ExemptionType"}
                                        disabled={formSettings?.isReadOnly}
                                        data={exemptionTypeRadioButtonsData}
                                        onChange={e => changeValueHandler(e, "ExemptionType")}
                                        value={formDataFields?.ExemptionType}
                                    />
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Veteran&apos;s Name
                                        {!formDataFields?.VeteransName && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={true}
                                        name={"VeteransName"}
                                        placeholder={"Veteran's Name"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.VeteransName}
                                        onChange={(e)=> changeValueHandler(e, "VeteransName")}
                                    />
                                    {!inputValidator("VeteransName") && (
                                        <Error>{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={`${classes.fieldsGroupW30}`}>
                                        <Label className={classes.Label}>
                                            Branch of Service
                                            {!formDataFields?.VeteransBranchOfServices && (
                                                <i className={"fa fa-asterisk text-danger"}></i>
                                            )}
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            required={true}
                                            name={"VeteransBranchOfServices"}
                                            placeholder={"Branch of Service"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.VeteransBranchOfServices}
                                            onChange={(e)=> changeValueHandler(e, "VeteransBranchOfServices")}
                                        />
                                        {!inputValidator("VeteransBranchOfServices") && (
                                            <Error>{validationMessage}</Error>
                                        )}
                                    </div>
                                    <div className={`${classes.fieldsGroupW20}`}>
                                        <Label className={classes.Label}>
                                            Disability Rating
                                            {!formDataFields?.VeteransDisabilityRating && (
                                                <i className={"fa fa-asterisk text-danger"}></i>
                                            )}
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            required={true}
                                            name={"VeteransDisabilityRating"}
                                            placeholder={"Disability Rating"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.VeteransDisabilityRating}
                                            onChange={(e)=> changeValueHandler(e, "VeteransDisabilityRating")}
                                        />
                                        {!inputValidator("VeteransDisabilityRating") && (
                                            <Error>{validationMessage}</Error>
                                        )}
                                    </div>
                                    <div className={`${classes.fieldsGroupW15}`}>
                                        <Label className={classes.Label}>
                                            Age
                                            {!formDataFields?.VeteransAge && (
                                                <i className={"fa fa-asterisk text-danger"}></i>
                                            )}
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            required={true}
                                            name={"VeteransAge"}
                                            placeholder={"Age"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.VeteransAge}
                                            onChange={(e)=> changeValueHandler(e, "VeteransAge")}
                                        />
                                        {!inputValidator("VeteransAge") && (
                                            <Error>{validationMessage}</Error>
                                        )}
                                    </div>
                                    <div className={`${classes.fieldsGroupW20}`}>
                                        <Label className={classes.Label}>
                                            Serial Number (If Known)
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"VeteransSerialNumber"}
                                            placeholder={"Serial Number (If Known)"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.VeteransSerialNumber}
                                            onChange={(e)=> changeValueHandler(e, "VeteransSerialNumber")}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.itemFields}`}>
                                <div className={` ${classes.fieldsGroupW70}`}>
                                    <Label className={classes.Label}>
                                        Does the service-connected disability include:
                                    </Label>
                                    <RadioGroup
                                        layout={"horizontal"}
                                        name={"DisabilityType"}
                                        disabled={formSettings?.isReadOnly}
                                        data={disabilityTypeRadioButtonsData}
                                        onChange={e => changeValueHandler(e, "DisabilityType")}
                                        value={formDataFields?.DisabilityType}
                                    />
                                </div>
                            </div>
                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                <Label className={classes.Label}>
                                    Are you the surviving spouse of a disabled veteran?
                                </Label>
                                <div className={classes.fieldsGroup}>
                                    <div className={classes.checkboxGroup}>
                                        <CheckboxGroup
                                            isAdminPreview={isAdminPreview}
                                            setFormDataFields={setFormDataFields}
                                            formDataFields={formDataFields}
                                            formSettings={formSettings}
                                            fieldName={"SurvivingSpouseOfVeteran"}
                                            fieldSecondName={"SurvivingSpouseOfVeteranSecond"}
                                            isRequired={false}
                                            checkboxesIsValid={survivingSpouseOfVeteranValid}
                                            setCheckboxesIsValid={setSurvivingSpouseOfVeteranValid}
                                        />
                                    </div>
                                </div>
                            </div>
                            { formDataFields?.SurvivingSpouseOfVeteran && 
                                <div className={`${classes.itemFieldsLeft}`}>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                        <Label className={classes.Label}>
                                            If yes, have you remarried?
                                        </Label>
                                        <div className={classes.fieldsGroup}>
                                            <div className={classes.checkboxGroup}>
                                                <CheckboxGroup
                                                    isAdminPreview={isAdminPreview}
                                                    setFormDataFields={setFormDataFields}
                                                    formDataFields={formDataFields}
                                                    formSettings={formSettings}
                                                    fieldName={"SurvivingSpouseRemarried"}
                                                    fieldSecondName={"SurvivingSpouseRemarriedSecond"}
                                                    isRequired={false}
                                                    checkboxesIsValid={survivingSpouseRemarriedValid}
                                                    setCheckboxesIsValid={setSurvivingSpouseRemarriedValid}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                <Label className={classes.Label}>
                                    Are you the surviving spouse of a U.S. armed service member who died on active duty?
                                </Label>
                                <div className={classes.fieldsGroup}>
                                    <div className={classes.checkboxGroup}>
                                        <CheckboxGroup
                                            isAdminPreview={isAdminPreview}
                                            setFormDataFields={setFormDataFields}
                                            formDataFields={formDataFields}
                                            formSettings={formSettings}
                                            fieldName={"SurvivingSpouseOfUSServiceMember"}
                                            fieldSecondName={"SurvivingSpouseOfUSServiceMemberSecond"}
                                            isRequired={false}
                                            checkboxesIsValid={survivingSpouseOfUSServiceMemberValid}
                                            setCheckboxesIsValid={setSurvivingSpouseOfUSServiceMemberValid}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                <Label className={classes.Label}>
                                    Are you a surviving child of a deceased disabled veteran or U.S. 
                                    armed service member who died on active duty?
                                </Label>
                                <div className={classes.fieldsGroup}>
                                    <div className={classes.checkboxGroup}>
                                        <CheckboxGroup
                                            isAdminPreview={isAdminPreview}
                                            setFormDataFields={setFormDataFields}
                                            formDataFields={formDataFields}
                                            formSettings={formSettings}
                                            fieldName={"SurvivingChild"}
                                            fieldSecondName={"SurvivingChildSecond"}
                                            isRequired={false}
                                            checkboxesIsValid={survivingChildValid}
                                            setCheckboxesIsValid={setSurvivingChildValid}
                                        />
                                    </div>
                                </div>
                            </div>
                            { formDataFields?.SurvivingChild &&
                                <div className={`${classes.itemFieldsLeft}`}>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                        <Label className={classes.Label}>
                                            If yes, did the spouse survive the veteran or the U.S. armed service member?
                                        </Label>
                                        <div className={classes.fieldsGroup}>
                                            <div className={classes.checkboxGroup}>
                                                <CheckboxGroup
                                                    isAdminPreview={isAdminPreview}
                                                    setFormDataFields={setFormDataFields}
                                                    formDataFields={formDataFields}
                                                    formSettings={formSettings}
                                                    fieldName={"SpouseSurviveVeteran"}
                                                    fieldSecondName={"SpouseSurviveVeteranSecond"}
                                                    isRequired={false}
                                                    checkboxesIsValid={spouseSurviveVeteranValid}
                                                    setCheckboxesIsValid={setSpouseSurviveVeteranValid}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                        <Label className={classes.Label}>
                                            Are you under 18 years of age?
                                        </Label>
                                        <div className={classes.fieldsGroup}>
                                            <div className={classes.checkboxGroup}>
                                                <CheckboxGroup
                                                    isAdminPreview={isAdminPreview}
                                                    setFormDataFields={setFormDataFields}
                                                    formDataFields={formDataFields}
                                                    formSettings={formSettings}
                                                    fieldName={"ChildUnder18"}
                                                    fieldSecondName={"ChildUnder18Second"}
                                                    isRequired={false}
                                                    checkboxesIsValid={childUnder18Valid}
                                                    setCheckboxesIsValid={setChildUnder18Valid}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    { formDataFields?.ChildUnder18 &&
                                        <div className={`${classes.itemFieldsLeft}`}>
                                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                                <Label className={classes.Label}>
                                                    Are you unmarried?
                                                </Label>
                                                <div className={classes.fieldsGroup}>
                                                    <div className={classes.checkboxGroup}>
                                                        <CheckboxGroup
                                                            isAdminPreview={isAdminPreview}
                                                            setFormDataFields={setFormDataFields}
                                                            formDataFields={formDataFields}
                                                            formSettings={formSettings}
                                                            fieldName={"ChildUnmarried"}
                                                            fieldSecondName={"ChildUnmarriedSecond"}
                                                            isRequired={false}
                                                            checkboxesIsValid={childUnmarriedValid}
                                                            setCheckboxesIsValid={setChildUnmarriedValid}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                        <Label className={classes.Label}>
                                            Number of qualifying parent&apos;s children who are under 18 and unmarried
                                        </Label>
                                        <NumericTextBox
                                            disabled={formSettings?.isReadOnly}
                                            required={false}
                                            className={classes.kTextbox}
                                            value={formDataFields?.NumberOfChildren}
                                            onChange={(e)=> changeValueHandler(e, "NumberOfChildren")}
                                            spinners={false}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
