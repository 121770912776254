import {IFormFiledInterface} from "../../../../../interfaces/form-filed.interface";
import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./BPPForm.module.scss";
import {
    CheckboxChangeEvent,
    Input,
    InputChangeEvent, NumericTextBox, NumericTextBoxChangeEvent, RadioGroupChangeEvent,
    TextAreaChangeEvent
} from "@progress/kendo-react-inputs";
import {
    IBppFormSeventhSectionInterface
} from "../../../../../interfaces/forms/BPPForm/bpp-form-seventh-section.interface";
import {DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import React, {useEffect, useRef, useState} from "react";
import { IFormEntryModel } from "../../../../../interfaces/form-entry.interface";
import {CustomRowDatePicker} from "../CustomInputs/CustomDatePickers/CustomRowDatePicker";
import {IRowState} from "../CustomInputs/UpdateRowErrorStateHandler";
import {ScheduleAName} from "../../../../../constants/form-fields-constants";
import {NumberFormatOptions} from "@telerik/kendo-intl";
import {CustomOnlyYearCalendarHelper} from "../../../../Shared/DatePickers/CustomCalendars";
import {Label} from "@progress/kendo-react-labels";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
  isAdminPreview?: boolean;
  formFields?: Array<IFormFiledInterface>;
  setFormDataFields: (form: IBppFormSeventhSectionInterface) => void;
  formDataFields?: IBppFormSeventhSectionInterface;
  formSettings?: IFormSettingModel;
  setFormIsValid: (value: boolean) => void;
  formEntry?: IFormEntryModel;
}

const maxScheduleYearDate = new Date(new Date().getFullYear(), 1,1);

export function BPPFormSeventhSection(props: GeneralFormType): JSX.Element{
    const { formFields, setFormDataFields, formDataFields, formSettings, isAdminPreview, setFormIsValid, formEntry } = props;
    const formRef = useRef<HTMLFormElement>(null);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={7} name={"Schedule A"}/>;
    const currencyFormatOptions: NumberFormatOptions = { maximumFractionDigits: 0 };
    const { CustomOnlyYearCalendar } = CustomOnlyYearCalendarHelper(maxScheduleYearDate.getFullYear() - 1);
    const editDisabled = formSettings?.isReadOnly || formEntry?.initiatedBy === 'PropertySearch' || isAdminPreview;
    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | NumericTextBoxChangeEvent,
        field: string,
        id: number,
    )
    {
        const newItem: IBppFormSeventhSectionInterface = formDataFields ? JSON.parse(JSON.stringify(formDataFields)) : [];

        let ScheduleA = newItem.ScheduleA?.find(x => x.Id == id);

        if (field == "YearAcquired"){
            ScheduleA = {...ScheduleA, [field]: event.value.getFullYear(), ScheduleName: ScheduleAName};
        } else {
            ScheduleA = {...ScheduleA, [field]: event.value, ScheduleName: ScheduleAName};
        }

        const index = newItem.ScheduleA?.findIndex(x => x.Id == id);

        if (index != undefined && newItem.ScheduleA) {
            newItem.ScheduleA[index] = ScheduleA
        }
        setFormDataFields({
            ...newItem
        })
    }

    function addRowHandler(event:  React.MouseEvent<HTMLElement>){
        event.preventDefault();
        const newItem: IBppFormSeventhSectionInterface = formDataFields ? JSON.parse(JSON.stringify(formDataFields)) : [];
        if(!newItem.ScheduleA){
            newItem.ScheduleA = [];
        }
        newItem.ScheduleA?.push({ Id: newItem.ScheduleA?.length });
        setFormDataFields({
            ...newItem,
        });
    }

    function removeRowHandler(event:  React.MouseEvent<HTMLElement>){
        event.preventDefault();
        const newItem: IBppFormSeventhSectionInterface = formDataFields ? JSON.parse(JSON.stringify(formDataFields)) : [];
        if(newItem && newItem.ScheduleA){
            newItem.ScheduleA.pop();
            setFormDataFields({
                ...newItem,
            });
        }
    }

    function getDateValue(id: number): any {
        const item = formDataFields?.ScheduleA?.find(x=> x.Id == id)?.YearAcquired;

        if(item?.length === 4) {
            return new Date(+item,2,2);
        }

        return null;
    }

    const [dateErrorsArray, setDateErrorsArray] = useState<Array<IRowState>>([]);

    function changeValidator() {
        if(formRef.current) {
            const isValid = formRef.current.checkValidity() &&
                !dateErrorsArray.some(x=>x.state === false);

            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if(!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [
        formDataFields,
        formDataFields?.ScheduleA,
        formRef.current,
        formRef?.current?.elements,
        dateErrorsArray
    ]);


    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <Label className={classes.ScheduleLabel}>Personal Property valued less than $20,000</Label>
                            <div className={classes.information}>
                                List all taxable personal property by type/category of property (See Definitions and Relevant Tax Code Sections). If needed, you may attach additional sheets OR a computer-generated copy listing the information below. If you manage or control property as a fiduciary on Jan. 1, also list the names and addresses of each property owner. “Good faith estimate of market value” or “historical cost when new” is optional for Schedule A only.
                            </div>
                            <table className="blue">
                                <tr>
                                    <th className="thw30">
                                        General Property Description by Type/Category
                                    </th>
                                    <th className="thw10">
                                        Estimate of Quantity of Each Type
                                    </th>
                                    <th className="thw10">
                                        Good Faith Estimate of Market Value
                                    </th>
                                    <th>or</th>
                                    <th className="thw10">
                                        Historical Cost When New
                                    </th>
                                    <th className="thw10">
                                        Year Acquired
                                    </th>
                                    <th className="thw30">
                                        Property Owner Name/Address<br />
                                        <div className="small">(if you manage or control property as a
                                            fiduciary)</div>
                                    </th>
                                </tr>
                                {formDataFields?.ScheduleA?.filter(x=> !x.HasActiveSubSegments).map(item => {
                                    return <tr key={item.Id}>
                                        <td>
                                            <Input
                                                className={classes.kTextbox}
                                                disabled={editDisabled}
                                                value={item.Description}
                                                onChange={e => changeValueHandler(e, "Description", item.Id!)}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                className={classes.kTextbox}
                                                disabled={editDisabled}
                                                value={item.Quantity}
                                                onChange={e => changeValueHandler(e, "Quantity", item.Id!)}
                                            />
                                        </td>
                                        <td>
                                            <NumericTextBox
                                                disabled={editDisabled}
                                                className={classes.kTextbox}
                                                value={item.MarketValue}
                                                onChange={e => changeValueHandler(e, "MarketValue", item.Id!)}
                                                format={currencyFormatOptions}
                                                spinners={false}
                                            />
                                        </td>
                                        <td></td>
                                        <td>
                                            <NumericTextBox
                                                disabled={editDisabled}
                                                className={classes.kTextbox}
                                                value={item.HistoricCost}
                                                onChange={e => changeValueHandler(e, "HistoricCost", item.Id!)}
                                                format={currencyFormatOptions}
                                                spinners={false}
                                            />
                                        </td>
                                        <td>
                                            <CustomRowDatePicker
                                                setFormDataFields={setFormDataFields}
                                                fieldName={"YearAcquired"}
                                                onlyYear={true}
                                                required={false}
                                                group={"ScheduleA"}
                                                id={item.Id!}
                                                errorsArray={dateErrorsArray}
                                                setErrorsArray={setDateErrorsArray}
                                                formDataFields={formDataFields}
                                                defaultValueDate={getDateValue(item.Id!)}
                                                disabled={editDisabled}
                                                CustomCalendar={CustomOnlyYearCalendar}
                                                rowItem={item.YearAcquired}
                                                maxDate={maxScheduleYearDate}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                className={classes.kTextbox}
                                                disabled={editDisabled}
                                                value={item.PropertyOwnerName}
                                                onChange={e => changeValueHandler(e, "PropertyOwnerName", item.Id!)}
                                            />
                                        </td>
                                    </tr>
                                })}
                            </table>
                            { !formSettings?.isReadOnly && !editDisabled &&
                            <div>
                                <button className={classes.LightButton}
                                    disabled={editDisabled}
                                    onClick={e => addRowHandler(e)}>
                                    <i className="fa fas fa-plus"></i>
                                    Add Row</button>
                                <button
                                    className={classes.LightButton}
                                    disabled={formSettings?.isReadOnly || formEntry?.initiatedBy === 'PropertySearch' || isAdminPreview}
                                    onClick={e => removeRowHandler(e)}>
                                    <i className="fa fas fa-minus"></i>
                                    Remove Row</button>
                            </div> }
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
