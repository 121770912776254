import classes from "../BPP-Form-PDF-style.module.scss";
import shared from "../../PDF-Shared-style.module.scss";
import {
  IBppFormSeventhSectionInterface,
  IScheduleA,
} from "src/interfaces/forms/BPPForm/bpp-form-seventh-section.interface";
import { GetBlankRows } from "../../BlankRows";
import { chunkArray } from "src/utils/array";

const maxRowsPerPage = 32;

export const ScheduleA = (props: {
  formDataFieldsSeventhSection?: IBppFormSeventhSectionInterface;
}) => {
  const { formDataFieldsSeventhSection } = props;

  const [primaryTable, ...additionalTables] = chunkArray(
    formDataFieldsSeventhSection?.ScheduleA || [],
    maxRowsPerPage
  );

  return (
    <>
      <div
        className={`keepTogether ${classes.ScheduleSection} ${shared.PrintTogether}`}
      >
        <div className={shared.Step}>
          SCHEDULE A: PERSONAL PROPERTY VALUED LESS THAN $20,000
        </div>
        <div className={shared.Row}>
          <div className={shared.Column12}>
            <div>
              List all taxable personal property by type/category of property
              (See Definitions and Important Information). If needed, you may
              attach additional sheets OR a computer-generated copy listing the
              information below. If you manage or control property as a
              fiduciary on Jan. 1, also list the names and addresses of each
              property owner. “Good faith estimate of market value” or
              “historical cost when new” is optional for Schedule A only.
            </div>
          </div>
          <ScheduleATable rows={primaryTable} blankRows={6} />
        </div>
      </div>

      {additionalTables.map((rows) => (
        <div
          className={`keepTogether ${classes.ScheduleSection} ${shared.PrintTogether}`}
        >
          <div className={shared.Row}>
            <ScheduleATable rows={rows} blankRows={0} />
          </div>
        </div>
      ))}
    </>
  );
};

const ScheduleATable = (props: { rows?: IScheduleA[]; blankRows: number }) => {
  const { rows, blankRows } = props;

  return (
    <div className={shared.Column12}>
      <table className={`${shared.Blue}`}>
        <tr>
          <th style={{ width: "30%" }}>
            General Property Description by Type/Category
          </th>
          <th style={{ width: "8%" }} className={classes.Or}>
            Estimate of Quantity of Each Type
          </th>
          <th style={{ width: "9%" }} className={classes.Or}>
            Good Faith Estimate of Market Value*
          </th>
          <th style={{ width: "9%" }} className={classes.And}>
            Historical Cost When New*
          </th>
          <th style={{ width: "8%" }}>Year Acquired*</th>
          <th style={{ width: "36%" }}>
            Property Owner Name/Address
            <br />
            (if you manage or control property as a fiduciary)
          </th>
        </tr>
        {rows
          ?.filter((x) => !x.HasActiveSubSegments)
          ?.map((item) => {
            return (
              <tr key={item.Id}>
                <td>{item?.Description}</td>
                <td>
                  {item?.Quantity} {/*currency */}
                </td>
                <td>
                  {item?.MarketValue} {/*currency */}
                </td>
                <td>{item?.HistoricCost}</td>
                <td>{item?.YearAcquired}</td>
                <td>{item?.PropertyOwnerName}</td>
              </tr>
            );
          })}
        {GetBlankRows(rows?.length, blankRows)}
      </table>
    </div>
  );
};
