import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import React, {useEffect, useRef, useState} from "react";
import {
    CheckboxChangeEvent, Input,
    InputChangeEvent, NumericTextBox, NumericTextBoxChangeEvent, 
    RadioGroupChangeEvent, TextArea,
    TextAreaChangeEvent
} from "@progress/kendo-react-inputs";
import {CalendarChangeEvent, DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./HomesteadExemption.module.scss";
import {Error, Label} from "@progress/kendo-react-labels";
import {UploadFileInfo} from "@progress/kendo-react-upload";
import {
    ClearFileArray} from "../../../../../context/SelectedForm/selected-form-context.helper";
import {IFormEntryModel} from "../../../../../interfaces/form-entry.interface";
import {GenericActionType} from "../../../../../context/generic-actions.type";
import {SelectedFormType} from "../../../../../context/SelectedForm/selected-form-reducer";
import {
    IHomesteadExemptionFourthSectionInterface
} from "../../../../../interfaces/forms/HomesteadExemptionForm/homestead-exemption-fourth-section.interface";
import {CheckboxGroup} from "../CustomInputs/CheckBoxes/CheckboxGroup";
import {CustomUpload} from "../CustomInputs/CustomUpload";
import {CustomDatePicker} from "../CustomInputs/CustomDatePickers/CustomDatePicker";
import {FormValidation} from "../InputValidator";
import {ZipCodeInput} from "../CustomInputs/ZipCodeInput";
import {StatesInput} from "../CustomInputs/StatesInput";
import {settingsService} from "../../../../../services/settings.service";
import { IHomesteadExemptionThirdSectionInterface } from "src/interfaces/forms/HomesteadExemptionForm/homestead-exemption-third-section.interface";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IHomesteadExemptionFourthSectionInterface) => void;
    formDataFields?:  IHomesteadExemptionFourthSectionInterface,
    formDataFieldsThirdSection?:  IHomesteadExemptionThirdSectionInterface,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void,
    formEntry?: IFormEntryModel,
    selectedFormDispatch: (value: GenericActionType<SelectedFormType>) => void,
}

export default function HomesteadExemptionFourthSection(props: GeneralFormType): JSX.Element {
    const {setFormDataFields, formDataFields, formSettings, isAdminPreview, setFormIsValid, formEntry, selectedFormDispatch, formDataFieldsThirdSection} = props;
    const settings = settingsService().settings;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const [isHeirPropertyDocumentsUploadValid, setIsHeirPropertyDocumentsUploadValid] = useState<boolean>(true);
    const [isManufacturedHomeSupportingDocumentsUploadValid, setIsManufacturedHomeSupportingDocumentsUploadValid] = useState<boolean>(true);
    const [isOtherPropertyOwnersDocumentsUploadValid, setIsOtherPropertyOwnersDocumentsUploadValid] = useState<boolean>(true);
    const [ManufacturedHomeSupportingDocumentsFiles, setManufacturedHomeSupportingDocumentsFiles] = useState<Array<UploadFileInfo>>([]);
    const [HeirPropertyDocumentsFiles, setHeirPropertyDocumentsFiles] = useState<Array<UploadFileInfo>>([]);
    const [OtherPropertyOwnersDocumentsFiles, setOtherPropertyOwnersDocumentsFiles] = useState<Array<UploadFileInfo>>([]);
    const [propertyZipCode, setPropertyZipCode] = useState<boolean>(false);
    const [propertyState, setPropertyState] = useState<boolean>(false);
    const [dateFirstOccupiedInputIsValid, setDateFirstOccupiedInputIsValid] = useState<boolean>(false);
    const [datePurchasedPropertyInputIsValid, setDatePurchasedPropertyInputIsValid] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator } = FormValidation(formRef);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={4} name={"Property Information"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent
            | NumericTextBoxChangeEvent,
        field: string,
    ) {
      if(field === "DatePurchasedProperty" ||
        field === "DateFirstOccupied"){
          setFormDataFields({
              ...formDataFields,
              [field]: event.value.toDateString()
          });
      } else {
          setFormDataFields({
              ...formDataFields,
              [field]: event.value
          });
      }
    }

    useEffect(() => {
        return () => {
            setManufacturedHomeSupportingDocumentsFiles([]);
            setHeirPropertyDocumentsFiles([]);
            setOtherPropertyOwnersDocumentsFiles([]);
        }
    }, []);

    useEffect(() => {
        if (!formDataFields?.IsManufacturedHome){
            setFormDataFields({
                ...formDataFields,
                MobileHomeMake: undefined,
                MobileHomeModel: undefined,
                MobileHomeIdNumber: undefined,
            });

            if(ManufacturedHomeSupportingDocumentsFiles.length > 0 &&
                formDataFields?.ManufacturedHomeSupportingDocuments?.length &&
                formEntry?.accessCode){
                ClearFileArray<IHomesteadExemptionFourthSectionInterface>(
                    formEntry.formId,
                    selectedFormDispatch,
                    formDataFields?.ManufacturedHomeSupportingDocuments,
                    formEntry.accessCode,
                    "ManufacturedHomeSupportingDocuments",
                    setFormDataFields,
                    formDataFields,
                    setManufacturedHomeSupportingDocumentsFiles);
            }
        }
    }, [formDataFields?.IsManufacturedHome]);

    useEffect(() => {
        if (!formDataFields?.IsIncomeProducing){
            setFormDataFields({
                ...formDataFields,
                PercentageOfPropertyThatIsIncomeProducing: undefined
            })
        }
    }, [formDataFields?.IsIncomeProducing])

    useEffect(() => {
        if (!formDataFields?.DoOtherHeirPropertyOwnersOccupy
            && OtherPropertyOwnersDocumentsFiles.length > 0
            && formDataFields?.OtherPropertyOwnersDocuments?.length
            && formEntry?.accessCode){
            ClearFileArray<IHomesteadExemptionFourthSectionInterface>(
                formEntry.formId,
                selectedFormDispatch,
                formDataFields?.OtherPropertyOwnersDocuments,
                formEntry.accessCode,
                "OtherPropertyOwnersDocuments",
                setFormDataFields,
                formDataFields,
                setOtherPropertyOwnersDocumentsFiles);
        }
    }, [formDataFields?.DoOtherHeirPropertyOwnersOccupy]);

    useEffect(() => {
        if ((formDataFields?.IsApplicantOnDeed || formDataFields?.IsApplicantOnDeed === undefined)
            && HeirPropertyDocumentsFiles.length > 0
            && formDataFields?.HeirPropertyDocuments?.length
            && formEntry?.accessCode){
            ClearFileArray<IHomesteadExemptionFourthSectionInterface>(
                formEntry.formId,
                selectedFormDispatch,
                formDataFields?.HeirPropertyDocuments,
                formEntry.accessCode,
                "HeirPropertyDocuments",
                setFormDataFields,
                formDataFields,
                setHeirPropertyDocumentsFiles);
        } else if(!formDataFields?.IsApplicantOnDeed){
            setFormDataFields({
                ...formDataFields,
                CourtRecordorFilingNumber: undefined
            })
        }
    }, [formDataFields?.IsApplicantOnDeed])

    function changeValidator() {
        if (formRef.current) {
            const isValid = isHeirPropertyDocumentsUploadValid &&
                isOtherPropertyOwnersDocumentsUploadValid &&
                isManufacturedHomeSupportingDocumentsUploadValid &&
                dateFirstOccupiedInputIsValid &&
                datePurchasedPropertyInputIsValid &&
                propertyZipCode &&
                propertyState &&
                formRef.current.checkValidity();
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch() {
        if (!isFormTouched) {
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields,
        formRef.current,
        isHeirPropertyDocumentsUploadValid,
        isOtherPropertyOwnersDocumentsUploadValid,
        isManufacturedHomeSupportingDocumentsUploadValid,
        datePurchasedPropertyInputIsValid,
        dateFirstOccupiedInputIsValid,
        propertyZipCode,
        propertyState
    ]);

    const saveAsApplicantsMailingAddressHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        setFormDataFields({
            ...formDataFields,
            PropertyAddressLine1: formDataFieldsThirdSection?.ApplicantAddressLine1,
            PropertyAddressLine2: formDataFieldsThirdSection?.ApplicantAddressLine2,
            PropertyAddressCity: formDataFieldsThirdSection?.ApplicantAddressCity,
            PropertyAddressState: formDataFieldsThirdSection?.ApplicantAddressState,
            PropertyAddressZip: formDataFieldsThirdSection?.ApplicantAddressZip,
        });
    }

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroupW49}>
                                    <Label className={classes.Label}>
                                        Date you acquired this property
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    </Label>
                                    <CustomDatePicker
                                        fieldName={"DatePurchasedProperty"}
                                        required={true}
                                        disabled={formSettings?.isReadOnly}
                                        formDataFields={formDataFields}
                                        setFormDataFields={setFormDataFields}
                                        onlyYear={false}
                                        setIsValidField={setDatePurchasedPropertyInputIsValid}
                                        validField={datePurchasedPropertyInputIsValid}
                                        defaultValueDate={formDataFields?.DatePurchasedProperty}
                                    />
                                </div>
                                <div className={classes.fieldsGroupW49}>
                                    <Label className={classes.Label}>
                                        Date when you began occupying the property as your principal residence.
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    </Label>
                                    <CustomDatePicker
                                        fieldName={"DateFirstOccupied"}
                                        required={true}
                                        disabled={formSettings?.isReadOnly}
                                        formDataFields={formDataFields}
                                        setFormDataFields={setFormDataFields}
                                        onlyYear={false}
                                        setIsValidField={setDateFirstOccupiedInputIsValid}
                                        validField={dateFirstOccupiedInputIsValid}
                                        defaultValueDate={formDataFields?.DateFirstOccupied}
                                    />
                                </div>
                            </div>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroupW49}>
                                    <Label className={classes.Label}>
                                        Property Id (If you do not know your Property ID, please put N/A) 
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    </Label>
                                    <Input
                                        name={"PropertyId"}
                                        required={true}
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.kTextbox}
                                        value={formDataFields?.PropertyId}
                                        onChange={e => changeValueHandler(e, "PropertyId")}
                                    />
                                    {!inputValidator("PropertyId") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={classes.fieldsGroupW49}>
                                    <Label className={classes.Label}>
                                        Number of Acres Own and Occupy as Principal Residence
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    </Label>
                                    <NumericTextBox
                                        name={"PropertyNumberOfAcres"}
                                        required={true}
                                        disabled={formSettings?.isReadOnly}
                                        className={!inputValidator("PropertyNumberOfAcres") ? classes.invalidState + " " + classes.kTextbox : classes.kTextbox}
                                        value={formDataFields?.PropertyNumberOfAcres}
                                        onChange={e => changeValueHandler(e, "PropertyNumberOfAcres")}
                                    />
                                    {!inputValidator("PropertyNumberOfAcres") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={classes.fieldsGroupW100}>
                                    <Label className={classes.Label}>
                                        Legal Description
                                    </Label>
                                    <TextArea
                                        name={"PropertyLegalDescription"}
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.kTextbox}
                                        value={formDataFields?.PropertyLegalDescription}
                                        onChange={e => changeValueHandler(e, "PropertyLegalDescription")}
                                    />
                                    {!inputValidator("PropertyLegalDescription") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                            </div>
                            <div className={classes.itemFields}>
                                <div className={`${classes.itemTitle} ${classes.addressTitle} ${classes.fieldsGroupW100}`}>
                                    <p>Property's Physical Address</p>
                                    { !formSettings?.isReadOnly && !isAdminPreview &&
                                        <button className={`${classes.button} ${classes.buttonAddress}`} 
                                            onClick={e => saveAsApplicantsMailingAddressHandler(e)}>
                                            Same As Applicants's Mailing Address
                                        </button> }
                                </div>
                                <div className={classes.fieldsGroupW100}>
                                    <Label className={classes.Label}>
                                        Address Line 1
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    </Label>
                                    <Input
                                        name={"PropertyAddressLine1"}
                                        required={true}
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.kTextbox}
                                        value={formDataFields?.["PropertyAddressLine1"]}
                                        onChange={e => changeValueHandler(e, "PropertyAddressLine1")}
                                    />
                                    {!inputValidator("PropertyAddressLine1") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={classes.fieldsGroupW100}>
                                    <Label className={classes.Label}>
                                        Address Line 2
                                    </Label>
                                    <Input
                                        name={"PropertyAddressLine2"}
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.kTextbox}
                                        value={formDataFields?.["PropertyAddressLine2"]}
                                        onChange={e => changeValueHandler(e, "PropertyAddressLine2")}
                                    />
                                    {!inputValidator("PropertyAddressLine2") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={classes.fieldsGroupW30}>
                                    <Label className={classes.Label}>
                                        City
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    </Label>
                                    <Input
                                        required={true}
                                        name={"PropertyAddressCity"}
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.kTextbox}
                                        value={formDataFields?.["PropertyAddressCity"]}
                                        onChange={e => changeValueHandler(e, "PropertyAddressCity")}
                                    />
                                    {!inputValidator("PropertyAddressCity") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={classes.fieldsGroupW30}>
                                    <Label className={classes.Label}>
                                        State
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    </Label>
                                    <StatesInput
                                        setFormDataFields={setFormDataFields}
                                        fieldName={"PropertyAddressState"}
                                        required={true}
                                        formDataFields={formDataFields}
                                        isReadOnly={formSettings?.isReadOnly}
                                        setIsValidField={setPropertyState}
                                        validField={propertyState}
                                    />
                                    {!inputValidator("PropertyAddressState") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={classes.fieldsGroupW30}>
                                    <Label className={classes.Label}>
                                        Zip
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    </Label>
                                    <ZipCodeInput
                                        setFormDataFields={setFormDataFields}
                                        fieldName={"PropertyAddressZip"}
                                        required={true}
                                        formDataFields={formDataFields}
                                        isReadOnly={formSettings?.isReadOnly}
                                        setIsValidField={setPropertyZipCode}
                                        validField={propertyZipCode}
                                    />
                                    {!inputValidator("PropertyAddressZip") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                            </div>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroupW100 + " " + classes.flexGroup}>
                                    <Label className={classes.Label}>
                                        Is the applicant identified on deed or other recorded instrument?
                                    </Label>
                                    <span>
                                    <CheckboxGroup
                                        isAdminPreview={isAdminPreview}
                                        setFormDataFields={setFormDataFields}
                                        formDataFields={formDataFields}
                                        formSettings={formSettings}
                                        fieldName={"IsApplicantOnDeed"}
                                        fieldSecondName={"IsApplicantOnDeedSecond"}
                                        isRequired={false}
                                    />
                                    </span>
                                    {!inputValidator("IsApplicantOnDeed") && !inputValidator("IsApplicantOnDeedSecond") && (
                                        <Error className={classes.w100}>{validationMessage}</Error>
                                    )}
                                </div>
                                { formDataFields?.["IsApplicantOnDeed"] &&
                                <div className={classes.fieldsGroupW100}>
                                    <Label className={classes.Label}>
                                        Court Record/Filing number on recorded deed or other recorded instrument.
                                    </Label>
                                    <Input
                                        name={"CourtRecordorFilingNumber"}
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.kTextbox}
                                        value={formDataFields?.["CourtRecordorFilingNumber"]}
                                        onChange={e => changeValueHandler(e, "CourtRecordorFilingNumber")}
                                    />
                                    {!inputValidator("CourtRecordorFilingNumber") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div> }
                                { formDataFields?.["IsApplicantOnDeed"] === false &&
                                    <div className={classes.fieldsGroupW100}>
                                        <div className={classes.information}>An heir property
                                            owner not specifically identified as the residence homestead owner on a deed
                                            or other
                                            recorded instrument in the county where the property is located must
                                            provide:
                                            <ul className="ng-scope">
                                                <li>an affidavit establishing ownership of interest in the property (See
                                                    Form
                                                    50-114-A)
                                                </li>
                                                <li>a copy of the prior property owner’s death certificate;</li>
                                                <li>a copy of the property’s most recent utility bill; and</li>
                                                <li>A citation of any court record relating to the applicant’s ownership
                                                    of
                                                    the property, if available.
                                                </li>
                                            </ul>
                                        </div>
                                        <CustomUpload
                                            formEntry={formEntry}
                                            formSettings={formSettings}
                                            isAdminPreview={isAdminPreview}
                                            setFormDataFields={setFormDataFields}
                                            formDataFields={formDataFields}
                                            fieldName={"HeirPropertyDocuments"}
                                            files={HeirPropertyDocumentsFiles}
                                            isRequired={formDataFields?.["IsApplicantOnDeed"] === false}
                                            uploadIsValid={isHeirPropertyDocumentsUploadValid}
                                            setUploadIsValid={setIsHeirPropertyDocumentsUploadValid}
                                            selectedFormDispatch={selectedFormDispatch}
                                            setFiles={setHeirPropertyDocumentsFiles}
                                            multiple={true}
                                            isLabelRequired={true}
                                            labelText={"Upload Required Documents"}
                                        />
                                    </div>
                                }
                            </div>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroupW100 + " " + classes.flexGroup}>
                                    <Label className={classes.Label}>
                                        Is the property for which this application is submitted an heir property?
                                    </Label>
                                    <span>
                                     <CheckboxGroup
                                         isAdminPreview={isAdminPreview}
                                         setFormDataFields={setFormDataFields}
                                         formDataFields={formDataFields}
                                         formSettings={formSettings}
                                         fieldName={"IsAnHeirProperty"}
                                         fieldSecondName={"IsAnHeirPropertySecond"}
                                         isRequired={false}
                                     />
                                    </span>
                                </div>
                                { formDataFields?.["IsAnHeirProperty"] &&
                                    <div className={classes.fieldsGroupW100}>
                                        <div className={classes.information}>Heir property is
                                            property owned by one or more individuals, where at least
                                            one owner claims the property as a residence homestead, and the property was
                                            acquired by will, transfer on death deed, or intestacy.
                                        </div>
                                    </div> }
                            </div>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroupW100 + " " + classes.flexGroup}>
                                    <Label className={classes.Label}>
                                        Do other heir property owners occupy the property?
                                    </Label>
                                    <span>
                                     <CheckboxGroup
                                         isAdminPreview={isAdminPreview}
                                         setFormDataFields={setFormDataFields}
                                         formDataFields={formDataFields}
                                         formSettings={formSettings}
                                         fieldName={"DoOtherHeirPropertyOwnersOccupy"}
                                         fieldSecondName={"DoOtherHeirPropertyOwnersOccupySecond"}
                                         isRequired={false}
                                         firstCheckboxLabel={"Yes (affidavits required)"}
                                     />
                                    </span>
                                    { formDataFields?.["DoOtherHeirPropertyOwnersOccupy"] &&
                                    <div className={classes.fieldsGroupW100}>
                                        <Label className={`${classes.addressTitle}`}>
                                            Affidavit establishing ownership of interest in the property and other required documents.
                                        </Label>
                                        <CustomUpload
                                            formEntry={formEntry}
                                            formSettings={formSettings}
                                            isAdminPreview={isAdminPreview}
                                            setFormDataFields={setFormDataFields}
                                            formDataFields={formDataFields}
                                            fieldName={"OtherPropertyOwnersDocuments"}
                                            files={OtherPropertyOwnersDocumentsFiles}
                                            isRequired={formDataFields?.["DoOtherHeirPropertyOwnersOccupy"]}
                                            uploadIsValid={isOtherPropertyOwnersDocumentsUploadValid}
                                            setUploadIsValid={setIsOtherPropertyOwnersDocumentsUploadValid}
                                            selectedFormDispatch={selectedFormDispatch}
                                            setFiles={setOtherPropertyOwnersDocumentsFiles}
                                            multiple={true}
                                            isLabelRequired={true}
                                            labelText={"Please add affidavit establishing ownership of interest in the property and other required documents."}
                                        />
                                    </div> }
                                </div>
                            </div>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroupW100 + " " + classes.flexGroup}>
                                    <Label className={classes.Label}>
                                        Is any portion of the property for which you are claiming a residence homestead exemption income producing?
                                    </Label>
                                    <span>
                                     <CheckboxGroup
                                         isAdminPreview={isAdminPreview}
                                         setFormDataFields={setFormDataFields}
                                         formDataFields={formDataFields}
                                         formSettings={formSettings}
                                         fieldName={"IsIncomeProducing"}
                                         fieldSecondName={"IsIncomeProducingSecond"}
                                         isRequired={false}
                                     />
                                    </span>
                                </div>
                                { formDataFields?.["IsIncomeProducing"] && <>
                                    <Label className={classes.Label}>
                                        % of Property that is Income Producing
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    </Label>
                                    <div className={classes.fieldsGroupW100}>
                                            <Input
                                                required={formDataFields?.["IsIncomeProducing"]}
                                                name={"PercentageOfPropertyThatIsIncomeProducing"}
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.kTextbox}
                                                value={formDataFields?.["PercentageOfPropertyThatIsIncomeProducing"]}
                                                onChange={e => changeValueHandler(e, "PercentageOfPropertyThatIsIncomeProducing")}
                                            />
                                            {!inputValidator("PercentageOfPropertyThatIsIncomeProducing") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                    </div> </>}
                            </div>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroupW100 + " " + classes.flexGroup}>
                                    <Label className={classes.Label}>
                                        Is this property a manufactured home?
                                    </Label>
                                    <span>
                                     <CheckboxGroup
                                         isAdminPreview={isAdminPreview}
                                         setFormDataFields={setFormDataFields}
                                         formDataFields={formDataFields}
                                         formSettings={formSettings}
                                         fieldName={"IsManufacturedHome"}
                                         fieldSecondName={"IsManufacturedHomeSecond"}
                                         isRequired={false}
                                     />
                                    </span>
                                </div>
                                { formDataFields?.["IsManufacturedHome"] &&
                                    <>
                                        { ((settings.isHarrisClient && formSettings?.showMobileHomeMakeField) || !settings.isHarrisClient) &&
                                            <div className={classes.fieldsGroupW30}>
                                                <Label className={classes.Label}>
                                                    Make
                                                    <i className={"fa fa-asterisk text-danger"}></i>
                                                </Label>
                                                <Input
                                                    name={"MobileHomeMake"}
                                                    required={formDataFields?.IsManufacturedHome}
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.kTextbox}
                                                    value={formDataFields?.MobileHomeMake}
                                                    onChange={e => changeValueHandler(e, "MobileHomeMake")}
                                                />
                                                {!inputValidator("MobileHomeMake") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                        }
                                        { ((settings.isHarrisClient && formSettings?.showMobileHomeModelField) || !settings.isHarrisClient) &&
                                            <div className={classes.fieldsGroupW30}>
                                                <Label className={classes.Label}>
                                                    Model
                                                    <i className={"fa fa-asterisk text-danger"}></i>
                                                </Label>
                                                <Input
                                                    name={"MobileHomeModel"}
                                                    required={formDataFields?.IsManufacturedHome}
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.kTextbox}
                                                    value={formDataFields?.MobileHomeModel}
                                                    onChange={e => changeValueHandler(e, "MobileHomeModel")}
                                                />
                                                {!inputValidator("MobileHomeModel") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                        }
                                        { ((settings.isHarrisClient && formSettings?.showMobileHomeIdNumberField) || !settings.isHarrisClient) &&
                                            <div className={classes.fieldsGroupW30}>
                                                <Label className={classes.Label}>
                                                    Id Number
                                                    <i className={"fa fa-asterisk text-danger"}></i>
                                                </Label>
                                                <Input
                                                    name={"MobileHomeIdNumber"}
                                                    required={formDataFields?.IsManufacturedHome}
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.kTextbox}
                                                    value={formDataFields?.MobileHomeIdNumber}
                                                    onChange={e => changeValueHandler(e, "MobileHomeIdNumber")}
                                                />
                                                {!inputValidator("MobileHomeIdNumber") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                        }
                                        <div className={classes.fieldsGroupW100}>
                                            <div className={classes.information}>For a
                                                manufactured home to qualify for a residence homestead, applicant
                                                must provide:
                                                <ul className="ng-scope">
                                                    <li>A copy of the statement of ownership and location for the
                                                        manufactured home issued by the Texas Department of Housing
                                                        and Community Affairs showing that the applicant is the
                                                        owner of the manufactured home.
                                                    </li>
                                                    <li>A copy of the purchase contract or payment receipt showing
                                                        that the applicant is the purchaser of the manufactured
                                                        home.
                                                    </li>
                                                    <li>Or a sworn affidavit (see last page) by the applicant
                                                        indicating that:
                                                        <ul>
                                                            <li>The applicant is the owner of the manufactured
                                                                home.
                                                            </li>
                                                            <li>The seller of the manufactured home did not provide
                                                                the applicant with a purchase contract.
                                                            </li>
                                                            <li>And the applicant could not locate the seller after
                                                                making a good faith effort.
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className={classes.fieldsGroupW100}>
                                            <CustomUpload
                                                formEntry={formEntry}
                                                formSettings={formSettings}
                                                isAdminPreview={isAdminPreview}
                                                setFormDataFields={setFormDataFields}
                                                formDataFields={formDataFields}
                                                fieldName={"ManufacturedHomeSupportingDocuments"}
                                                files={ManufacturedHomeSupportingDocumentsFiles}
                                                isRequired={formDataFields?.["IsManufacturedHome"]}
                                                uploadIsValid={isManufacturedHomeSupportingDocumentsUploadValid}
                                                setUploadIsValid={setIsManufacturedHomeSupportingDocumentsUploadValid}
                                                selectedFormDispatch={selectedFormDispatch}
                                                setFiles={setManufacturedHomeSupportingDocumentsFiles}
                                                multiple={true}
                                                isLabelRequired={true}
                                                labelText={"Required Mobile Home Documentation"}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
