import {useParams} from "react-router-dom";
import TimberlandAppraisalForm from "../Forms/TimberlandAppraisalForm/TimberlandAppraisalForm";

interface FormEntry {
    formEntryId?: number;
    isAdminPreview?: boolean;
    formId?: number;
}
export default function TimberlandAppraisalFormEntry(props: FormEntry): JSX.Element {
    const { isAdminPreview, formEntryId, formId } = props;
    const { formEntryIdParams } = useParams();

    return <>
        <TimberlandAppraisalForm
            formId={formId}
            isAdminPreview={isAdminPreview}
            formEntryId={formEntryIdParams ? parseInt(formEntryIdParams) : formEntryId} />
    </>
}