import {Checkbox, CheckboxChangeEvent} from "@progress/kendo-react-inputs";
import {IFormSettingModel} from "../../../../../../interfaces/form-settings.interface";
import {useEffect, useState} from "react";
import classes from "./CheckboxGroup.module.scss";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: any) => void;
    formDataFields:  any,
    formSettings?: IFormSettingModel,
    fieldName: string,
    fieldSecondName: string,
    isRequired: boolean,
    checkboxesIsValid?: boolean,
    setCheckboxesIsValid?: (value: boolean) => void,
    firstCheckboxLabel?: string,
    secondCheckboxLabel?: string,
}


export function CheckboxGroup(props: GeneralFormType) : JSX.Element{
    const {
        setFormDataFields,
        formDataFields,
        formSettings,
        isAdminPreview,
        fieldName,
        fieldSecondName,
        isRequired,
        checkboxesIsValid,
        setCheckboxesIsValid,
        firstCheckboxLabel,
        secondCheckboxLabel} = props;

    function changeCheckboxGroupValueHandler(
        event: CheckboxChangeEvent,
        defaultValue: boolean,
    ) {
        if (event.value) {
            setFormDataFields({
                ...formDataFields,
                [fieldName]: defaultValue
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [fieldName]: undefined
            });
        }
    }

    useEffect(() => {
        return () => {
            if (setCheckboxesIsValid) {
                setCheckboxesIsValid(true);
            }
        }
    }, []);

    const [firstCheckbox, setFirstCheckbox] = useState<boolean>(false);
    const [secondCheckbox, setSecondCheckbox] = useState<boolean>(false);

    useEffect(() => {
        if (formDataFields?.[fieldName] != undefined && formDataFields?.[fieldName] == true){
            setFirstCheckbox(true);
            setSecondCheckbox(false);
        } else if (formDataFields?.[fieldName] != undefined && formDataFields?.[fieldName] == false){
            setFirstCheckbox(false);
            setSecondCheckbox(true);
        } else {
            setFirstCheckbox(false);
            setSecondCheckbox(false);
        }
    }, [formDataFields?.[fieldName]]);

    useEffect(() => {
        if(!isRequired && setCheckboxesIsValid){
            setCheckboxesIsValid(true);
        } else if ((firstCheckbox || secondCheckbox) && setCheckboxesIsValid ){
            setCheckboxesIsValid(true);
        } else if(setCheckboxesIsValid) {
            setCheckboxesIsValid(false);
        }
    }, [firstCheckbox, secondCheckbox]);


    return <>
        <div className={classes.items}>
            <Checkbox
                className={classes.checkBox}
                required={isRequired && !checkboxesIsValid}
                label={firstCheckboxLabel ? firstCheckboxLabel : "Yes"}
                name={fieldName}
                disabled={formSettings?.isReadOnly}
                value={firstCheckbox}
                onChange={e => changeCheckboxGroupValueHandler(e, true)}
            />
            <Checkbox
                className={classes.checkBox}
                required={isRequired && !checkboxesIsValid}
                label={secondCheckboxLabel ? secondCheckboxLabel : "No"}
                name={fieldSecondName}
                disabled={formSettings?.isReadOnly}
                value={secondCheckbox}
                onChange={e => changeCheckboxGroupValueHandler(e, false)}
            />
        </div>
    </>
}
