import classes from "./WildlifeManagement.module.scss";
import { useSelectedFormContext } from "../../../../../context/SelectedForm/selected-form-context";
import { useEffect, useState, useMemo } from "react";
import {
  getHarrisSelectedFormDataWithPrefilledDataHelper,
  getSelectedFormDataHelper,
  getSelectedFormEntryDataHelper,
  saveFormHelper,
} from "../../../../../context/SelectedForm/selected-form-context.helper";
import {
  IFormEntryModel,
} from "../../../../../interfaces/form-entry.interface";
import {
  IFormFiledInterface,
} from "../../../../../interfaces/form-filed.interface";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  IFormSettingModel,
} from "../../../../../interfaces/form-settings.interface";
import { Dialog } from "@progress/kendo-react-dialogs";
import { SelectedFormActionsEnum } from "../../../../../context/SelectedForm/selected-form-actions";
import { useFormContext } from "../../../../../context/Form/form-context";
import {
  onLoadForms
} from "../../../../../context/Form/form-helper";
import { CustomLoader } from "../../../../../components";
import { FormActionsEnum } from "../../../../../context/Form/form-actions";
import { useCallbackPrompt } from "../../../../../hooks/useCallbackPrompt";
import UnsavedFormDialog from "../../../../../components/CustomDialogs/UnsavedFormDialog/UnsavedFormDialog";
import { t } from "i18next";
import AuthService from 'src/services/authentication.service';
import { useFormSectionDataFields } from "../useFormSectionDataFields";
import { WildlifeManagementFormName } from "src/constants/form-names-constants";
import { IWildlifeManagementFifthSection, WildlifeManagementFifthSectionInterface } from "src/interfaces/forms/WildlifeManagement/wildlife-management-fifth-section.interface";
import { IWildlifeManagementFirstSection, WildlifeManagementFirstSectionInterface } from "src/interfaces/forms/WildlifeManagement/wildlife-management-first-section.interface";
import { IWildlifeManagementSecondSection, WildlifeManagementSecondSectionInterface } from "src/interfaces/forms/WildlifeManagement/wildlife-management-second-section.interface";
import { IWildlifeManagementSixthSection, WildlifeManagementSixthSectionInterface } from "src/interfaces/forms/WildlifeManagement/wildlife-management-sixth-section.interface";
import { IWildlifeManagementThirdSection, WildlifeManagementThirdSectionInterface } from "src/interfaces/forms/WildlifeManagement/wildlife-management-third-section.interface";
import WildlifeManagementFifthSection from "./WildlifeManagementFifthSection";
import WildlifeManagementFirstSection from "./WildlifeManagementFirstSection";
import WildlifeManagementFourthSection from "./WildlifeManagementFourthSection";
import WildlifeManagementSecondSection from "./WildlifeManagementSecondSection";
import WildlifeManagementSixthSection from "./WildlifeManagementSixthSection";
import WildlifeManagementThirdSection from "./WildlifeManagementThirdSection";
import { IWildlifeManagementFourthSectionHabitatControl, WildlifeManagementFourthSectionHabitatControlInterface } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-habitat-control.interface";
import { IWildlifeManagementFourthSectionSupplementalWater, WildlifeManagementFourthSectionSupplementalWaterInterface } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-supplemental-water.interface";
import { IWildlifeManagementFourthSectionSupplementalFood, WildlifeManagementFourthSectionSupplementalFoodInterface } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-providing-supplemental-food.interface";
import { IWildlifeManagementFourthSectionSupplementalShelter, WildlifeManagementFourthSectionSupplementalShelterInterface } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-providing-supplemental-shelter.interface";
import { IWildlifeManagementFourthSectionCensus, WildlifeManagementFourthSectionCensusInterface } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-census.interface";
import { IWildlifeManagementFourthSectionErosionControl, WildlifeManagementFourthSectionErosionControlInterface } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-erosion-control.interface";
import { IWildlifeManagementFourthSectionPredatorControl, WildlifeManagementFourthSectionPredatorControlInterface } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-predator-control.interface";
import WildlifeManagementPDFView from "src/pages/Shared/WildlifeManagementPDFView/WildlifeManagementPDFView";
import { globalConfig } from "src/configuration/config";
import { initiatedByPropertySearch, initiatedByOnlineForms } from "src/constants/form-entry-constants";
import { settingsService } from "src/services/settings.service";
import { FormHeader } from "../Shared/FormHeader/FormHeader";
import { submitted } from "src/constants/submission-form-status-constants";
import { useIsFormActive } from "../useIsFormActive";

interface FormEntry {
  formEntryId?: number;
  isAdminPreview?: boolean;
  formId?: number;
}

export default function WildlifeManagementForm(props: FormEntry): JSX.Element {
    const { isAuthorized } = AuthService.getInstance();
    const settings = settingsService().settings;

    const { isAdminPreview, formEntryId, formId } = props;
    const { formState, formDispatch } = useFormContext();
    const [searchParams] = useSearchParams();
    const { selectedFormDispatch, selectedFormState } = useSelectedFormContext();
    const { loading } = formState;
    const { isLoading } = selectedFormState;
    const [formDataFields, setFormDataFields] = useState<Array<IFormFiledInterface>>();
    const [formEntry, setFormEntry] = useState<IFormEntryModel>();
    const formSettings = useMemo(() => {
      let formSettings = selectedFormState.formSettings ?? undefined;
      if (
          !isAuthorized ||
          isAdminPreview ||
          selectedFormState.formEntry?.submissionStatus == submitted
       ) {
        formSettings = { ...formSettings, isReadOnly: true };
       }
       return formSettings;
    }, [selectedFormState.formSettings, selectedFormState.formEntry, isAuthorized, isAdminPreview]);

    const [formFirstSectionDataFields, setFormFirstSectionDataFields] = useState<IWildlifeManagementFirstSection>();
    const [formSecondSectionDataFields, setFormSecondSectionDataFields] = useState<IWildlifeManagementSecondSection>();
    const [formThirdSectionDataFields, setFormThirdSectionDataFields] = useState<IWildlifeManagementThirdSection>();
    const [formFourthSectionHabitatControlDataFields, setFormFourthSectionHabitatControlDataFields] =
      useState<IWildlifeManagementFourthSectionHabitatControl>();
    const [formFourthSectionErosionControlDataFields, setFormFourthSectionErosionControlDataFields] =
      useState<IWildlifeManagementFourthSectionErosionControl>();
    const [formFourthSectionPredatorControlDataFields, setFormFourthSectionPredatorControlDataFields] =
      useState<IWildlifeManagementFourthSectionPredatorControl>();
    const [formFourthSectionSupplementalWaterDataFields, setFormFourthSectionSupplementalWaterDataFields] =
    useState<IWildlifeManagementFourthSectionSupplementalWater>();
    const [formFourthSectionSupplementalFoodDataFields, setFormFourthSectionSupplementalFoodDataFields] =
      useState<IWildlifeManagementFourthSectionSupplementalFood>();
    const [formFourthSectionSupplementalShelterDataFields, setFormFourthSectionSupplementalShelterDataFields] =
      useState<IWildlifeManagementFourthSectionSupplementalShelter>();
    const [formFourthSectionCensusDataFields, setFormFourthSectionCensusDataFields] =
      useState<IWildlifeManagementFourthSectionCensus>();
    const [formFifthSectionDataFields, setFormFifthSectionDataFields] = useState<IWildlifeManagementFifthSection>();
    const [formSixthSectionDataFields, setFormSixthSectionDataFields] = useState<IWildlifeManagementSixthSection>();

    const [firstSectionIsValid, setFirstSectionIsValid] = useState<boolean>(false);
    const [secondSectionIsValid, setSecondSectionIsValid] = useState<boolean>(false);
    const [thirdSectionIsValid, setThirdSectionIsValid] = useState<boolean>(false);
    const [fourthSectionHabitatControlIsValid, setFourthSectionHabitatControlIsValid] = useState<boolean>(false);
    const [fourthSectionErosionControlIsValid, setFourthSectionErosionControlIsValid] = useState<boolean>(false);
    const [fourthSectionPredatorControlIsValid, setFourthSectionPredatorControlIsValid] = useState<boolean>(false);
    const [fourthSectionSupplementalWaterIsValid, setFourthSectionSupplementalWaterIsValid] = useState<boolean>(false);
    const [fourthSectionSupplementalFoodIsValid, setFourthSectionSupplementalFoodIsValid] = useState<boolean>(false);
    const [fourthSectionSupplementalShelterIsValid, setFourthSectionSupplementalShelterIsValid] = useState<boolean>(false);
    const [fourthSectionCensusIsValid, setFourthSectionCensusIsValid] = useState<boolean>(false);
    const [fifthSectionIsValid, setFifthSectionIsValid] = useState<boolean>(false);
    const [sixthSectionIsValid, setSixthSectionIsValid] = useState<boolean>(false);

    const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const { isFormActive } = useIsFormActive(
    isAuthorized,
    isAdminPreview,
    formSettings,
    "AdditionalDocuments",
    selectedFormState.formFields,
    formFifthSectionDataFields?.AdditionalDocuments
  );

  const [showPrompt, confirmNavigation, cancelNavigation, setDisablePrompt] = useCallbackPrompt(isFormActive);

    useEffect(() => {
      setIsFormValid(
        firstSectionIsValid &&
        secondSectionIsValid &&
        thirdSectionIsValid &&
        fourthSectionHabitatControlIsValid &&
        fourthSectionErosionControlIsValid &&
        fourthSectionPredatorControlIsValid &&
        fourthSectionSupplementalWaterIsValid &&
        fourthSectionSupplementalFoodIsValid &&
        fourthSectionSupplementalShelterIsValid &&
        fourthSectionCensusIsValid &&
        fifthSectionIsValid &&
        sixthSectionIsValid)
    }, [
      firstSectionIsValid,
      secondSectionIsValid,
      thirdSectionIsValid,
      fourthSectionHabitatControlIsValid,
      fourthSectionErosionControlIsValid,
      fourthSectionPredatorControlIsValid,
      fourthSectionSupplementalWaterIsValid,
      fourthSectionSupplementalFoodIsValid,
      fourthSectionSupplementalShelterIsValid,
      fourthSectionCensusIsValid,
      fifthSectionIsValid,
      sixthSectionIsValid])

    useEffect(() => {
      return () => {
        selectedFormDispatch({
          type: SelectedFormActionsEnum.CLEAR,
          payload: {
            errorMessage: undefined,
            isLoading: undefined,
            formFields: undefined,
            successMessage: undefined,
            form: undefined,
            formSettings: undefined,
            formEntry: undefined,
            status: SelectedFormActionsEnum.CLEAR
          },
        });
      }
    }, []);

    useEffect(() => {
      if (!formState.forms.length) {
        onLoadForms(formDispatch);
      }
    }, []);

    useEffect(() => {
      const stateFormId = formState?.forms?.find(x => x.name == WildlifeManagementFormName)?.id ?? formId;

      if (stateFormId && isAuthorized) {
        if (formEntryId) {
          getSelectedFormEntryDataHelper(selectedFormDispatch, stateFormId, formEntryId);
        } else if (settings.isHarrisClient && isAuthorized){
          GetFormDataWithSettingsForHarrisClient(stateFormId);
        } else {
          getSelectedFormDataHelper(selectedFormDispatch, stateFormId);
        }
      }
    }, [formState.forms]);


    function GetFormDataWithSettingsForHarrisClient(stateFormId: number){
      const propertySearchRoot = globalConfig.get().propertySearchRoot;
      const referrer = sessionStorage.getItem('referrer');

      if (referrer?.includes(propertySearchRoot)) {
        sessionStorage.setItem('referrer', referrer);

        const propertyId = searchParams.get("propertyId");
        const yearString = searchParams.get("year");
        const ownerId = searchParams.get("ownerId");

        if (stateFormId && propertyId && yearString && ownerId) {
          getHarrisSelectedFormDataWithPrefilledDataHelper(
              selectedFormDispatch,
              stateFormId,
              WildlifeManagementFormName,
              propertyId,
              parseInt(yearString),
              ownerId,
              initiatedByPropertySearch,
              navigate);
        } else {
          getSelectedFormDataHelper(selectedFormDispatch, stateFormId, initiatedByPropertySearch);
          //TODO: throw error.
        }
      } else {
        getSelectedFormDataHelper(selectedFormDispatch, stateFormId, initiatedByOnlineForms);
      }
    }

    useEffect(() => {
      if (selectedFormState?.formEntry) {
        if (selectedFormState.form?.name) {
          setFormEntry({
            ...selectedFormState?.formEntry,
            formName: selectedFormState.form?.name
          });
        } else {
          setFormEntry(selectedFormState.formEntry)
        }
      }
    }, [selectedFormState.formEntry, selectedFormState.form]);

    function ClearData() {
      setFormDataFields([]);
      setFormEntry(undefined);
      setFormFirstSectionDataFields({});
      setFormSecondSectionDataFields({});
      setFormThirdSectionDataFields({});
      setFormFourthSectionHabitatControlDataFields({});
      setFormFourthSectionErosionControlDataFields({});
      setFormFourthSectionPredatorControlDataFields({});
      setFormFourthSectionSupplementalWaterDataFields({});
      setFormFourthSectionSupplementalFoodDataFields({});
      setFormFourthSectionSupplementalShelterDataFields({});
      setFormFourthSectionCensusDataFields({});
      setFormFifthSectionDataFields({});
      setFormSixthSectionDataFields({});
      setFirstSectionIsValid(false);
      setSecondSectionIsValid(false);
      setThirdSectionIsValid(false);
      setFourthSectionHabitatControlIsValid(false);
      setFourthSectionErosionControlIsValid(false);
      setFourthSectionPredatorControlIsValid(false);
      setFourthSectionSupplementalWaterIsValid(false);
      setFourthSectionSupplementalFoodIsValid(false);
      setFourthSectionSupplementalShelterIsValid(false);
      setFourthSectionCensusIsValid(false);
      setFifthSectionIsValid(false);
      setSixthSectionIsValid(false);

      setDisablePrompt(false);
    }

    useEffect(() => {
      if (selectedFormState.status === SelectedFormActionsEnum.CLEAR) {
        ClearData();
        return;
      }

      setFormDataFields(selectedFormState.formFields);
      const FirstSection: IWildlifeManagementFirstSection = WildlifeManagementFirstSectionInterface();
      const SecondSection: IWildlifeManagementSecondSection = WildlifeManagementSecondSectionInterface();
      const ThirdSection: IWildlifeManagementThirdSection = WildlifeManagementThirdSectionInterface();
      const FourthSectionHabitatControl: IWildlifeManagementFourthSectionHabitatControl = WildlifeManagementFourthSectionHabitatControlInterface();
      const FourthSectionErosionControl: IWildlifeManagementFourthSectionErosionControl = WildlifeManagementFourthSectionErosionControlInterface();
      const FourthSectionPredatorControl: IWildlifeManagementFourthSectionPredatorControl = WildlifeManagementFourthSectionPredatorControlInterface();
      const FourthSectionSupplementalWater: IWildlifeManagementFourthSectionSupplementalWater = WildlifeManagementFourthSectionSupplementalWaterInterface();
      const FourthSectionSupplementalFood: IWildlifeManagementFourthSectionSupplementalFood = WildlifeManagementFourthSectionSupplementalFoodInterface();
      const FourthSectionSupplementalShelter: IWildlifeManagementFourthSectionSupplementalShelter = WildlifeManagementFourthSectionSupplementalShelterInterface();
      const FourthSectionCensus: IWildlifeManagementFourthSectionCensus = WildlifeManagementFourthSectionCensusInterface();
      const FifthSection: IWildlifeManagementFifthSection = WildlifeManagementFifthSectionInterface();
      const SixthSection: IWildlifeManagementSixthSection = WildlifeManagementSixthSectionInterface();

      selectedFormState?.formFields.forEach((f) => {
        let value;
        switch (f.type) {
          case "boolean":
          case "object":
            value = JSON.parse(f.value);
            break;
          case "string":
            value = f.value.trim();
            break;
          case "number":
            value = +f.value;
            break;
        }

        switch(true) {
          case f.name in FirstSection:
            (FirstSection as any)[f.name] = value;
            break;
          case f.name in SecondSection:
            (SecondSection as any)[f.name] = value;
            break;
          case f.name in ThirdSection:
            (ThirdSection as any)[f.name] = value;
            break;
          case f.name in FourthSectionHabitatControl:
            (FourthSectionHabitatControl as any)[f.name] = value;
            break;
          case f.name in FourthSectionErosionControl:
            (FourthSectionErosionControl as any)[f.name] = value;
            break;
          case f.name in FourthSectionPredatorControl:
            (FourthSectionPredatorControl as any)[f.name] = value;
            break;
          case f.name in FourthSectionSupplementalWater:
            (FourthSectionSupplementalWater as any)[f.name] = value;
            break;
          case f.name in FourthSectionSupplementalFood:
            (FourthSectionSupplementalFood as any)[f.name] = value;
            break;
          case f.name in FourthSectionSupplementalShelter:
            (FourthSectionSupplementalShelter as any)[f.name] = value;
            break;
          case f.name in FourthSectionCensus:
            (FourthSectionCensus as any)[f.name] = value;
            break;
          case f.name in FifthSection:
            (FifthSection as any)[f.name] = value;
            break;
          case f.name in SixthSection:
            (SixthSection as any)[f.name] = value;
            break;
        }
      });
      if (selectedFormState.formFields.length > 0) {
        setFormFirstSectionDataFields(FirstSection);
        setFormSecondSectionDataFields(SecondSection);
        setFormThirdSectionDataFields(ThirdSection);
        setFormFourthSectionHabitatControlDataFields(FourthSectionHabitatControl);
        setFormFourthSectionErosionControlDataFields(FourthSectionErosionControl);
        setFormFourthSectionPredatorControlDataFields(FourthSectionPredatorControl);
        setFormFourthSectionSupplementalWaterDataFields(FourthSectionSupplementalWater);
        setFormFourthSectionSupplementalFoodDataFields(FourthSectionSupplementalFood);
        setFormFourthSectionSupplementalShelterDataFields(FourthSectionSupplementalShelter);
        setFormFourthSectionCensusDataFields(FourthSectionCensus);
        setFormFifthSectionDataFields(FifthSection);
        setFormSixthSectionDataFields(SixthSection);
      }
    }, [selectedFormState.formFields]);

    useFormSectionDataFields({
      sectionFields: formFirstSectionDataFields,
      formDataFields: formDataFields,
      setFormDataFields: setFormDataFields,
      formId: formEntry?.formId,
      formEntryId: formEntry?.id
    });

    useFormSectionDataFields({
      sectionFields: formSecondSectionDataFields,
      formDataFields: formDataFields,
      setFormDataFields: setFormDataFields,
      formId: formEntry?.formId,
      formEntryId: formEntry?.id
    });

    useFormSectionDataFields({
      sectionFields: formThirdSectionDataFields,
      formDataFields: formDataFields,
      setFormDataFields: setFormDataFields,
      formId: formEntry?.formId,
      formEntryId: formEntry?.id
    });

    useFormSectionDataFields({
      sectionFields: formFourthSectionHabitatControlDataFields,
      formDataFields: formDataFields,
      setFormDataFields: setFormDataFields,
      formId: formEntry?.formId,
      formEntryId: formEntry?.id
    });

    useFormSectionDataFields({
      sectionFields: formFourthSectionErosionControlDataFields,
      formDataFields: formDataFields,
      setFormDataFields: setFormDataFields,
      formId: formEntry?.formId,
      formEntryId: formEntry?.id
    });

    useFormSectionDataFields({
      sectionFields: formFourthSectionPredatorControlDataFields,
      formDataFields: formDataFields,
      setFormDataFields: setFormDataFields,
      formId: formEntry?.formId,
      formEntryId: formEntry?.id
    });

    useFormSectionDataFields({
      sectionFields: formFourthSectionSupplementalWaterDataFields,
      formDataFields: formDataFields,
      setFormDataFields: setFormDataFields,
      formId: formEntry?.formId,
      formEntryId: formEntry?.id
    });

    useFormSectionDataFields({
      sectionFields: formFourthSectionSupplementalFoodDataFields,
      formDataFields: formDataFields,
      setFormDataFields: setFormDataFields,
      formId: formEntry?.formId,
      formEntryId: formEntry?.id
    });

    useFormSectionDataFields({
      sectionFields: formFourthSectionSupplementalShelterDataFields,
      formDataFields: formDataFields,
      setFormDataFields: setFormDataFields,
      formId: formEntry?.formId,
      formEntryId: formEntry?.id
    });

    useFormSectionDataFields({
      sectionFields: formFourthSectionCensusDataFields,
      formDataFields: formDataFields,
      setFormDataFields: setFormDataFields,
      formId: formEntry?.formId,
      formEntryId: formEntry?.id
    });

    useFormSectionDataFields({
      sectionFields: formFifthSectionDataFields,
      formDataFields: formDataFields,
      setFormDataFields: setFormDataFields,
      formId: formEntry?.formId,
      formEntryId: formEntry?.id
    });

    useFormSectionDataFields({
      sectionFields: formSixthSectionDataFields,
      formDataFields: formDataFields,
      setFormDataFields: setFormDataFields,
      formId: formEntry?.formId,
      formEntryId: formEntry?.id
    });

    const navigate = useNavigate();

    function saveHandler() {
      setDisablePrompt(true);
      const temp = {
        ...formEntry,
        formName: selectedFormState.form?.name,
        submissionStatus: "Saved",
      } as IFormEntryModel;
      setFormEntry(temp);
      if (selectedFormState.form?.name) {
        saveFormHelper(selectedFormDispatch, selectedFormState.form?.name, temp, formDataFields!, navigate);
      }
    }

    function setFormEntryBase64Url(url: string) {
      setFormEntry({ ...formEntry, pdfBase64Url: url } as IFormEntryModel);
    }

    function submitHandler() {
      const temp = {
        ...formEntry,
        formName: selectedFormState.form?.name,
        submissionStatus: "Submitted",
      } as IFormEntryModel;
      setFormEntry(temp);
      setDisablePrompt(true);

      if (selectedFormState.form?.name) {
        saveFormHelper(selectedFormDispatch, selectedFormState.form?.name, temp, formDataFields!, navigate);
      }
    }

    const [visible, setVisible] = useState<boolean>(false);
    const [submit, setSubmit] = useState<boolean>(false);

    const toggleDialog = () => {
      setVisible(!visible);
    };

    const toggleSubmitDialog = () => {
      setSubmit(!submit);
      setVisible(!visible);
    };

    const dialogTitle = (
      <div>
        {submit && !formEntry?.underRevision && formEntry?.submissionStatus != "Submitted" && (
          <button className="button" onClick={submitHandler}>
            submit form
          </button>
        )}
      </div>
    );

    return <>
      <div className={classes.form}>
        {visible && (
          <Dialog
            className="customFormDialog"
            title={dialogTitle}
            onClose={submit ? toggleSubmitDialog : toggleDialog}
            width={"70vw"}
            height={"90vh"}
          >
          <WildlifeManagementPDFView
            getPdfBase64Url={setFormEntryBase64Url}
            formDataFieldsFirstSection={formFirstSectionDataFields}
            formDataFieldsSecondSection={formSecondSectionDataFields}
            formDataFieldsThirdSection={formThirdSectionDataFields}
            formDataFieldsFourthSectionHabitatControl={formFourthSectionHabitatControlDataFields}
            formDataFieldsFourthSectionErosionControl={formFourthSectionErosionControlDataFields}
            formDataFieldsFourthSectionPredatorControl={formFourthSectionPredatorControlDataFields}
            formDataFieldsFourthSectionSupplementalWater={formFourthSectionSupplementalWaterDataFields}
            formDataFieldsFourthSectionSupplementalFood={formFourthSectionSupplementalFoodDataFields}
            formDataFieldsFourthSectionSupplementalShelter={formFourthSectionSupplementalShelterDataFields}
            formDataFieldsFourthSectionCensus={formFourthSectionCensusDataFields}
            formDataFieldsFifthSection={formFifthSectionDataFields}
            formDataFieldsSixthSection={formSixthSectionDataFields}
          />
          </Dialog>
        )}

        <FormHeader
          formName={WildlifeManagementFormName}
          isAdminPreview={isAdminPreview}
          isAuthorized={isAuthorized}
          formEntry={formEntry}
          formSettings={formSettings}
          isFormValid={isFormValid}
          onPrint={() => toggleDialog()}
          onSave={() => saveHandler()}
          onSubmit={() => toggleSubmitDialog()}
        />

        <WildlifeManagementFirstSection
          isAdminPreview={isAdminPreview}
          formSettings={formSettings}
          formDataFields={formFirstSectionDataFields}
          setFormDataFields={setFormFirstSectionDataFields}
          setFormIsValid={setFirstSectionIsValid}
        />

        <WildlifeManagementSecondSection
          isAdminPreview={isAdminPreview}
          formSettings={formSettings}
          formDataFields={formSecondSectionDataFields}
          setFormDataFields={setFormSecondSectionDataFields}
          setFormIsValid={setSecondSectionIsValid}
        />

        <WildlifeManagementThirdSection
          isAdminPreview={isAdminPreview}
          formSettings={formSettings}
          formDataFields={formThirdSectionDataFields}
          setFormDataFields={setFormThirdSectionDataFields}
          setFormIsValid={setThirdSectionIsValid}
        />

        <WildlifeManagementFourthSection
          isAdminPreview={isAdminPreview}
          formSettings={formSettings}
          formDataHabitatControlFields={formFourthSectionHabitatControlDataFields}
          formDataErosionControlFields={formFourthSectionErosionControlDataFields}
          formDataPredatorControlFields={formFourthSectionPredatorControlDataFields}
          formDataSupplementalWaterFields={formFourthSectionSupplementalWaterDataFields}
          formDataSupplementalFoodFields={formFourthSectionSupplementalFoodDataFields}
          formDataSupplementalShelterFields={formFourthSectionSupplementalShelterDataFields}
          formDataCensusFields={formFourthSectionCensusDataFields}
          setHabitatControlFormDataFields={setFormFourthSectionHabitatControlDataFields}
          setErosionControlFormDataFields={setFormFourthSectionErosionControlDataFields}
          setPredatorControlFormDataFields={setFormFourthSectionPredatorControlDataFields}
          setSupplementalWaterFormDataFields={setFormFourthSectionSupplementalWaterDataFields}
          setSupplementalFoodFormDataFields={setFormFourthSectionSupplementalFoodDataFields}
          setSupplementalShelterFormDataFields={setFormFourthSectionSupplementalShelterDataFields}
          setCensusFormDataFields={setFormFourthSectionCensusDataFields}
          setHabitatControlFormIsValid={setFourthSectionHabitatControlIsValid}
          setErosionControlFormIsValid={setFourthSectionErosionControlIsValid}
          setPredatorControlFormIsValid={setFourthSectionPredatorControlIsValid}
          setSupplementalWaterFormIsValid={setFourthSectionSupplementalWaterIsValid}
          setSupplementalFoodFormIsValid={setFourthSectionSupplementalFoodIsValid}
          setSupplementalShelterFormIsValid={setFourthSectionSupplementalShelterIsValid}
          setCensusFormIsValid={setFourthSectionCensusIsValid}
        />

        <WildlifeManagementFifthSection
          isAdminPreview={isAdminPreview}
          formSettings={formSettings}
          formEntry={formEntry}
          formDataFields={formFifthSectionDataFields}
          setFormDataFields={setFormFifthSectionDataFields}
          setFormIsValid={setFifthSectionIsValid}
          selectedFormDispatch={selectedFormDispatch}
        />

        <WildlifeManagementSixthSection
          isAdminPreview={isAdminPreview}
          formSettings={formSettings}
          formDataFields={formSixthSectionDataFields}
          setFormDataFields={setFormSixthSectionDataFields}
          setFormIsValid={setSixthSectionIsValid}
        />

        {(!isFormValid && !formSettings?.isReadOnly) &&
          <div className={classes.invalidForm}>
            <h2>Please Fill All Required Fields in Steps:</h2>
            {!firstSectionIsValid &&
              <div className={classes.item}>
                1.   Part I. Owner Information
              </div>}
            {!secondSectionIsValid &&
              <div className={classes.item}>
                2.   Part II. Qualifying Wildlife Management Activities
              </div>}
            {!thirdSectionIsValid &&
              <div className={classes.item}>
                3.   Part III. Wildlife Management Association Membership
              </div>}
            {!fourthSectionHabitatControlIsValid && !fourthSectionErosionControlIsValid &&
              !fourthSectionPredatorControlIsValid && !fourthSectionSupplementalWaterIsValid &&
              !fourthSectionSupplementalFoodIsValid && !fourthSectionSupplementalShelterIsValid &&
              !fourthSectionCensusIsValid &&
              <div className={classes.item}>
                4.   Part IV. Wildlife Management Activities
              </div>}
            {!fifthSectionIsValid &&
              <div className={classes.item}>
                5.   Part V. Supporting Documents
              </div>}
            {!sixthSectionIsValid &&
              <div className={classes.item}>
                6.   Part VI. Certification and Signature
              </div>}
          </div>}

        {isAuthorized && !isAdminPreview &&
          !formSettings?.isReadOnly &&
          formEntry?.submissionStatus != "Submitted" && (
            <span className="buttons-group">
              <button disabled={!isFormValid}
                className="button"
                onClick={() => toggleSubmitDialog()}
              >
                <i className="fa fa-tasks fa-fw"></i>
                Review & Submit
              </button>
            </span>
          )}
      </div>
      {(loading || isLoading) && <CustomLoader />}
      {showPrompt && isAuthorized && (!loading && !isLoading) && (
        <UnsavedFormDialog
          onConfirm={confirmNavigation}
          onCancel={cancelNavigation}
        />
      )}
    </>
  }