import {useCallback, useEffect, useState} from "react";
import {IRowState, UpdateErrorsState} from "../UpdateRowErrorStateHandler";
import {CustomDatePickerField} from "../../../../../Shared/DatePickers/CustomDatePickerField";
import {DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";

export interface IRowDateValueHandler<T>{
    setFormDataFields?: (form: T) => void;
    formDataFields?: T,
    fieldName: string,
    disabled?: boolean,
    CustomCalendar?: any,
    onlyYear: boolean,
    required: boolean,
    defaultValueDate?: Date | string | null,
    secondName?: string,
    group?: string,
    id: number,
    errorsArray: Array<IRowState>;
    setErrorsArray: (item: Array<IRowState>) => void,
    maxDate?: Date,
    customChangeEventHandler?: (e: DatePickerChangeEvent, index: number, fieldName: any) => void;
    rowItem?: string,
}

export function CustomRowDatePicker<T>(props: IRowDateValueHandler<T>): JSX.Element{
    const {
        setFormDataFields,
        formDataFields,
        fieldName,
        disabled,
        CustomCalendar,
        onlyYear,
        required,
        defaultValueDate,
        secondName,
        group,
        id,
        errorsArray,
        setErrorsArray,
        maxDate,
        customChangeEventHandler,
        rowItem
    } = props;

    useEffect(() => {
        return () => {
            setIsValidField(true);
        };
    }, []);

    const setIsValidField = (validField: boolean) => {
        UpdateErrorsState({
            valid: validField,
            setIsInputValid: () => undefined,
            id: id,
            errorsArray: errorsArray,
            setErrorsArray: setErrorsArray
        });
    }

    function changeRowDataHandler( event:any,
                                   field: string,
                                   group: string,
                                   id: number){
        const newItems: T = formDataFields ? JSON.parse(JSON.stringify(formDataFields)) : [];

        let item = (newItems as any)[group]?.find((x: any)=> x.Id == id);

        item = {...item,
            [field]: Date.parse(event) ?
                onlyYear ?
                    event?.getFullYear().toString() :
                    event?.toDateString() :
                event?.toString(),
            Id: id
        };

        const index = (newItems as any)[group]?.findIndex((x: any)=>x.Id == id);

        if(index != undefined && (newItems as any)[group]) {
            (newItems as any)[group][index] = item
        }

        if(setFormDataFields){
            setFormDataFields({
                ...newItems
            } as T)
        }
    }

    function ChangeEventsHelper(event:any) {
        if(customChangeEventHandler){
            customChangeEventHandler({value: event} as DatePickerChangeEvent, id, fieldName);
        } else if(group){
            changeRowDataHandler(event, fieldName, group, id);
        }
    }

    return  <>
        <CustomDatePickerField
            fieldName={fieldName}
            required={required}
            setIsValidField={setIsValidField}
            onlyYear={onlyYear}
            onChange={ChangeEventsHelper}
            disabled={disabled}
            CustomCalendar={CustomCalendar}
            maxDate={maxDate}
            secondName={secondName}
            initialValue={defaultValueDate?.toString()}
            rowItem={rowItem}
        />
    </>
}

