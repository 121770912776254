import {IFormFilesInterface} from "../Shared/form-files.interface";

export type IHomesteadExemptionThirdSectionInterface = {
    PropertyOwnerType?: string //Single Adult, Married Couple, Other (e.g., individual who owns the property with others)
    PropertyOwnerName?: string,
    PropertyOwnerBirthDate?: string,
    PropertyOwnerOwnershipInterest?: number,
    PropertyOwnerEmailAddress?: string,
    PropertyOwnerPhoneNumber?: string,
    ApplicantAddressLine1?: string,
    ApplicantAddressLine2?: string,
    ApplicantAddressLine3?: string,
    ApplicantAddressCity?: string,
    ApplicantAddressState?: string,
    ApplicantAddressZip?: string,
    IsActiveDutyMilitary?: boolean,
    UtilityBill?: Array<IFormFilesInterface>,
    IsExemptFromProvidingACopyOfId?: boolean,
    IsResidentOfAFacility?: boolean,
    FacilityName?: string,
    FacilityAddressLine1?: string,
    FacilityAddressLine2?: string,
    FacilityAddressCity?: string,
    FacilityAddressState?: string,
    FacilityAddressZip?: string,
    IsCertifiedForAddressConfidentialityProgram?: boolean,
    PropertyOwnerIdNumber?: string,
    PropertyOwnerPhotoId?: Array<IFormFilesInterface>,

    PropertyOwnerSpouseName?: string,
    PropertyOwnerSpouseBirthDate?: string,
    PropertyOwnerSpouseOwnershipInterest?: number,
    PropertyOwnerSpouseEmailAddress?: string,
    PropertyOwnerSpousePhoneNumber?: string,
    PropertyOwnerSpouseIdNumber?: string,
    PropertyOwnerSpousePhotoId?: Array<IFormFilesInterface>,

    HasMultipleOwners?: boolean,
    PropertyOwners?: Array<IPropertyOwners>,

    IsRequestingChiefAppraiserWaiveAddressRequirement?: boolean,
    IsSection521DriversLicenseHolder?: boolean,
}

export type IPropertyOwners = {
    Id: number,
    OwnerName?: string,
    EmailAddress?: string,
    PhoneNumber?: string,
    BirthDate?: string,
    PersonalIdCertificate?: string,
    PercentOwnership?: number,
}

export const HomesteadExemptionFormThirdSectionInitialState = (): IHomesteadExemptionThirdSectionInterface => ({
    PropertyOwnerType: undefined,
    PropertyOwnerName: undefined,
    PropertyOwnerBirthDate: undefined,
    PropertyOwnerOwnershipInterest: undefined,
    PropertyOwnerEmailAddress: undefined,
    PropertyOwnerPhoneNumber: undefined,
    ApplicantAddressLine1: undefined,
    ApplicantAddressLine2: undefined,
    ApplicantAddressLine3: undefined,
    ApplicantAddressCity: undefined,
    ApplicantAddressState: undefined,
    ApplicantAddressZip: undefined,
    IsActiveDutyMilitary: undefined,
    UtilityBill: undefined,
    IsExemptFromProvidingACopyOfId: undefined,
    IsResidentOfAFacility: undefined,
    FacilityName: undefined,
    FacilityAddressLine1: undefined,
    FacilityAddressLine2: undefined,
    FacilityAddressCity: undefined,
    FacilityAddressState: undefined,
    FacilityAddressZip: undefined,
    IsCertifiedForAddressConfidentialityProgram: undefined,
    PropertyOwnerIdNumber: undefined,
    PropertyOwnerPhotoId: undefined,

    PropertyOwnerSpouseName: undefined,
    PropertyOwnerSpouseBirthDate: undefined,
    PropertyOwnerSpouseOwnershipInterest: undefined,
    PropertyOwnerSpouseEmailAddress: undefined,
    PropertyOwnerSpousePhoneNumber: undefined,
    PropertyOwnerSpouseIdNumber: undefined,
    PropertyOwnerSpousePhotoId: undefined,

    HasMultipleOwners: undefined,
    PropertyOwners: undefined,

    IsRequestingChiefAppraiserWaiveAddressRequirement: undefined,
    IsSection521DriversLicenseHolder: undefined,
}
)