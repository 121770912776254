import {IFormFilesInterface} from "../Shared/form-files.interface";

export type IHomesteadExemptionSecondSectionInterface = {
    IsOwnerOfProperty?: boolean,
    IsGeneralHomesteadExemption?: boolean,
    IsDisabledPersonExemption?: boolean,
    IsAge65OrOlderExemption?: boolean,
    Affidavit?: Array<IFormFilesInterface>,
    IsDisabledVetExemption?: boolean,
    IsPermanentTotalDisability?: boolean,
    OneHundredPercentDisabledVeteranDocumentation?: Array<IFormFilesInterface>,
    IsDonatedResidenceExemption?: boolean,
    DonatedResidenceExemptionPercentDisabilityRating?: string,
    IsSurvingSpouseOfKiaExemption?: boolean,
    SurvivingSpouseName?: string,
    SurvivingSpouseDateOfDeath?: string,
    IsSurvingSpouseOfFirstResponder?: boolean,
}

export const HomesteadExemptionFormSecondSectionInitialState = (): IHomesteadExemptionSecondSectionInterface => ({
    IsOwnerOfProperty: undefined,
    IsGeneralHomesteadExemption: undefined,
    IsDisabledPersonExemption: undefined,
    IsAge65OrOlderExemption: undefined,
    Affidavit: undefined,
    IsDisabledVetExemption: undefined,
    IsPermanentTotalDisability: undefined,
    OneHundredPercentDisabledVeteranDocumentation: undefined,
    IsDonatedResidenceExemption: undefined,
    DonatedResidenceExemptionPercentDisabilityRating: undefined,
    IsSurvingSpouseOfKiaExemption: undefined,
    SurvivingSpouseName: undefined,
    SurvivingSpouseDateOfDeath: undefined,
    IsSurvingSpouseOfFirstResponder: undefined,
})