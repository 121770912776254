import { IBirdsType,
    IBirdsControlMethods,
    IWildBeastsType,
    IWildBeastsControlMethods } from "./predator-control.interface-types";

export interface IWildlifeManagementFourthSectionPredatorControl {
    PredatorControlImportedRedFireAnts?: boolean;
    PredatorControlBirds?: IBirdsType;
    PredatorControlBirdsControlMethods?: IBirdsControlMethods;
    PredatorControlBirdsControlMethodScareTactics?: string;
    PredatorControlWildBeasts?: IWildBeastsType;
    PredatorControlWildBeastsControlMethods?: IWildBeastsControlMethods;
    PredatorControlWildBeastsControlMethodOther?: string;
    PredatorControlAdditionalInformation?: string;
}

export const WildlifeManagementFourthSectionPredatorControlInterface = (): IWildlifeManagementFourthSectionPredatorControl => ({
    PredatorControlImportedRedFireAnts: undefined,
    PredatorControlBirds: undefined,
    PredatorControlBirdsControlMethods: undefined,
    PredatorControlBirdsControlMethodScareTactics: undefined,
    PredatorControlWildBeasts: undefined,
    PredatorControlWildBeastsControlMethods: undefined,
    PredatorControlWildBeastsControlMethodOther: undefined,
    PredatorControlAdditionalInformation: undefined
})