import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import {IAgFormFirstSectionInterface} from "../../../../../interfaces/forms/AgForm/ag-form-first-section.interface";
import React, {useEffect, useRef, useState} from "react";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./AgForm.module.scss";
import {
    CheckboxChangeEvent,
    InputChangeEvent, RadioGroup, RadioGroupChangeEvent,
    TextAreaChangeEvent
} from "@progress/kendo-react-inputs";
import {CalendarChangeEvent, DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import {CustomDatePicker} from "../CustomInputs/CustomDatePickers/CustomDatePicker";
import {Label} from "@progress/kendo-react-labels";
import { CustomOnlyYearCalendarHelper } from "src/pages/Shared/DatePickers/CustomCalendars";
import SectionTitle from "../Shared/SectionTitle";
import { defaultRadioButtonData } from "../Shared/RadioButtonData";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IAgFormFirstSectionInterface) => void;
    formDataFields?:  IAgFormFirstSectionInterface,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function AgFormFirstSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, setFormIsValid } = props;

    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} name={"Filing Information"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string
    )
    {

        if (field == "TaxYear"){
            setFormDataFields({
                ...formDataFields,
                [field]: event.value ? event.value.getFullYear().toString() : undefined
            });
        } else if (field == "ApplicantOwnedPropertyJan1") {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value ? JSON.parse(event.value) : undefined
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    const { CustomOnlyYearCalendar } = CustomOnlyYearCalendarHelper();
    const [taxYearInputValid, setTaxYearInputValid] = useState<boolean>(false);

    function changeValidator() {
        if(formRef.current) {
            setIsValid(formRef.current.checkValidity() && taxYearInputValid);
            setFormIsValid(formRef.current.checkValidity() && taxYearInputValid);
        }
    }

    function onTouch(){
        if(!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current, taxYearInputValid]);

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={classes.itemFields}>
                                <Label className={classes.Label}>
                                    State the year for which you are seeking agricultural use appraisal
                                    <i className={"fa fa-asterisk text-danger"}></i>
                                </Label>
                                <div className={classes.fieldsGroup}>
                                    <div className="inputsGroup">
                                        <CustomDatePicker
                                            fieldName={"TaxYear"}
                                            required={true}
                                            disabled={formSettings?.isReadOnly}
                                            formDataFields={formDataFields}
                                            setFormDataFields={setFormDataFields}
                                            onlyYear={true}
                                            CustomCalendar={CustomOnlyYearCalendar}
                                            setIsValidField={setTaxYearInputValid}
                                            validField={taxYearInputValid}
                                            defaultValueDate={formDataFields?.TaxYear}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.itemFields}>
                                <p className="itemTitle">
                                    Did the applicant own the property that is the subject of this application on Jan. 1 of the tax year?
                                </p>
                                <div className={classes.fieldsGroup}>
                                    <div className={classes.checkboxGroup}>
                                        <RadioGroup
                                            layout={"horizontal"}
                                            disabled={formSettings?.isReadOnly}
                                            data={defaultRadioButtonData}
                                            onChange={e => changeValueHandler(e, "ApplicantOwnedPropertyJan1")}
                                            value={formDataFields?.ApplicantOwnedPropertyJan1?.toString()}
                                        />
                                    </div>
                                </div>
                            </div>
                            { !formSettings?.isReadOnly &&
                            <div>
                                <div>
                                    <div>
                                        <div className={classes.information}>
                                            <div className={`${classes.informationTitle}`}>
                                            <p>GENERAL INFORMATION:</p>
                                            </div>
                                            <p>
                                                Agricultural use includes, but is not limited to, the following activities:
                                                (1)
                                                cultivating the soil; (2) producing crops for human food, animal feed, or
                                                planting seed or for the production of fibers; (3) floriculture, viticulture
                                                and
                                                horticulture; (4) raising or keeping livestock; (5) raising or keeping
                                                exotic
                                                animals or fowl for the production of human food or fiber, leather, pelts
                                                or other tangible products having a commercial value; (6) planting cover
                                                crops or leaving land idle for the purpose of participating in a
                                                governmental
                                                program provided the land is not used for residential purposes or a purpose
                                                inconsistent with agricultural use or leaving the land idle in conjunction
                                                with
                                                normal crop or livestock rotation procedures; (7) producing or harvesting
                                                logs and posts used for construction or repair of fences, pens, barns or
                                                other
                                                agricultural improvements on adjacent open-space land having the same
                                                owner and devoted to a different agricultural use; (8) wildlife management;
                                                and (9) beekeeping.
                                            </p>
                                            <p >
                                                Wildlife management is defined as actively using land that at the time the
                                                wildlife-management use began, was appraised as qualified open-space
                                                or timberland under Tax Code, Chapter 23, Subchapter D or E, to propagate
                                                a sustaining breeding, migrating or wintering population of indigenous
                                                wild animals for human use, including food, medicine or recreation, in at
                                                least three of the following ways: (1) habitat control; (2) erosion control;
                                                (3)
                                                predator control; (4) providing supplemental supplies of water; (5)
                                                providing
                                                supplement supplies of food; (6) providing shelters; and (7) making census
                                                counts to determine population.
                                            </p>
                                            <p >
                                                Wildlife management is defined as actively using land to protect federally
                                                listed endangered species under a federal permit if the land is included in
                                                a
                                                habitat preserve subject to a conservation easement created under Natural
                                                Resources Code Chapter 183 or part of a conservation development under a
                                                federally approved habitat conservation plan restricting the use of the land
                                                to protect federally listed endangered species or actively using land for a
                                                conservation or restoration project under certain federal and state
                                                statutes.
                                                These two types of wildlife management uses do not require showing a
                                                history of agricultural use but do require evidence identified in section 6,
                                                questions 7 and 8.
                                            </p>
                                            <p >
                                                Agricultural land use categories include: (1) irrigated cropland; (2) dry
                                                cropland; (3) improved pastureland; (4) native pastureland; (5) orchard; (6)
                                                wasteland; (7) timber production; (8) wildlife management; and (9) other
                                                categories of land that are typical in the area.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>

                                    <div className={classes.information}>
                                        <div className={`${classes.informationTitle}`}>
                                            <p>APPLICATION DEADLINES:</p>
                                        </div>
                                        <p>
                                            The completed application must be filed with the chief appraiser before May
                                            1 of the year for which agricultural appraisal is requested. If the application
                                            is approved, a new application is not required in later years unless the land
                                            ownership changes, eligibility ends or the chief appraiser requests a new
                                            application.
                                        </p>
                                        <p>
                                            A late application may be filed up to midnight the day before the appraisal
                                            review board approves appraisal records for the year, which usually occurs in
                                            July. If a late application is approved, a penalty will be applied in an amount
                                            equal to 10 percent of the difference between the amount of tax imposed on
                                            the property and the amount that would be imposed if the property were
                                            taxed at market value.
                                        </p></div>
                                </div>
                                <div>
                                    <div>
                                        <div className={classes.information}>
                                            <div className={`${classes.informationTitle}`}>
                                                <p>DUTY TO NOTIFY AND PENALTIES:</p>
                                            </div>
                                            <p>
                                                The property owner must notify the chief appraiser no later than the April
                                                30
                                                following the change in use or eligibility. A change of land use for all or
                                                part
                                                of the property will trigger substantial additional tax plus interest (a
                                                rollback
                                                tax). Payment of a penalty may also be required for failure to notify the
                                                chief
                                                appraiser of a change in agricultural use or qualification. Notice must be
                                                delivered to the chief appraiser if:
                                            </p>
                                            <ul>
                                                <li>
                                                    the property stops being used for agriculture (e.g., voluntarily
                                                    stopped farming);
                                                </li>
                                                <li>
                                                    category of land use changes (e.g., from dry cropland to irrigated
                                                    cropland;
                                                </li>
                                                <li>
                                                    level of use changes (e.g., a substantial increase or decrease the
                                                    number of cattle raised);
                                                </li>
                                                <li>
                                                    nature of use changes (e.g., a switch from growing corn to growing
                                                    ornamental plants);
                                                </li>
                                                <li>
                                                    property owner enters, leaves or changes governmental programs
                                                    (e.g., 100 acres placed in a conservation reserve program); or
                                                </li>
                                                <li>
                                                    the land is used for something other than agriculture (e.g., to build a
                                                    shopping center on most of the land).
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <div className={classes.information}>
                                            <div className={`${classes.informationTitle}`}>
                                                <p>DUTY TO NOTIFY FOR CERTAIN LANDOWNERS:</p>
                                            </div>
                                            <p>
                                                If land ceases to be devoted principally to agricultural use to the degree
                                                of intensity generally accepted in the area, open-space appraisal may be
                                                retained if the chief appraiser is notified as required and the property
                                                owner:
                                            </p>
                                            <ul>
                                                <li>
                                                    is a member of the armed services who is deployed or stationed
                                                    outside of Texas who intends to return the land to the manner and
                                                    to the degree of intensity that is generally accepted in the area not
                                                    later than the 180th day after being deployed or stationed outside
                                                    this state ceases;
                                                </li>
                                                <li>
                                                    owns land that has previously been under open-space appraisal
                                                    primarily based on its citrus production; the land is located in a pest
                                                    management zone; and an agreement was executed to destroy,
                                                    remove or treat all the citrus trees located on the land that are or
                                                    could become infested with pests with one of the following: Texas
                                                    Citrus Pest and Disease Management Corporation, Inc., the Texas
                                                    Commissioner of Agriculture or the U.S. Department of Agriculture;
                                                    or
                                                </li>
                                                <li>
                                                    owns land that has previously been under open-space appraisal
                                                    primarily on the basis of livestock; the land is located in a temporary
                                                    quarantine area established during the tax year by the Texas Animal
                                                    Health Commission for the purpose of regulating the handling of
                                                    livestock and eradicating ticks or exposure to ticks under Chapter
                                                    167, Agriculture Code.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <div className={classes.information}>
                                            <div className={`${classes.informationTitle}`}>
                                                <p>OTHER IMPORTANT INFORMATION:</p>
                                            </div>
                                            <p>
                                                If the initial application form does not contain all essential information,
                                                the
                                                chief appraiser may request additional information that is necessary to
                                                determine whether the land qualifies for 1-d-1 appraisal. The chief
                                                appraiser may
                                                disapprove the application and request additional information. The chief
                                                appraiser may deny the application and that determination may be protested
                                                to the county appraisal review board in a timely manner. If the chief
                                                appraiser
                                                requests additional information from an applicant, the information must be
                                                furnished within 30 days after the date of the request, or the application
                                                is
                                                denied. For good cause shown, the chief appraiser may extend the deadline
                                                for furnishing the information by written order for a single 15 day period.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div> }
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
