import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "src/pages/Home/components/Forms/ExemptionPropertyDamagedForm/ExemptionPropertyDamaged.module.scss";
import { SectionInformationTitle } from "../Shared/SectionTitle";

export default function ExemptionPropertyDamagedFormEighthSection(): JSX.Element {
    const title = <SectionInformationTitle number={8} name={"Important Information"}/>;

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <div className={classes.information}>
                    <div>
                        <p>
                            <b>GENERAL INFORMATION:</b> 
                            Property owners use this form to claim a temporary property tax exemption 
                            for property in a governor-declared disaster area with at least 15 percent damage. 
                            (Tax Code Section 11.35)
                        </p>
                    </div>
                    <div>
                        <p>
                            <b>FILING INSTRUCTIONS:</b> File this form and all supporting documentation with the 
                            appraisal district office in each county where property is located and the temporary 
                            exemption is requested. Do not file this document with the Texas Comptroller of Public 
                            Accounts. Contact information for appraisal district offices may be found on the 
                            Comptroller’s website.
                        </p>
                    </div>
                    <div>
                        <p>
                            <b>DEADLINE:</b> Application and supporting documentation must be submitted to the 
                            appraisal district no later than the 105th day after the date the governor declares 
                            the area as a disaster area.
                        </p>
                    </div>
                </div>
            </PanelBarItem>
        </PanelBar>
    </>
}
