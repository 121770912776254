import type { UserActionType } from "src/context/User/user-actions.type";
import type { UserInfo } from "src/interfaces/public/user-info.interface";

interface UserActions {
  REQUEST_USER_INFO: string;
  USER_INFO_ERROR: string;
  USER_INFO_SUCCESS: string;
}

export const UserActions: UserActions = {
  REQUEST_USER_INFO: "REQUEST_USER_INFO",
  USER_INFO_ERROR: "USER_INFO_ERROR",
  USER_INFO_SUCCESS: "USER_INFO_SUCCESS",
};

function action(type: string): UserActionType {
  return { type };
}

function actionPayload(type: string, payload: UserInfo): UserActionType {
  return { type, payload };
}

function actionError(type: string, error: string): UserActionType {
  return { type, error };
}

export const Dispatches = {
  requestLogin: action,
  loginError: actionError,
  loginSuccess: actionPayload,
};
