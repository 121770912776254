export enum PermissionEnum {
  ManageUsers = "ManageUsers",
  ViewUsers = "ViewUsers",
  ManageSiteSettings = "ManageSiteSettings",
  ViewSiteSettings = "ViewSiteSettings",
  ManagePermissions = "ManagePermissions",
  ViewPermissions = "ViewPermissions",
  DeleteFormsFromRecycleBin = "DeleteFormsFromRecycleBin",
  ViewUserGroups = "ViewUserGroups",
  ManageUserGroups = "ManageUserGroups",
  ReadForms = "ReadForms",
  ApproveForms = "ApproveForms",
  DenyForms = "DenyForms",
  DeleteForms = "DeleteForms",
  UnlockForms = "UnlockForms",
  ArchiveForms = "ArchiveForms",
  ManageFormSettings = "ManageFormSettings",
  ViewFormSettings = "ViewFormSettings",
  ManageViews = "ManageViews",
  UpdateFormStatus = "UpdateFormStatus",
  AddFormNotes = "AddFormNotes",
  AssignToSelf = "AssignToSelf",
  ManageAllForms = "ManageAllForms",
  AssignDialog = "AssignDialog",
  AssignForms = "AssignForms",
  SiteAdmin = "SiteAdmin",
}

export enum PermissionTypeEnum {
  Group = "group",
  User = "user",
}
