import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "./WildlifeManagement.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { DatePickerChangeEvent, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, RadioGroupChangeEvent, Input } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { IWildlifeManagementThirdSection } from "src/interfaces/forms/WildlifeManagement/wildlife-management-third-section.interface";
import { CheckboxGroup } from "../CustomInputs/CheckBoxes/CheckboxGroup";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IWildlifeManagementThirdSection) => void;
    formDataFields?:  IWildlifeManagementThirdSection,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function WildlifeManagementThirdSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, setFormIsValid, isAdminPreview } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const [isMemberWildlifeInputValid, setIsMemberWildlifeInputValid] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} part={"III"} name={"Wildlife Management Association Membership"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string
    ) {
        setFormDataFields({
            ...formDataFields,
            [field]: event.value
        });
    }

    function changeValidator() {
        if (formRef.current) {
            const isValid = formRef.current.checkValidity() && isMemberWildlifeInputValid;
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if (!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [ formDataFields, formRef.current, isMemberWildlifeInputValid ]);

    return (
       <>
            <PanelBar keepItemsMounted={true}>
                <PanelBarItem title={title} expanded={true}>
                    <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                        <div className="container">
                            <div className={classes.item}>
                                <div className={classes.itemFields}>
                                    <Label>
                                        Are you a member of a wildlife property association?
                                    </Label>
                                    <div className={classes.fieldsGroup}>
                                        <div className={classes.checkboxGroup}>
                                            <CheckboxGroup
                                                isAdminPreview={isAdminPreview}
                                                setFormDataFields={setFormDataFields}
                                                formDataFields={formDataFields}
                                                formSettings={formSettings}
                                                fieldName={"IsMemberOfWildlifePropertyAssociation"}
                                                fieldSecondName={""}
                                                isRequired={false}
                                                checkboxesIsValid={isMemberWildlifeInputValid}
                                                setCheckboxesIsValid={setIsMemberWildlifeInputValid}
                                            />
                                        </div>
                                    </div>
                                </div>
                                { formDataFields?.IsMemberOfWildlifePropertyAssociation &&
                                    <div className={`${classes.flexRow}`}>
                                        <div className={`${classes.fieldsGroupW100}`}>
                                            <Label className={classes.Label}>
                                                Name of wildlife property co-op/association
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                required={false}
                                                name={"NameOfWildlifePropertyCoOpAssociation"}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.NameOfWildlifePropertyCoOpAssociation}
                                                onChange={(e)=> changeValueHandler(e, "NameOfWildlifePropertyCoOpAssociation")}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                   </form>
                </PanelBarItem>
            </PanelBar>
       </>
    )
}