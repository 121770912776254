import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "./DisabledVetForm.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, RadioGroupChangeEvent, Input, NumericTextBoxChangeEvent, TextArea } from "@progress/kendo-react-inputs";
import { FormValidation } from "../InputValidator";
import { Error, Label } from "@progress/kendo-react-labels";
import { IDisabledVetFourthSection } from "src/interfaces/forms/DisableVetForm/disable-vet-form-fourth-section";
import { StatesInput } from "../CustomInputs/StatesInput";
import { ZipCodeInput } from "../CustomInputs/ZipCodeInput";
import { IDisabledVetSecondSection } from "src/interfaces/forms/DisableVetForm/disable-vet-form-second-section";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IDisabledVetFourthSection) => void;
    formDataFields?: IDisabledVetFourthSection,
    formDataFieldsSecondSection?: IDisabledVetSecondSection,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function DisabledVetFourthSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formDataFieldsSecondSection, formSettings, setFormIsValid, isAdminPreview } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const [propertyAddressStateValid, setPropertyAddressStateValid] = useState<boolean>(false);
    const [propertyAddressZipCodeValid, setPropertyAddressZipCodeValid] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator } = FormValidation(formRef);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={4} name={"Property Descriptions"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | TextAreaChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | NumericTextBoxChangeEvent,
        field: string
    ) {
        setFormDataFields({
            ...formDataFields,
            [field]: event.value
        });
    }

    function changeValidator() {
        if (formRef.current) {
            const isValid = formRef.current.checkValidity() &&
                propertyAddressStateValid &&
                propertyAddressZipCodeValid
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    const saveAsOwnersMailingAddressHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        setFormDataFields({
            ...formDataFields,
            PropertyAddressLine1: formDataFieldsSecondSection?.PropertyOwnerMailingAddressLine1,
            PropertyAddressLine2: formDataFieldsSecondSection?.PropertyOwnerMailingAddressLine2,
            PropertyAddressCity: formDataFieldsSecondSection?.PropertyOwnerMailingAddressCity,
            PropertyAddressState: formDataFieldsSecondSection?.PropertyOwnerMailingAddressState,
            PropertyAddressZip: formDataFieldsSecondSection?.PropertyOwnerMailingAddressZip,
        });
    }

    function onTouch(){
        if (!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [
        formDataFields,
        formRef.current,
        propertyAddressStateValid,
        propertyAddressZipCodeValid
    ]);

    return (
       <>
            <PanelBar keepItemsMounted={true}>
                <PanelBarItem title={title} expanded={true}>
                    <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                        <div className="container">
                            <div className={`${classes.item}`}>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    Provide the descriptive information requested below for the designated 
                                    property that is the subject of this application for exemption under 
                                    Tax Code Section 11.22.
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Appraisal District Account Number (if known)
                                        {!formDataFields?.PropertyId && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={true}
                                        name={"PropertyId"}
                                        placeholder={"Appraisal District Account Number"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.PropertyId}
                                        onChange={(e)=> changeValueHandler(e, "PropertyId")}
                                    />
                                    {!inputValidator("PropertyId") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Legal Description (if known)
                                    </Label>
                                    <TextArea
                                        name={"LegalDescription"}
                                        required={false}
                                        className={classes.kTextarea}
                                        placeholder={"Legal Description"}
                                        autoSize={true}
                                        rows={2}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.PropertyLegalDescription}
                                        onChange={(e)=> changeValueHandler(e, "PropertyLegalDescription")}
                                    />
                                </div>
                            </div>
                            <div className={classes.item}>
                                <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                    <p>Property's Physical Address</p>
                                    { !formSettings?.isReadOnly && !isAdminPreview &&
                                        <button className={`${classes.button} ${classes.buttonAddress}`} 
                                            onClick={e => saveAsOwnersMailingAddressHandler(e)}>
                                            Same As Owner's Mailing Address
                                        </button> }
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Address Line 1
                                        {!formDataFields?.PropertyAddressLine1 && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={true}
                                        name={"PropertyAddressLine1"}
                                        placeholder={"Address Line 1"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.PropertyAddressLine1}
                                        onChange={(e)=> changeValueHandler(e, "PropertyAddressLine1")}
                                    />
                                    {!inputValidator("PropertyAddressLine1") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Address Line 2
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"PropertyAddressLine2"}
                                        placeholder={"Address Line 2"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.PropertyAddressLine2}
                                        onChange={(e)=> changeValueHandler(e, "PropertyAddressLine2")}
                                    />
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={`${classes.fieldsGroupW50}`}>
                                        <Label className={classes.Label}>
                                            City
                                            {!formDataFields?.PropertyAddressCity && (
                                                <i className={"fa fa-asterisk text-danger"}></i>
                                            )}
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            required={true}
                                            name={"PropertyAddressCity"}
                                            placeholder={"City"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.PropertyAddressCity}
                                            onChange={(e)=> changeValueHandler(e, "PropertyAddressCity")}
                                        />
                                        {!inputValidator("PropertyAddressCity") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                    <div className={`${classes.fieldsGroupW20}`}>
                                        <Label className={classes.Label}>
                                            State
                                            {!formDataFields?.PropertyAddressState && (
                                                <i className={"fa fa-asterisk text-danger"}></i>
                                            )}
                                        </Label>
                                        <StatesInput
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"PropertyAddressState"}
                                            required={true}
                                            validField={propertyAddressStateValid}
                                            setIsValidField={setPropertyAddressStateValid}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                        />
                                        {!inputValidator("PropertyAddressState") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                    <div className={`${classes.fieldsGroupW20}`}>
                                        <Label className={classes.Label}>
                                            Zip Code                                            
                                            {!formDataFields?.PropertyAddressZip && (
                                                <i className={"fa fa-asterisk text-danger"}></i>
                                            )}
                                        </Label>
                                        <ZipCodeInput
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"PropertyAddressZip"}
                                            placeholder={"Zip Code"}
                                            required={true}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                            setIsValidField={setPropertyAddressZipCodeValid}
                                            validField={propertyAddressZipCodeValid}
                                        />
                                        {!inputValidator("PropertyAddressZip") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.item}>
                                <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                    <p>Manufactured Home</p>
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={`${classes.fieldsGroupW30}`}>
                                        <Label className={classes.Label}>
                                            Make
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            required={false}
                                            name={"ManufacturedMake"}
                                            placeholder={"Make"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.ManufacturedMake}
                                            onChange={(e)=> changeValueHandler(e, "ManufacturedMake")}
                                        />
                                    </div>
                                    <div className={`${classes.fieldsGroupW30}`}>
                                        <Label className={classes.Label}>
                                            Model
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            required={false}
                                            name={"ManufacturedModel"}
                                            placeholder={"Model"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.ManufacturedModel}
                                            onChange={(e)=> changeValueHandler(e, "ManufacturedModel")}
                                        />
                                    </div>
                                    <div className={`${classes.fieldsGroupW30}`}>
                                        <Label className={classes.Label}>
                                            Identification Number
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            required={false}
                                            name={"ManufacturedIdNumber"}
                                            placeholder={"Identification Number"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.ManufacturedIdNumber}
                                            onChange={(e)=> changeValueHandler(e, "ManufacturedIdNumber")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                   </form>
                </PanelBarItem>
            </PanelBar>
       </>
    )
}