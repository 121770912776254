import { SelectedFormType } from "./selected-form-reducer";
import {GenericActionType} from "../generic-actions.type";
import {setLoading, temporaryError, temporarySuccessMessage} from "../shared/shared-context.helper";
import {
    getFormById,
    getFormSettings,
    getPrefilledDataForHarrisForm,
    removeSupportingDocuments,
    saveSupportingDocuments
} from "../../api/form-api";
import {SelectedFormActionsEnum} from "./selected-form-actions";
import {AxiosError, AxiosResponse} from "axios";
import {
    getFormEntryByPropertyIdAndYear,
    getFormEntryDataById,
    getFormEntryTimelines,
    getFormFieldsByFormEntryId,
    getNewFormEntries, getPriorYearFormFieldsByFormId,
    saveForm, tryGetPreviouslySubmittedForm
} from "../../api/form-entries-api";
import {IFormEntryModel} from "../../interfaces/form-entry.interface";
import {IFormFiledInterface} from "../../interfaces/form-filed.interface";
import {appendSupportingDocumentsToPdf, saveFormPdf} from "../../api/form-pdfs-api";
import {IUpdateSupportingDocuments} from "../../interfaces/update-supporting-documents.interface";
import {settingsService} from "src/services/settings.service";
import {UploadFileInfo, UploadFileStatus} from "@progress/kendo-react-upload";
import {NavigateFunction, NavigateOptions} from "react-router-dom";
import {IFormFilesInterface} from "src/interfaces/forms/Shared/form-files.interface";
import { IBppFormFourteenthSectionInterface } from "src/interfaces/forms/BPPForm/bpp-form-fourteenth-section.interface";
import { IBppFormFourthSectionInterface } from "src/interfaces/forms/BPPForm/bpp-form-fourth-section.interface";
import { submitted } from "src/constants/submission-form-status-constants";

const settings = settingsService().settings;
export function getSelectedFormDataHelper(
    selectedFormDispatch: (value: GenericActionType<SelectedFormType>) => void,
    formId: number,
    initiatedBy?: string
) {
    setLoading(selectedFormDispatch, true);

    Promise.all([
        getFormById(formId),
        getFormSettings(parseInt(settings.onlineFormsYear), formId),
        getNewFormEntries(formId),
    ])
    .then((response) => {
        selectedFormDispatch({
            type: SelectedFormActionsEnum.FORM,
            payload: {
                form: response[0].data,
            }
        });

        selectedFormDispatch({
            type: SelectedFormActionsEnum.FORM_SETTINGS,
            payload: {
                formSettings: response[1].data,
            },
        });

        selectedFormDispatch({
            type: SelectedFormActionsEnum.FORM_ENTRY,
            payload: {
                formEntry: initiatedBy
                            ? { ...response[2].data.formEntry, initiatedBy: initiatedBy }
                            : response[2].data.formEntry,
            },
        });

        selectedFormDispatch({
            type: SelectedFormActionsEnum.FORM_FIELDS,
            payload: {
                formFields: []
            },
        });

        setLoading(selectedFormDispatch);
    })
    .catch((error: AxiosError) =>
        temporaryError(selectedFormDispatch, error.response?.data)
    );
}

export async function getHarrisSelectedFormDataWithPrefilledDataHelper(
    selectedFormDispatch: (value: GenericActionType<SelectedFormType>) => void,
    formId: number,
    formName: string,
    propertyId: string,
    year: number,
    ownerId: string,
    initiatedBy: string,
    navigateFunction: NavigateFunction
) {
    setLoading(selectedFormDispatch, true);

    try {
        const response = await getFormEntryByPropertyIdAndYear(formId, propertyId, year);

        if (response.data?.id) {
            setLoading(selectedFormDispatch);
            navigateToFormEntryHelper(navigateFunction, formName, response.data.id, { replace: true });
            return;
        }

        const [formResponse, formSettingsResponse, prefilledDataResponse, formEntryResponse] = await Promise.all([
            getFormById(formId),
            getFormSettings(parseInt(settings.onlineFormsYear), formId),
            getPrefilledDataForHarrisForm(formId, propertyId, ownerId, year),
            getNewFormEntries(formId)
        ]);

        selectedFormDispatch({
            type: SelectedFormActionsEnum.FORM,
            payload: {
                form: formResponse.data,
            }
        });

        selectedFormDispatch({
            type: SelectedFormActionsEnum.FORM_SETTINGS,
            payload: {
                formSettings: formSettingsResponse.data,
            },
        });

        selectedFormDispatch({
            type: SelectedFormActionsEnum.PREFILLED_DATA,
            payload: {
                pacsFormAssets: (prefilledDataResponse.data?.assetsJson !== '' && prefilledDataResponse.data?.assetsJson )
                                    ? JSON.parse(prefilledDataResponse.data?.assetsJson)
                                    : [],
                formFields: prefilledDataResponse.data?.fields,
            },
        });

        selectedFormDispatch({
            type: SelectedFormActionsEnum.FORM_ENTRY,
            payload: {
                formEntry: { ...formEntryResponse.data.formEntry, initiatedBy: initiatedBy },
            },
        });

        setLoading(selectedFormDispatch);
    } catch (e) {
        const error = e instanceof AxiosError ? e.response?.data : e;
        temporaryError(selectedFormDispatch, error);
    }
}

export function getSelectedFormEntryDataHelper(
    selectedFormDispatch: (value: GenericActionType<SelectedFormType>) => void,
    formId: number,
    formEntryId: number
) {
    setLoading(selectedFormDispatch, true);

    Promise.all([
        getFormById(formId),
        getFormSettings(parseInt(settings.onlineFormsYear), formId),
        getFormEntryDataById(formEntryId),
        getFormFieldsByFormEntryId(formEntryId),
        getFormEntryTimelines(formEntryId)
    ])
        .then((response) => {

            selectedFormDispatch({
                type: SelectedFormActionsEnum.FORM,
                payload: {
                    form: response[0].data,
                }
            });

            selectedFormDispatch({
                type: SelectedFormActionsEnum.FORM_SETTINGS,
                payload: {
                    formSettings: response[1].data,
                },
            });

            selectedFormDispatch({
                type: SelectedFormActionsEnum.FORM_ENTRY,
                payload: {
                    formEntry: response[2].data,
                },
            });

            selectedFormDispatch({
                type: SelectedFormActionsEnum.FORM_TIMELINES,
                payload: {
                    formTimelines: response[4].data,
                }
            });

            selectedFormDispatch({
                type: SelectedFormActionsEnum.FORM_FIELDS,
                payload: {
                    formFields: response[3].data,
                },
            });

            setLoading(selectedFormDispatch);
        })
        .catch((error: AxiosError) =>
            temporaryError(selectedFormDispatch, error.response?.data)
        );
}

export function getPriorYearFormFieldsHelper(
    selectedFormDispatch: (value: GenericActionType<SelectedFormType>) => void,
    setFormFourteenthSectionDataFields: (form: IBppFormFourteenthSectionInterface) => void,
    formId: number,
    accountNumber: string,
    accessCode: string,
    formFourteenthSectionDataFields?: IBppFormFourteenthSectionInterface
) {
    setLoading(selectedFormDispatch, true);
    Promise.all([
        getPriorYearFormFieldsByFormId(formId, accessCode, accountNumber)
    ])
        .then((response) => {
            if (response[0].data) {
                const filesArray: Array<IFormFilesInterface> = formFourteenthSectionDataFields?.SupportingFiles ?? [];
                filesArray?.push(response[0].data as IFormFilesInterface);

                setFormFourteenthSectionDataFields({
                    ...formFourteenthSectionDataFields,
                    SupportingFiles: filesArray,
                    PriorYearRenditionAdded: (response[0].data as IFormFilesInterface).name
                });
                temporarySuccessMessage(selectedFormDispatch, "Prior year rendition was successfully linked to this application.");
            } else {
                temporaryError(selectedFormDispatch, "No prior year rendition could be found for this account.");
            }
            setLoading(selectedFormDispatch);
        })
        .catch((error: AxiosError) =>
            temporaryError(selectedFormDispatch, error.response?.data)
        );
}

export function saveFormHelper
(selectedFormDispatch: (value: GenericActionType<SelectedFormType>) => void,
   formName: string,
   formEntry: IFormEntryModel,
   formFields: Array<IFormFiledInterface>,
   navigateFunction?: NavigateFunction
) {
    const data = {
        formEntry,
        formFields
    }
    setLoading(selectedFormDispatch, true);

    return saveForm(data).then((response) => {
        if (formEntry.accessCode && formEntry.pdfBase64Url && formEntry.submissionStatus == "Submitted") {
            saveFormPdfHelper(selectedFormDispatch, formEntry.accessCode, formEntry.pdfBase64Url);
        }

        if (navigateFunction) {
            navigateToFormEntryHelper(navigateFunction, formName, response.data.id);
        }

        temporarySuccessMessage(selectedFormDispatch, `Form ${formEntry?.submissionStatus == "Submitted" ? "Submitted" : "Saved"} successfully!`);

        if (formEntry.submissionStatus === submitted) {
            getSelectedFormEntryDataHelper(selectedFormDispatch, response.data.formId, response.data.id);
        }

        return response;
    })
    .catch((error: AxiosError) => {
            setLoading(selectedFormDispatch);
            temporaryError(selectedFormDispatch, error.response?.data)
        }
    );
}

function navigateToFormEntryHelper(navigateFunction: NavigateFunction, formEntryName: string, formEntryId: number, options?: NavigateOptions){
    navigateFunction(`/home/forms/${formEntryName}/form-entry/${formEntryId}`, options);
}

export function saveFormPdfHelper(
    selectedFormDispatch: (value: GenericActionType<SelectedFormType>) => void,
    accessCode:string, base64Data: string) {

    setLoading(selectedFormDispatch, true);

    saveFormPdf(accessCode, base64Data).then((response: any) => {
        setLoading(selectedFormDispatch);
        return response;
    })
        .catch((error: AxiosError) => {
                setLoading(selectedFormDispatch);
                temporaryError(selectedFormDispatch, error.response?.data)
            }
        );
}

export function appendSupportingDocumentsToPdfHelper(
    selectedFormDispatch: (value: GenericActionType<SelectedFormType>) => void,
    accessCode:string,
    base64Data: string,
    documents: IFormFilesInterface[],
): Promise<string> {

    setLoading(selectedFormDispatch, true);

    return appendSupportingDocumentsToPdf(accessCode, documents, base64Data).then((response: AxiosResponse<string, any>) => {
        setLoading(selectedFormDispatch);
        return response.data;
    });
}

export function saveSupportingDocumentsHelper(files: IUpdateSupportingDocuments[]): Promise<IFormFilesInterface[]> {
    const formData = new FormData();
    if (files.length) {
        formData.append("FileId", files[0].Id);
        formData.append(`AccessCode`, files[0].AccessCode);
        formData.append(`FieldKey`, files[0].FieldKey);
    }
    files.forEach(element => {
        if (element?.Files?.getRawFile) {
            const rawFile = element.Files.getRawFile();
            formData.append(`Files`, rawFile);
        }
    });
    return saveSupportingDocuments(formData).then(response => {
        return response.data
    });
}

// export function removeSupportingDocumentsHelper(formId: number,
//     files: IUpdateSupportingDocuments): Promise<IFormFiledInterface> {
//     const formData = new FormData();
//     formData.append("FileId", files.Id);
//     formData.append("FileNames", files.fileName!);
//     formData.append("AccessCode", files.AccessCode);
//     formData.append("FieldKey", files.FieldKey)
//     return removeSupportingDocuments(formId, formData).then(
//         (response: AxiosResponse<IFormFiledInterface, any>) => {
//             return response.data
//         });
// }

export function ClearFileArray<T>(
    formId: number,
    selectedFormDispatch: (value: GenericActionType<SelectedFormType>) => void,
    filesArrayData: Array<IFormFilesInterface>,
    formEntryAccessCode: string,
    fieldName: string,
    setFormDataFields: (form: T) => void,
    formDataFields: T,
    setUploadFilesArray: (array: Array<UploadFileInfo>) => void
) {
    setFormDataFields({
        ...formDataFields,
        [fieldName]: [],
    });
    setUploadFilesArray([]);
}
