import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "./BPPForm.module.scss";
import {Label} from "@progress/kendo-react-labels";
import {
    Checkbox,
    CheckboxChangeEvent,
    Input,
    InputChangeEvent,
    TextAreaChangeEvent
} from "@progress/kendo-react-inputs";
import {IFormFiledInterface} from "../../../../../interfaces/form-filed.interface";
import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import {
    IBppFormFourthSectionInterface
} from "../../../../../interfaces/forms/BPPForm/bpp-form-fourth-section.interface";
import {DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import {useEffect, useRef, useState} from "react";
import {
    IBppFormSecondSectionInterface
} from "../../../../../interfaces/forms/BPPForm/bpp-form-second-section.interface";
import SectionTitle from "../Shared/SectionTitle";
import { IBppFormFourteenthSectionInterface } from "src/interfaces/forms/BPPForm/bpp-form-fourteenth-section.interface";

interface GeneralFormType {
    isAdminPreview?: boolean;
    setFormDataFields: (form: IBppFormFourthSectionInterface) => void;
    formDataFields?:  IBppFormFourthSectionInterface;
    secondSectionDataFields?: IBppFormSecondSectionInterface;
    fourteenthSectionDataFields?: IBppFormFourteenthSectionInterface;
    formSettings?: IFormSettingModel;
    getPriorYearFormFieldsHandler: (event: React.MouseEvent<HTMLElement>) => void;
    setFormIsValid: (value: boolean) => void;
}

export default function BPPFormFourthSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, isAdminPreview, getPriorYearFormFieldsHandler,
        setFormIsValid, secondSectionDataFields, fourteenthSectionDataFields } = props;

    const formRef = useRef<HTMLFormElement>(null);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={4} name={"Prior Year Rendition"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent,
        field: string,
        group?: string
    ) {
        if (group) {
            setFormDataFields({
                ...formDataFields,
                [group]: {
                    ...(formDataFields as any)?.[group],
                    [field]: event.value
                }
            } as IBppFormFourthSectionInterface);
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            } as IBppFormFourthSectionInterface);
        }
    }

    function changeValidator() {
        if(formRef.current) {
            setIsValid(formRef.current.checkValidity());
            setFormIsValid(formRef.current.checkValidity());
        }
    }

    function onTouch(){
        if(!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields, secondSectionDataFields ,formRef.current]);


    return <>
        <PanelBar keepItemsMounted={true} style={{border:"none"}}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <Label className={classes.Label}>
                                Affirmation of Prior Year Rendition
                            </Label>
                            <div className={`${classes.itemFields} ${classes.bordered}`}>
                                <div className={`${classes.fieldsGroupW100}`}>
                                    <div className={classes.checkboxGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly || !secondSectionDataFields?.AccountNumber}
                                            className={classes.Checkbox}
                                            value={formDataFields?.AssetsWereSameAsPreviousYear}
                                            label={"Check this box if your assets were exactly the same as last year’s rendition form.\n"}
                                            onChange={e => changeValueHandler(e, "AssetsWereSameAsPreviousYear")}
                                        />
                                    </div>
                                    {formDataFields?.AssetsWereSameAsPreviousYear && <div className={`${classes.fieldsGroupW100}`}>
                                        <div className={`${classes.alert} ${classes.alertWarning}`}>
                                            If your prior year rendition was filed using this online application, you can
                                            attach last year's rendition by clicking the button below
                                        </div>

                                        <br/>
                                        {(!isAdminPreview && !formSettings?.isReadOnly) && <>
                                            <div className={`${classes.fieldsGroup} ${classes.fieldsGroupLeft}`}>
                                                <Label className={classes.Label}>
                                                    Account Number
                                                </Label>
                                                <div className="inputsGroup">
                                                    {!secondSectionDataFields?.IsNewProperty &&
                                                        <Input
                                                            className={classes.kTextbox}
                                                            disabled={true}
                                                            value={secondSectionDataFields?.AccountNumber}
                                                            name={"AccountNumber"}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            <button
                                                disabled={secondSectionDataFields?.IsNewProperty === true ||
                                                    !secondSectionDataFields?.AccountNumber ||
                                                    !!fourteenthSectionDataFields?.PriorYearRenditionAdded ||
                                                    !isValid }
                                                className={`${classes.button} ${classes.buttonAddress} ${classes.linkToPrior}`}
                                                onClick={e => getPriorYearFormFieldsHandler(e)}>
                                                <i className="fa fa-file-o fa-fw"></i> Link to Prior Year Rendition
                                            </button>
                                            </> }
                                        <br />
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
