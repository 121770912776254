import { ReactNode } from "react";
import shared from "./PDF-Shared-style.module.scss";

export const DefaultBlankRowsCount = 1;
export function GetBlankRows(tableRowCount: number | undefined, columnsCount: number, RowsToApply?: number) : ReactNode {
    let rowsCount = (RowsToApply ?? DefaultBlankRowsCount) - (tableRowCount ?? 0);
    if (rowsCount < 1) {
        return;
    }

    let rows = [];
    for (var i = 0; i < rowsCount!; i++) {
      let columns = [];
      for (let j = 0; j < columnsCount; j++) {
        columns.push(<td>&nbsp;</td>);
      }
      rows.push(<tr className={shared.BlankRow}>{columns}</tr>);
    }
    return rows;
}