export interface DynamicConfig {
  apiRoot: string;
  signalRApiRoot: string;
  applicationManagementPortalRoot: string;
  uiRoot: string;
  propertySearchRoot: string;
}

export const defaultConfig: DynamicConfig = {
  apiRoot: "",
  signalRApiRoot: "",
  applicationManagementPortalRoot: "",
  uiRoot: "",
  propertySearchRoot: "",
};

class GlobalConfig {
  config: DynamicConfig = defaultConfig;

  public get(): DynamicConfig {
    return this.config;
  }

  public set(value: DynamicConfig): void {
    this.config = value;
  }
}

export const globalConfig = new GlobalConfig();

export const globalConfigUrl = "/config.json";
