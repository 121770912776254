import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "./WildlifeManagement.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { DatePickerChangeEvent, CalendarChangeEvent, Calendar } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, RadioGroupChangeEvent, Input } from "@progress/kendo-react-inputs";
import { FormValidation } from "../InputValidator";
import { Error, Label } from "@progress/kendo-react-labels";
import { CustomDatePicker } from "../CustomInputs/CustomDatePickers/CustomDatePicker";
import { PhoneNumberInput } from "../CustomInputs/PhoneNumberInputs/PhoneNumberInput";
import { StatesInput } from "../CustomInputs/StatesInput";
import { ZipCodeInput } from "../CustomInputs/ZipCodeInput";
import { IWildlifeManagementFirstSection } from "src/interfaces/forms/WildlifeManagement/wildlife-management-first-section.interface";
import { CustomOnlyYearCalendarHelper } from "src/pages/Shared/DatePickers/CustomCalendars";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IWildlifeManagementFirstSection) => void;
    formDataFields?:  IWildlifeManagementFirstSection,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function WildlifeManagementFirstSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, isAdminPreview, setFormIsValid } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const { CustomOnlyYearCalendar } = CustomOnlyYearCalendarHelper();
    const [taxYearInputValid, setTaxYearInputValid] = useState<boolean>(false);
    const [phoneNumberInputValid, setPhoneNumberInputValid] = useState<boolean>(false);
    const [stateInputValid, setStateInputValid] = useState<boolean>(false);
    const [zipCodeInputValid, setZipCodeInputValid] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator } = FormValidation(formRef);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} part={"I"} name={"Owner Information"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string,
        group?: string
    ) {
        if (group) {
            setFormDataFields({
                ...formDataFields,
                [group]: {
                    ...(formDataFields as any)?.[group],
                    [field]: event.value
                }
            });
        } 
        if (field == "TaxYear") {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value ? event.value.getFullYear().toString() : undefined
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    function changeValidator() {
        if (formRef.current) {
            const isValid = formRef.current.checkValidity() &&
                phoneNumberInputValid &&
                taxYearInputValid &&
                stateInputValid &&
                zipCodeInputValid;
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if(!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current, phoneNumberInputValid,
        taxYearInputValid, stateInputValid, zipCodeInputValid]);

    return (
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={classes.itemFields}>
                                <Label className={classes.Label}>
                                    Wildlife Management Annual Report year
                                </Label>
                                <CustomDatePicker
                                    fieldName={"TaxYear"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    formDataFields={formDataFields}
                                    setFormDataFields={setFormDataFields}
                                    onlyYear={true}
                                    CustomCalendar={CustomOnlyYearCalendar}
                                    setIsValidField={setTaxYearInputValid}
                                    validField={taxYearInputValid}
                                    defaultValueDate={formDataFields?.TaxYear}
                                />
                            </div>
                        </div>                            
                        <div className={`${classes.item} ${classes.bordered}`}>
                            <div className={classes.itemFields}>
                                <div className={`${classes.fieldsGroup} ${classes.fieldsGroupW50} ${classes.column}`}>
                                    <Label className={classes.Label}>
                                        Account Number (if known, otherwise put N/A)
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={false}
                                        name={"AccountNumber"}
                                        placeholder={"Account Number"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.AccountNumber}
                                        onChange={(e)=> changeValueHandler(e, "AccountNumber")}
                                    />
                                    <Label className={classes.Label}>
                                        Owner&apos;s Name
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={false}
                                        name={"OwnersName"}
                                        placeholder={"Owner's Name"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.OwnersName}
                                        onChange={(e)=> changeValueHandler(e, "OwnersName")}
                                    />
                                    <Label className={classes.Label}>
                                        Phone Number
                                    </Label>
                                    <PhoneNumberInput
                                        required={false}
                                        setFormDataFields={setFormDataFields}
                                        fieldName={"PhoneNumber"}
                                        placeholder={"Phone Number"}
                                        formDataFields={formDataFields}
                                        isReadOnly={formSettings?.isReadOnly}
                                        setIsValidField={setPhoneNumberInputValid}
                                        validField={phoneNumberInputValid}
                                    />
                                    <Label className={classes.Label}>
                                        Tract Name
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={false}
                                        name={"TractName"}
                                        placeholder={"Tract Name"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.TractName}
                                        onChange={(e)=> changeValueHandler(e, "TractName")}
                                    />
                                    <Label className={classes.Label}>
                                        Majority County
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={false}
                                        name={"MajorityCounty"}
                                        placeholder={"Majority County"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.MajorityCounty}
                                        onChange={(e)=> changeValueHandler(e, "MajorityCounty")}
                                    />
                                    <Label className={classes.Label}>
                                        Additional Counties (if any)
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={false}
                                        name={"AdditionalCountiesIfAny"}
                                        placeholder={"Additional Counties (if any)"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.AdditionalCountiesIfAny}
                                        onChange={(e)=> changeValueHandler(e, "AdditionalCountiesIfAny")}
                                    />
                                </div>
                                <div className={`${classes.fieldsGroup} ${classes.fieldsGroupW50} ${classes.column}`}>
                                    <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                        <p>Mailing Address</p>
                                    </div>
                                    <div className={classes.itemFields}>
                                    <Label className={classes.Label}>
                                        Address Line 1
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={false}
                                        name={"Line1"}
                                        placeholder={"Line 1"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.MailingAddressLine1}
                                        onChange={(e)=> changeValueHandler(e, "MailingAddressLine1")}
                                    />
                                    <Label className={classes.Label}>
                                        Address Line 2
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={false}
                                        name={"Line2"}
                                        placeholder={"Line 2"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.MailingAddressLine2}
                                        onChange={(e)=> changeValueHandler(e, "MailingAddressLine2")}
                                    />
                                    </div>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                        <div className={classes.fieldsGroupW30}>
                                            <Label className={classes.Label}>
                                                City
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                required={false}
                                                name={"City"}
                                                placeholder={"City"}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.MailingAddressCity}
                                                onChange={(e)=> changeValueHandler(e, "MailingAddressCity")}
                                            />
                                        </div>
                                        <div className={classes.fieldsGroupW30}>
                                            <Label className={classes.Label}>
                                                State
                                            </Label>
                                            <StatesInput
                                                setFormDataFields={setFormDataFields}
                                                fieldName={"MailingAddressState"}
                                                required={false}
                                                validField={stateInputValid}
                                                setIsValidField={setStateInputValid}
                                                formDataFields={formDataFields}
                                                isReadOnly={formSettings?.isReadOnly}
                                            />
                                            {!inputValidator("MailingAddressState") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                        <div className={classes.fieldsGroupW30}>
                                            <Label className={classes.Label}>
                                                Zip
                                            </Label>
                                            <ZipCodeInput
                                                setFormDataFields={setFormDataFields}
                                                fieldName={"MailingAddressZip"}
                                                placeholder={"Zip"}
                                                required={false}
                                                formDataFields={formDataFields}
                                                isReadOnly={formSettings?.isReadOnly}
                                                setIsValidField={setZipCodeInputValid}
                                                validField={zipCodeInputValid}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    )
}