import { IStreamsideTechniquesUsed,
    IHerbaceousPlantEstablishmentTypes,
    IDikeLeveeConstructionManagementTypes,
    IEstablishWaterDiversionTypes,
    IEstablishWaterDiversionSlopeTypes,
    IEstablishWaterDiversionVegetatedTypes } from "./erosion-control.interface-types";

export interface IWildlifeManagementFourthSectionErosionControl {
    PondConstruction?: boolean;
    PondConstructionSurfaceArea?: number;
    PondConstructionLengthOfDam?: number;
    PondConstructionNumberOfCubicYardsOfSoilDisplaced?: number;
    PondConstructionPlannedDateOfConstruction?: string;
    PondConstructionAdditionalInformation?: string;
    GullyShaping?: boolean;
    GullyShapingTotalAcresToBeTreated?: number;
    GullyShapingSeedingMixUsed?: string;
    GullyShapingAcresTreatedAnnually?: number;
    GullyShapingPlannedDateOfConstruction?: string;
    GullyShapingAdditionalInformation?: string;
    StreamsidePondWetlandRevegetation?: boolean;
    StreamsidePondWetlandRevegetationTechniquesUsed?: IStreamsideTechniquesUsed;
    StreamsidePondWetlandRevegetationTechniquesUsedOther?: string;
    StreamsidePondWetlandRevegetationPlannedDateOfConstruction?: string;
    StreamsidePondWetlandRevegetationAdditionalInformation?: string;
    HerbaceousPlantEstablishment?: boolean;
    HerbaceousPlantEstablishmentTypes?: IHerbaceousPlantEstablishmentTypes;
    HerbaceousPlantEstablishmentAdditionalInformation?: string;
    DikeLeveeConstructionManagement?: boolean;
    DikeLeveeConstructionManagementTypes?: IDikeLeveeConstructionManagementTypes;
    DikeLeveeConstructionManagementAdditionalInformation?: string;
    EstablishWaterDiversion?: boolean;
    EstablishWaterDiversionTypes?: IEstablishWaterDiversionTypes;
    EstablishWaterDiversionSlope?: IEstablishWaterDiversionSlopeTypes;
    EstablishWaterDiversionLength?: number;
    EstablishWaterDiversionVegetated?: boolean;
    EstablishWaterDiversionVegetatedType?: IEstablishWaterDiversionVegetatedTypes;
    EstablishWaterDiversionNative?: string;
    EstablishWaterDiversionCrop?: string;
    EstablishWaterDiversionAdditionalInformation?: string;
}

export const WildlifeManagementFourthSectionErosionControlInterface = (): IWildlifeManagementFourthSectionErosionControl => ({
    PondConstruction: undefined,
    PondConstructionSurfaceArea: undefined,
    PondConstructionLengthOfDam: undefined,
    PondConstructionNumberOfCubicYardsOfSoilDisplaced: undefined,
    PondConstructionPlannedDateOfConstruction: undefined,
    PondConstructionAdditionalInformation: undefined,
    GullyShaping: undefined,
    GullyShapingTotalAcresToBeTreated: undefined,
    GullyShapingSeedingMixUsed: undefined,
    GullyShapingAcresTreatedAnnually: undefined,
    GullyShapingPlannedDateOfConstruction: undefined,
    GullyShapingAdditionalInformation: undefined,
    StreamsidePondWetlandRevegetation: undefined,
    StreamsidePondWetlandRevegetationTechniquesUsed: undefined,
    StreamsidePondWetlandRevegetationTechniquesUsedOther: undefined,
    StreamsidePondWetlandRevegetationPlannedDateOfConstruction: undefined,
    StreamsidePondWetlandRevegetationAdditionalInformation: undefined,
    HerbaceousPlantEstablishment: undefined,
    HerbaceousPlantEstablishmentTypes: undefined,
    HerbaceousPlantEstablishmentAdditionalInformation: undefined,
    DikeLeveeConstructionManagement: undefined,
    DikeLeveeConstructionManagementTypes: undefined,
    DikeLeveeConstructionManagementAdditionalInformation: undefined,
    EstablishWaterDiversion: undefined,
    EstablishWaterDiversionTypes: undefined,
    EstablishWaterDiversionSlope: undefined,
    EstablishWaterDiversionLength: undefined,
    EstablishWaterDiversionVegetated: undefined,
    EstablishWaterDiversionVegetatedType: undefined,
    EstablishWaterDiversionNative: undefined,
    EstablishWaterDiversionCrop: undefined,
    EstablishWaterDiversionAdditionalInformation: undefined
})