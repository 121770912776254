import { useState } from "react";
import { usePublicContext } from "src/context/public/public-context";
import { PublicActionsEnum } from "src/context/public/public-actions";

export const RequestHelpButton = () => {
  const { publicDispatch } = usePublicContext();

  function loadFormEntryChat() {
    publicDispatch({
      type: PublicActionsEnum.SHOW_CREATE_DIALOG,
      payload: { showCreateDialog: false },
    });
    publicDispatch({
      type: PublicActionsEnum.SHOW_FORM_DIALOG,
      payload: { showFormDialog: true },
    });
  }

  return (
    <>
      <button className="button" onClick={loadFormEntryChat}>
        <i className="fa fa-comments fa-fw"></i>
        Request Help
      </button>
    </>
  );
};
