export type IExemptionPropertyDamagedFormSecondSection = {
    Name?: string;
    PersonalId?: string;
    PrimaryPhoneNumber?: string;
    SecondaryContactNumber?: string;
    EmailAddress?: string;
    MailingAddressLine1?: string;
    MailingAddressLine2?: string;
    MailingAddressCity?: string;
    MailingAddressState?: string;
    MailingAddressZip?: string;
    AlternateMailingAddressLine1?: string;
    AlternateMailingAddressLine2?: string;
    AlternateMailingAddressCity?: string;
    AlternateMailingAddressState?: string;
    AlternateMailingAddressZip?: string;
}

export const ExemptionPropertyDamagedFormSecondSectionInterface = (): IExemptionPropertyDamagedFormSecondSection => ({
    Name: undefined,
    PersonalId: undefined,
    PrimaryPhoneNumber: undefined,
    SecondaryContactNumber: undefined,
    EmailAddress: undefined,
    MailingAddressLine1: undefined,
    MailingAddressLine2: undefined,
    MailingAddressCity: undefined,
    MailingAddressState: undefined,
    MailingAddressZip: undefined,
    AlternateMailingAddressLine1: undefined,
    AlternateMailingAddressLine2: undefined,
    AlternateMailingAddressCity: undefined,
    AlternateMailingAddressState: undefined,
    AlternateMailingAddressZip: undefined
})