import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useTranslation } from "react-i18next";

export interface CustomDialogPropsType {
  title?: string;
  onClose?: () => void;
  onSubmit?: any;
  content: JSX.Element;
  actionButtons?: JSX.Element;
  useCustomActionBar?: boolean;
  width?: number | string;
  height?: number;
  className?: string;
  submitText?: string;
  cancelText?: string;
}

const CustomDialog = (props: CustomDialogPropsType): JSX.Element => {
  const { t } = useTranslation();
  const {
    useCustomActionBar,
    title,
    onClose,
    className,
    width,
    height,
    content,
    actionButtons,
    cancelText,
    onSubmit,
    submitText,
  } = props;

  const defaultWidth = 500;
  const defaultHeight = 600;
  return (
    <Dialog
      title={title}
      onClose={onClose}
      className={className}
      width={width ?? defaultWidth}
      height={height ?? defaultHeight}
    >
      {content}
      {!useCustomActionBar && (
        <DialogActionsBar>
          {actionButtons}
          {!actionButtons && (
            <>
              <Button className="k-button" onClick={onClose}>
                {cancelText ?? t("form.close")}
              </Button>
              {onSubmit && (
                <Button className="default-btn" onClick={onSubmit}>
                  {submitText ?? t("form.submit")}
                </Button>
              )}
            </>
          )}
        </DialogActionsBar>
      )}
    </Dialog>
  );
};

export default CustomDialog;
