import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import React, {useEffect, useRef, useState} from "react";
import {
    Checkbox,
    CheckboxChangeEvent, Input,
    InputChangeEvent, 
    RadioGroupChangeEvent, 
    TextAreaChangeEvent
} from "@progress/kendo-react-inputs";
import {CalendarChangeEvent, DatePicker, DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {ComboBoxChangeEvent, DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./HomesteadExemption.module.scss";
import {
    IHomesteadExemptionSecondSectionInterface
} from "../../../../../interfaces/forms/HomesteadExemptionForm/homestead-exemption-second-section.interface";
import {Error, Label} from "@progress/kendo-react-labels";
import {UploadFileInfo} from "@progress/kendo-react-upload";
import {
    ClearFileArray} from "../../../../../context/SelectedForm/selected-form-context.helper";
import {IFormEntryModel} from "../../../../../interfaces/form-entry.interface";
import {GenericActionType} from "../../../../../context/generic-actions.type";
import {SelectedFormType} from "../../../../../context/SelectedForm/selected-form-reducer";
import {CheckboxGroup} from "../CustomInputs/CheckBoxes/CheckboxGroup";
import {CustomUpload} from "../CustomInputs/CustomUpload";
import {CustomDatePicker} from "../CustomInputs/CustomDatePickers/CustomDatePicker";
import {FormValidation} from "../InputValidator";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IHomesteadExemptionSecondSectionInterface) => void;
    formDataFields?:  IHomesteadExemptionSecondSectionInterface,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void,
    formEntry?: IFormEntryModel,
    selectedFormDispatch: (value: GenericActionType<SelectedFormType>) => void,
}

export default function HomesteadExemptionSecondSection(props: GeneralFormType): JSX.Element {
    const {setFormDataFields, formDataFields, formSettings, isAdminPreview, setFormIsValid, formEntry, selectedFormDispatch} = props;

    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const [IsGeneralHomesteadExemptionHelper, setIsGeneralHomesteadExemptionHelper] = useState<boolean>(false);
    const [IsIsDisabledPersonExemptionHelper, setIsDisabledPersonExemptionHelper] = useState<boolean>(false);
    const [IsAge65OrOlderExemptionHelper, setIsAge65OrOlderExemptionHelper] = useState<boolean>(false);
    const [IsDisabledVetExemptionHelper, setIsDisabledVetExemptionHelper] = useState<boolean>(false);
    const [IsDonatedResidenceExemptionHelper, setIsDonatedResidenceExemptionHelper] = useState<boolean>(false);
    const [IsSurvingSpouseOfKiaExemptionHelper, setIsSurvingSpouseOfKiaExemptionHelper] = useState<boolean>(false);
    const [IsSurvingSpouseOfFirstResponderHelper, setIsSurvingSpouseOfFirstResponderHelper] = useState<boolean>(false);
    const [IsOwnerOfPropertyCheckBoxGroupValid, setIsOwnerOfPropertyCheckBoxGroupValid] = useState<boolean>(false);
    const [files, setFiles] = useState<Array<UploadFileInfo>>([]);
    const [isAffidavitUploadIsValid, setIsAffidavitUploadIsValid] = useState<boolean>(true);
    const [IsPermanentTotalDisabilityCheckBoxGroupValid, setIsPermanentTotalDisabilityCheckBoxGroupValid] = useState<boolean>(true);
    const [isOneHundredPercentDisabledVeteranDocumentationUploadIsValid, setOneHundredPercentDisabledVeteranDocumentationUploadIsValid] = useState<boolean>(true);
    const [AffidavitFiles, setAffidavitFiles] = useState<Array<UploadFileInfo>>([]);
    const [OneHundredPercentDisabledVeteranDocumentationFiles, setOneHundredPercentDisabledVeteranDocumentationFiles] = useState<Array<UploadFileInfo>>([]);
    const [exemptionRequestedCheckBoxGroupRequired, setExemptionRequestedCheckBoxGroupRequired] = useState<boolean>(false);
    const [dateIsValid, setDateIsValid] = useState<boolean>(!(formDataFields?.IsSurvingSpouseOfKiaExemption || formDataFields?.IsSurvingSpouseOfFirstResponder));
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator, inputValidatorMessage, setStyles } = FormValidation(formRef);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={2} name={"Exemption(s) Requested (Select all that apply.)"}/>;

    useEffect(() =>{
        return () => {
            setFiles([]);
        }
    },[]);

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent
            | ComboBoxChangeEvent,
        field: string,
    ) {
        if (field === "SurvivingSpouseDateOfDeath"){
            setFormDataFields({
                ...formDataFields,
                [field]: event.value.toDateString()
            });
        } else if (field == "IsDisabledVetExemption" && event.value === false) {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value,
                "IsPermanentTotalDisability": undefined
            });
        } else if (field == "IsDonatedResidenceExemption" && event.value === false){
            setFormDataFields({
                ...formDataFields,
                [field]: event.value,
                DonatedResidenceExemptionPercentDisabilityRating: undefined,
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    useEffect(() => {
        if(!formDataFields?.IsAge65OrOlderExemption && formDataFields?.Affidavit && AffidavitFiles.length > 0 && formEntry?.accessCode){
            ClearFileArray<IHomesteadExemptionSecondSectionInterface>(
                formEntry.formId,
                selectedFormDispatch,
                formDataFields.Affidavit,
                formEntry.accessCode,
                "Affidavit",
                setFormDataFields,
                formDataFields,
                setAffidavitFiles);
        }
    }, [formDataFields?.IsAge65OrOlderExemption]);

    useEffect(() => {
        if(!formDataFields?.IsDisabledVetExemption
            && formDataFields?.OneHundredPercentDisabledVeteranDocumentation
            && OneHundredPercentDisabledVeteranDocumentationFiles.length > 0 && formEntry?.accessCode){
            ClearFileArray<IHomesteadExemptionSecondSectionInterface>(
                formEntry.formId,
                selectedFormDispatch,
                formDataFields.OneHundredPercentDisabledVeteranDocumentation,
                formEntry.accessCode,
                "OneHundredPercentDisabledVeteranDocumentation",
                setFormDataFields,
                formDataFields,
                setAffidavitFiles);
        }
    }, [formDataFields?.IsDisabledVetExemption]);

    useEffect(() => {
       if(!formDataFields?.IsSurvingSpouseOfKiaExemption && !formDataFields?.IsSurvingSpouseOfFirstResponder){
           setFormDataFields({
               ...formDataFields,
               SurvivingSpouseDateOfDeath: undefined,
               SurvivingSpouseName: undefined
           })
       }
    }, [formDataFields?.IsSurvingSpouseOfKiaExemption,
        formDataFields?.IsSurvingSpouseOfFirstResponder])

    useEffect(() => {
        return () => {
            setAffidavitFiles([]);
            setOneHundredPercentDisabledVeteranDocumentationFiles([]);
        }
    }, []);

    useEffect(() => {
        if(formDataFields?.IsGeneralHomesteadExemption ||
            formDataFields?.IsDisabledPersonExemption ||
            formDataFields?.IsAge65OrOlderExemption ||
            formDataFields?.IsDisabledVetExemption ||
            formDataFields?.IsDonatedResidenceExemption ||
            formDataFields?.IsSurvingSpouseOfKiaExemption ||
            formDataFields?.IsSurvingSpouseOfFirstResponder){
            setExemptionRequestedCheckBoxGroupRequired(false);
        } else {
            setExemptionRequestedCheckBoxGroupRequired(true);
        }

    }, [formDataFields?.IsGeneralHomesteadExemption,
        formDataFields?.IsDisabledPersonExemption,
        formDataFields?.IsAge65OrOlderExemption,
        formDataFields?.IsDisabledVetExemption,
        formDataFields?.IsDonatedResidenceExemption,
        formDataFields?.IsSurvingSpouseOfKiaExemption,
        formDataFields?.IsSurvingSpouseOfFirstResponder]);

    function changeValidator() {
        if (formRef.current) {
            const isValid =
                isAffidavitUploadIsValid &&
                IsPermanentTotalDisabilityCheckBoxGroupValid &&
                isOneHundredPercentDisabledVeteranDocumentationUploadIsValid &&
                IsOwnerOfPropertyCheckBoxGroupValid &&
                !exemptionRequestedCheckBoxGroupRequired &&
                dateIsValid &&
                formRef.current.checkValidity();

            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch() {
        if (!isFormTouched) {
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [
        formDataFields,
        formRef.current,
        formRef.current?.elements,
        isAffidavitUploadIsValid,
        IsPermanentTotalDisabilityCheckBoxGroupValid,
        isOneHundredPercentDisabledVeteranDocumentationUploadIsValid,
        IsOwnerOfPropertyCheckBoxGroupValid,
        exemptionRequestedCheckBoxGroupRequired,
        dateIsValid
    ]);

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={classes.information}><p
                                className="ng-scope">Select the type of residence homestead exemption for which
                                you are applying for.</p>
                                <p className="ng-scope">A brief description of the qualifications for each type
                                    of exemption can be shown by clicking on the question mark beside each
                                    exemption name.</p>
                                For complete details regarding each type of
                                exemption and its specific qualifications, you should consult Tax Code Chapter
                                11, Taxable Property and Exemptions. You may call your county appraisal
                                district to determine what homestead exemptions are offered by the taxing units
                                in your area
                            </div>
                            <div className={classes.itemFields}>
                                <Label className={classes.Label}>
                                    Do you live in the property for which you are seeking this residence homestead exemption?
                                    <i className={"fa fa-asterisk text-danger"}></i>
                                </Label>
                                <div className={classes.inputsGroup}>
                                    <CheckboxGroup
                                        isAdminPreview={isAdminPreview}
                                        setFormDataFields={setFormDataFields}
                                        formDataFields={formDataFields}
                                        formSettings={formSettings}
                                        fieldName={"IsOwnerOfProperty"}
                                        fieldSecondName={"IsOwnerOfPropertySecond"}
                                        isRequired={true}
                                        checkboxesIsValid={IsOwnerOfPropertyCheckBoxGroupValid}
                                        setCheckboxesIsValid={setIsOwnerOfPropertyCheckBoxGroupValid}
                                    />
                                </div>
                                {!IsOwnerOfPropertyCheckBoxGroupValid && (
                                    <Error className={classes.fieldsGroupW100}>{validationMessage}</Error>
                                )}
                            </div>
                            <div className={classes.itemFields}>
                                <div className={classes.textHelper} onClick={() => {setIsGeneralHomesteadExemptionHelper(!IsGeneralHomesteadExemptionHelper)}}><i className="fa fa-question-circle"></i></div>
                                <div className={classes.fieldsGroupW100}>
                                    <div className={classes.checkboxGroup}>
                                        <Checkbox
                                            required={exemptionRequestedCheckBoxGroupRequired}
                                            name={"IsGeneralHomesteadExemption"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.IsGeneralHomesteadExemption}
                                            label={"GENERAL RESIDENCE HOMESTEAD EXEMPTION"}
                                            onChange={(e : CheckboxChangeEvent)=> changeValueHandler(e, "IsGeneralHomesteadExemption")}
                                        />
                                    </div>
                                </div>
                                {IsGeneralHomesteadExemptionHelper &&
                                    <div>
                                        <div className="font-bold ng-scope">
                                            (Tax Code Section 11.13(a) and (b)): You may qualify for this exemption if:
                                        </div>
                                        <ol>
                                            <li>you owned this property on Jan. 1</li>
                                            <li>you occupied it as your principal residence on Jan. 1</li>
                                            <li>you and your spouse do not claim a residence homestead exemption on any
                                                other property
                                            </li>
                                        </ol>
                                    </div> }
                            </div>
                            <div className={classes.itemFields}>
                                <div className={classes.textHelper} onClick={() => {setIsDisabledPersonExemptionHelper(!IsIsDisabledPersonExemptionHelper)}}><i className="fa fa-question-circle"></i></div>
                                <div className={classes.fieldsGroupW100}>
                                    <div className={classes.checkboxGroup}>
                                        <Checkbox
                                            required={exemptionRequestedCheckBoxGroupRequired}
                                            name={"IsDisabledPersonExemption"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.IsDisabledPersonExemption}
                                            label={"DISABLED PERSON EXEMPTION"}
                                            onChange={(e: any)=> changeValueHandler(e, "IsDisabledPersonExemption")}

                                        />
                                    </div>
                                </div>

                                {IsIsDisabledPersonExemptionHelper &&
                                <div>
                                    <div className="font-bold ng-scope">
                                        (Tax Code Section 11.13(c) and (d)): You may qualify for this exemption if
                                        you are under a disability for purposes of payment of disability insurance
                                        benefits under Federal Old-Age, Survivors, and Disability Insurance.
                                    </div>
                                    <ol className="ng-scope">
                                        <li>You cannot receive an age 65 or older exemption if you receive this
                                            exemption
                                        </li>
                                    </ol>
                                </div> }

                            </div>
                            <div className={classes.itemFields}>
                                <div className={classes.textHelper} onClick={() => {setIsAge65OrOlderExemptionHelper(!IsAge65OrOlderExemptionHelper)}}><i className="fa fa-question-circle"></i></div>
                                <div className={classes.fieldsGroupW100}>
                                    <div className={classes.checkboxGroup}>
                                        <Checkbox
                                            required={exemptionRequestedCheckBoxGroupRequired}
                                            name={"IsAge65OrOlderExemption"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.IsAge65OrOlderExemption}
                                            label={"AGE 65 OR OLDER EXEMPTION"}
                                            onChange={(e: any)=> changeValueHandler(e, "IsAge65OrOlderExemption")}
                                        />
                                    </div>

                                    {IsAge65OrOlderExemptionHelper &&
                                            <div ><b
                                                className="ng-scope">
                                                (Tax Code Section 11.13(c) and (d)):
                                            </b>
                                                This exemption is effective Jan. 1 of the tax year in which you become
                                                age
                                                65.
                                                <br className="ng-scope" />You cannot receive a disability exemption if
                                                    you receive this exemption. You may qualify if you are 65 years of
                                                    age or older.
                                            </div> }

                                    { formDataFields?.IsAge65OrOlderExemption &&
                                    <div className="inputsGroup">
                                        <div>
                                                    An applicant
                                                    for an age 65 or older or disabled exemption who is not specifically
                                                    identified on a deed or
                                                    other instrument recorded in the applicable real property records as
                                                    an owner of the residence homestead must provide an affidavit (see
                                                    last page) or
                                                    other compelling evidence establishing the applicant’s ownership of
                                                    an interest in the homestead.
                                        </div>
                                        <div>   In addition to
                                                the information provided on this form, an applicant for an age 65 or
                                                older or disabled exemption who is not specifically identified on a deed
                                                or
                                                other instrument recorded in the applicable real property records as an
                                                owner of the residence homestead must provide an affidavit (see last
                                                page) or
                                                other compelling evidence establishing the applicant’s ownership of an
                                                interest in the homestead.
                                        </div>
                                        <CustomUpload
                                            files={AffidavitFiles}
                                            setFiles={setAffidavitFiles}
                                            uploadIsValid={isAffidavitUploadIsValid}
                                            setUploadIsValid={setIsAffidavitUploadIsValid}
                                            formEntry={formEntry}
                                            formSettings={formSettings}
                                            isAdminPreview={isAdminPreview}
                                            setFormDataFields={setFormDataFields}
                                            formDataFields={formDataFields}
                                            fieldName={"Affidavit"}
                                            isRequired={true}
                                            selectedFormDispatch={selectedFormDispatch}
                                            multiple={true}
                                            isLabelRequired={false}
                                            labelText={"AFFIDAVIT FOR OWNER/APPLICANT WHO IS AGE 65 OR OLDER AND OWNERSHIP INTEREST NOT OF RECORD"}
                                        />
                                    </div> }
                                </div>
                            </div>
                            <div className={classes.itemFields}>
                                <div className={classes.textHelper} onClick={() => {setIsDisabledVetExemptionHelper(!IsDisabledVetExemptionHelper)}}><i className="fa fa-question-circle"></i></div>
                                <div className={classes.fieldsGroupW100}>
                                    <div className={classes.checkboxGroup}>
                                        <Checkbox
                                            required={exemptionRequestedCheckBoxGroupRequired}
                                            name={"IsDisabledVetExemption"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.IsDisabledVetExemption}
                                            label={"100 PERCENT DISABLED VETERANS EXEMPTION (or Surviving Spouse)"}
                                            onChange={(e: any)=> changeValueHandler(e, "IsDisabledVetExemption")}
                                        />
                                    </div>

                                    {IsDisabledVetExemptionHelper &&
                                        <div className={classes.itemFields}>
                                            <div><b
                                                className="ng-scope"> (Tax Code Section 11.131(b)). You may qualify if
                                                you are a disabled veteran who receives
                                                from the U.S. Department of Veterans Affairs or its successor:</b>
                                                <ul className="ng-scope">
                                                    <li>100 percent disability compensation due to a service-connected
                                                        disability; and
                                                    </li>
                                                    <li>a rating of 100 percent disabled or individual
                                                        unemployability.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    }

                                    { formDataFields?.IsDisabledVetExemption &&
                                    <div className="inputsGroup">
                                        <div className={classes.flexGroup}>
                                            <Label className={classes.Label}>
                                                <i className={"fa fa-asterisk text-danger"}></i>
                                                Is this a permanent total disability as determined by the U.S. Department of Veterans Affairs under 38 C.F.R. Section 4.15?
                                            </Label>
                                            <CheckboxGroup
                                                isAdminPreview={isAdminPreview}
                                                setFormDataFields={setFormDataFields}
                                                formDataFields={formDataFields}
                                                formSettings={formSettings}
                                                fieldName={"IsPermanentTotalDisability"}
                                                fieldSecondName={"IsPermanentTotalDisabilitySecond"}
                                                isRequired={formDataFields?.IsDisabledVetExemption}
                                                checkboxesIsValid={IsPermanentTotalDisabilityCheckBoxGroupValid}
                                                setCheckboxesIsValid={setIsPermanentTotalDisabilityCheckBoxGroupValid}
                                            />
                                            {!IsPermanentTotalDisabilityCheckBoxGroupValid && (
                                                <Error className={classes.w100}>{validationMessage}</Error>
                                            )}
                                        </div>
                                        <div className={classes.information}>
                                            An applicant for a 100 percent disabled veteran’s exemption or the surviving
                                            spouse of a disabled veteran
                                            who qualified for the 100 percent disabled veteran’s exemption must provide
                                            documentation from the United States Department of Veterans Affairs
                                            indicating
                                            that the veteran received 100 percent disability compensation due to a
                                            service-connected disability and had a rating of 100 percent disabled or
                                            individual
                                            unemployability.
                                        </div>
                                        <CustomUpload
                                            files={OneHundredPercentDisabledVeteranDocumentationFiles}
                                            setFiles={setOneHundredPercentDisabledVeteranDocumentationFiles}
                                            uploadIsValid={isOneHundredPercentDisabledVeteranDocumentationUploadIsValid}
                                            setUploadIsValid={setOneHundredPercentDisabledVeteranDocumentationUploadIsValid}
                                            formEntry={formEntry}
                                            formSettings={formSettings}
                                            isAdminPreview={isAdminPreview}
                                            setFormDataFields={setFormDataFields}
                                            formDataFields={formDataFields}
                                            fieldName={"OneHundredPercentDisabledVeteranDocumentation"}
                                            isRequired={true}
                                            selectedFormDispatch={selectedFormDispatch}
                                            multiple={true}
                                            isLabelRequired={false}
                                            labelText={"100 Percent Disabled Veteran Documentation"}
                                        />
                                    </div> }
                                </div>
                            </div>
                            <div className={classes.itemFields}>
                                <div className={classes.textHelper} onClick={() => {setIsDonatedResidenceExemptionHelper(!IsDonatedResidenceExemptionHelper)}}><i className="fa fa-question-circle"></i></div>
                                <div className={classes.fieldsGroupW100}>
                                    <div className={classes.checkboxGroup}>
                                        <Checkbox
                                            required={exemptionRequestedCheckBoxGroupRequired}
                                            name={"IsDonatedResidenceExemption"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.IsDonatedResidenceExemption}
                                            label={"DONATED RESIDENCE HOMESTEAD OF PARTIALLY DISABLED VETERAN"}
                                            onChange={(e: any)=> changeValueHandler(e, "IsDonatedResidenceExemption")}
                                        />

                                    </div>

                                    { IsDonatedResidenceExemptionHelper &&
                                        <div>
                                            <div><b
                                                className="ng-scope"> (Tax Code Section 11.132(b)). You may qualify
                                                if:</b>
                                                <ul className="ng-scope">
                                                    <li>you are a disabled veteran with a disability rating of less than
                                                        100 percent; and
                                                    </li>
                                                    <li>your residence homestead was donated to you by a charitable
                                                        organization at no cost to you or at some cost that is not more
                                                        than 50 percent
                                                        of the good faith estimate of the market value of the residence
                                                        homestead as of the date the donation is made.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    }

                                    { formDataFields?.IsDonatedResidenceExemption &&
                                    <div className={classes.checkboxGroup}>
                                        <Label className={classes.Label}>
                                            Percent Disability Rating
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        </Label>
                                        <Input
                                            required={formDataFields?.IsDonatedResidenceExemption}
                                            name={"DonatedResidenceExemptionPercentDisabilityRating"}
                                            className={classes.kTextbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.DonatedResidenceExemptionPercentDisabilityRating}
                                            onChange={(e: any)=> changeValueHandler(e, "DonatedResidenceExemptionPercentDisabilityRating")}
                                        />
                                        {!inputValidator("DonatedResidenceExemptionPercentDisabilityRating") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div> }
                                </div>
                            </div>
                            <div className={classes.itemFields}>
                                <div className={classes.textHelper} onClick={() => {setIsSurvingSpouseOfKiaExemptionHelper(!IsSurvingSpouseOfKiaExemptionHelper)}}><i className="fa fa-question-circle"></i></div>
                                <div className={classes.fieldsGroupW100}>
                                    <div className={classes.checkboxGroup}>
                                        <Checkbox
                                            required={exemptionRequestedCheckBoxGroupRequired}
                                            name={"IsSurvingSpouseOfKiaExemption"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.IsSurvingSpouseOfKiaExemption}
                                            label={"SURVIVING SPOUSE OF AN ARMED SERVICES MEMBER KILLED OR FATALLY INJURED IN THE LINE OF DUTY"}
                                            onChange={(e: any)=> changeValueHandler(e, "IsSurvingSpouseOfKiaExemption")}
                                        />
                                    </div>

                                    {IsSurvingSpouseOfKiaExemptionHelper &&
                                        <div >
                                            <div ><b
                                                className="ng-scope"> (Tax Code Section 11.133(b) and (c)). You may
                                                qualify if:</b>
                                                <ul className="ng-scope">
                                                    <li>you are the surviving spouse of a member of the U.S. armed
                                                        services who is killed in action; and
                                                    </li>
                                                    <li>you have not remarried since the death of the member of the
                                                        armed services.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>}

                                    { formDataFields?.IsSurvingSpouseOfKiaExemption &&
                                        <div className={classes.itemFields}>
                                            <Label className={`${classes.Label} ${classes.fieldsGroupW100}`}>
                                                Please provide the following information regarding your deceased spouse:
                                            </Label>
                                            <div className={classes.fieldsGroupW49}>
                                                <Label className={classes.Label}>
                                                    Name of Deceased Spouse
                                                    <i className={"fa fa-asterisk text-danger"}></i>
                                                </Label>
                                                <Input
                                                    required={formDataFields.IsSurvingSpouseOfKiaExemption}
                                                    name={"SurvivingSpouseName"}
                                                    className={classes.kTextbox}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.SurvivingSpouseName}
                                                    onChange={(e: any)=> changeValueHandler(e, "SurvivingSpouseName")}
                                                />
                                                {!inputValidator("SurvivingSpouseName") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                            <div className={classes.fieldsGroupW49}>
                                                <Label className={classes.Label}>
                                                    Date of Death
                                                    <i className={"fa fa-asterisk text-danger"}></i>
                                                </Label>
                                                <CustomDatePicker
                                                    fieldName={"SurvivingSpouseDateOfDeath"}
                                                    required={formDataFields?.IsSurvingSpouseOfKiaExemption}
                                                    disabled={formSettings?.isReadOnly}
                                                    formDataFields={formDataFields}
                                                    setFormDataFields={setFormDataFields}
                                                    onlyYear={false}
                                                    validField={dateIsValid}
                                                    setIsValidField={setDateIsValid}
                                                    defaultValueDate={formDataFields?.SurvivingSpouseDateOfDeath}
                                                />
                                            </div>
                                        </div>}
                                </div>
                            </div>
                            <div className={classes.itemFields}>
                                <div className={classes.textHelper} onClick={() => {setIsSurvingSpouseOfFirstResponderHelper(!IsSurvingSpouseOfFirstResponderHelper)}}><i className="fa fa-question-circle"></i></div>
                                <div className={classes.fieldsGroupW100}>
                                    <div className={classes.checkboxGroup}>
                                        <Checkbox
                                            required={exemptionRequestedCheckBoxGroupRequired}
                                            name={"IsSurvingSpouseOfFirstResponder"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.IsSurvingSpouseOfFirstResponder}
                                            label={"SURVIVING SPOUSE OF A FIRST RESPONDER KILLED IN THE LINE OF DUTY"}
                                            onChange={(e: any)=> changeValueHandler(e, "IsSurvingSpouseOfFirstResponder")}
                                        />
                                    </div>

                                    {IsSurvingSpouseOfFirstResponderHelper &&
                                        <div>
                                            <div><b
                                                className="ng-scope"> (Tax Code Section 11.133(b) and (c)). You may
                                                qualify if:</b>
                                                <ul className="ng-scope">
                                                    <li>you are the surviving spouse of a member of the U.S. armed
                                                        services who is killed in action; and
                                                    </li>
                                                    <li>you have not remarried since the death of the member of the
                                                        armed services.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>}

                                    { formDataFields?.IsSurvingSpouseOfFirstResponder &&
                                        <div className={classes.itemFields}>
                                            <Label className={`${classes.Label} ${classes.fieldsGroupW100}`}>
                                                Please provide the following information regarding your deceased spouse:
                                            </Label>

                                            <div className={classes.fieldsGroupW49}>
                                                <Label className={classes.Label}>
                                                    Name of Deceased Spouse
                                                    <i className={"fa fa-asterisk text-danger"}></i>
                                                </Label>
                                                <Input
                                                    required={formDataFields.IsSurvingSpouseOfFirstResponder}
                                                    name={"SurvivingSpouseName1"}
                                                    className={classes.kTextbox}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.SurvivingSpouseName}
                                                    onChange={(e: any)=> changeValueHandler(e, "SurvivingSpouseName")}
                                                />
                                                {!inputValidator("SurvivingSpouseName1") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                            <div className={classes.fieldsGroupW49}>
                                                <Label className={classes.Label}>
                                                    Date of Death
                                                    <i className={"fa fa-asterisk text-danger"}></i>
                                                </Label>
                                                <CustomDatePicker
                                                    fieldName={"SurvivingSpouseDateOfDeath"}
                                                    required={formDataFields?.IsSurvingSpouseOfFirstResponder}
                                                    disabled={formSettings?.isReadOnly}
                                                    formDataFields={formDataFields}
                                                    setFormDataFields={setFormDataFields}
                                                    onlyYear={false}
                                                    secondName={"SurvivingSpouseDateOfDeath1"}
                                                    validField={dateIsValid}
                                                    setIsValidField={setDateIsValid}
                                                    defaultValueDate={formDataFields?.SurvivingSpouseDateOfDeath}
                                                />
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
