export type IAddressChangeFormThirdSection = {
    PreviousAddressLine1?: string;
    PreviousAddressLine2?: string;
    PreviousAddressCity?: string;
    PreviousAddressState?: string;
    PreviousAddressZip?: string;
    NewAddressLine1?: string;
    NewAddressLine2?: string;
    NewAddressCity?: string;
    NewAddressState?: string;
    NewAddressZip?: string;
    ReasonForChange?: string;
}

export const AddressChangeFormThirdSectionInitialState = (): IAddressChangeFormThirdSection => ({
    PreviousAddressLine1: undefined,
    PreviousAddressLine2: undefined,
    PreviousAddressCity: undefined,
    PreviousAddressState: undefined,
    PreviousAddressZip: undefined,
    NewAddressLine1: undefined,
    NewAddressLine2: undefined,
    NewAddressCity: undefined,
    NewAddressState: undefined,
    NewAddressZip: undefined,
    ReasonForChange: undefined
})