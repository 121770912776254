import classes from '../../BPP-Form-PDF-style.module.scss';
import shared from '../../../PDF-Shared-style.module.scss';
import { IBppFormEleventhSectionInterface } from 'src/interfaces/forms/BPPForm/bpp-form-eleventh-section.interface';
import { ScheduleETable } from './ScheduleETable';
import { chunkArray } from 'src/utils/array';

const maxRowsPerPage = 32;

export const ScheduleETable1 = (props: {
  formDataFieldsEleventhSection?: IBppFormEleventhSectionInterface
}) => {
  const { formDataFieldsEleventhSection } = props;

  const furnituresAndFixtures = chunkArray(formDataFieldsEleventhSection?.["ScheduleE-FurnitureFixtures"] || [], maxRowsPerPage);
  const machineryEquipment = chunkArray(formDataFieldsEleventhSection?.["ScheduleE-MachineryEquipment"] || [], maxRowsPerPage);
  const officeEquipment = chunkArray(formDataFieldsEleventhSection?.["ScheduleE-OfficeEquipment"] || [], maxRowsPerPage);
  const numberOfTables = Math.max(furnituresAndFixtures.length, machineryEquipment.length, officeEquipment.length);

  const primaryTable = {
    furnituresAndFixtures: furnituresAndFixtures[0],
    machineryEquipment: machineryEquipment[0],
    officeEquipment: officeEquipment[0],
  }

  const additionalTables: Partial<typeof primaryTable>[] = [];

  for (let i = 1; i < numberOfTables; i++) {
    additionalTables.push({
      furnituresAndFixtures: furnituresAndFixtures[i],
      machineryEquipment: machineryEquipment[i],
      officeEquipment: officeEquipment[i],
    })
  }

  return (
    <>
      <div className={`keepTogether ${classes.ScheduleSection} ${shared.PrintTogether}`}>
        <br />
        <div className={shared.Step}>
          SCHEDULE E: FURNITURE, FIXTURES, MACHINERY, EQUIPMENT, COMPUTERS
        </div>
        <div className={shared.Row}>
          <div className={shared.Column12}>
            <div>
              Total (by year acquired) all furniture, fixtures, machinery,
              equipment and computers (new or used) still in possession on Jan. 1.
              Items received as gifts are to be listed in the same manner. If
              needed, attach additional sheets OR a computer generated listing of
              the information below.
            </div>
          </div>
          <br />
        </div>

        <div className={shared.Row}>
          <div className={`${shared.Column12} ${classes.TablesContainer}`}>
            <ScheduleETable
              group="ScheduleE-FurnitureFixtures"
              name="Furniture and Fixtures"
              rows={primaryTable.furnituresAndFixtures}
            />

            <ScheduleETable
              group="ScheduleE-MachineryEquipment"
              name="Machinery and Equipment"
              rows={primaryTable.machineryEquipment}
            />

            <ScheduleETable
              group="ScheduleE-OfficeEquipment"
              name="Office Equipment"
              rows={primaryTable.officeEquipment}
            />
          </div>
        </div>
      </div>

      {additionalTables.map((table) =>
        <div className={`keepTogether ${classes.ScheduleSection} ${shared.PrintTogether}`}>
          <div className={shared.Row}>
            <div className={`${shared.Column12} ${classes.TablesContainer}`}>
              <ScheduleETable
                group="ScheduleE-FurnitureFixtures"
                name="Furniture and Fixtures"
                rows={table.furnituresAndFixtures || []}
              />

              <ScheduleETable
                group="ScheduleE-MachineryEquipment"
                name="Machinery and Equipment"
                rows={table.machineryEquipment || []}
              />

              <ScheduleETable
                group="ScheduleE-OfficeEquipment"
                name="Office Equipment"
                rows={table.officeEquipment || []}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
