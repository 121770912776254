import "./SectionTitle.module.scss";

interface InformationSectionTitleType {
    name?: string;
    number?: number;
    part?: string;
}

interface SectionTitleType {
    isFormTouched?: boolean;
    isValid?: boolean;
    name?: string;
    number?: number;
    part?: string;
}

export default function SectionTitle(props: SectionTitleType): JSX.Element {
    const {isFormTouched, isValid, name, number, part} = props;
    const title = part ? `Part ${part}. ${name}` : (number ? `${number}. ${name}`: name);

    return <div><i className={"fa " +
        ((!isFormTouched && isValid) ?
        "fa-circle-o text-success" : 
        (!isValid ? "fa-asterisk text-danger" : "fa-check-circle text-success"))}>
        </i>{title}</div>
}

export function SectionInformationTitle(props: InformationSectionTitleType): JSX.Element {
    const {name, number, part} = props;
    const title = part ? `Part ${part}. ${name}` : `${number}. ${name}`;
    
    return <div><i className={`fa fa-check-circle text-success`}></i>{title}</div>
}
