import { IAgriculturalUseCategoriesOfLand, INonAgriculturalUse } from "../AgForm/ag-form-sixth-section.interface";

export type ITimberlandAppraisalFormFifthSection = {
    IsPropertyUsedForNonAgriculturalActivities?: boolean;
    AgriculturalUseCategoriesOfLand?: Array<IAgriculturalUseCategoriesOfLand>,
    AgriculturalUsePineForestAcres?: number,
    AgriculturalUseHardwoodForestAcres?: number,
    AgriculturalUseMixedHardwoodForestAcres?: number,
    NonAgriculturalUse?: Array<INonAgriculturalUse>
}

export const TimberlandAppraisalFormFifthSectionInterface = (): ITimberlandAppraisalFormFifthSection => ({
    IsPropertyUsedForNonAgriculturalActivities: undefined,
    AgriculturalUseCategoriesOfLand: undefined,
    NonAgriculturalUse: undefined,
    AgriculturalUsePineForestAcres: undefined,
    AgriculturalUseHardwoodForestAcres: undefined,
    AgriculturalUseMixedHardwoodForestAcres: undefined
})