import { ReactNode } from "react";
import shared from "./PDF-Shared-style.module.scss";

export function GetHeader(leftText: string, rightText: string) : ReactNode {
    return <>
        <div className={`${shared.Header} ${shared.Row} ${shared.SpaceBetween}`}>
            <div className={`${shared.Column6NoPadding} ${shared.TextLeft}`}>
              {leftText}
            </div>
            <div className={`${shared.Column6NoPadding} ${shared.TextRight}`}>
              {rightText}
            </div>
        </div>
    </>;
}