import {
  useState,
  useMemo,
  useEffect,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import {
  ScheduleAName,
  ScheduleBName,
  ScheduleCName,
  ScheduleDName,
  ScheduleEComputerEquipmentName,
  ScheduleEFurnitureFixturesName,
  ScheduleEMachineryEquipmentName,
  ScheduleEManufacturingEquipmentName,
  ScheduleEOfficeEquipmentName,
  ScheduleEOtherName,
  ScheduleFName,
} from "src/constants/form-fields-constants";
import { IFormFiledInterface } from "src/interfaces/form-filed.interface";
import { IAssetsInterface } from "src/interfaces/forms/BPPForm/assets-interface";
import { IBppFormEighthSectionInterface } from "src/interfaces/forms/BPPForm/bpp-form-eighth-section.interface";
import { IBppFormEleventhSectionInterface } from "src/interfaces/forms/BPPForm/bpp-form-eleventh-section.interface";
import { IBppFormNinthSectionInterface } from "src/interfaces/forms/BPPForm/bpp-form-ninth-section.interface";
import { IBPPFormPersonalPropertySegmentsInterface } from "src/interfaces/forms/BPPForm/bpp-form-personal-property-segments.interface";
import { IBppFormSeventhSectionInterface } from "src/interfaces/forms/BPPForm/bpp-form-seventh-section.interface";
import { IBppFormTenthSectionInterface } from "src/interfaces/forms/BPPForm/bpp-form-tenth-section.interface";
import { IBppFormTwelfthSectionInterface } from "src/interfaces/forms/BPPForm/bpp-form-twelfth-section.interface";
import { IDeletedAssetsInterface } from "src/interfaces/forms/BPPForm/deleted-assets-interface";
import { ISettings } from "src/interfaces/settings.interface";

type AssetsMap = {
  [ScheduleAName]: IBPPFormPersonalPropertySegmentsInterface[] | undefined
  [ScheduleBName]: IBPPFormPersonalPropertySegmentsInterface[] | undefined
  [ScheduleCName]: IBPPFormPersonalPropertySegmentsInterface[] | undefined
  [ScheduleDName]: IBPPFormPersonalPropertySegmentsInterface[] | undefined
  [ScheduleEFurnitureFixturesName]: IBPPFormPersonalPropertySegmentsInterface[] | undefined
  [ScheduleEMachineryEquipmentName]: IBPPFormPersonalPropertySegmentsInterface[] | undefined
  [ScheduleEOfficeEquipmentName]: IBPPFormPersonalPropertySegmentsInterface[] | undefined
  [ScheduleEComputerEquipmentName]: IBPPFormPersonalPropertySegmentsInterface[] | undefined
  [ScheduleEManufacturingEquipmentName]: IBPPFormPersonalPropertySegmentsInterface[] | undefined
  [ScheduleEOtherName]: IBPPFormPersonalPropertySegmentsInterface[] | undefined
  [ScheduleFName]: IBPPFormPersonalPropertySegmentsInterface[] | undefined
  NewAssets: IBPPFormPersonalPropertySegmentsInterface[] | undefined
  DeletedAssets: IBPPFormPersonalPropertySegmentsInterface[] | undefined
}

export function useSchedulesAndAssets(
  formFields: IFormFiledInterface[],
  pacsAssets: IBPPFormPersonalPropertySegmentsInterface[],
  isInitiatedByPropertySearch: boolean,
) {
  const [assets, setAssets] = useState<AssetsMap>({
    [ScheduleAName]: undefined,
    [ScheduleBName]: undefined,
    [ScheduleCName]: undefined,
    [ScheduleDName]: undefined,
    [ScheduleEFurnitureFixturesName]: undefined,
    [ScheduleEMachineryEquipmentName]: undefined,
    [ScheduleEOfficeEquipmentName]: undefined,
    [ScheduleEComputerEquipmentName]: undefined,
    [ScheduleEManufacturingEquipmentName]: undefined,
    [ScheduleEOtherName]: undefined,
    [ScheduleFName]: undefined,
    NewAssets: undefined,
    DeletedAssets: undefined,
  });

  const formSeventhSectionDataFields: IBppFormSeventhSectionInterface = useMemo(
    () => ({
      ScheduleA: assets[ScheduleAName],
    }),
    [assets[ScheduleAName]]
  );
  const setFormSeventhSectionDataFields = useCallback(
    (fields: IBppFormSeventhSectionInterface) =>
      setAssets((assets) => ({ ...assets, [ScheduleAName]: fields.ScheduleA })),
    []
  );

  const formEighthSectionDataFields: IBppFormEighthSectionInterface = useMemo(
    () => ({
      ScheduleB: assets[ScheduleBName],
    }),
    [assets[ScheduleBName]]
  );
  const setFormEighthSectionDataFields = useCallback(
    (fields: IBppFormEighthSectionInterface) =>
      setAssets((assets) => ({ ...assets, [ScheduleBName]: fields.ScheduleB })),
    []
  );

  const formNinthSectionDataFields: IBppFormNinthSectionInterface = useMemo(
    () => ({
      ScheduleC: assets[ScheduleCName],
    }),
    [assets[ScheduleCName]]
  );
  const setFormNinthSectionDataFields = useCallback(
    (fields: IBppFormNinthSectionInterface) =>
      setAssets((assets) => ({ ...assets, [ScheduleCName]: fields.ScheduleC })),
    []
  );

  const formTenthSectionDataFields: IBppFormTenthSectionInterface = useMemo(
    () => ({
      ScheduleD: assets[ScheduleDName],
    }),
    [assets[ScheduleDName]]
  );
  const setFormTenthSectionDataFields = useCallback(
    (fields: IBppFormTenthSectionInterface) =>
      setAssets((assets) => ({ ...assets, [ScheduleDName]: fields.ScheduleD })),
    []
  );

  const formEleventhSectionDataFields: IBppFormEleventhSectionInterface =
    useMemo(
      () => ({
        "ScheduleE-ComputerEquipment": assets[ScheduleEComputerEquipmentName],
        "ScheduleE-FurnitureFixtures": assets[ScheduleEFurnitureFixturesName],
        "ScheduleE-MachineryEquipment": assets[ScheduleEMachineryEquipmentName],
        "ScheduleE-ManufacturingEquipment":
          assets[ScheduleEManufacturingEquipmentName],
        "ScheduleE-OfficeEquipment": assets[ScheduleEOfficeEquipmentName],
        "ScheduleE-Other": assets[ScheduleEOtherName],
      }),
      [
        assets[ScheduleEComputerEquipmentName],
        assets[ScheduleEFurnitureFixturesName],
        assets[ScheduleEMachineryEquipmentName],
        assets[ScheduleEManufacturingEquipmentName],
        assets[ScheduleEOfficeEquipmentName],
        assets[ScheduleEOtherName],
      ]
    );
  const setFormEleventhSectionDataFields = useCallback(
    (fields: IBppFormEleventhSectionInterface) =>
      setAssets((assets) => ({
        ...assets,
        "ScheduleE-ComputerEquipment": fields[ScheduleEComputerEquipmentName],
        "ScheduleE-FurnitureFixtures": fields[ScheduleEFurnitureFixturesName],
        "ScheduleE-MachineryEquipment": fields[ScheduleEMachineryEquipmentName],
        "ScheduleE-ManufacturingEquipment":
          fields[ScheduleEManufacturingEquipmentName],
        "ScheduleE-OfficeEquipment": fields[ScheduleEOfficeEquipmentName],
        "ScheduleE-Other": fields[ScheduleEOtherName],
      })),
    []
  );

  const formTwelfthSectionDataFields: IBppFormTwelfthSectionInterface = useMemo(
    () => ({
      ScheduleF: assets[ScheduleFName],
    }),
    [assets[ScheduleFName]]
  );
  const setFormTwelfthSectionDataFields = useCallback(
    (fields: IBppFormTwelfthSectionInterface) =>
      setAssets((assets) => ({ ...assets, [ScheduleFName]: fields.ScheduleF })),
    []
  );

  const allAssets: IBPPFormPersonalPropertySegmentsInterface[] = useMemo(() => {
    if (!isInitiatedByPropertySearch) {
      return [];
    }
    return Object.values(assets)
      .reduce(
        (all: IBPPFormPersonalPropertySegmentsInterface[], a) =>
          all.concat(a || []),
        [] as IBPPFormPersonalPropertySegmentsInterface[]
      )
      .sort((a, b) => (a.Id || 0) - (b.Id || 0))
  }, [assets, isInitiatedByPropertySearch]);

  useEffect(() => {
    const schedules = parseBppSchedulesWithPacsData(formFields, pacsAssets);

    setAssets({
      [ScheduleAName]: schedules.SeventhSection.ScheduleA,
      [ScheduleBName]: schedules.EighthSection.ScheduleB,
      [ScheduleCName]: schedules.NinthSection.ScheduleC,
      [ScheduleDName]: schedules.TenthSection.ScheduleD,
      [ScheduleEFurnitureFixturesName]:
        schedules.EleventhSection["ScheduleE-FurnitureFixtures"],
      [ScheduleEMachineryEquipmentName]:
        schedules.EleventhSection["ScheduleE-MachineryEquipment"],
      [ScheduleEOfficeEquipmentName]:
        schedules.EleventhSection["ScheduleE-OfficeEquipment"],
      [ScheduleEComputerEquipmentName]:
        schedules.EleventhSection["ScheduleE-ComputerEquipment"],
      [ScheduleEManufacturingEquipmentName]:
        schedules.EleventhSection["ScheduleE-ManufacturingEquipment"],
      [ScheduleEOtherName]: schedules.EleventhSection["ScheduleE-Other"],
      [ScheduleFName]: schedules.TwelfthSection.ScheduleF,
      NewAssets: schedules.NewAssets,
      DeletedAssets: schedules.DeletedAssets,
    });
  }, [formFields, pacsAssets]);

  const addAsset = () => {
    const ids = allAssets.map((asset) => asset.Id || 0);
    let newId = Math.max(0, ...ids) + 1;

    setAssets((assets) => ({
      ...assets,
      NewAssets: [...(assets.NewAssets || []), { Id: newId, isNew: true }]
    }));
  }

  const updateAsset = (item: IBPPFormPersonalPropertySegmentsInterface) => {
    // remove old copy of item from assets
    removeItem(item.Id, assets);

    // add new updated copy of item to assets
    insertItem(item, assets);

    setAssets({ ...assets });
  }

  const removeAsset = (id: number) => {
    let removedItem = removeItem(id, assets);

    if (removedItem == undefined) {
      return;
    }

    if (!removedItem.isNew) {
      removedItem.removed = true;
      removedItem.disabled = true;
      assets.DeletedAssets = [...(assets.DeletedAssets || []), removedItem]
    }

    setAssets({ ...assets });
  }

  const restoreAsset = (id: number) => {
    const item = removeItem(id, assets);

    if (!item) {
      return;
    }

    item.removed = false;

    insertItem(item, assets);
    setAssets({ ...assets });
  };

  return {
    formSeventhSectionDataFields,
    setFormSeventhSectionDataFields,
    formEighthSectionDataFields,
    setFormEighthSectionDataFields,
    formNinthSectionDataFields,
    setFormNinthSectionDataFields,
    formTenthSectionDataFields,
    setFormTenthSectionDataFields,
    formEleventhSectionDataFields,
    setFormEleventhSectionDataFields,
    formTwelfthSectionDataFields,
    setFormTwelfthSectionDataFields,
    newAssets: assets.NewAssets,
    deletedAssets: assets.DeletedAssets,
    assets: allAssets,
    addAsset,
    updateAsset,
    removeAsset,
    restoreAsset,
  };
}

function parseBppSchedulesWithPacsData(
  formFields: IFormFiledInterface[],
  pacsFormAssets: IBPPFormPersonalPropertySegmentsInterface[]
) {
  const SeventhSection: IBppFormSeventhSectionInterface = { ScheduleA: [] };
  const EighthSection: IBppFormEighthSectionInterface = { ScheduleB: [] };
  const NinthSection: IBppFormNinthSectionInterface = { ScheduleC: [] };
  const TenthSection: IBppFormTenthSectionInterface = { ScheduleD: [] };
  const EleventhSection: IBppFormEleventhSectionInterface = {
    "ScheduleE-FurnitureFixtures": [],
    "ScheduleE-Other": [],
    "ScheduleE-ManufacturingEquipment": [],
    "ScheduleE-ComputerEquipment": [],
    "ScheduleE-OfficeEquipment": [],
    "ScheduleE-MachineryEquipment": [],
  };
  const TwelfthSection: IBppFormTwelfthSectionInterface = { ScheduleF: [] };
  const SavedAssetsList: IAssetsInterface = {
    AssetsList: [],
    CurrentAssetCount: 0,
    CurrentYearValue: 0,
    PriorAssetCount: 0,
    PriorYearValue: 0,
    Difference: 0,
    PercentageOfDifference: 0,
  };
  const DeletedAssetsList: IDeletedAssetsInterface = { DeletedAssetsList: [] };

  const assets = pacsFormAssets;

  formFields.forEach((f) => {
    if (f.name in SeventhSection) {
      if (f.type == "object") {
        const temp = JSON.parse(f.value);
        temp.forEach((asset: IBPPFormPersonalPropertySegmentsInterface) => asset.disabled = true);
        (SeventhSection as any)[f.name] = [
          ...assets?.filter((x) => x.ScheduleName === f.name),
          ...temp,
        ];
      }
    } else if (f.name in EighthSection) {
      if (f.type == "object") {
        const temp = JSON.parse(f.value);
        temp.forEach((asset: IBPPFormPersonalPropertySegmentsInterface) => asset.disabled = true);
        (EighthSection as any)[f.name] = [
          ...assets?.filter((x) => x.ScheduleName === f.name),
          ...temp,
        ];
      }
    } else if (f.name in NinthSection) {
      if (f.type == "object") {
        const temp = JSON.parse(f.value);
        temp.forEach((asset: IBPPFormPersonalPropertySegmentsInterface) => asset.disabled = true);
        (NinthSection as any)[f.name] = [
          ...assets?.filter((x) => x.ScheduleName === f.name),
          ...temp,
        ];
      }
    } else if (f.name in TenthSection) {
      if (f.type == "object") {
        const temp = JSON.parse(f.value);
        temp.forEach((asset: IBPPFormPersonalPropertySegmentsInterface) => asset.disabled = true);
        (TenthSection as any)[f.name] = [
          ...assets?.filter((x) => x.ScheduleName === f.name),
          ...temp,
        ];
      }
    } else if (f.name in EleventhSection) {
      if (f.type == "object") {
        const temp = JSON.parse(f.value);
        temp.forEach((asset: IBPPFormPersonalPropertySegmentsInterface) => asset.disabled = true);
        (EleventhSection as any)[f.name] = [
          ...assets?.filter((x) => x.ScheduleName === f.name),
          ...temp,
        ];
      }
    } else if (f.name in TwelfthSection) {
      if (f.type == "object") {
        const temp = JSON.parse(f.value);
        temp.forEach((asset: IBPPFormPersonalPropertySegmentsInterface) => asset.disabled = true);
        (TwelfthSection as any)[f.name] = [
          ...assets?.filter((x) => x.ScheduleName === f.name),
          ...temp,
        ];
      }
    } else if (f.name in SavedAssetsList) {
      if (f.type == "object") {
        const temp = JSON.parse(f.value);
        temp.forEach((asset: IBPPFormPersonalPropertySegmentsInterface) => asset.disabled = true);
        (SavedAssetsList as any)[f.name] = [
          ...assets?.filter((x) => x.ScheduleName === f.name),
          ...temp,
        ];
      }
    } else if (f.name in DeletedAssetsList) {
      if (f.type == "object") {
        const temp = JSON.parse(f.value);
        temp.forEach((asset: IBPPFormPersonalPropertySegmentsInterface) => asset.disabled = true);
        (DeletedAssetsList as any)[f.name] = [
          ...assets?.filter((x) => x.ScheduleName === f.name),
          ...temp,
        ];
      }
    }
  });

  pacsFormAssets.forEach((item) => {
    // save initial values as it comes from PACS in case we need to compare edits with originals in the future
    item.initialValues = JSON.parse(JSON.stringify(item)); // make a deep copy of the object

    item.disabled = true;
    item.originalValue = item.LastYearAssetValue;
    item.MarketValue = item.MarketValue ?? item.LastYearAssetValue;

    if (item.ScheduleName == ScheduleAName) {
      SeventhSection.ScheduleA?.push(item);
    } else if (item.ScheduleName == ScheduleBName) {
      EighthSection.ScheduleB?.push(item);
    } else if (item.ScheduleName == ScheduleCName) {
      NinthSection.ScheduleC?.push(item);
    } else if (item.ScheduleName == ScheduleDName) {
      TenthSection.ScheduleD?.push(item);
    } else if (item.ScheduleName == ScheduleEFurnitureFixturesName) {
      EleventhSection[ScheduleEFurnitureFixturesName]?.push(item);
    } else if (item.ScheduleName == ScheduleEMachineryEquipmentName) {
      EleventhSection[ScheduleEMachineryEquipmentName]?.push(item);
    } else if (item.ScheduleName == ScheduleEOfficeEquipmentName) {
      EleventhSection[ScheduleEOfficeEquipmentName]?.push(item);
    } else if (item.ScheduleName == ScheduleEComputerEquipmentName) {
      EleventhSection[ScheduleEComputerEquipmentName]?.push(item);
    } else if (item.ScheduleName == ScheduleEManufacturingEquipmentName) {
      EleventhSection[ScheduleEManufacturingEquipmentName]?.push(item);
    } else if (item.ScheduleName == ScheduleEOtherName) {
      EleventhSection[ScheduleEOtherName]?.push(item);
    } else if (item.ScheduleName == ScheduleFName) {
      TwelfthSection.ScheduleF?.push(item);
    } else {
      SavedAssetsList.AssetsList?.push(item);
    }
  });

  return {
    SeventhSection,
    EighthSection,
    NinthSection,
    TenthSection,
    EleventhSection,
    TwelfthSection,
    NewAssets: SavedAssetsList.AssetsList || [],
    DeletedAssets: DeletedAssetsList.DeletedAssetsList,
  };
}

function insertItem(item: IBPPFormPersonalPropertySegmentsInterface, assets: AssetsMap) {
  if (item.ScheduleName != undefined && item.ScheduleName in assets) {
    const scheduleName = item.ScheduleName as keyof AssetsMap;
    assets[scheduleName] = [...(assets[scheduleName] || []), item];
  } else {
    assets.NewAssets = [ ...(assets.NewAssets || []), item ]
  }
}

function removeItem(id: number | undefined, assets: AssetsMap): IBPPFormPersonalPropertySegmentsInterface | undefined {
  let removedItem;

  Object.values(assets).forEach(items => {
    const index = items?.findIndex(item => item.Id === id)
    if (index != undefined && index > -1) {
      removedItem = items?.splice(index, 1)[0];
    }
  });

  return removedItem;
}

