export type IAgFormSixthSectionInterface = {
    AgriculturalUseCategoriesOfLand?: Array<IAgriculturalUseCategoriesOfLand>,
    DoesRaiseLivestoc?: boolean,
    AgriculturalUseLivestockExoticsWildlifeTypeByAcres?: Array<IAgriculturalUseLivestockExoticsWildlifeTypeByAcres>,
    AgriculturalUseLivestockExoticsWildlifeTypeByHead?: Array<IAgriculturalUseLivestockExoticsWildlifeTypeByHead>,
    DoesGrowCrops?: boolean,
    AgriculturalUseCopsByAcre?: Array<IAgriculturalUseCopsByAcre>,
    DidPlantCoverCrops?: boolean,
    AgriculturalUseGovernmentalPrograms?: Array<IAgriculturalUseGovernmentalPrograms>,
    PorpertyIsUsedForNonAgriculturalActivities?: boolean,
    NonAgriculturalUse?: Array<INonAgriculturalUse>
}


export const AgFormSixthSectionInitialState = (): IAgFormSixthSectionInterface => ({
    AgriculturalUseCategoriesOfLand: undefined,
    DoesRaiseLivestoc: undefined,
    AgriculturalUseLivestockExoticsWildlifeTypeByAcres: undefined,
    AgriculturalUseLivestockExoticsWildlifeTypeByHead: undefined,
    DoesGrowCrops: undefined,
    AgriculturalUseCopsByAcre: undefined,
    DidPlantCoverCrops: undefined,
    AgriculturalUseGovernmentalPrograms: undefined,
    PorpertyIsUsedForNonAgriculturalActivities: undefined,
    NonAgriculturalUse: undefined,
})

export type IAgriculturalUseCategoriesOfLand = {
    Id: number,
    Year?: number,
    Category?: string,
    Acres?: number
    OtherCategory?: string,
}

export type IAgriculturalUseLivestockExoticsWildlifeTypeByAcres = {
    Id: number,
    Type?: string,
    Acres?: number
}

export type IAgriculturalUseLivestockExoticsWildlifeTypeByHead = {
    Id: number,
    Type?: string,
    Head?: number
}

export type IAgriculturalUseCopsByAcre = {
    Id: number,
    Type?: string,
    Acres?: number
}

export type IAgriculturalUseGovernmentalPrograms = {
    Id: number,
    Name?: string,
    Acres?: number
}

export type INonAgriculturalUse = {
    Id: number,
    Type?: string,
    Acres?: number
}
