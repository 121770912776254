import {IFormFilesInterface} from "../Shared/form-files.interface";

export type IBPPFormThirdSectionInterface = {
    FilingAs?: IFilingAs
    RepresentationFirstName?: string,
    RepresentationLastName?: string,
    RepresentationPhoneNumber?: string,
    RepresentationAddressLine1?: string,
    RepresentationAddressLine2?: string,
    RepresentationAddressCity?: string,
    RepresentationAddressState?: string,
    RepresentationAddressZip?: string,
    IsSecuredParty?: boolean,
    SignedAuthorization?: Array<IFormFilesInterface>,
}

export type IFilingAs = {
    "Owner, employee, or employee of an affiliated entity of the owner"?: boolean | string,
    "Authorized Agent"?: boolean | string,
    Fiduciary?: boolean | string,
    "Secured Party"?: boolean | string
}

export const ThirdSectionInitialState = {
    FilingAs: undefined,
    RepresentationFirstName: undefined,
    RepresentationLastName: undefined,
    RepresentationPhoneNumber: undefined,
    RepresentationAddressLine1: undefined,
    RepresentationAddressLine2: undefined,
    RepresentationAddressCity: undefined,
    RepresentationAddressState: undefined,
    RepresentationAddressZip: undefined,
    IsSecuredParty: undefined,
    SignedAuthorization: undefined,
}