import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./BPPForm.module.scss";
import {Label} from "@progress/kendo-react-labels";
import {
    Checkbox,
    CheckboxChangeEvent,
    Input,
    InputChangeEvent,
    TextAreaChangeEvent
} from "@progress/kendo-react-inputs";
import {UploadFileInfo} from "@progress/kendo-react-upload";
import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import {IBPPFormThirdSectionInterface} from "../../../../../interfaces/forms/BPPForm/bpp-form-third-section.interface";
import {DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import React, {useEffect, useRef, useState} from "react";
import {
    ClearFileArray
} from "../../../../../context/SelectedForm/selected-form-context.helper";
import {IFormEntryModel} from "../../../../../interfaces/form-entry.interface";
import {SelectedFormType} from "../../../../../context/SelectedForm/selected-form-reducer";
import {GenericActionType} from "../../../../../context/generic-actions.type";
import {CustomUpload} from "../CustomInputs/CustomUpload";
import {PhoneNumberInput} from "../CustomInputs/PhoneNumberInputs/PhoneNumberInput";
import {ZipCodeInput} from "../CustomInputs/ZipCodeInput";
import {StatesInput} from "../CustomInputs/StatesInput";
import { IBppFormSecondSectionInterface } from "src/interfaces/forms/BPPForm/bpp-form-second-section.interface";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    formFields?: IBppFormSecondSectionInterface,
    setFormDataFields: (form: IBPPFormThirdSectionInterface) => void;
    formDataFields?:  IBPPFormThirdSectionInterface,
    formSettings?: IFormSettingModel,
    formEntry?: IFormEntryModel,
    setFormIsValid: (value: boolean) => void
    selectedFormDispatch: (value: GenericActionType<SelectedFormType>) => void,
}

export default function BPPFormThirdSection(props : GeneralFormType): JSX.Element {

    const { formFields, formDataFields, setFormDataFields, formSettings, isAdminPreview, formEntry, setFormIsValid, selectedFormDispatch } = props;

    const formRef = useRef<HTMLFormElement>(null);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const [isSignedAuthorizionUploadValid, setIsSignedAuthorizationUploadValid] = useState<boolean>(true);
    const [files, setFiles] = useState<Array<UploadFileInfo>>([]);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={3} name={"Representation"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent,
        field: string,
        group?: string
    )
    {
        if (group) {
            setFormDataFields({
                ...formDataFields,
                [group]: {
                    ...(formDataFields as any)?.[group],
                    [field]: event.value
                }
            } as IBPPFormThirdSectionInterface);
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            } as IBPPFormThirdSectionInterface);
        }
    }

    useEffect(() => {
        if(!formDataFields?.IsSecuredParty
            && files.length > 0
            && formDataFields?.["SignedAuthorization"]?.length
            && formEntry?.accessCode) {
            ClearFileArray<IBPPFormThirdSectionInterface>(
                formEntry.formId,
                selectedFormDispatch,
                formDataFields?.["SignedAuthorization"],
                formEntry.accessCode,
                "SignedAuthorization",
                setFormDataFields,
                formDataFields,
                setFiles);
        }
    }, [formDataFields?.IsSecuredParty]);

    const saveAsPropertyAddressHandler = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setFormDataFields({
            ...formDataFields,
            RepresentationAddressLine1: formFields?.PropertyAddressLine1,
            RepresentationAddressLine2: formFields?.PropertyAddressLine2,
            RepresentationAddressCity : formFields?.PropertyAddressCity,
            RepresentationAddressState: formFields?.PropertyAddressState,
            RepresentationAddressZip:   formFields?.PropertyAddressZip,
        });
    }

    const saveAsBusinessAddressHandler = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setFormDataFields({
            ...formDataFields,
            RepresentationAddressLine1: formFields?.BusinessAddressLine1,
            RepresentationAddressLine2: formFields?.BusinessAddressLine2,
            RepresentationAddressCity : formFields?.BusinessAddressCity,
            RepresentationAddressState: formFields?.BusinessAddressState,
            RepresentationAddressZip:   formFields?.BusinessAddressZip,
        });
    }

    useEffect(() =>{
        return () => {
            setFiles([]);
        }
    },[]);

    const [phoneIsValid, setPhoneIsValid] = useState<boolean>(false);
    const [zipCodeIsValid, setZipCodeIsValid] = useState<boolean>(false);
    const [businessStateInputValid, setBusinessStateInputValid] = useState<boolean>(false);

    function changeValidator() {
        if(formRef.current) {
            setIsValid(formRef.current.checkValidity() && phoneIsValid && zipCodeIsValid && businessStateInputValid);
            setFormIsValid(formRef.current.checkValidity() && phoneIsValid && zipCodeIsValid && businessStateInputValid);
        }
    }

    function onTouch(){
        if(!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current, phoneIsValid, zipCodeIsValid, businessStateInputValid]);

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <p className="itemTitle">
                                Please indicate if you are filling out this form as:
                            </p>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroup}>
                                    <div className={classes.checkboxGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.FilingAs?.["Owner, employee, or employee of an affiliated entity of the owner"]}
                                            label={"Owner, employee, or employee of an affiliated entity of the owner"}
                                            onChange={(e)=>
                                                changeValueHandler(e, "Owner, employee, or employee of an affiliated entity of the owner", "FilingAs")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.FilingAs?.["Authorized Agent"]}
                                            label={"Authorized Agent"}
                                            onChange={(e)=> changeValueHandler(e, "Authorized Agent", "FilingAs")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.FilingAs?.Fiduciary}
                                            label={"Fiduciary"}
                                            onChange={(e)=> changeValueHandler(e, "Fiduciary", "FilingAs")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.FilingAs?.["Secured Party"]}
                                            label={"Secured Party"}
                                            onChange={(e)=> changeValueHandler(e, "Secured Party", "FilingAs")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.item}>
                            <p className="itemTitle">
                                Name of Owner, Authorized Agent, Fiduciary or Secured Party
                            </p>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroupW30}>
                                    <Label className={classes.Label}>
                                        First Name
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <Input
                                            className={classes.kTextbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.RepresentationFirstName}
                                            onChange={(e)=> changeValueHandler(e, "RepresentationFirstName")}
                                        />
                                    </div>
                                </div>
                                <div className={classes.fieldsGroupW30}>
                                    <Label className={classes.Label}>
                                        Last Name
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <Input
                                            className={classes.kTextbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.RepresentationLastName}
                                            onChange={(e)=> changeValueHandler(e, "RepresentationLastName")}
                                        />
                                    </div>
                                </div>
                                <div className={classes.fieldsGroupW30}>
                                    <Label className={classes.Label}>
                                        Phone Number
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <PhoneNumberInput
                                            required={false}
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"RepresentationPhoneNumber"}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                            setIsValidField={setPhoneIsValid}
                                            validField={phoneIsValid}
                                        />
                                    </div>
                                </div>

                                <div className={classes.item}>
                                    <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                        <p>Mailing Address</p>
                                        {!formSettings?.isReadOnly && <div>
                                        <button className={`${classes.button} ${classes.buttonAddress}`} onClick={e => saveAsBusinessAddressHandler(e)}>
                                            Same as Mailing Address
                                        </button>
                                        <button className={`${classes.button} ${classes.buttonAddress}`} onClick={e => saveAsPropertyAddressHandler(e)}>
                                            Same as Property Address
                                        </button> </div> }
                                    </div>

                                    <div className={classes.fieldsGroup}>
                                        <Label className={classes.Label}>
                                            Address Line 1
                                        </Label>
                                        <div className={classes.inputsGroup}>
                                            <Input
                                                className={classes.kTextbox}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.RepresentationAddressLine1}
                                                onChange={(e)=> changeValueHandler(e, "RepresentationAddressLine1")}
                                            />
                                        </div>
                                    </div>

                                    <div className={classes.fieldsGroup}>
                                        <Label className={classes.Label}>
                                            Address Line 2
                                        </Label>
                                        <div className={classes.inputsGroup}>
                                            <Input
                                                className={classes.kTextbox}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.RepresentationAddressLine2}
                                                onChange={(e)=> changeValueHandler(e, "RepresentationAddressLine2")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={`${classes.fieldsGroupW30}`}>
                                    <Label className={classes.Label}>
                                        City
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <Input
                                            className={classes.kTextbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.RepresentationAddressCity}
                                            onChange={(e)=> changeValueHandler(e, "RepresentationAddressCity")}
                                        />
                                    </div>
                                </div>
                                <div className={`${classes.fieldsGroupW30}`}>
                                    <Label className={classes.Label}>
                                        State
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <StatesInput
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"RepresentationAddressState"}
                                            required={false}
                                            validField={businessStateInputValid}
                                            setIsValidField={setBusinessStateInputValid}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                        />
                                    </div>
                                </div>
                                <div className={`${classes.fieldsGroupW30}`}>
                                    <Label className={classes.Label}>
                                        Zip Code
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <ZipCodeInput
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"RepresentationAddressZip"}
                                            required={false}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                            setIsValidField={setZipCodeIsValid}
                                            validField={zipCodeIsValid}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroup}>
                                    <div className={classes.checkboxGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.IsSecuredParty}
                                            label={"Check this box if you are a secured party with a security interest in the property subject to this rendition and with a historical cost new of more than $50,000 as defined by Tax Code Section 22.01 (c-1) and (c-2)."}
                                            onChange={(e)=> changeValueHandler(e, "IsSecuredParty")}
                                        />
                                        <div className="alert alert-info ">
                                            You must attach a document signed by the property owner indicating consent for
                                            you to file the rendition. Without the authorization, the rendition is not valid
                                            and cannot be processed.
                                        </div>
                                        { formDataFields?.["IsSecuredParty"] &&
                                            <CustomUpload
                                                files={files}
                                                setFiles={setFiles}
                                                uploadIsValid={!!formDataFields && !!formDataFields.IsSecuredParty && isSignedAuthorizionUploadValid}
                                                setUploadIsValid={setIsSignedAuthorizationUploadValid}
                                                formEntry={formEntry}
                                                formSettings={formSettings}
                                                isAdminPreview={isAdminPreview}
                                                setFormDataFields={setFormDataFields}
                                                formDataFields={formDataFields}
                                                fieldName={"SignedAuthorization"}
                                                isRequired={false}
                                                selectedFormDispatch={selectedFormDispatch}
                                                multiple={false}
                                                isLabelRequired={false}
                                                labelText={""} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
