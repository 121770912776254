import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "./DisabledVetForm.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { UploadFileInfo, UploadFileStatus } from "@progress/kendo-react-upload";
import { DatePickerChangeEvent, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, RadioGroupChangeEvent, Input, Checkbox } from "@progress/kendo-react-inputs";
import PropertyOwnerIdNumberImage from  "../../../../../assets/images/identification-card.png";
import { FormValidation } from "../InputValidator";
import { Error, Label } from "@progress/kendo-react-labels";
import { EmailInput } from "../CustomInputs/EmailInputs/EmailInput";
import { PhoneNumberInput } from "../CustomInputs/PhoneNumberInputs/PhoneNumberInput";
import { StatesInput } from "../CustomInputs/StatesInput";
import { ZipCodeInput } from "../CustomInputs/ZipCodeInput";
import { IDisabledVetSecondSection } from "src/interfaces/forms/DisableVetForm/disable-vet-form-second-section";
import { CheckboxGroup } from "../CustomInputs/CheckBoxes/CheckboxGroup";
import { IFilePreviewInterface } from "src/interfaces/file-preview.interface";
import { IFormEntryModel } from "src/interfaces/form-entry.interface";
import { AxiosResponse, AxiosError } from "axios";
import { getSupportingDocumentByAccessCodeAndDocumentId } from "src/api/form-entries-api";
import { temporaryError } from "src/context/shared/shared-context.helper";
import { CustomUpload } from "../CustomInputs/CustomUpload";
import { GenericActionType } from "src/context/generic-actions.type";
import { SelectedFormType } from "src/context/SelectedForm/selected-form-reducer";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IDisabledVetSecondSection) => void,
    formEntry?: IFormEntryModel,
    formDataFields?:  IDisabledVetSecondSection,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void,
    selectedFormDispatch: (value: GenericActionType<SelectedFormType>) => void
}

export default function DisabledVetFormSecondSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, setFormIsValid, 
        isAdminPreview, formEntry, selectedFormDispatch } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={2} name={"Property Owner/Applicant"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string,
        group?: string
    )
    {
        if (group) {
            setFormDataFields({
                ...formDataFields,
                [group]: {
                    ...(formDataFields as any)?.[group],
                    [field]: event.value
                }
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    function changeTaxYearValueHandler(
        event: CheckboxChangeEvent,
        field: string,
        value: number
      ) {
        let temp: Array<number> = formDataFields?.TaxYears
          ? JSON.parse(JSON.stringify(formDataFields?.TaxYears))
          : [];
    
        if (event.value === true) {
          temp.push(value);
        } else if (event.value === false) {
          temp = temp.filter((x) => x != value);
        }
        setFormDataFields({
          ...formDataFields,
          [field]: temp,
        });
      }

    useEffect(() => {
        if (formSettings) {
            const years: Array<number> = [];

            if (formSettings.taxYearCurrentAvailableToApply) {
            years.push(new Date().getFullYear());
            }

            if (formSettings.taxYearCurrentMinus1AvailableToApply) {
            years.push(new Date().getFullYear() - 1);
            }

            if (formSettings.taxYearCurrentMinus2AvailableToApply) {
            years.push(new Date().getFullYear() - 2);
            }

            if (formSettings.taxYearCurrentPlus1AvailableToApply) {
            years.push(new Date().getFullYear() + 1);
            }

            setTaxYears(years);
        }
    }, [formSettings]);

    const [taxYears, setTaxYears] = useState<Array<number>>([]);
    const [emailValid, setEmailValid] = useState<boolean>(false);
    const [propertyOwnerPhoneNumberValid, setPropertyOwnerPhoneNumberValid] = useState<boolean>(false);
    const [physicalStateValid, setPhysicalStateValid] = useState<boolean>(false);
    const [mailingStateValid, setMailingStateValid] = useState<boolean>(false);
    const [physicalZipCodeValid, setPhysicalZipCodeValid] = useState<boolean>(false);
    const [mailingZipCodeValid, setMailingZipCodeValid] = useState<boolean>(false);
    const [isPropertyOwnerPhotoIdUploadValid, setIsPropertyOwnerPhotoIdUploadValid] = useState<boolean>(true);
    const [propertyOwnerPhotoIdFiles, setPropertyOwnerPhotoIdFiles] = useState<Array<UploadFileInfo>>([]);
    const [OwnerPhotoIdFilesPreviews, setOwnerPhotoIdFilesPreviews] = useState<IFilePreviewInterface>({});
    const { validationMessage, inputValidator } = FormValidation(formRef);

    function changeValidator() {
        if (formRef.current) {
            const isValid = 
                emailValid &&
                isPropertyOwnerPhotoIdUploadValid && 
                propertyOwnerPhoneNumberValid &&
                physicalStateValid && 
                mailingStateValid && 
                physicalZipCodeValid && 
                mailingZipCodeValid &&
                taxYearsValidator() &&
                formRef.current.checkValidity();
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    useEffect(() => {
        return () => {
            setPropertyOwnerPhotoIdFiles([]);
            setOwnerPhotoIdFilesPreviews({});
        }
    }, []);

    useEffect(() => {
        if (formEntry?.id && formDataFields?.PropertyOwnerPhotoId?.length){
            const propertyOwnerPhotoId = formDataFields?.PropertyOwnerPhotoId?.[0];
            if(propertyOwnerPhotoId){
                getSupportingDocumentByAccessCodeAndDocumentId(formEntry.accessCode, propertyOwnerPhotoId.id)
                    .then((response: AxiosResponse) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(response.data);
                        reader.onloadend = function() {
                            const base64data = reader.result;

                            const result: IFilePreviewInterface = {
                                uid: base64data?.toString(),
                                isImage: propertyOwnerPhotoId.extension?.match(/\.(jpg|jpeg|png|gif)$/i) ? true : false,
                            }

                            setOwnerPhotoIdFilesPreviews({
                                ...result
                            });
    
                            setPropertyOwnerPhotoIdFiles([{
                                uid: propertyOwnerPhotoId.id,
                                name: propertyOwnerPhotoId!.name!,
                                extension: propertyOwnerPhotoId.extension,
                                size: propertyOwnerPhotoId.size,
                                status: UploadFileStatus.Initial,
                                progress: 1
                            }])
                        }
                    })
                    .catch((error: AxiosError) => {
                        temporaryError(selectedFormDispatch, error.response?.data);
                    });
            }
        }
    }, [formEntry, formDataFields]);

    useEffect(() => {
        propertyOwnerPhotoIdFiles
            .filter((file: UploadFileInfo) => !file.validationErrors)
            .forEach((file: UploadFileInfo) => {
                const reader = new FileReader();

                reader.onloadend = (ev: any) => {

                    const result: IFilePreviewInterface = {
                        uid: ev.target.result,
                        isImage: file.extension?.match(/\.(jpg|jpeg|png|gif)$/i) ? true : false,
                    }

                    setOwnerPhotoIdFilesPreviews({
                       ...result
                    });
                };
                if (file && file.getRawFile) {
                    reader.readAsDataURL(file.getRawFile());
                }
            });
    }, [propertyOwnerPhotoIdFiles]);

    useEffect(() => {
        if(formDataFields?.PropertyOwnerPhotoId?.length === 0){
            setOwnerPhotoIdFilesPreviews({});
        }

    }, [formDataFields?.PropertyOwnerPhotoId]);

    const saveAsPhysicalAddressHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        setFormDataFields({
            ...formDataFields,
            PropertyOwnerMailingAddressLine1: formDataFields?.PropertyOwnerPhysicalAddressLine1,
            PropertyOwnerMailingAddressLine2: formDataFields?.PropertyOwnerPhysicalAddressLine2,
            PropertyOwnerMailingAddressCity: formDataFields?.PropertyOwnerPhysicalAddressCity,
            PropertyOwnerMailingAddressState: formDataFields?.PropertyOwnerPhysicalAddressState,
            PropertyOwnerMailingAddressZip: formDataFields?.PropertyOwnerPhysicalAddressZip,
        });
    }

    const taxYearsValidator = () => {
        return !!(formDataFields?.TaxYears && formDataFields.TaxYears.length > 0);
    };

    function onTouch(){
        if (!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [
        formDataFields,
        formRef.current,
        emailValid,
        isPropertyOwnerPhotoIdUploadValid,
        propertyOwnerPhoneNumberValid,
        physicalStateValid,
        mailingStateValid, 
        physicalZipCodeValid, 
        mailingZipCodeValid
    ]);

    return (
       <>
            <PanelBar keepItemsMounted={true}>
                <PanelBarItem title={title} expanded={true}>
                    <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                        <div className="container">
                            <div className={classes.item}>
                                <div className={`${classes.itemFields}`}>
                                    <Label className={classes.Label}>
                                        Are you filing a late application?
                                    </Label>
                                    <div className={classes.fieldsGroup}>
                                        <div className={classes.checkboxGroup}>
                                            <CheckboxGroup
                                                isAdminPreview={isAdminPreview}
                                                setFormDataFields={setFormDataFields}
                                                formDataFields={formDataFields}
                                                formSettings={formSettings}
                                                fieldName={"IsLateApplication"}
                                                fieldSecondName={"IsLateApplicationSecond"}
                                                isRequired={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.item}>                                
                                <div className={classes.itemFields}>
                                    <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Enter the tax year(s) for which you are filing:
                                            {!taxYearsValidator() && (
                                                <i className={"fa fa-asterisk text-danger"}></i>
                                            )}
                                        </Label>
                                        <div className={classes.inputsGroup}>
                                            {formSettings?.isReadOnly && (
                                                <div>{formDataFields?.TaxYears?.join(", ")}</div>
                                            )}
                                            {taxYears
                                                .sort()
                                                .reverse()
                                                .map((item) => {
                                                return (
                                                    <Checkbox
                                                    key={item}
                                                    name={item.toString()}
                                                    className={classes.Checkbox}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.TaxYears?.some(
                                                        (x) => x == item
                                                    )}
                                                    label={item.toString()}
                                                    onChange={(e) =>
                                                        changeTaxYearValueHandler(e, "TaxYears", item)
                                                    }
                                                    />
                                                );
                                                })}
                                            {!taxYearsValidator() && (
                                                <Error>{validationMessage}</Error>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.item}>
                                <div className={classes.itemFields}>
                                    <Label className={classes.Label}>
                                        Did the applicant own the property that is the subject of this application on Jan. 1 of the tax year?
                                    </Label>
                                    <div className={classes.fieldsGroup}>
                                        <div className={classes.checkboxGroup}>
                                            <CheckboxGroup
                                                isAdminPreview={isAdminPreview}
                                                setFormDataFields={setFormDataFields}
                                                formDataFields={formDataFields}
                                                formSettings={formSettings}
                                                fieldName={"ApplicantOwnedPropertyJan1"}
                                                fieldSecondName={"ApplicantOwnedPropertyJan1Second"}
                                                isRequired={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.item} ${classes.flexRow}`}>
                                <div className={`${classes.fieldsGroupW50} ${classes.column}`}>
                                    <div className={`${classes.itemFields} ${classes.bordered}`}>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <Label className={classes.Label}>
                                                Name of Property Owner
                                                {!formDataFields?.PropertyOwnerName && (
                                                    <i className={"fa fa-asterisk text-danger"}></i>
                                                )}
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                required={true}
                                                name={"PropertyOwnerName"}
                                                placeholder={"Name of Property Owner"}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.PropertyOwnerName}
                                                onChange={(e)=> changeValueHandler(e, "PropertyOwnerName")}
                                            />
                                            {!inputValidator("PropertyOwnerName") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <Label className={classes.Label}>
                                                Primary Phone Number (area code and number)
                                            </Label>
                                            <PhoneNumberInput
                                                required={false}
                                                setFormDataFields={setFormDataFields}
                                                fieldName={"PropertyOwnerPhoneNumber"}
                                                placeholder={"Phone Number"}
                                                formDataFields={formDataFields}
                                                isReadOnly={formSettings?.isReadOnly}
                                                setIsValidField={setPropertyOwnerPhoneNumberValid}
                                                validField={propertyOwnerPhoneNumberValid}
                                            />
                                        </div>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <Label className={classes.Label}>
                                                Email Address
                                            </Label>
                                            <EmailInput
                                                required={false}
                                                setFormDataFields={setFormDataFields}
                                                fieldName={"PropertyOwnerEmailAddress"}
                                                placeholder={"Email Address"}
                                                formDataFields={formDataFields}
                                                isReadOnly={formSettings?.isReadOnly}
                                                setIsValidField={setEmailValid}
                                                validField={emailValid}
                                            />
                                        </div>
                                    </div>
                                    <div className={`${classes.item} ${classes.bordered}`}>
                                        <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                            <p>Physical Address</p>
                                        </div>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <Label className={classes.Label}>
                                                Address Line 1
                                                {!formDataFields?.PropertyOwnerPhysicalAddressLine1 && (
                                                    <i className={"fa fa-asterisk text-danger"}></i>
                                                )}
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                name={"PropertyOwnerPhysicalAddressLine1"}
                                                placeholder={"Address Line 1"}
                                                required={true}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.PropertyOwnerPhysicalAddressLine1}
                                                onChange={(e)=> changeValueHandler(e, "PropertyOwnerPhysicalAddressLine1")}
                                            />
                                            {!inputValidator("PropertyOwnerPhysicalAddressLine1") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <Label className={classes.Label}>
                                                Address Line 2
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                name={"PropertyOwnerPhysicalAddressLine2"}
                                                placeholder={"Address Line 2"}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.PropertyOwnerPhysicalAddressLine2}
                                                onChange={(e)=> changeValueHandler(e, "PropertyOwnerPhysicalAddressLine2")}
                                            />
                                        </div>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <div className={`${classes.fieldsGroupW50}`}>
                                                <Label className={classes.Label}>
                                                    City
                                                    {!formDataFields?.PropertyOwnerPhysicalAddressCity && (
                                                        <i className={"fa fa-asterisk text-danger"}></i>
                                                    )}
                                                </Label>
                                                <Input
                                                    className={classes.kTextbox}
                                                    required={true}
                                                    name={"PropertyOwnerPhysicalAddressCity"}
                                                    placeholder={"City"}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.PropertyOwnerPhysicalAddressCity}
                                                    onChange={(e)=> changeValueHandler(e, "PropertyOwnerPhysicalAddressCity")}
                                                />
                                                {!inputValidator("PropertyOwnerPhysicalAddressCity") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                            <div className={`${classes.fieldsGroupW20}`}>
                                                <Label className={classes.Label}>
                                                    State
                                                    {!formDataFields?.PropertyOwnerPhysicalAddressState && (
                                                        <i className={"fa fa-asterisk text-danger"}></i>
                                                    )}
                                                </Label>
                                                <StatesInput
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"PropertyOwnerPhysicalAddressState"}
                                                    required={true}
                                                    validField={physicalStateValid}
                                                    setIsValidField={setPhysicalStateValid}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                />
                                                {!inputValidator("PropertyOwnerPhysicalAddressState") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                            <div className={`${classes.fieldsGroupW20}`}>
                                                <Label className={classes.Label}>
                                                    Zip Code
                                                    {!formDataFields?.PropertyOwnerPhysicalAddressZip && (
                                                        <i className={"fa fa-asterisk text-danger"}></i>
                                                    )}
                                                </Label>
                                                <ZipCodeInput
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"PropertyOwnerPhysicalAddressZip"}
                                                    placeholder={"Zip Code"}
                                                    required={true}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                    setIsValidField={setPhysicalZipCodeValid}
                                                    validField={physicalZipCodeValid}
                                                />
                                                {!inputValidator("PropertyOwnerPhysicalAddressZip") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${classes.item} ${classes.bordered}`}>
                                        <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                            <p>Mailing Address</p>
                                            { !formSettings?.isReadOnly && !isAdminPreview &&
                                                <button className={`${classes.button} ${classes.buttonAddress}`} 
                                                    onClick={e => saveAsPhysicalAddressHandler(e)}>
                                                    Copy Physical Address
                                                </button> }
                                        </div>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <Label className={classes.Label}>
                                                Address Line 1
                                                {!formDataFields?.PropertyOwnerMailingAddressLine1 && (
                                                    <i className={"fa fa-asterisk text-danger"}></i>
                                                )}
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                required={true}
                                                name={"PropertyOwnerMailingAddressLine1"}
                                                placeholder={"Address Line 1"}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.PropertyOwnerMailingAddressLine1}
                                                onChange={(e)=> changeValueHandler(e, "PropertyOwnerMailingAddressLine1")}
                                            />
                                            {!inputValidator("PropertyOwnerMailingAddressLine1") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <Label className={classes.Label}>
                                                Address Line 2
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                name={"PropertyOwnerMailingAddressLine2"}
                                                placeholder={"Address Line 2"}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.PropertyOwnerMailingAddressLine2}
                                                onChange={(e)=> changeValueHandler(e, "PropertyOwnerMailingAddressLine2")}
                                            />
                                        </div>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <div className={`${classes.fieldsGroupW50}`}>
                                                <Label className={classes.Label}>
                                                    City
                                                    {!formDataFields?.PropertyOwnerMailingAddressCity && (
                                                        <i className={"fa fa-asterisk text-danger"}></i>
                                                    )}
                                                </Label>
                                                <Input
                                                    className={classes.kTextbox}
                                                    required={true}
                                                    name={"PropertyOwnerMailingAddressCity"}
                                                    placeholder={"City"}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.PropertyOwnerMailingAddressCity}
                                                    onChange={(e)=> changeValueHandler(e, "PropertyOwnerMailingAddressCity")}
                                                />
                                                {!inputValidator("PropertyOwnerMailingAddressCity") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                            <div className={`${classes.fieldsGroupW20}`}>
                                                <Label className={classes.Label}>
                                                    State
                                                    {!formDataFields?.PropertyOwnerMailingAddressState && (
                                                        <i className={"fa fa-asterisk text-danger"}></i>
                                                    )}
                                                </Label>
                                                <StatesInput
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"PropertyOwnerMailingAddressState"}
                                                    required={true}
                                                    validField={mailingStateValid}
                                                    setIsValidField={setMailingStateValid}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                />
                                            </div>
                                            <div className={`${classes.fieldsGroupW20}`}>
                                                <Label className={classes.Label}>
                                                    Zip Code
                                                    {!formDataFields?.PropertyOwnerMailingAddressZip && (
                                                        <i className={"fa fa-asterisk text-danger"}></i>
                                                    )}
                                                </Label>
                                                <ZipCodeInput
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"PropertyOwnerMailingAddressZip"}
                                                    placeholder={"Zip"}
                                                    required={true}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                    setIsValidField={setMailingZipCodeValid}
                                                    validField={mailingZipCodeValid}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${classes.fieldsGroupW50} ${classes.column} ${classes.bordered}`}>
                                    <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                        <p>State Issued Personal Identification</p>
                                    </div>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                        Attach a copy of your driver&apos;s license or state-issued personal identification certificate.
                                    </div>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                        <Label className={classes.Label}>
                                            Driver&apos;s License, Personal I.D. Certificate Social 
                                            Security Number or Federal Tax I.D. Number
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            required={true}
                                            name={"PropertyOwnerIdNumber"}
                                            placeholder={"Driver's License, Personal I.D. Certificate Social Security Number or Federal Tax I.D. Number"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.PropertyOwnerIdNumber}
                                            onChange={(e)=> changeValueHandler(e, "PropertyOwnerIdNumber")}
                                        />
                                        {!inputValidator("PropertyOwnerIdNumber") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                    <div className={classes.fieldsGroupW100}>
                                        <div className={classes.imagePreview}>
                                            { OwnerPhotoIdFilesPreviews.uid && OwnerPhotoIdFilesPreviews.isImage ? (
                                                    <img
                                                        className={classes.image}
                                                        src={OwnerPhotoIdFilesPreviews?.uid}
                                                        alt={"image preview"}
                                                    />
                                                ) :
                                                <img
                                                    className={classes.image}
                                                    src={PropertyOwnerIdNumberImage}
                                                    alt={"image preview"}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className={classes.fieldsGroupW100}>
                                        <CustomUpload
                                            files={propertyOwnerPhotoIdFiles}
                                            setFiles={setPropertyOwnerPhotoIdFiles}
                                            uploadIsValid={isPropertyOwnerPhotoIdUploadValid}
                                            setUploadIsValid={setIsPropertyOwnerPhotoIdUploadValid}
                                            formEntry={formEntry}
                                            formSettings={formSettings}
                                            isAdminPreview={isAdminPreview}
                                            setFormDataFields={setFormDataFields}
                                            formDataFields={formDataFields}
                                            fieldName={"PropertyOwnerPhotoId"}
                                            isRequired={true}
                                            selectedFormDispatch={selectedFormDispatch}
                                            multiple={false}
                                            isLabelRequired={false}
                                            labelText={"Attach a copy of the most recent utility bill for the property subject to the claimed exemption."}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.item}>
                                <div className={`${classes.itemFields} ${classes.bordered}`}>
                                    <Label className={classes.Label}>
                                        Are you a Texas resident?
                                    </Label>
                                    <div className={classes.fieldsGroup}>
                                        <div className={classes.checkboxGroup}>
                                            <CheckboxGroup
                                                isAdminPreview={isAdminPreview}
                                                setFormDataFields={setFormDataFields}
                                                formDataFields={formDataFields}
                                                formSettings={formSettings}
                                                fieldName={"IsTexasResident"}
                                                fieldSecondName={"IsTexasResidentSecond"}
                                                isRequired={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                   </form>
                </PanelBarItem>
            </PanelBar>
       </>
    )
}