import classes from "./BPPForm.module.scss";
import BPPFormSecondSection from "./BPPFormSecondSection";
import { useSelectedFormContext } from "../../../../../context/SelectedForm/selected-form-context";
import React, { useEffect, useMemo, useState } from "react";
import {
  getHarrisSelectedFormDataWithPrefilledDataHelper,
  getPriorYearFormFieldsHelper,
  getSelectedFormDataHelper,
  getSelectedFormEntryDataHelper,
  saveFormHelper,
} from "../../../../../context/SelectedForm/selected-form-context.helper";
import {
  IFormEntryModel,
} from "../../../../../interfaces/form-entry.interface";
import {
  IFormFiledInterface,
} from "../../../../../interfaces/form-filed.interface";
import {
  IBppFormSecondSectionInterface,

} from "../../../../../interfaces/forms/BPPForm/bpp-form-second-section.interface";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {
  IFormSettingModel,
} from "../../../../../interfaces/form-settings.interface";
import BPPFormPDFView from "../../../../Shared/BPPFormPDFView/BPPFormPDFView";
import { Dialog } from "@progress/kendo-react-dialogs";
import { SelectedFormActionsEnum } from "../../../../../context/SelectedForm/selected-form-actions";
import BPPFormThirdSection from "./BPPFormThirdSection";
import {
  IBPPFormThirdSectionInterface,

} from "../../../../../interfaces/forms/BPPForm/bpp-form-third-section.interface";
import {
  IBppFormFourthSectionInterface,
} from "../../../../../interfaces/forms/BPPForm/bpp-form-fourth-section.interface";
import BPPFormFourthSection from "./BPPFormFourthSection";
import BPPFormFifthSection from "./BPPFormFifthSection";
import {
  IBppFormFifthSectionInterface,
} from "../../../../../interfaces/forms/BPPForm/bpp-form-fifth-section.interface";
import {
  IBppFormSeventhSectionInterface,

} from "../../../../../interfaces/forms/BPPForm/bpp-form-seventh-section.interface";
import { BPPFormSeventhSection } from "./BPPFormSeventhSection";
import BPPFormSixthSection from "./BPPFormSixthSection";
import {
  IBppFormSixthSectionInterface,

} from "../../../../../interfaces/forms/BPPForm/bpp-form-sixth-section.interface";
import {
  IBppFormEighthSectionInterface,
} from "../../../../../interfaces/forms/BPPForm/bpp-form-eighth-section.interface";
import { BPPFormEighthSection } from "./BPPFormEighthSection";
import {
  IBppFormNinthSectionInterface,

} from "../../../../../interfaces/forms/BPPForm/bpp-form-ninth-section.interface";
import { BPPFormNinthSection } from "./BPPFormNinthSection";
import {
  IBppFormTenthSectionInterface,

} from "../../../../../interfaces/forms/BPPForm/bpp-form-tenth-section.interface";
import { BPPFormTenthSection } from "./BPPFormTenthSection";
import {
  IBppFormEleventhSectionInterface,
} from "../../../../../interfaces/forms/BPPForm/bpp-form-eleventh-section.interface";
import { BPPFormEleventhSection } from "./BppFormEleventhSection/BPPFormEleventhSection";
import {
  IBppFormTwelfthSectionInterface,

} from "../../../../../interfaces/forms/BPPForm/bpp-form-twelfth-section.interface";
import { BPPFormTwelfthSection } from "./BPPFormTwelfthSection";
import BPPFormThirteenthSection from "./BPPFormThirteenthSection";
import {
  IBppFormThirteenthSectionInterface,

} from "../../../../../interfaces/forms/BPPForm/bpp-form-thirteenth-section.interface";
import {
  IBppFormFourteenthSectionInterface,
} from "../../../../../interfaces/forms/BPPForm/bpp-form-fourteenth-section.interface";
import BPPFormFourteenthSection from "./BPPFormFourteenthSection";
import {
  IBppFormFifteenthSectionInterface,
} from "../../../../../interfaces/forms/BPPForm/bpp-form-fifteenth-section.interface";
import BPPFormFifteenthSection from "./BPPFormFifteenthSection";
import BPPFormFirstSection from "./BPPFormFirstSection";
import { GetFormFieldFormSections } from "../useFormSectionDataFields";
import { useFormContext } from "../../../../../context/Form/form-context";
import { BPPFormName } from "../../../../../constants/form-names-constants";
import {
  GetFormAssetsSettingsAndPACSPersonalPropertyTypeCodesHelper,
  onLoadForms
} from "../../../../../context/Form/form-helper";
import { CustomLoader } from "../../../../../components";
import { FormActionsEnum } from "../../../../../context/Form/form-actions";
import { useCallbackPrompt } from "../../../../../hooks/useCallbackPrompt";
import UnsavedFormDialog from "../../../../../components/CustomDialogs/UnsavedFormDialog/UnsavedFormDialog";
import { globalConfig } from "../../../../../configuration/config";
import BPPFormAssetsTable from "./BPPAssets/BPPFormAssetsTable";
import { settingsService } from "../../../../../services/settings.service";
import {
  IPacsPersonalPropertyTypeCodeInterface
} from "../../../../../interfaces/pacs-personal-property-type-code.interface";
import { IFormAssetsSettingsInterface } from "../../../../../interfaces/form-assets-settings.interface";
import {
  IBPPFormPersonalPropertySegmentsInterface
} from "../../../../../interfaces/forms/BPPForm/bpp-form-personal-property-segments.interface";
import {initiatedByOnlineForms, initiatedByPropertySearch} from "../../../../../constants/form-entry-constants";
import AuthService from 'src/services/authentication.service';
import {requestedRenditionExtension} from "../../../../../constants/form-status-constants";
import {saved, submitted} from "../../../../../constants/submission-form-status-constants";
import { FormHeader } from "../Shared/FormHeader/FormHeader";
import {parseBppFromSections} from "./Helpers/BppForm-helpers";
import {IGeneralScheduleInterface} from "../../../../../interfaces/forms/BPPForm/general-schedule.interface";
import {fromSegments} from "../../../../../interfaces/forms/BPPForm/assets-interface";
import {setLoading} from "../../../../../context/shared/shared-context.helper";
import {IDeletedAssetsInterface} from "../../../../../interfaces/forms/BPPForm/deleted-assets-interface";
import { useSchedulesAndAssets } from "./useSchedulesAndAssets";
import { useIsFormActive } from "../useIsFormActive";

interface FormEntry {
  formEntryId?: number;
  isAdminPreview?: boolean;
  formId?: number;
}

export default function BPPForm(props: FormEntry): JSX.Element {
  const { isAuthorized } = AuthService.getInstance();

  const settings = settingsService().settings;
  const [searchParams] = useSearchParams();
  const { isAdminPreview, formEntryId, formId } = props;
  const { formState, formDispatch } = useFormContext();
  const { selectedFormDispatch, selectedFormState } = useSelectedFormContext();
  const { loading } = formState;
  const { isLoading } = selectedFormState;
  const [formDataFields, setFormDataFields] = useState<Array<IFormFiledInterface>>();
  const [formEntry, setFormEntry] = useState<IFormEntryModel>();
  const formSettings = useMemo(() => {
    let formSettings = selectedFormState.formSettings ?? undefined;
    if (
        !isAuthorized ||
        isAdminPreview ||
        selectedFormState.formEntry?.submissionStatus == submitted ||
        selectedFormState.formEntry?.submissionStatus == requestedRenditionExtension
     ) {
      formSettings = { ...formSettings, isReadOnly: true };
     }
     return formSettings;
  }, [selectedFormState.formSettings, selectedFormState.formEntry, isAuthorized, isAdminPreview]);

  const [formSecondSectionDataFields, setFormSecondSectionDataFields] =
    useState<IBppFormSecondSectionInterface>();
  const [formThirdSectionDataFields, setFormThirdSectionDataFields] =
    useState<IBPPFormThirdSectionInterface>();
  const [formFourthSectionDataFields, setFormFourthSectionDataFields] =
    useState<IBppFormFourthSectionInterface>();
  const [formFifthSectionDataFields, setFormFifthSectionDataFields] =
    useState<IBppFormFifthSectionInterface>();
  const [formSixthSectionDataFields, setFormSixthSectionDataFields] =
    useState<IBppFormSixthSectionInterface>();
  const [formThirteenthSectionDataFields, setFormThirteenthSectionDataFields] =
    useState<IBppFormThirteenthSectionInterface>();
  const [formFourteenthSectionDataFields, setFormFourteenthSectionDataFields] =
    useState<IBppFormFourteenthSectionInterface>();
  const [formFifteenthSectionDataFields, setFormFifteenthSectionDataFields] =
    useState<IBppFormFifteenthSectionInterface>();
  const [supportingDocsChanged, setSupportingDocsChanged] = useState<boolean>(false);
  const allDocuments = useMemo(() => [
    ...(formThirdSectionDataFields?.SignedAuthorization || []),
    ...(formFourteenthSectionDataFields?.SupportingFiles || [])
  ], [formThirdSectionDataFields, formFourteenthSectionDataFields]);

  const [secondSectionIsValid, setSecondSectionIsValid] = useState<boolean>(false);
  const [thirdSectionIsValid, setThirdSectionIsValid] = useState<boolean>(false);
  const [fourthSectionIsValid, setFourthSectionIsValid] = useState<boolean>(false);
  const [fifthSectionIsValid, setFifthSectionIsValid] = useState<boolean>(false);
  const [sixSectionIsValid, setSixSectionIsValid] = useState<boolean>(false);
  const [seventhSectionIsValid, setSeventhSectionISValid] = useState<boolean>(false);
  const [eighthSectionIsValid, setEighthSectionIsValid] = useState<boolean>(false);
  const [ninthSectionIsValid, setNinthSectionIsValid] = useState<boolean>(false);
  const [tenthSectionIsValid, setTenthSectionIsValid] = useState<boolean>(false);
  const [eleventhSectionIsValid, setEleventhSectionIsValid] = useState<boolean>(false);
  const [twelfthSectionIsValid, setTwelfthSectionIsValid] = useState<boolean>(false);
  const [thirteenthSectionIsValid, setThirteenthSectionIsValid] = useState<boolean>(false);
  const [fourteenthSectionIsValid, setFourteenthSectionIsValid] = useState<boolean>(false);
  const [fifteenthSectionIsValid, setFifteenthSectionIsValid] = useState<boolean>(false);
  const [assetsGridIsValid, setAssetsGridIsValid] = useState<boolean>((formEntry?.initiatedBy !== initiatedByPropertySearch || !settings.isHarrisClient));

  const { isFormActive } = useIsFormActive(
    isAuthorized,
    isAdminPreview,
    formSettings,
    "SupportingFiles",
    selectedFormState.formFields,
    formFourteenthSectionDataFields?.SupportingFiles
  );
  const [showPrompt, confirmNavigation, cancelNavigation, setDisablePrompt] = useCallbackPrompt(isFormActive);

  const {
    formSeventhSectionDataFields,
    formEighthSectionDataFields,
    formNinthSectionDataFields,
    formTenthSectionDataFields,
    formEleventhSectionDataFields,
    formTwelfthSectionDataFields,
    newAssets,
    deletedAssets,
    assets,
    addAsset,
    updateAsset,
    removeAsset,
    restoreAsset,
    setFormSeventhSectionDataFields,
    setFormEighthSectionDataFields,
    setFormNinthSectionDataFields,
    setFormTenthSectionDataFields,
    setFormEleventhSectionDataFields,
    setFormTwelfthSectionDataFields,
  } = useSchedulesAndAssets(
    selectedFormState.formFields,
    selectedFormState.pacsFormAssets,
    settings.isHarrisClient && formEntry?.initiatedBy === initiatedByPropertySearch
  );

  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [allSectionsTouched, setAllSectionsTouched] = useState<boolean>(false);

  const [PACSPropertyTypeCodes, setPACSPropertyTypeCodes] = useState<IPacsPersonalPropertyTypeCodeInterface[]>();
  const [formAssetsSettings, setFormAssetsSettings] = useState<IFormAssetsSettingsInterface[]>();

  const isAssetsVisible = !isAdminPreview &&
    settings.isHarrisClient &&
    PACSPropertyTypeCodes &&
    formAssetsSettings &&
    formDataFields &&
    formEntry?.initiatedBy === initiatedByPropertySearch;


  useEffect(() => {
    setIsFormValid(secondSectionIsValid &&
      thirdSectionIsValid &&
      fourthSectionIsValid &&
      fifthSectionIsValid &&
      sixSectionIsValid &&
      seventhSectionIsValid &&
      eighthSectionIsValid &&
      ninthSectionIsValid &&
      tenthSectionIsValid &&
      eleventhSectionIsValid &&
      twelfthSectionIsValid &&
      thirteenthSectionIsValid &&
      fourteenthSectionIsValid &&
      fifteenthSectionIsValid &&
      assetsGridIsValid)
  }, [
    secondSectionIsValid,
    thirdSectionIsValid,
    fourthSectionIsValid,
    fifthSectionIsValid,
    sixSectionIsValid,
    seventhSectionIsValid,
    eighthSectionIsValid,
    ninthSectionIsValid,
    tenthSectionIsValid,
    eleventhSectionIsValid,
    twelfthSectionIsValid,
    thirteenthSectionIsValid,
    fourteenthSectionIsValid,
    fifteenthSectionIsValid,
    assetsGridIsValid]);

  useEffect(() => {
    return () => {
      ClearData();

      selectedFormDispatch({
        type: SelectedFormActionsEnum.CLEAR,
        payload: {
          errorMessage: undefined,
          isLoading: undefined,
          formFields: undefined,
          successMessage: undefined,
          form: undefined,
          formSettings: undefined,
          formEntry: undefined,
          pacsFormAssets: [],
          prefilledFormFields: [],
          status: SelectedFormActionsEnum.CLEAR
        },
      });
    }
  }, []);

  useEffect(() => {
    if (!formState.forms.length) {
      onLoadForms(formDispatch);
    }
  }, []);

  useEffect(() => {
    const stateFormId = formState?.forms?.find(x => x.name == BPPFormName)?.id ?? formId;
    const propertyId = searchParams.get("propertyId");
    const yearString = searchParams.get("year");
    const ownerId = searchParams.get("ownerId");
    const propertySearchRoot = globalConfig.get().propertySearchRoot;
    const referrer = sessionStorage.getItem('referrer');
    const viewMode = searchParams.get("viewMode");

    if (!stateFormId || !isAuthorized) {
      return;
    }

    if (formEntryId) {
      getSelectedFormEntryDataHelper(selectedFormDispatch, stateFormId, formEntryId);
    } else if (settings.isHarrisClient && referrer?.includes(propertySearchRoot) && propertyId && yearString && ownerId) {
      if(!!viewMode) {
        setDisablePrompt(true);
      }

      GetFormAssetsSettingsAndPACSPersonalPropertyTypeCodesHelper(formDispatch, stateFormId);
      getHarrisSelectedFormDataWithPrefilledDataHelper(
          selectedFormDispatch,
          stateFormId,
          BPPFormName,
          propertyId,
          parseInt(yearString),
          ownerId,
          initiatedByPropertySearch,
          navigate);
    } else {
      getSelectedFormDataHelper(selectedFormDispatch, stateFormId);
    }
  }, [formState.forms]);

  useEffect(() => {
    if (formState.personalPropertyCodes) {
      setPACSPropertyTypeCodes(formState.personalPropertyCodes);
    }
  }, [formState.personalPropertyCodes]);

  useEffect(() => {
    if (formState.formAssetsSettings) {
      setFormAssetsSettings(formState.formAssetsSettings);
    }
  }, [formState.formAssetsSettings]);

  function ClearData() {
    setFormDataFields([]);
    setFormEntry(undefined);
    setFormSecondSectionDataFields({});
    setFormThirdSectionDataFields({});
    setFormFourthSectionDataFields({});
    setFormFifthSectionDataFields({});
    setFormSixthSectionDataFields({});
    setFormSeventhSectionDataFields({});
    setFormEighthSectionDataFields({});
    setFormNinthSectionDataFields({});
    setFormTenthSectionDataFields({});
    setFormEleventhSectionDataFields({});
    setFormTwelfthSectionDataFields({});
    setFormThirteenthSectionDataFields({});
    setFormFourteenthSectionDataFields({});
    setFormFifteenthSectionDataFields({});
    setSecondSectionIsValid(false);
    setThirdSectionIsValid(false);
    setFourthSectionIsValid(false);
    setFifthSectionIsValid(false);
    setSixSectionIsValid(false);
    setSeventhSectionISValid(false);
    setEighthSectionIsValid(false);
    setNinthSectionIsValid(false);
    setTenthSectionIsValid(false);
    setEleventhSectionIsValid(false);
    setTwelfthSectionIsValid(false);
    setThirteenthSectionIsValid(false);
    setFourteenthSectionIsValid(false);
    setFifteenthSectionIsValid(false);
    setSupportingDocsChanged(false);
    setDisablePrompt(false);

    selectedFormDispatch({
      type: SelectedFormActionsEnum.PACS_FORM_ASSETS,
      payload: {
        pacsFormAssets: [],
      },
    });

    selectedFormDispatch({
      type: SelectedFormActionsEnum.FORM_FIELDS,
      payload: {
        formFields: [],
      },
    });
  }

  useEffect(() => {
    if (selectedFormState.status === SelectedFormActionsEnum.CLEAR) {
      ClearData();
      return;
    }
    setFormDataFields(selectedFormState.formFields);
    const assets = selectedFormState.pacsFormAssets;
    const viewMode = searchParams.get("viewMode");
    const propertySearchRoot = globalConfig.get().propertySearchRoot;
    const referrer = sessionStorage.getItem('referrer');
    const isPropertySearchRoot = (!!referrer?.includes(propertySearchRoot) && !isAdminPreview) ||
        selectedFormState.formEntry?.initiatedBy === initiatedByPropertySearch;

    const {
        SecondSection,
        ThirdSection,
        FourthSection,
        FifthSection,
        SixthSection,
        ThirteenSection,
        FourteenthSection,
        FifteenthSection,
        NewAssets
    } = parseBppFromSections(selectedFormState.formFields)

    if (selectedFormState.formFields.length > 0) {
      setFormSecondSectionDataFields(SecondSection);
      setFormThirdSectionDataFields(ThirdSection);
      setFormFourthSectionDataFields(FourthSection);
      setFormFifthSectionDataFields(FifthSection);
      setFormSixthSectionDataFields(SixthSection);
      setFormThirteenthSectionDataFields(ThirteenSection);
      setFormFourteenthSectionDataFields(FourteenthSection);
      setFormFifteenthSectionDataFields(FifteenthSection);
    }
  }, [selectedFormState.formFields]);

  useEffect(() => {
    if (selectedFormState?.formEntry) {
      if (selectedFormState.form?.name) {

        setFormEntry({
          ...selectedFormState?.formEntry,
          formName: selectedFormState.form?.name,
        });

        const propertySearchRoot = globalConfig.get().propertySearchRoot;
        const referrer = sessionStorage.getItem('referrer');

        if( formState.personalPropertyCodes.length === 0 &&
            formState.formAssetsSettings.length === 0 &&
            selectedFormState?.formEntry?.id > 0 &&
            selectedFormState?.formEntry.initiatedBy === initiatedByPropertySearch
        ) {
          GetFormAssetsSettingsAndPACSPersonalPropertyTypeCodesHelper(formDispatch, selectedFormState.form?.id);
        }

      } else {
        setFormEntry(selectedFormState.formEntry)
      }
    }
  }, [selectedFormState.formEntry, selectedFormState.form]);

  function getPriorYearFormFieldsHandler(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();
    if (formEntry?.formId && formSecondSectionDataFields?.AccountNumber && formEntry?.accessCode) {
      getPriorYearFormFieldsHelper(selectedFormDispatch,
        setFormFourteenthSectionDataFields,
        formEntry?.formId, formSecondSectionDataFields.AccountNumber,
        formEntry?.accessCode, formFourteenthSectionDataFields);
    }
  }

  const navigate = useNavigate();

  function saveHandler() {
    setDisablePrompt(true);

    setLoading(selectedFormDispatch, true);

    const assetsToSave = fromSegments(newAssets || [], assets);
    const deletedAssetsToSave: IDeletedAssetsInterface = { DeletedAssetsList: deletedAssets || [] };

    const fields = GetFormFieldFormSections({
      formFields: selectedFormState.formFields,
      secondSection: formSecondSectionDataFields,
      thirdSection: formThirdSectionDataFields,
      fourthSection: formFourthSectionDataFields,
      fifthSection: formFifthSectionDataFields,
      sixthSection: formSixthSectionDataFields,
      scheduleA: formSeventhSectionDataFields,
      scheduleB: formEighthSectionDataFields,
      scheduleC: formNinthSectionDataFields,
      scheduleD: formTenthSectionDataFields,
      scheduleE: formEleventhSectionDataFields,
      scheduleF: formTwelfthSectionDataFields,
      thirteenthSection: formThirteenthSectionDataFields,
      fourteenthSection: formFourteenthSectionDataFields,
      fifteenthSection: formFifteenthSectionDataFields,
      formId: formState?.forms?.find(x => x.name == BPPFormName)?.id ?? formId,
      formEntryId: formEntry?.id,
      assetsList: assetsToSave,
      deletedAssetsList: deletedAssetsToSave,
      allDocuments,
    });

    const temp = {
      ...formEntry,
      formName: selectedFormState.form?.name,
      submissionStatus: formEntry?.submissionStatus ?? saved,
    } as IFormEntryModel;
    setFormEntry(temp);
    setSupportingDocsChanged(false);
    if (selectedFormState.form?.name) {

      saveFormHelper(selectedFormDispatch, selectedFormState.form?.name, temp, fields, navigate)
          .then(() => true);
    }
  }

  function setFormEntryBase64Url(url: string) {
    setFormEntry({ ...formEntry, pdfBase64Url: url } as IFormEntryModel);
  }

  function submitHandler() {
    const temp = {
      ...formEntry,
      formName: selectedFormState.form?.name,
      submissionStatus: "Submitted",
    } as IFormEntryModel;
    setFormEntry(temp);
    setDisablePrompt(true);

    const assetsToSave = fromSegments(newAssets || [], assets);
    const deletedAssetsToSave: IDeletedAssetsInterface = { DeletedAssetsList: deletedAssets || [] };

    const fields = GetFormFieldFormSections({
      formFields: selectedFormState.formFields,
      secondSection: formSecondSectionDataFields,
      thirdSection: formThirdSectionDataFields,
      fourthSection: formFourthSectionDataFields,
      fifthSection: formFifthSectionDataFields,
      sixthSection: formSixthSectionDataFields,
      scheduleA: formSeventhSectionDataFields,
      scheduleB: formEighthSectionDataFields,
      scheduleC: formNinthSectionDataFields,
      scheduleD: formTenthSectionDataFields,
      scheduleE: formEleventhSectionDataFields,
      scheduleF: formTwelfthSectionDataFields,
      thirteenthSection: formThirteenthSectionDataFields,
      fourteenthSection: formFourteenthSectionDataFields,
      fifteenthSection: formFifteenthSectionDataFields,
      formId: formState?.forms?.find(x => x.name == BPPFormName)?.id ?? formId,
      formEntryId: formEntry?.id,
      assetsList: assetsToSave,
      deletedAssetsList: deletedAssetsToSave,
      allDocuments
    });

    setFormDataFields(fields);
    setSupportingDocsChanged(false);

    if (selectedFormState.form?.name) {
      ClearData();
      const formName = selectedFormState.form?.name;
      saveFormHelper(selectedFormDispatch, formName, temp, fields, navigate)
          .then(() => {setVisible(false);});
    }
  }

  const [visible, setVisible] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);

  const toggleDialog = () => {
    if (!visible) {
      setLoading(selectedFormDispatch, true);
    }
    setVisible(!visible);
  };
  const toggleSubmitDialog = () => {
    if (!visible) {
      setLoading(selectedFormDispatch, true);
    }
    setSubmit(!submit);
    setVisible(!visible);
  };

  const dialogTitle = (
    <div>
      {submit && formEntry?.submissionStatus != "Submitted" && (
        <button className="button" onClick={submitHandler}>
          submit form
        </button>
      )}
    </div>
  );

  return (<>
    <div className={classes.form}>
      {visible && (
        <Dialog
          className="customFormDialog"
          title={dialogTitle}
          onClose={submit ? toggleSubmitDialog : toggleDialog}
          width={"70vw"}
          height={"90vh"}
        >
          <BPPFormPDFView
            getPdfBase64Url={setFormEntryBase64Url}
            accessCode={formEntry?.accessCode ?? ""}
            formDataFieldsSecondSection={formSecondSectionDataFields}
            formDataFieldsThirdSection={formThirdSectionDataFields}
            formDataFieldsFourthSection={formFourthSectionDataFields}
            formDataFieldsFifthSection={formFifthSectionDataFields}
            formDataFieldsSixthSection={formSixthSectionDataFields}
            formDataFieldsSeventhSection={formSeventhSectionDataFields}
            formDataFieldsEighthSection={formEighthSectionDataFields}
            formDataFieldsNinthSection={formNinthSectionDataFields}
            formDataFieldsTenthSection={formTenthSectionDataFields}
            formDataFieldsEleventhSection={formEleventhSectionDataFields}
            formDataFieldsTwelfthSection={formTwelfthSectionDataFields}
            formDataFieldsThirteenthSection={formThirteenthSectionDataFields}
            formDataFieldsFifteenSection={formFifteenthSectionDataFields}
            supportingDocuments={allDocuments}
            assets={isAssetsVisible ? assets : undefined}
          />
        </Dialog>
      )}

      <FormHeader
        formName={BPPFormName}
        isAdminPreview={isAdminPreview}
        isAuthorized={isAuthorized}
        formEntry={formEntry}
        formSettings={formSettings}
        isFormValid={isFormValid}
        onPrint={() => toggleDialog()}
        onSave={() => saveHandler()}
        onSubmit={() => toggleSubmitDialog()}
        showSaveOnSubmitted={supportingDocsChanged}
      />

            <BPPFormFirstSection />

            <BPPFormSecondSection
              isAdminPreview={isAdminPreview}
              formSettings={formSettings}
              formDataFields={formSecondSectionDataFields}
              setFormDataFields={setFormSecondSectionDataFields}
              setFormIsValid={setSecondSectionIsValid}
              allSectionTouched={allSectionsTouched}
            />

            <BPPFormThirdSection
              isAdminPreview={isAdminPreview}
              formSettings={formSettings}
              formFields={formSecondSectionDataFields}
              formDataFields={formThirdSectionDataFields}
              formEntry={formEntry}
              setFormDataFields={setFormThirdSectionDataFields}
              setFormIsValid={setThirdSectionIsValid}
              selectedFormDispatch={selectedFormDispatch}
            />

            <BPPFormFourthSection
              isAdminPreview={isAdminPreview}
              formSettings={formSettings}
              formDataFields={formFourthSectionDataFields}
              setFormDataFields={setFormFourthSectionDataFields}
              getPriorYearFormFieldsHandler={e => getPriorYearFormFieldsHandler(e)}
              setFormIsValid={setFourthSectionIsValid}
              secondSectionDataFields={formSecondSectionDataFields}
              fourteenthSectionDataFields={formFourteenthSectionDataFields}
            />

            <BPPFormFifthSection
              isAdminPreview={isAdminPreview}
              formSettings={formSettings}
              formFields={formDataFields}
              formDataFields={formFifthSectionDataFields}
              setFormDataFields={setFormFifthSectionDataFields}
              setFormIsValid={setFifthSectionIsValid}
              allSectionTouched={allSectionsTouched}
            />

            <BPPFormSixthSection
              isAdminPreview={isAdminPreview}
              formSettings={formSettings}
              setFormDataFields={setFormSixthSectionDataFields}
              formDataFields={formSixthSectionDataFields}
              setFormIsValid={setSixSectionIsValid}
            />

            { isAssetsVisible &&
              <BPPFormAssetsTable
                  addAsset={addAsset}
                  removeAsset={removeAsset}
                  restoreAsset={restoreAsset}
                  updateAsset={updateAsset}
                  disabled={formSettings?.isReadOnly}
                  assets={assets}
                  personalPropertyTypeCodes={PACSPropertyTypeCodes}
                  assetSettings={formAssetsSettings}
                  setFormIsValid={setAssetsGridIsValid}
              /> }

            <BPPFormSeventhSection
              isAdminPreview={isAdminPreview ||
                  (settings.isHarrisClient &&
                      formEntry?.initiatedBy === initiatedByPropertySearch)}
              formSettings={formSettings}
              formDataFields={formSeventhSectionDataFields}
              setFormDataFields={setFormSeventhSectionDataFields}
              setFormIsValid={setSeventhSectionISValid}
              formEntry={formEntry}
            />

            <BPPFormEighthSection
              isAdminPreview={isAdminPreview ||
                  (settings.isHarrisClient &&
                      formEntry?.initiatedBy === initiatedByPropertySearch)}
              formSettings={formSettings}
              formDataFields={formEighthSectionDataFields}
              setFormDataFields={setFormEighthSectionDataFields}
              setFormIsValid={setEighthSectionIsValid}
              formEntry={formEntry}
            />

            <BPPFormNinthSection
              isAdminPreview={isAdminPreview ||
                  (settings.isHarrisClient &&
                      formEntry?.initiatedBy === initiatedByPropertySearch)}
              formSettings={formSettings}
              formDataFields={formNinthSectionDataFields}
              setFormDataFields={setFormNinthSectionDataFields}
              setFormIsValid={setNinthSectionIsValid}
              formEntry={formEntry}
            />

            <BPPFormTenthSection
              isAdminPreview={isAdminPreview ||
                  (settings.isHarrisClient &&
                      formEntry?.initiatedBy === initiatedByPropertySearch)}
              formSettings={formSettings}
              formDataFields={formTenthSectionDataFields}
              setFormDataFields={setFormTenthSectionDataFields}
              setFormIsValid={setTenthSectionIsValid}
              formEntry={formEntry}
            />

            <BPPFormEleventhSection
              isAdminPreview={isAdminPreview ||
                  (settings.isHarrisClient &&
                      formEntry?.initiatedBy === initiatedByPropertySearch)}
              formSettings={formSettings}
              formDataFields={formEleventhSectionDataFields}
              setFormDataFields={setFormEleventhSectionDataFields}
              setFormIsValid={setEleventhSectionIsValid}
              formEntry={formEntry}
            />

            <BPPFormTwelfthSection
              isAdminPreview={isAdminPreview ||
                  (settings.isHarrisClient &&
                      formEntry?.initiatedBy === initiatedByPropertySearch)}
              formSettings={formSettings}
              formDataFields={formTwelfthSectionDataFields}
              setFormDataFields={setFormTwelfthSectionDataFields}
              setFormIsValid={setTwelfthSectionIsValid}
              formEntry={formEntry}
            />

            <BPPFormThirteenthSection
              isAdminPreview={isAdminPreview}
              formSettings={formSettings}
              formDataFields={formThirteenthSectionDataFields}
              setFormDataFields={setFormThirteenthSectionDataFields}
              setFormIsValid={setThirteenthSectionIsValid}
            />

            <BPPFormFourteenthSection
              isAdminPreview={isAdminPreview}
              formSettings={formSettings}
              formDataFields={formFourteenthSectionDataFields}
              formEntry={formEntry}
              setFormDataFields={setFormFourteenthSectionDataFields}
              setFormIsValid={setFourteenthSectionIsValid}
              selectedFormDispatch={selectedFormDispatch}
              setStateChanged={setSupportingDocsChanged}
            />

            <BPPFormFifteenthSection
              isAdminPreview={isAdminPreview}
              formSettings={formSettings}
              formDataFields={formFifteenthSectionDataFields}
              setFormDataFields={setFormFifteenthSectionDataFields}
              setFormIsValid={setFifteenthSectionIsValid}
            />

          {(!isFormValid && !formSettings?.isReadOnly) &&
            <div className={classes.invalidForm}>
              <h2>Please Fill All Required Fields in Steps:</h2>
              {!secondSectionIsValid &&
              <div  className={classes.item}>
                2.   Owner Information
              </div> }
              {!thirdSectionIsValid &&
                  <div  className={classes.item}>
                   3. Representation
                  </div> }
              {!fourthSectionIsValid &&
                  <div  className={classes.item}>
                    4.   Prior Year Rendition
                  </div> }
              {!fifthSectionIsValid &&
                  <div  className={classes.item}>
                    5.   Business Information
                  </div> }
              {!sixSectionIsValid &&
                  <div  className={classes.item}>
                    6.  Market Value
                  </div> }
              {!assetsGridIsValid &&
                  <div  className={classes.item}>
                    Assets Grid
                  </div> }
              {!seventhSectionIsValid &&
                  <div  className={classes.item}>
                    7.   Schedule A
                  </div> }
              {!eighthSectionIsValid &&
                  <div  className={classes.item}>
                    8.   Schedule B
                  </div> }
              {!ninthSectionIsValid &&
                  <div  className={classes.item}>
                    9.   Schedule C
                  </div> }
              {!tenthSectionIsValid &&
                  <div  className={classes.item}>
                    10.   Schedule D
                  </div> }
              {!eleventhSectionIsValid &&
                  <div  className={classes.item}>
                    11.   Schedule E
                  </div> }
              {!twelfthSectionIsValid &&
                  <div  className={classes.item}>
                    12.   Schedule F
                  </div> }
              {!thirdSectionIsValid &&
                  <div  className={classes.item}>
                    13.   Optional
                  </div> }
              {!fourthSectionIsValid &&
                  <div  className={classes.item}>
                    14.   Supporting Documents
                  </div> }
              {!fifteenthSectionIsValid &&
                  <div  className={classes.item}>
                    15.   Signature
                  </div> }
            </div> }

          {!formSettings?.isReadOnly && !isAdminPreview && formEntry?.submissionStatus != "Submitted" && (
              <button
                  disabled={!isFormValid}
                  className="button"
                  onClick={() => isFormValid ? toggleSubmitDialog() : setAllSectionsTouched(true)}
              >
                <i className="fa fa-tasks fa-fw"></i>
                Review & Submit
              </button>)
          }
      </div>
      {(loading || isLoading) && <CustomLoader />}
      {showPrompt && isAuthorized && (
        <UnsavedFormDialog
          onConfirm={confirmNavigation}
          onCancel={cancelNavigation}
        />
      )}
  </>
  );
}
