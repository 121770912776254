export const BPPFormName = "BPP";
export const HomesteadExemptionFormName = "HomesteadExemption";
export const AgFormName = "AgForm";
export const DisabledVetFormName = "DisabledVet";
export const FreeportFormName = "FreeportForm";
export const TimberlandAppraisalFormName = "TimberlandAppraisal";
export const WildlifeManagementFormName = "WildlifeManagement";
export const AddressChangeFormName = "AddressChangeForm";
export const TemporaryExemptionPropertyDamagedbyDisasterFormName = "TemporaryExemptionPropertyDamagedByDisaster";
export const AllFormNames = [BPPFormName, HomesteadExemptionFormName, AgFormName,
    DisabledVetFormName, FreeportFormName, TimberlandAppraisalFormName, WildlifeManagementFormName,
    AddressChangeFormName, TemporaryExemptionPropertyDamagedbyDisasterFormName];