import { PanelBar } from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import { PanelBarItem } from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./WildlifeManagement.module.scss";
import { UploadFileInfo } from "@progress/kendo-react-upload";
import { IFormSettingModel } from "../../../../../interfaces/form-settings.interface";
import { IFormEntryModel } from "../../../../../interfaces/form-entry.interface";
import { useEffect, useRef, useState } from "react";
import { SelectedFormType } from "../../../../../context/SelectedForm/selected-form-reducer";
import { GenericActionType } from "../../../../../context/generic-actions.type";
import { CustomUpload } from "../CustomInputs/CustomUpload";
import { IWildlifeManagementFifthSection } from "src/interfaces/forms/WildlifeManagement/wildlife-management-fifth-section.interface";
import { Label } from "@progress/kendo-react-labels";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IWildlifeManagementFifthSection) => void;
    formDataFields?:  IWildlifeManagementFifthSection,
    formSettings?: IFormSettingModel,
    formEntry?: IFormEntryModel,
    setFormIsValid: (value: boolean) => void,
    selectedFormDispatch: (value: GenericActionType<SelectedFormType>) => void,
}

export default function WildlifeManagementFifthSection(props: GeneralFormType): JSX.Element {
    const { formDataFields, setFormDataFields, formSettings, isAdminPreview, formEntry, setFormIsValid, selectedFormDispatch } = props;
    const formRef = useRef<HTMLFormElement>(null);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const [isAdditionalDocumentsFilesUploadValid, setIsAdditionalDocumentsFilesUploadValid] = useState<boolean>(true);
    const [additionalDocumentsFiles, setAdditionalDocumentsFiles] = useState<Array<UploadFileInfo>>([]);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} part={"V"} name={"Supporting Documents"}/>;

    useEffect(() => {
        return () => {
            setAdditionalDocumentsFiles([]);
        }
    }, []);

    function changeValidator() {
        if (formRef.current) {
            const isValid = isAdditionalDocumentsFilesUploadValid &&
                formRef.current.checkValidity();
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if(!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current, isAdditionalDocumentsFilesUploadValid]);


    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={classes.itemFields}>
                                <Label className={`${classes.Label} ${classes.fieldsGroupW100}`}>
                                    Attach copies of supporting documentation such as receipts, maps, photos, etc.
                                </Label>
                            </div>
                            <div className={`${classes.itemFields}`}>
                                <div className={`${classes.fieldsGroupW100}`}>
                                    <div className="inputsGroup">
                                        <CustomUpload
                                            files={additionalDocumentsFiles}
                                            setFiles={setAdditionalDocumentsFiles}
                                            uploadIsValid={isAdditionalDocumentsFilesUploadValid}
                                            setUploadIsValid={setIsAdditionalDocumentsFilesUploadValid}
                                            formEntry={formEntry}
                                            formSettings={formSettings}
                                            isAdminPreview={isAdminPreview}
                                            setFormDataFields={setFormDataFields}
                                            formDataFields={formDataFields}
                                            fieldName={"AdditionalDocuments"}
                                            isRequired={!!formSettings?.isRequiredUploadAdditionalSupportingDocuments && 
                                                (!!isAdminPreview || !formSettings.isReadOnly)}
                                            selectedFormDispatch={selectedFormDispatch}
                                            multiple={true}
                                            isLabelRequired={true}
                                            labelText={"Additional Documents"} />
                                    </div>

                                    <div className={classes.alert + " " + classes.alertWarning}>
                                        <div className="ng-scope">File cannot be larger then 10mb</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
