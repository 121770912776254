import instance from "src/axios-instance";
import type { IFormField } from "src/interfaces/admin/forms/form-field.interface";
import type { IFormWithKeyFields } from "src/interfaces/admin/forms/form-with-key-fields.interface";
import { IFormSettingTypeModel } from "src/interfaces/admin/forms/form-settings.interface";
import { AxiosResponse } from "axios";
import { IFormFilesInterface } from "src/interfaces/forms/Shared/form-files.interface";
import { IFormAssetsSettingsInterface } from "../interfaces/form-assets-settings.interface";
import { IImageType } from 'src/interfaces/admin/form-settings/image-type.interface';
import { IFormFiledInterface } from "src/interfaces/form-filed.interface";

export function getAvailableForms(): any {
  return instance.get("forms/available");
}

export function getForms(): any {
  return instance.get("forms");
}

// todo: check if it needed
export function getAdminForms(): any {
  return instance.get("forms/admin");
}

export function getAssignedForms(): any {
  return instance.get("forms/assigned");
}

export function getFormEntries(): any {
  return instance.get("forms/form-entries");
}

export function getFormsAssignedStatistics(): any {
  return instance.get("form-entries/forms-assigned-statistics");
}

export function getFormById(id: number): any {
  return instance.get(`forms/${id}`);
}

export function getFormSettings(year: number, formId: number): any {
  return instance.get(`forms/form-settings/${year}/${formId}`);
}

export function getFormAssetsSettings(formId: number): any {
  return instance.get(`forms/form-assets/${formId}`);
}

export function updateFormAssetsSettings(formId: number, statuses: IFormAssetsSettingsInterface[]): any {
  return instance.put(`forms/form-assets/${formId}`, statuses);
}

export function getPrefilledDataForHarrisForm(formId: number, propertyId: string, ownerId: string, year: number):
    Promise<AxiosResponse<{fields:IFormFiledInterface[], assetsJson:string}, any>> {
  return instance.get(`form-entries/prefilled-data/${formId}/${propertyId}/${ownerId}/${year}`);
}

export function getFormSettingsYears(formId: number): any {
  return instance.get(`forms/form-settings-years/${formId}`);
}

export function getDefaultImagePathSettings(): Promise<AxiosResponse<IImageType[], any>> {
  return instance.get(`forms/default-image-path-settings`);
}

export function copyToNextYearSetting(year: number, formId: number) {
  return instance.get(`forms/form-settings/copy/${year}/${formId}`);
}

export function getFormFields(formId: number): any {
  return instance.get(`forms/fields/${formId}`);
}

export function updateFormFields(statuses: IFormField[]): any {
  return instance.put("forms/fields", statuses);
}

export function updateGeneralSettings(
  form: IFormWithKeyFields,
  formSettings: IFormSettingTypeModel
): any {
  const params = {
    form,
    formSettings,
  };
  return instance.put("forms/form-settings", params);
}

export function saveSupportingDocuments(formData: FormData): Promise<AxiosResponse<IFormFilesInterface[], any>> {
  return instance.post<IFormFilesInterface[]>(`supporting-documents/upload`, formData, {headers: {'Content-Type': 'multipart/form-data' }});
}

export function removeSupportingDocuments(formId: number, formData: FormData): Promise<AxiosResponse<IFormFiledInterface, any>> {
  return instance.post(`supporting-documents/remove/${formId}`, formData);
}
