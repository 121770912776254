import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./BPPForm.module.scss";
import {UploadFileInfo} from "@progress/kendo-react-upload";
import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import {IFormEntryModel} from "../../../../../interfaces/form-entry.interface";
import {
    IBppFormFourteenthSectionInterface
} from "../../../../../interfaces/forms/BPPForm/bpp-form-fourteenth-section.interface";
import {useEffect, useRef, useState} from "react";
import {SelectedFormType} from "../../../../../context/SelectedForm/selected-form-reducer";
import {GenericActionType} from "../../../../../context/generic-actions.type";
import {CustomUpload} from "../CustomInputs/CustomUpload";
import SectionTitle from "../Shared/SectionTitle";
import { IFormFilesInterface } from "src/interfaces/forms/Shared/form-files.interface";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: React.Dispatch<React.SetStateAction<IBppFormFourteenthSectionInterface | undefined>>;
    formDataFields?:  IBppFormFourteenthSectionInterface,
    formSettings?: IFormSettingModel,
    formEntry?: IFormEntryModel,
    setFormIsValid: (value: boolean) => void,
    selectedFormDispatch: (value: GenericActionType<SelectedFormType>) => void,
    setStateChanged: (value: boolean) => void
}

export default function BPPFormFourteenthSection(props: GeneralFormType): JSX.Element {
    const formRef = useRef<HTMLFormElement>(null);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={14} name={"Supporting Documents"}/>;

    const { formDataFields, setFormDataFields, formSettings, isAdminPreview, formEntry, setFormIsValid,selectedFormDispatch, setStateChanged } = props;
    const [files, setFiles] = useState<Array<UploadFileInfo>>([]);

    const [isSupportingFilesUploadValid, setIsSupportingFilesUploadValid] = useState<boolean>(true);

    function onRemoveDocument(file: IFormFilesInterface) {
        const priorRenditon = formDataFields?.PriorYearRenditionAdded;
        if (priorRenditon && file.name === priorRenditon) {
            setFormDataFields(fields => ({
                ...fields,
                PriorYearRenditionAdded: undefined,
            }))
        }
    }

    useEffect(() =>{
        return () => {
            setFiles([]);
        }
    },[]);

    function changeValidator() {
        if (formRef.current) {
            const isValid = isSupportingFilesUploadValid &&
                formRef.current.checkValidity();
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if(!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current, isSupportingFilesUploadValid]);

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={`${classes.itemFields}`}>
                                <div className={`${classes.fieldsGroupW100}`}>
                                    <div className="inputsGroup">
                                        <CustomUpload
                                            files={files}
                                            setFiles={setFiles}
                                            uploadIsValid={isSupportingFilesUploadValid}
                                            setUploadIsValid={setIsSupportingFilesUploadValid}
                                            formEntry={formEntry}
                                            formSettings={formSettings}
                                            isAdminPreview={isAdminPreview}
                                            setFormDataFields={setFormDataFields}
                                            formDataFields={formDataFields}
                                            fieldName={"SupportingFiles"}
                                            isRequired={false}
                                            selectedFormDispatch={selectedFormDispatch}
                                            multiple={true}
                                            isLabelRequired={true}
                                            labelText={"Books and Documents"}
                                            setStateChanged={setStateChanged}
                                            onFileRemoved={onRemoveDocument}
                                        />
                                    </div>

                                    <div className={classes.alert + " " + classes.alertWarning}>
                                        <div className="ng-scope">File cannot be larger then 10mb. Only PDF, JPG and PNG files can be added</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
