import type { Dispatch } from "react";
import { FormActionsEnum } from "src/context/Form/form-actions";
import type { GenericActionType } from "src/context/generic-actions.type";
import type { IFormEntry } from "src/interfaces/form-entry.interface";
import type { IForm } from "src/interfaces/form.interface";
import {IPacsPersonalPropertyTypeCodeInterface} from "../../interfaces/pacs-personal-property-type-code.interface";
import {IFormAssetsSettingsInterface} from "../../interfaces/form-assets-settings.interface";

export interface FormContextType {
  formState: InitialFormStateType;
  formDispatch: Dispatch<GenericActionType<FormType>>;
}

export interface InitialFormStateType {
  forms: IForm[];
  formEntries: IFormEntry[];
  selectedFormEntry: IFormEntry | null;
  loading: boolean;
  errorMessage: string | null;
  personalPropertyCodes: Array<IPacsPersonalPropertyTypeCodeInterface>;
  formAssetsSettings: Array<IFormAssetsSettingsInterface>;
}

export type FormType = {
  loading?: boolean;
  forms?: IForm[];
  formEntries?: IFormEntry[];
  selectedFormEntry?: IFormEntry;
  errorMessage?: string;
  personalPropertyCodes?: Array<IPacsPersonalPropertyTypeCodeInterface>;
  formAssetsSettings?: Array<IFormAssetsSettingsInterface>;
};

export const initialState: InitialFormStateType = {
  forms: [],
  formEntries: [],
  selectedFormEntry: null,
  loading: false,
  errorMessage: "",
  personalPropertyCodes: [],
  formAssetsSettings: [],
};

export const FormReducer = (
  initialState: InitialFormStateType,
  action: GenericActionType<FormType>
): InitialFormStateType => {
  switch (action.type) {
    case FormActionsEnum.IS_LOADING:
      return {
        ...initialState,
        loading: action?.isLoading ?? false,
      };
    case FormActionsEnum.FORMS:
      return {
        ...initialState,
        forms: action?.payload?.forms ?? [],
      };
    case FormActionsEnum.FORM_ENTRIES:
      return {
        ...initialState,
        formEntries: action?.payload?.formEntries ?? [],
      };
    case FormActionsEnum.SELECTED_FORM_ENTRY:
      return {
        ...initialState,
        selectedFormEntry: action?.payload?.selectedFormEntry ?? null,
      };
    case FormActionsEnum.PERSONAL_PROPERTY_CODES:
      return {
        ...initialState,
        personalPropertyCodes: action.payload?.personalPropertyCodes
            ? action.payload?.personalPropertyCodes
            : [],
      };
    case FormActionsEnum.FORM_ASSETS_SETTINGS:
      return {
        ...initialState,
        formAssetsSettings: action.payload?.formAssetsSettings
            ? action.payload?.formAssetsSettings
            : [],
      };
    case FormActionsEnum.ERROR:
      return {
        ...initialState,
        errorMessage: action?.error ?? "",
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
