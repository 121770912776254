import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import {
    CheckboxChangeEvent, Input,
    InputChangeEvent, NumericTextBox, NumericTextBoxChangeEvent,
    RadioGroupChangeEvent,
    TextAreaChangeEvent
} from "@progress/kendo-react-inputs";
import {DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import React, {useEffect, useRef, useState} from "react";
import {IBppFormTenthSectionInterface} from "../../../../../interfaces/forms/BPPForm/bpp-form-tenth-section.interface";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./BPPForm.module.scss";
import {Label} from "@progress/kendo-react-labels";
import { IFormEntryModel } from "../../../../../interfaces/form-entry.interface";
import {IRowState} from "../CustomInputs/UpdateRowErrorStateHandler";
import {CustomRowDatePicker} from "../CustomInputs/CustomDatePickers/CustomRowDatePicker";
import {ScheduleDName} from "../../../../../constants/form-fields-constants";
import {NumberFormatOptions} from "@telerik/kendo-intl";
import {CustomOnlyYearCalendarHelper} from "../../../../Shared/DatePickers/CustomCalendars";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IBppFormTenthSectionInterface) => void;
    formDataFields?:  IBppFormTenthSectionInterface,
    formSettings?: IFormSettingModel
    setFormIsValid: (value: boolean) => void;
    formEntry?: IFormEntryModel;
}

const maxScheduleYearDate = new Date(new Date().getFullYear(), 1,1);

export function BPPFormTenthSection(props: GeneralFormType): JSX.Element {
    const formRef = useRef<HTMLFormElement>(null);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={10} name={"Schedule D"}/>;

    const {setFormDataFields, formDataFields, formSettings, isAdminPreview, setFormIsValid, formEntry} = props;
    const currencyFormatOptions: NumberFormatOptions = { maximumFractionDigits: 0 };
    const { CustomOnlyYearCalendar } = CustomOnlyYearCalendarHelper(maxScheduleYearDate.getFullYear() - 1);
    const editDisabled = formSettings?.isReadOnly || formEntry?.initiatedBy === 'PropertySearch' || isAdminPreview;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | NumericTextBoxChangeEvent,
        field: string,
        id: number,
    ) {
        const newItem: IBppFormTenthSectionInterface = formDataFields ? JSON.parse(JSON.stringify(formDataFields)) : [];

        let ScheduleD = newItem.ScheduleD?.find(x => x.Id == id);

        if(field === "YearAcquired" || field === "PropertyYear" || field === "PropertyVehicleYear"){
            ScheduleD = {...ScheduleD, [field]: event.value ?  event.value.getFullYear() : undefined, ScheduleName: ScheduleDName};

        } else {
            ScheduleD = {...ScheduleD, [field]: event.value, ScheduleName: ScheduleDName};
        }

        const index = newItem.ScheduleD?.findIndex(x => x.Id == id);

        if (index != undefined && newItem.ScheduleD) {
            newItem.ScheduleD[index] = ScheduleD
        }
        setFormDataFields({
            ...newItem
        })
    }

    function addRowHandler(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();
        const newItem: IBppFormTenthSectionInterface = formDataFields ? JSON.parse(JSON.stringify(formDataFields)) : [];
        if (!newItem.ScheduleD) {
            newItem.ScheduleD = [];
        }
        newItem.ScheduleD?.push({Id: newItem.ScheduleD?.length});
        setFormDataFields({
            ...newItem,
        });
    }

    function removeRowHandler(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();
        const newItem: IBppFormTenthSectionInterface = formDataFields ? JSON.parse(JSON.stringify(formDataFields)) : [];
        if (newItem && newItem.ScheduleD) {
            newItem.ScheduleD.pop();
            setFormDataFields({
                ...newItem,
            });
        }
    }

    function getDateValue(id: number, field: string): any {
        const item = (formDataFields?.ScheduleD?.find(x=> x.Id == id) as  any)?.[field];

        if(item) {
            return new Date((typeof item) === "string" ? +item : item,2,2);
        }

        return null
    }

    const [dateErrorsArray, setDateErrorsArray] = useState<Array<IRowState>>([]);

    function changeValidator() {
        if(formRef.current) {
            const isValid = formRef.current.checkValidity() &&
                !dateErrorsArray.some(x=>x.state === false);

            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if(!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [
        formDataFields,
        formDataFields?.ScheduleD,
        formRef.current,
        formRef?.current?.elements,
        dateErrorsArray
    ]);


    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <Label className={classes.ScheduleLabel}>VEHICLES AND TRAILERS AND SPECIAL EQUIPMENT</Label>
                            <div className={classes.information}>
                                List only vehicles that are licensed in the name of the business as shown on Page 1. Vehicles disposed of after Jan. 1 are taxable for the year and must be listed below. If needed,attach additional sheets OR a computer generated listing of the information below. Report leased vehicles under Schedule F. Leased vehicles must be reported showing the name and address of the owner.
                            </div>
                            <table className="blue">
                                <thead>
                                <tr>
                                    <th>Year (optional)</th>
                                    <th>Make (optional)</th>
                                    <th>Model (optional)</th>
                                    <th className="thw20">
                                        Vehicle Identification Number (VIN)
                                        <br />
                                        (optional)
                                    </th>
                                    <th>Good Faith Estimate of Market Value</th>
                                    <th>or</th>
                                    <th>Historical Cost When New</th>
                                    <th>Year Acquired</th>
                                </tr>
                                </thead>
                                <tbody>
                                {formDataFields?.ScheduleD?.filter(x=> !x.HasActiveSubSegments).map((item) => {
                                    return (
                                        <tr key={item.Id}>
                                            <td>
                                                <CustomRowDatePicker
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"PropertyVehicleYear"}
                                                    onlyYear={true}
                                                    required={false}
                                                    group={"ScheduleD"}
                                                    id={item.Id!}
                                                    errorsArray={dateErrorsArray}
                                                    setErrorsArray={setDateErrorsArray}
                                                    formDataFields={formDataFields}
                                                    defaultValueDate={getDateValue(item.Id!, "PropertyVehicleYear")}
                                                    disabled={editDisabled}
                                                    rowItem={ item.PropertyVehicleYear ? item.PropertyVehicleYear.toString() : undefined }
                                                    CustomCalendar={CustomOnlyYearCalendar}
                                                    maxDate={maxScheduleYearDate}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    className={classes.kTextbox}
                                                    disabled={editDisabled}
                                                    value={item.Make}
                                                    onChange={(e) =>
                                                        changeValueHandler(e, "Make", item.Id!)
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    className={classes.kTextbox}
                                                    disabled={editDisabled}
                                                    value={item.Model}
                                                    onChange={(e) =>
                                                        changeValueHandler(e, "Model", item.Id!)
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    className={classes.kTextbox}
                                                    disabled={editDisabled}
                                                    value={item.Vin}
                                                    onChange={(e) =>
                                                        changeValueHandler(e, "Vin", item.Id!)
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <NumericTextBox
                                                    disabled={editDisabled}
                                                    className={classes.kTextbox}
                                                    value={item.MarketValue}
                                                    onChange={e => changeValueHandler(e, "MarketValue", item.Id!)}
                                                    format={currencyFormatOptions}
                                                    spinners={false}
                                                />
                                            </td>
                                            <td></td>
                                            <td>
                                                <NumericTextBox
                                                    disabled={editDisabled}
                                                    className={classes.kTextbox}
                                                    value={item.HistoricCost}
                                                    onChange={e => changeValueHandler(e, "HistoricCost", item.Id!)}
                                                    format={currencyFormatOptions}
                                                    spinners={false}
                                                />
                                            </td>
                                            <td>
                                                <CustomRowDatePicker
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"YearAcquired"}
                                                    onlyYear={true}
                                                    required={false}
                                                    group={"ScheduleD"}
                                                    id={item.Id!}
                                                    errorsArray={dateErrorsArray}
                                                    setErrorsArray={setDateErrorsArray}
                                                    formDataFields={formDataFields}
                                                    defaultValueDate={getDateValue(item.Id!, "YearAcquired")}
                                                    disabled={editDisabled}
                                                    CustomCalendar={CustomOnlyYearCalendar}
                                                    rowItem={item.YearAcquired}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                            {!formSettings?.isReadOnly && !editDisabled && (
                                <div>
                                    <button
                                        className={classes.LightButton}
                                        disabled={editDisabled}
                                        onClick={(e) => addRowHandler(e)}
                                    >
                                        <i className="fa fas fa-plus"></i>
                                        Add Row
                                    </button>
                                    <button
                                        className={classes.LightButton}
                                        disabled={editDisabled}
                                        onClick={(e) => removeRowHandler(e)}
                                    >
                                        <i className="fa fas fa-minus"></i>
                                        Remove Row
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
