export interface IBppFormFifthSectionInterface{
    BusinessType?: IBusinessType,
    BusinessDescription?: string,
    SquareFeetOccupied?: string,
    SalesTaxPermitNumber?:string,
    BusinessMoved?: boolean,
    BusinessMovedDate?: string,
    BusinessStartDateAtLocation?: string,
    NewLocationAddressLine1?: string,
    NewLocationAddressLine2?: string,
    NewLocationAddressCity?: string,
    NewLocationAddressState?: string,
    NewLocationAddressZip?: string,
    BusinessWasSold?: boolean,
    BusinessSoldDate?: string,
    NewOwner?: string,
    BusinessWasClosed?: boolean,
    BusinessClosedDate?: string,
    AssetsRemainedInPlace?: "true" | "false",
    OwnedNoTaxableAssets?: boolean
}

export interface IBusinessType{
    Manufacturing?: boolean,
    Wholesale?: boolean,
    Retail?: boolean,
    Service?: boolean,
    NewBusiness?: boolean,
}

export const FifthSectionInitialState = {
    BusinessType: undefined,
    BusinessDescription: undefined,
    SquareFeetOccupied: undefined,
    SalesTaxPermitNumber: undefined,
    BusinessMoved: undefined,
    BusinessMovedDate: undefined,
    BusinessStartDateAtLocation: undefined,
    NewLocationAddressLine1: undefined,
    NewLocationAddressLine2: undefined,
    NewLocationAddressCity: undefined,
    NewLocationAddressState: undefined,
    NewLocationAddressZip: undefined,
    BusinessWasSold: undefined,
    BusinessSoldDate: undefined,
    NewOwner: undefined,
    BusinessWasClosed: undefined,
    BusinessClosedDate: undefined,
    AssetsRemainedInPlace: undefined,
    OwnedNoTaxableAssets: undefined,
}