import { render } from "react-dom";
import { UserProvider } from "src/context/User/user-context";
import App from "./App";
import { AdminPermissionProvider } from "./context/admin/permission/permission-context";
import { FormProvider } from "./context/Form/form-context";

const rootElement = document.getElementById("root");

render(
  <FormProvider>
    <UserProvider>
      <AdminPermissionProvider>
        <App />
      </AdminPermissionProvider>
    </UserProvider>
  </FormProvider>,
  rootElement
);
