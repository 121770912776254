import { IFormSettingModel } from "../../../../../interfaces/form-settings.interface";
import {
  CheckboxChangeEvent,
  Input,
  InputChangeEvent,
  RadioGroupChangeEvent,
  TextAreaChangeEvent,
} from "@progress/kendo-react-inputs";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import React, {useEffect, useRef, useState} from "react";
import { IBppFormTwelfthSectionInterface } from "../../../../../interfaces/forms/BPPForm/bpp-form-twelfth-section.interface";
import { PanelBar } from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import { PanelBarItem } from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./BPPForm.module.scss";
import {Label} from "@progress/kendo-react-labels";
import { IFormEntryModel } from "../../../../../interfaces/form-entry.interface";
import {ScheduleFName} from "../../../../../constants/form-fields-constants";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
  isAdminPreview?: boolean;
  setFormDataFields: (form: IBppFormTwelfthSectionInterface) => void;
  formDataFields?: IBppFormTwelfthSectionInterface;
  formSettings?: IFormSettingModel;
  setFormIsValid: (value: boolean) => void;
  formEntry?: IFormEntryModel;
}

export function BPPFormTwelfthSection(props: GeneralFormType): JSX.Element {

  const formRef = useRef<HTMLFormElement>(null);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
  const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={12} name={"Schedule F"}/>;
  const { setFormDataFields, formDataFields, formSettings, isAdminPreview,setFormIsValid, formEntry } = props;
  const editDisabled = formSettings?.isReadOnly || formEntry?.initiatedBy === 'PropertySearch' || isAdminPreview;

  function changeValueHandler(
    event:
      | CheckboxChangeEvent
      | DatePickerChangeEvent
      | TextAreaChangeEvent
      | DropDownListChangeEvent
      | InputChangeEvent
      | RadioGroupChangeEvent,
    field: string,
    id: number
  ) {
    const newItem: IBppFormTwelfthSectionInterface = formDataFields
      ? JSON.parse(JSON.stringify(formDataFields))
      : [];

    let ScheduleF = newItem.ScheduleF?.find((x) => x.Id == id);

    ScheduleF = { ...ScheduleF, [field]: event.value, ScheduleName: ScheduleFName };

    const index = newItem.ScheduleF?.findIndex((x) => x.Id == id);

    if (index != undefined && newItem.ScheduleF) {
      newItem.ScheduleF[index] = ScheduleF;
    }
    setFormDataFields({
      ...newItem,
    });
  }

  function addRowHandler(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();
    const newItem: IBppFormTwelfthSectionInterface = formDataFields
      ? JSON.parse(JSON.stringify(formDataFields))
      : [];
    if (!newItem.ScheduleF) {
      newItem.ScheduleF = [];
    }
    newItem.ScheduleF?.push({ Id: newItem.ScheduleF?.length });
    setFormDataFields({
      ...newItem,
    });
  }

  function removeRowHandler(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();
    const newItem: IBppFormTwelfthSectionInterface = formDataFields
      ? JSON.parse(JSON.stringify(formDataFields))
      : [];
    if (newItem && newItem.ScheduleF) {
      newItem.ScheduleF.pop();
      setFormDataFields({
        ...newItem,
      });
    }
  }

  function changeValidator() {
    if(formRef.current) {
      const isValid = formRef.current.checkValidity();
      setIsValid(isValid);
      setFormIsValid(isValid);
    }
  }


  function onTouch(){
    if(!isFormTouched){
      setIsFormTouched(true);
    }
  }

  useEffect(() => {
    changeValidator()
  }, [
    formDataFields,
    formDataFields?.ScheduleF,
    formRef.current,
    formRef?.current?.elements,
  ]);

  return (
    <>
      <PanelBar keepItemsMounted={true}>
        <PanelBarItem title={title} expanded={true}>
          <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
            <div className="container">
              <div className={classes.item}>
                <Label className={classes.ScheduleLabel}>
                  PROPERTY UNDER BAILMENT, LEASE, CONSIGNMENT OR OTHER ARRANGEMENT
                </Label>
                <div className={classes.information}>
                  List the name and address of each owner of taxable property that is in your possession or under your management on Jan. 1 by bailment, lease, consignment or other arrangement. If needed, attach additional sheets OR a computer-generated copy listing the information below.
                </div>
                <table className="blue">
                  <thead>
                    <tr>
                      <th>Property Owner’s Name</th>
                      <th>Property Owner’s Address</th>
                      <th>General Property Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formDataFields?.ScheduleF?.filter(x=> !x.HasActiveSubSegments).map((item) => {
                      return (
                        <tr key={item.Id}>
                          <td>
                            <Input
                                className={classes.kTextbox}
                              disabled={editDisabled}
                              value={item.OwnerName}
                              onChange={(e) =>
                                changeValueHandler(e, "OwnerName", item.Id!)
                              }
                            />
                          </td>
                          <td>
                            <Input
                                className={classes.kTextbox}
                              disabled={editDisabled}
                              value={item.OwnerAddress}
                              onChange={(e) =>
                                changeValueHandler(e, "OwnerAddress", item.Id!)
                              }
                            />
                          </td>
                          <td>
                            <Input
                                className={classes.kTextbox}
                              disabled={editDisabled}
                              value={item.Description}
                              onChange={(e) =>
                                changeValueHandler(
                                  e,
                                  "Description",
                                  item.Id!
                                )
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {!formSettings?.isReadOnly && !editDisabled && (
                  <div>
                    <button
                      className={classes.LightButton}
                      disabled={editDisabled}
                      onClick={(e) => addRowHandler(e)}
                    >
                      <i className="fa fas fa-plus"></i>
                      Add Row
                    </button>
                    <button
                      className={classes.LightButton}
                      disabled={editDisabled}
                      onClick={(e) => removeRowHandler(e)}
                    >
                      <i className="fa fas fa-minus"></i>
                      Remove Row
                    </button>
                  </div>
                )}
              </div>
            </div>
          </form>
        </PanelBarItem>
      </PanelBar>
    </>
  );
}
