export type IBppFormThirteenthSectionInterface = {
    TimelyAppliedForSep1InventoryDate?: boolean,
    InventoryInvolvedInterstateCommerceIssues?: boolean,
    InventoryInvolvedFreeportGoods?: boolean
}

export const ThirteenthSectionInitialState = {
    TimelyAppliedForSep1InventoryDate: undefined,
    InventoryInvolvedInterstateCommerceIssues: undefined,
    InventoryInvolvedFreeportGoods: undefined
}