import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useTranslation } from "react-i18next";
import CustomLoader from "src/components/CustomLoader/CustomLoader";
import type { IConfirmDialog } from "src/interfaces/dialogs/confirm-dialog.interface";

export default function ConfirmDialog(props: IConfirmDialog): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <Dialog title={props.title} onClose={props.onClose}>
        {props.message && <h3 className="ta-center">{props.message}</h3>}
        {props.subMessage && <p>{props.subMessage}</p>}
        {props.children}
        <div>{props.isLoading}</div>
        <DialogActionsBar>
          <div className="d-flex j-right">
            <Button className="k-button mr-2" onClick={props.onClose}>
              {props.cancelText ?? t("form.close")}
            </Button>
            <Button className="default-btn" onClick={props.onSubmit}>
              {t("confirm")}
            </Button>
          </div>
        </DialogActionsBar>
      </Dialog>

      {props.isLoading && <CustomLoader />}
    </>
  );
}
