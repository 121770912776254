export type ITimberlandAppraisalFormFourthSection = {
    AccountNumber?: string;
    NumberOfAcres?: string;
    LegalDescription?: string;
    HasPropertyOwnershipChanged?: boolean;
    WasAppraisalAllowed?: boolean;
    IsPropertyLocatedWithinCorporateLimits?: boolean;
}

export const TimberlandAppraisalFormFourthSectionInterface = (): ITimberlandAppraisalFormFourthSection => ({
    AccountNumber: undefined,
    NumberOfAcres: undefined,
    LegalDescription: undefined,
    HasPropertyOwnershipChanged: undefined,
    WasAppraisalAllowed: undefined,
    IsPropertyLocatedWithinCorporateLimits: undefined
})