export type IFreeportFormSixthSectionInterface = {
    PrintedName?: string,
    Signature?: string,
    SignatureDate?: string,
}

export const FreeportFormSixthSectionInterface = (): IFreeportFormSixthSectionInterface => ({
    PrintedName: undefined,
    Signature: undefined,
    SignatureDate: undefined,
})