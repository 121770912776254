import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import {
    CheckboxChangeEvent, Input,
    InputChangeEvent, NumericTextBox, NumericTextBoxChangeEvent,
    RadioGroupChangeEvent,
    TextAreaChangeEvent
} from "@progress/kendo-react-inputs";
import {DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import React, {useEffect, useRef, useState} from "react";
import {IBppFormNinthSectionInterface} from "../../../../../interfaces/forms/BPPForm/bpp-form-ninth-section.interface";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./BPPForm.module.scss";
import {Label} from "@progress/kendo-react-labels";
import { IFormEntryModel } from "../../../../../interfaces/form-entry.interface";
import {IRowState} from "../CustomInputs/UpdateRowErrorStateHandler";
import {CustomRowDatePicker} from "../CustomInputs/CustomDatePickers/CustomRowDatePicker";
import {ScheduleCName} from "../../../../../constants/form-fields-constants";
import {NumberFormatOptions} from "@telerik/kendo-intl";
import {CustomOnlyYearCalendarHelper} from "../../../../Shared/DatePickers/CustomCalendars";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IBppFormNinthSectionInterface) => void;
    formDataFields?:  IBppFormNinthSectionInterface,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void;
    formEntry?: IFormEntryModel;
}

const maxScheduleYearDate = new Date(new Date().getFullYear(), 1,1);

export function BPPFormNinthSection(props: GeneralFormType): JSX.Element{
    const formRef = useRef<HTMLFormElement>(null);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={9} name={"Schedule C"}/>;
    const {setFormDataFields, formDataFields, formSettings, isAdminPreview, setFormIsValid, formEntry } = props;
    const currencyFormatOptions: NumberFormatOptions = { maximumFractionDigits: 0 };
    const { CustomOnlyYearCalendar } = CustomOnlyYearCalendarHelper(maxScheduleYearDate.getFullYear() - 1);
    const editDisabled = formSettings?.isReadOnly || formEntry?.initiatedBy === 'PropertySearch' || isAdminPreview;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | NumericTextBoxChangeEvent,
        field: string,
        id: number,
    )
    {
        const newItem: IBppFormNinthSectionInterface = formDataFields ? JSON.parse(JSON.stringify(formDataFields)) : [];

        let ScheduleC = newItem.ScheduleC?.find(x=> x.Id == id);

        if(field == "YearAcquired"){
            ScheduleC = {...ScheduleC, [field]: event.value.getFullYear(), ScheduleName: ScheduleCName};

        } else {
            ScheduleC = {...ScheduleC, [field]: event.value, ScheduleName: ScheduleCName};
        }

        const index = newItem.ScheduleC?.findIndex(x=>x.Id == id);

        if(index != undefined && newItem.ScheduleC) {
            newItem.ScheduleC[index] = ScheduleC
        }
        setFormDataFields({
            ...newItem
        })
    }

    function addRowHandler(event:  React.MouseEvent<HTMLElement>){
        event.preventDefault();
        const newItem: IBppFormNinthSectionInterface = formDataFields ? JSON.parse(JSON.stringify(formDataFields)) : [];
        if(!newItem.ScheduleC){
            newItem.ScheduleC = [];
        }
        newItem.ScheduleC?.push({ Id: newItem.ScheduleC?.length });
        setFormDataFields({
            ...newItem,
        });
    }

    function removeRowHandler(event:  React.MouseEvent<HTMLElement>){
        event.preventDefault();
        const newItem: IBppFormNinthSectionInterface = formDataFields ? JSON.parse(JSON.stringify(formDataFields)) : [];
        if(newItem && newItem.ScheduleC){
            newItem.ScheduleC.pop();
            setFormDataFields({
                ...newItem,
            });
        }
    }

    function getDateValue(id: number): any {
        const item = formDataFields?.ScheduleC?.find(x=> x.Id == id)?.YearAcquired;

        if(item?.length === 4) {
            return new Date(+item,2,2);
        }

        return null;
    }

    const [dateErrorsArray, setDateErrorsArray] = useState<Array<IRowState>>([]);

    function changeValidator() {
        if(formRef.current) {
            const isValid = formRef.current.checkValidity() &&
                !dateErrorsArray.some(x=>x.state === false);

            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if(!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [
        formDataFields,
        formDataFields?.ScheduleC,
        formRef.current,
        formRef?.current?.elements,
        dateErrorsArray
    ]);


    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                           <Label className={classes.ScheduleLabel}>SUPPLIES</Label>
                            <div className={classes.information}>
                                List all supplies by type of property. If needed attach additional sheets OR a computer generated copy listing the information below. If you manage or control property as a fiduciary on Jan. 1, also list the names and addresses of each property owner.
                            </div>
                            <table className="blue">
                                <thead>
                                <tr>
                                    <th>Property Description by Type/Category</th>
                                    <th className="thw25">
                                        Property Address or Address Where Taxable
                                    </th>
                                    <th>Estimate of Quantity of Each Type</th>
                                    <th>Good Faith Estimate of Market Value</th>
                                    <th>or</th>
                                    <th>Historical Cost When New</th>
                                    <th>Year Acquired</th>
                                    <th className="thw25">
                                        Property Owner Name/Address
                                        <br />
                                        <div className="small">
                                            (if you manage or control property as a fiduciary)
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {formDataFields?.ScheduleC?.filter(x=> !x.HasActiveSubSegments).map((item) => {
                                    return (
                                        <tr key={item.Id}>
                                            <td>
                                                <Input
                                                    className={classes.kTextbox}
                                                    disabled={editDisabled}
                                                    value={item.Description}
                                                    onChange={(e) =>
                                                        changeValueHandler(
                                                            e,
                                                            "Description",
                                                            item.Id!
                                                        )
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    className={classes.kTextbox}
                                                    disabled={editDisabled}
                                                    value={item.PropertyAddress}
                                                    onChange={(e) =>
                                                        changeValueHandler(e, "PropertyAddress", item.Id!)
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    className={classes.kTextbox}
                                                    disabled={editDisabled}
                                                    value={item.Quantity}
                                                    onChange={(e) =>
                                                        changeValueHandler(e, "Quantity", item.Id!)
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <NumericTextBox
                                                    disabled={editDisabled}
                                                    className={classes.kTextbox}
                                                    value={item.MarketValue}
                                                    onChange={e => changeValueHandler(e, "MarketValue", item.Id!)}
                                                    format={currencyFormatOptions}
                                                    spinners={false}
                                                />
                                            </td>
                                            <td></td>
                                            <td>
                                                <NumericTextBox
                                                    disabled={editDisabled}
                                                    className={classes.kTextbox}
                                                    value={item.HistoricCost}
                                                    onChange={e => changeValueHandler(e, "HistoricCost", item.Id!)}
                                                    format={currencyFormatOptions}
                                                    spinners={false}
                                                />
                                            </td>
                                            <td>
                                                <CustomRowDatePicker
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"YearAcquired"}
                                                    onlyYear={true}
                                                    required={false}
                                                    group={"ScheduleC"}
                                                    id={item.Id!}
                                                    errorsArray={dateErrorsArray}
                                                    setErrorsArray={setDateErrorsArray}
                                                    formDataFields={formDataFields}
                                                    defaultValueDate={getDateValue(item.Id!)}
                                                    disabled={editDisabled}
                                                    CustomCalendar={CustomOnlyYearCalendar}
                                                    rowItem={item.YearAcquired}
                                                    maxDate={maxScheduleYearDate}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    className={classes.kTextbox}
                                                    disabled={editDisabled}
                                                    value={item.PropertyOwnerName}
                                                    onChange={(e) =>
                                                        changeValueHandler(
                                                            e,
                                                            "PropertyOwnerName",
                                                            item.Id!
                                                        )
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                            {!formSettings?.isReadOnly && !editDisabled && (
                                <div>
                                    <button
                                        className={classes.LightButton}
                                        disabled={editDisabled}
                                        onClick={(e) => addRowHandler(e)}
                                    >
                                        <i className="fa fas fa-plus"></i>
                                        Add Row
                                    </button>
                                    <button
                                        className={classes.LightButton}
                                        disabled={editDisabled}
                                        onClick={(e) => removeRowHandler(e)}
                                    >
                                        <i className="fa fas fa-minus"></i>
                                        Remove Row
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
