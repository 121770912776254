import { PanelBar } from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import { PanelBarItem } from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "src/pages/Home/components/Forms/ExemptionPropertyDamagedForm/ExemptionPropertyDamaged.module.scss";
import { IFormSettingModel } from "../../../../../interfaces/form-settings.interface";
import { IFormEntryModel } from "../../../../../interfaces/form-entry.interface";
import { useEffect, useRef, useState } from "react";
import { IExemptionPropertyDamagedFormFifthSection } from "src/interfaces/forms/ExemptionPropertyDamagedByDisasterForm/exemption-property-damaged-fifth-section.interface";
import { Label } from "@progress/kendo-react-labels";
import { Input, CheckboxChangeEvent, InputChangeEvent, RadioGroupChangeEvent, TextAreaChangeEvent } from "@progress/kendo-react-inputs";
import { CheckboxGroup } from "../CustomInputs/CheckBoxes/CheckboxGroup";
import { DatePickerChangeEvent, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { CustomDatePicker } from "../CustomInputs/CustomDatePickers/CustomDatePicker";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IExemptionPropertyDamagedFormFifthSection) => void;
    formDataFields?:  IExemptionPropertyDamagedFormFifthSection,
    formSettings?: IFormSettingModel,
    formEntry?: IFormEntryModel,
    setFormIsValid: (value: boolean) => void,
}

export default function ExemptionPropertyDamagedFormFifthSection(props: GeneralFormType): JSX.Element {
    const { formDataFields, setFormDataFields, formSettings, isAdminPreview, setFormIsValid } = props;
    const formRef = useRef<HTMLFormElement>(null);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const [canPropertyBeRepairedInputValid, setCanPropertyBeRepairedInputValid] = useState<boolean>(false);
    const [ifHomesteadDoIntendToReturnInputValid, setIfHomesteadDoIntendToReturnInputValid] = useState<boolean>(false);
    const [isAnyPartInhabitableInputValid, setIsAnyPartInhabitableInputValid] = useState<boolean>(false);
    const [ifBusinessDoIntendToReopenInputValid, setIfBusinessDoIntendToReopenInputValid] = useState<boolean>(false);
    const [isStructuralDamageInputValid, setIsStructuralDamageInputValid] = useState<boolean>(false);
    const [isNonStructuralDamageOnlyInputValid, setIsNonStructuralDamageOnlyInputValid] = useState<boolean>(false);
    const [isStillWaitingForCostRepairAssistanceInputValid, setIsStillWaitingForCostRepairAssistanceInputValid] = useState<boolean>(false);
    const [estimatedDateRepairsBeginIsValid, setEstimatedDateRepairsBeginIsValid] = useState<boolean>(false);
    const [estimatedCompletionDateIsValid, setEstimatedCompletionDateIsValid] = useState<boolean>(false);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={5} name={"Damage Description"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string
    ) {
        setFormDataFields({
            ...formDataFields,
            [field]: event.value
        });
    }

    function changeValidator() {
        if (formRef.current) {
            const isValid = canPropertyBeRepairedInputValid &&
                formRef.current.checkValidity();
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if(!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current,]);


    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={`${classes.itemFields} ${classes.bordered}`}>
                                <Label className={classes.Label}>
                                    1. Can the property be repaired?
                                </Label>
                                <div className={classes.fieldsGroup}>
                                    <div className={classes.checkboxGroup}>
                                        <CheckboxGroup
                                            isAdminPreview={isAdminPreview}
                                            setFormDataFields={setFormDataFields}
                                            formDataFields={formDataFields}
                                            formSettings={formSettings}
                                            fieldName={"CanPropertyBeRepaired"}
                                            fieldSecondName={"CanPropertyBeRepairedSecond"}
                                            isRequired={false}
                                            checkboxesIsValid={canPropertyBeRepairedInputValid}
                                            setCheckboxesIsValid={setCanPropertyBeRepairedInputValid}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.itemFields} ${classes.bordered}`}>
                                <Label className={`${classes.Label}`}>
                                    2. If this is a homestead, do you intend to return?
                                </Label>
                                <div className={classes.fieldsGroup}>
                                    <div className={classes.checkboxGroup}>
                                        <CheckboxGroup
                                            isAdminPreview={isAdminPreview}
                                            setFormDataFields={setFormDataFields}
                                            formDataFields={formDataFields}
                                            formSettings={formSettings}
                                            fieldName={"IfHomesteadDoIntendToReturn"}
                                            fieldSecondName={"IfHomesteadDoIntendToReturnSecond"}
                                            isRequired={false}
                                            checkboxesIsValid={ifHomesteadDoIntendToReturnInputValid}
                                            setCheckboxesIsValid={setIfHomesteadDoIntendToReturnInputValid}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.bordered}>
                                <div className={classes.itemFields}>
                                    <Label className={`${classes.Label}`}>
                                        3. Is any part inhabitable?
                                    </Label>
                                    <div className={classes.fieldsGroup}>
                                        <div className={classes.checkboxGroup}>
                                            <CheckboxGroup
                                                isAdminPreview={isAdminPreview}
                                                setFormDataFields={setFormDataFields}
                                                formDataFields={formDataFields}
                                                formSettings={formSettings}
                                                fieldName={"IsAnyPartInhabitable"}
                                                fieldSecondName={"IsAnyPartInhabitableSecond"}
                                                isRequired={false}
                                                checkboxesIsValid={isAnyPartInhabitableInputValid}
                                                setCheckboxesIsValid={setIsAnyPartInhabitableInputValid}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.itemFields}>
                                    <Label className={classes.Label}>
                                        Explain
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={false}
                                        name={"IsAnyPartInhabitableExplain"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.IsAnyPartInhabitableExplain}
                                        onChange={(e)=> changeValueHandler(e, "IsAnyPartInhabitableExplain")}
                                    />
                                </div>
                            </div>
                            <div className={`${classes.itemFields} ${classes.bordered}`}>
                                <Label className={`${classes.Label}`}>
                                    4. If this is a business, do you intend to reopen?
                                </Label>
                                <div className={classes.fieldsGroup}>
                                    <div className={classes.checkboxGroup}>
                                        <CheckboxGroup
                                            isAdminPreview={isAdminPreview}
                                            setFormDataFields={setFormDataFields}
                                            formDataFields={formDataFields}
                                            formSettings={formSettings}
                                            fieldName={"IfBusinessDoIntendToReopen"}
                                            fieldSecondName={"IfBusinessDoIntendToReopenSecond"}
                                            isRequired={false}
                                            checkboxesIsValid={ifBusinessDoIntendToReopenInputValid}
                                            setCheckboxesIsValid={setIfBusinessDoIntendToReopenInputValid}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.bordered}>
                                <div className={classes.itemFields}>
                                    <Label className={`${classes.Label}`}>
                                        5. Is there structural damage?
                                    </Label>
                                    <div className={classes.fieldsGroup}>
                                        <div className={classes.checkboxGroup}>
                                            <CheckboxGroup
                                                isAdminPreview={isAdminPreview}
                                                setFormDataFields={setFormDataFields}
                                                formDataFields={formDataFields}
                                                formSettings={formSettings}
                                                fieldName={"IsStructuralDamage"}
                                                fieldSecondName={"IsStructuralDamageSecond"}
                                                isRequired={false}
                                                checkboxesIsValid={isStructuralDamageInputValid}
                                                setCheckboxesIsValid={setIsStructuralDamageInputValid}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.itemFields}>
                                    <Label className={classes.Label}>
                                        Explain
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={false}
                                        name={"IsStructuralDamageExplain"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.IsStructuralDamageExplain}
                                        onChange={(e)=> changeValueHandler(e, "IsStructuralDamageExplain")}
                                    />
                                </div>
                            </div>
                            <div className={classes.bordered}>
                                <div className={classes.itemFields}>
                                    <Label className={`${classes.Label}`}>
                                        6. Is there non-structural damage only?
                                    </Label>
                                    <div className={classes.fieldsGroup}>
                                        <div className={classes.checkboxGroup}>
                                            <CheckboxGroup
                                                isAdminPreview={isAdminPreview}
                                                setFormDataFields={setFormDataFields}
                                                formDataFields={formDataFields}
                                                formSettings={formSettings}
                                                fieldName={"IsNonStructuralDamageOnly"}
                                                fieldSecondName={"IsNonStructuralDamageOnlySecond"}
                                                isRequired={false}
                                                checkboxesIsValid={isNonStructuralDamageOnlyInputValid}
                                                setCheckboxesIsValid={setIsNonStructuralDamageOnlyInputValid}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.itemFields}>
                                    <Label className={classes.Label}>
                                        Explain
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={false}
                                        name={"IsNonStructuralDamageOnlyExplain"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.IsNonStructuralDamageOnlyExplain}
                                        onChange={(e)=> changeValueHandler(e, "IsNonStructuralDamageOnlyExplain")}
                                    />
                                </div>
                            </div>
                            <div className={`${classes.itemFields} ${classes.fieldsGroup} ${classes.bordered}`}>
                                <div className={classes.fieldsGroupW30}>
                                    <Label className={classes.Label}>
                                        7. Estimated cost to repair
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={false}
                                        name={"EstimatedCostToRepair"}
                                        placeholder={"Estimated cost to repair"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.EstimatedCostToRepair}
                                        onChange={(e)=> changeValueHandler(e, "EstimatedCostToRepair")}
                                    />
                                </div>
                                <div className={classes.fieldsGroupW30}>
                                    <Label className={classes.Label}>
                                        Estimated date repairs begin
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <CustomDatePicker
                                            fieldName={"EstimatedDateRepairsBegin"}
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            formDataFields={formDataFields}
                                            setFormDataFields={setFormDataFields}
                                            onlyYear={false}
                                            setIsValidField={setEstimatedDateRepairsBeginIsValid}
                                            validField={estimatedDateRepairsBeginIsValid}
                                            defaultValueDate={formDataFields?.EstimatedDateRepairsBegin}
                                        />
                                    </div>
                                </div>
                                <div className={classes.fieldsGroupW30}>
                                    <Label className={classes.Label}>
                                        Estimated completion date
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <CustomDatePicker
                                            fieldName={"EstimatedCompletionDate"}
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            formDataFields={formDataFields}
                                            setFormDataFields={setFormDataFields}
                                            onlyYear={false}
                                            setIsValidField={setEstimatedCompletionDateIsValid}
                                            validField={estimatedCompletionDateIsValid}
                                            defaultValueDate={formDataFields?.EstimatedCompletionDate}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.itemFields} ${classes.bordered}`}>
                                <Label className={`${classes.Label}`}>
                                    8. Are you still waiting for assistance with cost or repair?
                                </Label>
                                <div className={classes.fieldsGroup}>
                                    <div className={classes.checkboxGroup}>
                                        <CheckboxGroup
                                            isAdminPreview={isAdminPreview}
                                            setFormDataFields={setFormDataFields}
                                            formDataFields={formDataFields}
                                            formSettings={formSettings}
                                            fieldName={"IsStillWaitingForCostRepairAssistance"}
                                            fieldSecondName={"IsStillWaitingForCostRepairAssistanceSecond"}
                                            isRequired={false}
                                            checkboxesIsValid={isStillWaitingForCostRepairAssistanceInputValid}
                                            setCheckboxesIsValid={setIsStillWaitingForCostRepairAssistanceInputValid}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.itemFields} ${classes.bordered}`}>
                                <Label className={`${classes.Label}`}>
                                    9. How many inches or feet above foundation is the water line?
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    required={false}
                                    name={"InchesAboutFoundation"}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.InchesAboutFoundation}
                                    onChange={(e)=> changeValueHandler(e, "InchesAboutFoundation")}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
