import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@progress/kendo-react-buttons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CustomLoader } from "src/components";
import classes from "src/components/Welcome/Welcome.module.scss";
import { useFormContext } from "src/context/Form/form-context";
import { AllFormNames } from "../../../constants/form-names-constants";

export default function FormList(): JSX.Element {
  const { formState } = useFormContext();

  const { t } = useTranslation();
  const navigate = useNavigate();

  function openForm(id: number, name: string) {
    if (AllFormNames.includes(name)) {
      navigate(`/home/forms/${name}`);
    } else {
      navigate(`/home/forms/${id}`);
    }
  }

  return (
    <div className={classes.FormContent}>
      <span className={classes.GetStarted}>{t("welcomePart.chooseForm")}</span>

      <div className={classes.FormList}>
        {formState.loading && <CustomLoader />}

        {!formState.loading &&
          formState.forms &&
          formState.forms.map((form) => {
            return (
              <div key={form.id} className={classes.Form}>
                <Button onClick={() => openForm(form.id, form.name)}>
                  <FontAwesomeIcon className="mr-2" icon="file" />
                  <span>{form.displayName}</span>
                </Button>
              </div>
            );
          })}
      </div>
    </div>
  );
}
