import classes from "src/pages/Home/components/Forms/TimberlandAppraisalForm/TimberlandAppraisal.module.scss";
import { useSelectedFormContext } from "../../../../../context/SelectedForm/selected-form-context";
import { useEffect, useState, useMemo } from "react";
import {
  getSelectedFormDataHelper,
  getSelectedFormEntryDataHelper,
  saveFormHelper,
} from "../../../../../context/SelectedForm/selected-form-context.helper";
import {
  IFormEntryModel,
} from "../../../../../interfaces/form-entry.interface";
import {
  IFormFiledInterface,
} from "../../../../../interfaces/form-filed.interface";
import { useNavigate } from "react-router-dom";
import {
  IFormSettingModel,
} from "../../../../../interfaces/form-settings.interface";
import { Dialog } from "@progress/kendo-react-dialogs";
import { SelectedFormActionsEnum } from "../../../../../context/SelectedForm/selected-form-actions";
import { useFormContext } from "../../../../../context/Form/form-context";
import {
  onLoadForms
} from "../../../../../context/Form/form-helper";
import { CustomLoader } from "../../../../../components";
import { FormActionsEnum } from "../../../../../context/Form/form-actions";
import { useCallbackPrompt } from "../../../../../hooks/useCallbackPrompt";
import UnsavedFormDialog from "../../../../../components/CustomDialogs/UnsavedFormDialog/UnsavedFormDialog";
import { t } from "i18next";
import AuthService from 'src/services/authentication.service';
import { useFormSectionDataFields } from "../useFormSectionDataFields";
import { ITimberlandAppraisalFormFifthSection, TimberlandAppraisalFormFifthSectionInterface } from "src/interfaces/forms/TimberlandAppraisalForm/timberland-appraisal-form-fifth-section.interface";
import { ITimberlandAppraisalFormFirstSection, TimberlandAppraisalFormFirstSectionInterface } from "src/interfaces/forms/TimberlandAppraisalForm/timberland-appraisal-form-first-section.interface";
import { ITimberlandAppraisalFormFourthSection, TimberlandAppraisalFormFourthSectionInterface } from "src/interfaces/forms/TimberlandAppraisalForm/timberland-appraisal-form-fourth-section.interface";
import { ITimberlandAppraisalFormSecondSection, TimberlandAppraisalFormSecondSectionInterface } from "src/interfaces/forms/TimberlandAppraisalForm/timberland-appraisal-form-second-section.interface";
import { ITimberlandAppraisalFormSixthSection, TimberlandAppraisalFormSixthSectionInterface } from "src/interfaces/forms/TimberlandAppraisalForm/timberland-appraisal-form-sixth-section.interface";
import { ITimberlandAppraisalFormThirdSection, TimberlandAppraisalFormThirdSectionInterface } from "src/interfaces/forms/TimberlandAppraisalForm/timberland-appraisal-form-third-section.interface";
import TimberlandAppraisalFormPDFView from "src/pages/Shared/TimberlandAppraisalFormPDFView/TimberlandAppraisalFormPDFView";
import TimberlandAppraisalFormFifthSection from "./TimberlandAppraisalFormFifthSection";
import TimberlandAppraisalFormFirstSection from "./TimberlandAppraisalFormFirstSection";
import TimberlandAppraisalFormFourthSection from "./TimberlandAppraisalFormFourthSection";
import TimberlandAppraisalFormSecondSection from "./TimberlandAppraisalFormSecondSection";
import TimberlandAppraisalFormSixthSection from "./TimberlandAppraisalFormSixthSection";
import TimberlandAppraisalFormThirdSection from "./TimberlandAppraisalFormThirdSection";
import { TimberlandAppraisalFormName } from "src/constants/form-names-constants";
import TimberlandAppraisalFormSeventhSection from "./TimberlandAppraisalFormSeventhSection";
import { FormHeader } from "../Shared/FormHeader/FormHeader";
import { submitted } from "src/constants/submission-form-status-constants";

interface FormEntry {
  formEntryId?: number;
  isAdminPreview?: boolean;
  formId?: number;
}

export default function TimberlandAppraisalForm(props: FormEntry): JSX.Element {
    const { isAuthorized } = AuthService.getInstance();

    const { isAdminPreview, formEntryId, formId } = props;
    const { formState, formDispatch } = useFormContext();
    const { selectedFormDispatch, selectedFormState } = useSelectedFormContext();
    const { loading } = formState;
    const { isLoading } = selectedFormState;
    const [formDataFields, setFormDataFields] = useState<Array<IFormFiledInterface>>();
    const [formEntry, setFormEntry] = useState<IFormEntryModel>();
    const formSettings = useMemo(() => {
      let formSettings = selectedFormState.formSettings ?? undefined;
      if (
          !isAuthorized ||
          isAdminPreview ||
          selectedFormState.formEntry?.submissionStatus == submitted
       ) {
        formSettings = { ...formSettings, isReadOnly: true };
       }
       return formSettings;
    }, [selectedFormState.formSettings, selectedFormState.formEntry, isAuthorized, isAdminPreview]);
    const [showPrompt, confirmNavigation, cancelNavigation, setDisablePrompt] = useCallbackPrompt(!!(isAuthorized && !isAdminPreview && !formSettings?.isReadOnly));

    const [formFirstSectionDataFields, setFormFirstSectionDataFields] = useState<ITimberlandAppraisalFormFirstSection>();
    const [formSecondSectionDataFields, setFormSecondSectionDataFields] = useState<ITimberlandAppraisalFormSecondSection>();
    const [formThirdSectionDataFields, setFormThirdSectionDataFields] = useState<ITimberlandAppraisalFormThirdSection>();
    const [formFourthSectionDataFields, setFormFourthSectionDataFields] = useState<ITimberlandAppraisalFormFourthSection>();
    const [formFifthSectionDataFields, setFormFifthSectionDataFields] = useState<ITimberlandAppraisalFormFifthSection>();
    const [formSixthSectionDataFields, setFormSixthSectionDataFields] = useState<ITimberlandAppraisalFormSixthSection>();

    const [firstSectionIsValid, setFirstSectionIsValid] = useState<boolean>(false);
    const [secondSectionIsValid, setSecondSectionIsValid] = useState<boolean>(false);
    const [thirdSectionIsValid, setThirdSectionIsValid] = useState<boolean>(false);
    const [fourthSectionIsValid, setFourthSectionIsValid] = useState<boolean>(false);
    const [fifthSectionIsValid, setFifthSectionIsValid] = useState<boolean>(false);
    const [sixthSectionIsValid, setSixthSectionIsValid] = useState<boolean>(false);

    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    useEffect(() => {
      setIsFormValid(
        firstSectionIsValid &&
        secondSectionIsValid &&
        thirdSectionIsValid &&
        fourthSectionIsValid &&
        fifthSectionIsValid &&
        sixthSectionIsValid)
    }, [
      firstSectionIsValid,
      secondSectionIsValid,
      thirdSectionIsValid,
      fourthSectionIsValid,
      fifthSectionIsValid,
      sixthSectionIsValid
    ])

    useEffect(() => {
      return () => {
        selectedFormDispatch({
          type: SelectedFormActionsEnum.CLEAR,
          payload: {
            errorMessage: undefined,
            isLoading: undefined,
            formFields: undefined,
            successMessage: undefined,
            form: undefined,
            formSettings: undefined,
            formEntry: undefined,
            status: SelectedFormActionsEnum.CLEAR
          },
        });
      }
    }, []);

    useEffect(() => {
      if (!formState.forms.length) {
        onLoadForms(formDispatch);
      }
    }, []);

    useEffect(() => {
      const stateFormId = formState?.forms?.find(x => x.name == TimberlandAppraisalFormName)?.id ?? formId;

      if (stateFormId && isAuthorized) {
        if (formEntryId) {
          getSelectedFormEntryDataHelper(selectedFormDispatch, stateFormId, formEntryId);
        } else {
          getSelectedFormDataHelper(selectedFormDispatch, stateFormId);
        }
      }
    }, [formState.forms]);

    useEffect(() => {
      if (selectedFormState?.formEntry) {
        if (selectedFormState.form?.name) {
          setFormEntry({
            ...selectedFormState?.formEntry,
            formName: selectedFormState.form?.name
          });
        } else {
          setFormEntry(selectedFormState.formEntry)
        }
      }
    }, [selectedFormState.formEntry, selectedFormState.form]);

    function ClearData() {
      setFormDataFields([]);
      setFormEntry(undefined);
      setFormFirstSectionDataFields({});
      setFormSecondSectionDataFields({});
      setFormThirdSectionDataFields({});
      setFormFourthSectionDataFields({});
      setFormFifthSectionDataFields({});
      setFormSixthSectionDataFields({});
      setFirstSectionIsValid(false);
      setSecondSectionIsValid(false);
      setThirdSectionIsValid(false);
      setFourthSectionIsValid(false);
      setFifthSectionIsValid(false);
      setSixthSectionIsValid(false);

      setDisablePrompt(false);
    }

    useEffect(() => {
      if (selectedFormState.status === SelectedFormActionsEnum.CLEAR) {
        ClearData();
        return;
      }

      setFormDataFields(selectedFormState.formFields);
      const FirstSection: ITimberlandAppraisalFormFirstSection = TimberlandAppraisalFormFirstSectionInterface();
      const SecondSection: ITimberlandAppraisalFormSecondSection = TimberlandAppraisalFormSecondSectionInterface();
      const ThirdSection: ITimberlandAppraisalFormThirdSection = TimberlandAppraisalFormThirdSectionInterface();
      const FourthSection: ITimberlandAppraisalFormFourthSection = TimberlandAppraisalFormFourthSectionInterface();
      const FifthSection: ITimberlandAppraisalFormFifthSection = TimberlandAppraisalFormFifthSectionInterface();
      const SixthSection: ITimberlandAppraisalFormSixthSection = TimberlandAppraisalFormSixthSectionInterface();

      selectedFormState?.formFields.forEach((f) => {
        let value;
        switch (f.type) {
          case "boolean":
          case "object":
            value = JSON.parse(f.value);
            break;
          case "string":
            value = f.value.trim();
            break;
          case "number":
            value = +f.value;
            break;
        }

        switch(true) {
          case f.name in FirstSection:
            (FirstSection as any)[f.name] = value;
            break;
          case f.name in SecondSection:
            (SecondSection as any)[f.name] = value;
            break;
          case f.name in ThirdSection:
            (ThirdSection as any)[f.name] = value;
            break;
          case f.name in FourthSection:
            (FourthSection as any)[f.name] = value;
            break;
          case f.name in FifthSection:
            (FifthSection as any)[f.name] = value;
            break;
          case f.name in SixthSection:
            (SixthSection as any)[f.name] = value;
            break;
        }
      });
      if (selectedFormState.formFields.length > 0) {
        setFormFirstSectionDataFields(FirstSection);
        setFormSecondSectionDataFields(SecondSection);
        setFormThirdSectionDataFields(ThirdSection);
        setFormFourthSectionDataFields(FourthSection);
        setFormFifthSectionDataFields(FifthSection);
        setFormSixthSectionDataFields(SixthSection);
      }
    }, [selectedFormState.formFields]);

    useFormSectionDataFields({
      sectionFields: formFirstSectionDataFields,
      formDataFields: formDataFields,
      setFormDataFields: setFormDataFields,
      formId: formEntry?.formId,
      formEntryId: formEntry?.id
    });

    useFormSectionDataFields({
      sectionFields: formSecondSectionDataFields,
      formDataFields: formDataFields,
      setFormDataFields: setFormDataFields,
      formId: formEntry?.formId,
      formEntryId: formEntry?.id
    });

    useFormSectionDataFields({
      sectionFields: formThirdSectionDataFields,
      formDataFields: formDataFields,
      setFormDataFields: setFormDataFields,
      formId: formEntry?.formId,
      formEntryId: formEntry?.id
    });

    useFormSectionDataFields({
      sectionFields: formFourthSectionDataFields,
      formDataFields: formDataFields,
      setFormDataFields: setFormDataFields,
      formId: formEntry?.formId,
      formEntryId: formEntry?.id
    });

    useFormSectionDataFields({
      sectionFields: formFifthSectionDataFields,
      formDataFields: formDataFields,
      setFormDataFields: setFormDataFields,
      formId: formEntry?.formId,
      formEntryId: formEntry?.id
    });

    useFormSectionDataFields({
      sectionFields: formSixthSectionDataFields,
      formDataFields: formDataFields,
      setFormDataFields: setFormDataFields,
      formId: formEntry?.formId,
      formEntryId: formEntry?.id
    });

    const navigate = useNavigate();

    function saveHandler() {
      setDisablePrompt(true);
      const temp = {
        ...formEntry,
        formName: selectedFormState.form?.name,
        submissionStatus: "Saved",
      } as IFormEntryModel;
      setFormEntry(temp);
      if (selectedFormState.form?.name) {
        saveFormHelper(selectedFormDispatch, selectedFormState.form?.name, temp, formDataFields!, navigate);
      }
    }

    function setFormEntryBase64Url(url: string) {
      setFormEntry({ ...formEntry, pdfBase64Url: url } as IFormEntryModel);
    }

    function submitHandler() {
      const temp = {
        ...formEntry,
        formName: selectedFormState.form?.name,
        submissionStatus: "Submitted",
      } as IFormEntryModel;
      setFormEntry(temp);
      setDisablePrompt(true);

      if (selectedFormState.form?.name) {
        saveFormHelper(selectedFormDispatch, selectedFormState.form?.name, temp, formDataFields!, navigate);
      }
    }

    const [visible, setVisible] = useState<boolean>(false);
    const [submit, setSubmit] = useState<boolean>(false);

    const toggleDialog = () => {
      setVisible(!visible);
    };

    const toggleSubmitDialog = () => {
      setSubmit(!submit);
      setVisible(!visible);
    };

    const dialogTitle = (
      <div>
        {submit && !formEntry?.underRevision && formEntry?.submissionStatus != "Submitted" && (
          <button className="button" onClick={submitHandler}>
            submit form
          </button>
        )}
      </div>
    );

    return <>
      <div className={classes.form}>
        {visible && (
          <Dialog
            className="customFormDialog"
            title={dialogTitle}
            onClose={submit ? toggleSubmitDialog : toggleDialog}
            width={"70vw"}
            height={"90vh"}
          >
            <TimberlandAppraisalFormPDFView
              getPdfBase64Url={setFormEntryBase64Url}
              formDataFieldsFirstSection={formFirstSectionDataFields}
              formDataFieldsSecondSection={formSecondSectionDataFields}
              formDataFieldsThirdSection={formThirdSectionDataFields}
              formDataFieldsFourthSection={formFourthSectionDataFields}
              formDataFieldsFifthSection={formFifthSectionDataFields}
              formDataFieldsSixthSection={formSixthSectionDataFields}
            />
          </Dialog>
        )}

        <FormHeader
          formName={TimberlandAppraisalFormName}
          isAdminPreview={isAdminPreview}
          isAuthorized={isAuthorized}
          formEntry={formEntry}
          formSettings={formSettings}
          isFormValid={isFormValid}
          onPrint={() => toggleDialog()}
          onSave={() => saveHandler()}
          onSubmit={() => toggleSubmitDialog()}
        />

        <TimberlandAppraisalFormFirstSection
          isAdminPreview={isAdminPreview}
          formSettings={formSettings}
          formDataFields={formFirstSectionDataFields}
          setFormDataFields={setFormFirstSectionDataFields}
          setFormIsValid={setFirstSectionIsValid}
        />

        <TimberlandAppraisalFormSecondSection
          isAdminPreview={isAdminPreview}
          formSettings={formSettings}
          formDataFields={formSecondSectionDataFields}
          setFormDataFields={setFormSecondSectionDataFields}
          setFormIsValid={setSecondSectionIsValid}
        />

        <TimberlandAppraisalFormThirdSection
          isAdminPreview={isAdminPreview}
          formSettings={formSettings}
          formDataFields={formThirdSectionDataFields}
          setFormDataFields={setFormThirdSectionDataFields}
          setFormIsValid={setThirdSectionIsValid}
        />

        <TimberlandAppraisalFormFourthSection
          isAdminPreview={isAdminPreview}
          formSettings={formSettings}
          formDataFields={formFourthSectionDataFields}
          setFormDataFields={setFormFourthSectionDataFields}
          setFormIsValid={setFourthSectionIsValid}
        />

        <TimberlandAppraisalFormFifthSection
          isAdminPreview={isAdminPreview}
          formSettings={formSettings}
          formEntry={formEntry}
          formDataFields={formFifthSectionDataFields}
          setFormDataFields={setFormFifthSectionDataFields}
          setFormIsValid={setFifthSectionIsValid}
        />

        <TimberlandAppraisalFormSixthSection
          isAdminPreview={isAdminPreview}
          formSettings={formSettings}
          formDataFields={formSixthSectionDataFields}
          setFormDataFields={setFormSixthSectionDataFields}
          setFormIsValid={setSixthSectionIsValid}
          selectedFormDispatch={selectedFormDispatch}
        />

        <TimberlandAppraisalFormSeventhSection />

        {(!isFormValid && !formSettings?.isReadOnly) &&
          <div className={classes.invalidForm}>
            <h2>Please Fill All Required Fields in Steps:</h2>
            {!firstSectionIsValid &&
              <div className={classes.item}>
                1.   Filing Information
              </div>}
            {!secondSectionIsValid &&
              <div className={classes.item}>
                2.   Property Owner/Applicant
              </div>}
            {!thirdSectionIsValid &&
              <div className={classes.item}>
                3.   Authorized Representative
              </div>}
            {!fourthSectionIsValid &&
              <div className={classes.item}>
                4.   Property Description and Information
              </div>}
            {!fifthSectionIsValid &&
              <div className={classes.item}>
                5.   Property’s Use
              </div>}
            {!sixthSectionIsValid &&
              <div className={classes.item}>
                6.   Signature
              </div>}
          </div>}

        {isAuthorized && !isAdminPreview &&
          !formSettings?.isReadOnly &&
          formEntry?.submissionStatus != "Submitted" && (
            <span className="buttons-group">
              <button disabled={!isFormValid}
                className="button"
                onClick={() => toggleSubmitDialog()}
              >
                <i className="fa fa-tasks fa-fw"></i>
                Review & Submit
              </button>
            </span>
          )}
      </div>
      {(loading || isLoading) && <CustomLoader />}
      {showPrompt && isAuthorized && (!loading && !isLoading) && (
        <UnsavedFormDialog
          onConfirm={confirmNavigation}
          onCancel={cancelNavigation}
        />
      )}
    </>
  }