import { UserActions } from "src/context/User/user-actions";
import type { UserActionType } from "src/context/User/user-actions.type";
import type { UserInfo } from "src/interfaces/public/user-info.interface";

export interface initialStateType {
  user: UserInfo | null;
  loading: boolean;
  errorMessage: string | null;
}

export const initialState: initialStateType = {
  user: null,
  loading: false,
  errorMessage: null,
};

export const UserReducer = (
  initialState: initialStateType,
  action: UserActionType
): initialStateType => {
  switch (action.type) {
    case UserActions.REQUEST_USER_INFO:
      return {
        ...initialState,
        loading: true,
      };
    case UserActions.USER_INFO_SUCCESS:
      return {
        ...initialState,
        user: (action?.payload as UserInfo)
          ? (action?.payload as UserInfo)
          : null,
        loading: false,
      };
    case UserActions.USER_INFO_ERROR:
      return {
        ...initialState,
        loading: false,
        errorMessage: action?.error ? action?.error : null,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
