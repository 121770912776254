import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "src/pages/Home/components/Forms/TimberlandAppraisalForm/TimberlandAppraisal.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { DatePickerChangeEvent, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, RadioGroupChangeEvent, Input, Checkbox, RadioGroup } from "@progress/kendo-react-inputs";
import { FormValidation } from "../InputValidator";
import { Error, Label } from "@progress/kendo-react-labels";
import { StatesInput } from "../CustomInputs/StatesInput";
import { ZipCodeInput } from "../CustomInputs/ZipCodeInput";
import { EmailInput } from "../CustomInputs/EmailInputs/EmailInput";
import { PhoneNumberInput } from "../CustomInputs/PhoneNumberInputs/PhoneNumberInput";
import { ITimberlandAppraisalFormThirdSection } from "src/interfaces/forms/TimberlandAppraisalForm/timberland-appraisal-form-third-section.interface";
import SectionTitle from "../Shared/SectionTitle";
import { typeOfAuthorizedRepresentativeRadioButtonData } from "../Shared/RadioButtonData";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: ITimberlandAppraisalFormThirdSection) => void;
    formDataFields?:  ITimberlandAppraisalFormThirdSection,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function TimberlandAppraisalFormSecondSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, setFormIsValid } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const [phoneNumberInputValid, setPhoneNumberInputValid] = useState<boolean>(false);
    const [emailInputValid, setEmailInputValid] = useState<boolean>(false);
    const [mailingAddressStateInputValid, setMailingAddressStateInputValid] = useState<boolean>(false);
    const [mailingAddressZipCodeInputValid, setMailingAddressZipCodeInputValid] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator } = FormValidation(formRef);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={3} name={"Authorized Representative"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string,
        group?: string
    ) {
        if (group) {
            setFormDataFields({
                ...formDataFields,
                [group]: {
                    ...(formDataFields as any)?.[group],
                    [field]: event.value
                }
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    function changeValidator() {
        if (formRef.current) {
            const isValid = formRef.current.checkValidity() &&
                authorizedRepresentativeTypeValidator() &&
                phoneNumberInputValid &&
                emailInputValid &&
                mailingAddressStateInputValid &&
                mailingAddressZipCodeInputValid;
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    const authorizedRepresentativeTypeValidator = () => {
        return !!(formDataFields?.AuthorizedRepresentativeType);
    };

    function onTouch(){
        if (!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [
        formDataFields,
        formRef.current,
        phoneNumberInputValid,
        emailInputValid,
        mailingAddressStateInputValid,
        mailingAddressZipCodeInputValid
    ]);

    return (
       <>
            <PanelBar keepItemsMounted={true}>
                <PanelBarItem title={title} expanded={true}>
                    <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                        <div className="container">
                            <div className={classes.information}>
                                <p>
                                    If you are an individual property owner filing this application on your own 
                                    behalf, skip to Section 4; all other applicants are required to complete Section 3.
                                </p>
                            </div>
                            <div className={classes.item}>
                                <div className={`${classes.itemFields} {classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Please indicate the basis for your authority to represent the property owner in filing this application:
                                        {!authorizedRepresentativeTypeValidator() && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                    <div className={`${classes.inputsGroup} ${classes.fieldsGroupW100}`}>
                                        <RadioGroup
                                            name={"AuthorizedRepresentativeType"}
                                            layout={"vertical"}
                                            disabled={formSettings?.isReadOnly}
                                            data={typeOfAuthorizedRepresentativeRadioButtonData}
                                            onChange={e => changeValueHandler(e, "AuthorizedRepresentativeType")}
                                            value={formDataFields?.AuthorizedRepresentativeType}
                                        />                                            
                                        {formDataFields?.AuthorizedRepresentativeType === "Other" &&
                                            <div className={classes.paddingTop}>
                                                <Label className={classes.Label}>
                                                    Explain basis
                                                    {!inputValidator("AuthorizedRepresentativeTypeOther") && (
                                                        <i className={"fa fa-asterisk text-danger"}></i>
                                                    )}
                                                </Label>
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"AuthorizedRepresentativeTypeOther"}
                                                    placeholder={"Explain basis"}
                                                    required={true}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.AuthorizedRepresentativeTypeOther}
                                                    onChange={(e)=> changeValueHandler(e, "AuthorizedRepresentativeTypeOther")}
                                                />
                                                {!inputValidator("AuthorizedRepresentativeTypeOther") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div> }
                                    </div>
                                </div>                                
                                <p>
                                    Provide the following information for the individual with the legal authority 
                                    to act for the property owner in this matter:
                                </p>
                                <div className={`${classes.item}`}>
                                    <div className={classes.itemFields}>
                                        <div className={`${classes.fieldsGroup} ${classes.fieldsGroupW50} ${classes.column}`}>
                                            <Label className={classes.Label}>
                                                Name of Authorized Representative
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                name={"AuthorizedRepresentativeName"}
                                                placeholder={"Name of Authorized Representative"}
                                                required={false}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.AuthorizedRepresentativeName}
                                                onChange={(e)=> changeValueHandler(e, "AuthorizedRepresentativeName")}
                                            />
                                            <Label className={classes.Label}>
                                                Title of Authorized Representative
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                name={"AuthorizedRepresentativeTitle"}
                                                placeholder={"Title of Authorized Representative"}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.AuthorizedRepresentativeTitle}
                                                onChange={(e)=> changeValueHandler(e, "AuthorizedRepresentativeTitle")}
                                            />
                                            <Label className={classes.Label}>
                                                Phone Number (area code and number)
                                            </Label>
                                            <PhoneNumberInput
                                                required={false}
                                                setFormDataFields={setFormDataFields}
                                                fieldName={"AuthorizedRepresentativePhoneNumber"}
                                                placeholder={"Phone Number (area code and number)"}
                                                formDataFields={formDataFields}
                                                isReadOnly={formSettings?.isReadOnly}
                                                setIsValidField={setPhoneNumberInputValid}
                                                validField={phoneNumberInputValid}
                                            />
                                            <Label className={classes.Label}>
                                                Email Address
                                            </Label>
                                            <EmailInput
                                                required={false}
                                                setFormDataFields={setFormDataFields}
                                                fieldName={"AuthorizedRepresentativeEmailAddress"}
                                                placeholder={"Email Address"}
                                                formDataFields={formDataFields}
                                                isReadOnly={formSettings?.isReadOnly}
                                                setIsValidField={setEmailInputValid}
                                                validField={emailInputValid}
                                            />
                                        </div>
                                        <div className={`${classes.fieldsGroup} ${classes.fieldsGroupW50} ${classes.column}`}>
                                            <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                                <p>Mailing Address</p>
                                            </div>
                                            <div className={classes.itemFields}>
                                                <Label className={classes.Label}>
                                                    Address Line 1
                                                </Label>
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"AuthorizedRepresentativeMailingAddressLine1"}
                                                    placeholder={"Address Line 1"}
                                                    required={false}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.AuthorizedRepresentativeMailingAddressLine1}
                                                    onChange={(e)=> changeValueHandler(e, "AuthorizedRepresentativeMailingAddressLine1")}
                                                />
                                                <Label className={classes.Label}>
                                                    Address Line 2
                                                </Label>
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"AuthorizedRepresentativeMailingAddressLine2"}
                                                    placeholder={"Address Line 2"}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.AuthorizedRepresentativeMailingAddressLine2}
                                                    onChange={(e)=> changeValueHandler(e, "AuthorizedRepresentativeMailingAddressLine2")}
                                                />
                                            </div>
                                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                                <div className={`${classes.fieldsGroupW30}`}>
                                                    <Label className={classes.Label}>
                                                        City
                                                    </Label>
                                                    <Input
                                                        className={classes.kTextbox}
                                                        required={false}
                                                        name={"AuthorizedRepresentativeMailingAddressCity"}
                                                        placeholder={"City"}
                                                        disabled={formSettings?.isReadOnly}
                                                        value={formDataFields?.AuthorizedRepresentativeMailingAddressCity}
                                                        onChange={(e)=> changeValueHandler(e, "AuthorizedRepresentativeMailingAddressCity")}
                                                    />
                                                </div>
                                                <div className={`${classes.fieldsGroupW30}`}>
                                                    <Label className={classes.Label}>
                                                        State
                                                    </Label>
                                                    <StatesInput
                                                        setFormDataFields={setFormDataFields}
                                                        fieldName={"AuthorizedRepresentativeMailingAddressState"}
                                                        required={false}
                                                        validField={mailingAddressStateInputValid}
                                                        setIsValidField={setMailingAddressStateInputValid}
                                                        formDataFields={formDataFields}
                                                        isReadOnly={formSettings?.isReadOnly}
                                                    />
                                                </div>
                                                <div className={`${classes.fieldsGroupW20}`}>
                                                    <Label className={classes.Label}>
                                                        Zip Code
                                                    </Label>
                                                    <ZipCodeInput
                                                        setFormDataFields={setFormDataFields}
                                                        fieldName={"AuthorizedRepresentativeMailingAddressZip"}
                                                        placeholder={"Zip"}
                                                        required={false}
                                                        formDataFields={formDataFields}
                                                        isReadOnly={formSettings?.isReadOnly}
                                                        setIsValidField={setMailingAddressZipCodeInputValid}
                                                        validField={mailingAddressZipCodeInputValid}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                   </form>
                </PanelBarItem>
            </PanelBar>
       </>
    )
}