import {
  action,
  actionError,
  actionPayload,
} from "src/context/generic-actions";

export enum AdminPermissionActionsEnum {
  ERROR = "ERROR",
  IS_LOADING = "IS_LOADING",
  SELECT_USER_OR_GROUP = "SELECT_USER_OR_GROUP",
  SELECTED_ID = "SELECTED_ID",
  REMOVE_SELECTED_USER = "REMOVE_SELECTED_USER",
  SET_USERS = "SET_USERS",
  SET_PERMISSIONS = "SET_PERMISSIONS",
  SET_GROUPS = "SET_GROUPS",
  SET_FORM_GROUPS = "SET_FORM_GROUPS",
  UPDATE_SELECTED_PERMISSION_IDS = "UPDATE_SELECTED_PERMISSION_IDS",
  REMOVE_SELECTED_GROUP = "REMOVE_SELECTED_GROUP",
  GET_USER_GROUP_SELECT_LIST = "GET_USER_GROUP_SELECT_LIST",
  GET_INIT_USER_GROUP_SELECT_LIST = "GET_INIT_USER_GROUP_SELECT_LIST",
  CATEGORY = "CATEGORY",
  SUCCESS_MESSAGE = "SUCCESS_MESSAGE",
}

export const Dispatches = {
  requestLogin: action,
  loginError: actionError,
  loginSuccess: actionPayload,
};
