export const ProvincesList = [
    { Text: "AA", Value: "AA" },
    { Text: "AE", Value: "AE" },
    { Text: "AP", Value: "AP" },
    { Text: "AL", Value: "AL" },
    { Text: "AK", Value: "AK" },
    { Text: "AZ", Value: "AZ" },
    { Text: "AR", Value: "AR" },
    { Text: "CA", Value: "CA" },
    { Text: "CO", Value: "CO" },
    { Text: "CT", Value: "CT" },
    { Text: "DC", Value: "DC" },
    { Text: "DE", Value: "DE" },
    { Text: "FL", Value: "FL" },
    { Text: "GA", Value: "GA" },
    { Text: "HI", Value: "HI" },
    { Text: "ID", Value: "ID" },
    { Text: "IL", Value: "IL" },
    { Text: "IN", Value: "IN" },
    { Text: "IA", Value: "IA" },
    { Text: "KS", Value: "KS" },
    { Text: "KY", Value: "KY" },
    { Text: "LA", Value: "LA" },
    { Text: "ME", Value: "ME" },
    { Text: "MD", Value: "MD" },
    { Text: "MA", Value: "MA" },
    { Text: "MI", Value: "MI" },
    { Text: "MN", Value: "MN" },
    { Text: "MS", Value: "MS" },
    { Text: "MO", Value: "MO" },
    { Text: "MT", Value: "MT" },
    { Text: "NE", Value: "NE" },
    { Text: "NV", Value: "NV" },
    { Text: "NH", Value: "NH" },
    { Text: "NJ", Value: "NJ" },
    { Text: "NM", Value: "NM" },
    { Text: "NY", Value: "NY" },
    { Text: "NC", Value: "NC" },
    { Text: "ND", Value: "ND" },
    { Text: "OH", Value: "OH" },
    { Text: "OK", Value: "OK" },
    { Text: "OR", Value: "OR" },
    { Text: "PA", Value: "PA" },
    { Text: "RI", Value: "RI" },
    { Text: "SC", Value: "SC" },
    { Text: "SD", Value: "SD" },
    { Text: "TN", Value: "TN" },
    { Text: "TX", Value: "TX" },
    { Text: "UT", Value: "UT" },
    { Text: "VT", Value: "VT" },
    { Text: "VA", Value: "VA" },
    { Text: "WA", Value: "WA" },
    { Text: "WV", Value: "WV" },
    { Text: "WI", Value: "WI" },
    { Text: "WY", Value: "WY" }
]
