import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./BPPForm.module.scss";
import {Label} from "@progress/kendo-react-labels";
import {CheckboxChangeEvent, Input, InputChangeEvent, TextAreaChangeEvent} from "@progress/kendo-react-inputs";
import {DatePicker, DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import {
    IBppFormFifteenthSectionInterface
} from "../../../../../interfaces/forms/BPPForm/bpp-form-fifteenth-section.interface";
import {DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import React, {useEffect, useRef, useState} from "react";
import { Error } from "@progress/kendo-react-labels";
import {MMddyyyyDateFormat} from "../../../../../constants/date-formats";
import {FormValidation} from "../InputValidator";
import {EmailInput} from "../CustomInputs/EmailInputs/EmailInput";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IBppFormFifteenthSectionInterface) => void;
    formDataFields?:  IBppFormFifteenthSectionInterface,
    formSettings?: IFormSettingModel
    setFormIsValid: (value: boolean) => void
}

export default function BPPFormFifteenthSection(props: GeneralFormType): JSX.Element {
    const formRef = useRef<HTMLFormElement>(null);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={15} name={"Signature"}/>;

    const { formDataFields, setFormDataFields, formSettings, isAdminPreview, setFormIsValid } = props;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent,
        field: string,
    )
    {
        if(field == "SignatureDate"){
            setFormDataFields({
                ...formDataFields,
                [field]: event.value.toDateString()
            });
        } else if(field == "Signature") {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value,
                ["SignatureDate"]: new Date().toDateString()
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    const [emailInputValid, setEmailInputValid] = useState<boolean>(false);

    function changeValidator() {
        if(formRef.current) {
            const valid = formRef.current.checkValidity() && emailInputValid;
            setIsValid(valid);
            setFormIsValid(valid);
        }
    }

    function onTouch(){
        if(!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current, emailInputValid]);

    const { validationMessage, inputValidator, inputValidatorMessage, setStyles } = FormValidation(formRef);

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={`${classes.itemFields}`}>
                                <blockquote className={classes.information}>
                                    <p>
                                        <small>
                                            By submitting this form you attest that the information contained on it is true
                                            and correct to the best of you knowledge and belief. If you are a secured party,
                                            property owner, an employee of the property owner, or an employee of a property
                                            owner on behalf of an affiliated entity of the property owner, you may submit
                                            this form.
                                        </small>
                                    </p>
                                    <p>
                                        <small>
                                            NOTE: The signature on this report must be notarized
                                            unless the person filing the report is a secured party
                                            as defined by Tax Code Section 22.01, the property
                                            owner, an employee of the property owner, an employee
                                            of the property owner on behalf of an affiliated
                                            entity of the property owner or on behalf of a
                                            property owner who is rendering tangible personal
                                            property with a good faith estimate of not more than
                                            $150,000 in total market value.
                                        </small>
                                    </p>
                                    <p>
                                        <small>
                                            If you are an agent and would like to render online
                                            using this application for properties with a good faith
                                            estimate of MORE than $150,000 in total market value,
                                            complete the following steps:
                                        </small>
                                    </p>
                                    <ul>
                                        <li><small>Fill out the rendition</small></li>
                                        <li><small>Click Save Progress</small></li>
                                        <li><small>Click Print</small></li>
                                        <li><small>Notarize the printed copy</small></li>
                                        <li><small>Upload the notarized copy as a supporting document in the saved form</small></li>
                                        <li><small>Click Review & Submit</small></li>
                                    </ul>
                                </blockquote>
                            </div>
                            <div className={`${classes.itemFields}`}>
                                <div className={`${classes.fieldsGroupW49}`}>
                                    <div className="inputsGroup">
                                        <Label className={classes.Label}>
                                            Printed Name
                                            <i className="fa fa-asterisk text-danger"></i>
                                        </Label>
                                        <div className="inputsGroup">
                                            <Input
                                                className={classes.kTextbox}
                                                name={"Signature"}
                                                required={true}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.Signature}
                                                onChange={e => changeValueHandler(e, "Signature")}
                                            />
                                            {!inputValidator("Signature") && (
                                                <Error>{validationMessage}</Error>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={`${classes.fieldsGroupLeft}`}>
                                    <div className="inputsGroup">
                                        <Label className={classes.Label}>
                                            Date Signed
                                            <i className="fa fa-asterisk text-danger"></i>
                                        </Label>
                                        <div className="inputsGroup">
                                            <DatePicker
                                                format={MMddyyyyDateFormat}
                                                name={"SignatureDate"}
                                                required={true}
                                                disabled={true}
                                                className={classes.kTextbox}
                                                value={formDataFields?.SignatureDate ? new Date(formDataFields?.SignatureDate) : undefined}
                                                onChange={e => changeValueHandler(e, "SignatureDate")}
                                            />
                                            {!inputValidator("SignatureDate") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={`${classes.fieldsGroupW49}`}>
                                    <div className="inputsGroup">
                                        <Label className={classes.Label}>
                                            Email Address
                                        </Label>
                                        <div className="inputsGroup">
                                            <EmailInput
                                                required={false}
                                                setFormDataFields={setFormDataFields}
                                                fieldName={"EmailAddress"}
                                                formDataFields={formDataFields}
                                                isReadOnly={formSettings?.isReadOnly}
                                                setIsValidField={setEmailInputValid}
                                                validField={emailInputValid}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.itemFields}`}>
                                <div className="list-group-item ng-scope">
                                    <div className="form-group form-field-type-raw-html formio-component-rawHtml">
                                        <div id="rawHtml" className="font-bold">If you make a false
                                            statement on this form, you could be found guilty of a Class A misdemeanor or a
                                            state jail felony under Penal Code Section 37.10.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
