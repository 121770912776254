export type IDisabledVetFourthSection = {
    PropertyId?: string;
    PropertyLegalDescription?: string;
    PropertyAddressLine1?: string;
    PropertyAddressLine2?: string;
    PropertyAddressCity?: string;
    PropertyAddressState?: string;
    PropertyAddressZip?: string;
    ManufacturedMake?: string;
    ManufacturedModel?: string;
    ManufacturedIdNumber?: string;
}

export const DisabledVetFourthSectionInterface = (): IDisabledVetFourthSection => ({
    PropertyId: undefined,
    PropertyLegalDescription: undefined,
    PropertyAddressLine1: undefined,
    PropertyAddressLine2: undefined,
    PropertyAddressCity: undefined,
    PropertyAddressState: undefined,
    PropertyAddressZip: undefined,
    ManufacturedMake: undefined,
    ManufacturedModel: undefined,
    ManufacturedIdNumber: undefined
})