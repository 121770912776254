import {IFormFilesInterface} from "../Shared/form-files.interface";

export type IHomesteadExemptionFourthSectionInterface = {
    DatePurchasedProperty?: string,
    DateFirstOccupied?: string,
    PropertyId?: string,
    PropertyNumberOfAcres?: number, // max 20
    PropertyLegalDescription?: string,
    PropertyAddressLine1?: string,
    PropertyAddressLine2?: string,
    PropertyAddressCity?: string,
    PropertyAddressState?: string,
    PropertyAddressZip?: string,
    IsApplicantOnDeed?: boolean,
    CourtRecordorFilingNumber?: string,
    HeirPropertyDocuments?: Array<IFormFilesInterface>,
    IsAnHeirProperty?: boolean,
    DoOtherHeirPropertyOwnersOccupy?: boolean,
    OtherPropertyOwnersDocuments?: Array<IFormFilesInterface>,
    IsIncomeProducing?: boolean,
    PercentageOfPropertyThatIsIncomeProducing?: string,
    IsManufacturedHome?: boolean,
    MobileHomeMake?: string,
    MobileHomeModel?: string,
    MobileHomeIdNumber?: string,
    ManufacturedHomeSupportingDocuments?: Array<IFormFilesInterface>,
}

export const HomesteadExemptionFormFourthSectionInitialState = (): IHomesteadExemptionFourthSectionInterface => ({
    DatePurchasedProperty: undefined,
    DateFirstOccupied: undefined,
    PropertyId: undefined,
    PropertyNumberOfAcres: undefined,
    PropertyLegalDescription: undefined,
    PropertyAddressLine1: undefined,
    PropertyAddressLine2: undefined,
    PropertyAddressCity: undefined,
    PropertyAddressState: undefined,
    PropertyAddressZip: undefined,
    IsApplicantOnDeed: undefined,
    CourtRecordorFilingNumber: undefined,
    HeirPropertyDocuments: undefined,
    IsAnHeirProperty: undefined,
    DoOtherHeirPropertyOwnersOccupy: undefined,
    IsIncomeProducing: undefined,
    PercentageOfPropertyThatIsIncomeProducing: undefined,
    IsManufacturedHome: undefined,
    MobileHomeMake: undefined,
    MobileHomeModel: undefined,
    MobileHomeIdNumber: undefined,
    ManufacturedHomeSupportingDocuments: undefined,
})
