import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "src/context/Form/form-context";
import { onLoadForms } from "src/context/Form/form-helper";
import AuthService from 'src/services/authentication.service';
import { settingsService } from "src/services/settings.service";
import appClasses from "src/styles/modules/app.module.scss";
import FormList from "./components/FormList";
import classes from "./Welcome.module.scss";

export default function Welcome(): JSX.Element {
  const { formDispatch } = useFormContext();

  const { settings } = settingsService();
  const { isAuthorized } = AuthService.getInstance();

  const { t } = useTranslation();

  useEffect(() => {
    onLoadForms(formDispatch);
  }, []);

  return (
    <div className={isAuthorized ? classes.UserWrapper : classes.Wrapper}>
      <div className={classes.Header}>
        <span className={classes.Title}>
          {t("welcomePart.title", {
            name: settings.clientName,
          })}
        </span>
        <span className={classes.SubTitle}>{t("welcomePart.subTitle")}</span>
      </div>

      <div className={appClasses.GreyBorder}></div>
      <ul className={classes.UnorderedList}>
        <li>{t("welcomePart.step1")}</li>
        <li>{t("welcomePart.step2")}</li>
        <li>{t("welcomePart.step3")}</li>
        <li>{t("welcomePart.step4")}</li>
      </ul>
      <div className={appClasses.GreyBorder}></div>

      <FormList />
    </div>
  );
}
