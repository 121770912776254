import {
  action,
  actionError,
  actionPayload,
} from "src/context/generic-actions";

export enum FormActionsEnum {
  IS_LOADING = "IS_LOADING",
  ERROR = "ERROR",
  FORMS = "FORMS",
  FORM_ENTRIES = "FORM_ENTRIES",
  SELECTED_FORM_ENTRY = "SELECTED_FORM_ENTRY",
  PERSONAL_PROPERTY_CODES = "PERSONAL_PROPERTY_CODES",
  FORM_ASSETS_SETTINGS = "FORM_ASSETS_SETTINGS",
}

export const Dispatches = {
  requestAvailableForms: action,
  availableFormsError: actionError,
  availableFormsSuccess: actionPayload,
};
