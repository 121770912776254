export type IDisabledVetEighthSection = {
    Signature?: string,
    SignatureDate?: string,
    Title?: string
}

export const DisabledVetEighthSectionInterface = (): IDisabledVetEighthSection => ({
    Signature: undefined,
    SignatureDate: undefined,
    Title: undefined
})