export const ScheduleAName = "ScheduleA";
export const ScheduleBName = "ScheduleB";
export const ScheduleCName = "ScheduleC";
export const ScheduleDName = "ScheduleD";
export const ScheduleEFurnitureFixturesName = "ScheduleE-FurnitureFixtures";
export const ScheduleEMachineryEquipmentName = "ScheduleE-MachineryEquipment";
export const ScheduleEOfficeEquipmentName = "ScheduleE-OfficeEquipment";
export const ScheduleEComputerEquipmentName = "ScheduleE-ComputerEquipment";
export const ScheduleEManufacturingEquipmentName = "ScheduleE-ManufacturingEquipment";
export const ScheduleEOtherName = "ScheduleE-Other";
export const ScheduleFName = "ScheduleF";
