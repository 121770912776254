import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import React, {useEffect, useRef, useState} from "react";
import {
    CheckboxChangeEvent, Input,
    InputChangeEvent,
    TextAreaChangeEvent
} from "@progress/kendo-react-inputs";
import {DatePicker, DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./AgForm.module.scss";
import {Error, Label} from "@progress/kendo-react-labels";
import {IAgFormTenthSectionInterface} from "../../../../../interfaces/forms/AgForm/ag-form-tenth-section.interface";
import {MMddyyyyDateFormat} from "../../../../../constants/date-formats";
import {FormValidation} from "../InputValidator";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IAgFormTenthSectionInterface) => void;
    formDataFields?:  IAgFormTenthSectionInterface,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function AgFormTenthSection(props: GeneralFormType): JSX.Element {
    const {setFormDataFields, formDataFields, formSettings, setFormIsValid} = props;

    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} name={"Section 7: Certification and Signature"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent,
        field: string,
    )
    {
        if(field == "SignatureDate"){
            setFormDataFields({
                ...formDataFields,
                [field]: event.value.toDateString()
            });
        } else if(field == "Signature") {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value,
                ["SignatureDate"]: new Date().toDateString()
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    function changeValidator() {
        if (formRef.current) {
            setIsValid(formRef.current.checkValidity());
            setFormIsValid(formRef.current.checkValidity());
        }
    }

    function onTouch() {
        if (!isFormTouched) {
            setIsFormTouched(true);
        }
    }

    const { validationMessage, inputValidator } = FormValidation(formRef);

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current]);

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={`${classes.itemFields}`}>

                                <p className={`${classes.alert} ${classes.alertDanger}`}>

                                    NOTICE REGARDING PENALTIES FOR MAKING OR FILING AN APPLICATION CONTAINING A FALSE STATEMENT: If you make a false statement on this form, you could be found guilty of a Class A misdemeanor or a state jail felony under Penal Code Section 37.10.

                                </p>
                                <p className={classes.information}>
                                    By submitting this form you swear or affirm the following:
                                    1. that each fact contained in this application is true and correct;
                                    2. that the property the owner identified in this application meets the qualifications under Texas law for the exemption claimed; and
                                    3. that I have read and understand the Notice Regarding Penalties for Making or Filing an Application Containing a False Statement.
                                </p>

                            </div>
                            <div className={`${classes.itemFields}`}>
                                <div className={`${classes.fieldsGroupW49}`}>
                                    <div className="inputsGroup">
                                        <Label className={classes.Label}>
                                            Printed Name
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        </Label>
                                        <div className="inputsGroup">
                                            <Input
                                                className={classes.kTextbox}
                                                name={"Signature"}
                                                required={true}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.Signature}
                                                onChange={e => changeValueHandler(e, "Signature")}
                                            />
                                            {!inputValidator("Signature") && (
                                                <Error>{validationMessage}</Error>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={`${classes.fieldsGroupLeft}`}>
                                    <div className="inputsGroup">
                                        <Label className={classes.Label}>
                                            Date Signed
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        </Label>
                                        <div className="inputsGroup">
                                            <DatePicker
                                                format={MMddyyyyDateFormat}
                                                name={"SignatureDate"}
                                                required={true}
                                                disabled={true}
                                                className={classes.kTextbox}
                                                value={formDataFields?.SignatureDate ? new Date(formDataFields?.SignatureDate) : undefined}
                                                onChange={e => changeValueHandler(e, "SignatureDate")}
                                            />
                                            {!inputValidator("SignatureDate") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
