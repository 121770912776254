export interface IBppFormFifteenthSectionInterface{
    Signature?: string,
    EmailAddress?: string,
    SignatureDate?: string
}


export const FifteenthSectionInitialState = {
    Signature: undefined,
    EmailAddress: undefined,
    SignatureDate: undefined
}