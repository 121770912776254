import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "src/pages/Home/components/Forms/TimberlandAppraisalForm/TimberlandAppraisal.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { DatePickerChangeEvent, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, RadioGroupChangeEvent } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { CustomDatePicker } from "../CustomInputs/CustomDatePickers/CustomDatePicker";
import { CustomOnlyYearCalendarHelper } from "src/pages/Shared/DatePickers/CustomCalendars";
import { ITimberlandAppraisalFormFirstSection } from "src/interfaces/forms/TimberlandAppraisalForm/timberland-appraisal-form-first-section.interface";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: ITimberlandAppraisalFormFirstSection) => void;
    formDataFields?:  ITimberlandAppraisalFormFirstSection,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function TimberlandAppraisalFormFirstSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, isAdminPreview, setFormIsValid } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const [taxYearInputValid, setTaxYearInputValid] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={1} name={"Filing Application"}/>;
    const { CustomOnlyYearCalendar } = CustomOnlyYearCalendarHelper();

    function changeValidator() {
        if (formRef.current) {
            const isValid = formRef.current.checkValidity() &&
                taxYearInputValid;
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if(!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current, taxYearInputValid]);

    return (
       <>
            <div className={classes.information}>
                <div>
                    <p>
                        <b>GENERAL INFORMATION:</b> 
                        Texas Constitution, Article VIII, Section 1-d-1, and Tax Code, Chapter 23, Subchapter E, 
                        provide for appraisal of open-space land devoted principally to producing timber and forest 
                        products based on its capacity to produce these products.
                    </p>
                </div>
                <div>
                    <p>
                        <b>FILING INSTRUCTIONS:</b> This form must be filed with the appraisal district office in 
                        each county in which the property is located. 
                        <b>Do <u>not</u> file this document with the Texas Comptroller of Public Accounts.</b>
                    </p>
                </div>
            </div>
            <PanelBar keepItemsMounted={true}>
                <PanelBarItem title={title} expanded={true}>
                    <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                        <div className="container">
                            <div className={classes.item}>
                                <div className={`${classes.itemFields} ${classes.itemFieldsLeft} ${classes.bordered}`}>
                                    <div className={`${classes.fieldsGroup} ${classes.fieldsGroupLeft}`}>
                                        <Label className={classes.Label}>
                                            Tax Year
                                            {!formDataFields?.TaxYear && (
                                                <i className={"fa fa-asterisk text-danger"}></i>
                                            )}
                                        </Label>
                                        <div className="inputsGroup">
                                            <CustomDatePicker
                                                fieldName={"TaxYear"}
                                                required={true}
                                                disabled={formSettings?.isReadOnly}
                                                formDataFields={formDataFields}
                                                setFormDataFields={setFormDataFields}
                                                onlyYear={true}
                                                CustomCalendar={CustomOnlyYearCalendar}
                                                setIsValidField={setTaxYearInputValid}
                                                validField={taxYearInputValid}
                                                defaultValueDate={formDataFields?.TaxYear}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                   </form>
                </PanelBarItem>
            </PanelBar>
       </>
    )
}