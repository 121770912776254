import {Input, InputChangeEvent} from "@progress/kendo-react-inputs";
import classes from "../custom-field.module.scss";
import React, {useEffect, useState} from "react";
import {Error} from "@progress/kendo-react-labels";
import {IRowState, UpdateErrorsState} from "../UpdateRowErrorStateHandler";
import {EmailInputErrorsHandler, ErrorState} from "./UpdateEmailErrorsState";
interface EmailInput {
    isReadOnly?: boolean,
    setFormDataFields: (form: any) => void;
    formDataFields?:  any,
    fieldName: string,
    required: boolean,
    errorsArray: Array<IRowState>;
    setErrorsArray: (item: Array<IRowState>) => void,
    group: string,
    id: number,
}

export function RowEmailInput(props: EmailInput) :JSX.Element {
    const { isReadOnly, formDataFields, setFormDataFields, fieldName, required, errorsArray, setErrorsArray, id, group} = props;
    const [errors, setErrors] = useState<ErrorState>({
        pattern: required,
        required: required,
    });
    const [isInputValid, setIsInputValid] = useState<boolean>(false);
    const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    function changeRowDataHandler( event:any,
                                   field: string,
                                   group: string,
                                   id: number){
        const newItems = formDataFields ? JSON.parse(JSON.stringify(formDataFields)) : [];

        let item = (newItems as any)[group]?.find((x: any)=> x.Id == id);


        item = {...item,
            [field]: event?.value?.toString(),
            Id: id
        };

        const index = (newItems as any)[group]?.findIndex((x: any)=>x.Id == id);

        if(index != undefined && (newItems as any)[group]) {
            (newItems as any)[group][index] = item
        }

        EmailInputErrorsHandler({
            required: required,
            event: event,
            setErrors: setErrors,
            errors: errors
        })

        setFormDataFields({
            ...newItems
        })
    }


    useEffect(() => {
        if((errors.pattern || errors.required)){
            UpdateErrorsState({
                valid: false,
                setIsInputValid: setIsInputValid,
                id: id,
                errorsArray: errorsArray,
                setErrorsArray: setErrorsArray
            });
        }  else if(!required){
            UpdateErrorsState({
                valid: true,
                setIsInputValid: setIsInputValid,
                id: id,
                errorsArray: errorsArray,
                setErrorsArray: setErrorsArray
            });
        }
    }, [errors, formDataFields?.[fieldName]]);

    return <>

        <Input
            name={fieldName}
            type={"email"}
            required={required}
            className={!isInputValid ? `${classes.input} ${classes.invalidInput}` : classes.input}
            disabled={isReadOnly}
            value={formDataFields?.[fieldName]}
            onChange={(e)=> changeRowDataHandler(e, fieldName, group, id)}
        />
        {errors.pattern && <Error>Please enter valid email!</Error>}
    </>
}
