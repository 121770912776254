import { createContext, useContext, useReducer } from "react";
import type { PublicContextType } from "./public-reducer";
import { initialState, PublicReducer } from "./public-reducer";

const PublicStateContext = createContext<PublicContextType>({
  publicState: initialState,
  publicDispatch: () => null,
});

export function usePublicContext(): PublicContextType {
  const context = useContext(PublicStateContext);

  if (context === undefined) {
    throw new Error("usePublicContext must be used within a PublicProvider");
  }

  return context;
}

export const PublicProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [publicState, publicDispatch] = useReducer(PublicReducer, initialState);

  return (
    <PublicStateContext.Provider value={{ publicState, publicDispatch }}>
      {children as React.ReactNode}
    </PublicStateContext.Provider>
  );
};
