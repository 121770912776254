export type IBppFormSecondSectionInterface = {
    TaxYear?: string;
    IsFreeport?: boolean;
    individual?: boolean;
    corporation?: boolean;
    partnership?: boolean;
    other?: boolean;
    OwnershipTypeOther?: string;
    AccountNumber?: string;
    IsNewProperty?: boolean;
    BusinessName?: string;
    BusinessOwnerName?: string;
    BusinessEmail?: string;
    BusinessPhoneNumber?: string;
    BusinessAddressLine1?: string;
    BusinessAddressLine2?: string;
    BusinessAddressCity?: string;
    BusinessAddressState?: string;
    BusinessAddressZip?: string;
    PropertyAddressLine1?: string;
    PropertyAddressLine2?: string;
    PropertyAddressCity?: string;
    PropertyAddressState?: string;
    PropertyAddressZip?: string;
}

export const SecondSectionInitialState: IBppFormSecondSectionInterface = {
    TaxYear: undefined,
    IsFreeport:undefined,
    individual: undefined,
    corporation: undefined,
    partnership: undefined,
    other: undefined,
    OwnershipTypeOther: undefined,
    AccountNumber: undefined,
    IsNewProperty: undefined,
    BusinessName: undefined,
    BusinessOwnerName: undefined,
    BusinessEmail: undefined,
    BusinessPhoneNumber: undefined,
    BusinessAddressLine1: undefined,
    BusinessAddressLine2: undefined,
    BusinessAddressCity: undefined,
    BusinessAddressState: undefined,
    BusinessAddressZip: undefined,
    PropertyAddressLine1: undefined,
    PropertyAddressLine2: undefined,
    PropertyAddressCity: undefined,
    PropertyAddressState: undefined,
    PropertyAddressZip: undefined,
}
