import {DatePicker} from "@progress/kendo-react-dateinputs";
import React, {useEffect, useRef, useState} from "react";
import classes from "../../Home/components/Forms/CustomInputs/custom-field.module.scss";
import GetDatePickerFormatHelper, {IDatePickerErrorModel} from "./CustomDatePickerHelpers";
import {MaskedTextBox} from "@progress/kendo-react-inputs";
import {Error} from "@progress/kendo-react-labels";

export interface ICustomDatePicker{
    fieldName: string,
    disabled?: boolean,
    CustomCalendar?: any,
    onlyYear: boolean,
    required: boolean,
    initialValue?: string,
    secondName?: string,
    setIsValidField: (item: boolean) => void;
    maxDate?: Date,
    onChange: (value:any, field?: string)  => void,
    label?: string,
    rowItem?: string
}

export function CustomDatePickerField(props: ICustomDatePicker): JSX.Element{
    const {
        fieldName,
        disabled,
        CustomCalendar,
        onlyYear,
        required,
        initialValue,
        secondName,
        maxDate,
        setIsValidField,
        onChange,
        label,
        rowItem
    } = props;

    const [defaultValue, setDefaultValue] = useState<Date | null>(null);
    const [touched, setIsTouched] = useState<boolean>(false);
    const [defaultStringValue, setDefaultStringValue] = useState<string | undefined>(undefined);
    const [focused, setIsFocused] = useState<boolean>(false);
    const [errors, setErrors] = useState<IDatePickerErrorModel>();

    const mask = "99/99/9999";
    const emptyMask = "__/__/____";
    const placeholder = "month/day/year";

    const onlyYearMask = "9999";
    const onlyYearEmptyMask = "____";
    const onlyYearPlaceholder = "year";

    useEffect(() => {
        return () => {
            setDefaultValue(null);
            setIsTouched(false);
        };
    }, []);

    function ParseDate(dateString: string)
    {
        if (!dateString) {
            return "";
        }
        const rawString = dateString.replace("__", "").replace("_", "");
        let day, month, year: string;
        if (dateString.indexOf(".") > -1) {
            const parts = rawString.split(".");
            month = parts && parts[1] && parts[1]?.trim()?.length > 0 ? parts[1] : "__";
            day = parts && parts[0] && parts[0]?.trim()?.length > 0 ? parts[0] : "__";
            year = parts && parts[2] && parts[2]?.trim()?.length > 0 ? parts[2] : "____";
        } else {
            const parts = rawString.split("/");
            day = parts && parts[1] && parts[1]?.trim()?.length > 0 ? parts[1] : "__";
            month = parts && parts[0] && parts[0]?.trim()?.length > 0 ? parts[0] : "__";
            year = parts && parts[2] && parts[2]?.trim()?.length > 0 ? parts[2] : "____";
        }

        if (parseInt(month, 10) > 12){
            month = "12";
        }

        const monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

        if(parseInt(year, 10) % 400 == 0
            || (parseInt(year, 10) % 100 != 0 && parseInt(year, 10) % 4 == 0)) {
            monthLength[1] = 29;
        }

        if (parseInt(day, 10) > monthLength[parseInt(month, 10) - 1]){
            day = monthLength[parseInt(month, 10) - 1].toString();
        }

        return onlyYear ? year : `${month}/${day}/${year}`;
    }

    function changeOnlyYearValueHandler(
        event: any,
        isMaskedInput?: boolean) {

        let value = event.value;

        if(onlyYear && isMaskedInput && event.value !== onlyYearEmptyMask){
            value = "01/01/" + value;
        }

        if(value === onlyYearEmptyMask && isMaskedInput){
            setDefaultStringValue(undefined);
            setDefaultValue(null);
            onChange(null, fieldName);
        } else if(isMaskedInput && Date.parse(value)){
            const year = ParseDate(value);
            const date = new Date(new Date().setFullYear(parseInt(year),1,1));

            setDefaultValue(date);
            setDefaultStringValue(year);
            onChange(date, fieldName);

            setErrors({...errors, invalid: !isValidDate(date.toLocaleString().split(",")[0])})
        } else if(isMaskedInput) {
            const year = ParseDate(value);
            const date = new Date(new Date().setFullYear(parseInt(year),1,1));

            setDefaultValue(date);

            if(year != undefined && year.replace("_","").replace("/","")){
                setDefaultStringValue(year);
            } else {
                setDefaultStringValue(onlyYearEmptyMask);
            }

            onChange(date, fieldName);
            const valid = isValidDate(date?.toLocaleString().split(",")[0]);
            setErrors({...errors, invalid: !valid});
        } else {
            const year = ParseDate(value?.toLocaleString().split(",")[0]);
            const date = new Date(new Date().setFullYear(parseInt(year),1,1));

            setDefaultValue(date);
            setDefaultStringValue(year);

            onChange(date, fieldName);
            setErrors({...errors, invalid: !isValidDate(date?.toLocaleString()?.split(",")[0])});
        }
    }

    function changeValueHandler(
        event: any,
        isMaskedInput?: boolean) {

        const value = event.value;

        if(value === emptyMask && isMaskedInput){
            setDefaultStringValue(undefined);
            setDefaultValue(null);
            onChange(null, fieldName);
        } else if(isMaskedInput && Date.parse(value)){
            const date = ParseDate(value);

            setDefaultValue(new Date(value));
            setDefaultStringValue(date);

            onChange(new Date(value), fieldName);

            setErrors({...errors, invalid: !isValidDate(date)})
        } else if(isMaskedInput) {
            const date = ParseDate(value);

            if(Date.parse(value)){
                setDefaultValue(value);
            }

            if(date != undefined && date?.replace("_","")?.replace("/","")){
                setDefaultStringValue(date);
            } else {
                setDefaultStringValue(emptyMask);
            }

            onChange(date, fieldName);
            const valid = isValidDate(date);
            setErrors({...errors, invalid: !valid});
        } else {
            const date = ParseDate(value?.toLocaleString()?.split(",")[0]);

            setDefaultValue(value);
            setDefaultStringValue(date);

            onChange(date, fieldName);
            const valid = isValidDate(date);
            setErrors({...errors, invalid: !valid});
        }
    }

    function isValidDate(dateString: string)
    {
        if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
            return false;
        }

        const parts = dateString.split("/");
        const day = parseInt(parts[1], 10);
        const month = parseInt(parts[0], 10);
        const year = parseInt(parts[2], 10);

        if(year < 1900 || year > 3000 || month == 0 || month > 12) {
            return false;
        }

        const monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

        if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
            monthLength[1] = 29;
        }

        return day > 0 && day <= monthLength[month - 1];
    }

    useEffect(() => {
        if(maxDate && defaultValue && defaultValue >= maxDate){
            setErrors({required: false, invalid: false, maxDate: true});
            setIsValidField(false);
        } else if(defaultValue == null && !required) {
            setErrors({required: false, invalid: false, maxDate: false});
            setIsValidField(true);
        } else if(defaultValue != null && defaultValue.getFullYear() >= 1900){
            setErrors({required: false, invalid: false, maxDate: false});
            setIsValidField(true);
        } else if(defaultValue != null && defaultValue.getFullYear() < 1900) {
            setErrors({required: false, invalid: true, maxDate: false});
            setIsValidField(false);
        } else if(defaultValue == null && required) {
            setErrors({required: true, invalid: false, maxDate: false});
            setIsValidField(false);
        }
    }, [maxDate, defaultValue, required]);

    useEffect(() => {
        if (initialValue && !touched && !onlyYear) {
            const date = ParseDate(initialValue?.toLocaleString()?.split(",")[0]);
            setDefaultValue(new Date(date));
            setDefaultStringValue(date);
        } else if (initialValue && !touched) {
            if(initialValue.length === 4) {
                const currentData = new Date(+initialValue,2,2);
                setDefaultValue(currentData);
                setDefaultStringValue(initialValue);
            } else {
                setDefaultValue(new Date(initialValue));
                setDefaultStringValue(new Date(initialValue).getFullYear()?.toString());
            }

        } else if (!initialValue) {
            setDefaultValue(null);
            setDefaultStringValue(undefined);
        }
    }, [initialValue]);


    useEffect(() => {
        if (defaultValue !== null && rowItem && defaultValue?.getFullYear()?.toString() != rowItem  && onlyYear) {
            const date = new Date(parseInt(rowItem),2,2);
            setDefaultValue(date);
            setDefaultStringValue(rowItem);
        }
    }, [rowItem]);

    const inputRef = useRef<HTMLFormElement>(null);

    function onMaskedInputFocusHandler() {
        setIsFocused(true);
        setIsTouched(true);

        if (defaultStringValue === placeholder) {
            setDefaultStringValue(emptyMask);
        } else if (defaultStringValue === onlyYearPlaceholder) {
            setDefaultStringValue(onlyYearEmptyMask);
        }
    }

    function onMaskedInputBlurHandler() {
        setIsFocused(false);

        if ((!defaultStringValue || defaultStringValue === emptyMask) && !onlyYear) {
            setDefaultStringValue(placeholder);
        } else if (!defaultStringValue || (defaultStringValue === onlyYearEmptyMask)) {
            setDefaultStringValue(onlyYearPlaceholder);
        }
    }

    const datePickerStyles = (errors?.invalid || errors?.required || errors?.maxDate)
        ? `${classes.datePicker} ${classes.datePickerFocusInvalid}`
        :  focused
            ? `${classes.datePicker} ${classes.datePickerFocus}`
            : classes.datePicker;


    return  <>
        {label}
        <div className={classes.datePickerContainer}>
            <DatePicker
                format={GetDatePickerFormatHelper(onlyYear)}
                max={maxDate}
                className={datePickerStyles}
                onChange={(e)=> onlyYear ? changeOnlyYearValueHandler(e) : changeValueHandler(e)}
                name={secondName ?? fieldName}
                value={defaultValue}
                disabled={disabled}
                calendar={CustomCalendar}
                required={required}
            />
            {onlyYear ?
                <MaskedTextBox
                    required={required}
                    disabled={disabled}
                    ref={inputRef}
                    valid={true}
                    className={classes.dateMaskedInput}
                    placeholder={onlyYearPlaceholder}
                    mask={onlyYearMask}
                    value={defaultStringValue}
                    onChange={(e)=> changeOnlyYearValueHandler(e, true)}
                    onFocus={onMaskedInputFocusHandler}
                    onBlur={onMaskedInputBlurHandler}
                />
                :
                <MaskedTextBox
                    required={required}
                    disabled={disabled}
                    ref={inputRef}
                    valid={true}
                    className={classes.dateMaskedInput}
                    placeholder={placeholder}
                    mask={mask}
                    value={defaultStringValue}
                    onChange={(e)=> changeValueHandler(e, true)}
                    onFocus={onMaskedInputFocusHandler}
                    onBlur={onMaskedInputBlurHandler}
                />
            }
        </div>
        {errors?.required && (
            <Error>This field is required!</Error>
        )}
        {errors?.invalid && (
            <Error>Please enter valid date!</Error>
        )}
        {errors?.maxDate && (
            <Error>Year must be earlier than the current year!</Error>
        )}
    </>
}

