import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "../WildlifeManagement.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, Checkbox, RadioGroupChangeEvent, Input, RadioGroup } from "@progress/kendo-react-inputs";
import { FormValidation } from "../../InputValidator";
import { Label } from "@progress/kendo-react-labels";
import { IWildlifeManagementFourthSectionHabitatControl } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-habitat-control.interface";
import { CalendarChangeEvent, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { CustomDatePicker } from "../../CustomInputs/CustomDatePickers/CustomDatePicker";
import { CheckboxGroup } from "../../CustomInputs/CheckBoxes/CheckboxGroup";
import SectionTitle from "../../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    formSettings?: IFormSettingModel,
    formDataFields?: IWildlifeManagementFourthSectionHabitatControl;
    setFormDataFields: (form: IWildlifeManagementFourthSectionHabitatControl) => void;
    setSectionIsValid: (value: boolean) => void;
    setFormIsValid: (value: boolean) => void;
    formIsValid?: boolean;
}

export default function HabitatControlSubSection(props: GeneralFormType): JSX.Element {
    const { formSettings, isAdminPreview, formDataFields, setFormDataFields, setFormIsValid, setSectionIsValid } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);    
    const [prescribedBurningPlannedBurnDateIsValid, setPrescribedBurningPlannedBurnDateIsValid] = 
        useState<boolean>(!formDataFields?.PrescribedBurningPlannedBurnDate);
    const [rangeEnhancementDateToBeSeededIsValid, setRangeEnhancementDateToBeSeededIsValid] = 
        useState<boolean>(!formDataFields?.RangeEnhancementDateToBeSeeded);
        
    const [rangeEnhancementFertilizedInputValid, setRangeEnhancementFertilizedInputValid] = 
        useState<boolean>(false);
    const [rangeEnhancementWeedControlNeededInputValid, setRangeEnhancementWeedControlNeededInputValid] = 
        useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator, setStyles } = FormValidation(formRef);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={1} name={"Habitat Control"}/>;
    const fencingOfRiparianAreaRadioButtonsData = [
        { label: "Complete fencing", value: "CompleteFencing" },
        { label: "Partial fencing", value: "PartialFencing" },
    ]
    const defermentFromLivestockGrazingRadioButtonsData = [
        { label: "Complete deferment", value: "CompleteDeferment" },
        { label: "Partial deferment", value: "PartialDeferment" },
    ]
    
    function changeValidator() {
        if (formRef.current) {
            const isValid = formRef.current.checkValidity();
            setIsValid(isValid);
            setSectionIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string,
        group?: string
    ) {
        if (group) {
            setFormDataFields({
                ...formDataFields,
                [group]: {
                    ...(formDataFields as any)?.[group],
                    [field]: event.value
                }
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    function onTouch(){
        if (!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [ formDataFields, formRef.current ]);

    return (
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.GrazingManagement}
                                    label={"Grazing Management"}
                                    onChange={(e)=> changeValueHandler(e, "GrazingManagement")}
                                />
                            </div>
                            { formDataFields?.GrazingManagement && 
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Check grazing system being utilized
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.GrazingManagementSystemTypes?.Herd3Pasture}
                                            label={"1 herd/3 pasture"}
                                            onChange={(e)=>
                                                changeValueHandler(e, "Herd3Pasture", "GrazingManagementSystemTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.GrazingManagementSystemTypes?.Herd4Pasture}
                                            label={"1 herd/4 pasture"}
                                            onChange={(e)=> changeValueHandler(e, "Herd4Pasture", "GrazingManagementSystemTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.GrazingManagementSystemTypes?.HerdMultiplePasture}
                                            label={"1 herd/multiple pasture"}
                                            onChange={(e)=> changeValueHandler(e, "HerdMultiplePasture", "GrazingManagementSystemTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.GrazingManagementSystemTypes?.HighIntensityLowFrequency}
                                            label={"High intensity/low frequency (HILF)"}
                                            onChange={(e)=> changeValueHandler(e, "HighIntensityLowFrequency", "GrazingManagementSystemTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.GrazingManagementSystemTypes?.ShortDurationSystem}
                                            label={"Short duration system"}
                                            onChange={(e)=> changeValueHandler(e, "ShortDurationSystem", "GrazingManagementSystemTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.GrazingManagementSystemTypes?.Other}
                                            label={"Other type of grazing system"}
                                            onChange={(e)=> changeValueHandler(e, "Other", "GrazingManagementSystemTypes")}
                                        />
                                    </div>
                                    {formDataFields?.GrazingManagementSystemTypes?.Other && 
                                        <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Other type of grazing system (describe)
                                            {!inputValidator("GrazingManagementSystemTypesOther") && (
                                                <i className={"fa fa-asterisk text-danger"}></i>
                                            )}
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"GrazingManagementSystemTypesOther"}
                                            placeholder="Other type of grazing system (describe)"
                                            required={formDataFields?.GrazingManagementSystemTypes?.Other}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.GrazingManagementSystemTypesOther}
                                            onChange={(e)=> changeValueHandler(e, "GrazingManagementSystemTypesOther")}
                                        />
                                        </div>
                                    }
                                </div>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"GrazingManagementAdditionalInformation"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.GrazingManagementAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "GrazingManagementAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.PrescribedBurning}
                                    label={"Prescribed Burning"}
                                    onChange={(e)=> changeValueHandler(e, "PrescribedBurning")}
                                />
                            </div>
                            { formDataFields?.PrescribedBurning && 
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Acres to be burned
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"PrescribedBurningAcresToBeBurned"}
                                            placeholder="Acres to be burned"
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.PrescribedBurningAcresToBeBurned}
                                            onChange={(e)=> changeValueHandler(e, "PrescribedBurningAcresToBeBurned")}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Planned burn date
                                        </Label>
                                        <CustomDatePicker
                                            fieldName={"PrescribedBurningPlannedBurnDate"}
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            formDataFields={formDataFields}
                                            setFormDataFields={setFormDataFields}
                                            onlyYear={false}
                                            setIsValidField={setPrescribedBurningPlannedBurnDateIsValid}
                                            validField={prescribedBurningPlannedBurnDateIsValid}
                                            defaultValueDate={formDataFields?.PrescribedBurningPlannedBurnDate}
                                        />
                                    </div>
                                </div>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"PrescribedBurningAdditionalInformation"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.PrescribedBurningAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "PrescribedBurningAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.RangeEnhancement}
                                    label={"Range Enhancement (Range Reseeding)"}
                                    onChange={(e)=> changeValueHandler(e, "RangeEnhancement")}
                                />
                            </div>
                            { formDataFields?.RangeEnhancement && 
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                        <div className={classes.fieldsGroupW50}>
                                            <Label className={classes.Label}>
                                                Acres to be seeded
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                name={"RangeEnhancementAcresToBeSeeded"}
                                                placeholder="Acres to be seeded"
                                                required={false}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.RangeEnhancementAcresToBeSeeded}
                                                onChange={(e)=> changeValueHandler(e, "RangeEnhancementAcresToBeSeeded")}
                                            />
                                        </div>
                                        <div className={classes.fieldsGroupW50}>
                                            <Label className={classes.Label}>
                                                Date to be seeded
                                            </Label>
                                            <CustomDatePicker
                                                fieldName={"RangeEnhancementDateToBeSeeded"}
                                                required={false}
                                                disabled={formSettings?.isReadOnly}
                                                formDataFields={formDataFields}
                                                setFormDataFields={setFormDataFields}
                                                onlyYear={false}
                                                setIsValidField={setRangeEnhancementDateToBeSeededIsValid}
                                                validField={rangeEnhancementDateToBeSeededIsValid}
                                                defaultValueDate={formDataFields?.RangeEnhancementDateToBeSeeded}
                                            />
                                        </div>
                                    </div>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                        <Label className={classes.Label}>
                                            Seeding Method
                                        </Label>
                                        <div className={classes.fieldsGroupW100}>
                                            <div className={`${classes.inputsGroup} ${classes.fieldsGroupW50}`}>
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.RangeEnhancementSeedingMethods?.Broadcast}
                                                    label={"Broadcast"}
                                                    onChange={(e)=>
                                                        changeValueHandler(e, "Broadcast", "RangeEnhancementSeedingMethods")}
                                                />
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.RangeEnhancementSeedingMethods?.Drilled}
                                                    label={"Drilled"}
                                                    onChange={(e)=> changeValueHandler(e, "Drilled", "RangeEnhancementSeedingMethods")}
                                                />
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.RangeEnhancementSeedingMethods?.NativeHay}
                                                    label={"Native Hay"}
                                                    onChange={(e)=> changeValueHandler(e, "NativeHay", "RangeEnhancementSeedingMethods")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                        <Label className={classes.Label}>
                                            Seeding mixture to be used
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"RangeEnhancementSeedingMixtureToBeUsed"}
                                            placeholder="Seeding mixture to be used"
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.RangeEnhancementSeedingMixtureToBeUsed}
                                            onChange={(e)=> changeValueHandler(e, "RangeEnhancementSeedingMixtureToBeUsed")}
                                        />
                                    </div>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                        <Label className={`${classes.Label}`}>
                                            Fertilized
                                        </Label>
                                        <div className={classes.checkboxGroup}>
                                            <CheckboxGroup
                                                isAdminPreview={isAdminPreview}
                                                setFormDataFields={setFormDataFields}
                                                formDataFields={formDataFields}
                                                formSettings={formSettings}
                                                fieldName={"RangeEnhancementFertilized"}
                                                fieldSecondName={"RangeEnhancementFertilizedSecond"}
                                                isRequired={false}
                                                checkboxesIsValid={rangeEnhancementFertilizedInputValid}
                                                setCheckboxesIsValid={setRangeEnhancementFertilizedInputValid}
                                            />
                                        </div>
                                    </div>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                        <Label className={`${classes.Label}`}>
                                            Weed control needed for establishment
                                        </Label>
                                        <div className={classes.checkboxGroup}>
                                            <CheckboxGroup
                                                isAdminPreview={isAdminPreview}
                                                setFormDataFields={setFormDataFields}
                                                formDataFields={formDataFields}
                                                formSettings={formSettings}
                                                fieldName={"RangeEnhancementWeedControlNeeded"}
                                                fieldSecondName={"RangeEnhancementWeedControlNeededSecond"}
                                                isRequired={false}
                                                checkboxesIsValid={rangeEnhancementWeedControlNeededInputValid}
                                                setCheckboxesIsValid={setRangeEnhancementWeedControlNeededInputValid}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"RangeEnhancementAdditionalInformation"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.RangeEnhancementAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "RangeEnhancementAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.BrushManagement}
                                    label={"Brush Management"}
                                    onChange={(e)=> changeValueHandler(e, "BrushManagement")}
                                />
                            </div>
                            { formDataFields?.BrushManagement && 
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Acres to be treated
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"BrushManagementAcresToBeTreated"}
                                            placeholder="Acres to be treated"
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.BrushManagementAcresToBeTreated}
                                            onChange={(e)=> changeValueHandler(e, "BrushManagementAcresToBeTreated")}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Check method of brush management
                                        </Label>
                                        <div className={classes.checkboxGroup}>
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.BrushManagementMethodMechanical}
                                                label={"Mechanical"}
                                                onChange={(e)=> changeValueHandler(e, "BrushManagementMethodMechanical")}
                                            />
                                        </div>
                                        {formDataFields?.BrushManagementMethodMechanical && <>
                                            <div className={`${classes.inputsGroup} ${classes.paddingLeft}`}>
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.BrushManagementMethodMechanicalTypes?.Grubber}
                                                    label={"Grubber"}
                                                    onChange={(e)=>
                                                        changeValueHandler(e, "Grubber", "BrushManagementMethodMechanicalTypes")}
                                                />
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.BrushManagementMethodMechanicalTypes?.Chain}
                                                    label={"Chain"}
                                                    onChange={(e)=>
                                                        changeValueHandler(e, "Chain", "BrushManagementMethodMechanicalTypes")}
                                                />
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.BrushManagementMethodMechanicalTypes?.RollerChopperAerator}
                                                    label={"Roller chopper/aerator"}
                                                    onChange={(e)=>
                                                        changeValueHandler(e, "RollerChopperAerator", "BrushManagementMethodMechanicalTypes")}
                                                />
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.BrushManagementMethodMechanicalTypes?.RhomeDisc}
                                                    label={"Rhome disc"}
                                                    onChange={(e)=>
                                                        changeValueHandler(e, "RhomeDisc", "BrushManagementMethodMechanicalTypes")}
                                                />
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.BrushManagementMethodMechanicalTypes?.BrushHogShredder}
                                                    label={"Brush hog (shredder)"}
                                                    onChange={(e)=>
                                                        changeValueHandler(e, "BrushHogShredder", "BrushManagementMethodMechanicalTypes")}
                                                />
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.BrushManagementMethodMechanicalTypes?.Dozer}
                                                    label={"Dozer"}
                                                    onChange={(e)=>
                                                        changeValueHandler(e, "Dozer", "BrushManagementMethodMechanicalTypes")}
                                                />
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.BrushManagementMethodMechanicalTypes?.HandCuttingChainsaw}
                                                    label={"Hand-cutting (chainsaw)"}
                                                    onChange={(e)=>
                                                        changeValueHandler(e, "HandCuttingChainsaw", "BrushManagementMethodMechanicalTypes")}
                                                />
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.BrushManagementMethodMechanicalTypes?.HydraulicShears}
                                                    label={"Hydraulic shears"}
                                                    onChange={(e)=>
                                                        changeValueHandler(e, "HydraulicShears", "BrushManagementMethodMechanicalTypes")}
                                                />
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.BrushManagementMethodMechanicalTypes?.Other}
                                                    label={"Other"}
                                                    onChange={(e)=>
                                                        changeValueHandler(e, "Other", "BrushManagementMethodMechanicalTypes")}
                                                />
                                            </div>
                                            { formDataFields?.BrushManagementMethodMechanicalTypes?.Other && 
                                                <div className={`${classes.fieldsGroupW100} ${classes.paddingLeft}`}>
                                                <Label className={classes.Label}>
                                                    Other (describe)
                                                    {!inputValidator("BrushManagementMethodMechanicalTypesOther") && (
                                                        <i className={"fa fa-asterisk text-danger"}></i>
                                                    )}
                                                </Label>
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"BrushManagementMethodMechanicalTypesOther"}
                                                    placeholder="Other (describe)"
                                                    required={formDataFields?.BrushManagementMethodMechanicalTypes?.Other}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.BrushManagementMethodMechanicalTypesOther}
                                                    onChange={(e)=> changeValueHandler(e, "BrushManagementMethodMechanicalTypesOther")}
                                                />
                                                </div>
                                            }</>
                                        }
                                        <div className={classes.checkboxGroup}>
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.BrushManagementMethodChemical}
                                                label={"Chemical"}
                                                onChange={(e)=> changeValueHandler(e, "BrushManagementMethodChemical")}
                                            />
                                        </div>                                        
                                        { formDataFields.BrushManagementMethodChemical &&
                                            <div className={`${classes.flexRow} ${classes.paddingLeft}`}>
                                                <div className={classes.fieldsGroupW50}>
                                                    <Label className={classes.Label}>
                                                        Kind
                                                    </Label>
                                                    <Input
                                                        className={classes.kTextbox}
                                                        name={"BrushManagementMethodChemicalKind"}
                                                        placeholder="Kind"
                                                        required={false}
                                                        disabled={formSettings?.isReadOnly}
                                                        value={formDataFields?.BrushManagementMethodChemicalKind}
                                                        onChange={(e)=> changeValueHandler(e, "BrushManagementMethodChemicalKind")}
                                                    />
                                                </div>
                                                <div className={classes.fieldsGroupW50}>
                                                    <Label className={classes.Label}>
                                                        Rate
                                                    </Label>
                                                    <Input
                                                        className={classes.kTextbox}
                                                        name={"BrushManagementMethodChemicalRate"}
                                                        placeholder="Rate"
                                                        required={false}
                                                        disabled={formSettings?.isReadOnly}
                                                        value={formDataFields?.BrushManagementMethodChemicalRate}
                                                        onChange={(e)=> changeValueHandler(e, "BrushManagementMethodChemicalRate")}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.BrushManagementDesign}
                                    label={"Brush Management Design"}
                                    onChange={(e)=> changeValueHandler(e, "BrushManagementDesign")}
                                />
                            </div>
                            { formDataFields?.BrushManagementDesign && 
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={`${classes.inputsGroup} ${classes.fieldsGroupW50}`}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.BrushManagementDesignTypes?.Block}
                                            label={"Block"}
                                            onChange={(e)=>
                                                changeValueHandler(e, "Block", "BrushManagementDesignTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.BrushManagementDesignTypes?.Mosaic}
                                            label={"Mosaic"}
                                            onChange={(e)=>
                                                changeValueHandler(e, "Mosaic", "BrushManagementDesignTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.BrushManagementDesignTypes?.Strips}
                                            label={"Strips"}
                                            onChange={(e)=>
                                                changeValueHandler(e, "Strips", "BrushManagementDesignTypes")}
                                        />
                                    </div>
                                    { formDataFields?.BrushManagementDesignTypes?.Strips && <>
                                        <div className={classes.fieldsGroupW20}>
                                            <Label className={classes.Label}>
                                                Width
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                name={"BrushManagementDesignStripsWidth"}
                                                placeholder="Width"
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.BrushManagementDesignStripsWidth}
                                                onChange={(e)=> changeValueHandler(e, "BrushManagementDesignStripsWidth")}
                                            />
                                        </div>
                                        <div className={classes.fieldsGroupW25}>
                                            <Label className={classes.Label}>
                                                Length
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                name={"BrushManagementDesignStripsLength"}
                                                placeholder="Length"
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.BrushManagementDesignStripsLength}
                                                onChange={(e)=> changeValueHandler(e, "BrushManagementDesignStripsLength")}
                                            />
                                        </div></>
                                    }
                                </div>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"BrushManagementAdditionalInformation"}
                                    required={false}
                                    placeholder={"Additional Information"}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.BrushManagementAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "BrushManagementAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.FenceModification}
                                    label={"Fence Modification"}
                                    onChange={(e)=> changeValueHandler(e, "FenceModification")}
                                />
                            </div>
                            { formDataFields?.FenceModification && <>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Target Species:
                                    </Label>
                                    <div className={classes.fieldsGroupW100}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.FenceModificationSpecies?.PronghornAntelope}
                                            label={"Pronghorn Antelope"}
                                            onChange={(e)=>
                                                changeValueHandler(e, "PronghornAntelope", "FenceModificationSpecies")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.FenceModificationSpecies?.BighornSheep}
                                            label={"Bighorn Sheep"}
                                            onChange={(e)=> changeValueHandler(e, "BighornSheep", "FenceModificationSpecies")}
                                        />
                                    </div>
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Technique
                                        </Label>
                                    </div>
                                    <div className={classes.fieldsGroupW50}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.FenceModificationBottomOfNetWire}
                                            label={"Fold up bottom of net-wire"}
                                            onChange={(e)=> changeValueHandler(e, "FenceModificationBottomOfNetWire")}
                                        />
                                        { formDataFields?.FenceModificationBottomOfNetWire && 
                                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                                <Label className={classes.Label}>
                                                    Gap width
                                                </Label>
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"FenceModificationBottomOfNetWireGapWidth"}
                                                    placeholder="Gap width"
                                                    required={false}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.FenceModificationBottomOfNetWireGapWidth}
                                                    onChange={(e)=> changeValueHandler(e, "FenceModificationBottomOfNetWireGapWidth")}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className={classes.fieldsGroupW50}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.FenceModificationReplaceSectionsOfNetWire}
                                            label={"Replace sections of net-wire with barbed wire"}
                                            onChange={(e)=> changeValueHandler(e, "FenceModificationReplaceSectionsOfNetWire")}
                                        />
                                        { formDataFields?.FenceModificationReplaceSectionsOfNetWire && 
                                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                                <Label className={classes.Label}>
                                                    Gap width
                                                </Label>
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"FenceModificationReplaceSectionsOfNetWireGapWidth"}
                                                    placeholder="Gap width"
                                                    required={false}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.FenceModificationReplaceSectionsOfNetWireGapWidth}
                                                    onChange={(e)=> changeValueHandler(e, "FenceModificationReplaceSectionsOfNetWireGapWidth")}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Miles of fencing that will be modified
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"FenceModificationMilesOfFenceThatWillBeModified"}
                                        placeholder="Miles of fencing that will be modified"
                                        required={false}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.FenceModificationMilesOfFenceThatWillBeModified}
                                        onChange={(e)=> changeValueHandler(e, "FenceModificationMilesOfFenceThatWillBeModified")}
                                    />
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.FenceModificationReplaceEntireFence}
                                        label={"Replace entire net-wire fence with barbed wire"}
                                        onChange={(e)=> changeValueHandler(e, "FenceModificationReplaceEntireFence")}
                                    />
                                    { formDataFields?.FenceModificationReplaceEntireFence &&                                        
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <div className={classes.fieldsGroupW100}>
                                                <Label className={classes.Label}>
                                                    Miles replaced
                                                </Label>
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"FenceModificationMilesReplaced"}
                                                    placeholder="Miles replaced"
                                                    required={false}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.FenceModificationMilesReplaced}
                                                    onChange={(e)=> changeValueHandler(e, "FenceModificationMilesReplaced")}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div></>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"FenceModificationAdditionalInformation"}
                                    required={false}
                                    placeholder={"Additional Information"}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.FenceModificationAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "FenceModificationAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.RiparianManagement}
                                    label={"Riparian management and enhancement"}
                                    onChange={(e)=> changeValueHandler(e, "RiparianManagement")}
                                />
                            </div>
                            { formDataFields?.RiparianManagement && <>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={`${classes.checkboxGroup} ${classes.fieldsGroupW100}`}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.RiparianManagementFencingOfRiparianArea}
                                            label={"Fencing of riparian area"}
                                            onChange={(e)=> changeValueHandler(e, "RiparianManagementFencingOfRiparianArea")}
                                        />
                                    </div>
                                    { formDataFields.RiparianManagementFencingOfRiparianArea && 
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <div className={`${classes.inputsGroup} ${classes.fieldsGroupW100}`}>
                                                <RadioGroup
                                                    disabled={formSettings?.isReadOnly}
                                                    layout={"horizontal"}
                                                    data={fencingOfRiparianAreaRadioButtonsData}
                                                    onChange={e => changeValueHandler(e, "RiparianManagementFencingRiparianAreaType")}
                                                    value={formDataFields?.RiparianManagementFencingRiparianAreaType}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.checkboxGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.RiparianManagementDefermentFromLivestockGrazing}
                                            label={"Deferment from livestock grazing"}
                                            onChange={(e)=> changeValueHandler(e, "RiparianManagementDefermentFromLivestockGrazing")}
                                        />
                                    </div>
                                    { formDataFields.RiparianManagementDefermentFromLivestockGrazing &&
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <div className={`${classes.inputsGroup} ${classes.fieldsGroupW50}`}>
                                                <RadioGroup
                                                    disabled={formSettings?.isReadOnly}
                                                    layout={"horizontal"}
                                                    data={defermentFromLivestockGrazingRadioButtonsData}
                                                    onChange={e => changeValueHandler(e, "RiparianManagementDefermentType")}
                                                    value={formDataFields?.RiparianManagementDefermentType}
                                                />
                                            </div>
                                            <div className={`${classes.fieldsGroupLeft}`}>
                                                <Label className={classes.Label}>
                                                    Season deferred
                                                </Label>
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"RiparianManagementSeasonDeferred"}
                                                    placeholder="Season deferred"
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.RiparianManagementSeasonDeferred}
                                                    onChange={(e)=> changeValueHandler(e, "RiparianManagementSeasonDeferred")}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.checkboxGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.RiparianManagementEstablishVegetation}
                                            label={"Establish Vegetation"}
                                            onChange={(e)=> changeValueHandler(e, "RiparianManagementEstablishVegetation")}
                                        />
                                    </div>
                                    { formDataFields?.RiparianManagementEstablishVegetation && <>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <div className={classes.checkboxGroup}>
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.RiparianManagementEstablishVegetationTrees}
                                                    label={"Trees"}
                                                    onChange={(e)=> changeValueHandler(e, "RiparianManagementEstablishVegetationTrees")}
                                                />
                                            </div>
                                            { formDataFields?.RiparianManagementEstablishVegetationTrees && 
                                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                                    <Label className={classes.Label}>
                                                        Trees (list species)
                                                    </Label>
                                                    <Input
                                                        className={classes.kTextbox}
                                                        name={"RiparianManagementTreesListSpecies"}
                                                        placeholder="Trees (list species)"
                                                        required={false}
                                                        disabled={formSettings?.isReadOnly}
                                                        value={formDataFields?.RiparianManagementTreesListSpecies}
                                                        onChange={(e)=> changeValueHandler(e, "RiparianManagementTreesListSpecies")}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <div className={classes.checkboxGroup}>
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.RiparianManagementEstablishVegetationShrubs}
                                                    label={"Shrubs"}
                                                    onChange={(e)=> changeValueHandler(e, "RiparianManagementEstablishVegetationShrubs")}
                                                />
                                            </div>
                                            { formDataFields?.RiparianManagementEstablishVegetationShrubs && 
                                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                                    <Label className={classes.Label}>
                                                        Shrubs (list species)
                                                    </Label>
                                                    <Input
                                                        className={classes.kTextbox}
                                                        name={"RiparianManagementShrubsListSpecies"}
                                                        placeholder="Shrubs (list species)"
                                                        required={false}
                                                        disabled={formSettings?.isReadOnly}
                                                        value={formDataFields?.RiparianManagementShrubsListSpecies}
                                                        onChange={(e)=> changeValueHandler(e, "RiparianManagementShrubsListSpecies")}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <div className={classes.checkboxGroup}>
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.RiparianManagementEstablishVegetationHerbaceousSpecies}
                                                    label={"Herbaceous species"}
                                                    onChange={(e)=> changeValueHandler(e, "RiparianManagementEstablishVegetationHerbaceousSpecies")}
                                                />
                                            </div>
                                            { formDataFields?.RiparianManagementEstablishVegetationHerbaceousSpecies && 
                                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                                    <Label className={classes.Label}>
                                                        Herbaceous species (list species)
                                                    </Label>
                                                    <Input
                                                        className={classes.kTextbox}
                                                        name={"RiparianManagementHerbaceousListSpecies"}
                                                        placeholder="Herbaceous species (list species)"
                                                        required={false}
                                                        disabled={formSettings?.isReadOnly}
                                                        value={formDataFields?.RiparianManagementHerbaceousListSpecies}
                                                        onChange={(e)=> changeValueHandler(e, "RiparianManagementHerbaceousListSpecies")}
                                                    />
                                                </div>
                                            }
                                        </div></>
                                    }
                                </div></>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"RiparianManagementAdditionalInformation"}
                                    required={false}
                                    placeholder={"Additional Information"}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.RiparianManagementAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "RiparianManagementAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.WetlandEnhancement}
                                    label={"Wetland Enhancement"}
                                    onChange={(e)=> changeValueHandler(e, "WetlandEnhancement")}
                                />
                            </div>
                            { formDataFields?.WetlandEnhancement && 
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Wetland Enhancement
                                    </Label>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                        <div className={classes.fieldsGroupW100}>
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.WetlandEnhancementTypes?.ProvideSeasonalWater}
                                                label={"Provide seasonal water"}
                                                onChange={(e)=>
                                                    changeValueHandler(e, "ProvideSeasonalWater", "WetlandEnhancementTypes")}
                                            />
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.WetlandEnhancementTypes?.ProvidePermanentWater}
                                                label={"Provide permanent water"}
                                                onChange={(e)=> changeValueHandler(e, "ProvidePermanentWater", "WetlandEnhancementTypes")}
                                            />
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.WetlandEnhancementTypes?.MoistSoilManagement}
                                                label={"Moist soil management"}
                                                onChange={(e)=> changeValueHandler(e, "MoistSoilManagement", "WetlandEnhancementTypes")}
                                            />
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.WetlandEnhancementTypes?.Other}
                                                label={"Other"}
                                                onChange={(e)=> changeValueHandler(e, "Other", "WetlandEnhancementTypes")}
                                            />
                                        </div>
                                        {formDataFields?.WetlandEnhancementTypes?.Other && 
                                            <div className={classes.fieldsGroupW100}>
                                            <Label className={classes.Label}>
                                                Other (describe)
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                name={"WetlandEnhancementTypesOther"}
                                                placeholder="Other (describe)"
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.WetlandEnhancementTypesOther}
                                                onChange={(e)=> changeValueHandler(e, "WetlandEnhancementTypesOther")}
                                            />
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"WetlandEnhancementAdditionalInformation"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.WetlandEnhancementAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "WetlandEnhancementAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.HabitatProtection}
                                    label={"Habitat Protection for species of concern"}
                                    onChange={(e)=> changeValueHandler(e, "HabitatProtection")}
                                />
                            </div>
                            { formDataFields?.HabitatProtection && 
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HabitatProtectionTypes?.Fencing}
                                            label={"Fencing"}
                                            onChange={(e)=>
                                                changeValueHandler(e, "Fencing", "HabitatProtectionTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HabitatProtectionTypes?.Firebreaks}
                                            label={"Firebreaks"}
                                            onChange={(e)=> changeValueHandler(e, "Firebreaks", "HabitatProtectionTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HabitatProtectionTypes?.PrescribedBurning}
                                            label={"Prescribed Burning"}
                                            onChange={(e)=> changeValueHandler(e, "PrescribedBurning", "HabitatProtectionTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HabitatProtectionTypes?.ControlOfNestParasites}
                                            label={"Control of Nest Parasites"}
                                            onChange={(e)=> changeValueHandler(e, "ControlOfNestParasites", "HabitatProtectionTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HabitatProtectionTypes?.HabitatManipulation}
                                            label={"Habitat Manipulation (thinning, etc.)"}
                                            onChange={(e)=> changeValueHandler(e, "HabitatManipulation", "HabitatProtectionTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HabitatProtectionTypes?.NativeExoticUngulateControl}
                                            label={"Native/Exotic Ungulate Control"}
                                            onChange={(e)=> changeValueHandler(e, "NativeExoticUngulateControl", "HabitatProtectionTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HabitatProtectionTypes?.Other}
                                            label={"Other"}
                                            onChange={(e)=> changeValueHandler(e, "Other", "HabitatProtectionTypes")}
                                        />
                                    </div>
                                    {formDataFields?.HabitatProtectionTypes?.Other && 
                                        <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Other (describe)
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"HabitatProtectionTypesOther"}
                                            placeholder="Other (describe)"
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.HabitatProtectionTypesOther}
                                            onChange={(e)=> changeValueHandler(e, "HabitatProtectionTypesOther")}
                                        />
                                        </div>
                                    }
                                </div>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"HabitatProtectionAdditionalInformation"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.HabitatProtectionAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "HabitatProtectionAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.PrescribedControlOfSpecies}
                                    label={"Prescribed Control of Native, Exotic and Feral Species"}
                                    onChange={(e)=> changeValueHandler(e, "PrescribedControlOfSpecies")}
                                />
                            </div>
                            { formDataFields?.PrescribedControlOfSpecies && 
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.PrescribedControlOfSpeciesTypes?.Vegetation}
                                            label={"Prescribed control of vegetation"}
                                            onChange={(e)=>
                                                changeValueHandler(e, "Vegetation", "PrescribedControlOfSpeciesTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.PrescribedControlOfSpeciesTypes?.Animal}
                                            label={"Prescribed control of animal species"}
                                            onChange={(e)=> changeValueHandler(e, "Animal", "PrescribedControlOfSpeciesTypes")}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Species being controlled
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"PrescribedControlOfSpeciesBeingControlled"}
                                            placeholder="Species being controlled"
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.PrescribedControlOfSpeciesBeingControlled}
                                            onChange={(e)=> changeValueHandler(e, "PrescribedControlOfSpeciesBeingControlled")}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Method of control
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"PrescribedControlOfSpeciesMethod"}
                                            placeholder="Method of control"
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.PrescribedControlOfSpeciesMethod}
                                            onChange={(e)=> changeValueHandler(e, "PrescribedControlOfSpeciesMethod")}
                                        />
                                    </div>
                                </div>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"PrescribedControlOfSpeciesAdditionalInformation"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.PrescribedControlOfSpeciesAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "PrescribedControlOfSpeciesAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.WildlifeRestoration}
                                    label={"Wildlife Restoration"}
                                    onChange={(e)=> changeValueHandler(e, "WildlifeRestoration")}
                                />
                            </div>
                            { formDataFields?.WildlifeRestoration && 
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.WildlifeRestorationTypes?.Habitat}
                                            label={"Habitat Restoration"}
                                            onChange={(e)=>
                                                changeValueHandler(e, "Habitat", "WildlifeRestorationTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.WildlifeRestorationTypes?.Wildlife}
                                            label={"Wildlife Restoration"}
                                            onChange={(e)=> changeValueHandler(e, "Wildlife", "WildlifeRestorationTypes")}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Target Species
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"WildlifeRestorationTargetSpecies"}
                                            placeholder="Target Species"
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.WildlifeRestorationTargetSpecies}
                                            onChange={(e)=> changeValueHandler(e, "WildlifeRestorationTargetSpecies")}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Method of restoration
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"WildlifeRestorationMethod"}
                                            placeholder="Method of restoration"
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.WildlifeRestorationMethod}
                                            onChange={(e)=> changeValueHandler(e, "WildlifeRestorationMethod")}
                                        />
                                    </div>
                                </div>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"WildlifeRestorationAdditionalInformation"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.WildlifeRestorationAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "WildlifeRestorationAdditionalInformation")}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    )
}