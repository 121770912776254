import { Popup } from "@progress/kendo-react-popup";
import { useState, useRef } from "react";
import { FormTimeline } from "src/components/FormTimeline/FormTimeline";
import { useSelectedFormContext } from "src/context/SelectedForm/selected-form-context";
import styles from "./TimelinePopupButton.module.scss";
import { ITimeline } from "src/interfaces/timeline.interface";

export interface TimelinePopupButtonProps {
  className?: string;
  timelines: ITimeline[];
}

export const TimelinePopupButton = (props: TimelinePopupButtonProps) => {
  const timelineButtonRef = useRef<HTMLButtonElement>(null);
  const [showTimeline, setShowTimeline] = useState(false);

  return (
    <span className={props.className}>
      <button
        className="button"
        ref={timelineButtonRef}
        onClick={() => setShowTimeline(!showTimeline)}
      >
        <i className="fa fa-history fa-fw"></i>
        Timeline
      </button>
      <Popup
        anchor={timelineButtonRef.current}
        show={showTimeline}
        popupClass={"popup-content"}
        anchorAlign={{
          horizontal: "right",
          vertical: "bottom",
        }}
        popupAlign={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <div
          className={["popup-content-wrapper", styles.TimelinePopup].join(" ")}
        >
          <FormTimeline timelines={props.timelines} />
        </div>
      </Popup>
    </span>
  );
};
