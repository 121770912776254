export type IHomesteadExemptionSixthSectionInterface = {
    PrintedName?: string,
    Title?: string,
    Signature?: string,
    SignatureDate?: string,
}

export const HomesteadExemptionFormSixthSectionInitialState = (): IHomesteadExemptionSixthSectionInterface => ({
    PrintedName: undefined,
    Title: undefined,
    Signature: undefined,
    SignatureDate: undefined,
})