import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import {useEffect, useRef, useState} from "react";
import {
    CheckboxChangeEvent, Input,
    InputChangeEvent, NumericTextBoxChangeEvent, 
    TextAreaChangeEvent
} from "@progress/kendo-react-inputs";
import {CalendarChangeEvent, DatePicker, DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./HomesteadExemption.module.scss";
import {Error, Label} from "@progress/kendo-react-labels";
import { IHomesteadExemptionSixthSectionInterface } from "../../../../../interfaces/forms/HomesteadExemptionForm/homestead-exemption-sixth-section.interface";
import {MMddyyyyDateFormat} from "../../../../../constants/date-formats";
import {FormValidation} from "../InputValidator";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IHomesteadExemptionSixthSectionInterface) => void;
    formDataFields?:  IHomesteadExemptionSixthSectionInterface,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void,
}

export default function HomesteadExemptionSixthSection(props: GeneralFormType): JSX.Element {
    const {setFormDataFields, formDataFields, formSettings, setFormIsValid} = props;

    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator } = FormValidation(formRef);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={6} name={"Signature"}/>;
    
    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | InputChangeEvent
            | CalendarChangeEvent
            | NumericTextBoxChangeEvent,
        field: string,
    ) {
        if (field == "SignatureDate"){
            setFormDataFields({
                ...formDataFields,
                [field]: event.value?.toString()
            });
        } else if (field == "Signature") {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value?.toString(),
                SignatureDate: new Date().toDateString()
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

   function changeValidator() {
        if (formRef.current) {
            setIsValid(formRef.current.checkValidity());
            setFormIsValid(formRef.current.checkValidity());
        }
    }

    function onTouch() {
        if (!isFormTouched) {
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current]);

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={classes.itemFields}>
                                <Label className={`${classes.Label} ${classes.fieldsGroupW100}`}>
                                    I understand if I make a false statement on this form, I could be found guilty of a Class A misdemeanor or a state jail felony under Penal Code Section 37.10.
                                </Label>

                                <div className={classes.fieldsGroup}>
                                    <Label className={`${classes.Label}`}>
                                        I,
                                    </Label>
                                </div>
                                <div className={classes.fieldsGroupW49}>
                                    <Label className={`${classes.Label}`}>
                                        Property Owner/Authorized Representative Name
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    </Label>
                                    <Input
                                        required={true}
                                        name={"PrintedName"}
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.kTextbox}
                                        value={formDataFields?.PrintedName}
                                        onChange={e => changeValueHandler(e, "PrintedName")}
                                    />
                                    {!inputValidator("PrintedName") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={classes.fieldsGroupW30}>
                                    <Label className={`${classes.Label}`}>
                                        Title/Authorization
                                    </Label>
                                    <Input
                                        name={"Title"}
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.kTextbox}
                                        value={formDataFields?.Title}
                                        onChange={e => changeValueHandler(e, "Title")}
                                    />
                                    {!inputValidator("Title") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={classes.fieldsGroup}>
                                    <Label className={`${classes.Label}`}>
                                        swear or affirm the following:
                                    </Label>
                                </div>
                                <div className={classes.fieldsGroupW100 + " " + classes.information}>
                                    <ul className="ng-scope">
                                        <li>that each fact contained in this application is true and correct;</li>
                                        <li>that I/the property owner meet(s) the qualifications under Texas law for the
                                            residence homestead exemption for which I am applying; and
                                        </li>
                                        <li>that I/the property owner do(es) not claim an exemption on another residence
                                            homestead or claim a residence homestead exemption on a residence
                                            homestead outside Texas
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroupW49}>
                                    <Label className={`${classes.Label}`}>
                                        Printed Name
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    </Label>
                                    <Input
                                        required={true}
                                        name={"Signature"}
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.kTextbox}
                                        value={formDataFields?.Signature}
                                        onChange={e => changeValueHandler(e, "Signature")}
                                    />
                                    {!inputValidator("Signature") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={classes.fieldsGroupW30}>
                                    <Label className={`${classes.Label}`}>
                                        Date Signed
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    </Label>
                                    <DatePicker
                                        format={MMddyyyyDateFormat}
                                        name={"SignatureDate"}
                                        required={true}
                                        disabled={true}
                                        className={classes.kTextbox}
                                        value={formDataFields?.SignatureDate ? new Date(formDataFields?.SignatureDate) : undefined}
                                        onChange={e => changeValueHandler(e, "SignatureDate")}
                                    />
                                    {!inputValidator("SignatureDate") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                            </div>
                            <Label className={`${classes.Label} ${classes.fieldsGroupW100} ${classes.information}`}>* May be used by appraisal
                                district to determine eligibility for persons age 65 or older exemption or surviving
                                spouse exemptions (Tax Code §11.43(m))
                                ** Social security number disclosure may be required for tax administration and
                                identification. (42 U.S.C. §405(c)(2)(C)(i); Tax Code §11.43(f)). A driver’s license
                                number, personal identification
                                number or social security number disclosed in an exemption application is confidential
                                and not open to public inspection, except as authorized by Tax Code §11.48(b).
                                *** May be confidential under Government Code §552.137; however, by including the email
                                address on this form, you are affirmatively consenting to its release under the Public
                                Information Act
                            </Label>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
