import { AxiosError, AxiosResponse } from "axios";
import { getUserPermission } from "src/api/permissions-api";
import { AdminActionsEnum } from "src/context/admin/admin-actions";
import { AdminType } from "src/context/admin/admin-reducer";
import { GenericActionType } from "src/context/generic-actions.type";
import { temporaryError } from "src/context/shared/shared-context.helper";
import { PermissionEnum } from "src/enums/permission.enum";
import { IUserPermission } from "src/interfaces/admin/permission.interface";
import AuthService from "./authentication.service";

class PermissionsData {
  isSuperAdmin = false;
  userPermissions: IUserPermission[] = [];
  formId: number | null = null;

  public getIsSuperAdmin(): boolean {
    return this.isSuperAdmin;
  }

  public getUserPermissions(): IUserPermission[] {
    return this.userPermissions;
  }

  public setUserData(
    permissions: IUserPermission[] = [],
    isSuperAdmin = false
  ): void {
    this.userPermissions = permissions;
    this.isSuperAdmin = isSuperAdmin;
  }

  public getFormId(): number | null {
    return this.formId;
  }

  public setFormId(value: number | null): void {
    this.formId = value;
  }
}

export const permissionData = new PermissionsData();

export function setFormId(id: number | null): void {
  permissionData.setFormId(id);
}

export function hasPermission(permissionNames: string[]): boolean {
  const formId: number | null = permissionData.getFormId();
  const userPermissions: IUserPermission[] = [
    ...permissionData.getUserPermissions(),
  ];
  const isSuperAdmin = permissionData.getIsSuperAdmin();

  let hasPermission = false;

  if (isSuperAdmin) {
    return true;
  }

  const sitePermissions = userPermissions.filter((p) => p.formId === null);
  permissionNames.forEach((requiredPermission) => {
    if (hasPermission) {
      return true;
    }

    if (formId) {
      if (
        sitePermissions?.find((p) => p.name == PermissionEnum.ManageAllForms)
      ) {
        return (hasPermission = true);
      }

      const userFormPermission = userPermissions.filter(
        (p) => p.formId == formId
      );

      if (userFormPermission) {
        hasPermission = userFormPermission.some((permission) => {
          return (
            permission.name.toLowerCase() ===
            requiredPermission.toLowerCase().trim()
          );
        });
      }
    }

    hasPermission =
      hasPermission ||
      (sitePermissions
        ? sitePermissions.some((permission) => {
            return (
              permission.name.toLowerCase() ===
              requiredPermission.toLowerCase().trim()
            );
          })
        : false);
  });

  return hasPermission;
}

export async function setUserPermissions(
  dispatch: (value: GenericActionType<AdminType>) => void
) {
  const authService = AuthService.getInstance();
  const user = await authService.user();

  if (user) {
    getUserPermission()
      .then((response: AxiosResponse<IUserPermission[]>) => {
        permissionData.setUserData(response.data, user?.isSuperAdmin);
        dispatch({
          type: AdminActionsEnum.IS_PERMISSIONS_LOADING,
          payload: { isPermissionLoading: false },
        });
      })
      .catch((error: AxiosError) => {
        dispatch({
          type: AdminActionsEnum.IS_PERMISSIONS_LOADING,
          payload: { isPermissionLoading: false },
        });
        temporaryError(dispatch, error.response?.data);
      });
  }
}
