import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "./DisabledVetForm.module.scss";
import { useRef, useState } from "react";
import { SectionInformationTitle } from "../Shared/SectionTitle";

export default function DisabledVetFormFirstSection(): JSX.Element {
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const title = <SectionInformationTitle number={1} name={"Form Information"}/>;

    function onTouch(){
        if (!isFormTouched){
            setIsFormTouched(true);
        }
    }

    return (
       <>
            <PanelBar keepItemsMounted={true}>
                <PanelBarItem title={title} expanded={true}>
                    <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                        <div className="container">
                            <div className={classes.item}>
                                <div className={`${classes.itemFields}`}>
                                    <div className={classes.fieldsGroupW50}>
                                        <p>
                                            <b>
                                                GENERAL INFORMATION 
                                            </b>
                                            <br/>
                                            This application is for use in claiming a property tax exemption pursuant to Tax Code
                                            Section 11.22 for property owned by a disabled veteran, the surviving spouse or
                                            surviving child, with a service-connected disability, or a U.S. armed service member
                                            who died on active duty
                                        </p>
                                        <p>
                                            Property owners applying for a disabled veteran&apos;s or survivor&apos;s exemption 
                                            file this form and supporting documentation with the appraisal district in each 
                                            county in which the property is located (Tax Code Sections 11.22 and 11.43 ).&nbsp;
                                            <b>Do not file this document with the Texas Comptroller of Public Accounts.</b>
                                        </p>
                                        <p>
                                        <b>
                                            FILING INSTRUCTIONS 
                                        </b>
                                        <br/>
                                        Property owners applying for a disabled veteran&apos;s or survivor&apos;s exemption may file this
                                        form and supporting documentation with the appraisal district in the county in which
                                        the property is located. <b>Do <u>not</u> file this document with the Texas Comptroller
                                        of Public Accounts.</b> Contact information for appraisal district offices may be found
                                        on the Comptroller&apos;s website.
                                        </p>
                                        <p>
                                        <b>
                                            APPLICATION DEADLINES (Tax Code Section 11.43(a-d)):
                                        </b>
                                        <br/>
                                        You must submit the completed application and supporting documents to the
                                        appraisal district between Jan. 1 and April 30 of the tax year for which you request the
                                        exemption. For good cause shown, the chief appraiser may extend the deadline for
                                        filing the application by written order for a single period not to exceed 60 days. The
                                        chief appraiser may disapprove the application and request additional information. If
                                        the chief appraiser requests additional information, the information must be furnished
                                        within 30 days after the date of the request, or the application is denied. For good cause
                                        shown, the chief appraiser may extend the deadline for furnishing the information by
                                        written order for a single period not to exceed 15 days. If the chief appraiser denies the
                                        application, you may protest that determination to the county appraisal review board
                                        under Tax Code chapter 41.
                                        </p>
                                        <p>
                                        <b>
                                            LATE APPLICATIONS (Tax Code Section 11.439):
                                        </b>
                                        <br/>
                                        Applicants may file a late application no later than five years after the delinquency date
                                        for the taxes on the designated property. 
                                        </p>
                                        <p>
                                        <b>
                                            REQUIRED DOCUMENTATION 
                                        </b>
                                        </p>
                                        <br/>
                                        Applicants must provide the following documentation supporting this exemption request:
                                        <ul>
                                            <li>
                                            attach a copy of property owner&apos;s driver&apos;s license or state-issued personal
                                            identification certificate 
                                            </li>
                                            <li>
                                            documentation indicating the percentage of disability certified by the Veterans
                                            Administration or its successor or the branch of the armed services in which the
                                            veteran served 
                                            </li>
                                        </ul>
                                        <p>
                                        <b>
                                            ADDITIONAL INFORMATION REQUEST
                                        </b>
                                        <br/>
                                        Once this exemption is allowed, an application does not have to be filed annually
                                        unless the ownership of the property changes or qualifications for the exemption
                                        change. The chief appraiser may require a new application to be filed to confirm
                                        current eligibility by sending written notice and an application form. 
                                        </p>
                                    </div>
                                    <div className={classes.fieldsGroupW50}>
                                        <p>
                                        <b>
                                            DUTY TO NOTIFY 
                                        </b>
                                        <br/>
                                        If eligibility ends, the property owner must notify the appraisal district in writing
                                        before May 1 after the eligibility ends. 
                                        </p>
                                        <p>
                                        <b>
                                            EXEMPTION QUALIFICATIONS (Tax Code Section 11.22): 
                                        </b>
                                        <br/>
                                        <b>Disabled Veteran&apos;s Exemption</b> &nbsp;
                                        A qualified individual is entitled to an exemption
                                        from taxation of a portion of the assessed value of one property the applicant owns
                                        and designates. This application applies to property owned on Jan. 1 of this year.
                                        </p>
                                        <p>
                                        To qualify, an individual must be a Texas resident and possess a veteran&apos;s percentage of
                                        disability certified by the Veterans&apos; Administration (VA) or its successor or the branch
                                        of the armed services in which the veteran served. 
                                        </p>
                                        <p>
                                        A qualified applicant can receive this exemption on only one property, which must be
                                        the same for every taxing unit in which the applicant claims the exemption.
                                        </p>
                                        <p>
                                        A disability rating from 10-29 percent can have and exemption up to $5,000 from the
                                        property&apos;s assessed value, 30-49 percent up to $7,500, 50-69 percent up to $10,000 and
                                        70-100 percent up to $12,000.
                                        </p>
                                        <table className="blue">
                                            <tbody>
                                                <tr>
                                                    <th className="width-50">Disability Rating</th>
                                                    <th className="width-50">Exemption Amount Up To</th>
                                                </tr>
                                                <tr>
                                                    <td>10% - 29%</td>
                                                    <td>$5,000 from the property value</td>
                                                </tr>
                                                <tr>
                                                    <td>30% - 49%</td>
                                                    <td>$7,500 from the property value</td>
                                                </tr>
                                                <tr>
                                                    <td>50% - 69%</td>
                                                    <td>$10,000 from the property value</td>
                                                </tr>
                                                <tr>
                                                    <td>70% - 100%</td>
                                                    <td>$12,000 from the property value</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p>
                                        A disabled veteran may also qualify for an exemption of $12,000 of the assessed value
                                        of the property if the veteran is age 65 or older with a disability rating of at least 10
                                        percent; totally blind in one or both eyes; or has lost the use of one or more limbs.
                                        </p>
                                        <p>
                                        <b>Survivor&apos;s Exemption</b> &nbsp;
                                        (Tax Code Section 11.22(c)(d)): The veteran&apos;s surviving
                                        spouse is entitled to an exemption from taxation of a portion of the assessed value
                                        of a property the spouse owns and designates for the exemption. The amount of the
                                        exemption is the amount of the veteran&apos;s exemption at the time of death. The spouse is
                                        entitled to this exemption only for as long as the spouse remains unmarried.
                                        </p>
                                        <p>
                                        If the spouse does not survive the veteran, each of the veteran&apos;s surviving children
                                        who is younger than 18 years of age and unmarried is entitled to an exemption from
                                        taxation of a portion of the assessed value of a property the child owns and designates
                                        for the exemption. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                   </form>
                </PanelBarItem>
            </PanelBar>
       </>
    )
}