import type { AxiosError, AxiosResponse } from "axios";
import { acknowledgeAll, getAlerts, setAlertSeen } from "src/api/alerts-api";
import { getCountOfDialogs } from "src/api/dialog-api";
import { getUserInformation } from "src/api/user-api";
import type { SharedType } from "src/context/shared/shared-reducer";
import type { IUser } from "src/interfaces/admin/user.interface";
import type { IAlert } from "src/interfaces/alert.interface";
import type { IPagination } from "src/interfaces/grid-setup.interface";
import { IUnreadDialogCount } from "src/interfaces/messages/dialogs/unread-dialog-count.interface";
import type { GenericActionType } from "../generic-actions.type";
import { SharedActionsEnum } from "./shared-actions";

export function getUserHelper(
  sharedDispatch: (value: GenericActionType<SharedType>) => void
): void {
  setLoading(sharedDispatch, true);

  getUserInformation()
    .then((response: AxiosResponse<IUser>) => {
      sharedDispatch({
        type: SharedActionsEnum.USER_DATA,
        payload: {
          user: response.data,
        },
      });

      setLoading(sharedDispatch);
    })
    .catch((err: AxiosError) =>
      temporaryError(sharedDispatch, err.response?.data)
    );
}

export function clearUserDataHelper(
  sharedDispatch: (value: GenericActionType<SharedType>) => void
): void {
  sharedDispatch({
    type: SharedActionsEnum.USER_DATA,
    payload: {
      user: undefined,
    },
  });
}

export function getAlertsHelper(
  sharedDispatch: (value: GenericActionType<SharedType>) => void,
  pagination?: IPagination
): void {
  setLoading(sharedDispatch, true);

  getAlerts(pagination)
    .then((response: AxiosResponse<IAlert[]>) => {
      sharedDispatch({
        type: SharedActionsEnum.ALERTS,
        payload: {
          alerts: response.data,
        },
      });

      setLoading(sharedDispatch);
    })
    .catch((error: AxiosError) => {
      temporaryError(sharedDispatch, error.response?.data);
    });
}

export function setAlertSeenHelper(
  sharedDispatch: (value: GenericActionType<SharedType>) => void,
  alertId: number,
  alerts: IAlert[]
): void {
  setLoading(sharedDispatch, true);

  setAlertSeen(alertId)
    .then(() => {
      sharedDispatch({
        type: SharedActionsEnum.ALERTS,
        payload: {
          alerts: alerts.map((alert) => {
            if (alert.id === alertId) {
              alert.seen = true;
              return alert;
            }
            return alert;
          }),
        },
      });

      setLoading(sharedDispatch);
    })
    .catch((error: AxiosError) => {
      temporaryError(sharedDispatch, error.response?.data);
    });
}

export function acknowledgeAllHelper(
  sharedDispatch: (value: GenericActionType<SharedType>) => void,
  alerts: IAlert[]
): void {
  setLoading(sharedDispatch, true);

  acknowledgeAll()
    .then((response: AxiosResponse) => {
      sharedDispatch({
        type: SharedActionsEnum.ALERTS,
        payload: {
          alerts: alerts.map((alert) => {
            if (!alert.seen) {
              alert.seen = true;
              return alert;
            }
            return alert;
          }),
        },
      });

      temporarySuccessMessage(sharedDispatch, response.data);
      setLoading(sharedDispatch);
    })
    .catch((error: AxiosError) => {
      temporaryError(sharedDispatch, error.response?.data);
    });
}

export function getDialogCountsHelper(
  sharedDispatch: (value: GenericActionType<SharedType>) => void
): void {
  getCountOfDialogs()
    .then((response: AxiosResponse<IUnreadDialogCount>) => {
      sharedDispatch({
        type: SharedActionsEnum.DIALOG_COUNTS,
        payload: { dialogCounts: response.data },
      });
    })
    .catch((error: AxiosError) =>
      temporaryError(sharedDispatch, error.response?.data)
    );
}

export function temporaryError<T>(
  dispatch: (value: GenericActionType<T>) => void,
  error?: string | undefined | unknown
): void {
  setLoading(dispatch);
  setError(
    dispatch,
    typeof error === "string"
      ? error
      : "something went wrong, please try again later or contact support"
  );
  setTimeout(function () {
    setError(dispatch);
  }, 3000);
}

function setError<T>(
  sharedDispatch: (value: GenericActionType<T>) => void,
  error?: string
) {
  sharedDispatch({
    type: SharedActionsEnum.ERROR,
    error: error ?? undefined,
  });
}

export function setLoading<T>(
  dispatch: (value: GenericActionType<T>) => void,
  isLoading?: boolean
): void {
  dispatch({
    type: SharedActionsEnum.IS_LOADING,
    isLoading: isLoading ?? false,
  });
}

export function temporarySuccessMessage<T>(
  dispatch: (value: GenericActionType<T>) => void,
  successMessage?: string
): void {
  setLoading(dispatch);
  setSuccess(dispatch, successMessage ?? "The action successfully completed");
  setTimeout(function () {
    setSuccess(dispatch);
  }, 4000);
}

function setSuccess<T>(
  dispatch: (value: GenericActionType<T>) => void,
  successMessage?: string
) {
  dispatch({
    type: SharedActionsEnum.SUCCESS_MESSAGE,
    success: successMessage ?? undefined,
  });
}
