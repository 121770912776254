import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "src/pages/Home/components/Forms/ExemptionPropertyDamagedForm/ExemptionPropertyDamaged.module.scss";
import { SectionInformationTitle } from "../Shared/SectionTitle";

export default function TimberlandAppraisalFormSeventhSection(): JSX.Element {
    const title = <SectionInformationTitle number={7} name={"Important Information"}/>;

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
            <div className={classes.information}>
                    <div>
                        <p>
                            <b>GENERAL INFORMATION:</b> 
                            While land must currently be devoted principally to the production of timber or forest 
                            products to qualify for timberland appraisal, the requirement to show a history of use 
                            for five of the previous seven years can be satisfied by timber production or by 
                            agricultural use. Agricultural use includes, but is not limited to, the following 
                            activities: (1) cultivating the soil; (2) producing crops for human food, animal feed, 
                            or planting seed or for the production of fibers; (3) floriculture, viticulture and 
                            horticulture; (4) raising or keeping livestock; (5) raising or keeping exotic animals 
                            or fowl for the production of human food or fiber, leather, pelts or other tangible 
                            products having a commercial value; (6) planting cover crops or leaving land idle for 
                            the purpose of participating in a governmental program provided the land is not used for 
                            residential purposes or a purpose inconsistent with agricultural use or leaving the land 
                            idle in conjunction with normal crop or livestock rotation procedures; (7) producing or 
                            harvesting logs and posts used for construction or repair of fences, pens, barns or other 
                            agricultural improvements on adjacent open-space land having the same owner and devoted to 
                            a different agricultural use; (8) wildlife management; and (9) beekeeping. Agricultural 
                            land use categories include: (1) irrigated cropland; (2) dry cropland; (3) improved 
                            pastureland; (4) native pastureland; (5) orchard; (6) wasteland; (7) timber production; 
                            (8) wildlife management; and (9) other categories of land that are typical in the area.
                        </p>
                    </div>
                    <div>
                        <p>
                            <b>APPLICATION DEADLINES:</b> The completed application must be filed with the chief 
                            appraiser before May 1 of the year for which timberland appraisal is requested. If the 
                            application is approved, a new application is not required in later years unless the land 
                            ownership changes, eligibility ends or the chief appraiser requests a new application. 
                            A late application may be filed up to midnight the day before the appraisal review board 
                            approves appraisal records for the year, which usually occurs in July. If a late application 
                            is approved, a penalty will be applied in an amount equal to 10 percent of the difference 
                            between the amount of tax imposed on the property and the amount that would be imposed if 
                            the property were taxed at market value.
                        </p>
                    </div>
                    <div>
                        <p>
                            <b>DUTY TO NOTIFY AND PENALTIES:</b> The property owner must notify the chief appraiser no 
                            later than the April 30 following the change in use or eligibility. A change of land use for 
                            all or part of the property will trigger substantial additional tax plus interest (a rollback 
                            tax). Payment of a penalty may also be required for failure to notify the chief appraiser of 
                            a change in timber production or agricultural use or qualification. Notice must be delivered 
                            to the chief appraiser if:
                            <ul>
                                <li>the property stops being used for timber production (e.g., voluntarily decide to stop 
                                    actively managing the land to produce income);
                                </li>
                                <li>
                                    category of land use changes (e.g., from growing timber to grazing cattle); or
                                </li>
                                <li>
                                    the land is used for something other than agriculture (e.g., to build a shopping center 
                                    on most of the land).
                                </li>
                            </ul>
                        </p>
                    </div>
                    <div>
                        <p>
                            <b>OTHER IMPORTANT INFORMATION:</b> If the initial application form does not contain all 
                            essential information, the chief appraiser may request additional information that is necessary 
                            to determine whether the land qualifies for special appraisal. The chief appraiser may disapprove 
                            the application and request additional information. The chief appraiser may deny the application 
                            and that determination may be protested to the county appraisal review board in a timely manner. 
                            If the chief appraiser requests additional information from an applicant, the information must be 
                            furnished within 30 days after the date of the request, or the application is denied. For good 
                            cause shown, the chief appraiser may extend the deadline for furnishing the information by written 
                            order for a single 15-day period.
                        </p>
                    </div>
                </div>
            </PanelBarItem>
        </PanelBar>
    </>
}
