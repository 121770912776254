import { useNavigate } from "react-router-dom";
import { useSelectedFormContext } from "../../../../../context/SelectedForm/selected-form-context";
import classes from "./AgForm.module.scss";
import AgFormFirstSection from "./AgFormFirstSection";
import { useEffect, useState, useMemo } from "react";
import {
  AgFormFirstSectionInitialState,
  IAgFormFirstSectionInterface
} from "../../../../../interfaces/forms/AgForm/ag-form-first-section.interface";
import { IFormFiledInterface } from "../../../../../interfaces/form-filed.interface";
import { IFormSettingModel } from "../../../../../interfaces/form-settings.interface";
import { IFormEntryModel } from "../../../../../interfaces/form-entry.interface";
import {
  getSelectedFormDataHelper,
  getSelectedFormEntryDataHelper, saveFormHelper
} from "../../../../../context/SelectedForm/selected-form-context.helper";
import { SelectedFormActionsEnum } from "../../../../../context/SelectedForm/selected-form-actions";
import {
  AgFormSecondSectionInitialState,
  IAgFormSecondSectionInterface
} from "../../../../../interfaces/forms/AgForm/ag-form-second-section.interface";
import AgFormSecondSection from "./AgFormSecondSection";
import {
  AgFormThirdSectionInitialState,
  IAgFormThirdSectionInterface
} from "../../../../../interfaces/forms/AgForm/ag-form-third-section.interface";
import AgFormThirdSection from "./AgFormThirdSection";
import {
  AgFormFourthSectionInitialState,
  IAgFormFourthSectionInterface
} from "../../../../../interfaces/forms/AgForm/ag-form-fourth-section.interface";
import AgFormFourthSection from "./AgFormFourthSection";
import {
  AgFormSixthSectionInitialState,
  IAgFormSixthSectionInterface
} from "../../../../../interfaces/forms/AgForm/ag-form-sixth-section.interface";
import AgFormSixthSection from "./AgFormSixthSection/AgFormSixthSection";
import {
  AgFormSeventhSectionInitialState,
  IAgFormSeventhSectionInterface
} from "../../../../../interfaces/forms/AgForm/ag-form-seventh-section.interface";
import AgFormSeventhSection from "./AgFormSeventhSection";
import {
  AgFormEighthSectionInitialState,
  IAgFormEighthSectionInterface
} from "../../../../../interfaces/forms/AgForm/ag-form-eighth-section.interface";
import AgFormEighthSection from "./AgFormEighthSection";
import {
  AgFormNinthSectionInitialState,
  IAgFormNinthSectionInterface
} from "../../../../../interfaces/forms/AgForm/ag-form-ninth-section.interface";
import AgFormNinthSection from "./AgFormNinthSection";
import {
  AgFormTenthSectionInitialState,
  IAgFormTenthSectionInterface
} from "../../../../../interfaces/forms/AgForm/ag-form-tenth-section.interface";
import AgFormTenthSection from "./AgFormTenthSection";
import { useFormSectionDataFields } from "../useFormSectionDataFields";
import { Dialog } from "@progress/kendo-react-dialogs";
import AGFormPDFView from "../../../../Shared/AgFormPDFView/AGFormPDFView";
import { useFormContext } from "../../../../../context/Form/form-context";
import { AgFormName } from "../../../../../constants/form-names-constants";
import { onLoadForms } from "../../../../../context/Form/form-helper";
import { FormActionsEnum } from "../../../../../context/Form/form-actions";
import { CustomLoader } from "../../../../../components";
import { useCallbackPrompt } from "../../../../../hooks/useCallbackPrompt";
import UnsavedFormDialog from "../../../../../components/CustomDialogs/UnsavedFormDialog/UnsavedFormDialog";
import { t } from "i18next";
import AuthService from 'src/services/authentication.service';
import { FormHeader } from "../Shared/FormHeader/FormHeader";
import { submitted } from "src/constants/submission-form-status-constants";
import { useIsFormActive } from "../useIsFormActive";

interface FormEntry {
  formEntryId?: number;
  isAdminPreview?: boolean;
  formId?: number;
}

export default function AgForm(props: FormEntry): JSX.Element {
  const { isAuthorized } = AuthService.getInstance();

  const { isAdminPreview, formEntryId, formId } = props;
  const { formState, formDispatch } = useFormContext();
  const { selectedFormDispatch, selectedFormState } = useSelectedFormContext();
  const { loading } = formState;
  const { isLoading } = selectedFormState;
  const [formDataFields, setFormDataFields] = useState<Array<IFormFiledInterface>>();
  const [formEntry, setFormEntry] = useState<IFormEntryModel>();
  const formSettings = useMemo(() => {
    let formSettings = selectedFormState.formSettings ?? undefined;
    if (
        !isAuthorized ||
        isAdminPreview ||
        selectedFormState.formEntry?.submissionStatus == submitted
     ) {
      formSettings = { ...formSettings, isReadOnly: true };
     }
     return formSettings;
  }, [selectedFormState.formSettings, selectedFormState.formEntry, isAuthorized, isAdminPreview]);

  const [formFirstSectionDataFields, setFormFirstSectionDataFields] = useState<IAgFormFirstSectionInterface>();
  const [formSecondSectionDataFields, setFormSecondSectionDataFields] = useState<IAgFormSecondSectionInterface>();
  const [formThirdSectionDataFields, setFormThirdSectionDataFields] = useState<IAgFormThirdSectionInterface>();
  const [formFourthSectionDataFields, setFormFourthSectionDataFields] = useState<IAgFormFourthSectionInterface>();
  const [formSixthSectionDataFields, setFormSixthSectionDataFields] = useState<IAgFormSixthSectionInterface>()
  const [formSeventhSectionDataFields, setFormSeventhSectionDataFields] = useState<IAgFormSeventhSectionInterface>();
  const [formEighthSectionDataFields, setFormEighthSectionDataFields] = useState<IAgFormEighthSectionInterface>();
  const [formNinthSectionDataFields, setFormNinthSectionDataFields] = useState<IAgFormNinthSectionInterface>();
  const [formTenthSectionDataFields, setFormTenthSectionDataFields] = useState<IAgFormTenthSectionInterface>();

  const [firstSectionIsValid, setFirstSectionIsValid] = useState<boolean>(false);
  const [secondSectionIsValid, setSecondSectionIsValid] = useState<boolean>(false);
  const [thirdSectionIsValid, setThirdSectionIsValid] = useState<boolean>(false);
  const [fourthSectionIsValid, setFourthSectionIsValid] = useState<boolean>(false);
  const [sixthSectionIsValid, setSixthSectionIsValid] = useState<boolean>(false);
  const [seventhSectionIsValid, setSeventhSectionIsValid] = useState<boolean>(false);
  const [eighthSectionIsValid, setEighthSectionIsValid] = useState<boolean>(false);
  const [ninthSectionIsValid, setNinthSectionIsValid] = useState<boolean>(false);
  const [tenthSectionIsValid, setTenthSectionIsValid] = useState<boolean>(false);

  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const { isFormActive } = useIsFormActive(
    isAuthorized,
    isAdminPreview,
    formSettings,
    "SupportingDocuments",
    selectedFormState.formFields,
    formNinthSectionDataFields?.SupportingDocuments
  );
  const [showPrompt, confirmNavigation, cancelNavigation, setDisablePrompt] = useCallbackPrompt(isFormActive);

  useEffect(() => {
    setIsFormValid(
      firstSectionIsValid &&
      secondSectionIsValid &&
      thirdSectionIsValid &&
      fourthSectionIsValid &&
      sixthSectionIsValid &&
      seventhSectionIsValid &&
      eighthSectionIsValid &&
      ninthSectionIsValid &&
      tenthSectionIsValid)
  }, [
    firstSectionIsValid,
    secondSectionIsValid,
    thirdSectionIsValid,
    fourthSectionIsValid,
    sixthSectionIsValid,
    seventhSectionIsValid,
    eighthSectionIsValid,
    ninthSectionIsValid,
    tenthSectionIsValid])

  useEffect(() => {
    return () => {
      selectedFormDispatch({
        type: SelectedFormActionsEnum.CLEAR,
        payload: {
          errorMessage: undefined,
          isLoading: undefined,
          formFields: undefined,
          successMessage: undefined,
          form: undefined,
          formSettings: undefined,
          formEntry: undefined,
          status: SelectedFormActionsEnum.CLEAR
        },
      });
    }
  }, []);

  useEffect(() => {
    if (!formState.forms.length) {
      onLoadForms(formDispatch);
    }
  }, []);

  useEffect(() => {
    const stateFormId = formState?.forms?.find(x => x.name == AgFormName)?.id ?? formId;

    if (stateFormId) {
      if (formEntryId && isAuthorized) {
        getSelectedFormEntryDataHelper(selectedFormDispatch, stateFormId, formEntryId);
      } else {
        getSelectedFormDataHelper(selectedFormDispatch, stateFormId);
      }
    }
  }, [formState.forms]);

  useEffect(() => {
    if (selectedFormState?.formEntry) {
      if (selectedFormState.form?.name) {
        setFormEntry({
          ...selectedFormState?.formEntry,
          formName: selectedFormState.form?.name
        });
      } else {
        setFormEntry(selectedFormState.formEntry)
      }
    }
  }, [selectedFormState.formEntry, selectedFormState.form]);

  function ClearData() {
    setFormDataFields([]);
    setFormEntry(undefined);
    setFormFirstSectionDataFields({});
    setFormSecondSectionDataFields({});
    setFormThirdSectionDataFields({});
    setFormFourthSectionDataFields({});
    setFormSixthSectionDataFields({});
    setFormSeventhSectionDataFields({});
    setFormEighthSectionDataFields({});
    setFormNinthSectionDataFields({});
    setFormTenthSectionDataFields({});
    setFirstSectionIsValid(false);
    setSecondSectionIsValid(false);
    setThirdSectionIsValid(false);
    setFourthSectionIsValid(false);
    setSixthSectionIsValid(false);

    setDisablePrompt(false);
  }

  useEffect(() => {
    if (selectedFormState.status === SelectedFormActionsEnum.CLEAR) {
      ClearData();
      return;
    }

    setFormDataFields(selectedFormState.formFields);
    const FirstSection: IAgFormFirstSectionInterface = AgFormFirstSectionInitialState();
    const SecondSection: IAgFormSecondSectionInterface = AgFormSecondSectionInitialState();
    const ThirdSection: IAgFormThirdSectionInterface = AgFormThirdSectionInitialState();
    const FourthSection: IAgFormFourthSectionInterface = AgFormFourthSectionInitialState();
    const SixthSection: IAgFormSixthSectionInterface = AgFormSixthSectionInitialState();
    const SeventhSection: IAgFormSeventhSectionInterface = AgFormSeventhSectionInitialState();
    const EighthSection: IAgFormEighthSectionInterface = AgFormEighthSectionInitialState();
    const NinthSection: IAgFormNinthSectionInterface = AgFormNinthSectionInitialState();
    const Tenthsection: IAgFormTenthSectionInterface = AgFormTenthSectionInitialState();

    selectedFormState?.formFields.forEach((f) => {
      if (f.name in FirstSection) {
        if (f.type == "boolean") {
          (FirstSection as any)[f.name] = JSON.parse(f.value);
        } else if (f.type == "string") {
          (FirstSection as any)[f.name] = f.value.trim();
        }
      } else if (f.name in SecondSection) {
        if (f.type == "boolean") {
          (SecondSection as any)[f.name] = JSON.parse(f.value);
        } else if (f.type == "string") {
          (SecondSection as any)[f.name] = f.value.trim();
        }
      } else if (f.name in ThirdSection) {
        if (f.type == "boolean") {
          (ThirdSection as any)[f.name] = JSON.parse(f.value);
        } else if (f.type == "string") {
          (ThirdSection as any)[f.name] = f.value.trim();
        }
      } else if (f.name in FourthSection) {
        if (f.type == "boolean") {
          (FourthSection as any)[f.name] = JSON.parse(f.value);
        } else if (f.type == "string") {
          (FourthSection as any)[f.name] = f.value.trim();
        }
      } else if (f.name in SixthSection) {
        if (f.type == "boolean") {
          (SixthSection as any)[f.name] = JSON.parse(f.value);
        } else if (f.type == "string") {
          (SixthSection as any)[f.name] = f.value.trim();
        } else if (f.type == "object") {
          (SixthSection as any)[f.name] = JSON.parse(f.value);
        }
      } else if (f.name in SeventhSection) {
        if (f.type == "boolean") {
          (SeventhSection as any)[f.name] = JSON.parse(f.value);
        } else if (f.type == "string") {
          (SeventhSection as any)[f.name] = f.value.trim();
        } else if (f.type == "object") {
          (SeventhSection as any)[f.name] = JSON.parse(f.value);
        }
      } else if (f.name in EighthSection) {
        if (f.type == "boolean") {
          (EighthSection as any)[f.name] = JSON.parse(f.value);
        } else if (f.type == "string") {
          (EighthSection as any)[f.name] = f.value.trim();
        }
      } else if (f.name in NinthSection) {
        if (f.type == "object") {
          (NinthSection as any)[f.name] = JSON.parse(f.value);
        }
      } else if (f.name in Tenthsection) {
        if (f.type == "string") {
          (Tenthsection as any)[f.name] = f.value.trim();
        }
      }

    });
    if (selectedFormState.formFields.length > 0) {
      setFormFirstSectionDataFields(FirstSection);
      setFormSecondSectionDataFields(SecondSection);
      setFormThirdSectionDataFields(ThirdSection);
      setFormFourthSectionDataFields(FourthSection);
      setFormSixthSectionDataFields(SixthSection);
      setFormSeventhSectionDataFields(SeventhSection);
      setFormEighthSectionDataFields(EighthSection);
      setFormNinthSectionDataFields(NinthSection);
      setFormTenthSectionDataFields(Tenthsection);
    }
  }, [selectedFormState.formFields]);

  useFormSectionDataFields({
    sectionFields: formFirstSectionDataFields,
    formDataFields: formDataFields,
    setFormDataFields: setFormDataFields,
    formId: formEntry?.formId,
    formEntryId: formEntry?.id
  });

  useFormSectionDataFields({
    sectionFields: formSecondSectionDataFields,
    formDataFields: formDataFields,
    setFormDataFields: setFormDataFields,
    formId: formEntry?.formId,
    formEntryId: formEntry?.id
  });

  useFormSectionDataFields({
    sectionFields: formThirdSectionDataFields,
    formDataFields: formDataFields,
    setFormDataFields: setFormDataFields,
    formId: formEntry?.formId,
    formEntryId: formEntry?.id
  });

  useFormSectionDataFields({
    sectionFields: formFourthSectionDataFields,
    formDataFields: formDataFields,
    setFormDataFields: setFormDataFields,
    formId: formEntry?.formId,
    formEntryId: formEntry?.id
  });

  useFormSectionDataFields({
    sectionFields: formSixthSectionDataFields,
    formDataFields: formDataFields,
    setFormDataFields: setFormDataFields,
    formId: formEntry?.formId,
    formEntryId: formEntry?.id
  });

  useFormSectionDataFields({
    sectionFields: formSeventhSectionDataFields,
    formDataFields: formDataFields,
    setFormDataFields: setFormDataFields,
    formId: formEntry?.formId,
    formEntryId: formEntry?.id
  });

  useFormSectionDataFields({
    sectionFields: formEighthSectionDataFields,
    formDataFields: formDataFields,
    setFormDataFields: setFormDataFields,
    formId: formEntry?.formId,
    formEntryId: formEntry?.id
  });

  useFormSectionDataFields({
    sectionFields: formNinthSectionDataFields,
    formDataFields: formDataFields,
    setFormDataFields: setFormDataFields,
    formId: formEntry?.formId,
    formEntryId: formEntry?.id
  });

  useFormSectionDataFields({
    sectionFields: formTenthSectionDataFields,
    formDataFields: formDataFields,
    setFormDataFields: setFormDataFields,
    formId: formEntry?.formId,
    formEntryId: formEntry?.id
  });

  const navigate = useNavigate();

  function saveHandler() {
    setDisablePrompt(true);
    const temp = {
      ...formEntry,
      formName: selectedFormState.form?.name,
      submissionStatus: "Saved",
    } as IFormEntryModel;
    setFormEntry(temp);
    if (selectedFormState.form?.name) {
      saveFormHelper(selectedFormDispatch, selectedFormState.form?.name, temp, formDataFields!, navigate);
    }
  }

  function setFormEntryBase64Url(url: string) {
    setFormEntry({ ...formEntry, pdfBase64Url: url } as IFormEntryModel);
  }

  function submitHandler() {
    const temp = {
      ...formEntry,
      formName: selectedFormState.form?.name,
      submissionStatus: "Submitted",
    } as IFormEntryModel;
    setFormEntry(temp);
    setDisablePrompt(true);

    if (selectedFormState.form?.name) {
      saveFormHelper(selectedFormDispatch, selectedFormState.form?.name, temp, formDataFields!, navigate);
    }
  }

  const [visible, setVisible] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const toggleSubmitDialog = () => {
    setSubmit(!submit);
    setVisible(!visible);
  };

  const dialogTitle = (
    <div>
      {submit && !formEntry?.underRevision && formEntry?.submissionStatus != "Submitted" && (
        <button className="button" onClick={submitHandler}>
          submit form
        </button>
      )}
    </div>
  );

  return <>
    <div className={classes.form}>
      {visible && (
        <Dialog
          className="customFormDialog"
          title={dialogTitle}
          onClose={submit ? toggleSubmitDialog : toggleDialog}
          width={"70vw"}
          height={"90vh"}
        >
          <AGFormPDFView
            getPdfBase64Url={setFormEntryBase64Url}
            formDataFieldsFirstSection={formFirstSectionDataFields}
            formDataFieldsSecondSection={formSecondSectionDataFields}
            formDataFieldsThirdSection={formThirdSectionDataFields}
            formDataFieldsFourthSection={formFourthSectionDataFields}
            formDataFieldsSixthSection={formSixthSectionDataFields}
            formDataFieldsSeventhSection={formSeventhSectionDataFields}
            formDataFieldsEighthSection={formEighthSectionDataFields}
            formDataFieldsNinthSection={formNinthSectionDataFields}
            formDataFieldsTenthSection={formTenthSectionDataFields}
          />
        </Dialog>
      )}

      <FormHeader
        formName={AgFormName}
        isAdminPreview={isAdminPreview}
        isAuthorized={isAuthorized}
        formEntry={formEntry}
        formSettings={formSettings}
        isFormValid={isFormValid}
        onPrint={() => toggleDialog()}
        onSave={() => saveHandler()}
        onSubmit={() => toggleSubmitDialog()}
      />

      <AgFormFirstSection
        isAdminPreview={isAdminPreview}
        formSettings={formSettings}
        formDataFields={formFirstSectionDataFields}
        setFormDataFields={setFormFirstSectionDataFields}
        setFormIsValid={setFirstSectionIsValid}
      />

      <AgFormSecondSection
        isAdminPreview={isAdminPreview}
        formSettings={formSettings}
        formDataFields={formSecondSectionDataFields}
        setFormDataFields={setFormSecondSectionDataFields}
        setFormIsValid={setSecondSectionIsValid}
      />

      <AgFormThirdSection
        isAdminPreview={isAdminPreview}
        formSettings={formSettings}
        formDataFields={formThirdSectionDataFields}
        setFormDataFields={setFormThirdSectionDataFields}
        setFormIsValid={setThirdSectionIsValid}
      />

      <AgFormFourthSection
        isAdminPreview={isAdminPreview}
        formSettings={formSettings}
        formDataFields={formFourthSectionDataFields}
        setFormDataFields={setFormFourthSectionDataFields}
        setFormIsValid={setFourthSectionIsValid}
      />

      <AgFormSixthSection
        isAdminPreview={isAdminPreview}
        formSettings={formSettings}
        formDataFields={formSixthSectionDataFields}
        setFormDataFields={setFormSixthSectionDataFields}
        setFormIsValid={setSixthSectionIsValid}
      />

      <AgFormSeventhSection
        isAdminPreview={isAdminPreview}
        formSettings={formSettings}
        formDataFields={formSeventhSectionDataFields}
        setFormDataFields={setFormSeventhSectionDataFields}
        setFormIsValid={setSeventhSectionIsValid}
        selectedFormDispatch={selectedFormDispatch}
        formEntry={formEntry}
      />

      <AgFormEighthSection
        isAdminPreview={isAdminPreview}
        formSettings={formSettings}
        formDataFields={formEighthSectionDataFields}
        setFormDataFields={setFormEighthSectionDataFields}
        setFormIsValid={setEighthSectionIsValid}
      />

      <AgFormNinthSection
        isAdminPreview={isAdminPreview}
        formSettings={formSettings}
        formDataFields={formNinthSectionDataFields}
        setFormDataFields={setFormNinthSectionDataFields}
        setFormIsValid={setNinthSectionIsValid}
        selectedFormDispatch={selectedFormDispatch}
        formEntry={formEntry}
      />

      <AgFormTenthSection
        isAdminPreview={isAdminPreview}
        formSettings={formSettings}
        formDataFields={formTenthSectionDataFields}
        setFormDataFields={setFormTenthSectionDataFields}
        setFormIsValid={setTenthSectionIsValid}
      />

      {(!isFormValid && !formSettings?.isReadOnly) &&
        <div className={classes.invalidForm}>
          <h2>Please Fill All Required Fields in Steps:</h2>
          {!firstSectionIsValid &&
            <div className={classes.item}>
              Filing Information
            </div>}
          {!secondSectionIsValid &&
            <div className={classes.item}>
              Section 1: Property Owner/Applicant
            </div>}
          {!thirdSectionIsValid &&
            <div className={classes.item}>
              Section 2: Authorized Representative
            </div>}
          {!fourthSectionIsValid &&
            <div className={classes.item}>
              Section 3: Property Description and Information
            </div>}
          {!sixthSectionIsValid &&
            <div className={classes.item}>
              Section 4: Property Use
            </div>}
          {!seventhSectionIsValid &&
            <div className={classes.item}>
              Section 5: Wildlife Management Use
            </div>}
          {!eighthSectionIsValid &&
            <div className={classes.item}>
              Section 6: Conversion to Timber Production
            </div>}
          {!ninthSectionIsValid &&
            <div className={classes.item}>
              Additional Supporting Documents
            </div>}
          {!tenthSectionIsValid &&
            <div className={classes.item}>
              Section 7: Certification and Signature
            </div>}
        </div>}

      {isAuthorized && !isAdminPreview &&
        !formSettings?.isReadOnly &&
        formEntry?.submissionStatus != "Submitted" && (
          <span className="buttons-group">
            <button disabled={!isFormValid}
              className="button"
              onClick={() => toggleSubmitDialog()}
            >
              <i className="fa fa-tasks fa-fw"></i>
              Review & Submit
            </button>
          </span>
        )}
    </div>
    {(loading || isLoading) && <CustomLoader />}
    {showPrompt && isAuthorized && (!loading && !isLoading) && (
      <UnsavedFormDialog
        onConfirm={confirmNavigation}
        onCancel={cancelNavigation}
      />
    )}
  </>
}
