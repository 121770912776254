import { PanelBar } from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import { PanelBarItem } from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "src/pages/Home/components/Forms/TimberlandAppraisalForm/TimberlandAppraisal.module.scss";
import { IFormSettingModel } from "../../../../../interfaces/form-settings.interface";
import { IFormEntryModel } from "../../../../../interfaces/form-entry.interface";
import { useEffect, useRef, useState } from "react";
import { Label } from "@progress/kendo-react-labels";
import { CheckboxChangeEvent, InputChangeEvent, RadioGroupChangeEvent, TextAreaChangeEvent, NumericTextBoxChangeEvent, Checkbox } from "@progress/kendo-react-inputs";
import { DatePickerChangeEvent, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { ComboBoxChangeEvent, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { ITimberlandAppraisalFormFifthSection } from "src/interfaces/forms/TimberlandAppraisalForm/timberland-appraisal-form-fifth-section.interface";
import { AgriculturalUseCategoriesOfLandTable } from "../AgForm/AgFormSixthSection/AgriculturalUseCategoriesOfLandTable";
import { IRowState } from "../CustomInputs/UpdateRowErrorStateHandler";
import { AgriculturalUseForestTypesTable } from "./TimberlandAppraisalFifthSection/AgriculturalUseForestTypes";
import { NonAgriculturalUseTable } from "./TimberlandAppraisalFifthSection/NonAgriculturalUseTable";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: ITimberlandAppraisalFormFifthSection) => void;
    formDataFields?:  ITimberlandAppraisalFormFifthSection,
    formSettings?: IFormSettingModel,
    formEntry?: IFormEntryModel,
    setFormIsValid: (value: boolean) => void,
}

export default function TimberlandAppraisalFormFifthSection(props: GeneralFormType): JSX.Element {
    const { formDataFields, setFormDataFields, formSettings, isAdminPreview, setFormIsValid } = props;
    const formRef = useRef<HTMLFormElement>(null);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const [dateErrorsArray, setDateErrorsArray] = useState<Array<IRowState>>([]);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={5} name={"Property’s Use"}/>;

    function changeListValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | NumericTextBoxChangeEvent
            | ComboBoxChangeEvent,
        field: string,
        group: string,
        id: number
    ) {
        const newItem: ITimberlandAppraisalFormFifthSection = formDataFields
            ? JSON.parse(JSON.stringify(formDataFields)) : [];

        let list = (newItem as any)[group]?.find((x: any) => x.Id == id);

        if (field == "Year"){
            list = {...list, [field]: event.value.getFullYear()};
        } else {
            list = {...list, [field]: event.value};
        }

        const index = (newItem as any)[group]?.findIndex((x: any) => x.Id == id);

        if (index != undefined && (newItem as any)) {
            (newItem as any)[group][index] = list;
        }

        setFormDataFields({
            ...newItem,
        });
    }

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent
            | NumericTextBoxChangeEvent,
        field: string
    ) {
        setFormDataFields({
            ...formDataFields,
            [field]: event.value
        });
    }

    function addRowHandler(event: React.MouseEvent<HTMLElement>, group: string) {
        event.preventDefault();
        const newItem: ITimberlandAppraisalFormFifthSection = formDataFields
            ? JSON.parse(JSON.stringify(formDataFields))
            : [];
        if (!(newItem as any)[group]) {
            (newItem as any)[group] = [];
        }
        (newItem as any)[group]?.push({ Id: (newItem as any)[group]?.length });
        setFormDataFields({
            ...newItem,
        });
    }

    function removeRowHandler(
        event: React.MouseEvent<HTMLElement>,
        group: string
    ) {
        event.preventDefault();
        const newItem: ITimberlandAppraisalFormFifthSection = formDataFields
            ? JSON.parse(JSON.stringify(formDataFields))
            : [];
        if (newItem && (newItem as any)[group]) {
            (newItem as any)[group].pop();
            setFormDataFields({
                ...newItem,
            });
        }
    }

    function changeValidator() {
        if (formRef.current) {
            const isValid = formRef.current.checkValidity();
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if(!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current,]);


    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div>
                            <p>
                                Provide complete answers to the following questions. List the agricultural
                                use of the property according to the agricultural land categories listed in
                                the preceding paragraph. Divide the total acreage according to individual
                                uses to which the land is principally devoted.
                            </p>
                        </div>
                        <div className={classes.item}>
                            <Label className={classes.Label}>
                                1. Describe the current and past agricultural uses of this property as described
                                above, starting with the current year and working back 5 years or until 5 out
                                of 7 years of agricultural use is shown. Five years of continuous agricultural use
                                may be required if the land is located within the corporate limits of a city or town
                            </Label>
                            <div className="print-together">
                                <div className="row">
                                    <div className="column-12">
                                        <AgriculturalUseCategoriesOfLandTable
                                            setErrorsArray={setDateErrorsArray}
                                            errorsArray={dateErrorsArray}
                                            items={formDataFields?.AgriculturalUseCategoriesOfLand || []}
                                            onChange={(items) => setFormDataFields({ ...formDataFields, AgriculturalUseCategoriesOfLand: items })}
                                            formSettings={formSettings}
                                        />
                                        {!formSettings?.isReadOnly && (
                                            <div>
                                                <button
                                                    className={classes.LightButton}
                                                    disabled={formSettings?.isReadOnly}
                                                    onClick={(e) =>
                                                        addRowHandler(e, "AgriculturalUseCategoriesOfLand")
                                                    }
                                                >
                                                    <i className="fa fas fa-plus"></i>
                                                    Add Row
                                                </button>
                                                <button
                                                    className={classes.LightButton}
                                                    disabled={formSettings?.isReadOnly}
                                                    onClick={(e) =>
                                                        removeRowHandler(e, "AgriculturalUseCategoriesOfLand")
                                                    }
                                                >
                                                    <i className="fa fas fa-minus"></i>
                                                    Remove Row
                                                </button>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className={classes.item}>
                            <Label className={classes.Label}>
                                2. List the total number of acres of the property described in Section 4 in each
                                of the following forest types:
                            </Label>
                            <div className="print-together">
                                <div className="row">
                                    <div className="column-12">
                                        <AgriculturalUseForestTypesTable
                                            changeValueHandler={changeValueHandler}
                                            formDataFields={formDataFields}
                                            formSettings={formSettings}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.itemFields}>
                            <div className={`${classes.fieldsGroupW49}`}>
                                <div className="inputsGroup">
                                    <Checkbox
                                        name={"IsPropertyUsedForNonAgriculturalActivities"}
                                        className={classes.Checkbox}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.IsPropertyUsedForNonAgriculturalActivities}
                                        label={"Check this box if this property is now used for any non-agricultural activities."}
                                        onChange={(e)=> changeValueHandler(e, "IsPropertyUsedForNonAgriculturalActivities")}
                                    />
                                </div>
                            </div>
                        </div>
                        { formDataFields?.IsPropertyUsedForNonAgriculturalActivities &&
                            <div className="print-together">
                                <Label className={classes.Label}>
                                    List all non-agricultural uses and the number of acres devoted to the use.
                                </Label>
                                <div className="row">
                                    <div className="column-12">
                                        <NonAgriculturalUseTable
                                            changeValueHandler={changeListValueHandler}
                                            formDataFields={formDataFields}
                                            formSettings={formSettings}
                                        />
                                        {!formSettings?.isReadOnly && (
                                            <div>
                                                <button
                                                    className={classes.LightButton}
                                                    disabled={formSettings?.isReadOnly}
                                                    onClick={(e) =>
                                                        addRowHandler(e, "NonAgriculturalUse")
                                                    }
                                                >
                                                    <i className="fa fas fa-plus"></i>
                                                    Add Row
                                                </button>
                                                <button
                                                    className={classes.LightButton}
                                                    disabled={formSettings?.isReadOnly}
                                                    onClick={(e) =>
                                                        removeRowHandler(e, "NonAgriculturalUse")
                                                    }
                                                >
                                                    <i className="fa fas fa-minus"></i>
                                                    Remove Row
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div> }
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
