import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import React, {useEffect, useRef, useState} from "react";
import {
    CheckboxChangeEvent, 
    InputChangeEvent, NumericTextBoxChangeEvent, 
    RadioGroupChangeEvent, TextArea,
    TextAreaChangeEvent
} from "@progress/kendo-react-inputs";
import {CalendarChangeEvent, DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./HomesteadExemption.module.scss";
import {Error, Label} from "@progress/kendo-react-labels";
import {IFormEntryModel} from "../../../../../interfaces/form-entry.interface";
import {GenericActionType} from "../../../../../context/generic-actions.type";
import {SelectedFormType} from "../../../../../context/SelectedForm/selected-form-reducer";
import {
    IHomesteadExemptionFifthSectionInterface
} from "../../../../../interfaces/forms/HomesteadExemptionForm/homestead-exemption-fifth-section.interface";
import {CustomUpload} from "../CustomInputs/CustomUpload";
import { UploadFileInfo } from "@progress/kendo-react-upload";
import {FormValidation} from "../InputValidator";
import {settingsService} from "../../../../../services/settings.service";
import {CustomDatePicker} from "../CustomInputs/CustomDatePickers/CustomDatePicker";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IHomesteadExemptionFifthSectionInterface) => void;
    formDataFields?:  IHomesteadExemptionFifthSectionInterface,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void,
    formEntry?: IFormEntryModel,
    selectedFormDispatch: (value: GenericActionType<SelectedFormType>) => void,
}

export default function HomesteadExemptionFifthSection(props: GeneralFormType): JSX.Element {
    const {setFormDataFields, formDataFields, formSettings, isAdminPreview, setFormIsValid, formEntry, selectedFormDispatch} = props;
    const settings = settingsService().settings;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const [isAdditionalDocumentsFilesUploadValid,setIsAdditionalDocumentsFilesUploadValid] = useState<boolean>(true);
    const [additionalDocumentsFiles, setAdditionalDocumentsFiles] = useState<Array<UploadFileInfo>>([]);
    const [purchaseDateValid,setPurchaseDateIsValid] = useState<boolean>(!settings.isHarrisClient);
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator } = FormValidation(formRef);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={5} name={"Additional Information and Documentation"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent
            | NumericTextBoxChangeEvent,
        field: string,
        eventType?: string
    ) {
        if (eventType === "RadioGroupChangeEvent") {
            setFormDataFields({
                ...formDataFields,
                [field]: JSON.parse(event.value)
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    useEffect(() => {
        return () => {
            setAdditionalDocumentsFiles([]);
        }
    }, []);

    function changeValidator() {
        if (formRef.current) {
            const isValid = settings.isHarrisClient
                ? formRef.current.checkValidity()
                : formRef.current.checkValidity() && purchaseDateValid;
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch() {
        if (!isFormTouched) {
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current, purchaseDateValid]);

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroupW100}>
                                    <Label className={classes.Label}>
                                        Please provide any additional, relevant, information that was not required as input in this form.
                                    </Label>
                                    <TextArea
                                        name={"AdditionalInformation"}
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.kTextbox}
                                        value={formDataFields?.AdditionalInformation}
                                        onChange={e => changeValueHandler(e, "AdditionalInformation")}
                                    />
                                    {!inputValidator("AdditionalInformation") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                            </div>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroupW100}>
                                    <Label className={classes.Label}>
                                        If you own other residential property in Texas, please list the county(ies) of location.
                                    </Label>
                                    <TextArea
                                        name={"AditionalOwnedPropertiesCounties"}
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.kTextbox}
                                        value={formDataFields?.AditionalOwnedPropertiesCounties}
                                        onChange={e => changeValueHandler(e, "AditionalOwnedPropertiesCounties")}
                                    />
                                    {!inputValidator("AditionalOwnedPropertiesCounties") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                            </div>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroupW100}>
                                    <CustomUpload
                                        files={additionalDocumentsFiles}
                                        setFiles={setAdditionalDocumentsFiles}
                                        uploadIsValid={isAdditionalDocumentsFilesUploadValid}
                                        setUploadIsValid={setIsAdditionalDocumentsFilesUploadValid}
                                        formEntry={formEntry}
                                        formSettings={formSettings}
                                        isAdminPreview={isAdminPreview}
                                        setFormDataFields={setFormDataFields}
                                        formDataFields={formDataFields}
                                        fieldName={"AdditionalDocuments"}
                                        isRequired={false}
                                        selectedFormDispatch={selectedFormDispatch}
                                        multiple={true}
                                        isLabelRequired={true}
                                        labelText={"Please add any additional documents you deem pertinent to this application or any documents that were requested by the appraisal district."}                                               
                                    />
                                </div>
                            </div>
                            { settings.isHarrisClient &&
                                formSettings?.showOptionalInformationRequestedPurchasePriceField &&
                                <div className={classes.itemFields}>
                                    <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Optional Information Requested Purchase Price
                                        </Label>
                                        <TextArea
                                            name={"OptionalInformationRequestedPurchasePrice"}
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.kTextbox}
                                            value={formDataFields?.OptionalInformationRequestedPurchasePrice}
                                            onChange={e => changeValueHandler(e, "OptionalInformationRequestedPurchasePrice")}
                                        />
                                        {!inputValidator("OptionalInformationRequestedPurchasePrice") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                </div> }
                            { settings.isHarrisClient &&
                                formSettings?.showPurchaseDateField &&
                                <div className={classes.itemFields}>
                                    <div className={classes.fieldsGroupW49}>
                                        <Label className={classes.Label}>
                                            Purchase Date
                                        </Label>
                                        <CustomDatePicker
                                            fieldName={"PurchaseDate"}
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            formDataFields={formDataFields}
                                            setFormDataFields={setFormDataFields}
                                            onlyYear={false}
                                            setIsValidField={setPurchaseDateIsValid}
                                            validField={purchaseDateValid}
                                            defaultValueDate={formDataFields?.PurchaseDate}
                                        />
                                    </div>
                                </div> }
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
