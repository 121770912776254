import type { FieldRenderProps } from "@progress/kendo-react-form";
import { Field, FieldWrapper } from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";

const FieldWithLeftLabel = (
  fieldRenderProps: FieldRenderProps
): JSX.Element => {
  const {
    label,
    validator,
    name,
    type,
    placeholder,
    renderComponent,
    mask,
    disabled,
    onChange,
    initValue,
    readOnly,
  } = fieldRenderProps;
  return (
    <FieldWrapper>
      <Label>{label}</Label>
      <div className={"k-form-field-wrap"}>
        <Field
          name={name}
          type={type}
          placeholder={placeholder}
          component={renderComponent}
          validator={validator}
          mask={mask}
          disabled={disabled}
          onChange={onChange}
          initValue={initValue}
          readOnly={readOnly}
        />
      </div>
    </FieldWrapper>
  );
};

export default FieldWithLeftLabel;
