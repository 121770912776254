import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import classes from "./Footer.module.scss";
import { settingsService } from "../../services/settings.service";

export default function Footer(): JSX.Element {
  const { settings } = settingsService();
  const { t } = useTranslation();

  return (
    <div className={classes.FooterContainer}>
      <div>
        <span className="mr-2">{t("footer.poweredBy")}</span>
        <a href="http://www.bisconsultants.com/" rel="noopener">
          {t("footer.bisConsulting")}
        </a>
      </div>
      <div>
        <strong className="mr-2">
          <FontAwesomeIcon icon="check" /> {t("footer.version")}:
        </strong>
        {settings?.applicationVersion}
      </div>
    </div>
  );
}
