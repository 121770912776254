import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "../WildlifeManagement.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, Checkbox, RadioGroupChangeEvent, Input } from "@progress/kendo-react-inputs";
import { FormValidation } from "../../InputValidator";
import { Label } from "@progress/kendo-react-labels";
import { CalendarChangeEvent, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { CustomDatePicker } from "../../CustomInputs/CustomDatePickers/CustomDatePicker";
import { IWildlifeManagementFourthSectionErosionControl } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-erosion-control.interface";
import { CheckboxGroup } from "../../CustomInputs/CheckBoxes/CheckboxGroup";
import SectionTitle from "../../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    formSettings?: IFormSettingModel,
    formDataFields?: IWildlifeManagementFourthSectionErosionControl;
    setFormDataFields: (form: IWildlifeManagementFourthSectionErosionControl) => void;
    setFormIsValid: (value: boolean) => void;
    setSectionIsValid: (value: boolean) => void;
}

export default function ErosionControlSubSection(props: GeneralFormType): JSX.Element {
    const { formSettings, isAdminPreview, formDataFields, setFormDataFields, setFormIsValid, setSectionIsValid } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const [pondConstructionPlannedDateOfConstructionIsValid, setPondConstructionPlannedDateOfConstructionIsValid] = 
        useState<boolean>(!formDataFields?.PondConstructionPlannedDateOfConstruction);
    const [gullyShapingPlannedDateOfConstructionIsValid, setGullyShapingPlannedDateOfConstructionIsValid] = 
        useState<boolean>(!formDataFields?.GullyShapingPlannedDateOfConstruction);
    const [streamsidePondWetlandRevegetationPlannedDateOfConstructionIsValid, 
           setStreamsidePondWetlandRevegetationPlannedDateOfConstructionIsValid] = 
        useState<boolean>(!formDataFields?.StreamsidePondWetlandRevegetationPlannedDateOfConstruction);
    const [establishWaterDiversionVegetatedInputValid, setEstablishWaterDiversionVegetatedInputValid] =
        useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator, setStyles } = FormValidation(formRef);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={2} name={"Erosion Control"}/>;
    
    function changeValidator() {
        if (formRef.current) {
            const isValid = formRef.current.checkValidity();
            setIsValid(isValid);
            setSectionIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string,
        group?: string
    ) {
        if (group) {
            setFormDataFields({
                ...formDataFields,
                [group]: {
                    ...(formDataFields as any)?.[group],
                    [field]: event.value
                }
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    function onTouch(){
        if (!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [ formDataFields, formRef.current ]);

    return (
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.PondConstruction}
                                    label={"Pond construction and repair"}
                                    onChange={(e)=> changeValueHandler(e, "PondConstruction")}
                                />
                            </div>
                            { formDataFields?.PondConstruction && 
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Surface area (acres)
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"PondConstructionSurfaceArea"}
                                            placeholder="Surface area (acres)"
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.PondConstructionSurfaceArea}
                                            onChange={(e)=> changeValueHandler(e, "PondConstructionSurfaceArea")}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Number of cubic yards of soil displaced
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"PondConstructionNumberOfCubicYardsOfSoilDisplaced"}
                                            placeholder="Number of cubic yards of soil displaced"
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.PondConstructionNumberOfCubicYardsOfSoilDisplaced}
                                            onChange={(e)=> changeValueHandler(e, "PondConstructionNumberOfCubicYardsOfSoilDisplaced")}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Length of dam (feet)
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"PondConstructionLengthOfDam"}
                                            placeholder="Length of dam (feet)"
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.PondConstructionLengthOfDam}
                                            onChange={(e)=> changeValueHandler(e, "PondConstructionLengthOfDam")}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Planned date of construction
                                        </Label>
                                        <CustomDatePicker
                                            fieldName={"PondConstructionPlannedDateOfConstruction"}
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            formDataFields={formDataFields}
                                            setFormDataFields={setFormDataFields}
                                            onlyYear={false}
                                            setIsValidField={setPondConstructionPlannedDateOfConstructionIsValid}
                                            validField={pondConstructionPlannedDateOfConstructionIsValid}
                                            defaultValueDate={formDataFields?.PondConstructionPlannedDateOfConstruction}
                                        />
                                    </div>
                                </div>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"PondConstructionAdditionalInformation"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.PondConstructionAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "PondConstructionAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.GullyShaping}
                                    label={"Gully shaping"}
                                    onChange={(e)=> changeValueHandler(e, "GullyShaping")}
                                />
                            </div>
                            { formDataFields?.GullyShaping && 
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Total acres to be treated
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"GullyShapingTotalAcresToBeTreated"}
                                            placeholder="Total acres to be treated"
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.GullyShapingTotalAcresToBeTreated}
                                            onChange={(e)=> changeValueHandler(e, "GullyShapingTotalAcresToBeTreated")}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Acres treated annually
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"GullyShapingAcresTreatedAnnually"}
                                            placeholder="Acres treated annually"
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.GullyShapingAcresTreatedAnnually}
                                            onChange={(e)=> changeValueHandler(e, "GullyShapingAcresTreatedAnnually")}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Seeding mix used for reestablishment of vegetation
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"GullyShapingSeedingMixUsed"}
                                            placeholder="Seeding mix used for reestablishment of vegetation"
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.GullyShapingSeedingMixUsed}
                                            onChange={(e)=> changeValueHandler(e, "GullyShapingSeedingMixUsed")}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Planned date of construction
                                        </Label>
                                        <CustomDatePicker
                                            fieldName={"GullyShapingPlannedDateOfConstruction"}
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            formDataFields={formDataFields}
                                            setFormDataFields={setFormDataFields}
                                            onlyYear={false}
                                            setIsValidField={setGullyShapingPlannedDateOfConstructionIsValid}
                                            validField={gullyShapingPlannedDateOfConstructionIsValid}
                                            defaultValueDate={formDataFields?.GullyShapingPlannedDateOfConstruction}
                                        />
                                    </div>
                                </div>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"GullyShapingAdditionalInformation"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.GullyShapingAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "GullyShapingAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.StreamsidePondWetlandRevegetation}
                                    label={"Streamside, pond, and wetland revegetation"}
                                    onChange={(e)=> changeValueHandler(e, "StreamsidePondWetlandRevegetation")}
                                />
                            </div>
                            { formDataFields?.StreamsidePondWetlandRevegetation &&
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Techniques used
                                        </Label>
                                    </div>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.StreamsidePondWetlandRevegetationTechniquesUsed?.NativeHayBales}
                                            label={"Native hay bales"}
                                            onChange={(e)=>
                                                changeValueHandler(e, "Herd3Pasture", "StreamsidePondWetlandRevegetationTechniquesUsed")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.StreamsidePondWetlandRevegetationTechniquesUsed?.Fencing}
                                            label={"Fencing"}
                                            onChange={(e)=> changeValueHandler(e, "Fencing", "StreamsidePondWetlandRevegetationTechniquesUsed")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.StreamsidePondWetlandRevegetationTechniquesUsed?.FilterStrips}
                                            label={"Filter strips"}
                                            onChange={(e)=> changeValueHandler(e, "FilterStrips", "StreamsidePondWetlandRevegetationTechniquesUsed")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.StreamsidePondWetlandRevegetationTechniquesUsed?.SeedingUplandBuffer}
                                            label={"Seeding upland buffer"}
                                            onChange={(e)=> changeValueHandler(e, "SeedingUplandBuffer", "StreamsidePondWetlandRevegetationTechniquesUsed")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.StreamsidePondWetlandRevegetationTechniquesUsed?.RipRap}
                                            label={"Rip-rap, etc."}
                                            onChange={(e)=> changeValueHandler(e, "RipRap", "StreamsidePondWetlandRevegetationTechniquesUsed")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.StreamsidePondWetlandRevegetationTechniquesUsed?.StreamCrossings}
                                            label={"Stream crossings"}
                                            onChange={(e)=> changeValueHandler(e, "StreamCrossings", "StreamsidePondWetlandRevegetationTechniquesUsed")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.StreamsidePondWetlandRevegetationTechniquesUsed?.Other}
                                            label={"Other"}
                                            onChange={(e)=> changeValueHandler(e, "Other", "StreamsidePondWetlandRevegetationTechniquesUsed")}
                                        />
                                    </div>
                                    {formDataFields?.StreamsidePondWetlandRevegetationTechniquesUsed?.Other && 
                                        <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Other (describe)
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"StreamsidePondWetlandRevegetationTechniquesUsedOther"}
                                            placeholder="Other (describe)"
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.StreamsidePondWetlandRevegetationTechniquesUsedOther}
                                            onChange={(e)=> changeValueHandler(e, "StreamsidePondWetlandRevegetationTechniquesUsedOther")}
                                        />
                                        </div>
                                    }
                                    <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Planned date of construction
                                        </Label>
                                        <CustomDatePicker
                                            fieldName={"StreamsidePondWetlandRevegetationPlannedDateOfConstruction"}
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            formDataFields={formDataFields}
                                            setFormDataFields={setFormDataFields}
                                            onlyYear={false}
                                            setIsValidField={setStreamsidePondWetlandRevegetationPlannedDateOfConstructionIsValid}
                                            validField={streamsidePondWetlandRevegetationPlannedDateOfConstructionIsValid}
                                            defaultValueDate={formDataFields?.StreamsidePondWetlandRevegetationPlannedDateOfConstruction}
                                        />
                                    </div>
                                </div>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"StreamsidePondWetlandRevegetationAdditionalInformation"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.StreamsidePondWetlandRevegetationAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "StreamsidePondWetlandRevegetationAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.HerbaceousPlantEstablishment}
                                    label={"Herbaceous and/or woody plant establishment on critical areas (erodible)"}
                                    onChange={(e)=> changeValueHandler(e, "HerbaceousPlantEstablishment")}
                                />
                            </div>
                            { formDataFields?.HerbaceousPlantEstablishment && 
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HerbaceousPlantEstablishmentTypes?.EstablishWindbreak}
                                            label={"Establish windbreak"}
                                            onChange={(e)=>
                                                changeValueHandler(e, "EstablishWindbreak", "HerbaceousPlantEstablishmentTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HerbaceousPlantEstablishmentTypes?.EstablishShrubMottes}
                                            label={"Establish shrub mottes"}
                                            onChange={(e)=> changeValueHandler(e, "EstablishShrubMottes", "HerbaceousPlantEstablishmentTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HerbaceousPlantEstablishmentTypes?.ImprovePlantDiversity}
                                            label={"Improve plant diversity"}
                                            onChange={(e)=> changeValueHandler(e, "ImprovePlantDiversity", "HerbaceousPlantEstablishmentTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HerbaceousPlantEstablishmentTypes?.ImproveWildlifeHabitat}
                                            label={"Improve wildlife habitat"}
                                            onChange={(e)=> changeValueHandler(e, "ImproveWildlifeHabitat", "HerbaceousPlantEstablishmentTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HerbaceousPlantEstablishmentTypes?.ConservationNoTillPractices}
                                            label={"Conservation/no-till practices"}
                                            onChange={(e)=> changeValueHandler(e, "ConservationNoTillPractices", "HerbaceousPlantEstablishmentTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.HerbaceousPlantEstablishmentTypes?.ManageCrpCover}
                                            label={"Manage CRP cover"}
                                            onChange={(e)=> changeValueHandler(e, "ManageCrpCover", "HerbaceousPlantEstablishmentTypes")}
                                        />
                                    </div>
                                </div>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"HerbaceousPlantEstablishmentAdditionalInformation"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.HerbaceousPlantEstablishmentAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "HerbaceousPlantEstablishmentAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.DikeLeveeConstructionManagement}
                                    label={"Dike/Levee Construction/Management"}
                                    onChange={(e)=> changeValueHandler(e, "DikeLeveeConstructionManagement")}
                                />
                            </div>
                            { formDataFields?.DikeLeveeConstructionManagement && 
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.DikeLeveeConstructionManagementTypes?.ReshapingRepairingErosionDamage}
                                            label={"Reshaping/repairing erosion damage"}
                                            onChange={(e)=>
                                                changeValueHandler(e, "ReshapingRepairingErosionDamage", "DikeLeveeConstructionManagementTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.DikeLeveeConstructionManagementTypes?.RevegetatingStabilizeLeveeAreas}
                                            label={"Revegetating/stabilize levee areas"}
                                            onChange={(e)=> changeValueHandler(e, "RevegetatingStabilizeLeveeAreas", "DikeLeveeConstructionManagementTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.DikeLeveeConstructionManagementTypes?.InstallWaterControlStructure}
                                            label={"Install water control structure"}
                                            onChange={(e)=> changeValueHandler(e, "InstallWaterControlStructure", "DikeLeveeConstructionManagementTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.DikeLeveeConstructionManagementTypes?.Fencing}
                                            label={"Fencing"}
                                            onChange={(e)=> changeValueHandler(e, "Fencing", "DikeLeveeConstructionManagementTypes")}
                                        />
                                    </div>
                                </div>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"DikeLeveeConstructionManagementAdditionalInformation"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.DikeLeveeConstructionManagementAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "DikeLeveeConstructionManagementAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>                        
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.EstablishWaterDiversion}
                                    label={"Establish water diversion"}
                                    onChange={(e)=> changeValueHandler(e, "EstablishWaterDiversion")}
                                />
                                { formDataFields?.EstablishWaterDiversion && <>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                        <div className={classes.fieldsGroupW100}>
                                            <div className={`${classes.fieldsGroupW75} ${classes.flexRow}`}>
                                                <div className={classes.fieldsGroupW25}>
                                                    <Label className={classes.Label}>
                                                        Type
                                                    </Label>
                                                </div>
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.EstablishWaterDiversionTypes?.Channel}
                                                    label={"Channel"}
                                                    onChange={(e)=>
                                                        changeValueHandler(e, "Channel", "EstablishWaterDiversionTypes")}
                                                />
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.EstablishWaterDiversionTypes?.Ridge}
                                                    label={"Ridge"}
                                                    onChange={(e)=>
                                                        changeValueHandler(e, "Ridge", "EstablishWaterDiversionTypes")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>                                        
                                        <div className={classes.fieldsGroupW100}>
                                            <div className={classes.flexRow}>
                                                <div className={`${classes.fieldsGroupW75} ${classes.flexRow}`}>
                                                    <div className={classes.fieldsGroupW25}>
                                                        <Label className={classes.Label}>
                                                            Slope
                                                        </Label>
                                                    </div>
                                                    <Checkbox
                                                        disabled={formSettings?.isReadOnly}
                                                        className={classes.Checkbox}
                                                        value={formDataFields?.EstablishWaterDiversionSlope?.Level}
                                                        label={"Level"}
                                                        onChange={(e)=>
                                                            changeValueHandler(e, "Level", "EstablishWaterDiversionSlope")}
                                                    />
                                                    <Checkbox
                                                        disabled={formSettings?.isReadOnly}
                                                        className={classes.Checkbox}
                                                        value={formDataFields?.EstablishWaterDiversionSlope?.Graded}
                                                        label={"Graded"}
                                                        onChange={(e)=>
                                                            changeValueHandler(e, "Graded", "EstablishWaterDiversionSlope")}
                                                    />
                                                </div>
                                                <div className={`${classes.fieldsGroupW25} ${classes.fieldsGroupLeft}`}>
                                                    <Label className={classes.Label}>
                                                        Length (feet)
                                                    </Label>
                                                    <Input
                                                        className={classes.kTextbox}
                                                        name={"EstablishWaterDiversionLength"}
                                                        placeholder="Length (feet)"
                                                        disabled={formSettings?.isReadOnly}
                                                        value={formDataFields?.EstablishWaterDiversionLength}
                                                        onChange={(e)=> changeValueHandler(e, "EstablishWaterDiversionLength")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                        <Label className={`${classes.Label}`}>
                                            Vegetated
                                        </Label>
                                        <div className={classes.fieldsGroup}>
                                            <div className={classes.checkboxGroup}>
                                                <CheckboxGroup
                                                    isAdminPreview={isAdminPreview}
                                                    setFormDataFields={setFormDataFields}
                                                    formDataFields={formDataFields}
                                                    formSettings={formSettings}
                                                    fieldName={"EstablishWaterDiversionVegetated"}
                                                    fieldSecondName={"EstablishWaterDiversionVegetatedSecond"}
                                                    isRequired={false}
                                                    checkboxesIsValid={establishWaterDiversionVegetatedInputValid}
                                                    setCheckboxesIsValid={setEstablishWaterDiversionVegetatedInputValid}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    { formDataFields?.EstablishWaterDiversionVegetated && 
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <div className={classes.fieldsGroupW50}>
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.EstablishWaterDiversionVegetatedType?.Native}
                                                    label={"Native"}
                                                    onChange={(e)=> changeValueHandler(e, "Native", "EstablishWaterDiversionVegetatedType")}
                                                />
                                                { formDataFields?.EstablishWaterDiversionVegetatedType?.Native && 
                                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                                        <Label className={classes.Label}>
                                                            Native
                                                        </Label>
                                                        <Input
                                                            className={classes.kTextbox}
                                                            name={"EstablishWaterDiversionNative"}
                                                            placeholder="Native"
                                                            required={false}
                                                            disabled={formSettings?.isReadOnly}
                                                            value={formDataFields?.EstablishWaterDiversionNative}
                                                            onChange={(e)=> changeValueHandler(e, "EstablishWaterDiversionNative")}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            <div className={classes.fieldsGroupW50}>
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.EstablishWaterDiversionVegetatedType?.Crop}
                                                    label={"Crop"}
                                                    onChange={(e)=> changeValueHandler(e, "Crop", "EstablishWaterDiversionVegetatedType")}
                                                />
                                                { formDataFields?.EstablishWaterDiversionVegetatedType?.Crop && 
                                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                                        <Label className={classes.Label}>
                                                            Crop
                                                        </Label>
                                                        <Input
                                                            className={classes.kTextbox}
                                                            name={"EstablishWaterDiversionCrop"}
                                                            placeholder="Crop"
                                                            required={false}
                                                            disabled={formSettings?.isReadOnly}
                                                            value={formDataFields?.EstablishWaterDiversionCrop}
                                                            onChange={(e)=> changeValueHandler(e, "EstablishWaterDiversionCrop")}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }</>
                                }
                                <div className={classes.paddingTop}>
                                    <Label className={classes.Label}>
                                        Additional Information
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"EstablishWaterDiversionAdditionalInformation"}
                                        required={false}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.EstablishWaterDiversionAdditionalInformation}
                                        onChange={(e)=> changeValueHandler(e, "EstablishWaterDiversionAdditionalInformation")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    )
}