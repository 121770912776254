import {
    IBppFormSecondSectionInterface,
    SecondSectionInitialState
} from "../../../../../../interfaces/forms/BPPForm/bpp-form-second-section.interface";
import {
    IBPPFormThirdSectionInterface,
    ThirdSectionInitialState
} from "../../../../../../interfaces/forms/BPPForm/bpp-form-third-section.interface";
import {
    FourthSectionInitialState,
    IBppFormFourthSectionInterface
} from "../../../../../../interfaces/forms/BPPForm/bpp-form-fourth-section.interface";
import {
    FifthSectionInitialState,
    IBppFormFifthSectionInterface
} from "../../../../../../interfaces/forms/BPPForm/bpp-form-fifth-section.interface";
import {
    IBppFormSixthSectionInterface,
    SixthSectionInitialState
} from "../../../../../../interfaces/forms/BPPForm/bpp-form-sixth-section.interface";
import {
    IBppFormThirteenthSectionInterface,
    ThirteenthSectionInitialState
} from "../../../../../../interfaces/forms/BPPForm/bpp-form-thirteenth-section.interface";
import {
    FourteenthSectionInitialState,
    IBppFormFourteenthSectionInterface
} from "../../../../../../interfaces/forms/BPPForm/bpp-form-fourteenth-section.interface";
import {
    FifteenthSectionInitialState,
    IBppFormFifteenthSectionInterface
} from "../../../../../../interfaces/forms/BPPForm/bpp-form-fifteenth-section.interface";
import {
    IBPPFormPersonalPropertySegmentsInterface
} from "../../../../../../interfaces/forms/BPPForm/bpp-form-personal-property-segments.interface";
import {IFormFiledInterface} from "../../../../../../interfaces/form-filed.interface";

export interface IParsedBppSections {
    SecondSection: IBppFormSecondSectionInterface,
    ThirdSection: IBPPFormThirdSectionInterface,
    FourthSection: IBppFormFourthSectionInterface,
    FifthSection: IBppFormFifthSectionInterface,
    SixthSection: IBppFormSixthSectionInterface,
    ThirteenSection: IBppFormThirteenthSectionInterface,
    FourteenthSection: IBppFormFourteenthSectionInterface,
    FifteenthSection: IBppFormFifteenthSectionInterface,
    NewAssets?: IBPPFormPersonalPropertySegmentsInterface[]
}

export function parseBppFromSections(
    formFields: IFormFiledInterface[]
): IParsedBppSections {
    const SecondSection: IBppFormSecondSectionInterface = {...SecondSectionInitialState};
    const ThirdSection: IBPPFormThirdSectionInterface = {...ThirdSectionInitialState};
    const FourthSection: IBppFormFourthSectionInterface = {...FourthSectionInitialState};
    const FifthSection: IBppFormFifthSectionInterface = {...FifthSectionInitialState};
    const SixthSection: IBppFormSixthSectionInterface = {...SixthSectionInitialState};

    const ThirteenSection: IBppFormThirteenthSectionInterface = {...ThirteenthSectionInitialState};
    const FourteenthSection: IBppFormFourteenthSectionInterface = {...FourteenthSectionInitialState};
    const FifteenthSection: IBppFormFifteenthSectionInterface = {...FifteenthSectionInitialState};

    formFields.forEach((f) => {
        if (f.name in SecondSection) {
            setFieldValue(f, SecondSection);
        } else if (f.name in ThirdSection) {
            setFieldValue(f, ThirdSection);
        } else if (f.name in FourthSection) {
            setFieldValue(f, FourthSection);
        } else if (f.name in FifthSection) {
            setFieldValue(f, FifthSection);
        } else if (f.name in SixthSection) {
            setFieldValue(f, SixthSection);
        } else if (f.name in ThirteenSection) {
            setFieldValue(f, ThirteenSection);
        } else if (f.name in FourteenthSection) {
            setFieldValue(f, FourteenthSection);
        } else if (f.name in FifteenthSection) {
            setFieldValue(f, FifteenthSection);
        }
    });

    return {
        SecondSection,
        ThirdSection,
        FourthSection,
        FifthSection,
        SixthSection,
        ThirteenSection,
        FourteenthSection,
        FifteenthSection
    }
}

function setFieldValue(f: IFormFiledInterface, section: any) {
    if (f.type == "boolean") {
        section[f.name] = JSON.parse(f.value);
    } else if (f.type == "string") {
        section[f.name] = f.value?.trim();
    } else if (f.type == "object") {
        section[f.name] = JSON.parse(f.value);
    }
}