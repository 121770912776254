import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "./AddressChangeForm.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { DatePickerChangeEvent, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, RadioGroupChangeEvent, Input } from "@progress/kendo-react-inputs";
import { FormValidation } from "../InputValidator";
import { Error, Label } from "@progress/kendo-react-labels";
import { PhoneNumberInput } from "../CustomInputs/PhoneNumberInputs/PhoneNumberInput";
import { StatesInput } from "../CustomInputs/StatesInput";
import { ZipCodeInput } from "../CustomInputs/ZipCodeInput";
import { IAddressChangeFormSecondSection } from "../../../../../interfaces/forms/AddressChangeForm/address-change-form-second-section.interface";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IAddressChangeFormSecondSection) => void;
    formDataFields?:  IAddressChangeFormSecondSection,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function AddressChangeFormSecondSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, isAdminPreview, setFormIsValid } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={2} name={"Account Information"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string
    )
    {
        setFormDataFields({
            ...formDataFields,
            [field]: event.value
        });
    }

    const [phoneNumberInputValid, setPhoneNumberInputValid] = useState<boolean>(false);
    const [physicalStateInputValid, setPhysicalStateInputValid] = useState<boolean>(false);
    const [physicalZipCode, setPhysicalZipCode] = useState<boolean>(false);
    const { validationMessage, inputValidator } = FormValidation(formRef);

    function changeValidator() {
        if (formRef.current) {
            const isValid = formRef.current.checkValidity() &&
                phoneNumberInputValid &&
                physicalStateInputValid &&
                physicalZipCode;
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if(!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current, phoneNumberInputValid,
        physicalStateInputValid, physicalZipCode]);

    return (
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={`${classes.item}`}>
                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                <div className={classes.fieldsGroupW50}>
                                    <Label className={classes.Label}>
                                        Property Owner Name
                                        {!formDataFields?.PropertyOwnerName && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={true}
                                        name={"PropertyOwnerName"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.PropertyOwnerName}
                                        onChange={(e)=> changeValueHandler(e, "PropertyOwnerName")}
                                    />
                                    {!inputValidator("PropertyOwnerName") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={classes.fieldsGroupW50}>
                                    <Label className={classes.Label}>
                                        Phone Number
                                        {!formDataFields?.PhoneNumber && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                    <PhoneNumberInput
                                        required={true}
                                        setFormDataFields={setFormDataFields}
                                        fieldName={"PhoneNumber"}
                                        formDataFields={formDataFields}
                                        isReadOnly={formSettings?.isReadOnly}
                                        setIsValidField={setPhoneNumberInputValid}
                                        validField={phoneNumberInputValid}
                                    />
                                </div>
                                <div className={classes.fieldsGroupW50}>
                                    <Label className={classes.Label}>
                                        Account Number (PID) (If you do not know your Property ID, please put N/A)
                                        {!formDataFields?.PropertyId && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={true}
                                        name={"PropertyId"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.PropertyId}
                                        onChange={(e)=> changeValueHandler(e, "PropertyId")}
                                    />
                                    {!inputValidator("PropertyId") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={classes.fieldsGroupW50}>
                                    <Label className={classes.Label}>
                                        Legal Description
                                        {!formDataFields?.LegalDescription && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={true}
                                        name={"LegalDescription"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.LegalDescription}
                                        onChange={(e)=> changeValueHandler(e, "LegalDescription")}
                                    />
                                    {!inputValidator("LegalDescription") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                            </div>
                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                <Label className={classes.Label}>
                                    Address Line 1
                                    {!formDataFields?.PhysicalAddressLine1 && (
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    )}
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"PhysicalAddressLine1"}
                                    required={true}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.PhysicalAddressLine1}
                                    onChange={(e)=> changeValueHandler(e, "PhysicalAddressLine1")}
                                />
                                {!inputValidator("PhysicalAddressLine1") && (
                                    <Error >{validationMessage}</Error>
                                )}
                            </div>
                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                <Label className={classes.Label}>
                                    Address Line 2
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"PhysicalAddressLine2"}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.PhysicalAddressLine2}
                                    onChange={(e)=> changeValueHandler(e, "PhysicalAddressLine2")}
                                />
                            </div>
                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                <div className={`${classes.fieldsGroupW50}`}>
                                    <Label className={classes.Label}>
                                        City
                                        {!formDataFields?.PhysicalAddressCity && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={true}
                                        name={"PhysicalAddressCity"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.PhysicalAddressCity}
                                        onChange={(e)=> changeValueHandler(e, "PhysicalAddressCity")}
                                    />
                                    {!inputValidator("PhysicalAddressCity") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={`${classes.fieldsGroupW20}`}>
                                    <Label className={classes.Label}>
                                        State
                                        {!formDataFields?.PhysicalAddressState && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                    <StatesInput
                                        setFormDataFields={setFormDataFields}
                                        fieldName={"PhysicalAddressState"}
                                        required={true}
                                        validField={physicalStateInputValid}
                                        setIsValidField={setPhysicalStateInputValid}
                                        formDataFields={formDataFields}
                                        isReadOnly={formSettings?.isReadOnly}
                                    />
                                    {!inputValidator("PhysicalAddressState") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={`${classes.fieldsGroupW20}`}>
                                    <Label className={classes.Label}>
                                        Zip Code
                                        {!formDataFields?.PhysicalAddressZip && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                    <ZipCodeInput
                                        setFormDataFields={setFormDataFields}
                                        fieldName={"PhysicalAddressZip"}
                                        required={true}
                                        formDataFields={formDataFields}
                                        isReadOnly={formSettings?.isReadOnly}
                                        setIsValidField={setPhysicalZipCode}
                                        validField={physicalZipCode}
                                    />
                                    {!inputValidator("PhysicalAddressZip") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    )
}