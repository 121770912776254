export type IFreeportFormThirdSectionInterface = {
    PropertyId?: string;
    LegalDescription?: string;
    PropertyAddressLine1?: string;
    PropertyAddressLine2?: string;
    PropertyAddressCity?: string;
    PropertyAddressState?: string;
    PropertyAddressZip?: string;
}

export const FreeportFormThirdSectionInterface = (): IFreeportFormThirdSectionInterface => ({
    PropertyId: undefined,
    LegalDescription: undefined,
    PropertyAddressLine1: undefined,
    PropertyAddressLine2: undefined,
    PropertyAddressCity: undefined,
    PropertyAddressState: undefined,
    PropertyAddressZip: undefined
})