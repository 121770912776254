import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "./BPPForm.module.scss";
import { SectionInformationTitle } from "../Shared/SectionTitle";

export default function BPPFormFirstSection(): JSX.Element {
    const title = <SectionInformationTitle number={1} name={"Form Information"}/>;
    return (
       <>
           <PanelBar keepItemsMounted={true}>
               <PanelBarItem title={title} expanded={true}>
                   <div className="container">
                       <div className={classes.information}>
                           <blockquote>
                               <p>
                                   <small>
                                       This document must be filed with the appraisal district office in the county in which your property is taxable. It must not be filed with the Comptroller of Public Accounts. Location and address information for appraisal district offices may be found at comptroller.texas.gov/propertytax/references/directory/cad.
                                   </small>
                               </p>
                           </blockquote>
                       </div>

                       <PanelBar keepItemsMounted={true} >
                           <PanelBarItem title={<div className={`${classes.informationTitle}`}>
                               <p>IMPORTANT INFORMATION</p>
                           </div>} expanded={true}>
                               <div className={classes.information}>
                                   <div className="item">
                                       <p className="ng-scope"><b>GENERAL INFORMATION:</b> This form is for use in rendering, pursuant to
                                           Tax Code
                                           Section 22.01, tangible personal property used for the production of income that
                                           you own or manage and control as a fiduciary on Jan. 1 of this year. This report is
                                           confidential and not open to public inspection; disclosure is permitted pursuant to
                                           the terms of Tax Code Section 22.27.</p>
                                       <p className="ng-scope"><b>FILING INSTRUCTIONS:</b> This document and all supporting documentation
                                           must be
                                           filed with the appraisal district office in the county in which the property is taxable. <b>Do
                                               not file this document with the Texas Comptroller of Public Accounts.</b> Contact
                                           information for appraisal district offices may be found on the Comptroller’s website.</p>
                                       <p className="ng-scope"><b>DEADLINES:</b> Rendition statements and property report deadlines depend
                                           on property
                                           type. The statements and reports must be delivered to the chief appraiser after Jan. 1
                                           and no later than the deadline indicated below. On written request by the property
                                           owner, the chief appraiser shall extend a deadline for filing a rendition statement or
                                           property report to May 15. The chief appraiser may further extend the deadline an
                                           additional 15 days upon good cause shown in writing by the property owner</p>
                                       <table className="blue">
                                           <thead>
                                           <tr>
                                               <th className={classes.width40}>
                                                   Rendition Statements and Reports
                                               </th>
                                               <th >
                                                   Deadlines
                                               </th>
                                               <th>
                                                   Allowed Extension(s)
                                               </th>
                                           </tr>
                                           </thead>
                                           <tbody>
                                           <tr>
                                               <td >
                                                   Property generally
                                               </td>
                                               <td>
                                                   <b>April 15</b>
                                               </td>
                                               <td>
                                                   <ul>
                                                       <li>
                                                           May 15 upon written request
                                                       </li>
                                                       <li>
                                                           Additional 15 days for good cause shown
                                                       </li>
                                                   </ul>
                                               </td>
                                           </tr>
                                           <tr>
                                               <td >
                                                   Property regulated by the Public Utility
                                                   Commission of Texas, the Railroad Commission
                                                   of Texas, the federal Surface Transportation
                                                   Board or the Federal Energy Regulatory
                                                   Commission. Tax Code Section 22.23(d).
                                               </td>
                                               <td>
                                                   <b>April 30</b>
                                               </td>
                                               <td>
                                                   <ul>
                                                       <li>
                                                           May 15 upon written request
                                                       </li>
                                                       <li>
                                                           Additional 15 days for good cause shown
                                                       </li>
                                                   </ul>
                                               </td>
                                           </tr>
                                           </tbody>
                                       </table>
                                       <p className="ng-scope"><b>EXEMPTION:</b>
                                           A person is entitled to an exemption from taxation of the tangible personal
                                           property that is held or used for the production of income if it has less
                                           than $2,500
                                           of taxable value (Tax Code Section 11.145).
                                           <br />
                                           If an exemption is denied or terminated on a property, the owner must render it for
                                           taxation within 30 days from the denial or termination. (Tax Code sections 21.01(a)
                                           and 22.02)</p>
                                       <p className="ng-scope"><b>PENALTIES:</b> The chief appraiser must impose a penalty on a person who
                                           fails to
                                           timely file a required rendition statement or property report in an amount equal to
                                           10 percent of the total amount of taxes imposed on the property for that year by
                                           taxing units participating in the appraisal district. The chief appraiser must impose
                                           an additional penalty on the person equal to 50 percent of the total amount of
                                           taxes imposed on the property for the tax year of the statement or report by the
                                           taxing units participating in the appraisal district if it is finally determined by a court
                                           that:</p>
                                       <ol className="ng-scope">
                                           <li>
                                               the person filed a false statement or report with the intent to commit fraud
                                               or to evade the tax; or
                                           </li>
                                           <li>
                                               the person alters, destroys or conceals any record, document or thing, or
                                               presents to the chief appraiser any altered or fraudulent record, document
                                               or thing, or otherwise engages in fraudulent conduct, for the purpose
                                               of affecting the course or outcome of an inspection, investigation,
                                               determination or other proceeding before the appraisal district.

                                           </li>
                                       </ol>
                                   </div>
                               </div>
                           </PanelBarItem>
                           <PanelBarItem title={ <div className={`${classes.informationTitle}`}>
                               <p>DEFINITIONS</p>
                           </div>} expanded={true}>
                               <div className={classes.information}>
                                   <div className="item">

                                       <div id="collapsablePanelRawHtml" className="" ><p className="ng-scope"><b>Address Where
                                           Taxable:</b> In some instances, personal property that is only
                                           temporarily at its current address may be taxable at another location (taxable situs).
                                           If you know that this is the case, please list the address where taxable.
                                       </p>

                                           <p className="ng-scope"><b>Consigned Goods:</b> Personal property owned by another person that
                                               you are selling
                                               by arrangement with that person. If you have consigned goods, report the name
                                               and address of the owner in the appropriate blank.</p>

                                           <p className="ng-scope"><b>Estimate of Quantity:</b> For each type or category listed, the number
                                               of items or
                                               other relevant measure of quantity (e.g., gallons, bushels, tons, pounds, board feet).</p>

                                           <p className="ng-scope"><b>Fiduciary:</b> A person or institution who manages property for
                                               another and who
                                               must exercise a standard of care in such management activity imposed by law or
                                               contract.</p>

                                           <p className="ng-scope"><b>Good Faith Estimate of Market Value:</b> Your best estimate of what
                                               the property
                                               would have sold for in U.S. dollars on Jan. 1 of the current tax year if it had been on
                                               the market for a reasonable length of time and neither you nor the purchaser was
                                               forced to buy or sell. For inventory, it is the price for which the property would have
                                               sold as a unit to a purchaser who would continue the business.</p>

                                           <p className="ng-scope"><b>Historical Cost When New:</b> What you paid for the property when it
                                               was new or, if
                                               you bought the property used, what the original buyer paid when it was new. If you
                                               bought the property used and do not know what the original buyer paid, state what
                                               you paid with a note that you purchased it used.</p>

                                           <p className="ng-scope"><b>Inventory:</b> Personal property that is held for sale in the ordinary
                                               course of a trade
                                               or business.</p>

                                           <p className="ng-scope"><b>Personal Property:</b> Every kind of property that is not real
                                               property; generally,
                                               property that is movable without damage to itself or the associated real property.</p>

                                           <p className="ng-scope"><b>Property Address:</b> The physical address of the personal property on
                                               Jan. 1 of the
                                               current tax year. Normally, the property is taxable by the taxing unit where the
                                               property is located.</p>

                                           <p className="ng-scope"><b>Secured Party:</b> A person in whose favor a security interest is
                                           created or provided
                                           for under a security agreement; see Business and Commerce Code Section 9.102 for
                                           further details.</p>

                                           <p className="ng-scope"><b>Security Interest:</b> An interest in personal property or fixtures
                                               which secured
                                               payment or performance of an obligation see Business and Commerce Code Section
                                               1.201 for further details.</p>

                                           <p className="ng-scope"><b>Type/Category:</b> Functionally similar personal property groups.
                                               Examples are:
                                               furniture, fixtures, machinery, equipment, vehicles and supplies. Narrower
                                               groupings such as personal computers, milling equipment, freezer cases and
                                               forklifts should be used, if possible. A person is not required to render for taxation
                                               personal property appraised under Tax Code Section 23.24.</p>

                                           <p className="ng-scope"><b>Year Acquired:</b> The year that you purchased the property, or
                                               otherwise acquired.</p></div>
                                   </div>
                               </div>
                           </PanelBarItem>
                       </PanelBar>
                   </div>
               </PanelBarItem>
           </PanelBar>
       </>
    )
}