import type { Dispatch } from "react";
import type { GenericActionType } from "src/context/generic-actions.type";
import { MessagingSearchType } from "src/enums/dialog-type.enum";
import { IDialog } from "src/interfaces/messages/dialogs/dialog.interface";
import { PublicActionsEnum } from "./public-actions";

export interface PublicContextType {
  publicState: InitialPublicStateType;
  publicDispatch: Dispatch<GenericActionType<PublicType>>;
}

export interface InitialPublicStateType {
  errorMessage: string | null;
  isLoading: boolean;
  dialogType: MessagingSearchType | null;
  showFormDialog: boolean;
  showCreateDialog: boolean;
  showUserAgreement: boolean;
  showElectronicUserAgreement: boolean;
  electronicUserAgreementPdf: Blob | null;
  helpRequestDialog: IDialog | null;
}

export interface PublicType {
  errorMessage?: string;
  isLoading?: boolean;
  dialogType?: MessagingSearchType;
  showCreateDialog?: boolean;
  showFormDialog?: boolean;
  showSignInModal?: boolean;
  showRegisterModal?: boolean;
  showForgotPasswordModal?: boolean;
  showResendEmailModal?: boolean;
  showUserAgreement?: boolean;
  showElectronicUserAgreement?: boolean;
  electronicUserAgreementPdf?: Blob;
  helpRequestDialog?: IDialog;
}

export const initialState: InitialPublicStateType = {
  errorMessage: "",
  isLoading: false,
  dialogType: null,
  showFormDialog: false,
  showCreateDialog: false,
  showUserAgreement: false,
  showElectronicUserAgreement: false,
  electronicUserAgreementPdf: null,
  helpRequestDialog: null,
};

export const PublicReducer = (
  initialState: InitialPublicStateType,
  action: GenericActionType<PublicType>
): InitialPublicStateType => {
  switch (action.type) {
    case PublicActionsEnum.IS_LOADING:
      return {
        ...initialState,
        isLoading: action.isLoading ?? false,
      };
    case PublicActionsEnum.DIALOG_TYPE:
      return {
        ...initialState,
        dialogType: action.payload?.dialogType ?? null,
      };
    case PublicActionsEnum.ERROR:
      return {
        ...initialState,
        isLoading: false,
        errorMessage: action.error ?? null,
      };
    case PublicActionsEnum.SHOW_CREATE_DIALOG:
      return {
        ...initialState,
        showCreateDialog: action.payload?.showCreateDialog ?? false,
      };
    case PublicActionsEnum.SHOW_FORM_DIALOG:
      return {
        ...initialState,
        showFormDialog: action.payload?.showFormDialog ?? false,
      };

    case PublicActionsEnum.SHOW_ELECTRONIC_USER_AGREEMENT:
      return {
        ...initialState,
        showElectronicUserAgreement:
          action.payload?.showElectronicUserAgreement ?? false,
      };
    case PublicActionsEnum.SHOW_USER_AGREEMENT:
      return {
        ...initialState,
        showUserAgreement: action.payload?.showUserAgreement ?? false,
      };
    case PublicActionsEnum.HELP_REQUEST_DIALOG:
      return {
        ...initialState,
        helpRequestDialog: action.payload?.helpRequestDialog ?? null,
      };
    case PublicActionsEnum.ELECTRONIC_USER_AGREEMENT_PDF:
      return {
        ...initialState,
        electronicUserAgreementPdf:
          action.payload?.electronicUserAgreementPdf ?? null,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
