import classes from "../BPP-Form-PDF-style.module.scss";
import shared from "../../PDF-Shared-style.module.scss";
import { GetBlankRows } from "../../BlankRows";
import { chunkArray } from "src/utils/array";
import { IBppFormTwelfthSectionInterface, IScheduleF } from "src/interfaces/forms/BPPForm/bpp-form-twelfth-section.interface";

const maxRowsPerPage = 34;

export const ScheduleF = (props: {
  formDataFieldsTwelfthSection?: IBppFormTwelfthSectionInterface;
}) => {
  const { formDataFieldsTwelfthSection } = props;

  const [primaryTable, ...additionalTables] = chunkArray(
    formDataFieldsTwelfthSection?.ScheduleF || [],
    maxRowsPerPage
  );

  return (
    <>
      <div className={`keepTogether ${classes.ScheduleSection} ${shared.PrintTogether}`}>
        <div className={shared.Step}>
          SCHEDULE F: PROPERTY UNDER BAILMENT, LEASE, CONSIGNMENT OR OTHER
          ARRANGEMENT
        </div>
        <div className={shared.Row}>
          <div className={shared.Column12}>
            <div>
              List the name and address of each owner of taxable property that is
              in your possession or under your management on Jan. 1 by bailment,
              lease, consignment or other arrangement. If needed, attach
              additional sheets OR a computer-generated copy listing the
              information below.
            </div>
          </div>

          <ScheduleFTable rows={primaryTable} blankRows={3} />

          {additionalTables.length === 0 && <Note />}
        </div>
      </div>

      {additionalTables.map((rows, index) => (
        <div
          className={`keepTogether ${classes.ScheduleSection} ${shared.PrintTogether}`}
        >
          <div className={shared.Row}>
            <ScheduleFTable rows={rows} blankRows={0} />
            {(index == additionalTables.length - 1) && <Note />}
          </div>
        </div>
      ))}
    </>
  );
};

const ScheduleFTable = (props: { rows?: IScheduleF[]; blankRows: number }) => {
  const { rows, blankRows } = props;

  return (
    <>
      <div className={shared.Column12}>
        <table className={shared.Blue}>
          <tr>
            <th>Property Owner&apos;s Name</th>
            <th>Property Owner&apos;s Address</th>
            <th>General Property Description</th>
          </tr>
          {rows?.filter(x=> !x.HasActiveSubSegments)?.map((item) => {
            return (
              <tr key={item.Id}>
                <td>{item?.OwnerName}</td>
                <td>{item?.OwnerAddress}</td>
                <td>{item?.Description}</td>
              </tr>
            );
          })}
          {GetBlankRows(rows?.length, blankRows)}
        </table>
      </div>
    </>
  );
};

const Note = () => {
  return (
    <div className={shared.Column12}>
      <div className={shared.Font8}>
        <p>
        * Provide an amount for either the good faith estimate of market value,
        or a historical cost when new and year acquired.
        If you provided an historical cost when new and year acquired,
        you need not provide a good faith estimate of market value
        </p>
      </div>
    </div>
  )
}
