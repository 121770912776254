import {MaskedTextBox, MaskedTextBoxChangeEvent} from "@progress/kendo-react-inputs";
import classes from "../custom-field.module.scss";
import {useEffect, useRef, useState, useMemo} from "react";
import {Error} from "@progress/kendo-react-labels";
import {PhoneNumberInputErrorsHandler, PhoneNumberInputMask} from "./UpdatePhoneNumberErrorsState";

interface PhoneNumberInput {
    isReadOnly?: boolean;
    setFormDataFields?: (form: any) => void;
    formDataFields?: any;
    fieldName?: string;
    value?: string;
    required: boolean;
    setIsValidField?: (item: boolean) => void;
    validField?: boolean;
    placeholder?: string;
}

export function PhoneNumberInput(props: PhoneNumberInput) :JSX.Element {
    const { isReadOnly, formDataFields, setFormDataFields, fieldName, required, value, setIsValidField, validField = true, placeholder} = props;
    const [errors, setErrors] = useState<{required: boolean, pattern: boolean}>({
        pattern: required,
        required: required,
    });

    const fieldValue = useMemo(() => {
        if (value != undefined) {
            return value
        }

        if (formDataFields && fieldName) {
            return formDataFields[fieldName]
        }

        return undefined;
    }, [value, formDataFields, fieldName]);

    const changeValueHandler = (
        event: MaskedTextBoxChangeEvent,
        field: string) => {

        PhoneNumberInputErrorsHandler({
            errors: errors,
            eventValue: event.value,
            required: required,
            setErrors: setErrors
        })

        setFormDataFields?.({
            ...formDataFields,
            [field]: event.value
        });
    }

    useEffect(() => {
        if (!fieldValue) return;
        PhoneNumberInputErrorsHandler({
            errors: errors,
            eventValue: fieldValue,
            required: required,
            setErrors: setErrors
        })
    }, [fieldValue]);

    useEffect(() => {
        return () => {
            if(setIsValidField){
                setIsValidField(true);
            }
        }
    },[]);

    useEffect(() => {
        if ((errors.pattern || errors.required) && setIsValidField){
            setIsValidField(false);
        } else if(!required && setIsValidField){
            setIsValidField(true);
        } else if (setIsValidField){
            setIsValidField(true);
        }
    }, [errors, fieldValue]);

    return <>
        <MaskedTextBox
            valid={validField}
            required={required}
            name={fieldName}
            className={!validField ? `${classes.input} ${classes.invalidInput}` : classes.input}
            placeholder={placeholder}
            mask={PhoneNumberInputMask}
            value={fieldValue}
            disabled={isReadOnly}
            onChange={(e)=> fieldName && changeValueHandler(e, fieldName)}
        />
        {errors.pattern && <Error >{"Please enter valid Phone Number!"}</Error>}
    </>
}
