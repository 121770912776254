import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "./FreeportForm.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { DatePickerChangeEvent, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, RadioGroupChangeEvent, RadioGroup, Checkbox, Input } from "@progress/kendo-react-inputs";
import { FormValidation } from "../InputValidator";
import { Error, Label } from "@progress/kendo-react-labels";
import { CustomDatePicker } from "../CustomInputs/CustomDatePickers/CustomDatePicker";
import { IFreeportFormFirstSectionInterface } from "src/interfaces/forms/FreeportForm/freeport-form-first-section.interface";
import { EmailInput } from "../CustomInputs/EmailInputs/EmailInput";
import { PhoneNumberInput } from "../CustomInputs/PhoneNumberInputs/PhoneNumberInput";
import { StatesInput } from "../CustomInputs/StatesInput";
import { ZipCodeInput } from "../CustomInputs/ZipCodeInput";
import { CustomOnlyYearCalendarHelper } from "src/pages/Shared/DatePickers/CustomCalendars";
import SectionTitle from "../Shared/SectionTitle";
import { defaultRadioButtonData } from "../Shared/RadioButtonData";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IFreeportFormFirstSectionInterface) => void;
    formDataFields?:  IFreeportFormFirstSectionInterface,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function FreeportFormFirstSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, isAdminPreview, setFormIsValid } = props;
    const { CustomOnlyYearCalendar } = CustomOnlyYearCalendarHelper();
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const [taxYearInputValid, setTaxYearInputValid] = useState<boolean>(false);
    const [emailInputValid, setEmailInputValid] = useState<boolean>(false);
    const [phoneNumberInputValid, setPhoneNumberInputValid] = useState<boolean>(false);
    const [physicalStateInputValid, setPhysicalStateInputValid] = useState<boolean>(false);
    const [mailingStateInputValid, setMailingStateInputValid] = useState<boolean>(false);
    const [physicalZipCode, setPhysicalZipCode] = useState<boolean>(false);
    const [mailingZipCode, setMailingZipCode] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator } = FormValidation(formRef);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={1} name={"Property Owner/Applicant"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string
    )
    {
        if (field == "TaxYear") {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value ? event.value.getFullYear().toString() : undefined
            });
        } else if (field == "ApplicantOwnedPropertyJan1") {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value ? JSON.parse(event.value) : undefined
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    function changeValidator() {
        if (formRef.current) {
            const isValid = formRef.current.checkValidity() &&
                phoneNumberInputValid &&
                emailInputValid &&
                taxYearInputValid &&
                physicalStateInputValid &&
                mailingStateInputValid &&
                physicalZipCode &&
                mailingZipCode;
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if(!isFormTouched){
            setIsFormTouched(true);
        }
    }

    const saveAsPhysicalAddressHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        setFormDataFields({
            ...formDataFields,
            MailingAddressLine1: formDataFields?.PhysicalAddressLine1,
            MailingAddressLine2: formDataFields?.PhysicalAddressLine2,
            MailingAddressCity: formDataFields?.PhysicalAddressCity,
            MailingAddressState: formDataFields?.PhysicalAddressState,
            MailingAddressZip: formDataFields?.PhysicalAddressZip,
        });
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current, phoneNumberInputValid, emailInputValid, 
        taxYearInputValid, physicalStateInputValid, mailingStateInputValid,
        physicalZipCode, mailingZipCode]);

    return (
       <>
            <div className={classes.information}>
                <div>
                    <p>
                        <b>GENERAL INSTRUCTIONS:</b> 
                        This application is for use in claiming a property tax exemption for freeport goods 
                        pursuant to Texas Constitution Article 8, Section 1-j and Tax Code Section 11.251. 
                        
                    </p>
                </div>
                <div>
                    <p>
                        <b>FILING INSTRUCTIONS:</b> This form and all supporting documentation must be filed with the 
                        appraisal district office in each county in which the property is taxable.Do not file this document with the Texas Comptroller of Public Accounts. 
                    </p>
                </div>
               
            </div>
            <PanelBar keepItemsMounted={true}>
                <PanelBarItem title={title} expanded={true}>
                    <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                        <div className="container">
                            <div className={classes.item}>
                                <div className={`${classes.itemFields} ${classes.itemFieldsLeft} ${classes.bordered}`}>
                                    <div className={`${classes.fieldsGroup} ${classes.fieldsGroupLeft}`}>
                                        <Label className={classes.Label}>
                                            Tax Year
                                            {!formDataFields?.TaxYear && (
                                                <i className={"fa fa-asterisk text-danger"}></i>
                                            )}
                                        </Label>
                                        <div className="inputsGroup">
                                            <CustomDatePicker
                                                fieldName={"TaxYear"}
                                                required={true}
                                                disabled={formSettings?.isReadOnly}
                                                formDataFields={formDataFields}
                                                setFormDataFields={setFormDataFields}
                                                onlyYear={true}
                                                CustomCalendar={CustomOnlyYearCalendar}
                                                setIsValidField={setTaxYearInputValid}
                                                validField={taxYearInputValid}
                                                defaultValueDate={formDataFields?.TaxYear}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={`${classes.itemFields} ${classes.bordered}`}>
                                    <p>
                                        Did the applicant own the property that is the subject of this application on Jan. 1 of the tax year?
                                    </p>
                                    <div className={classes.fieldsGroup}>
                                        <div className={classes.checkboxGroup}>
                                            <RadioGroup
                                                layout={"horizontal"}
                                                disabled={formSettings?.isReadOnly}
                                                data={defaultRadioButtonData}
                                                onChange={e => changeValueHandler(e, "ApplicantOwnedPropertyJan1")}
                                                value={formDataFields?.ApplicantOwnedPropertyJan1?.toString()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={`${classes.itemFields} ${classes.bordered}`}>
                                    <div className={classes.fieldsGroup}>
                                        <Label className={classes.Label}>
                                            The applicant is the following type of property owner:
                                        </Label>
                                        <div className={classes.inputsGroup}>
                                            <Checkbox
                                                name={"individual"}
                                                className={classes.Checkbox}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.individual}
                                                label={"Individual"}
                                                onChange={(e)=> changeValueHandler(e, "individual")}
                                            />
                                            <Checkbox
                                                name={"corporation"}
                                                className={classes.Checkbox}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.corporation}
                                                label={"Corporation"}
                                                onChange={(e)=> changeValueHandler(e, "corporation")}

                                            />
                                            <Checkbox
                                                name={"partnership"}
                                                className={classes.Checkbox}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.partnership}
                                                label={"Partnership"}
                                                onChange={(e)=> changeValueHandler(e, "partnership")}
                                            />
                                            <Checkbox
                                                name={"other"}
                                                className={classes.Checkbox}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.other}
                                                label={"Other"}
                                                onChange={(e)=> changeValueHandler(e, "other")}
                                            />
                                            {formDataFields?.other &&
                                                <div className="inputsGroup">
                                                    <Label className={classes.Label}>
                                                        Type of Property Owner (Other):
                                                        {!formDataFields?.OwnershipTypeOther && (
                                                            <i className={"fa fa-asterisk text-danger"}></i>
                                                        )}
                                                    </Label>
                                                    <Input
                                                        className={classes.kTextbox}
                                                        name={"OwnershipTypeOther"}
                                                        placeholder={"Type of Property Owner (Other)"}
                                                        required={formDataFields?.other}
                                                        disabled={formSettings?.isReadOnly}
                                                        value={formDataFields?.OwnershipTypeOther}
                                                        onChange={(e)=> changeValueHandler(e, "OwnershipTypeOther")}
                                                    />
                                                    {!inputValidator("OwnershipTypeOther") && (
                                                        <Error >{validationMessage}</Error>
                                                    )}
                                                </div> 
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.item} ${classes.bordered}`}>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Name of Property Owner
                                        {!formDataFields?.NameOfPropertyOwner && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={true}
                                        name={"NameOfPropertyOwner"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.NameOfPropertyOwner}
                                        onChange={(e)=> changeValueHandler(e, "NameOfPropertyOwner")}
                                    />
                                    {!inputValidator("NameOfPropertyOwner") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Primary Phone Number (area code and number)
                                    </Label>
                                    <PhoneNumberInput
                                        required={false}
                                        setFormDataFields={setFormDataFields}
                                        fieldName={"PrimaryPhoneNumber"}
                                        formDataFields={formDataFields}
                                        isReadOnly={formSettings?.isReadOnly}
                                        setIsValidField={setPhoneNumberInputValid}
                                        validField={phoneNumberInputValid}
                                    />
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Email Address
                                    </Label>
                                    <EmailInput
                                        required={false}
                                        setFormDataFields={setFormDataFields}
                                        fieldName={"EmailAddress"}
                                        formDataFields={formDataFields}
                                        isReadOnly={formSettings?.isReadOnly}
                                        setIsValidField={setEmailInputValid}
                                        validField={emailInputValid}
                                    />
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Driver’s License, Personal I.D. Certificate Social Security Number or Federal Tax I.D. Number
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={true}
                                        name={"PersonalId"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.["PersonalId"]}
                                        onChange={(e)=> changeValueHandler(e, "PersonalId")}
                                    />
                                    {!inputValidator("PersonalId") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                            </div>
                            <div className={`${classes.item} ${classes.bordered}`}>
                                <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                    <p>Physical Address</p>
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Physical Address Line 1
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"PhysicalAddressLine1"}
                                        required={true}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.PhysicalAddressLine1}
                                        onChange={(e)=> changeValueHandler(e, "PhysicalAddressLine1")}
                                    />
                                    {!inputValidator("PhysicalAddressLine1") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Physical Address Line 2
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"PhysicalAddressLine2"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.PhysicalAddressLine2}
                                        onChange={(e)=> changeValueHandler(e, "PhysicalAddressLine2")}
                                    />
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={`${classes.fieldsGroupW50}`}>
                                        <Label className={classes.Label}>
                                            City
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            required={true}
                                            name={"PhysicalAddressCity"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.PhysicalAddressCity}
                                            onChange={(e)=> changeValueHandler(e, "PhysicalAddressCity")}
                                        />
                                        {!inputValidator("PhysicalAddressCity") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                    <div className={`${classes.fieldsGroupW20}`}>
                                        <Label className={classes.Label}>
                                            State
                                        </Label>
                                        <StatesInput
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"PhysicalAddressState"}
                                            required={true}
                                            validField={physicalStateInputValid}
                                            setIsValidField={setPhysicalStateInputValid}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                        />
                                        {!inputValidator("PhysicalAddressState") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                    <div className={`${classes.fieldsGroupW20}`}>
                                        <Label className={classes.Label}>
                                            Zip Code
                                        </Label>
                                        <ZipCodeInput
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"PhysicalAddressZip"}
                                            required={true}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                            setIsValidField={setPhysicalZipCode}
                                            validField={physicalZipCode}
                                        />
                                        {!inputValidator("PhysicalAddressZip") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.item} ${classes.bordered}`}>
                                <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                    <p>Mailing Address</p>
                                    { !formSettings?.isReadOnly && !isAdminPreview &&
                                        <button className={`${classes.button} ${classes.buttonAddress}`} onClick={e => saveAsPhysicalAddressHandler(e)}>
                                            Copy Physical Address
                                        </button> }
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Mailing Address Line 1
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={true}
                                        name={"MailingAddressLine1"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.MailingAddressLine1}
                                        onChange={(e)=> changeValueHandler(e, "MailingAddressLine1")}
                                    />
                                    {!inputValidator("MailingAddressLine1") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Mailing Address Line 2
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"MailingAddressLine2"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.MailingAddressLine2}
                                        onChange={(e)=> changeValueHandler(e, "MailingAddressLine2")}
                                    />
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={`${classes.fieldsGroupW50}`}>
                                        <Label className={classes.Label}>
                                            City
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            required={true}
                                            name={"MailingAddressCity"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.MailingAddressCity}
                                            onChange={(e)=> changeValueHandler(e, "MailingAddressCity")}
                                        />
                                        {!inputValidator("MailingAddressCity") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                    <div className={`${classes.fieldsGroupW20}`}>
                                        <Label className={classes.Label}>
                                            State
                                        </Label>
                                        <StatesInput
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"MailingAddressState"}
                                            required={true}
                                            validField={mailingStateInputValid}
                                            setIsValidField={setMailingStateInputValid}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                        />
                                        {!inputValidator("MailingAddressState") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                    <div className={`${classes.fieldsGroupW20}`}>
                                        <Label className={classes.Label}>
                                            Zip Code
                                        </Label>
                                        <ZipCodeInput
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"MailingAddressZip"}
                                            required={true}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                            setIsValidField={setMailingZipCode}
                                            validField={mailingZipCode}
                                        />
                                        {!inputValidator("MailingAddressZip") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                   </form>
                </PanelBarItem>
            </PanelBar>
       </>
    )
}