import instance from "src/axios-instance";
import type { ICreateUser } from "src/interfaces/admin/create-user.interface";
import type { IUser } from "src/interfaces/admin/user.interface";
import type { IChangePassword } from "src/interfaces/public/change-password.interface";
import type { IUpdateEmail } from "src/interfaces/public/update-email.interface";
import type { IResetPassword } from "src/interfaces/reset-password.interface";
import type { ITableActions } from "src/interfaces/table-actions.interface";
import type { UserSignAgreement } from "src/interfaces/user-sign-agreement.interface";

export function getUserInformation(): any {
  return instance.get(`users/me`);
}

export function updateEmail(data: IUpdateEmail): any {
  return instance.put("users/update-email", data);
}

export function changePassword(data: IChangePassword): any {
  return instance.put("users/change-password", data);
}

export function signUserAgreement(data: UserSignAgreement): any {
  return instance.post("users/sign-agreement", data);
}

export function getUserById(userId: number): any {
  return instance.get(`users/${userId}`);
}

export function getElectronicUserAgreementPdf(): any {
  return instance.get(`users/agreement-pdf`, { responseType: "arraybuffer" });
}

export function getAllUser(tableState: ITableActions): any {
  return instance.post("users", {
    Sort: tableState.sort,
    Filter: {
      filters: tableState.filters,
      logic: tableState.logic,
    },
    Paging: {
      page: tableState.page,
      size: tableState.pageSize,
      skip: tableState.skip,
      take: tableState.take,
    },
  });
}
export function getAllUserForExport(tableState: ITableActions): any {
  return instance.post("users", {
    Filter: {
      filters: tableState.filters,
      logic: tableState.logic,
    },
  });
}

export function createNewUser(data: ICreateUser): any {
  return instance.post(`users/create`, data);
}

export function updateUser(data: IUser): any {
  return instance.put(`users`, data);
}

export function updatePassword(data: IResetPassword, userId: number): any {
  return instance.put(`users/${userId}/reset-password`, data);
}

export function getAdmins(): any {
  return instance.get(`users/admins`);
}

export function sendPasswordResetEmail(email: string): any {
  return instance.post(`users/${email}/password-reset-email`);
}
