import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "src/pages/Home/components/Forms/TimberlandAppraisalForm/TimberlandAppraisal.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, RadioGroupChangeEvent, RadioGroup, Input, TextArea } from "@progress/kendo-react-inputs";
import { FormValidation } from "../InputValidator";
import { DatePickerChangeEvent, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import { ITimberlandAppraisalFormFourthSection } from "src/interfaces/forms/TimberlandAppraisalForm/timberland-appraisal-form-fourth-section.interface";
import SectionTitle from "../Shared/SectionTitle";
import { defaultRadioButtonData } from "../Shared/RadioButtonData";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: ITimberlandAppraisalFormFourthSection) => void;
    formDataFields?:  ITimberlandAppraisalFormFourthSection,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function TimberlandAppraisalFormFourthSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, setFormIsValid, isAdminPreview } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator, setStyles } = FormValidation(formRef);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={4} name={"Property Description and Information"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string,
        group?: string
    ) {
        if (group) {
            setFormDataFields({
                ...formDataFields,
                [group]: {
                    ...(formDataFields as any)?.[group],
                    [field]: event.value
                }
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    
    function changeValidator() {
        if (formRef.current) {
            const isValid = formRef.current.checkValidity();
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if (!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [
        formDataFields,
        formRef.current
    ]);

    return (
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                <div className={classes.fieldsGroupW50}>
                                    <Label className={classes.Label}>
                                        Account Number (if known)
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={false}
                                        name={"AccountNumber"}
                                        placeholder={"Account Number (if known)"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.AccountNumber}
                                        onChange={(e)=> changeValueHandler(e, "AccountNumber")}
                                    />
                                </div>
                                <div className={classes.fieldsGroupW50}>
                                    <Label className={classes.Label}>
                                        Number of Acres (subject to this application)
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={false}
                                        name={"NumberOfAcres"}
                                        placeholder={"Number of Acres (subject to this application)"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.NumberOfAcres}
                                        onChange={(e)=> changeValueHandler(e, "NumberOfAcres")}
                                    />
                                </div>
                                <div className={classes.fieldsGroupW100}>
                                    <Label className={classes.Label}>
                                        Legal description, abstract numbers, field numbers and/or plat numbers:
                                    </Label>
                                    <TextArea
                                        name={"LegalDescription"}
                                        required={false}
                                        className={classes.kTextarea}
                                        placeholder={"Legal description, abstract numbers, field numbers and/or plat numbers:"}
                                        autoSize={true}
                                        rows={2}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.LegalDescription}
                                        onChange={(e)=> changeValueHandler(e, "LegalDescription")}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classes.item}>
                            <p>
                                Select the appropriate box in response to each question below.
                            </p>
                            <div className={`${classes.itemFields}`}>
                                <Label className={classes.Label}>
                                    1. Has the ownership of the property changed since Jan. 1 of 
                                    last year or since the last application was submitted?
                                </Label>
                                <div className={classes.fieldsGroup}>
                                    <div className={classes.checkboxGroup}>
                                        <RadioGroup
                                            layout={"horizontal"}
                                            disabled={formSettings?.isReadOnly}
                                            data={defaultRadioButtonData}
                                            onChange={e => changeValueHandler(e, "HasPropertyOwnershipChanged")}
                                            value={formDataFields?.HasPropertyOwnershipChanged?.toString()}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.itemFields}`}>
                                <Label className={classes.Label}>
                                    2. Last year, was timberland appraisal allowed on this property by the 
                                    chief appraiser of this appraisal district?
                                </Label>
                                <div className={classes.fieldsGroup}>
                                    <div className={classes.checkboxGroup}>
                                        <RadioGroup
                                            layout={"horizontal"}
                                            disabled={formSettings?.isReadOnly}
                                            data={defaultRadioButtonData}
                                            onChange={e => changeValueHandler(e, "WasAppraisalAllowed")}
                                            value={formDataFields?.WasAppraisalAllowed?.toString()}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.itemFields}`}>
                                <Label className={classes.Label}>
                                    3. Is this property located within the corporate limits of a city or town?
                                </Label>
                                <div className={classes.fieldsGroup}>
                                    <div className={classes.checkboxGroup}>
                                        <RadioGroup
                                            layout={"horizontal"}
                                            disabled={formSettings?.isReadOnly}
                                            data={defaultRadioButtonData}
                                            onChange={e => changeValueHandler(e, "IsPropertyLocatedWithinCorporateLimits")}
                                            value={formDataFields?.IsPropertyLocatedWithinCorporateLimits?.toString()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    )
}