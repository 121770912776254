import { AxiosResponse } from "axios";
import instance from "src/axios-instance";
import type { ICustomFormField } from "src/interfaces/admin/custom-form-field";
import { TrueRollApplicationRefreshResponse } from "src/interfaces/admin/true-roll/true-roll-application.interface";
import { IFormEntryModel } from "src/interfaces/form-entry.interface";
import { IFormFiledInterface } from "src/interfaces/form-filed.interface";

export function deleteFormEntriesByIds(formEntriesIds: number[]): any {
  return instance.delete("form-entries/hard", { data: formEntriesIds });
}

export function softDeleteFormEntriesByIds(formEntriesIds: number[]): any {
  return instance.delete("form-entries/soft", { data: formEntriesIds });
}

export function restoreFormEntries(formEntriesIds: number[]): any {
  return instance.put("form-entries/restore", formEntriesIds);
}

export function archiveFormEntries(
  formEntryIds: number[],
  archiveId: number
): any {
  return instance.put(`form-entries/archives/${archiveId}`, formEntryIds);
}

export function unArchivesByFormIds(formIds: number[]): any {
  return instance.put("form-entries/unarchive", formIds);
}

export function assignToFormEntries(
  formIds: number[],
  userId: number | null
): any {
  return instance.put(`form-entries/assignTo/${userId ?? ""}`, formIds);
}

export function setFormEntriesStatus(ids: number[], statusId: number): any {
  return instance.put(`form-entries/status/${statusId}`, ids);
}

export function getFormEntiresByUserId(userId: number): any {
  return instance.get(`form-entries/user/${userId}`);
}

export function getFormEntryByAccessCode(accessCode: string): any {
  return instance.get(`form-entries/${accessCode}`);
}

export function getFormEntryTimelines(id: number): any {
  return instance.get(`form-entries/${id}/timelines`);
}

export function getFormEntryNotes(id: number): any {
  return instance.get(`form-entries/${id}/notes`);
}

export function getFormEntryRevisionHistories(id: number): any {
  return instance.get(`form-entries/${id}/revision-history`);
}

export function getFormEntryDataById(formEntryId: number): any {
  return instance.get(`form-entries/entry/${formEntryId}`);
}

export function getFormFieldsByFormEntryId(formEntryId: number): any {
  return instance.get(`form-entries/form-fields/${formEntryId}`);
}

export function getPriorYearFormFieldsByFormId(formId: number, accessCode: string, accountNumber: string): any {
  return instance.put(`form-entries/prior-year/${formId}`,
    JSON.stringify({accountNumber: accountNumber, accessCode: accessCode}), { headers: {'content-type': 'application/json'}});
}

export function tryGetPreviouslySubmittedForm(formId: number, propertyId: string, year: number) {
  return instance.get<IFormEntryModel>(
    `form-entries/submitted/${formId}/${propertyId}/${year}`,
    { headers: {'content-type': 'application/json'}}
  );
}

export function getFormEntryByPropertyIdAndYear(formId: number, propertyId: string, year: number): Promise<any> {
  return instance.get(
      `form-entries/${formId}/${propertyId}/${year}`,
      { headers: {'content-type': 'application/json'}}
  );
}

export function getFieldTypesByFormEntryId(id: number): any {
  return instance.get(`form-entries/${id}/custom-admin-fields`);
}

export function getCustomFormFieldById(id: number): any {
  return instance.get(`form-entries/custom-form-fields/${id}`);
}

export function addOrUpdateCustomFormFields(data: ICustomFormField[]): any {
  return instance.post(`form-entries/custom-form-fields`, data);
}

export function getSupportingDocumentsByFormEntryId(id: number): any {
  return instance.get(`form-entries/${id}/supporting-documents`);
}

export function getSupportingDocumentByAccessCodeAndDocumentId(
  accessCode: string,
  documentId: string
): any {
  return instance.get(`form-entries/${accessCode}/supporting-documents/${documentId}`, {
    responseType: "blob",
  });
}

export function rollbackRevision(formEntryId: number): any {
  return instance.get(`form-entries/${formEntryId}/rollback-revision`);
}

export function unlockFormRevision(message: string, formEntryId: number): any {
  return instance.post(`form-entries/${formEntryId}/unlock-revision`, message);
}

export function approveForm(formEntryId: number, sendEmail: boolean): any {
  return instance.post(`form-entries/${formEntryId}/approve`, sendEmail);
}

export function getNewFormEntries(formId: number): any {
  return instance.get(`form-entries/generate-new/${formId}`);
}

export function saveForm(data:any) {
  return instance.post<IFormEntryModel>(`form-entries/save`, data);
}

export function denyForm(
  formEntryId: number,
  sendUserEmail: boolean,
  message: string
): any {
  return instance.post(`form-entries/${formEntryId}/deny`, {
    sendUserEmail,
    message,
  });
}

export function denyFormRenditionExtension(
    formEntryId: number,
    sendUserEmail: boolean,
    message: string
): any {
  return instance.post(`form-entries/${formEntryId}/deny-rendition-extension`, {
    sendUserEmail,
    message,
  });
}

export function getPACSPersonalPropertyTypeCodes(): any {
  return instance.get(`form-entries/property-codes`);
}

export function approveFormRenditionExtension(formEntryId: number, sendEmail: boolean): any {
  return instance.post(`form-entries/${formEntryId}/approve-rendition-extension`, sendEmail);
}

export async function refreshTrueRollApplication(formEntryId: number) {
  const response = await instance.post<TrueRollApplicationRefreshResponse>(`form-entries/${formEntryId}/trueroll/application/refresh`);
  return response.data;
}

export async function resubmitTrueRollApplication(formEntryId: number) {
  const response = await instance.post<TrueRollApplicationRefreshResponse>(`form-entries/${formEntryId}/trueroll/application/resubmit`);
  return response.data;
}
