import type { CancelToken } from "axios";
import instance from "src/axios-instance";
import type { ICreateMessage } from "src/interfaces/messages/create-message";

export function getMessagesByDialogId(
  dialogId: number,
  page: number,
  cancel: CancelToken
): any {
  return instance({
    method: "GET",
    url: `messages/dialog/${dialogId}`,
    params: { page: page },
    cancelToken: cancel,
  });
}

export function sendMessage(dialogId: number, message: ICreateMessage): any {
  const formData = new FormData();
  formData.append("date", message.date);
  formData.append("body", message.body);
  formData.append("isRead", new Boolean(message.isRead).toString());
  formData.append("emailSent", new Boolean(message.emailSent).toString());
  for (const file of message.attachments){
    formData.append('attachments', file);
  }
  return instance.post(`messages/dialog/${dialogId}/send`, formData, { 
    headers: {
      'Content-Type': 'multipart/form-data'
    }});
}

export function getDialogMessages(dialogId: number): any {
  return instance.get(`messages/dialog/${dialogId}`);
}

export function consumeMessagesByDialogId(dialogId: number): any {
  return instance.put(`messages/dialog/${dialogId}/consume`, { dialogId });
}

export function consumeMessagesByMessageId(messageId: number): any {
  return instance.put(`messages/${messageId}/consume`, { messageId });
}

export function downloadFileByAttachmentId(attachmentId: number): any {
  return instance.get(`messages/download/${attachmentId}`, {
    responseType: "blob",
  });
}
