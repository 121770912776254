import React, {useEffect, useMemo, useRef, useState} from "react";
import {Input, InputChangeEvent, NumericTextBox, NumericTextBoxChangeEvent} from "@progress/kendo-react-inputs";
import classes from "./form-assets.module.scss";
import {
    DatePickerChangeEvent
} from "@progress/kendo-react-dateinputs";
import {
    IPacsPersonalPropertyTypeCodeInterface
} from "../../../../../../interfaces/pacs-personal-property-type-code.interface";
import {DropDownList, DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import {IFormAssetsSettingsInterface} from "../../../../../../interfaces/form-assets-settings.interface";
import {IGeneralScheduleInterface} from "../../../../../../interfaces/forms/BPPForm/general-schedule.interface";
import {
    IBPPFormPersonalPropertySegmentsInterface
} from "../../../../../../interfaces/forms/BPPForm/bpp-form-personal-property-segments.interface";
import {
    USDCurrencyWithOutFractionDigitsFormatOptions
} from "../../../../../Shared/NumberFormatHelper/NumberFormatHelper";
import {CustomOnlyYearCalendarHelper} from "../../../../../Shared/DatePickers/CustomCalendars";
import {IRowState} from "../../CustomInputs/UpdateRowErrorStateHandler";
import {CustomRowDatePicker} from "../../CustomInputs/CustomDatePickers/CustomRowDatePicker";
import {FormValidation} from "../../InputValidator";
import {Error} from "@progress/kendo-react-labels";
import { BPPFormAssetsTableTotals } from "./BPPFormAssetsTableTotals";

interface IBPPFormDialogInterface {
    assets: Array<IBPPFormPersonalPropertySegmentsInterface>,
    addAsset: () => void,
    updateAsset: (asset: IBPPFormPersonalPropertySegmentsInterface) => void,
    removeAsset: (id: number) => void,
    restoreAsset: (id: number) => void,
    personalPropertyTypeCodes: Array<IPacsPersonalPropertyTypeCodeInterface>;
    assetSettings: Array<IFormAssetsSettingsInterface>;
    setFormIsValid: (value: boolean) => void,
    disabled?: boolean
}

const maxAssetsYearDate = new Date(new Date().getFullYear(), 1,1);

export default function BPPFormAssetsTable(props: IBPPFormDialogInterface): JSX.Element {
    const {
        addAsset,
        updateAsset,
        removeAsset: deleteAsset,
        restoreAsset,
        personalPropertyTypeCodes,
        assetSettings,
        setFormIsValid,
        disabled,
    } = props;

    const assets = useMemo(() => props.assets.filter(a => !a.HasActiveSubSegments), [props.assets]);
    const assetTypes = useMemo(
        () => personalPropertyTypeCodes.filter(p => assetSettings.some(a => a.assetCode === p.code && !!a.assetName)),
        [personalPropertyTypeCodes, assetSettings]
    );

    const { CustomOnlyYearCalendar } = CustomOnlyYearCalendarHelper(maxAssetsYearDate.getUTCFullYear() - 1);

    function changeAssetsHandler(
        e: | InputChangeEvent
           | DatePickerChangeEvent
           | DropDownListChangeEvent
           | NumericTextBoxChangeEvent,
        item: IBPPFormPersonalPropertySegmentsInterface,
        fieldName: keyof IGeneralScheduleInterface,
    ){
        if (fieldName === "YearAcquired" && e && e.value){
            item = {...item, YearAcquired: e.value ? e.value?.getFullYear().toString() : null};
            item.changed = !item.isNew && !!item.initialValues && (item.initialValues.YearAcquired || undefined) !== (item.YearAcquired || undefined);
        } else if (fieldName === "MarketValue"){
            item.MarketValue = e.value ? parseInt(e.value) : 0;
            item.changed = !item.isNew && item.MarketValue !== item.LastYearAssetValue;
        } else if (fieldName === "HistoricCost"){
            item.HistoricCost = e.value ? parseInt(e.value) : undefined;
            item.changed = !item.isNew && item.HistoricCost !== item.initialValues?.HistoricCost;
        } else if (fieldName === "PersonalPropertyType") {
            item.Type = assetSettings.find(x=>x.assetCode === personalPropertyTypeCodes.find(p=>p.description === e.value)?.code)?.assetCode;
            item.PersonalPropertyType = e.value;
            item.ScheduleName =  assetSettings.find(x=>x.assetCode === personalPropertyTypeCodes.find(p=>p.description === e.value)?.code)?.assetName;
            item.changed = !item.isNew && !!item.initialValues && (item.initialValues.PersonalPropertyType || undefined) !== (item.PersonalPropertyType || undefined);
        } else {
            (item[fieldName] as any) =  e && e.value ? e.value.toString() : "";
            item.changed = !item.isNew && !!item.initialValues && (item.initialValues[fieldName] || undefined) !== (item[fieldName] || undefined);
        }

        updateAsset(item);
    }

    function addAssetItem(event: React.MouseEvent<HTMLElement>) {
        event.preventDefault();
        addAsset();
    }

    function enableEdit(event: React.MouseEvent<HTMLElement>, item: IBPPFormPersonalPropertySegmentsInterface){
        event.preventDefault();

        item.disabled = !item.disabled;

        updateAsset(item)
    }

    function removeAsset(event: React.MouseEvent<HTMLElement>, item: IBPPFormPersonalPropertySegmentsInterface){
        event.preventDefault();

        if (item.Id != undefined) {
            deleteAsset(item.Id)
        }
    }

    function renewAsset(event: React.MouseEvent<HTMLElement>, item: IBPPFormPersonalPropertySegmentsInterface){
        event.preventDefault();

        if (item.Id != undefined) {
            restoreAsset(item.Id);
        }
    }

    const formRef = useRef<HTMLFormElement>(null);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const { inputValidator, validationMessage } = FormValidation(formRef);
    const [dateErrorsArray, setDateErrorsArray] = useState<Array<IRowState>>([]);

    function changeValidator() {
        if(formRef.current) {
            setFormIsValid(formRef.current.checkValidity() && !dateErrorsArray.some(x=>x.state === false));
        }
    }

    function onTouch(){
        if(!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator();
    }, [formRef.current, assets, dateErrorsArray]);

    function getStyles(item: IBPPFormPersonalPropertySegmentsInterface){
        return item?.removed
            ? classes.removed
            : item?.isNew
                ? classes.added
                : item?.changed
                    ? classes.changed
                    : "";
    }

    return <form name={"Name"} ref={formRef} onFocus={onTouch}>
        <table className="blue">
           <thead>
               <tr>
                   <th>Action</th>
                   <th style={{ width: '20%'}}>Asset Type</th>
                   <th style={{ width: '20%'}}>Asset Description</th>
                   <th style={{ width: '15%'}}>Last Year Market Value</th>
                   <th style={{ width: '7%'}}>Asset Year Acquired</th>
                   <th style={{ width: '15%'}}>Historical Cost</th>
                   <th style={{ width: '15%'}}>Good Faith Estimate</th>
               </tr>
           </thead>
            <tbody>
                {assets.map((item) => {
                    return <tr key={item.Id} className={getStyles(item)}>
                        <td className={classes.W5}>
                            <div className={classes.actions}>
                                { item?.removed
                                    ? <button disabled={disabled} className={classes.btn} onClick={(e) => renewAsset(e, item)}><i className="fa fa-solid fa-rotate-left"></i></button>
                                    : <><button disabled={disabled}  className={classes.btn} onClick={(e) => enableEdit(e ,item)}><i className="fa fas fa-edit"></i></button>
                                        <button disabled={disabled}  className={classes.btn} onClick={(e) => removeAsset(e ,item)}><i className="fa fas fa-times" ></i></button></> }
                            </div>
                        </td>
                        <td>
                            <DropDownList
                                name={`PersonalPropertyType-${item.Id}`}
                                disabled={item?.disabled}
                                className={item?.removed ?"removed-dropdown" : ""}
                                data={assetTypes.map(x=>x.description)}
                                value={item?.PersonalPropertyType}
                                onChange={(e) => changeAssetsHandler(e, item, "PersonalPropertyType")}
                                required={true}
                            />
                            {!inputValidator(`PersonalPropertyType-${item.Id}`) && (
                                <Error>{validationMessage}</Error>
                            )}
                        </td>
                        <td>
                            <Input
                                disabled={item?.disabled}
                                value={item?.Description}
                                onChange={e => changeAssetsHandler(e, item,"Description")}
                            />
                        </td>
                        <td>
                            <NumericTextBox
                                disabled={true}
                                value={item?.LastYearAssetValue}
                                format={USDCurrencyWithOutFractionDigitsFormatOptions}
                                spinners={false}
                            />
                        </td>
                        <td>
                            <CustomRowDatePicker
                                fieldName={"YearAcquired"}
                                onlyYear={true}
                                required={!!item.HistoricCost}
                                group={"ScheduleB"}
                                id={item.Id || -1}
                                errorsArray={dateErrorsArray}
                                setErrorsArray={setDateErrorsArray}
                                formDataFields={assets}
                                disabled={item?.disabled || (!!item.YearAcquired && item.YearAcquired === item.initialValues?.YearAcquired)}
                                customChangeEventHandler={(e, index, fieldName) => changeAssetsHandler(e, item, fieldName)}
                                CustomCalendar={CustomOnlyYearCalendar}
                                maxDate={maxAssetsYearDate}
                                defaultValueDate={item.YearAcquired}
                                rowItem={item.YearAcquired}
                            />
                        </td>
                        <td>
                            <NumericTextBox
                                disabled={item?.disabled}
                                value={item?.HistoricCost}
                                onChange={e => changeAssetsHandler(e, item, "HistoricCost")}
                                format={USDCurrencyWithOutFractionDigitsFormatOptions}
                                spinners={false}
                            />
                        </td>
                        <td>
                            <NumericTextBox
                                disabled={item?.disabled}
                                value={item?.MarketValue}
                                onChange={e => changeAssetsHandler(e, item, "MarketValue")}
                                format={USDCurrencyWithOutFractionDigitsFormatOptions}
                                spinners={false}
                            />
                        </td>
                    </tr>
                })}
                <tr>
                    <td>
                        <button className={`${classes.btn} ${classes.actions}`} disabled={disabled}  onClick={addAssetItem}>
                            <i className="fa fas fa-plus"></i>
                        </button>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
        <BPPFormAssetsTableTotals className={classes.w50} assets={assets} />
    </form>
}
