import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./BPPForm.module.scss";
import {
    Checkbox,
    CheckboxChangeEvent,
    Input,
    InputChangeEvent, RadioGroup, RadioGroupChangeEvent,
    TextAreaChangeEvent
} from "@progress/kendo-react-inputs";
import {Label} from "@progress/kendo-react-labels";
import {DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {IFormFiledInterface} from "../../../../../interfaces/form-filed.interface";
import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import {IBppFormFifthSectionInterface} from "../../../../../interfaces/forms/BPPForm/bpp-form-fifth-section.interface";
import {DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import {useEffect, useRef, useState} from "react";
import { Error } from "@progress/kendo-react-labels";
import {FormValidation} from "../InputValidator";
import {CustomDatePicker} from "../CustomInputs/CustomDatePickers/CustomDatePicker";
import {ZipCodeInput} from "../CustomInputs/ZipCodeInput";
import {StatesInput} from "../CustomInputs/StatesInput";
import SectionTitle from "../Shared/SectionTitle";
import { defaultRadioButtonData } from "../Shared/RadioButtonData";

interface GeneralFormType {
    isAdminPreview?: boolean,
    formFields?: Array<IFormFiledInterface>,
    setFormDataFields: (form: IBppFormFifthSectionInterface) => void;
    formDataFields?:  IBppFormFifthSectionInterface,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void,
    allSectionTouched: boolean
}

export default function BPPFormFifthSection(props: GeneralFormType): JSX.Element {
    const { formFields, setFormDataFields, formDataFields, formSettings, isAdminPreview, setFormIsValid, allSectionTouched } = props;

    const formRef = useRef<HTMLFormElement>(null);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const {validationMessage, inputValidatorMessage, inputValidator, setStyles} = FormValidation(formRef);
    const [zipCodeIsValid, setZipCodeIsValid] = useState<boolean>(!formDataFields?.BusinessMoved);
    const [businessMovedDateInputValid, setBusinessMovedDateInputValid] = useState<boolean>(!formDataFields?.BusinessMoved);
    const [businessStartDateAtLocation, setBusinessStartDateAtLocation] = useState<boolean>(!formDataFields?.BusinessMoved);
    const [businessSoldDate, setBusinessSoldDate] = useState<boolean>(!formDataFields?.BusinessWasSold);
    const [businessClosedDate, setBusinessClosedDate] = useState<boolean>(!formDataFields?.BusinessClosedDate);
    const [businessStateInputValid, setBusinessStateInputValid] = useState<boolean>(!formDataFields?.BusinessMoved);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={5} name={"Business Information"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent,
        field: string,
        group?: string
    ) {
        if (group){
            setFormDataFields({
                ...formDataFields,
                [group]: {
                    ...(formDataFields as any)?.[group],
                    [field]: event.value
                }
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    function changeValidator() {
        if (formRef.current) {
            const valid = formRef.current.checkValidity() &&
                zipCodeIsValid &&
                businessMovedDateInputValid &&
                businessStartDateAtLocation &&
                businessClosedDate &&
                businessStateInputValid;

            setIsValid(valid);
            setFormIsValid(valid);
        }
    }

    function onTouch(){
        if (!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [
        formDataFields,
        formDataFields?.BusinessMoved,
        formRef.current,
        zipCodeIsValid,
        businessMovedDateInputValid,
        businessStartDateAtLocation,
        businessClosedDate,
        businessStateInputValid]);


    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={`${classes.itemFields}`}>
                                <div className={classes.fieldsGroup}>
                                    <Label className={classes.Label}>
                                        Business Type:
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.BusinessType?.Manufacturing}
                                            label={"Manufacturing"}
                                            onChange={e => changeValueHandler(e, "Manufacturing", "BusinessType")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.BusinessType?.Wholesale}
                                            label={"Wholesale"}
                                            onChange={e => changeValueHandler(e, "Wholesale", "BusinessType")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.BusinessType?.Retail}
                                            label={"Retail"}
                                            onChange={e => changeValueHandler(e, "Retail", "BusinessType")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.BusinessType?.Service}
                                            label={"Service"}
                                            onChange={e => changeValueHandler(e, "Service", "BusinessType")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.BusinessType?.NewBusiness}
                                            label={"New Business"}
                                            onChange={e => changeValueHandler(e, "NewBusiness", "BusinessType")}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.itemFields}`}>
                                <div className={`${classes.fieldsGroupW100}`}>
                                    <Label className={classes.Label}>
                                        Business Description
                                    </Label>
                                    <div className="inputsGroup">
                                        <Input
                                            className={classes.kTextbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.BusinessDescription}
                                            onChange={e => changeValueHandler(e, "BusinessDescription")}
                                        />
                                    </div>
                                </div>
                                <div className={`${classes.fieldsGroupW49}`}>
                                    <Label className={classes.Label}>
                                        Square Feet Occupied
                                    </Label>
                                    <div className="inputsGroup">
                                        <Input
                                            className={classes.kTextbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.SquareFeetOccupied}
                                            onChange={e => changeValueHandler(e, "SquareFeetOccupied")}
                                        />
                                    </div>
                                </div>
                                <div className={`${classes.fieldsGroupW49}`}>
                                    <Label className={classes.Label}>
                                        Sales Tax Permit Number
                                    </Label>
                                    <div className="inputsGroup">
                                        <Input
                                            className={classes.kTextbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.SalesTaxPermitNumber}
                                            onChange={e => changeValueHandler(e, "SalesTaxPermitNumber")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={`${classes.itemFields}`}>
                                <div className={`${classes.fieldsGroup}`}>
                                    <div className={classes.checkboxGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.BusinessMoved}
                                            label={"Check this box if the business has moved."}
                                            onChange={e => changeValueHandler(e, "BusinessMoved")}
                                        />
                                    </div>
                                </div>
                                { formDataFields?.BusinessMoved &&
                                <div className={classes.item}>
                                    <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                        <p>New Location</p>
                                    </div>
                                    <div className={`${classes.itemFields}`}>
                                        <div className={classes.fieldsGroupW49}>
                                            <Label className={classes.Label}>
                                                Business Moved Date
                                                <i className="fa fa-asterisk text-danger"></i>
                                            </Label>
                                            <CustomDatePicker
                                                fieldName={"BusinessMovedDate"}
                                                required={formDataFields?.BusinessMoved}
                                                disabled={formSettings?.isReadOnly}
                                                formDataFields={formDataFields}
                                                setFormDataFields={setFormDataFields}
                                                onlyYear={false}
                                                setIsValidField={setBusinessMovedDateInputValid}
                                                validField={businessMovedDateInputValid}
                                                defaultValueDate={formDataFields.BusinessMovedDate}
                                            />
                                        </div>
                                        <div className={classes.fieldsGroupW49}>
                                            <Label className={classes.Label}>
                                                Business Start Date At Location
                                                <i className="fa fa-asterisk text-danger"></i>
                                            </Label>
                                            <CustomDatePicker
                                                fieldName={"BusinessStartDateAtLocation"}
                                                required={formDataFields?.BusinessMoved}
                                                disabled={formSettings?.isReadOnly}
                                                formDataFields={formDataFields}
                                                setFormDataFields={setFormDataFields}
                                                onlyYear={false}
                                                setIsValidField={setBusinessStartDateAtLocation}
                                                validField={businessStartDateAtLocation}
                                                defaultValueDate={formDataFields.BusinessStartDateAtLocation}
                                            />
                                        </div>

                                        <div className={`${classes.fieldsGroupW100}`}>
                                            <Label className={classes.Label}>
                                                Address Line 1
                                                <i className="fa fa-asterisk text-danger"></i>
                                            </Label>
                                            <div className="inputsGroup">
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"NewLocationAddressLine1"}
                                                    required={formDataFields?.BusinessMoved}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.NewLocationAddressLine1}
                                                    onChange={e => changeValueHandler(e, "NewLocationAddressLine1")}
                                                />
                                                {!inputValidator("NewLocationAddressLine1") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                        </div>

                                        <div className={`${classes.fieldsGroupW100}`}>
                                            <Label className={classes.Label}>
                                                Address Line 2
                                            </Label>
                                            <div className="inputsGroup">
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"NewLocationAddressLine2"}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.NewLocationAddressLine2}
                                                    onChange={e => changeValueHandler(e, "NewLocationAddressLine2")}
                                                />
                                            </div>
                                        </div>
                                        <div className={`${classes.fieldsGroupW30}`}>
                                            <Label className={classes.Label}>
                                                City
                                                <i className="fa fa-asterisk text-danger"></i>
                                            </Label>
                                            <div className="inputsGroup">
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"NewLocationAddressCity"}
                                                    required={formDataFields?.BusinessMoved}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.NewLocationAddressCity}
                                                    onChange={e => changeValueHandler(e, "NewLocationAddressCity")}
                                                />
                                                {!inputValidator("NewLocationAddressCity") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                        </div>
                                        <div className={`${classes.fieldsGroupW30}`}>
                                            <Label className={classes.Label}>
                                                State
                                                <i className="fa fa-asterisk text-danger"></i>
                                            </Label>
                                            <div className="inputsGroup">
                                                <StatesInput
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"NewLocationAddressState"}
                                                    required={formDataFields?.BusinessMoved}
                                                    validField={businessStateInputValid}
                                                    setIsValidField={setBusinessStateInputValid}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                />
                                            </div>
                                        </div>
                                        <div className={`${classes.fieldsGroupW30}`}>
                                            <Label className={classes.Label}>
                                                Zip Code
                                                <i className="fa fa-asterisk text-danger"></i>
                                            </Label>
                                            <div className="inputsGroup">
                                                <ZipCodeInput
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"NewLocationAddressZip"}
                                                    required={formDataFields?.BusinessMoved}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                    setIsValidField={setZipCodeIsValid}
                                                    validField={zipCodeIsValid}
                                                />
                                                {!inputValidator("NewLocationAddressZip") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div> }
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={`${classes.itemFields}`}>
                                <div className={`${classes.fieldsGroup}`}>
                                    <div className={classes.checkboxGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.BusinessWasSold}
                                            label={"Check this box if the business was sold."}
                                            onChange={e => changeValueHandler(e, "BusinessWasSold")}
                                        />
                                    </div>
                                </div>
                                { formDataFields?.BusinessWasSold &&
                                <div className={classes.item}>
                                    <div className={`${classes.itemFields}`}>
                                        <div className={classes.fieldsGroupW49}>
                                            <Label className={classes.Label}>
                                                Business Sold Date
                                                <i className="fa fa-asterisk text-danger"></i>
                                            </Label>
                                            <CustomDatePicker
                                                fieldName={"BusinessSoldDate"}
                                                required={formDataFields?.BusinessWasSold}
                                                disabled={formSettings?.isReadOnly}
                                                formDataFields={formDataFields}
                                                setFormDataFields={setFormDataFields}
                                                onlyYear={false}
                                                setIsValidField={setBusinessSoldDate}
                                                validField={businessSoldDate}
                                                defaultValueDate={formDataFields.BusinessSoldDate}
                                            />
                                        </div>

                                        <div className={`${classes.fieldsGroupW100}`}>
                                            <Label className={classes.Label}>
                                                New Owner
                                                <i className="fa fa-asterisk text-danger"></i>
                                            </Label>
                                            <div className="inputsGroup">
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"NewOwner"}
                                                    required={formDataFields?.BusinessWasSold}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.NewOwner}
                                                    onChange={e => changeValueHandler(e, "NewOwner")}
                                                />
                                                {!inputValidator("NewOwner") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                </div> }
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={`${classes.itemFields}`}>
                                <div className={`${classes.fieldsGroup}`}>
                                    <div className={classes.checkboxGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.BusinessWasClosed}
                                            label={"Check this box if the business has been closed."}
                                            onChange={e => changeValueHandler(e, "BusinessWasClosed")}
                                        />
                                    </div>
                                </div>
                                { formDataFields?.BusinessWasClosed &&
                                <div className={classes.item}>
                                    <div className={`${classes.itemFields}`}>
                                        <div className={classes.fieldsGroupW49}>
                                            <Label className={classes.Label}>
                                                Business Closed Date
                                                <i className="fa fa-asterisk text-danger"></i>
                                            </Label>
                                            <CustomDatePicker
                                                fieldName={"BusinessClosedDate"}
                                                required={formDataFields?.BusinessWasClosed}
                                                disabled={formSettings?.isReadOnly}
                                                formDataFields={formDataFields}
                                                setFormDataFields={setFormDataFields}
                                                onlyYear={false}
                                                setIsValidField={setBusinessClosedDate}
                                                validField={businessClosedDate}
                                                defaultValueDate={formDataFields.BusinessClosedDate}
                                            />
                                        </div>
                                    </div>
                                </div> }
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={`${classes.itemFields}`}>
                                <p className={classes.Label}>
                                    Did assets remain in place as of Jan. 1?
                                </p>
                                <div className={classes.fieldsGroup}>
                                    <div className={classes.checkboxGroup}>
                                        <RadioGroup
                                            layout={"horizontal"}
                                            name={"AssetsRemainedInPlace"}
                                            disabled={formSettings?.isReadOnly}
                                            data={defaultRadioButtonData}
                                            onChange={e => changeValueHandler(e, "AssetsRemainedInPlace")}
                                            value={formDataFields?.AssetsRemainedInPlace}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.OwnedNoTaxableAssets}
                                    label={"The business owned no taxable assets in this county as of Jan. 1"}
                                    onChange={e => changeValueHandler(e, "OwnedNoTaxableAssets")}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
