import {createContext, useContext, useReducer} from "react";
import {initialState, SelectedFormContextType, SelectedFormReducer} from "./selected-form-reducer";


const SelectedFormStateContext = createContext<SelectedFormContextType>({
    selectedFormState: initialState,
    selectedFormDispatch: () => null,
});

export function useSelectedFormContext(): SelectedFormContextType {
    const context = useContext(SelectedFormStateContext);

    if (context === undefined) {
        throw new Error(
            "useSelectedFormContext must be used within a SelectedFormProvider"
        );
    }

    return context;
}

export const SelectedFormProvider = ({
                                      children,
                                  }: JSX.ElementChildrenAttribute): JSX.Element => {
    const [ selectedFormState, selectedFormDispatch] = useReducer(
        SelectedFormReducer,
        initialState
    );

    return (
        <SelectedFormStateContext.Provider
            value={{ selectedFormState, selectedFormDispatch }}
        >
            {children as React.ReactNode}
        </SelectedFormStateContext.Provider>
    );
};
