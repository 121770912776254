import { IFormFilesInterface } from "../Shared/form-files.interface";

export type IDisabledVetSecondSection = {
    TaxYears?: Array<number>;
    IsLateApplication?: boolean;
    ApplicantOwnedPropertyJan1?: boolean;
    PropertyOwnerName?: string;
    PropertyOwnerPhoneNumber?: string;
    PropertyOwnerEmailAddress?: string;
    PropertyOwnerPhysicalAddressLine1?: string;
    PropertyOwnerPhysicalAddressLine2?: string;
    PropertyOwnerPhysicalAddressCity?: string;
    PropertyOwnerPhysicalAddressState?: string;
    PropertyOwnerPhysicalAddressZip?: string;
    PropertyOwnerMailingAddressLine1?: string;
    PropertyOwnerMailingAddressLine2?: string;
    PropertyOwnerMailingAddressCity?: string;
    PropertyOwnerMailingAddressState?: string;
    PropertyOwnerMailingAddressZip?: string;
    PropertyOwnerIdNumber?: string;
    PropertyOwnerPhotoId?: Array<IFormFilesInterface>,
    IsTexasResident?: boolean;
}

export const DisabledVetSecondSectionInterface = () => ({
    TaxYears: undefined,
    IsLateApplication: undefined,
    ApplicantOwnedPropertyJan1: undefined,
    PropertyOwnerName: undefined,
    PropertyOwnerPhoneNumber: undefined,
    PropertyOwnerEmailAddress: undefined,
    PropertyOwnerPhysicalAddressLine1: undefined,
    PropertyOwnerPhysicalAddressLine2: undefined,
    PropertyOwnerPhysicalAddressCity: undefined,
    PropertyOwnerPhysicalAddressState: undefined,
    PropertyOwnerPhysicalAddressZip: undefined,
    PropertyOwnerMailingAddressLine1: undefined,
    PropertyOwnerMailingAddressLine2: undefined,
    PropertyOwnerMailingAddressCity: undefined,
    PropertyOwnerMailingAddressState: undefined,
    PropertyOwnerMailingAddressZip: undefined,
    PropertyOwnerIdNumber: undefined,
    PropertyOwnerPhotoId: undefined,
    IsTexasResident: undefined
})