import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "./FreeportForm.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { DatePickerChangeEvent, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, RadioGroupChangeEvent, Input, TextArea } from "@progress/kendo-react-inputs";
import { FormValidation } from "../InputValidator";
import { Error, Label } from "@progress/kendo-react-labels";
import { StatesInput } from "../CustomInputs/StatesInput";
import { ZipCodeInput } from "../CustomInputs/ZipCodeInput";
import { IFreeportFormThirdSectionInterface } from "src/interfaces/forms/FreeportForm/freeport-form-third-section.interface";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IFreeportFormThirdSectionInterface) => void;
    formDataFields?:  IFreeportFormThirdSectionInterface,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function FreeportFormSecondSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, setFormIsValid } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const [propertyStateInputValid, setPropertyStateInputValid] = useState<boolean>(false);
    const [propertyZipCodeInputValid, setPropertyZipCodeInputValid] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator } = FormValidation(formRef);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={3} name={"Property Information"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string
    ) {
        setFormDataFields({
            ...formDataFields,
            [field]: event.value
        });
    }

    function changeValidator() {
        if (formRef.current) {
            const isValid = formRef.current.checkValidity() &&
                propertyStateInputValid &&
                propertyZipCodeInputValid
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if (!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [
        formDataFields,
        formRef.current,
        propertyStateInputValid,
        propertyZipCodeInputValid
    ]);

    return (
       <>
            <PanelBar keepItemsMounted={true}>
                <PanelBarItem title={title} expanded={true}>
                    <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                        <div className="container">
                            <div className={`${classes.item} ${classes.bordered}`}>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup} ${classes.fieldsGroupW50}`}>
                                    <Label className={classes.Label}>
                                        Appraisal District Account Number (if known)
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={true}
                                        name={"PropertyId"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.PropertyId}
                                        onChange={(e)=> changeValueHandler(e, "PropertyId")}
                                    />
                                    {!inputValidator("PropertyId") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Legal Description (if known)
                                    </Label>
                                    <TextArea
                                        name={"LegalDescription"}
                                        required={false}
                                        className={classes.kTextarea}
                                        autoSize={true}
                                        rows={2}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.LegalDescription}
                                        onChange={(e)=> changeValueHandler(e, "LegalDescription")}
                                    />
                                </div>
                            </div>
                            <div className={`${classes.item} ${classes.bordered}`}>
                                <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                    <p>Location of Inventory</p>
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Address Line 1
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={true}
                                        name={"PropertyAddressLine1"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.PropertyAddressLine1}
                                        onChange={(e)=> changeValueHandler(e, "PropertyAddressLine1")}
                                    />
                                    {!inputValidator("PropertyAddressLine1") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Address Line 2
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"PropertyAddressLine2"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.PropertyAddressLine2}
                                        onChange={(e)=> changeValueHandler(e, "PropertyAddressLine2")}
                                    />
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={`${classes.fieldsGroupW50}`}>
                                        <Label className={classes.Label}>
                                            City
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            required={true}
                                            name={"PropertyAddressCity"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.PropertyAddressCity}
                                            onChange={(e)=> changeValueHandler(e, "PropertyAddressCity")}
                                        />
                                        {!inputValidator("PropertyAddressCity") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                    <div className={`${classes.fieldsGroupW20}`}>
                                        <Label className={classes.Label}>
                                            State
                                        </Label>
                                        <StatesInput
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"PropertyAddressState"}
                                            required={true}
                                            validField={propertyStateInputValid}
                                            setIsValidField={setPropertyStateInputValid}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                        />
                                        {!inputValidator("PropertyAddressState") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                    <div className={`${classes.fieldsGroupW20}`}>
                                        <Label className={classes.Label}>
                                            Zip Code
                                        </Label>
                                        <ZipCodeInput
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"PropertyAddressZip"}
                                            required={true}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                            setIsValidField={setPropertyZipCodeInputValid}
                                            validField={propertyZipCodeInputValid}
                                        />
                                        {!inputValidator("PropertyAddressZip") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                   </form>
                </PanelBarItem>
            </PanelBar>
       </>
    )
}