import instance from "src/axios-instance";
import { IAssignedPermission } from "src/interfaces/admin/assigned-permission.interface";
import { CreateGroup } from "src/interfaces/admin/create-group.interface";
import { IPermission } from "src/interfaces/admin/permission.interface";

export function getUserPermission(): any {
  return instance.get("permission");
}

export function listPermissionTypes(category: string): any {
  return instance.get<IPermission[]>(`permission/types/${category}`);
}

export function updateAssignedGroupPermissions(
  groupId: number,
  permissionTypeAssign: IAssignedPermission
): any {
  return instance.put(`permission/group/${groupId}`, permissionTypeAssign);
}

export function updateAssignedUserPermissions(
  userId: number,
  permissionTypeAssign: IAssignedPermission
): any {
  return instance.put(`permission/user/${userId}`, permissionTypeAssign);
}

export function getUsersAndGroups(category?: string): any {
  return instance.get(`usergroups/category/${category}`);
}

export function getAllUserGroups(): any {
  return instance.get("usergroups");
}

export function getUsersWithPermissions(formId?: number): any {
  return instance.get(`usergroups/users-with-permission/${formId ?? ""}`);
}

export function getGroupsWithPermissions(formId?: number): any {
  return instance.get(`usergroups/groups-with-permission/${formId ?? ""}`);
}

export function removeUserFromGroup(userId: number, groupTypeId: number): any {
  return instance.delete(`usergroups/user/${userId}/${groupTypeId}`);
}

export function addUserFromGroup(userId: number, groupTypeId: number): any {
  return instance.post(`usergroups/user/${userId}/${groupTypeId}`);
}

export function createGroup(newGroup: CreateGroup): any {
  return instance.post(`usergroups`, newGroup);
}

export function removeGroup(groupTypeId: number): any {
  return instance.delete(`usergroups/${groupTypeId}`);
}
