import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "./BPPForm.module.scss";
import {Label} from "@progress/kendo-react-labels";
import {
    Checkbox,
    CheckboxChangeEvent,
    Input,
    InputChangeEvent, RadioGroup, RadioGroupChangeEvent,
    TextAreaChangeEvent
} from "@progress/kendo-react-inputs";
import {IFormFiledInterface} from "../../../../../interfaces/form-filed.interface";
import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import {IBppFormSixthSectionInterface} from "../../../../../interfaces/forms/BPPForm/bpp-form-sixth-section.interface";
import {DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import {useEffect, useRef, useState} from "react";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    formFields?: Array<IFormFiledInterface>,
    setFormDataFields: (form: IBppFormSixthSectionInterface) => void;
    formDataFields?:  IBppFormSixthSectionInterface,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function BPPFormSixthSection(props: GeneralFormType): JSX.Element {
    const formRef = useRef<HTMLFormElement>(null);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={6} name={"Market Value"}/>;
    const { formFields, setFormDataFields, formDataFields, formSettings, isAdminPreview, setFormIsValid } = props;

    const radioButtonData = [
        { label: "Under $20,000", value: "Under20K" },
        { label: "$20,000 or More", value: "Over20K" },
    ];

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent,
        field: string,
    )
    {
        setFormDataFields({
            ...formDataFields,
            [field]: event.value
        });
    }

    function changeValidator() {
        if(formRef.current) {
            setIsValid(formRef.current.checkValidity());
            setFormIsValid(formRef.current.checkValidity());
        }
    }

    function onTouch(){
        if(!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current]);


    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <p className="itemTitle">
                                Check the total market value of your property
                            </p>
                            <div className={`${classes.itemFields}`}>
                                <div className={classes.fieldsGroup}>
                                    <Label className={classes.Label}>
                                        Business Type:
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <RadioGroup
                                            disabled={formSettings?.isReadOnly}
                                            data={radioButtonData}
                                            onChange={e => changeValueHandler(e, "MarketValue")}
                                            value={formDataFields?.MarketValue}
                                        />
                                    </div>
                                    <blockquote className={classes.information}>
                                        <p>
                                            <small>
                                                If you answered Under $20,000, please complete only Schedule A and if
                                                applicable, Schedule F. Otherwise, complete Schedule(s) B, C, D, E and/or F,
                                                whichever are applicable.
                                            </small>
                                        </p>
                                        <p>
                                            <small>
                                                When required by the chief appraiser, you must render any taxable property
                                                that you own or manage and control as a fiduciary on Jan. 1 [Tax Code
                                                Section 22.01 (b)]. For this type of property, complete
                                                Schedule(s) A, B, C, D, E and/or F, whichever are applicable.
                                            </small>
                                        </p>
                                        <p>
                                            <small>
                                                When required by the chief appraiser, you must file a report listing the
                                                name and address of each owner of taxable property that is in your
                                                possession or under your management on Jan. 1 by bailment, lease,
                                                consignment or other arrangement [Tax Code Section 22.04 (a)]. For this type
                                                of property complete Schedule F.
                                            </small>
                                        </p>
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
