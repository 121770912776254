import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import classes from "../BPPForm.module.scss";
import { IFormEntryModel } from "../../../../../../interfaces/form-entry.interface";

interface GeneralFormType {
  formSettings: IFormSettingModel | undefined;
  group: string;
  addRowHandler: (event: React.MouseEvent<HTMLElement>, group: string) => void;
  removeRowHandler: (event: React.MouseEvent<HTMLElement>, group: string) => void;
  formEntry?: IFormEntryModel;
}

export function ScheduleERowHandler(
    props: GeneralFormType
  ): JSX.Element {
    return (
      <div>
        <button
          className={classes.LightButton}
          disabled={props.formSettings?.isReadOnly || props.formEntry?.initiatedBy === 'PropertySearch'}
          onClick={(e) =>
              props.addRowHandler(e, props.group)
          }
        >
          <i className="fa fas fa-plus"></i>
          Add Row
        </button>
        <button
          className={classes.LightButton}
          disabled={props.formSettings?.isReadOnly || props.formEntry?.initiatedBy === 'PropertySearch'}
          onClick={(e) =>
              props.removeRowHandler(e, props.group)
          }
        >
          <i className="fa fas fa-minus"></i>
          Remove Row
        </button>
      </div>
    );
}
