import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "../WildlifeManagement.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, RadioGroupChangeEvent, Checkbox, Input, NumericTextBox, NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { FormValidation } from "../../InputValidator";
import { Calendar, CalendarChangeEvent, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { IWildlifeManagementFourthSectionSupplementalWater } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-supplemental-water.interface";
import { Label } from "@progress/kendo-react-labels";
import { CustomDatePicker } from "../../CustomInputs/CustomDatePickers/CustomDatePicker";
import SectionTitle from "../../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    formSettings?: IFormSettingModel,
    formDataFields?: IWildlifeManagementFourthSectionSupplementalWater;
    setFormDataFields: (form: IWildlifeManagementFourthSectionSupplementalWater) => void;
    setFormIsValid: (value: boolean) => void;
    setSectionIsValid: (value: boolean) => void;
}

export default function SupplementalWaterSubSection(props: GeneralFormType): JSX.Element {
    const { formSettings, isAdminPreview, formDataFields, setFormDataFields, setFormIsValid, setSectionIsValid } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator, setStyles } = FormValidation(formRef);

    const [marshWetlandRestorationOrDevelopmentPlannedDateOfConstructionIsValid, 
           setMarshWetlandRestorationOrDevelopmentPlannedDateOfConstructionIsValid] = 
        useState<boolean>(!formDataFields?.MarshWetlandRestorationOrDevelopmentPlannedDateOfConstruction);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={4} name={"Supplemental Water"}/>;
    
    function changeValidator() {
        if (formRef.current) {
            const isValid = formRef.current.checkValidity();
            setIsValid(isValid);
            setSectionIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent
            | NumericTextBoxChangeEvent,
        field: string,
        group?: string
    ) {
        if (group) {
            setFormDataFields({
                ...formDataFields,
                [group]: {
                    ...(formDataFields as any)?.[group],
                    [field]: event.value
                }
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    function onTouch(){
        if (!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [ formDataFields, formRef.current ]);

    return (
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.MarshWetlandRestorationOrDevelopment}
                                    label={"Marsh/Wetland Restoration or Development"}
                                    onChange={(e)=> changeValueHandler(e, "MarshWetlandRestorationOrDevelopment")}
                                />
                            </div>
                            { formDataFields?.MarshWetlandRestorationOrDevelopment && 
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.MarshWetlandRestorationOrDevelopmentTypes?.GreentreeReservoirs}
                                            label={"Greentree reservoirs"}
                                            onChange={(e)=>
                                                changeValueHandler(e, "GreentreeReservoirs", "MarshWetlandRestorationOrDevelopmentTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.MarshWetlandRestorationOrDevelopmentTypes?.ShallowRoostPondDevelopment}
                                            label={"Shallow roost pond development"}
                                            onChange={(e)=>
                                                changeValueHandler(e, "ShallowRoostPondDevelopment", "MarshWetlandRestorationOrDevelopmentTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.MarshWetlandRestorationOrDevelopmentTypes?.SeasonallyFloodedCrops}
                                            label={"Seasonally flooded crops"}
                                            onChange={(e)=>
                                                changeValueHandler(e, "SeasonallyFloodedCrops", "MarshWetlandRestorationOrDevelopmentTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.MarshWetlandRestorationOrDevelopmentTypes?.ArtificiallyCreatedWetlands}
                                            label={"Artificially created wetlands"}
                                            onChange={(e)=>
                                                changeValueHandler(e, "ArtificiallyCreatedWetlands", "MarshWetlandRestorationOrDevelopmentTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.MarshWetlandRestorationOrDevelopmentTypes?.MarshRestoration}
                                            label={"Marsh restoration/development/protection"}
                                            onChange={(e)=>
                                                changeValueHandler(e, "MarshRestoration", "MarshWetlandRestorationOrDevelopmentTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.MarshWetlandRestorationOrDevelopmentTypes?.PrairiePotholeRestoration}
                                            label={"Prairie pothole restoration/development/protection"}
                                            onChange={(e)=>
                                                changeValueHandler(e, "PrairiePotholeRestoration", "MarshWetlandRestorationOrDevelopmentTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.MarshWetlandRestorationOrDevelopmentTypes?.MoistSoilManagementUnits}
                                            label={"Moist soil management units"}
                                            onChange={(e)=>
                                                changeValueHandler(e, "MoistSoilManagementUnits", "MarshWetlandRestorationOrDevelopmentTypes")}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Planned date of construction
                                        </Label>
                                        <CustomDatePicker
                                            fieldName={"MarshWetlandRestorationOrDevelopmentPlannedDateOfConstruction"}
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            formDataFields={formDataFields}
                                            setFormDataFields={setFormDataFields}
                                            onlyYear={false}
                                            setIsValidField={setMarshWetlandRestorationOrDevelopmentPlannedDateOfConstructionIsValid}
                                            validField={marshWetlandRestorationOrDevelopmentPlannedDateOfConstructionIsValid}
                                            defaultValueDate={formDataFields?.MarshWetlandRestorationOrDevelopmentPlannedDateOfConstruction}
                                        />
                                    </div>
                                </div>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"MarshWetlandRestorationOrDevelopmentAdditionalInformation"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.MarshWetlandRestorationOrDevelopmentAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "MarshWetlandRestorationOrDevelopmentAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.WellWindmillOverflow}
                                    label={"Well/trough/windmill overflow/other wildlife watering facilities"}
                                    onChange={(e)=> changeValueHandler(e, "WellWindmillOverflow")}
                                />
                            </div>
                            { formDataFields?.WellWindmillOverflow && <>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={`${classes.checkboxGroup} ${classes.fieldsGroupW100}`}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.WellWindmillOverflowDrillNewWell}
                                            label={"Drill new well"}
                                            onChange={(e)=> changeValueHandler(e, "WellWindmillOverflowDrillNewWell")}
                                        />
                                    </div>
                                    { formDataFields.WellWindmillOverflowDrillNewWell && <>
                                        <div className={`${classes.flexRow} ${classes.paddingLeft}`}>
                                            <div className={classes.fieldsGroupW50}>
                                                <Label className={classes.Label}>
                                                    Depth
                                                </Label>
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"WellWindmillOverflowDrillNewWellDepth"}
                                                    placeholder="Depth"
                                                    required={false}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.WellWindmillOverflowDrillNewWellDepth}
                                                    onChange={(e)=> changeValueHandler(e, "WellWindmillOverflowDrillNewWellDepth")}
                                                />
                                            </div>
                                            <div className={classes.fieldsGroupW50}>
                                                <Label className={classes.Label}>
                                                    Gallons per minute
                                                </Label>
                                                <NumericTextBox
                                                    name={"WellWindmillOverflowDrillNewWellGallonsPerMinute"}
                                                    required={false}
                                                    disabled={formSettings?.isReadOnly}
                                                    className={!inputValidator("WellWindmillOverflowDrillNewWellGallonsPerMinute") ? classes.invalidState + " " + classes.kTextbox : classes.kTextbox}
                                                    value={formDataFields?.WellWindmillOverflowDrillNewWellGallonsPerMinute}
                                                    onChange={(e) => changeValueHandler(e, "WellWindmillOverflowDrillNewWellGallonsPerMinute")}
                                                />
                                            </div>
                                        </div>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <div className={classes.fieldsGroupW100}>                                       
                                                <Label className={classes.Label}>
                                                    Drill New Well Type
                                                </Label>
                                            </div>
                                            <div className={classes.inputsGroup}>
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.WellWindmillOverflowDrillNewWellTypes?.Windmill}
                                                    label={"Windmill"}
                                                    onChange={(e)=>
                                                        changeValueHandler(e, "Windmill", "WellWindmillOverflowDrillNewWellTypes")}
                                                />
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.WellWindmillOverflowDrillNewWellTypes?.Pump}
                                                    label={"Pump"}
                                                    onChange={(e)=> changeValueHandler(e, "Pump", "WellWindmillOverflowDrillNewWellTypes")}
                                                />
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.WellWindmillOverflowDrillNewWellTypes?.Pipeline}
                                                    label={"Pipeline"}
                                                    onChange={(e)=> changeValueHandler(e, "Pipeline", "WellWindmillOverflowDrillNewWellTypes")}
                                                />
                                            </div>
                                        </div></>
                                    }
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.checkboxGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.WellWindmillOverflowModificationsOfExistingWaterSources}
                                            label={"Modification(s) of existing water source"}
                                            onChange={(e)=> changeValueHandler(e, "WellWindmillOverflowModificationsOfExistingWaterSources")}
                                        />
                                    </div>
                                    { formDataFields.WellWindmillOverflowModificationsOfExistingWaterSources &&
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <div className={classes.inputsGroup}>
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.WellWindmillOverflowModificationsOfExistingWaterSourcesTypes?.Fencing}
                                                    label={"Fencing"}
                                                    onChange={(e)=>
                                                        changeValueHandler(e, "Fencing", "WellWindmillOverflowModificationsOfExistingWaterSourcesTypes")}
                                                />
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.WellWindmillOverflowModificationsOfExistingWaterSourcesTypes?.Overflow}
                                                    label={"Overflow"}
                                                    onChange={(e)=>
                                                        changeValueHandler(e, "Overflow", "WellWindmillOverflowModificationsOfExistingWaterSourcesTypes")}
                                                />
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.WellWindmillOverflowModificationsOfExistingWaterSourcesTypes?.TroughModification}
                                                    label={"Trough modification"}
                                                    onChange={(e)=>
                                                        changeValueHandler(e, "TroughModification", "WellWindmillOverflowModificationsOfExistingWaterSourcesTypes")}
                                                />
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.WellWindmillOverflowModificationsOfExistingWaterSourcesTypes?.Pipeline}
                                                    label={"Pipeline"}
                                                    onChange={(e)=>
                                                        changeValueHandler(e, "Pipeline", "WellWindmillOverflowModificationsOfExistingWaterSourcesTypes")}
                                                />
                                            </div>
                                            <div className={classes.fieldsGroupW100}>
                                                <Label className={classes.Label}>
                                                    Distance between water sources (waterers)
                                                </Label>
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"WellWindmillOverflowModificationsOfExistingWaterSourcesDistanceBetweenWaterSources"}
                                                    placeholder="Distance between water sources (waterers)"
                                                    required={false}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.WellWindmillOverflowModificationsOfExistingWaterSourcesDistanceBetweenWaterSources}
                                                    onChange={(e)=> changeValueHandler(e, "WellWindmillOverflowModificationsOfExistingWaterSourcesDistanceBetweenWaterSources")}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <h3>Type of wildlife watering facility</h3>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW50}>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup} ${classes.fieldsGroupW100}`}>
                                            <div className={classes.fieldsGroupW50}>
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPvcPipeFacility}
                                                    label={"PVC pipe facility"}
                                                    onChange={(e)=> changeValueHandler(e, "WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPvcPipeFacility")}
                                                />
                                            </div>
                                            { formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPvcPipeFacility &&
                                                <div className={classes.fieldsGroupW50}>
                                                    <NumericTextBox
                                                        name={"WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPvcPipeFacilityNumber"}
                                                        required={false}
                                                        disabled={formSettings?.isReadOnly}
                                                        className={!inputValidator("WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPvcPipeFacilityNumber") ? 
                                                            classes.invalidState + " " + classes.kTextbox : classes.kTextbox}
                                                        value={formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPvcPipeFacilityNumber}
                                                        onChange={(e) => changeValueHandler(e, "WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPvcPipeFacilityNumber")}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup} ${classes.fieldsGroupW100}`}>
                                            <div className={classes.fieldsGroupW50}>
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesSmallGameGuzzler}
                                                    label={"Small game guzzler"}
                                                    onChange={(e)=> changeValueHandler(e, "WellWindmillOverflowTypeOfWildlifeWateringFacilitiesSmallGameGuzzler")}
                                                />
                                            </div>
                                            { formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesSmallGameGuzzler &&
                                                <div className={classes.fieldsGroupW50}>                                        
                                                    <NumericTextBox
                                                        name={"WellWindmillOverflowTypeOfWildlifeWateringFacilitiesSmallGameGuzzlerNumber"}
                                                        required={false}
                                                        disabled={formSettings?.isReadOnly}
                                                        className={!inputValidator("WellWindmillOverflowTypeOfWildlifeWateringFacilitiesSmallGameGuzzlerNumber") ? 
                                                            classes.invalidState + " " + classes.kTextbox : classes.kTextbox}
                                                        value={formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesSmallGameGuzzlerNumber}
                                                        onChange={(e) => changeValueHandler(e, "WellWindmillOverflowTypeOfWildlifeWateringFacilitiesSmallGameGuzzlerNumber")}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup} ${classes.fieldsGroupW100}`}>
                                            <div className={classes.fieldsGroupW50}>
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPlasticContainer}
                                                    label={"Plastic container"}
                                                    onChange={(e)=> changeValueHandler(e, "WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPlasticContainer")}
                                                />
                                            </div>
                                            { formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPlasticContainer &&
                                                <div className={classes.fieldsGroupW50}>                                        
                                                    <NumericTextBox
                                                        name={"WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPlasticContainerNumber"}
                                                        required={false}
                                                        disabled={formSettings?.isReadOnly}
                                                        className={!inputValidator("WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPlasticContainerNumber") ? 
                                                            classes.invalidState + " " + classes.kTextbox : classes.kTextbox}
                                                        value={formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPlasticContainerNumber}
                                                        onChange={(e) => changeValueHandler(e, "WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPlasticContainerNumber")}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup} ${classes.fieldsGroupW100}`}>
                                            <div className={classes.fieldsGroupW50}>
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesBigGameGuzzler}
                                                    label={"Big game guzzler"}
                                                    onChange={(e)=> changeValueHandler(e, "WellWindmillOverflowTypeOfWildlifeWateringFacilitiesBigGameGuzzler")}
                                                />
                                            </div>
                                            { formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesBigGameGuzzler &&
                                                <div className={classes.fieldsGroupW50}>                                        
                                                    <NumericTextBox
                                                        name={"WellWindmillOverflowTypeOfWildlifeWateringFacilitiesBigGameGuzzlerNumber"}
                                                        required={false}
                                                        disabled={formSettings?.isReadOnly}
                                                        className={!inputValidator("WellWindmillOverflowTypeOfWildlifeWateringFacilitiesBigGameGuzzlerNumber") ? 
                                                            classes.invalidState + " " + classes.kTextbox : classes.kTextbox}
                                                        value={formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesBigGameGuzzlerNumber}
                                                        onChange={(e) => changeValueHandler(e, "WellWindmillOverflowTypeOfWildlifeWateringFacilitiesBigGameGuzzlerNumber")}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup} ${classes.fieldsGroupW100}`}>
                                            <div className={classes.fieldsGroupW50}>
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesFlyingSaucerGuzzler}
                                                    label={"Flying saucer guzzler"}
                                                    onChange={(e)=> changeValueHandler(e, "WellWindmillOverflowTypeOfWildlifeWateringFacilitiesFlyingSaucerGuzzler")}
                                                />
                                            </div>
                                            { formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesFlyingSaucerGuzzler &&
                                                <div className={classes.fieldsGroupW50}>                                        
                                                    <NumericTextBox
                                                        name={"WellWindmillOverflowTypeOfWildlifeWateringFacilitiesFlyingSaucerGuzzlerNumber"}
                                                        required={false}
                                                        disabled={formSettings?.isReadOnly}
                                                        className={!inputValidator("WellWindmillOverflowTypeOfWildlifeWateringFacilitiesFlyingSaucerGuzzlerNumber") ? 
                                                            classes.invalidState + " " + classes.kTextbox : classes.kTextbox}
                                                        value={formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesFlyingSaucerGuzzlerNumber}
                                                        onChange={(e) => changeValueHandler(e, "WellWindmillOverflowTypeOfWildlifeWateringFacilitiesFlyingSaucerGuzzlerNumber")}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>                                    
                                    <div className={classes.fieldsGroupW50}>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup} ${classes.fieldsGroupW100}`}>
                                            <div className={classes.fieldsGroupW50}>
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesDrumWithFaucetOrFloat}
                                                    label={"Drum with faucet or float"}
                                                    onChange={(e)=> changeValueHandler(e, "WellWindmillOverflowTypeOfWildlifeWateringFacilitiesDrumWithFaucetOrFloat")}
                                                />
                                            </div>
                                            { formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesDrumWithFaucetOrFloat &&
                                                <div className={classes.fieldsGroupW50}>                                        
                                                    <NumericTextBox
                                                        name={"WellWindmillOverflowTypeOfWildlifeWateringFacilitiesDrumWithFaucetOrFloatNumber"}
                                                        required={false}
                                                        disabled={formSettings?.isReadOnly}
                                                        className={!inputValidator("WellWindmillOverflowTypeOfWildlifeWateringFacilitiesDrumWithFaucetOrFloatNumber") ? 
                                                            classes.invalidState + " " + classes.kTextbox : classes.kTextbox}
                                                        value={formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesDrumWithFaucetOrFloatNumber}
                                                        onChange={(e) => changeValueHandler(e, "WellWindmillOverflowTypeOfWildlifeWateringFacilitiesDrumWithFaucetOrFloatNumber")}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup} ${classes.fieldsGroupW100}`}>
                                            <div className={classes.fieldsGroupW50}>
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesWindmillSupplyPipeDripper}
                                                    label={"Windmill supply pipe dripper"}
                                                    onChange={(e)=> changeValueHandler(e, "WellWindmillOverflowTypeOfWildlifeWateringFacilitiesWindmillSupplyPipeDripper")}
                                                />
                                            </div>
                                            { formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesWindmillSupplyPipeDripper &&
                                                <div className={classes.fieldsGroupW50}>                                        
                                                    <NumericTextBox
                                                        name={"WellWindmillOverflowTypeOfWildlifeWateringFacilitiesWindmillSupplyPipeDripperNumber"}
                                                        required={false}
                                                        disabled={formSettings?.isReadOnly}
                                                        className={!inputValidator("WellWindmillOverflowTypeOfWildlifeWateringFacilitiesWindmillSupplyPipeDripperNumber") ? 
                                                            classes.invalidState + " " + classes.kTextbox : classes.kTextbox}
                                                        value={formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesWindmillSupplyPipeDripperNumber}
                                                        onChange={(e) => changeValueHandler(e, "WellWindmillOverflowTypeOfWildlifeWateringFacilitiesWindmillSupplyPipeDripperNumber")}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup} ${classes.fieldsGroupW100}`}>
                                            <div className={classes.fieldsGroupW50}>
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesIngroundBowlTrough}
                                                    label={"In-ground bowl trough"}
                                                    onChange={(e)=> changeValueHandler(e, "WellWindmillOverflowTypeOfWildlifeWateringFacilitiesIngroundBowlTrough")}
                                                />
                                            </div>
                                            { formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesIngroundBowlTrough &&
                                                <div className={classes.fieldsGroupW50}>                                        
                                                    <NumericTextBox
                                                        name={"WellWindmillOverflowTypeOfWildlifeWateringFacilitiesIngroundBowlTroughNumber"}
                                                        required={false}
                                                        disabled={formSettings?.isReadOnly}
                                                        className={!inputValidator("WellWindmillOverflowTypeOfWildlifeWateringFacilitiesIngroundBowlTroughNumber") ? 
                                                            classes.invalidState + " " + classes.kTextbox : classes.kTextbox}
                                                        value={formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesIngroundBowlTroughNumber}
                                                        onChange={(e) => changeValueHandler(e, "WellWindmillOverflowTypeOfWildlifeWateringFacilitiesIngroundBowlTroughNumber")}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup} ${classes.fieldsGroupW100}`}>
                                            <div className={classes.fieldsGroupW50}>
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesInvertedUmbrellaGuzzler}
                                                    label={"Inverted umbrella guzzler"}
                                                    onChange={(e)=> changeValueHandler(e, "WellWindmillOverflowTypeOfWildlifeWateringFacilitiesInvertedUmbrellaGuzzler")}
                                                />
                                            </div>
                                            { formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesInvertedUmbrellaGuzzler &&
                                                <div className={classes.fieldsGroupW50}>                                        
                                                    <NumericTextBox
                                                        name={"WellWindmillOverflowTypeOfWildlifeWateringFacilitiesInvertedUmbrellaGuzzlerNumber"}
                                                        required={false}
                                                        disabled={formSettings?.isReadOnly}
                                                        className={!inputValidator("WellWindmillOverflowTypeOfWildlifeWateringFacilitiesInvertedUmbrellaGuzzlerNumber") ? 
                                                            classes.invalidState + " " + classes.kTextbox : classes.kTextbox}
                                                        value={formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesInvertedUmbrellaGuzzlerNumber}
                                                        onChange={(e) => changeValueHandler(e, "WellWindmillOverflowTypeOfWildlifeWateringFacilitiesInvertedUmbrellaGuzzlerNumber")}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup} ${classes.fieldsGroupW100}`}>
                                            <div className={classes.fieldsGroupW50}>
                                                <Checkbox
                                                    disabled={formSettings?.isReadOnly}
                                                    className={classes.Checkbox}
                                                    value={formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesRanchSpecialtiesGuzzler}
                                                    label={"Ranch Specialties guzzler"}
                                                    onChange={(e)=> changeValueHandler(e, "WellWindmillOverflowTypeOfWildlifeWateringFacilitiesRanchSpecialtiesGuzzler")}
                                                />
                                            </div>
                                            { formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesRanchSpecialtiesGuzzler &&
                                                <div className={classes.fieldsGroupW50}>                                        
                                                    <NumericTextBox
                                                        name={"WellWindmillOverflowTypeOfWildlifeWateringFacilitiesRanchSpecialtiesGuzzlerNumber"}
                                                        required={false}
                                                        disabled={formSettings?.isReadOnly}
                                                        className={!inputValidator("WellWindmillOverflowTypeOfWildlifeWateringFacilitiesRanchSpecialtiesGuzzlerNumber") ? 
                                                            classes.invalidState + " " + classes.kTextbox : classes.kTextbox}
                                                        value={formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesRanchSpecialtiesGuzzlerNumber}
                                                        onChange={(e) => changeValueHandler(e, "WellWindmillOverflowTypeOfWildlifeWateringFacilitiesRanchSpecialtiesGuzzlerNumber")}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup} ${classes.fieldsGroupW100}`}>
                                        <div className={classes.fieldsGroupW25}>
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesOther}
                                                label={"Other"}
                                                onChange={(e)=> changeValueHandler(e, "WellWindmillOverflowTypeOfWildlifeWateringFacilitiesOther")}
                                            />
                                        </div>
                                        { formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesOther &&
                                            <div className={classes.fieldsGroupW75}>
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"WellWindmillOverflowTypeOfWildlifeWateringFacilitiesOtherDescription"}
                                                    required={false}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesOtherDescription}
                                                    onChange={(e)=> changeValueHandler(e, "WellWindmillOverflowTypeOfWildlifeWateringFacilitiesOtherDescription")}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div></>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"WellWindmillOverflowAdditionalInformation"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.WellWindmillOverflowAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "WellWindmillOverflowAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.SpringDevelopmentEnhancement}
                                    label={"Spring development and/or enhancement"}
                                    onChange={(e)=> changeValueHandler(e, "SpringDevelopmentEnhancement")}
                                />
                            </div>
                            { formDataFields?.SpringDevelopmentEnhancement && 
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW100}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.SpringDevelopmentEnhancementTypes?.Fencing}
                                            label={"Fencing"}
                                            onChange={(e)=>
                                                changeValueHandler(e, "Fencing", "SpringDevelopmentEnhancementTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.SpringDevelopmentEnhancementTypes?.WaterDiversionPipeline}
                                            label={"Water diversion/pipeline"}
                                            onChange={(e)=> changeValueHandler(e, "WaterDiversionPipeline", "SpringDevelopmentEnhancementTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.SpringDevelopmentEnhancementTypes?.BrushRemoval}
                                            label={"Brush removal"}
                                            onChange={(e)=> changeValueHandler(e, "BrushRemoval", "SpringDevelopmentEnhancementTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.SpringDevelopmentEnhancementTypes?.SpringCleanOut}
                                            label={"Spring clean out"}
                                            onChange={(e)=> changeValueHandler(e, "SpringCleanOut", "SpringDevelopmentEnhancementTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.SpringDevelopmentEnhancementTypes?.Other}
                                            label={"Other"}
                                            onChange={(e)=> changeValueHandler(e, "Other", "SpringDevelopmentEnhancementTypes")}
                                        />
                                    </div>
                                    {formDataFields?.SpringDevelopmentEnhancementTypes?.Other && 
                                        <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Other (describe)
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"SpringDevelopmentEnhancementOther"}
                                            placeholder="Other (describe)"
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.SpringDevelopmentEnhancementOther}
                                            onChange={(e)=> changeValueHandler(e, "SpringDevelopmentEnhancementOther")}
                                        />
                                        </div>
                                    }
                                </div>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"SpringDevelopmentEnhancementAdditionalInformation"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.SpringDevelopmentEnhancementAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "SpringDevelopmentEnhancementAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    )
}