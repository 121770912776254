import { useEffect, useRef, useState } from "react";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import shared from "../PDF-Shared-style.module.scss";
import classes from "./AG-Form-PDF-style.module.scss";
import {IAgFormFirstSectionInterface} from "../../../interfaces/forms/AgForm/ag-form-first-section.interface";
import {IAgFormSecondSectionInterface} from "../../../interfaces/forms/AgForm/ag-form-second-section.interface";
import {IAgFormThirdSectionInterface} from "../../../interfaces/forms/AgForm/ag-form-third-section.interface";
import {IAgFormFourthSectionInterface} from "../../../interfaces/forms/AgForm/ag-form-fourth-section.interface";
import {IAgFormSixthSectionInterface} from "../../../interfaces/forms/AgForm/ag-form-sixth-section.interface";
import {IAgFormSeventhSectionInterface} from "../../../interfaces/forms/AgForm/ag-form-seventh-section.interface";
import {IAgFormEighthSectionInterface} from "../../../interfaces/forms/AgForm/ag-form-eighth-section.interface";
import {IAgFormNinthSectionInterface} from "../../../interfaces/forms/AgForm/ag-form-ninth-section.interface";
import {IAgFormTenthSectionInterface} from "../../../interfaces/forms/AgForm/ag-form-tenth-section.interface";
import { settingsService } from "../../../services/settings.service";

interface IFormFields {
  formDataFieldsFirstSection?: IAgFormFirstSectionInterface;
  formDataFieldsSecondSection?: IAgFormSecondSectionInterface;
  formDataFieldsThirdSection?: IAgFormThirdSectionInterface;
  formDataFieldsFourthSection?: IAgFormFourthSectionInterface;
  formDataFieldsSixthSection?: IAgFormSixthSectionInterface;
  formDataFieldsSeventhSection?: IAgFormSeventhSectionInterface;
  formDataFieldsEighthSection?: IAgFormEighthSectionInterface;
  formDataFieldsNinthSection?: IAgFormNinthSectionInterface;
  formDataFieldsTenthSection?: IAgFormTenthSectionInterface;
  getPdfBase64Url?: (url: string) => void;
}

export default function AGFormPDFView(props: IFormFields): JSX.Element {
  const {
    formDataFieldsFirstSection,
    formDataFieldsSecondSection,
    formDataFieldsThirdSection,
    formDataFieldsFourthSection,
    formDataFieldsSixthSection,
    formDataFieldsSeventhSection,
    formDataFieldsEighthSection,
    formDataFieldsNinthSection,
    formDataFieldsTenthSection,
    getPdfBase64Url,
  } = props;

  const templateRef = useRef(null);
  const [templateBase64, setTemplateBase64] = useState("");
  const settings = settingsService().settings;

  useEffect(() => {
    drawDOM(templateRef.current as any, { paperSize: "A4" })
      .then((group) => {
        if (templateRef.current) {
          const myReference = templateRef.current as any;
          myReference.style.display = "none";
        }
        return exportPDF(group);
      })
      .then((dataUri) => {
        setTemplateBase64(dataUri);
        if (getPdfBase64Url) {
          getPdfBase64Url(dataUri);
        }
      });
  }, []);

  return (
    <>
      {templateRef && templateBase64 && (
        <iframe
          style={{ width: "100%", height: "100%" }}
          src={templateBase64}
        />
      )}
      <div ref={templateRef} className={`${shared.FormContainer} ${shared.Form}`}>
        <div className={shared.PrintTogether}>
          <div className={`${shared.Row} ${shared.AlignCenter}`}>
            <div className={`${shared.Column9} ${shared.H3}`}>
              Application for 1-d-1 (Open-Space) Agricultural Use Appraisal
            </div>
            <div className={`${shared.Column2} ${shared.TextCenter} ${shared.FormVersion}`}>Form 50-129</div>
          </div>

          <div className={`${shared.Row} ${shared.SpaceBetween}`}>
            <div className={`${shared.ColOffset9} ${shared.Column3}`}>
              <div className={shared.Value}>
                {formDataFieldsFirstSection?.TaxYear}
              </div>
              <div className={shared.ValueLabelBelow}>Tax Year</div>
            </div>

            <div className={shared.Column8}>
              <div className={shared.Value}>
                {settings?.clientDisplayName}
              </div>
              <div className={shared.ValueLabelBelow}>Appraisal District’s Name</div>
            </div>

            <div className={shared.Column3}>
              <div className={shared.Value}>
                {formDataFieldsFourthSection?.PropertyId}
              </div>
              <div className={shared.ValueLabelBelow}>
                Appraisal District Account Number (if known)
              </div>
            </div>
          </div>

          <p>
            <b>GENERAL INFORMATION:&nbsp;</b> Texas Constitution, Article VIII,
            Section 1-d-1, and Tax Code, Chapter 23, Subchapter D, provide for
            appraisal of &nbsp; &nbsp; open-space land.
          </p>
          <p>
            <b>FILING INSTRUCTIONS:&nbsp;</b> This form must be filed with the
            appraisal district office in each county in which the property is
            located.{" "}
            <b>
              {" "}
              Do not file this document with the Texas Comptroller of Public
              Accounts.
            </b>
          </p>

          <div className={shared.Step}>SECTION 1: Property Owner/Applicant</div>
          <div>
            <div className={shared.TextSmall}>
              The applicant is the following type of property owner:
            </div>
            <div>
              <span>
                <i
                  className={
                    "fa " +
                    (formDataFieldsSecondSection?.["individual"]
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>
                Individual
              </span>
              &nbsp;&nbsp;
              <span>
                <i
                  className={
                    "fa " +
                    (formDataFieldsSecondSection?.["partnership"]
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>
                Partnership
              </span>
              &nbsp;&nbsp;
              <span>
                <i
                  className={
                    " fa " +
                    (formDataFieldsSecondSection?.["corporation"]
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>
                Corporation
              </span>
              &nbsp;&nbsp;
              <span>
                <i
                  className={
                    "fa " +
                    (formDataFieldsSecondSection?.["other"]
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>
                Other
              </span>
              &nbsp;
              <span className={shared.Value}>
                {formDataFieldsSecondSection?.OwnershipTypeOther}
              </span>
            </div>

            <div className={shared.Row}>
              <div className={shared.Column6}>
                <div className={shared.Value}>
                  {formDataFieldsSecondSection?.PropertyOwnerName}
                </div>
                <div className={shared.ValueLabelBelow}>Name of Property Owner</div>
              </div>
              <div className={shared.Column3}>
                <div className={shared.Value}>
                  {formDataFieldsSecondSection?.PropertyOwnerBirthDate}
                </div>
                <div className={shared.ValueLabelBelow}>Date of Birth</div>
              </div>
              <div className={shared.Column12}>
                <div className={shared.Value}>
                  {formDataFieldsSecondSection?.PropertyOwnerPhysicalAddressLine1}{" "} &nbsp;
                  {formDataFieldsSecondSection?.PropertyOwnerPhysicalAddressLine2 && (
                    <span>
                      {formDataFieldsSecondSection?.PropertyOwnerPhysicalAddressLine2}{" "} &nbsp;
                    </span>
                  )}
                  {formDataFieldsSecondSection?.PropertyOwnerPhysicalAddressCity}{" "} &nbsp;
                  {formDataFieldsSecondSection?.PropertyOwnerPhysicalAddressState}, &nbsp;
                  {formDataFieldsSecondSection?.PropertyOwnerPhysicalAddressZip} &nbsp;
                </div>

                <div className={shared.ValueLabelBelow}>
                  Physical Address, City, State, ZIP Code
                </div>
              </div>

              <div className={shared.Column6}>
                <div className={shared.Value}>
                  {formDataFieldsSecondSection?.PropertyOwnerPhoneNumber}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Primary Phone Number (area code and number)
                </div>
              </div>
              <div className={shared.Column6}>
                <div className={shared.Value}>
                  {formDataFieldsSecondSection?.PropertyOwnerEmailAddress}
                </div>
                <div className={shared.ValueLabelBelow}>Email Address</div>
              </div>

              <div className={shared.Column12}>
                <div className={shared.Value}>
                  {formDataFieldsSecondSection?.PropertyOwnerMailingAddressLine1}{" "} &nbsp;
                  {formDataFieldsSecondSection?.PropertyOwnerMailingAddressLine2 && (
                    <span>
                      {formDataFieldsSecondSection?.PropertyOwnerMailingAddressLine2}
                    </span>
                  )}
                  {formDataFieldsSecondSection?.PropertyOwnerMailingAddressCity}{" "} &nbsp;
                  {formDataFieldsSecondSection?.PropertyOwnerMailingAddressState}, &nbsp;
                  {formDataFieldsSecondSection?.PropertyOwnerMailingAddressZip} &nbsp;
                </div>
                <div className={shared.ValueLabelBelow}>
                  Mailing Address, City, State, ZIP Code (if different from the
                  physical address provided above)
                </div>
              </div>
            </div>
          </div>

          <div className={shared.Step}>SECTION 2: Authorized Representative</div>
          <div>
            <p>
              <b>
                If you are an individual property owner filing this application
                on your own behalf, skip to Section 3; all other applicants are
                required to complete Section 2.
              </b>
            </p>
            <div className={shared.TextSmall}>
              Please indicate the basis for your authority to represent the
              property owner in filing this application:
            </div>

            <div className={shared.Row}>
              <span className={shared.Column4}>
                <i
                  className={
                    "fa " +
                    (formDataFieldsThirdSection?.AuthorizedRepresentativeType === "OfficerOfTheCompany"
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>
                Officer of the company
              </span>
              &nbsp;&nbsp;
              <span className={shared.Column4}>
                <i
                  className={
                    " fa " +
                    (formDataFieldsThirdSection?.AuthorizedRepresentativeType === "GeneralPartnerOfTheCompany"
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>
                General Partner of the company
              </span>
              &nbsp;&nbsp;
              <span className={shared.Column4}>
                <i
                  className={
                    " fa " +
                    (formDataFieldsThirdSection?.AuthorizedRepresentativeType === "AttorneyForTheCompany"
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>
                Attorney for the company
              </span>
            </div>

            <div className={shared.Row}>
              <span className={shared.Column12}>
                <i
                  className={
                    " fa " +
                    (formDataFieldsThirdSection?.AuthorizedRepresentativeType === "Agent"
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>
                Agent for tax matters appointed under Tax Code Section 1.111
                with completed and signed Form 50-162
              </span>
            </div>

            <div className={shared.Row}>
              <div className={shared.Column12}>
                {" "}
                <span>
                  <i
                    className={
                      " fa " +
                      (formDataFieldsThirdSection?.AuthorizedRepresentativeType === "Other"
                        ? "fa-check-square-o"
                        : "fa-square-o")
                    }
                  ></i>
                  Other and explain basis: &nbsp; &nbsp;
                </span>
                <span className={shared.Value}>
                  {formDataFieldsThirdSection?.AuthorizedRepresentativeTypeOther}
                </span>
              </div>
            </div>

            <div className={shared.Row}>
              <div className={shared.Column6}>
                <div className={shared.Value}>
                  {formDataFieldsThirdSection?.RepresentativeName}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Name of Authorized Representative
                </div>
              </div>
            </div>
            <div className={`${shared.Row} ${shared.SpaceBetween}`}>
              <div className={shared.Column4}>
                <div className={shared.Value}>
                  {formDataFieldsThirdSection?.RepresentativeTitle}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Title of Authorized Representative
                </div>
              </div>
              <div className={shared.Column4}>
                <div className={shared.Value}>
                  {formDataFieldsThirdSection?.RepresentativePhoneNumber}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Primary Phone Number (area code and number)
                </div>
              </div>
              <div className={shared.Column4}>
                <div className={shared.Value}>
                  {formDataFieldsThirdSection?.RepresentativeEmailAddress}
                </div>
                <div className={shared.ValueLabelBelow}>Email Address</div>
              </div>

              <div className={shared.Column12}>
                <div className={shared.Value}>
                  {formDataFieldsThirdSection?.RepresentativeMailingAddressLine1}{" "} &nbsp;
                  {formDataFieldsThirdSection?.RepresentativeMailingAddressLine2 && (
                    <span>
                      { formDataFieldsThirdSection?.RepresentativeMailingAddressLine2 }{" "} &nbsp;
                    </span>
                  )}
                  {formDataFieldsThirdSection?.RepresentativeMailingAddressCity}{" "} &nbsp;
                  {formDataFieldsThirdSection?.RepresentativeMailingAddressState} , &nbsp;
                  { formDataFieldsThirdSection?.RepresentativeMailingAddressZip }{" "} &nbsp;
                </div>
                <div className={shared.ValueLabelBelow}>
                  Mailing Address, City, State, ZIP Code
                </div>
              </div>
            </div>
          </div>

          <div className={shared.Step}>
            SECTION 3: Property Description and Information
          </div>
          <div>
            <div className={shared.TextSmall}>
              Please indicate the basis for your authority to represent the
              property owner in filing this application:
            </div>
            <br />
            <div className={shared.Row1}>
              <div className={shared.Column5}>
                <div className={shared.Value}>
                  {formDataFieldsFourthSection?.PropertyId}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Account Number (if known)
                </div>
              </div>
              <div className={shared.Column5}>
                <div className={shared.Value}>
                  {formDataFieldsFourthSection?.["TotalAcreage"]}
                </div>
                <div className={shared.ValueLabelBelow}>
                  Number of Acres (subject to this application)
                </div>
              </div>
            </div>
            <br />
            <div className={shared.Row}>
              <div className={`${shared.TextSmall} ${shared.Column11}`}>
                Legal Description, abstract numbers, field numbers and/or plat
                numbers
              </div>
              <div className={shared.Column11}>
                <div className={shared.TextArea}>
                  {formDataFieldsFourthSection?.PropertyLegalDescription}
                </div>
              </div>
            </div>
            <div className={shared.PageFooter}>
              <hr className={shared.hr} />
              <div className={shared.Row} >
                <div className={`col-6 ${shared.Font7} ${shared.Footer}`}>
                  Form developed by: Texas Comptroller of Public Accounts, Property
                  Tax Assistance Division
                </div>
                <div className={`col-5 ${shared.Font8} ${shared.Footer}`}>
                  For additional copies, visit:{" "}
                  <b>comptroller.texas.gov/taxes/property-tax</b>
                </div>
                <div className={`${shared.Column12} ${shared.Font8} ${shared.TextRight}`}>
                  50-129 1-22/20
                </div>
              </div>
            </div>
            <div className={shared.PageBreak}></div>
          </div>
          <br />
          <br/>
          <br />
          <div className={shared.Step}>
            SECTION 3: Property Description and Information (concluded)
          </div>
          <div>
            <div className={shared.TextSmall}>
              Select the appropriate box in response to each question below.
            </div>
            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>1.</div>
              <div className={`${shared.Column8} ${shared.TextSmall}`}>
                Has the ownership of the property changed since Jan. 1 of last
                year or since the last application was submitted?
              </div>
              <div className={`${shared.Column3} ${shared.TextRight}`}>
                <i
                  className={
                    "fa " +
                    (formDataFieldsFourthSection?.HasPropertyOwnershipChanged == true
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                  className={
                    "fa " +
                    (formDataFieldsFourthSection?.HasPropertyOwnershipChanged == false
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; No
              </div>
              <div className={`${shared.Column11} ${shared.TextSmall} ${shared.MarginLeft20}`}>
                If yes, the new owner must complete all applicable questions
                and, if the land is used to manage wildlife, sections 4 and 5
                must be completed.
              </div>
            </div>
            <br />
            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>2.</div>
              <div className={`${shared.Column8} ${shared.TextSmall}`}>
                Last year, was 1-d-1 appraisal allowed on this property by the
                chief appraiser of this appraisal district?
              </div>
              <div className={`${shared.Column3} ${shared.TextRight}`}>
                <i
                  className={
                    "fa " +
                    (formDataFieldsFourthSection?.WasAllowed1D1Appraisal == true
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                  className={
                    " fa " +
                    (formDataFieldsFourthSection?.WasAllowed1D1Appraisal == false
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; No
              </div>
              <div className={`${shared.Column11} ${shared.TextSmall} ${shared.MarginLeft20}`}>
                If no, all applicable questions must be completed and, if the
                land is used to manage wildlife, sections 4 and 5 must be
                completed.
              </div>
              <div className={`${shared.Column11} ${shared.TextSmall} ${shared.MarginLeft20}`}>
                If yes, complete only those parts of sections 4 and 5 that have
                changed since the earlier application or any information in
                sections 4 and 5 requested by the &nbsp; &nbsp; chief appraiser.
              </div>
            </div>
            <br />
            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>3.</div>
              <div className={`${shared.Column8} ${shared.TextSmall}`}>
                Is this property located within the corporate limits of a city
                or town?
              </div>
              <div className={`${shared.Column3} ${shared.TextRight}`}>
                <i
                  className={
                    "fa " +
                    (formDataFieldsFourthSection?.IsThisPropertyLocatedWithinTheCorporateLimitsOfaCityorTown == true
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                  className={
                    " fa " +
                    (formDataFieldsFourthSection?.IsThisPropertyLocatedWithinTheCorporateLimitsOfaCityorTown == false
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; No
              </div>
            </div>
          </div>

          <div className={shared.Step}>SECTION 4: Property Use</div>
          <div>
            <div className={shared.TextSmall}>
              Provide complete answers to the following questions. List the
              agricultural use of the property according to the agricultural
              land categories listed in the important information section of
              this form. Divide the total acreage according to individual uses
              to which the land is principally devoted.
            </div>

            <ol className={shared.OrderedList}>
              <li>
                <div className={shared.TextSmall}>
                  Describe the current and past agricultural uses of this
                  property as described in Section 3, starting with the current
                  year and working back 5 years or until 5 out of 7 years of
                  agricultural use is shown. Five years of continuous
                  agricultural use may be required if the land is located within
                  the corporate limits of a city or town.
                </div>
                <table className={shared.Blue}>
                  <tr>
                    <th className={`${shared.Width10} ${shared.TextCenter}`}>Year</th>
                    <th className={shared.TextCenter}>
                      Agricultural Use Category of Land (Lists all that apply)
                    </th>
                    {formDataFieldsSixthSection?.AgriculturalUseCategoriesOfLand?.some(item => item.Category === 'Other') &&
                      <th className={`${shared.Width20} ${shared.TextCenter}`}>Other Category</th>
                    }
                    <th className={`${shared.Width20} ${shared.TextCenter}`}>
                      Acres Principally Devoted to Agricultural Use
                    </th>
                  </tr>
                  {formDataFieldsSixthSection?.AgriculturalUseCategoriesOfLand?.map((item) => {
                    return (
                      <tr key={item.Id}>
                        <td className={`${shared.Width10} ${shared.TextCenter}`}>
                          {item?.Year}
                        </td>
                        <td className={shared.TextCenter}>
                          {item?.Category}
                        </td>
                        {formDataFieldsSixthSection?.AgriculturalUseCategoriesOfLand?.some(item => item.Category === 'Other') &&
                          <td className={shared.TextCenter}>
                            {item?.OtherCategory}
                          </td>
                        }
                        <td className={`${shared.Width20} ${shared.TextCenter}`}>
                          {item?.Acres}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </li>

              <li>
                <div className={shared.TextSmall}>
                  (a) List the livestock, exotic animals or exotic fowl raised
                  or the type of wildlife managed on the property and the number
                  of acres used for each activity. Attach a list if the space is
                  not sufficient.
                </div>

                <table className={shared.Blue}>
                  <tr>
                    <th className={shared.TextCenter}>
                      Livestock, Exotic or Wildlife
                    </th>
                    <th className={`${shared.Width20} ${shared.TextCenter}`}>Number of Acres</th>
                  </tr>
                  {formDataFieldsSixthSection?.AgriculturalUseLivestockExoticsWildlifeTypeByAcres?.map((item) => {
                    return (
                      <tr key={item.Id}>
                        <td className={shared.TextCenter}>
                          {item?.Type}
                        </td>
                        <td className={`${shared.Width20} ${shared.TextCenter}`}>
                          {item?.Acres}
                        </td>
                      </tr>
                    );
                  })}
                </table>

                <div className={shared.TextSmall}>
                  (b) How many head of livestock or exotic animals are raised on
                  the property (average per year)?
                </div>

                <table className={shared.Blue}>
                  <tr>
                    <th className={shared.TextCenter}>Livestock or Exotics</th>
                    <th className={`${shared.Width20} ${shared.TextCenter}`}>Number of Head</th>
                  </tr>
                  {formDataFieldsSixthSection?.AgriculturalUseLivestockExoticsWildlifeTypeByHead?.map((item) => {
                    return (
                      <tr key={item.Id}>
                        <td className={shared.TextCenter}>
                          {item?.Type}
                        </td>
                        <td className={`${shared.Width20} ${shared.TextCenter}`}>
                          {item?.Head}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </li>

              <li>
                <div className={shared.TextSmall}>
                  List the crops grown (including ornamental plants, flowers or
                  grapevines) and the number of acres devoted to each crop.
                  Attach a list if the space is not sufficient.
                </div>
                <table className={shared.Blue}>
                  <tr>
                    <th className={shared.TextCenter}>Type of Crop</th>
                    <th className={`${shared.Width20} ${shared.TextCenter}`}>Number of Acres</th>
                  </tr>
                  {formDataFieldsSixthSection?.AgriculturalUseCopsByAcre?.map((item) => {
                    return (
                      <tr key={item.Id}>
                        <td className={shared.TextCenter}>
                          {item?.Type}
                        </td>
                        <td className={`${shared.Width20} ${shared.TextCenter}`}>
                          {item?.Acres}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </li>

              <li>
                <div className={shared.TextSmall}>
                  List the participation in any government programs for planting
                  cover crops or land lying idle and the number of acres devoted
                  to each program. Attach a list if the space is not sufficient.
                </div>
                <table className={shared.Blue}>
                  <tr>
                    <th className={shared.TextCenter}>Program Name</th>
                    <th className={`${shared.Width20} ${shared.TextCenter}`}>Number of Acres</th>
                  </tr>
                  {formDataFieldsSixthSection?.AgriculturalUseGovernmentalPrograms?.map((item) => {
                    return (
                      <tr key={item.Id}>
                        <td className={shared.TextCenter}>
                          {item?.Name}
                        </td>
                        <td className={`${shared.Width20} ${shared.TextCenter}`}>
                          {item?.Acres}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </li>

              <li>
                <div className={shared.TextSmall}>
                  If the property is now used for any nonagricultural
                  activities, list all nonagricultural uses and the number of
                  acres devoted to each use. Attach a list if the space is not
                  sufficient.
                </div>
                <table className={shared.Blue}>
                  <tr>
                    <th className={shared.TextCenter}>Nonagricultural Use</th>
                    <th className={`${shared.Width20} ${shared.TextCenter}`}>Number of Acres</th>
                  </tr>
                  {formDataFieldsSixthSection?.NonAgriculturalUse?.map((item) => {
                    return (
                      <tr key={item.Id}>
                        <td className={shared.TextCenter}>
                          {item?.Type}
                        </td>
                        <td className={`${shared.Width20} ${shared.TextCenter}`}>
                          {item?.Acres}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </li>
            </ol>
          </div>

          <div className={shared.Step}>SECTION 5: Wildlife Management Use</div>
          <div>
            <div className={shared.TextSmall}>
              <b>
                Complete this section only if the land is used for wildlife
                management. If the land is not used for wildlife management, do
                not complete this section.
              </b>
            </div>
            <br />
            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>1.</div>
              <div className={`${shared.Column11} ${shared.TextSmall}`}>
                If the land is used to manage wildlife, list at least three of
                the wildlife management practices being used (listed and
                described in the important information section of this form).
              </div>
              <ol className={shared.AlphaList} type="A">
                <li>
                  <div className={shared.Value}>
                    {formDataFieldsSeventhSection?.["WildlifeManagementPracticeA"]}
                  </div>
                </li>

                <li>
                  <div className={shared.Value}>
                    {formDataFieldsSeventhSection?.["WildlifeManagementPracticeB"]}
                  </div>
                </li>

                <li>
                  <div className={shared.Value}>
                    {formDataFieldsSeventhSection?.["WildlifeManagementPracticeC"]}
                  </div>
                </li>
              </ol>
            </div>
            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>2.</div>
              <div className={`${shared.Column11} ${shared.TextSmall}`}>
                Indicate the property&apos;s agricultural land use category
                (described in the important information section of this form)
                for the tax year preceding the land&apos;s conversion to
                wildlife management use. For example, if the land was
                categorized as native pasture before conversion to wildlife
                management, native pasture would be the response to this request
                as it is the category of use prior to conversion.
              </div>
              <div className={`${shared.AlphaList} ${shared.Value} ${shared.FullLineValue} ${shared.MarginLeft20}`}>
                {formDataFieldsSeventhSection?.["AgriculturalLandUseCategory"]}
              </div>
            </div>
            <br />
            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>3.</div>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                Does the property have a wildlife management plan that uses the appropriate Texas Parks and Wildlife Department form?
                <div className={shared.TextSmall}>
                  If yes, attach the wildlife management plan for the property using the
                  appropriate Texas Parks & Wildlife Department form (obtained at
                  &nbsp;
                  <a href="src/pages/Shared/AgFormPDFView/AGFormPDFView">
                    www.tpwd.texas.gov/landwater/land/private/agricultural_land/
                  </a>
                  ).
                </div>
              </div>

              <div className={`${shared.Column2} ${shared.TextRight}`}>
                <i
                    className={
                        "fa " +
                        (formDataFieldsSeventhSection?.wildlifeManagementPlanUsesTheAppropriateTexasParksandWildlifeDepartmen == true
                            ? "fa-check-square-o"
                            : "fa-square-o")
                    }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                    className={
                        " fa " +
                        (formDataFieldsSeventhSection?.wildlifeManagementPlanUsesTheAppropriateTexasParksandWildlifeDepartmen == false
                            ? "fa-check-square-o"
                            : "fa-square-o")
                    }
                ></i>{" "}
                &nbsp; No
              </div>
            </div>

            <br />
            <br />

            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>4.</div>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                Was the land subject to wildlife management a part of a larger
                tract of land qualified for 1-d-1 or timberland appraisal on
                Jan. 1 of the previous year?
              </div>
              <div className={`${shared.Column2} ${shared.TextRight}`}>
                <i
                  className={
                    "fa " +
                    (formDataFieldsSeventhSection?.["LandWasPartOfLargerTract"] == true
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                  className={
                    " fa " +
                    (formDataFieldsSeventhSection?.["LandWasPartOfLargerTract"] == false
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; No
              </div>
            </div>
            <br />
            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>5.</div>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                Is any part of the land subject to wildlife management managed
                through a wildlife management property association?
              </div>
              <div className={`${shared.Column2} ${shared.TextRight}`}>
                <i
                  className={
                    "fa " +
                    (formDataFieldsSeventhSection?.["PartOfLandIsManagedThroughPropertyAssociation"] == true
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                  className={
                    " fa " +
                    (formDataFieldsSeventhSection?.["PartOfLandIsManagedThroughPropertyAssociation"] == false
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; No
              </div>
              <div className={`${shared.Column11} ${shared.TextSmall} ${shared.MarginLeft20}`}>
                If yes, attach a written agreement obligating the owners in the
                association to perform wildlife management practices necessary
                to qualify wildlife management land for 1-d-1 appraisal.
              </div>
            </div>
            <br />
            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>6.</div>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                Is any part of the land located in an area designated by Texas
                Parks and Wildlife Department as a habitat for an endangered
                species, a threatened species or a candidate species for listing
                by as threatened or endangered?
              </div>
              <div className={`${shared.Column2} ${shared.TextRight}`}>
                <i
                  className={
                    "fa " +
                    (formDataFieldsSeventhSection?.["PartOfLandIsInHabitatForEndangeredSpecies"] == true
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                  className={
                    " fa " +
                    (formDataFieldsSeventhSection?.["PartOfLandIsInHabitatForEndangeredSpecies"] == false
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; No
              </div>
            </div>
            <br />
            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>7.</div>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                (a) Is the land that is the subject of this application subject
                to a permit issued under Federal Endangered Species Act Section
                &nbsp; &nbsp; 7 or 10(a)?
              </div>
              <div className={`${shared.Column2} ${shared.TextRight}`}>
                <i
                  className={
                    "fa " +
                    (formDataFieldsSeventhSection?.["LandIsSubjectToPermit7or10a"] == true
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                  className={
                    " fa " +
                    (formDataFieldsSeventhSection?.["LandIsSubjectToPermit7or10a"] == false
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; No
              </div>
            </div>
            <br />
            <div className={shared.Row}>
              <div className={`${shared.Column9} ${shared.TextSmall} ${shared.MarginLeft20}`}>
                (b) If yes, is the land included in a habitat preserve and
                subject to a conservation easement created under Texas Natural
                Resources Code Chapter 183 or part of a conservation development
                under a federally approved habitat conservation plan?
              </div>
              <div className={`${shared.Column2} ${shared.TextRight}`}>
                <i
                  className={
                    "fa " +
                    (formDataFieldsSeventhSection?.["LandIsIncludedInHabitatPreserve"] == true
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                  className={
                    " fa " +
                    (formDataFieldsSeventhSection?.["LandIsIncludedInHabitatPreserve"] == false
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; No
              </div>
            </div>
            <br />
            <div className={shared.Row}>
              <div className={`${shared.Column11} ${shared.TextSmall} ${shared.MarginLeft20}`}>
                &nbsp; &nbsp; If yes to questions 7(a) and (b), provide evidence
                of the permit and of the conservation easement or habitat
                conservation plan. Your application cannot be approved without
                this evidence.
              </div>
            </div>
            <br />
            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>8.</div>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                Is the land that is the subject of this application actively
                used for a conservation or restoration project providing
                compensation for natural resources damage under one or more of
                the following laws:
              </div>
              <div className={`${shared.Column9} ${shared.TextSmall} ${shared.MarginLeft20}`}>
                Comprehensive Environmental Response, Compensation, and
                Liability Act (42 U.S.C. Section 9601 et seq.)
              </div>
              <div className={`${shared.Column2} ${shared.TextRight}`}>
                <i
                  className={
                    "fa " +
                    (formDataFieldsSeventhSection?.["IsComprehensiveEnvironmentalResponse"] == true
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                  className={
                    " fa " +
                    (formDataFieldsSeventhSection?.["IsComprehensiveEnvironmentalResponse"] == false
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; No
              </div>
              <div className={`${shared.Column9} ${shared.TextSmall} ${shared.MarginLeft20}`}>
                Oil Pollution Act (33 U.S.C. Section 2701 et seq.)
              </div>
              <div className={`${shared.Column2} ${shared.TextRight}`}>
                <i
                  className={
                    "fa " +
                    (formDataFieldsSeventhSection?.["IsOilPollutionAct"] == true
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                  className={
                    " fa " +
                    (formDataFieldsSeventhSection?.["IsOilPollutionAct"] == false
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; No
              </div>
              <div className={`${shared.Column9} ${shared.TextSmall} ${shared.MarginLeft20}`}>
                Federal Water Pollution Control Act (33 U.S.C. Section 1251 et
                seq.)
              </div>
              <div className={`${shared.Column2} ${shared.TextRight}`}>
                <i
                  className={
                    "fa " +
                    (formDataFieldsSeventhSection?.["IsFederalWaterPollutionAct"] == true
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                  className={
                    " fa " +
                    (formDataFieldsSeventhSection?.["IsFederalWaterPollutionAct"] == false
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; No
              </div>
              <div className={`${shared.Column9} ${shared.TextSmall} ${shared.MarginLeft20}`}>
                Texas Natural Resources Code Chapter 40
              </div>
              <div className={`${shared.Column2} ${shared.TextRight}`}>
                <i
                  className={
                    "fa " +
                    (formDataFieldsSeventhSection?.["IsChapter40"] == true
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                  className={
                    " fa " +
                    (formDataFieldsSeventhSection?.["IsChapter40"] == false
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; No
              </div>
            </div>
            <br />
            <div className={shared.Row}>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                If yes to any of the above, provide evidence of the conservation
                easement, deed restriction or settlement agreement with the
                Texas Commission on Environmental Qualify. Applications without
                this evidence cannot be approved.
              </div>
            </div>
          </div>

          <div className={shared.Step}>SECTION 6: Conversion to Timber Production</div>
          <div>
            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>1.</div>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                Was the land subject to this application converted to timber
                production after Sept. 1, 1997?
              </div>
              <div className={`${shared.Column2} ${shared.TextRight}`}>
                <i
                  className={
                    "fa " +
                    (formDataFieldsEighthSection?.LandWasConvertedToTemberAfter1997 == true
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                  className={
                    " fa " +
                    (formDataFieldsEighthSection?.LandWasConvertedToTemberAfter1997 == false
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; No
              </div>
            </div>
            <br />
            <div className={`${shared.Row} ${shared.SpaceBetween}`}>
              <div className={`${shared.Column8} ${shared.TextSmall} ${shared.MarginLeft20}`}>
                If yes, on what date was it converted to timber production?
              </div>
              <div className={`${shared.Column2} ${shared.TextRight}`}>
                <div className={shared.Value}>
                  {formDataFieldsEighthSection?.["DateConvertedToTember"]}
                </div>
              </div>
            </div>
            <br />
            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>2.</div>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                Does the property owner wish to have the land subject to this
                application continue to be appraised as 1-d-1 land?
              </div>
              <div className={`${shared.Column2} ${shared.TextRight}`}>
                <i
                  className={
                    "fa " +
                    (formDataFieldsEighthSection?.WantsLandToBeAppraisedAs1D1 == true
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; Yes &nbsp; &nbsp;
                <i
                  className={
                    " fa " +
                    (formDataFieldsEighthSection?.WantsLandToBeAppraisedAs1D1 == false
                      ? "fa-check-square-o"
                      : "fa-square-o")
                  }
                ></i>{" "}
                &nbsp; No
              </div>
            </div>
            <br />
          </div>

          <div className={shared.Step}>SECTION 7: Certification and Signature</div>
          <div className={shared.PageBreak}>
            <div>
              <b>
                NOTICE REGARDING PENALTIES FOR MAKING OR FILING AN APPLICATION
                CONTAINING A FALSE STATEMENT: If you make a false statement on
                this form, you could be found guilty of a Class A misdemeanor or
                a state jail felony under Penal Code Section 37.10.
              </b>
            </div>

            <br />
            <div className={shared.PrintedName}>
              &quot;I{" "}
              <div className={shared.Width60}>
                {" "}
                <div className={shared.Value}>
                  {formDataFieldsTenthSection?.["Signature"]}
                </div>
                <div className={`${shared.ValueLabelBelow} ${shared.TextCenter}`}>
                  Appraisal District’s Name
                </div>
              </div>
              , swear or affirm the following:
            </div>
            <br />
            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>1.</div>
              <div className={`${shared.Column9} ${shared.TextSmall}`}>
                that each fact contained in this application is true and
                correct;
              </div>
            </div>

            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>2.</div>
              <div className={`${shared.Column11} ${shared.TextSmall}`}>
                that the property described in this application meets the
                qualifications under Texas law for the special appraisal
                claimed;
              </div>
            </div>

            <div className={shared.Row}>
              <div className={`${shared.NumericTitle} ${shared.TextSmall}`}>3.</div>
              <div className={`${shared.Column11} ${shared.TextSmall}`}>
                that I have read and understand the Notice Regarding Penalties
                for Making or Filing an Application Containing a False
                Statement.&quot;
              </div>
            </div>
            <br />

            <div className={`${shared.Row} ${shared.SpaceBetween}`}>
              <div className={shared.Column8}>
                <div className={shared.Value}>
                  {formDataFieldsTenthSection?.["Signature"]}
                </div>
                <div className={`${shared.ValueLabelBelow} ${shared.TextCenter}`}>
                  Signature of Property Owner or Authorized Representative
                </div>
              </div>
              <div className={shared.Column3}>
                <div className={shared.Value}>
                  {formDataFieldsTenthSection?.["SignatureDate"] ? new Date(formDataFieldsTenthSection?.["SignatureDate"]).toDateString() : ""}
                </div>
                <div className={`${shared.ValueLabelBelow} ${shared.TextCenter}`}>Date</div>
              </div>
            </div>

            <br />
            <div className={shared.Row}>
              <div className={`${shared.Column11} ${shared.TextSmall}`}>
                &quot; An email address of a member of the public could be
                confidential under Government Code Section 552.137; however, by
                including the email address on this form, you are affirmatively
                consenting to its release under the Public Information Act.
              </div>
            </div>
          </div>

          <div className={shared.PrintTogether}>
            <div className={`${shared.H3} ${shared.TextCenter}`}>Important Information</div>
            <div className={shared.Row}>
              <div className={shared.Column6}>
                <div className={shared.SubHeader}>GENERAL INFORMATION</div>
                <div className={shared.TextSmall}>
                  Agricultural use includes, but is not limited to, the
                  following activities: (1) cultivating the soil; (2) producing
                  crops for human food, animal feed, or planting seed or for the
                  production of fibers; (3) floriculture, viticulture and
                  horticulture; (4) raising or keeping livestock; (5) raising or
                  keeping exotic animals or fowl for the production of human
                  food or fiber, leather, pelts or other tangible products
                  having a commercial value; (6) planting cover crops or leaving
                  land idle for the purpose of participating in a governmental
                  program provided the land is not used for residential purposes
                  or a purpose inconsistent with agricultural use or leaving the
                  land idle in conjunction with normal crop or livestock
                  rotation procedures; (7) producing or harvesting logs and
                  posts used for construction or repair of fences, pens, barns
                  or other agricultural improvements on adjacent open-space land
                  having the same owner and devoted to a different agricultural
                  use; (8) wildlife management; and (9) beekeeping.
                </div>
                <br />
                <div className={shared.TextSmall}>
                  Wildlife management is defined as actively using land that at
                  the time the wildlife-management use began, was appraised as
                  qualified open-space or timberland under Tax Code, Chapter 23,
                  Subchapter D or E, to propagate a sustaining breeding,
                  migrating or wintering population of indigenous wild animals
                  for human use, including food, medicine or recreation, in at
                  least three of the following ways: (1) habitat control; (2)
                  erosion control; (3) predator control; (4) providing
                  supplemental supplies of water; (5) providing supplement
                  supplies of food; (6) providing shelters; and (7) making
                  census counts to determine population.
                </div>
                <br />
                <div className={shared.TextSmall}>
                  Wildlife management is defined as actively using land to
                  protect federally listed endangered species under a federal
                  permit if the land is included in a habitat preserve subject
                  to a conservation easement created under Natural Resources
                  Code Chapter 183 or part of a conservation development under a
                  federally approved habitat conservation plan restricting the
                  use of the land to protect federally listed endangered species
                  or actively using land for a conservation or restoration
                  project under certain federal and state statutes. These two
                  types of wildlife management uses do not require showing a
                  history of agricultural use but do require evidence identified
                  in section 6, questions 7 and 8.
                </div>
                <br />
                <div className={shared.TextSmall}>
                  Agricultural land use categories include: (1) irrigated
                  cropland; (2) dry cropland; (3) improved pastureland; (4)
                  native pastureland; (5) orchard; (6) wasteland; (7) timber
                  production; (8) wildlife management; and (9) other categories
                  of land that are typical in the area.
                </div>
                <br />
                <div className={shared.SubHeader}>APPLICATION DEADLINES</div>
                <div className={shared.TextSmall}>
                  The completed application must be filed with the chief
                  appraiser before May 1 of the year for which agricultural
                  appraisal is requested. If the application is approved, a new
                  application is not required in later years unless the land
                  ownership changes, eligibility ends or the chief appraiser
                  requests a new application.
                </div>
                <br />
                <div className={shared.TextSmall}>
                  A late application may be filed up to midnight the day before
                  the appraisal review board approves appraisal records for the
                  year, which usually occurs in July. If a late application is
                  approved, a penalty will be applied in an amount equal to 10
                  percent of the difference between the amount of tax imposed on
                  the property and the amount that would be imposed if the
                  property were taxed at market value.
                </div>
              </div>

              <div className={shared.Column6}>
                <div className={shared.SubHeader}>DUTY TO NOTIFY AND PENALTIES:</div>
                <div className={shared.TextSmall}>
                  <div>
                    The property owner must notify the chief appraiser no later
                    than the April 30 following the change in use or
                    eligibility. A change of land use for all or part of the
                    property will trigger substantial additional tax plus
                    interest (a rollback tax). Payment of a penalty may also be
                    required for failure to notify the chief appraiser of a
                    change in agricultural use or qualification. Notice must be
                    delivered to the chief appraiser if:
                  </div>
                  <br />
                  <ul className={shared.UnorderedList}>
                    <li>
                      the property stops being used for agriculture (e.g.,
                      voluntarily stopped farming);
                    </li>
                    <br />
                    <li>
                      category of land use changes (e.g., from dry cropland to
                      irrigated cropland;
                    </li>
                    <br />
                    <li>
                      level of use changes (e.g., a substantial increase or
                      decrease the number of cattle raised);
                    </li>
                    <br />
                    <li>
                      nature of use changes (e.g., a switch from growing corn to
                      growing ornamental plants);
                    </li>
                    <br />
                    <li>
                      property owner enters, leaves or changes governmental
                      programs (e.g., 100 acres placed in a conservation reserve
                      program);
                    </li>
                    <br />
                    <li>
                      or the land is used for something other than agriculture
                      (e.g., to build a shopping center on most of the land).
                    </li>
                  </ul>
                </div>

                <div className={shared.SubHeader}>
                  DUTY TO NOTIFY FOR CERTAIN LANDOWNERS:
                </div>
                <div className={shared.TextSmall}>
                  <div>
                    If land ceases to be devoted principally to agricultural use
                    to the degree of intensity generally accepted in the area,
                    open-space appraisal may be retained if the chief appraiser
                    is notified as required and the property owner:
                  </div>
                  <br />
                  <ul className={shared.UnorderedList}>
                    <li>
                      is a member of the armed services who is deployed or
                      stationed outside of Texas who intends to return the land
                      to the manner and to the degree of intensity that is
                      generally accepted in the area not later than the 180th
                      day after being deployed or stationed outside this state
                      ceases;
                    </li>
                    <br />
                    <li>
                      owns land that has previously been under open-space
                      appraisal primarily based on its citrus production; the
                      land is located in a pest management zone; and an
                      agreement was executed to destroy, remove or treat all the
                      citrus trees located on the land that are or could become
                      infested with pests with one of the following: Texas
                      Citrus Pest and Disease Management Corporation, Inc., the
                      Texas Commissioner of Agriculture or the U.S. Department
                      of Agriculture; or
                    </li>
                    <br />
                    <li>
                      owns land that has previously been under open-space
                      appraisal primarily on the basis of livestock; the land is
                      located in a temporary quarantine area established during
                      the tax year by the Texas Animal Health Commission for the
                      purpose of regulating the handling of livestock and
                      eradicating ticks or exposure to ticks under Chapter 167,
                      Agriculture Code.
                    </li>
                  </ul>
                </div>

                <div className={shared.SubHeader}>OTHER IMPORTANT INFORMATION</div>
                <div className={shared.TextSmall}>
                  If the initial application form does not contain all essential
                  information, the chief appraiser may request additional
                  information that is necessary to determine whether the land
                  qualifies for 1-d-1 appraisal. The chief appraiser may
                  disapprove the application and request additional information.
                  The chief appraiser may deny the application and that
                  determination may be protested to the county appraisal review
                  board in a timely manner. If the chief appraiser requests
                  additional information from an applicant, the information must
                  be furnished within 30 days after the date of the request, or
                  the application is denied. For good cause shown, the chief
                  appraiser may extend the deadline for furnishing the
                  information by written order for a single 15 day period.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
