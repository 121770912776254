export type ITimberlandAppraisalFormThirdSection = {
    AuthorizedRepresentativeType?: string;
    AuthorizedRepresentativeTypeOther?: string;
    AuthorizedRepresentativeName?: string;
    AuthorizedRepresentativeTitle?: string;
    AuthorizedRepresentativePhoneNumber?: string;
    AuthorizedRepresentativeEmailAddress?: string;
    AuthorizedRepresentativeMailingAddressLine1?: string;
    AuthorizedRepresentativeMailingAddressLine2?: string;
    AuthorizedRepresentativeMailingAddressCity?: string;
    AuthorizedRepresentativeMailingAddressState?: string;
    AuthorizedRepresentativeMailingAddressZip?: string;
}

export const TimberlandAppraisalFormThirdSectionInterface = (): ITimberlandAppraisalFormThirdSection => ({
    AuthorizedRepresentativeType: undefined,
    AuthorizedRepresentativeTypeOther: undefined,
    AuthorizedRepresentativeName: undefined,
    AuthorizedRepresentativeTitle: undefined,
    AuthorizedRepresentativePhoneNumber: undefined,
    AuthorizedRepresentativeEmailAddress: undefined,
    AuthorizedRepresentativeMailingAddressLine1: undefined,
    AuthorizedRepresentativeMailingAddressLine2: undefined,
    AuthorizedRepresentativeMailingAddressCity: undefined,
    AuthorizedRepresentativeMailingAddressState: undefined,
    AuthorizedRepresentativeMailingAddressZip: undefined
})