import {useParams} from "react-router-dom";
import FreeportForm from "../Forms/FreeportForm/FreeportForm";

interface FormEntry {
    formEntryId?: number;
    isAdminPreview?: boolean;
    formId?: number;
}
export default function FreeportFormEntry(props: FormEntry): JSX.Element {
    const { isAdminPreview, formEntryId, formId } = props;
    const { formEntryIdParams } = useParams();

    return <>
        <FreeportForm
            formId={formId}
            isAdminPreview={isAdminPreview}
            formEntryId={formEntryIdParams ? parseInt(formEntryIdParams) : formEntryId} />
    </>
}