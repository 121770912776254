import shared from "../../../PDF-Shared-style.module.scss";
import classes from "../../BPP-Form-PDF-style.module.scss";
import { GetBlankRows } from "../../../BlankRows";
import { IBPPFormPersonalPropertySegmentsInterface } from "src/interfaces/forms/BPPForm/bpp-form-personal-property-segments.interface";

interface GeneralFormType {
  group: "ScheduleE-FurnitureFixtures" | "ScheduleE-MachineryEquipment" |
         "ScheduleE-OfficeEquipment" | "ScheduleE-ComputerEquipment" |
         "ScheduleE-ManufacturingEquipment" | "ScheduleE-Other";
  name: string;
  rows: IBPPFormPersonalPropertySegmentsInterface[] | undefined;
}

export function ScheduleETable(props: GeneralFormType) : JSX.Element {
    const tableWidth = (
        props.group === "ScheduleE-ComputerEquipment" ||
        props.group === "ScheduleE-ManufacturingEquipment")
        ? shared.Width30 : shared.Width33;
  return (
    <div className={`${tableWidth} ${shared.InlineBlock}`}>
        <table className={shared.Blue}>
            <tr>
                <th colSpan={3} className={classes.BlueHeader}>
                    { props.name }
                </th>
            </tr>
            <tr>
                <th style={{width:"20%"}}>
                    Year Acquired
                </th>
                <th style={{width:"40%"}}>
                    Historical Cost When New<br />(Omit Cents)*
                </th>
                <th style={{width:"40%"}}>
                    Good Faith Estimate of Market Value*
                </th>
            </tr>
            { props.rows?.filter(x => !x.HasActiveSubSegments)?.map(item => {
                return <tr key={item.Id}>
                    <th>
                        {item?.YearAcquired}
                    </th>
                    <td>
                        {item?.HistoricCost}
                    </td>
                    <td>
                        {item?.MarketValue}
                    </td>
                </tr>
            })}
            {GetBlankRows(props.rows?.length, 3)}
        </table>
    </div>
  );
}
