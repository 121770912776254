import { GenericActionType } from "src/context/generic-actions.type";

export function action<T>(type: string): GenericActionType<T> {
  return { type };
}

export function actionPayload<T>(
  type: string,
  payload: T
): GenericActionType<T> {
  return { type, payload };
}

export function actionError<T>(
  type: string,
  error: string
): GenericActionType<T> {
  return { type, error };
}
