import classes from "../BPP-Form-PDF-style.module.scss";
import shared from "../../PDF-Shared-style.module.scss";
import { GetBlankRows } from "../../BlankRows";
import { chunkArray } from "src/utils/array";
import { IBppFormTenthSectionInterface } from "src/interfaces/forms/BPPForm/bpp-form-tenth-section.interface";
import { IGeneralScheduleInterface } from "src/interfaces/forms/BPPForm/general-schedule.interface";

const maxRowsPerPage = 34;

export const ScheduleD = (props: {
  formDataFieldsTenthSection?: IBppFormTenthSectionInterface;
}) => {
  const { formDataFieldsTenthSection } = props;

  const [primaryTable, ...additionalTables] = chunkArray(
    formDataFieldsTenthSection?.ScheduleD || [],
    maxRowsPerPage
  );

  return (
    <>
      <div className={`keepTogether ${classes.ScheduleSection} ${shared.PrintTogether}`}>
        <div className={shared.Step}>
          SCHEDULE D: VEHICLES AND TRAILERS AND SPECIAL EQUIPMENT
        </div>
        <div className={shared.Row}>
          <div className={shared.Column12}>
            <div>
              List only vehicles that are licensed in the name of the business as
              shown on Page 1. Vehicles disposed of after Jan. 1 are taxable for
              the year and must be listed below. If needed,attach additional
              sheets OR a computer generated listing of the information below.
              Report leased vehicles under Schedule F. Leased vehicles must be
              reported showing the name and address of the owner.
            </div>
          </div>

          <ScheduleDTable rows={primaryTable} blankRows={7} />

          {additionalTables.length === 0 && <Note />}
        </div>
      </div>

      {additionalTables.map((rows, index) => (
        <div
          className={`keepTogether ${classes.ScheduleSection} ${shared.PrintTogether}`}
        >
          <div className={shared.Row}>
            <ScheduleDTable rows={rows} blankRows={0} />
            {(index == additionalTables.length - 1) && <Note />}
          </div>
        </div>
      ))}
    </>
  );
};

const ScheduleDTable = (props: { rows?: IGeneralScheduleInterface[]; blankRows: number }) => {
  const { rows, blankRows } = props;

  return (
    <>
      <div className={shared.Column12}>
        <table className={shared.Blue}>
          <tr>
            <th style={{width: '6%'}}>Year <br/>(optional)</th>
            <th style={{width: '19%'}}>Make <br/>(optional)</th>
            <th style={{width: '19%'}}>Model <br/>(optional)</th>
            <th style={{width: '30%'}}>
              Vehicle Identification Number (VIN)
              <br />
              (optional)
            </th>
            <th style={{width: '9%'}}>Good Faith Estimate of Market Value*</th>
            <th style={{width: '9%'}}>Historical Cost When New* (Omit Cents)</th>
            <th style={{width: '8%'}}>Year Acquired*</th>
          </tr>
          { rows?.filter(x => !x.HasActiveSubSegments)?.map((item) => {
            return (
              <tr key={item.Id}>
                <td>{item?.PropertyVehicleYear}</td>
                <td>{item?.Make}</td>
                <td>{item?.Model}</td>
                <td>{item?.Vin}</td>
                <td>{item?.MarketValue}</td>
                <td>{item?.HistoricCost}</td>
                <td>{item?.YearAcquired}</td>
              </tr>
            );
          })}
          {GetBlankRows(rows?.length, blankRows)}
        </table>
      </div>
    </>
  );
};

const Note = () => {
  return (
    <div className={shared.Column12}>
      <div className={shared.Font8}>
        <p>
        * Provide an amount for either the good faith estimate of market value,
        or a historical cost when new and year acquired.
        If you provided an historical cost when new and year acquired,
        you need not provide a good faith estimate of market value.
        </p>
      </div>
    </div>
  )
}
