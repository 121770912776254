export type IAddressChangeFormSecondSection = {
    PropertyOwnerName?: string;
    PropertyId?: string;
    PhoneNumber?: string;
    LegalDescription?: string;
    PhysicalAddressLine1?: string;
    PhysicalAddressLine2?: string;
    PhysicalAddressCity?: string;
    PhysicalAddressState?: string;
    PhysicalAddressZip?: string;
}

export const AddressChangeFormSecondSectionInitialState = (): IAddressChangeFormSecondSection => ({
    PropertyOwnerName: undefined,
    PropertyId: undefined,
    PhoneNumber: undefined,
    LegalDescription: undefined,
    PhysicalAddressLine1: undefined,
    PhysicalAddressLine2: undefined,
    PhysicalAddressCity: undefined,
    PhysicalAddressState: undefined,
    PhysicalAddressZip: undefined
})