import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "./WildlifeManagement.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { Label } from "@progress/kendo-react-labels";
import { IWildlifeManagementFourthSectionHabitatControl } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-habitat-control.interface";
import { IWildlifeManagementFourthSectionCensus } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-census.interface";
import { IWildlifeManagementFourthSectionErosionControl } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-erosion-control.interface";
import { IWildlifeManagementFourthSectionPredatorControl } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-predator-control.interface";
import { IWildlifeManagementFourthSectionSupplementalFood } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-providing-supplemental-food.interface";
import { IWildlifeManagementFourthSectionSupplementalShelter } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-providing-supplemental-shelter.interface";
import { IWildlifeManagementFourthSectionSupplementalWater } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-supplemental-water.interface";
import HabitatControlSubSection from "./FourthSection/HabitatControlSubSection";
import CensusSubSection from "./FourthSection/CensusSubSection";
import ErosionControlSubSection from "./FourthSection/ErosionControlSubSection";
import PredatorControlSubSection from "./FourthSection/PredatorControlSubSection";
import SupplementalFoodSubSection from "./FourthSection/SupplementalFoodSubSection";
import SupplementalShelterSubSection from "./FourthSection/SupplementalShelterSubSection";
import SupplementalWaterSubSection from "./FourthSection/SupplementalWaterSubSection";

interface GeneralFormType {
    isAdminPreview?: boolean,
    formSettings?: IFormSettingModel,
    formDataHabitatControlFields?: IWildlifeManagementFourthSectionHabitatControl;
    formDataErosionControlFields?: IWildlifeManagementFourthSectionErosionControl;
    formDataPredatorControlFields?: IWildlifeManagementFourthSectionPredatorControl;
    formDataSupplementalWaterFields?: IWildlifeManagementFourthSectionSupplementalWater;
    formDataSupplementalFoodFields?: IWildlifeManagementFourthSectionSupplementalFood;
    formDataSupplementalShelterFields?: IWildlifeManagementFourthSectionSupplementalShelter;
    formDataCensusFields?: IWildlifeManagementFourthSectionCensus;
    setHabitatControlFormDataFields: (form: IWildlifeManagementFourthSectionHabitatControl) => void;
    setErosionControlFormDataFields: (form: IWildlifeManagementFourthSectionErosionControl) => void;
    setPredatorControlFormDataFields: (form: IWildlifeManagementFourthSectionPredatorControl) => void;
    setSupplementalWaterFormDataFields: (form: IWildlifeManagementFourthSectionSupplementalWater) => void;
    setSupplementalFoodFormDataFields: (form: IWildlifeManagementFourthSectionSupplementalFood) => void;
    setSupplementalShelterFormDataFields: (form: IWildlifeManagementFourthSectionSupplementalShelter) => void;
    setCensusFormDataFields: (form: IWildlifeManagementFourthSectionCensus) => void;
    setHabitatControlFormIsValid: (value: boolean) => void;
    setErosionControlFormIsValid: (value: boolean) => void;
    setPredatorControlFormIsValid: (value: boolean) => void;
    setSupplementalWaterFormIsValid: (value: boolean) => void;
    setSupplementalFoodFormIsValid: (value: boolean) => void;
    setSupplementalShelterFormIsValid: (value: boolean) => void;
    setCensusFormIsValid: (value: boolean) => void;
}

export default function WildlifeManagementFourthSection(props: GeneralFormType): JSX.Element {
    const { formSettings, isAdminPreview, formDataHabitatControlFields, 
        formDataErosionControlFields, formDataPredatorControlFields, 
        formDataSupplementalWaterFields, formDataSupplementalFoodFields, 
        formDataSupplementalShelterFields, formDataCensusFields, 
        setHabitatControlFormDataFields, setErosionControlFormDataFields, 
        setPredatorControlFormDataFields, setSupplementalWaterFormDataFields,
        setSupplementalFoodFormDataFields, setSupplementalShelterFormDataFields, 
        setCensusFormDataFields, setHabitatControlFormIsValid, 
        setErosionControlFormIsValid, setPredatorControlFormIsValid, 
        setSupplementalWaterFormIsValid, setSupplementalFoodFormIsValid, 
        setSupplementalShelterFormIsValid, setCensusFormIsValid } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const title = <div><i className={"fa " + 
        (!isValid ? "fa-asterisk text-danger" : "fa-check-circle text-success")
    }></i>Part IV. Wildlife Management Activities</div>

    return (
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <div className="container">
                    <div className={classes.item}>
                        <Label className={classes.Label}>
                            Check the activities you have implemented during the year to 
                            support each of the wildlife management activities listed in Part II.
                        </Label>
                    </div>
                </div>
                <HabitatControlSubSection
                    isAdminPreview={isAdminPreview}
                    formSettings={formSettings}
                    formDataFields={formDataHabitatControlFields}
                    setFormDataFields={setHabitatControlFormDataFields}
                    setFormIsValid={setHabitatControlFormIsValid}
                    setSectionIsValid={setIsValid}
                />
                <ErosionControlSubSection
                    isAdminPreview={isAdminPreview}
                    formSettings={formSettings}
                    formDataFields={formDataErosionControlFields}
                    setFormDataFields={setErosionControlFormDataFields}
                    setFormIsValid={setErosionControlFormIsValid}
                    setSectionIsValid={setIsValid}
                />
                <PredatorControlSubSection
                    isAdminPreview={isAdminPreview}
                    formSettings={formSettings}
                    formDataFields={formDataPredatorControlFields}
                    setFormDataFields={setPredatorControlFormDataFields}
                    setFormIsValid={setPredatorControlFormIsValid}
                    setSectionIsValid={setIsValid}
                />
                <SupplementalWaterSubSection
                    isAdminPreview={isAdminPreview}
                    formSettings={formSettings}
                    formDataFields={formDataSupplementalWaterFields}
                    setFormDataFields={setSupplementalWaterFormDataFields}
                    setFormIsValid={setSupplementalWaterFormIsValid}
                    setSectionIsValid={setIsValid}
                />
                <SupplementalFoodSubSection
                    isAdminPreview={isAdminPreview}
                    formSettings={formSettings}
                    formDataFields={formDataSupplementalFoodFields}
                    setFormDataFields={setSupplementalFoodFormDataFields}
                    setFormIsValid={setSupplementalFoodFormIsValid}
                    setSectionIsValid={setIsValid}
                />
                <SupplementalShelterSubSection
                    isAdminPreview={isAdminPreview}
                    formSettings={formSettings}
                    formDataFields={formDataSupplementalShelterFields}
                    setFormDataFields={setSupplementalShelterFormDataFields}
                    setFormIsValid={setSupplementalShelterFormIsValid}
                    setSectionIsValid={setIsValid}
                />
                <CensusSubSection
                    isAdminPreview={isAdminPreview}
                    formSettings={formSettings}
                    formDataFields={formDataCensusFields}
                    setFormDataFields={setCensusFormDataFields}
                    setFormIsValid={setCensusFormIsValid}
                    setSectionIsValid={setIsValid}
                />
            </PanelBarItem>
        </PanelBar>
    )
}