import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Step, StepProps, Stepper } from "@progress/kendo-react-layout";
import ReactTimeAgo from "react-time-ago";
import { ITimeline } from "src/interfaces/timeline.interface";
import { getIconNameHandler } from "src/utils/icons-formatter";
import { convertToLocalDate } from "src/utils/local-date-convertor";

export type FormTimelineProps = {
  timelines: ITimeline[];
  onTimelineClick?: (timeline: ITimeline) => void;
};

export const FormTimeline = (props: FormTimelineProps) => {
  const { timelines, onTimelineClick } = props;

  const handleClick = (id: number) => {
    const timeline = timelines.find((timeline) => timeline.id === id);
    if (timeline) {
      onTimelineClick?.(timeline);
    }
  };

  const TimelineStep = (props: StepProps) => {
    const { date, subject, icon, cssClass, id } = props;

    return (
      <Step onClick={() => handleClick(id)}>
        <div className={`custom-step ${cssClass ?? ""}`}>
          {icon && <FontAwesomeIcon icon={getIconNameHandler(icon ?? "")} />}
        </div>
        <div className="step-content">
          <div className="date">
            <ReactTimeAgo
              date={convertToLocalDate(date.toString())}
              locale="en-US"
            />
          </div>
          <div className="subject">{subject}</div>
        </div>
      </Step>
    );
  };

  return (
    <Stepper
      value={timelines.length}
      items={timelines}
      item={TimelineStep}
      orientation="vertical"
    />
  );
};
