import { createContext, useContext, useReducer } from "react";
import type { FormContextType } from "./form-reducer";
import { FormReducer, initialState } from "./form-reducer";

const FormStateContext = createContext<FormContextType>({
  formState: initialState,
  formDispatch: () => null,
});

export function useFormContext(): FormContextType {
  const context = useContext(FormStateContext);

  if (context === undefined) {
    throw new Error("useFormContext must be used within a FormProvider");
  }

  return context;
}

export const FormProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [formState, formDispatch] = useReducer(FormReducer, initialState);

  return (
    <FormStateContext.Provider value={{ formState, formDispatch }}>
      {children as React.ReactNode}
    </FormStateContext.Provider>
  );
};
