export type IHomesteadExemptionFirstSectionInterface = {
    PropertySearch?: string,
    TaxYears?: Array<number>,
    IsLateApplication?: boolean,
    PropertyIsInStockOfCoop?: boolean,
    CooperativeHousingCorporation?: string,
    WasReceivingExemptionOnPreviousResidence?: boolean,
    IsTransferringPreviousResidenceHomestead?: boolean,
    IsTransferringATaxLimitation?: boolean,
    PreviousResidenceAddressLine1?: string,
    PreviousResidenceAddressLine2?: string,
    PreviousResidenceAddressCity?: string,
    PreviousResidenceAddressState?: string,
    PreviousResidenceAddressZip?: string,
    PreviousResidenceCounty?: string
}

export const HomesteadExemptionFormFirstSectionInitialState = (): IHomesteadExemptionFirstSectionInterface => ({
    PropertySearch: undefined,
    TaxYears: undefined,
    IsLateApplication: undefined,
    PropertyIsInStockOfCoop: undefined,
    CooperativeHousingCorporation: undefined,
    WasReceivingExemptionOnPreviousResidence: undefined,
    IsTransferringPreviousResidenceHomestead: undefined,
    IsTransferringATaxLimitation: undefined,
    PreviousResidenceAddressLine1: undefined,
    PreviousResidenceAddressLine2: undefined,
    PreviousResidenceAddressCity: undefined,
    PreviousResidenceAddressState: undefined,
    PreviousResidenceAddressZip: undefined,
    PreviousResidenceCounty: undefined
})