import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import {useEffect, useRef, useState} from "react";
import {
    CheckboxChangeEvent, Input,
    InputChangeEvent, NumericTextBoxChangeEvent,    
    TextAreaChangeEvent
} from "@progress/kendo-react-inputs";
import {CalendarChangeEvent, DatePicker, DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./DisabledVetForm.module.scss";
import {Error, Label} from "@progress/kendo-react-labels";
import {MMddyyyyDateFormat} from "../../../../../constants/date-formats";
import {FormValidation} from "../InputValidator";
import { IDisabledVetEighthSection } from "src/interfaces/forms/DisableVetForm/disable-vet-form-eighth-section";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IDisabledVetEighthSection) => void;
    formDataFields?:  IDisabledVetEighthSection,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void,
}

export default function DisabledVetEighthSection(props: GeneralFormType): JSX.Element {
    const {setFormDataFields, formDataFields, formSettings, setFormIsValid} = props;

    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={8} name={"Certification and Signature"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | InputChangeEvent
            | CalendarChangeEvent
            | NumericTextBoxChangeEvent,
        field: string,
    ) {
        if (field == "SignatureDate"){
            setFormDataFields({
                ...formDataFields,
                [field]: event.value?.toString()
            });
        } else if (field == "Signature") {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value?.toString(),
                ["SignatureDate"]: new Date().toDateString()
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

   function changeValidator() {
        if (formRef.current) {
            setIsValid(formRef.current.checkValidity());
            setFormIsValid(formRef.current.checkValidity());
        }
    }

    function onTouch() {
        if (!isFormTouched) {
            setIsFormTouched(true);
        }
    }

    const { validationMessage, inputValidator } = FormValidation(formRef);

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current]);

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={classes.itemFields}>
                                <Label className={`${classes.Label} ${classes.fieldsGroupW100}`}>
                                    NOTICE REGARDING PENALTIES FOR MAKING OR FILING AN APPLICATION CONTAINING A FALSE STATEMENT: 
                                    If you make a false statement on this form, you could be found guilty of a Class A 
                                    misdemeanor or a state jail felony under Penal Code Section 37.10.
                                </Label>
                            </div>
                            <div className={classes.itemFields}>
                                <Label className={`${classes.Label} ${classes.fieldsGroupW100}`}>
                                    By submitting this form you swear or affirm the following:
                                </Label>
                                <ul className="ng-scope">
                                    <li>that each fact contained in this application is true and correct;</li>
                                    <li>that the property the owner identified in this application meets the qualifications under Texas law for the exemption claimed; and</li>
                                    <li>that I have read and understand the Notice Regarding Penalties for Making or Filing an Application Containing a False Statement.</li>
                                </ul>
                            </div>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroupW49}>
                                    <Label className={`${classes.Label}`}>
                                        Signature of Property Owner or Authorized Representative
                                        {!formDataFields?.Signature && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                    <Input
                                        required={true}
                                        name={"Signature"}
                                        placeholder={"Signature of Property Owner or Authorized Representative"}
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.kTextbox}
                                        value={formDataFields?.Signature}
                                        onChange={e => changeValueHandler(e, "Signature")}
                                    />
                                    {!inputValidator("Signature") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={classes.fieldsGroupW20}>
                                    <Label className={`${classes.Label}`}>
                                        Title/Authorization
                                    </Label>
                                    <Input
                                        name={"Title"}
                                        disabled={formSettings?.isReadOnly}
                                        placeholder={"Title"}
                                        className={classes.kTextbox}
                                        value={formDataFields?.Title}
                                        onChange={e => changeValueHandler(e, "Title")}
                                    />
                                    {!inputValidator("Title") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={classes.fieldsGroupW20}>
                                    <Label className={`${classes.Label}`}>
                                        Date Signed
                                        {!formDataFields?.SignatureDate && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                    <DatePicker
                                        format={MMddyyyyDateFormat}
                                        name={"SignatureDate"}
                                        required={true}
                                        disabled={true}
                                        className={classes.kTextbox}
                                        value={formDataFields?.SignatureDate ? new Date(formDataFields?.SignatureDate) : undefined}
                                        onChange={e => changeValueHandler(e, "SignatureDate")}
                                    />
                                    {!inputValidator("SignatureDate") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
