import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "./AddressChangeForm.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { Label } from "@progress/kendo-react-labels";
import { CustomDatePicker } from "../CustomInputs/CustomDatePickers/CustomDatePicker";
import { IAddressChangeFormFirstSection } from "../../../../../interfaces/forms/AddressChangeForm/address-change-form-first-section.interface";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IAddressChangeFormFirstSection) => void;
    formDataFields?:  IAddressChangeFormFirstSection,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function AddressChangeFormFirstSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, setFormIsValid } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={1} name={"Date Request"}/>;

    const [dateOfRequestInputValid, setDateOfRequestInputValid] = useState<boolean>(false);

    function changeValidator() {
        if (formRef.current) {
            const isValid = formRef.current.checkValidity() &&
            dateOfRequestInputValid
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if(!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current, dateOfRequestInputValid]);

    return (
       <>
            <PanelBar keepItemsMounted={true}>
                <PanelBarItem title={title} expanded={true}>
                    <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                        <div className="container">
                            <div className={classes.item}>
                                <div className={`${classes.itemFields} ${classes.itemFieldsLeft} ${classes.bordered}`}>
                                    <div className={`${classes.fieldsGroup} ${classes.fieldsGroupLeft}`}>
                                        <Label className={classes.Label}>
                                            Date of Request
                                            {!formDataFields?.DateOfRequest && (
                                                <i className={"fa fa-asterisk text-danger"}></i>
                                            )}
                                        </Label>
                                        <div className="inputsGroup">
                                            <CustomDatePicker
                                                fieldName={"DateOfRequest"}
                                                required={true}
                                                disabled={formSettings?.isReadOnly}
                                                formDataFields={formDataFields}
                                                setFormDataFields={setFormDataFields}
                                                onlyYear={false}
                                                setIsValidField={setDateOfRequestInputValid}
                                                validField={dateOfRequestInputValid}
                                                defaultValueDate={formDataFields?.DateOfRequest}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                   </form>
                </PanelBarItem>
            </PanelBar>
       </>
    )
}