export interface IWildlifeManagementSecondSection {
    WildlifeManagementPractices?: IWildlifeManagementPractices;
}

export interface IWildlifeManagementPractices {
    HabitatControl?: boolean;
    ErosionControl?: boolean;
    PredatorControl?: boolean;
    MakingCensusCountsToDeterminePopulation?: boolean;
    ProvideSupplementalSuppliesOfWater?: boolean;
    ProvideSupplementalSuppliesOfFood?: boolean;
    ProvideShelters?: boolean;
}

export const WildlifeManagementSecondSectionInterface = (): IWildlifeManagementSecondSection => ({
    WildlifeManagementPractices: undefined
})