import {IFormFilesInterface} from "../Shared/form-files.interface";

export type IHomesteadExemptionFifthSectionInterface = {
    AdditionalInformation?: string,
    AditionalOwnedPropertiesCounties?: string,
    AdditionalDocuments?: Array<IFormFilesInterface>,
    OptionalInformationRequestedPurchasePrice?: string,
    PurchaseDate?: string
}

export const HomesteadExemptionFormFifthSectionInitialState = (): IHomesteadExemptionFifthSectionInterface => ({
    AdditionalInformation: undefined,
    AditionalOwnedPropertiesCounties: undefined,
    AdditionalDocuments: undefined,
})
