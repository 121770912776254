export interface IWildlifeManagementFirstSection {
    TaxYear?: string;
    AccountNumber?: string;
    OwnersName?: string;
    PhoneNumber?: string;
    TractName?: string;
    MajorityCounty?: string;
    AdditionalCountiesIfAny?: string;
    MailingAddressLine1?: string;
    MailingAddressLine2?: string;
    MailingAddressCity?: string;
    MailingAddressState?: string;
    MailingAddressZip?: string;
}

export const WildlifeManagementFirstSectionInterface = (): IWildlifeManagementFirstSection => ({
    TaxYear: undefined,
    AccountNumber: undefined,
    OwnersName: undefined,
    PhoneNumber: undefined,
    TractName: undefined,
    MajorityCounty: undefined,
    AdditionalCountiesIfAny: undefined,
    MailingAddressLine1: undefined,
    MailingAddressLine2: undefined,
    MailingAddressCity: undefined,
    MailingAddressState: undefined,
    MailingAddressZip: undefined
})