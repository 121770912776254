import { AxiosResponse } from "axios";
import instance from "../axios-instance";
import { IFormFilesInterface } from "src/interfaces/forms/Shared/form-files.interface";

export function getPdfData(formEntryId: number): any {
    return instance.get(`pdfs/form-entry/${formEntryId}`);
}

export async function getPdfBlob(fileName: string): Promise<Blob> {
    const response = await instance.get<Blob>(`pdfs/${fileName}`, { responseType: 'blob' });
    return response.data;
}

export function exportPdfUrl(formEntryId: number): any {
    return instance.get(`pdfs/export-pdf-base64/${formEntryId}`);
}

export function saveFormPdf(accessCode:string, base64Data: string) {
    return instance.post(`pdfs/`, {
        accessCode: accessCode,
        base64Data: base64Data
    });
}

export function appendSupportingDocumentsToPdf(accessCode:string, documents: IFormFilesInterface[], base64Data: string) : Promise<AxiosResponse<string, any>> {
    return instance.post(`pdfs/append-supporting-documents-to-pdf/`, {
        accessCode: accessCode,
        fileIds: documents.map(d => d.id),
        base64Data: base64Data
    });
}
