import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

export interface iRootStorage {
    localRestoreComplete: boolean,
}

const initialState: iRootStorage = {
    localRestoreComplete: false,
}

export const initRootSlice = createAsyncThunk(
  'initRootSlice',
  async () => {	  
    //TODO: add init values here
    console.log("initRootSlice => initState");

    return {  };
  }
);


export const rootSlice = createSlice({
    name: 'rootSlice',
    initialState,
    reducers: {
       
    },
    extraReducers: (builder) => {
      builder
      .addCase(initRootSlice.fulfilled, (state, action) => {

      })
    },
});

export const {  } = rootSlice.actions

export default rootSlice.reducer