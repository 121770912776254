import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "./AddressChangeForm.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { DatePickerChangeEvent, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, RadioGroupChangeEvent, Input } from "@progress/kendo-react-inputs";
import { FormValidation } from "../InputValidator";
import { Error, Label } from "@progress/kendo-react-labels";
import { StatesInput } from "../CustomInputs/StatesInput";
import { ZipCodeInput } from "../CustomInputs/ZipCodeInput";
import { IAddressChangeFormThirdSection } from "../../../../../interfaces/forms/AddressChangeForm/address-change-form-third-section.interface";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IAddressChangeFormThirdSection) => void;
    formDataFields?:  IAddressChangeFormThirdSection,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function AddressChangeFormSecondSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, isAdminPreview, setFormIsValid } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={3} name={"Mailing Address Information"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string
    )
    {
        setFormDataFields({
            ...formDataFields,
            [field]: event.value
        });
    }

    const [previousStateInputValid, setPreviousStateInputValid] = useState<boolean>(false);
    const [previousZipCodeInputValid, setPreviousZipCodeInputValid] = useState<boolean>(false);
    const [newStateInputValid, setNewStateInputValid] = useState<boolean>(false);
    const [newZipCodeInputValid, setNewZipCodeInputValid] = useState<boolean>(false);
    const { validationMessage, inputValidator } = FormValidation(formRef);

    function changeValidator() {
        if (formRef.current) {
            const isValid = formRef.current.checkValidity() &&
                previousStateInputValid &&
                previousZipCodeInputValid &&
                newStateInputValid &&
                newZipCodeInputValid;
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if(!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current, previousStateInputValid, previousZipCodeInputValid,
        newStateInputValid, newZipCodeInputValid]);

    return (
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={`${classes.item}`}>
                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                <div className={classes.fieldsGroupW50}>
                                    <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                        <p>Previous Mailing Address</p>
                                    </div>
                                    <Label className={classes.Label}>
                                        Address Line 1
                                        {!formDataFields?.PreviousAddressLine1 && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"PreviousAddressLine1"}
                                        required={true}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.PreviousAddressLine1}
                                        onChange={(e)=> changeValueHandler(e, "PreviousAddressLine1")}
                                    />
                                    {!inputValidator("PreviousAddressLine1") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                    <Label className={classes.Label}>
                                        Address Line 2
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"PreviousAddressLine2"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.PreviousAddressLine2}
                                        onChange={(e)=> changeValueHandler(e, "PreviousAddressLine2")}
                                    />
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                        <div className={`${classes.fieldsGroupW30}`}>
                                            <Label className={classes.Label}>
                                                City
                                                {!formDataFields?.PreviousAddressCity && (
                                                    <i className={"fa fa-asterisk text-danger"}></i>
                                                )}
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                required={true}
                                                name={"PreviousAddressCity"}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.PreviousAddressCity}
                                                onChange={(e)=> changeValueHandler(e, "PreviousAddressCity")}
                                            />
                                            {!inputValidator("PreviousAddressCity") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                        <div className={`${classes.fieldsGroupW30}`}>
                                            <Label className={classes.Label}>
                                                State
                                                {!formDataFields?.PreviousAddressState && (
                                                    <i className={"fa fa-asterisk text-danger"}></i>
                                                )}
                                            </Label>
                                            <StatesInput
                                                setFormDataFields={setFormDataFields}
                                                fieldName={"PreviousAddressState"}
                                                required={true}
                                                validField={previousStateInputValid}
                                                setIsValidField={setPreviousStateInputValid}
                                                formDataFields={formDataFields}
                                                isReadOnly={formSettings?.isReadOnly}
                                            />
                                            {!inputValidator("PreviousAddressState") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                        <div className={`${classes.fieldsGroupW20}`}>
                                            <Label className={classes.Label}>
                                                Zip Code
                                                {!formDataFields?.PreviousAddressZip && (
                                                    <i className={"fa fa-asterisk text-danger"}></i>
                                                )}
                                            </Label>
                                            <ZipCodeInput
                                                setFormDataFields={setFormDataFields}
                                                fieldName={"PreviousAddressZip"}
                                                required={true}
                                                formDataFields={formDataFields}
                                                isReadOnly={formSettings?.isReadOnly}
                                                setIsValidField={setPreviousZipCodeInputValid}
                                                validField={previousZipCodeInputValid}
                                            />
                                            {!inputValidator("PreviousAddressZip") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.fieldsGroupW50}>
                                    <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                        <p>New Mailing Address</p>
                                    </div>
                                    <Label className={classes.Label}>
                                        Address Line 1
                                        {!formDataFields?.NewAddressLine1 && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"NewAddressLine1"}
                                        required={true}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.NewAddressLine1}
                                        onChange={(e)=> changeValueHandler(e, "NewAddressLine1")}
                                    />
                                    {!inputValidator("NewAddressLine1") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                    <Label className={classes.Label}>
                                        Address Line 2
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"NewAddressLine2"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.NewAddressLine2}
                                        onChange={(e)=> changeValueHandler(e, "NewAddressLine2")}
                                    />
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                        <div className={`${classes.fieldsGroupW30}`}>
                                            <Label className={classes.Label}>
                                                City
                                                {!formDataFields?.NewAddressCity && (
                                                    <i className={"fa fa-asterisk text-danger"}></i>
                                                )}
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                required={true}
                                                name={"NewAddressCity"}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.NewAddressCity}
                                                onChange={(e)=> changeValueHandler(e, "NewAddressCity")}
                                            />
                                            {!inputValidator("NewAddressCity") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                        <div className={`${classes.fieldsGroupW30}`}>
                                            <Label className={classes.Label}>
                                                State
                                                {!formDataFields?.NewAddressState && (
                                                    <i className={"fa fa-asterisk text-danger"}></i>
                                                )}
                                            </Label>
                                            <StatesInput
                                                setFormDataFields={setFormDataFields}
                                                fieldName={"NewAddressState"}
                                                required={true}
                                                validField={newStateInputValid}
                                                setIsValidField={setNewStateInputValid}
                                                formDataFields={formDataFields}
                                                isReadOnly={formSettings?.isReadOnly}
                                            />
                                            {!inputValidator("NewAddressState") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                        <div className={`${classes.fieldsGroupW20}`}>
                                            <Label className={classes.Label}>
                                                Zip Code
                                                {!formDataFields?.NewAddressZip && (
                                                    <i className={"fa fa-asterisk text-danger"}></i>
                                                )}
                                            </Label>
                                            <ZipCodeInput
                                                setFormDataFields={setFormDataFields}
                                                fieldName={"NewAddressZip"}
                                                required={true}
                                                formDataFields={formDataFields}
                                                isReadOnly={formSettings?.isReadOnly}
                                                setIsValidField={setNewZipCodeInputValid}
                                                validField={newZipCodeInputValid}
                                            />
                                            {!inputValidator("NewAddressZip") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                <Label className={classes.Label}>
                                    Reason For Change
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    required={false}
                                    name={"ReasonForChange"}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.ReasonForChange}
                                    onChange={(e)=> changeValueHandler(e, "ReasonForChange")}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    )
}