import { useMemo, useRef } from "react";
import { IFormFiledInterface } from "src/interfaces/form-filed.interface";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { IFormFilesInterface } from "src/interfaces/forms/Shared/form-files.interface";

/**
 * Generic hook to check if form could possibly have unsaved changes
 */
export const useIsFormActive = (
  isAuthorized: boolean,
  isAdminPreview: boolean | undefined,
  formSettings: IFormSettingModel | undefined,
  additionalDocsFieldName?: string,
  initialFormDataFields?: IFormFiledInterface[],
  supportingDocs?: IFormFilesInterface[],
) => {
  const initialDocs: IFormFilesInterface[] = useMemo(() =>
    JSON.parse(
      initialFormDataFields?.find(f => f.name === additionalDocsFieldName)?.value || '[]'
    ),
    [initialFormDataFields]
  )

  const isFormActive = useMemo(() => {
    const isTaxpayer = isAuthorized && !isAdminPreview;
    const isFormEditable = !(formSettings?.isReadOnly ?? true);
    const currentDocs = supportingDocs || [];
    const hasSupportingDocumentsChanged =
      currentDocs.length !== initialDocs.length ||
      currentDocs.some((doc) => !initialDocs.find((d) => d.id === doc.id));

    return isTaxpayer && (isFormEditable || hasSupportingDocumentsChanged);
  }, [
    isAuthorized,
    isAdminPreview,
    formSettings?.isReadOnly,
    supportingDocs
  ]);

  return { isFormActive }
};
