import { useContext, useEffect } from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";
import { History } from "history";

export const useBlocker = (blocker: any, when = true) => {
  const navigation = useContext(UNSAFE_NavigationContext);
  const history = navigation.navigator as History;

  useEffect(() => {
    if (!when) return;
    const unblock = history.block((tx) => { // <-- use history
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        }
      };
      blocker(autoUnblockingTx);
    });
    return unblock;
  }, [blocker, when]);
};
