import { AxiosError, AxiosResponse } from 'axios';
import FileSaver from 'file-saver';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { downloadFileByAttachmentId } from 'src/api/messages-api';
import { useSharedContext } from 'src/context/shared/shared-context';
import { temporaryError } from 'src/context/shared/shared-context.helper';

export default function Download(): JSX.Element {
  const { sharedDispatch } = useSharedContext();
  const { attachmentId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (attachmentId) {
      download(+attachmentId);
    }
    else {
      returnError('Link is incorrect');
    }
  }, []);

  const download = (attachmentId: number) => {
    downloadFileByAttachmentId(attachmentId).then((response: AxiosResponse<any>) => {
      const blob = new Blob([response.data], { type: response.headers.contentType });
      const filename = getFileName(response.headers['content-disposition']);
      FileSaver.saveAs(blob, filename);
    })
    .catch((error: AxiosError) => {   
      returnError(error.response?.data);
    });
  }

  const getFileName = (disposition: string): string => {
    let fileName = 'no filename';
    if (!disposition) {
      return fileName;
    }
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

    if (utf8FilenameRegex.test(disposition)) {
      const arrayRes = utf8FilenameRegex.exec(disposition);
      if (arrayRes && arrayRes.length > 0) {
        fileName = decodeURIComponent(arrayRes[1]);
      }
    } else {
      // prevent ReDos attacks by anchoring the ascii regex to string start and
      //  slicing off everything before 'filename='
      const filenameStart = disposition.toLowerCase().indexOf('filename=');
      if (filenameStart >= 0) {
        const partialDisposition = disposition.slice(filenameStart);
        const matches = asciiFilenameRegex.exec(partialDisposition );
        if (matches != null && matches[2]) {
          fileName = matches[2];
        }
      }
    }
    return fileName;
  }

  const returnError = (message: string | unknown) => {
    temporaryError(sharedDispatch, message);
      setTimeout(() => {
        navigate('/home')
      }, 2000)
  }
  
  return <></>;
}