import { ReactNode } from "react";
import "./PDF-Shared-style.module.scss";

export function GetFixedWidthCheckbox(value: boolean | string | undefined) : ReactNode {
    return <i className={ "fa fa-fw " + (value ? "fa-check-square-o" : "fa-square-o") + " fa-2x" }></i>;
}

export function GetCheckbox(value: boolean | string | undefined, text: string, isBold?: boolean) : ReactNode {
    let textResult = isBold === true ? (<label>{text}</label>) : text;
    return <span>
        <i className={"fa " + (value ? "fa-check-square-o" : "fa-square-o") + " fa-2x" }></i>
        {textResult}
    </span>;
}

export function GetYesNoCheckboxGroup(value: boolean | "true" | "false" | undefined, yesText?: string, noText?: string) : ReactNode {
    const yes = value == true || value === "true"
    const no = value == false || value === "false"

    return <>
        <i className={"fa " + (yes
            ? "fa-check-square-o"
            : "fa-square-o") + " fa-2x"
        }></i>
        {yesText ?? "Yes"} &emsp;&nbsp;
        <i className={"fa " + (no
            ? "fa-check-square-o"
            : "fa-square-o") + " fa-2x"
        }></i>
        {noText ?? "No"}
    </>
}