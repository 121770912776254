import { IFormSettingModel } from "../../../../../../interfaces/form-settings.interface";
import {
  CheckboxChangeEvent,
  Input,
  InputChangeEvent, NumericTextBoxChangeEvent,
  RadioGroupChangeEvent,
  TextAreaChangeEvent,
} from "@progress/kendo-react-inputs";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import React, {useEffect, useRef, useState} from "react";
import { IBppFormEleventhSectionInterface } from "../../../../../../interfaces/forms/BPPForm/bpp-form-eleventh-section.interface";
import { PanelBarItem } from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./../BPPForm.module.scss";
import { PanelBar } from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import { ScheduleERowHandler } from "./ScheduleERowHandler";
import { ScheduleETable } from "./ScheduleETable";
import { IFormEntryModel } from "../../../../../../interfaces/form-entry.interface";
import {IRowState} from "../../CustomInputs/UpdateRowErrorStateHandler";
import {
  ScheduleEComputerEquipmentName,
  ScheduleEFurnitureFixturesName,
  ScheduleEMachineryEquipmentName, ScheduleEManufacturingEquipmentName, ScheduleEOfficeEquipmentName, ScheduleEOtherName
} from "../../../../../../constants/form-fields-constants";
import SectionTitle from "../../Shared/SectionTitle";

interface GeneralFormType {
  isAdminPreview?: boolean;
  setFormDataFields: (form: IBppFormEleventhSectionInterface) => void;
  formDataFields?: IBppFormEleventhSectionInterface;
  formSettings?: IFormSettingModel;
  setFormIsValid: (value: boolean) => void;
  formEntry?: IFormEntryModel;
}

export function BPPFormEleventhSection(props: GeneralFormType): JSX.Element {
  const formRef = useRef<HTMLFormElement>(null);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
  const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={11} name={"Schedule E"}/>;

  const { setFormDataFields, formDataFields, formSettings, setFormIsValid, formEntry, isAdminPreview } = props;
  const editDisabled = formSettings?.isReadOnly || formEntry?.initiatedBy === 'PropertySearch' || isAdminPreview;

  function changeValueHandler(
    event:
      | CheckboxChangeEvent
      | DatePickerChangeEvent
      | TextAreaChangeEvent
      | DropDownListChangeEvent
      | InputChangeEvent
      | RadioGroupChangeEvent
      | NumericTextBoxChangeEvent,
    field: string,
    group: string,
    id: number
  ) {
    const newItem: IBppFormEleventhSectionInterface = formDataFields
      ? JSON.parse(JSON.stringify(formDataFields))
      : [];

      let Schedule = (newItem as any)[group]?.find((x: any) => x.Id == id);

      if(field == "YearAcquired"){
        Schedule = {...Schedule, [field]: event.value.getFullYear(), ScheduleName: group};

      } else {
        Schedule = {...Schedule, [field]: event.value, ScheduleName: group};
      }

    const index = (newItem as any)[group]?.findIndex((x: any) => x.Id == id);

    if (index != undefined && (newItem as any)) {
      (newItem as any)[group][index] = Schedule;
    }
    setFormDataFields({
      ...newItem,
    });
  }

  function addRowHandler(event: React.MouseEvent<HTMLElement>, group: string) {
    event.preventDefault();
    const newItem: IBppFormEleventhSectionInterface = formDataFields
      ? JSON.parse(JSON.stringify(formDataFields))
      : [];
    if (!(newItem as any)[group]) {
      (newItem as any)[group] = [];
    }
    (newItem as any)[group]?.push({ Id: (newItem as any)[group]?.length });
    setFormDataFields({
      ...newItem,
    });
  }

  function removeRowHandler(
    event: React.MouseEvent<HTMLElement>,
    group: string
  ) {
    event.preventDefault();
    const newItem: IBppFormEleventhSectionInterface = formDataFields
      ? JSON.parse(JSON.stringify(formDataFields))
      : [];
    if (newItem && (newItem as any)[group]) {
      (newItem as any)[group].pop();
      setFormDataFields({
        ...newItem,
      });
    }
  }

  const [dateErrorsArray, setDateErrorsArray] = useState<Array<IRowState>>([]);

  function changeValidator() {
    if(formRef.current) {
      const isValid = formRef.current.checkValidity() &&
          !dateErrorsArray.some(x=>x.state === false);

      setIsValid(isValid);
      setFormIsValid(isValid);
    }
  }

  function onTouch(){
    if(!isFormTouched){
      setIsFormTouched(true);
    }
  }

  useEffect(() => {
    changeValidator()
  }, [
    formDataFields,
    formDataFields?.["ScheduleE-FurnitureFixtures"],
    formDataFields?.["ScheduleE-ComputerEquipment"],
    formDataFields?.["ScheduleE-MachineryEquipment"],
    formDataFields?.["ScheduleE-ManufacturingEquipment"],
    formDataFields?.["ScheduleE-OfficeEquipment"],
    formDataFields?.["ScheduleE-Other"],
    formRef.current,
    formRef?.current?.elements,
    dateErrorsArray
  ]);


  return (
    <>
      <PanelBar keepItemsMounted={true}>
        <PanelBarItem title={title} expanded={true}>
          <form name={"Name"} ref={formRef} onFocus={onTouch}>
            <div className="container">
              <div className={classes.item}>
                <div className="print-together">
                  <div className={classes.ScheduleLabel}>
                    SCHEDULE E: FURNITURE, FIXTURES, MACHINERY, EQUIPMENT,
                    COMPUTERS
                  </div>

                  <div className={classes.information}>
                    Total (by year acquired) all furniture, fixtures, machinery,
                    equipment and computers (new or used) still in possession on
                    Jan. 1. Items received as gifts are to be listed in the same
                    manner. If needed, attach additional sheets OR a computer
                    generated listing of the information below.
                  </div>

                  <br />

                <div className="row">
                  <div className="column-6">
                      <ScheduleETable
                        errorsArray={dateErrorsArray}
                        setErrorsArray={setDateErrorsArray}
                        setFormDataFields={setFormDataFields}
                        changeValueHandler={changeValueHandler}
                        formDataFields={formDataFields}
                        formSettings={{...formSettings, isReadOnly: editDisabled}}
                        group={ScheduleEFurnitureFixturesName}
                        name="Furniture And Fixtures"
                        formEntry={formEntry}
                      ></ScheduleETable>
                      {!formSettings?.isReadOnly && !editDisabled &&
                        <ScheduleERowHandler
                        formSettings={{...formSettings, isReadOnly: editDisabled}}
                        addRowHandler={addRowHandler}
                        removeRowHandler={removeRowHandler}
                        group={ScheduleEFurnitureFixturesName}
                        formEntry={formEntry}
                        ></ScheduleERowHandler>
                      }
                  </div>
                  <div className="column-6">
                      <ScheduleETable
                        errorsArray={dateErrorsArray}
                        setErrorsArray={setDateErrorsArray}
                        setFormDataFields={setFormDataFields}
                        changeValueHandler={changeValueHandler}
                        formDataFields={formDataFields}
                        formSettings={{...formSettings, isReadOnly: editDisabled}}
                        group={ScheduleEMachineryEquipmentName}
                        name="Machinery And Equipment"
                        formEntry={formEntry}
                      ></ScheduleETable>
                      {!formSettings?.isReadOnly && !editDisabled &&
                        <ScheduleERowHandler
                        formSettings={{...formSettings, isReadOnly: editDisabled}}
                        addRowHandler={addRowHandler}
                        removeRowHandler={removeRowHandler}
                        group={ScheduleEMachineryEquipmentName}
                        formEntry={formEntry}
                        ></ScheduleERowHandler>}
                  </div>
                </div>
              </div>

              <div className="print-together">
                <div className="row">
                  <div className="column-6">
                    <ScheduleETable
                      errorsArray={dateErrorsArray}
                      setErrorsArray={setDateErrorsArray}
                      setFormDataFields={setFormDataFields}
                      changeValueHandler={changeValueHandler}
                      formDataFields={formDataFields}
                      formSettings={{...formSettings, isReadOnly: editDisabled}}
                      group={ScheduleEOfficeEquipmentName}
                      name="Office Equipment"
                      formEntry={formEntry}
                    ></ScheduleETable>
                    {!formSettings?.isReadOnly && !editDisabled &&
                      <ScheduleERowHandler
                      formSettings={{...formSettings, isReadOnly: editDisabled}}
                      addRowHandler={addRowHandler}
                      removeRowHandler={removeRowHandler}
                      group={ScheduleEOfficeEquipmentName}
                      formEntry={formEntry}
                      ></ScheduleERowHandler>
                    }
                  </div>
                  <div className="column-6">
                    <ScheduleETable
                      errorsArray={dateErrorsArray}
                      setErrorsArray={setDateErrorsArray}
                      setFormDataFields={setFormDataFields}
                      changeValueHandler={changeValueHandler}
                      formDataFields={formDataFields}
                      formSettings={{...formSettings, isReadOnly: editDisabled}}
                      group={ScheduleEComputerEquipmentName}
                      name="Computer Equipment"
                      formEntry={formEntry}
                    ></ScheduleETable>
                    {!formSettings?.isReadOnly && !editDisabled &&
                      <ScheduleERowHandler
                      formSettings={{...formSettings, isReadOnly: editDisabled}}
                      addRowHandler={addRowHandler}
                      removeRowHandler={removeRowHandler}
                      group={ScheduleEComputerEquipmentName}
                      formEntry={formEntry}
                      ></ScheduleERowHandler>}
                  </div>
                </div>
              </div>
              <div className="print-together">
                <div className="row">
                  <div className="column-6">
                    <ScheduleETable
                      errorsArray={dateErrorsArray}
                      setErrorsArray={setDateErrorsArray}
                      setFormDataFields={setFormDataFields}
                      changeValueHandler={changeValueHandler}
                      formDataFields={formDataFields}
                      formSettings={{...formSettings, isReadOnly: editDisabled}}
                      group={ScheduleEManufacturingEquipmentName}
                      name="POS/Servers/Mainframes"
                      formEntry={formEntry}
                    ></ScheduleETable>
                    {!formSettings?.isReadOnly && !editDisabled &&
                      <ScheduleERowHandler
                      formSettings={{...formSettings, isReadOnly: editDisabled}}
                      addRowHandler={addRowHandler}
                      removeRowHandler={removeRowHandler}
                      group={ScheduleEManufacturingEquipmentName}
                      formEntry={formEntry}
                      ></ScheduleERowHandler>}
                  </div>
                  <div className="column-6">
                    <ScheduleETable
                      errorsArray={dateErrorsArray}
                      setErrorsArray={setDateErrorsArray}
                      setFormDataFields={setFormDataFields}
                      changeValueHandler={changeValueHandler}
                      formDataFields={formDataFields}
                      formSettings={{...formSettings, isReadOnly: editDisabled}}
                      group={ScheduleEOtherName}
                      name="Other (any other items not listed in other schedules)"
                      formEntry={formEntry}
                    ></ScheduleETable>
                    {!formSettings?.isReadOnly && !editDisabled &&
                      <ScheduleERowHandler
                      formSettings={{...formSettings, isReadOnly: editDisabled}}
                      addRowHandler={addRowHandler}
                      removeRowHandler={removeRowHandler}
                      group={ScheduleEOtherName}
                      formEntry={formEntry}
                      ></ScheduleERowHandler>}
                  </div>
                </div>
              </div>
            </div>
            </div>
          </form>
        </PanelBarItem>
      </PanelBar>
    </>
  );
}
