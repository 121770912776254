import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./BPPForm.module.scss";
import {
    Checkbox,
    CheckboxChangeEvent,
    InputChangeEvent,
    TextAreaChangeEvent
} from "@progress/kendo-react-inputs";
import {
    IBppFormThirteenthSectionInterface
} from "../../../../../interfaces/forms/BPPForm/bpp-form-thirteenth-section.interface";
import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import {DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import {useEffect, useRef, useState} from "react";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IBppFormThirteenthSectionInterface) => void;
    formDataFields?:  IBppFormThirteenthSectionInterface,
    formSettings?: IFormSettingModel
    setFormIsValid: (value: boolean) => void
}


export default function BPPFormThirteenthSection(props: GeneralFormType): JSX.Element {
    const {setFormDataFields, formDataFields, formSettings, isAdminPreview, setFormIsValid} = props;

    const formRef = useRef<HTMLFormElement>(null);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={13} name={"Optional"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent,
        field: string,
    )
    {
        setFormDataFields({
            ...formDataFields,
            [field]: event.value
        });
    }

    function changeValidator() {
        if(formRef.current) {
            setIsValid(formRef.current.checkValidity());
            setFormIsValid(formRef.current.checkValidity());
        }
    }

    function onTouch(){
        if(!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current]);


    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={`${classes.itemFields}`}>
                                <div className={`${classes.fieldsGroup}`}>
                                    <div className="inputsGroup">
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.TimelyAppliedForSep1InventoryDate}
                                            label={"Check this box if you timely applied for a Sept. 1 inventory date."}
                                            onChange={e => changeValueHandler(e, "TimelyAppliedForSep1InventoryDate")}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.itemFields}`}>
                                <div className={`${classes.fieldsGroup}`}>
                                    <div className="inputsGroup">
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.InventoryInvolvedInterstateCommerceIssues}
                                            label={"Check this box if your inventory involved interstate/foreign commerce issues."}
                                            onChange={e => changeValueHandler(e, "InventoryInvolvedInterstateCommerceIssues")}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.itemFields}`}>
                                <div className={`${classes.fieldsGroup}`}>
                                    <div className="inputsGroup">
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.InventoryInvolvedFreeportGoods}
                                            label={"Check this box if your inventory involved freeport goods."}
                                            onChange={e => changeValueHandler(e, "InventoryInvolvedFreeportGoods")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}