import {
    action,
    actionError,
    actionPayload,
} from "src/context/generic-actions";

export enum SelectedFormActionsEnum {
    ERROR = "ERROR",
    IS_LOADING = "IS_LOADING",
    FORM = "FORM",
    FORM_SETTINGS = "FORM_SETTINGS",
    FORM_FIELDS = "FORM_FIELDS",
    FORM_TIMELINES = "FORM_TIMELIENS",
    PACS_FORM_ASSETS = "PACS_FORM_ASSETS",
    PREFILLED_DATA = "PREFILLED_DATA",
    FORM_ENTRY = "FORM_ENTRY",
    SUCCESS_MESSAGE = "SUCCESS_MESSAGE",
    CLEAR = "CLEAR",
    FORM_EXISTS = "FORM_EXISTS"
}

export const Dispatches = {
    requestLogin: action,
    loginError: actionError,
    loginSuccess: actionPayload,
};
