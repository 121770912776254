export type IDisabledVetFifthSection = {
    ExemptionType?: string;
    VeteransName?: string;
    VeteransBranchOfServices?: string;
    VeteransDisabilityRating?: string;
    VeteransAge?: string;
    VeteransSerialNumber?: string;
    DisabilityType?: string;
    SurvivingSpouseOfVeteran?: boolean;
    SurvivingSpouseRemarried?: boolean;
    SurvivingSpouseOfUSServiceMember?: boolean;
    SurvivingChild?: boolean;
    SpouseSurviveVeteran?: boolean;
    ChildUnder18?: boolean;
    ChildUnmarried?: boolean;
    NumberOfChildren?: number;
}

export const DisabledVetFifthSectionInterface = (): IDisabledVetFifthSection => ({
    ExemptionType: undefined,
    VeteransName: undefined,
    VeteransBranchOfServices: undefined,
    VeteransDisabilityRating: undefined,
    VeteransAge: undefined,
    VeteransSerialNumber: undefined,
    DisabilityType: undefined,
    SurvivingSpouseOfVeteran: undefined,
    SurvivingSpouseRemarried: undefined,
    SurvivingSpouseOfUSServiceMember: undefined,
    SurvivingChild: undefined,
    SpouseSurviveVeteran: undefined,
    ChildUnder18: undefined,
    ChildUnmarried: undefined,
    NumberOfChildren: undefined
})