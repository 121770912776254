import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import {useEffect} from "react";
import {Upload, UploadFileInfo, UploadOnAddEvent} from "@progress/kendo-react-upload";
import {
    saveSupportingDocumentsHelper
} from "../../../../../context/SelectedForm/selected-form-context.helper";
import {IFormFilesInterface} from "../../../../../interfaces/forms/Shared/form-files.interface";
import {AxiosError} from "axios";
import {IFormEntryModel} from "../../../../../interfaces/form-entry.interface";
import {GenericActionType} from "../../../../../context/generic-actions.type";
import {SelectedFormType} from "../../../../../context/SelectedForm/selected-form-reducer";
import { temporaryError } from "../../../../../context/shared/shared-context.helper";
import classes from "../HomesteadExemptionForm/HomesteadExemption.module.scss";
import { Label, Error } from "@progress/kendo-react-labels";
import FilesList from "../FilesList";
import { IFormFiledInterface } from "src/interfaces/form-filed.interface";

interface GeneralFormType {
    files: UploadFileInfo[];
    setFiles: (value: UploadFileInfo[]) => void;
    uploadIsValid: boolean;
    setUploadIsValid: (value: boolean) => void;
    isAdminPreview?: boolean,
    formDataFields: any,
    setFormDataFields: (form: any) => void;
    formSettings?: IFormSettingModel,
    selectedFormDispatch: (value: GenericActionType<SelectedFormType>) => void,
    fieldName: string,
    isRequired: boolean,
    formEntry?: IFormEntryModel,
    multiple: boolean;
    isLabelRequired: boolean;
    labelText: string;
    setStateChanged?: (value: boolean) => void
    onFileRemoved?: (file: IFormFilesInterface) => void,
}

const SUPPORTED_TYPES = ["application/pdf", "image/png", "image/jpg", "image/jpeg"];

const isUnsupportedFileType = (file: UploadFileInfo) => {
    const type = file.getRawFile?.()?.type;
    return type === undefined || !SUPPORTED_TYPES.includes(type);
}

export function CustomUpload(props: GeneralFormType) : JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, uploadIsValid, setUploadIsValid, isAdminPreview, files, setFiles,
        fieldName, isRequired, formEntry, selectedFormDispatch, multiple, isLabelRequired, labelText, setStateChanged,
        onFileRemoved} = props;
    const validationMessage = "This field is required!";

    useEffect(() => {
        return () => {
            if (setUploadIsValid) {
                setUploadIsValid(true);
            }
        }
    }, []);

    useEffect(() => {
        if (isRequired && setUploadIsValid) {
            setUploadIsValid( files.length > 0 );
        } else if (setUploadIsValid) {
            setUploadIsValid(true);
        }
    }, [files.length, isRequired]);

    useEffect(() => {
        if (formDataFields?.[fieldName]) {
            setUploadIsValid(true);
        }
    }, [formDataFields?.[fieldName]]);

    const isSubmitted = formEntry?.submissionStatus === "Submitted" ||
        formEntry?.submissionStatus === "Approved" ||
        formEntry?.submissionStatus === "Denied";

    const onAddHandler = (event: UploadOnAddEvent) => {
        const unsupportedFiles = event.affectedFiles.filter(isUnsupportedFileType);

        if (unsupportedFiles.length > 0) {
            const extensions = unsupportedFiles.map(file => file.extension).join(', ');
            temporaryError(selectedFormDispatch, `Unsupported file type: ${extensions}`);
            return;
        }


        if (formEntry?.accessCode) {
            const filesToUpload = event.affectedFiles.map((file) => ({
                Id: file.uid ,
                AccessCode: formEntry?.accessCode,
                FieldKey: fieldName,
                Files: file}));
            if (!multiple) {
                const filesFieldArray: Array<IFormFilesInterface> = (formDataFields as any)?.[fieldName] ?? [];
                if (filesFieldArray.length) {
                    const fileToDelete = filesFieldArray[0];
                    saveSupportingDocumentsHelper(filesToUpload)
                        .then((response: IFormFilesInterface[])=>{
                            if (response) {
                                setFormDataFields({
                                    ...formDataFields,
                                    [fieldName]: response,
                                });
                                setFiles(event.newState);

                                if(setStateChanged) {
                                    setStateChanged(true);
                                }
                            }
                        })
                        .catch((error: AxiosError) => {
                            temporaryError(selectedFormDispatch, error.response?.data);
                        })
                    return;
                }
            }
            saveSupportingDocumentsHelper(filesToUpload)
            .then((response: IFormFilesInterface[]) => {
                if (response) {
                    const files: Array<IFormFilesInterface> = (formDataFields as any)?.[fieldName] ?? [];

                    response.forEach(file => {
                        files?.push(file);
                    });

                    setFormDataFields({
                        ...formDataFields,
                        [fieldName]: files
                    });
                    setFiles(event.newState);

                    if(setStateChanged) {
                        setStateChanged(true);
                    }
                }
            })
            .catch((error: AxiosError) => {
                temporaryError(selectedFormDispatch, error.response?.data);
            })
        }
    };

    const onRemoveHandler = (event: UploadOnAddEvent) => {
        if (formEntry?.accessCode) {
            event.affectedFiles.forEach(file => {
                let files: Array<IFormFilesInterface> = (formDataFields as any)?.[fieldName]
                files = files.filter(x => x.id != file.uid);

                setFormDataFields({
                    ...formDataFields,
                    [fieldName]: files,
                });
                setFiles(event.newState);

                if(setStateChanged) {
                    setStateChanged(true);
                }
            });
        }
    };

    return <>
        { isLabelRequired && labelText &&
            <Label className={classes.Label}>
                {labelText}
            </Label>
        }

        { (!formEntry?.isUploadReadOnly) && !isAdminPreview  &&
            <div className={!uploadIsValid ? classes.invalidState : ""}>
                <Upload
                    multiple={multiple}
                    disabled={formSettings?.isReadOnly && formEntry?.isUploadReadOnly}
                    autoUpload={false}
                    showActionButtons={false}
                    onAdd={e => onAddHandler(e)}
                    onRemove={e => !isSubmitted && onRemoveHandler(e)}
                    accept={SUPPORTED_TYPES.join(', ')}
                />
            </div>
        }
        <FilesList
            setFormDataFields={setFormDataFields}
            selectedFormDispatch={selectedFormDispatch}
            setFiles={setFiles}
            formEntry={formEntry}
            formSettings={formSettings}
            formDataFields={formDataFields}
            fieldName={fieldName}
            files={files}
            isAdminPreview={isAdminPreview}
            setStateChanged={setStateChanged}
            onFileRemoved={onFileRemoved}
        />
        {!uploadIsValid && (
            <Error>{validationMessage}</Error>
        )}
    </>
}
