import instance from "src/axios-instance";
import { IPagination } from "src/interfaces/grid-setup.interface";

export function getAlerts(data?: IPagination | null): any {
  const pagination = {
    page: data ? data.page : 0,
    size: data ? data.size : 0,
  };

  return instance.get(`alerts?page=${pagination.page}&size=${pagination.size}`);
}

export function getAlertsByUserId(userId: number): any {
  return instance.get(`alerts/user/${userId}`);
}

export function getAlertsCount(): any {
  return instance.get(`alerts/count`);
}

export function setAlertSeen(alertId: number): any {
  return instance.put(`alerts/${alertId}/acknowledged`);
}

export function acknowledgeAll(): any {
  return instance.put("alerts/acknowledge");
}
