import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "./WildlifeManagement.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { DatePickerChangeEvent, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, RadioGroupChangeEvent, Checkbox, Input } from "@progress/kendo-react-inputs";
import { FormValidation } from "../InputValidator";
import { Error, Label } from "@progress/kendo-react-labels";
import { IWildlifeManagementSecondSection } from "src/interfaces/forms/WildlifeManagement/wildlife-management-second-section.interface";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IWildlifeManagementSecondSection) => void;
    formDataFields?:  IWildlifeManagementSecondSection,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function WildlifeManagementSecondSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, setFormIsValid } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator } = FormValidation(formRef);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} part={"II"} name={"Qualifying Wildlife Management Activities"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string,
        group?: string
    ) {
        if (group) {
            setFormDataFields({
                ...formDataFields,
                [group]: {
                    ...(formDataFields as any)?.[group],
                    [field]: event.value
                }
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    const wildLifeManagementPracticesValidator = () => {
        if (!formDataFields?.WildlifeManagementPractices) {
            return false;
        }
        return Object.values(formDataFields?.WildlifeManagementPractices as Record<string, boolean>)
            .filter(e => e === true)
            .length >= 3;
    };

    function changeValidator() {
        if (formRef.current) {
            const isValid = formRef.current.checkValidity() && wildLifeManagementPracticesValidator();
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if (!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [ formDataFields, formRef.current ]);

    return (
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={`${classes.itemFields} ${classes.bordered}`}>
                                <Label className={classes.Label}>
                                    Check the wildlife management practices implemented on the property during 
                                    the year being reported. A minimum of three practices is required.
                                    {!wildLifeManagementPracticesValidator() && (
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    )}
                                </Label>
                                <div className={classes.checkboxGroup}>
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.WildlifeManagementPractices?.HabitatControl}
                                        label={"Habitat control"}
                                        onChange={(e)=> changeValueHandler(e, "HabitatControl", "WildlifeManagementPractices")}
                                    />
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.WildlifeManagementPractices?.ErosionControl}
                                        label={"Erosion control"}
                                        onChange={(e)=> changeValueHandler(e, "ErosionControl", "WildlifeManagementPractices")}
                                    />
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.WildlifeManagementPractices?.PredatorControl}
                                        label={"Predator control"}
                                        onChange={(e)=> changeValueHandler(e, "PredatorControl", "WildlifeManagementPractices")}
                                    />
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.WildlifeManagementPractices?.MakingCensusCountsToDeterminePopulation}
                                        label={"Making census counts to determine population."}
                                        onChange={(e)=> changeValueHandler(e, "MakingCensusCountsToDeterminePopulation", "WildlifeManagementPractices")}
                                    />
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.WildlifeManagementPractices?.ProvideSupplementalSuppliesOfWater}
                                        label={"Provide supplemental supplies of water"}
                                        onChange={(e)=> changeValueHandler(e, "ProvideSupplementalSuppliesOfWater", "WildlifeManagementPractices")}
                                    />
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.WildlifeManagementPractices?.ProvideSupplementalSuppliesOfFood}
                                        label={"Provide supplemental supplies of food"}
                                        onChange={(e)=> changeValueHandler(e, "ProvideSupplementalSuppliesOfFood", "WildlifeManagementPractices")}
                                    />
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.WildlifeManagementPractices?.ProvideShelters}
                                        label={"Provide shelters"}
                                        onChange={(e)=> changeValueHandler(e, "ProvideShelters", "WildlifeManagementPractices")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    )
}