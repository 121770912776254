import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "./DisabledVetForm.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { DatePickerChangeEvent, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, RadioGroupChangeEvent, Input, TextArea, RadioGroup } from "@progress/kendo-react-inputs";
import { FormValidation } from "../InputValidator";
import { Error, Label } from "@progress/kendo-react-labels";
import { StatesInput } from "../CustomInputs/StatesInput";
import { ZipCodeInput } from "../CustomInputs/ZipCodeInput";
import { IDisabledVetThirdSection } from "src/interfaces/forms/DisableVetForm/disable-vet-form-third-section";
import { PhoneNumberInput } from "../CustomInputs/PhoneNumberInputs/PhoneNumberInput";
import { EmailInput } from "../CustomInputs/EmailInputs/EmailInput";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IDisabledVetThirdSection) => void;
    formDataFields?:  IDisabledVetThirdSection,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function DisabledVetThirdSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, setFormIsValid } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const [representativePhoneNumberValid, setRepresentativePhoneNumberValid] = useState<boolean>(false);
    const [representativeEmailValid, setRepresentativeEmailValid] = useState<boolean>(false);
    const [representativeMailingStateValid, setRepresentativeMailingStateValid] = useState<boolean>(false);
    const [representativeMailingZipCodeValid, setRepresentativeMailingZipCodeValid] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator } = FormValidation(formRef);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={3} name={"Authorized Representative"}/>;

    const authorizedRepresentationBasisRadioButtonsData = [
        { label: "Attorney for property owner", value: "Attorney" },
        { label: "Agent for tax matters appointed under Tax Code Section 1.111 with completed and signed Form 50-162", value: "Agent" },
        { label: "Other and explain basis", value: "Other" }
    ];

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string
    ) {
        setFormDataFields({
            ...formDataFields,
            [field]: event.value
        });
    }

    function changeValidator() {
        if (formRef.current) {
            const isValid = formRef.current.checkValidity() &&
                representativePhoneNumberValid &&
                representativeEmailValid &&
                representativeMailingStateValid &&
                representativeMailingZipCodeValid
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if (!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [
        formDataFields,
        formRef.current,
        representativePhoneNumberValid,
        representativeEmailValid,
        representativeMailingStateValid,
        representativeMailingZipCodeValid
    ]);

    return (
       <>
            <PanelBar keepItemsMounted={true}>
                <PanelBarItem title={title} expanded={true}>
                    <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                        <div className="container">
                            <div className={`${classes.item}`}>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    If you are an individual property owner filing this application on your own 
                                    behalf, skip to section 4; all other applicants are required to complete section 3.
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup} ${classes.fieldsGroupW50}`}>
                                    <Label className={classes.Label}>
                                        Please indicate the basis for your authority to represent the property owner in filing this application.
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <RadioGroup
                                            layout={"vertical"}
                                            name={"AuthorizedRepresentationBasis"}
                                            disabled={formSettings?.isReadOnly}
                                            data={authorizedRepresentationBasisRadioButtonsData}
                                            onChange={e => changeValueHandler(e, "AuthorizedRepresentationBasis")}
                                            value={formDataFields?.AuthorizedRepresentationBasis}
                                        />
                                    </div>
                                    { formDataFields?.AuthorizedRepresentationBasis === "Other" &&
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <Label className={classes.Label}>
                                                Explain Basis
                                                {!formDataFields?.AuthorizedRepresentationBasisOther && (
                                                    <i className={"fa fa-asterisk text-danger"}></i>
                                                )}
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                required={true}
                                                name={"AuthorizedRepresentationBasisOther"}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.AuthorizedRepresentationBasisOther}
                                                onChange={(e)=> changeValueHandler(e, "AuthorizedRepresentationBasisOther")}
                                            />
                                            {!inputValidator("AuthorizedRepresentationBasisOther") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                    }
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    Provide the following information for the individual with the legal authority to act 
                                    for the property owner in this matter
                                </div>
                            </div>
                            <div className={`${classes.item} ${classes.flexRow}`}>
                                <div className={`${classes.fieldsGroupW50}`}>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                        <Label className={classes.Label}>
                                            Name of Authorized Representative
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            required={false}
                                            name={"RepresentativeName"}
                                            placeholder={"Name of Authorized Representative"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.RepresentativeName}
                                            onChange={(e)=> changeValueHandler(e, "RepresentativeName")}
                                        />
                                    </div>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                        <Label className={classes.Label}>
                                            Title of Authorized Representative
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            required={false}
                                            name={"RepresentativeTitle"}
                                            placeholder={"Title of Authorized Representative"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.RepresentativeTitle}
                                            onChange={(e)=> changeValueHandler(e, "RepresentativeTitle")}
                                        />
                                    </div>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                        <Label className={classes.Label}>
                                            Phone Number
                                        </Label>
                                        <PhoneNumberInput
                                            required={false}
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"RepresentativePhoneNumber"}
                                            placeholder={"Primary Phone Number (area code and number)"}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                            setIsValidField={setRepresentativePhoneNumberValid}
                                            validField={representativePhoneNumberValid}
                                        />
                                    </div>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                        <Label className={classes.Label}>
                                            Email Address
                                        </Label>
                                        <EmailInput
                                            required={false}
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"RepresentativeEmailAddress"}
                                            placeholder={"Email Address"}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                            setIsValidField={setRepresentativeEmailValid}
                                            validField={representativeEmailValid}
                                        />
                                    </div>
                                </div>
                                <div className={`${classes.fieldsGroupW50}`}>
                                    <div className={`${classes.item}`}>
                                        <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                            <p>Mailing Address</p>
                                        </div>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <Label className={classes.Label}>
                                                Address Line 1
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                required={false}
                                                name={"RepresentativeMailingAddressLine1"}
                                                placeholder={"Address Line 1"}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.RepresentativeMailingAddressLine1}
                                                onChange={(e)=> changeValueHandler(e, "RepresentativeMailingAddressLine1")}
                                            />
                                        </div>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <Label className={classes.Label}>
                                                Address Line 2
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                name={"RepresentativeMailingAddressLine2"}
                                                placeholder={"Address Line 2"}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.RepresentativeMailingAddressLine2}
                                                onChange={(e)=> changeValueHandler(e, "RepresentativeMailingAddressLine2")}
                                            />
                                        </div>
                                        <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                            <div className={`${classes.fieldsGroupW50}`}>
                                                <Label className={classes.Label}>
                                                    City
                                                </Label>
                                                <Input
                                                    className={classes.kTextbox}
                                                    required={false}
                                                    name={"RepresentativeMailingAddressCity"}
                                                    placeholder={"City"}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.RepresentativeMailingAddressCity}
                                                    onChange={(e)=> changeValueHandler(e, "RepresentativeMailingAddressCity")}
                                                />
                                            </div>
                                            <div className={`${classes.fieldsGroupW20}`}>
                                                <Label className={classes.Label}>
                                                    State
                                                </Label>
                                                <StatesInput
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"RepresentativeMailingAddressState"}
                                                    required={false}
                                                    validField={representativeMailingStateValid}
                                                    setIsValidField={setRepresentativeMailingStateValid}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                />
                                            </div>
                                            <div className={`${classes.fieldsGroupW20}`}>
                                                <Label className={classes.Label}>
                                                    Zip Code
                                                </Label>
                                                <ZipCodeInput
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"RepresentativeMailingAddressZip"}
                                                    placeholder={"Zip Code"}
                                                    required={false}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                    setIsValidField={setRepresentativeMailingZipCodeValid}
                                                    validField={representativeMailingZipCodeValid}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                   </form>
                </PanelBarItem>
            </PanelBar>
       </>
    )
}