import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArchive,
  faBan,
  faBars,
  faBell,
  faBellSlash,
  faBook,
  faCalendarAlt,
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCog,
  faCogs,
  faComment,
  faComments,
  faCopy,
  faDownload,
  faEdit,
  faEnvelope,
  faEnvelopeSquare,
  faExclamationTriangle,
  faEye,
  faFile,
  faFileAlt,
  faFileExcel,
  faFilePdf,
  faFilter,
  faFrown,
  faHistory,
  faInfo,
  faInfoCircle,
  faLock,
  faObjectGroup,
  faPaperPlane,
  faPen,
  faPlus,
  faPowerOff,
  faRecycle,
  faSave,
  faSearch,
  faShieldAlt,
  faSignInAlt,
  faSignOutAlt,
  faSitemap,
  faSpinner,
  faStar,
  faStickyNote,
  faSyncAlt,
  faTimes,
  faTimesCircle,
  faTrash,
  faUndo,
  faUnlock,
  faUser,
  faUserCircle,
  faUserPlus,
  faUsers,
  faWindowMaximize,
  faWindowMinimize,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faCheck,
  faStar,
  faArchive,
  faCopy,
  faUser,
  faComments,
  faFileAlt,
  faBell,
  faSignOutAlt,
  faPowerOff,
  faSearch,
  faTimes,
  faCircle,
  faUsers,
  faLock,
  faSave,
  faInfoCircle,
  faFileExcel,
  faPlus,
  faCogs,
  faCog,
  faEnvelope,
  faEnvelopeSquare,
  faBars,
  faSyncAlt,
  faComment,
  faPaperPlane,
  faSitemap,
  faShieldAlt,
  faBan,
  faUndo,
  faRecycle,
  faUserCircle,
  faTrash,
  faEdit,
  faPen,
  faObjectGroup,
  faFilter,
  faExclamationTriangle,
  faDownload,
  faFile,
  faStickyNote,
  faBook,
  faChevronLeft,
  faChevronRight,
  faFilePdf,
  faWindowMinimize,
  faWindowMaximize,
  faCalendarAlt,
  faHistory,
  faInfo,
  faUnlock,
  faEye,
  faSpinner,
  faBellSlash,
  faSignInAlt,
  faUserPlus,
  faCheckCircle,
  faTimesCircle,
  faFrown
);
