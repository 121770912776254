import {InputChangeEvent} from "@progress/kendo-react-inputs";

export interface ChangeValueHandlerType{
    event: InputChangeEvent,
    setErrors: (item:ErrorState) => void,
    errors: ErrorState,
    required: boolean
}

export interface ErrorState{
    required: boolean,
    pattern: boolean,
}

export const EmailInputErrorsHandler = (props: ChangeValueHandlerType) => {
    const { event, errors, setErrors, required } = props;
    const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (!event.value && required) {
        setErrors({...errors, required:true});
    } else if (event.value === "" && !required) {
        setErrors({...errors, pattern:false});
    } else if (event.value && !pattern.test(event.value)) {
        setErrors({...errors, pattern:true});
    } else {
        setErrors({ pattern: false, required: false });
    }
}
