import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import {useEffect, useRef, useState} from "react";
import {
    Checkbox,
    CheckboxChangeEvent, 
    InputChangeEvent, RadioGroup,
    RadioGroupChangeEvent, 
    TextAreaChangeEvent
} from "@progress/kendo-react-inputs";
import {CalendarChangeEvent, DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {ComboBox, ComboBoxChangeEvent, DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./AgForm.module.scss";
import {Label} from "@progress/kendo-react-labels";
import {IAgFormSeventhSectionInterface} from "../../../../../interfaces/forms/AgForm/ag-form-seventh-section.interface";
import {UploadFileInfo} from "@progress/kendo-react-upload";
import {GenericActionType} from "../../../../../context/generic-actions.type";
import {SelectedFormType} from "../../../../../context/SelectedForm/selected-form-reducer";
import {IFormEntryModel} from "../../../../../interfaces/form-entry.interface";
import {CustomUpload} from "../CustomInputs/CustomUpload";
import SectionTitle from "../Shared/SectionTitle";
import { defaultRadioButtonData } from "../Shared/RadioButtonData";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IAgFormSeventhSectionInterface) => void;
    formDataFields?:  IAgFormSeventhSectionInterface,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void,
    formEntry?: IFormEntryModel,
    selectedFormDispatch: (value: GenericActionType<SelectedFormType>) => void,
}

export default function AgFormSeventhSection(props: GeneralFormType): JSX.Element {
    const {setFormDataFields, formDataFields, formSettings, isAdminPreview, setFormIsValid, formEntry, selectedFormDispatch} = props;

    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} name={"Section 5: Wildlife Management Use"}/>;

    const WildlifeManagementPractice = [
        'Habitat Control',
        'Erosion Control',
        'Predator Control',
        'Providing Supplemental Supplies of Water',
        'Providing Supplement Supplies of Food',
        'roviding Shelters',
        'Making Census Counts to Determine Population',
        'Other',
    ];

    const AgriculturalLandUseCategory = [
        'Irrigated Cropland',
        'Dry Cropland',
        'Improved Pastureland',
        'Native Pastureland',
        'Orchard',
        'Wasteland',
        'Timber Production',
        'Wildlife Management',
        'Other categories of land that are typical in the area'
    ];

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent
            | ComboBoxChangeEvent,
        field: string,
        eventType?: string
    ) {
        if(eventType === "RadioGroupChangeEvent"){
            setFormDataFields({
                ...formDataFields,
                [field]: JSON.parse(event.value)
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    function changeValidator() {
        if (formRef.current) {
            setIsValid(formRef.current.checkValidity());
            setFormIsValid(formRef.current.checkValidity());
        }
    }

    function onTouch() {
        if (!isFormTouched) {
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current]);

    const [isWildlifeManagementPlanDocumentationUploadValid, setIsWildlifeManagementPlanDocumentationFiles] = useState<boolean>(true);
    const [isPropertyOwnersAssociationAgreementUploadValid, setIsPropertyOwnersAssociationAgreementUploadValid] = useState<boolean>(true);
    const [isConservationEasmentEvidenceQ7UploadValid, setIsConservationEasmentEvidenceQ7UploadValid] = useState<boolean>(true);
    const [isConservationEvidenceQ8UploadValid, setIsConservationEvidenceQ8UploadValid] = useState<boolean>(true);

    const [WildlifeManagementPlanDocumentationFiles, setWildlifeManagementPlanDocumentationFiles] = useState<Array<UploadFileInfo>>([]);
    const [PropertyOwnersAssociationAgreementFiles, setPropertyOwnersAssociationAgreementFiles] = useState<Array<UploadFileInfo>>([]);
    const [ConservationEasmentEvidenceQ7Files, setConservationEasmentEvidenceQ7Files] =  useState<Array<UploadFileInfo>>([]);
    const [ConservationEvidenceQ8Files, setConservationEvidenceQ8Files] = useState<Array<UploadFileInfo>>([]);

    useEffect(() =>{
        return () => {
            setWildlifeManagementPlanDocumentationFiles([]);
            setPropertyOwnersAssociationAgreementFiles([]);
            setConservationEasmentEvidenceQ7Files([]);
            setConservationEvidenceQ8Files([]);
        }
    },[]);

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={classes.itemFields}>
                                <div className={`${classes.fieldsGroupW}`}>
                                    <div className="inputsGroup">
                                        <Checkbox
                                            name={"IsUsingLandToManageWildlife"}
                                            className={classes.Checkbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.IsUsingLandToManageWildlife}
                                            label={"Check this box if you are using the land to manage wildlife as permitted by law\n"}
                                            onChange={(e)=> changeValueHandler(e, "IsUsingLandToManageWildlife")}
                                        />
                                    </div>
                                </div>
                            </div>
                            { formDataFields?.IsUsingLandToManageWildlife &&
                                <div className={classes.item}>
                                    <div className={classes.alertDanger}>
                                        If the land is not used for wildlife management, do not complete this section.
                                    </div>
                                <div className={classes.itemFields}>
                                    <Label className={classes.Label}>
                                        1. List three of the wildlife management practice in which you manage wildlife.
                                    </Label>
                                    <div className={`${classes.fieldsGroupW100}`}>
                                        <div className="inputsGroup">
                                            <ComboBox
                                                name={"WildlifeManagementPracticeA"}
                                                className={classes.kTextbox}
                                                disabled={props.formSettings?.isReadOnly}
                                                data={WildlifeManagementPractice}
                                                value={formDataFields?.WildlifeManagementPracticeA}
                                                onChange={(e)=> changeValueHandler(e, "WildlifeManagementPracticeA")}
                                            />
                                        </div>
                                    </div>
                                    <div className={`${classes.fieldsGroupW100}`}>
                                        <div className="inputsGroup">
                                            <ComboBox
                                                name={"WildlifeManagementPracticeB"}
                                                className={classes.kTextbox}
                                                disabled={props.formSettings?.isReadOnly}
                                                data={WildlifeManagementPractice}
                                                value={formDataFields?.WildlifeManagementPracticeB}
                                                onChange={(e)=> changeValueHandler(e, "WildlifeManagementPracticeB")}
                                            />
                                        </div>
                                    </div>
                                    <div className={`${classes.fieldsGroupW100}`}>
                                        <div className="inputsGroup">
                                            <ComboBox
                                                name={"WildlifeManagementPracticeC"}
                                                className={classes.kTextbox}
                                                disabled={props.formSettings?.isReadOnly}
                                                data={WildlifeManagementPractice}
                                                value={formDataFields?.WildlifeManagementPracticeC}
                                                onChange={(e)=> changeValueHandler(e, "WildlifeManagementPracticeC")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.itemFields}>
                                    <Label className={classes.Label}>
                                        2. Indicate the property’s agricultural land use category (described in step 6) for the tax year preceding the land’s conversion to wildlife management use.
                                    </Label>
                                    <p>For example, if the land was categorized as native pasture before conversion to wildlife management, native pasture would be the response to this request as it is the category of use prior to conversion.</p>
                                    <div className={`${classes.fieldsGroupW100}`}>
                                        <div className="inputsGroup">
                                            <ComboBox
                                                name={"AgriculturalLandUseCategory"}
                                                className={classes.kTextbox}
                                                disabled={props.formSettings?.isReadOnly}
                                                data={AgriculturalLandUseCategory}
                                                value={formDataFields?.AgriculturalLandUseCategory}
                                                onChange={(e)=> changeValueHandler(e, "AgriculturalLandUseCategory")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div >
                                        Please choose Yes or No for the following questions:
                                    </div>
                                </div>
                                <div className={classes.itemFields}>
                                    <div className={`${classes.flexGroup} ${classes.fieldsGroupW100}`}>
                                        <Label className={classes.Label}>
                                            3. Does the property have a wildlife management plan that uses the appropriate Texas Parks and Wildlife Department form?
                                        </Label>
                                        <div >
                                            <RadioGroup
                                                layout={"horizontal"}
                                                name={"wildlifeManagementPlanUsesTheAppropriateTexasParksandWildlifeDepartmen"}
                                                disabled={formSettings?.isReadOnly}
                                                data={defaultRadioButtonData}
                                                onChange={e => changeValueHandler(e, "wildlifeManagementPlanUsesTheAppropriateTexasParksandWildlifeDepartmen", "RadioGroupChangeEvent")}
                                                value={formDataFields?.wildlifeManagementPlanUsesTheAppropriateTexasParksandWildlifeDepartmen?.toString()}
                                            />
                                        </div>
                                    </div>
                                    <div className={`${classes.fieldsGroupW100}`}>
                                        <div className="inputsGroup">
                                            { formDataFields.wildlifeManagementPlanUsesTheAppropriateTexasParksandWildlifeDepartmen &&
                                            <CustomUpload
                                                files={WildlifeManagementPlanDocumentationFiles}
                                                setFiles={setWildlifeManagementPlanDocumentationFiles}
                                                uploadIsValid={isWildlifeManagementPlanDocumentationUploadValid}
                                                setUploadIsValid={setIsWildlifeManagementPlanDocumentationFiles}
                                                formEntry={formEntry}
                                                formSettings={formSettings}
                                                isAdminPreview={isAdminPreview}
                                                setFormDataFields={setFormDataFields}
                                                formDataFields={formDataFields}
                                                fieldName={"WildlifeManagementPlanDocumentation"}
                                                isRequired={false}
                                                selectedFormDispatch={selectedFormDispatch}
                                                multiple={false}
                                                isLabelRequired={false}
                                                labelText={"Attach the wildlife management plan for the property using the appropriate Texas Parks and Wildlife Department form (obtained at www.tpwd.texas.gov/landwater/land/private/agricultural_land/)."} /> }
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.itemFields}>
                                    <div className={`${classes.flexGroup} ${classes.fieldsGroupW100}`}>
                                        <Label className={classes.Label}>
                                            4.  Was the land subject to wildlife management a part of a larger tract of land qualified for 1-d-1 or timberland appraisal on Jan. 1 of the previous year?
                                        </Label>
                                        <div className="inputsGroup">
                                            <RadioGroup
                                                layout={"horizontal"}
                                                name={"LandWasPartOfLargerTract"}
                                                disabled={formSettings?.isReadOnly}
                                                data={defaultRadioButtonData}
                                                onChange={e => changeValueHandler(e, "LandWasPartOfLargerTract", "RadioGroupChangeEvent")}
                                                value={formDataFields?.LandWasPartOfLargerTract?.toString()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.itemFields}>
                                    <div className={`${classes.flexGroup} ${classes.fieldsGroupW100}`}>
                                        <Label className={classes.Label}>
                                            5. Is any part of the land subject to wildlife management managed through a wildlife management property association?
                                        </Label>
                                        <div className="inputsGroup">
                                            <RadioGroup
                                                layout={"horizontal"}
                                                name={"PartOfLandIsManagedThroughPropertyAssociation"}
                                                disabled={formSettings?.isReadOnly}
                                                data={defaultRadioButtonData}
                                                onChange={e => changeValueHandler(e, "PartOfLandIsManagedThroughPropertyAssociation", "RadioGroupChangeEvent")}
                                                value={formDataFields?.PartOfLandIsManagedThroughPropertyAssociation?.toString()}
                                            />
                                        </div>
                                        {formDataFields?.PartOfLandIsManagedThroughPropertyAssociation &&
                                        <div className={classes.flexGroup}>
                                            <div className={`${classes.information} ${classes.w100}`}>
                                                Attach a written agreement obligating the owners in the association to perform wildlife management practices necessary to qualify wildlife management land for 1-d-1 appraisal.
                                            </div>
                                            <div className={`${classes.fieldsGroupW100}`}>
                                                <div className="inputsGroup">
                                                <CustomUpload
                                                    files={PropertyOwnersAssociationAgreementFiles}
                                                    setFiles={setPropertyOwnersAssociationAgreementFiles}
                                                    uploadIsValid={isPropertyOwnersAssociationAgreementUploadValid}
                                                    setUploadIsValid={setIsPropertyOwnersAssociationAgreementUploadValid}
                                                    formEntry={formEntry}
                                                    formSettings={formSettings}
                                                    isAdminPreview={isAdminPreview}
                                                    setFormDataFields={setFormDataFields}
                                                    formDataFields={formDataFields}
                                                    fieldName={"PropertyOwnersAssociationAgreement"}
                                                    isRequired={false}
                                                    selectedFormDispatch={selectedFormDispatch}
                                                    multiple={true}
                                                    isLabelRequired={false}
                                                    labelText={""} />
                                                </div>
                                            </div>
                                        </div> }
                                    </div>
                                </div>
                                <div className={classes.itemFields}>
                                    <div className={`${classes.flexGroup} ${classes.fieldsGroupW100}`}>
                                        <Label className={classes.Label}>
                                            6. Is any part of the land located in an area designated by Texas Parks and Wildlife Department as a habitat for an endangered species, a threatened species or a candidate species for listing by as threatened or endangered?
                                        </Label>
                                        <div className="inputsGroup">
                                            <RadioGroup
                                                layout={"horizontal"}
                                                name={"PartOfLandIsInHabitatForEndangeredSpecies"}
                                                disabled={formSettings?.isReadOnly}
                                                data={defaultRadioButtonData}
                                                onChange={e => changeValueHandler(e, "PartOfLandIsInHabitatForEndangeredSpecies", "RadioGroupChangeEvent")}
                                                value={formDataFields?.PartOfLandIsInHabitatForEndangeredSpecies?.toString()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.itemFields}>
                                    <div className={`${classes.flexGroup} ${classes.fieldsGroupW100}`}>
                                        <Label className={classes.Label}>
                                            7. (a) Is the land that is the subject of this application subject to a permit issued under Federal Endangered Species Act Section 7 or 10(a)?
                                        </Label>
                                        <div className="inputsGroup">
                                            <RadioGroup
                                                layout={"horizontal"}
                                                name={"LandIsSubjectToPermit7or10a"}
                                                disabled={formSettings?.isReadOnly}
                                                data={defaultRadioButtonData}
                                                onChange={e => changeValueHandler(e, "LandIsSubjectToPermit7or10a", "RadioGroupChangeEvent")}
                                                value={formDataFields?.["LandIsSubjectToPermit7or10a"]?.toString()}
                                            />
                                        </div>
                                        {formDataFields?.["LandIsSubjectToPermit7or10a"] &&
                                            <div className={classes.itemFields}>
                                                <div className={`${classes.flexGroup} ${classes.fieldsGroupW100}`}>
                                                    <Label className={classes.Label}>
                                                        (b) If yes, is the land included in a habitat preserve and subject to a conservation easement created under Texas Natural Resources Code Chapter 183 or part of a conservation development under a federally approved habitat conservation plan?
                                                    </Label>
                                                    <div className="inputsGroup">
                                                        <RadioGroup
                                                            layout={"horizontal"}
                                                            name={"LandIsIncludedInHabitatPreserve"}
                                                            disabled={formSettings?.isReadOnly}
                                                            data={defaultRadioButtonData}
                                                            onChange={e => changeValueHandler(e, "LandIsIncludedInHabitatPreserve", "RadioGroupChangeEvent")}
                                                            value={formDataFields?.LandIsIncludedInHabitatPreserve?.toString()}
                                                        />
                                                    </div>
                                                    {formDataFields?.LandIsIncludedInHabitatPreserve &&
                                                    <div className={classes.itemFields}>
                                                        <Label className={classes.Label}>
                                                            Provide evidence of the permit and of the conservation easement or habitat conservation plan.
                                                            Your application cannot be approved without this evidence.
                                                        </Label>
                                                        <div className={`${classes.fieldsGroupW100}`}>
                                                            <div className="inputsGroup">
                                                            <CustomUpload
                                                                files={ConservationEasmentEvidenceQ7Files}
                                                                setFiles={setConservationEasmentEvidenceQ7Files}
                                                                uploadIsValid={isConservationEasmentEvidenceQ7UploadValid}
                                                                setUploadIsValid={setIsConservationEasmentEvidenceQ7UploadValid}
                                                                formEntry={formEntry}
                                                                formSettings={formSettings}
                                                                isAdminPreview={isAdminPreview}
                                                                setFormDataFields={setFormDataFields}
                                                                formDataFields={formDataFields}
                                                                fieldName={"ConservationEasmentEvidenceQ7"}
                                                                isRequired={false}
                                                                selectedFormDispatch={selectedFormDispatch}
                                                                multiple={false}
                                                                isLabelRequired={false}
                                                                labelText={""} />
                                                            </div>
                                                        </div>
                                                    </div> }
                                                </div>
                                            </div>
                                             }
                                    </div>
                                </div>
                                <div className={classes.itemFields}>
                                    <Label className={classes.Label}>
                                        8. Is the land that is the subject of this application actively used for a conservation or restoration project providing
                                        compensation for natural resources damage under one or more of the following laws:
                                    </Label>
                                    <div className={`${classes.fieldsGroupW100}`}>
                                        <div className={`${classes.flexGroup} ${classes.fieldsGroupW100}`}>
                                            <Label className={classes.itemTitle}>
                                                Comprehensive Environmental Response, Compensation, and Liability Act (42 U.S.C. Section 9601 et seq.)
                                            </Label>
                                            <RadioGroup
                                                layout={"horizontal"}
                                                name={"IsComprehensiveEnvironmentalResponse"}
                                                disabled={formSettings?.isReadOnly}
                                                data={defaultRadioButtonData}
                                                onChange={e => changeValueHandler(e, "IsComprehensiveEnvironmentalResponse", "RadioGroupChangeEvent")}
                                                value={formDataFields?.IsComprehensiveEnvironmentalResponse?.toString()}
                                            />
                                        </div>
                                        <div className={`${classes.flexGroup} ${classes.fieldsGroupW100}`}>
                                            <Label className={classes.itemTitle}>
                                                Oil Pollution Act (33 U.S.C. Section 2701 et seq.)
                                            </Label>
                                            <RadioGroup
                                                layout={"horizontal"}
                                                name={"IsOilPollutionAct"}
                                                disabled={formSettings?.isReadOnly}
                                                data={defaultRadioButtonData}
                                                onChange={e => changeValueHandler(e, "IsOilPollutionAct", "RadioGroupChangeEvent")}
                                                value={formDataFields?.IsOilPollutionAct?.toString()}
                                            />
                                        </div>
                                        <div className={`${classes.flexGroup} ${classes.fieldsGroupW100}`}>
                                            <Label className={classes.itemTitle}>
                                                Federal Water Pollution Control Act (33 U.S.C. Section 1251 et seq.)
                                            </Label>
                                            <RadioGroup
                                                layout={"horizontal"}
                                                name={"IsFederalWaterPollutionAct"}
                                                disabled={formSettings?.isReadOnly}
                                                data={defaultRadioButtonData}
                                                onChange={e => changeValueHandler(e, "IsFederalWaterPollutionAct", "RadioGroupChangeEvent")}
                                                value={formDataFields?.IsFederalWaterPollutionAct?.toString()}
                                            />
                                        </div>
                                        <div className={`${classes.flexGroup} ${classes.fieldsGroupW100}`}>
                                            <Label className={classes.itemTitle}>
                                                Texas Natural Resources Code Chapter 40
                                            </Label>
                                            <RadioGroup
                                                layout={"horizontal"}
                                                name={"IsChapter40"}
                                                disabled={formSettings?.isReadOnly}
                                                data={defaultRadioButtonData}
                                                onChange={e => changeValueHandler(e, "IsChapter40", "RadioGroupChangeEvent")}
                                                value={formDataFields?.IsChapter40?.toString()}
                                            />
                                        </div>
                                        { formDataFields?.['IsComprehensiveEnvironmentalResponse'] ||
                                            formDataFields?.['IsOilPollutionAct'] ||
                                            formDataFields?.['IsFederalWaterPollutionAct'] ||
                                            formDataFields?.['IsChapter40'] &&
                                        <div className="inputsGroup">
                                            <Label className={classes.itemTitle}>
                                                Provide evidence of the conservation easement, deed restriction or settlement agreement with the Texas Commission on
                                                Environmental Quality. Applications without this evidence cannot be approved.
                                            </Label>
                                            <CustomUpload
                                                files={ConservationEvidenceQ8Files}
                                                setFiles={setConservationEvidenceQ8Files}
                                                uploadIsValid={isConservationEvidenceQ8UploadValid}
                                                setUploadIsValid={setIsConservationEvidenceQ8UploadValid}
                                                formEntry={formEntry}
                                                formSettings={formSettings}
                                                isAdminPreview={isAdminPreview}
                                                setFormDataFields={setFormDataFields}
                                                formDataFields={formDataFields}
                                                fieldName={"ConservationEvidenceQ8"}
                                                isRequired={false}
                                                selectedFormDispatch={selectedFormDispatch}
                                                multiple={false}
                                                isLabelRequired={false}
                                                labelText={""} />
                                        </div> }
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
