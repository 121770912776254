import classes from "../BPP-Form-PDF-style.module.scss";
import shared from "../../PDF-Shared-style.module.scss";
import { GetBlankRows } from "../../BlankRows";
import { chunkArray } from "src/utils/array";
import {
  IBppFormEighthSectionInterface,
  IScheduleB,
} from "src/interfaces/forms/BPPForm/bpp-form-eighth-section.interface";

const maxRowsPerPage = 34;

export const ScheduleB = (props: {
  formDataFieldsEighthSection?: IBppFormEighthSectionInterface;
}) => {
  const { formDataFieldsEighthSection } = props;

  const [primaryTable, ...additionalTables] = chunkArray(
    formDataFieldsEighthSection?.ScheduleB || [],
    maxRowsPerPage
  );

  return (
    <>
      <div
        className={`keepTogether ${classes.ScheduleSection} ${shared.PrintTogether}`}
      >
        <div className={shared.Row}>
          <div className={shared.Column12}>
            <b>PERSONAL PROPERTY VALUED AT $20,000 OR MORE</b>
          </div>
        </div>
        <div className={shared.Step}>
          SCHEDULE B: INVENTORY, RAW MATERIALS AND WORK IN PROCESS
        </div>
        <div className={shared.Row}>
          <div className={shared.Column12}>
            <div>
              List all taxable inventories by type of property. If needed,
              attach additional sheets OR a computer-generated copy listing the
              information below. If you manage or control property as a
              fiduciary on Jan. 1, also list the names and addresses of each
              property owner.
            </div>
          </div>
          <ScheduleBTable rows={primaryTable} blankRows={7} />
        </div>
      </div>

      {additionalTables.map((rows) => (
        <div
          className={`keepTogether ${classes.ScheduleSection} ${shared.PrintTogether}`}
        >
          <div className={shared.Row}>
            <ScheduleBTable rows={rows} blankRows={0} />
          </div>
        </div>
      ))}
    </>
  );
};

const ScheduleBTable = (props: { rows?: IScheduleB[]; blankRows: number }) => {
  const { rows, blankRows } = props;

  return (
    <div className={shared.Column12}>
      <table className={shared.Blue}>
        <tr>
          <th style={{ width: "20%" }}>
            Property Description by Type/Category
          </th>
          <th style={{ width: "20%" }}>
            Property Address or Address Where Taxable
          </th>
          <th style={{ width: "8%" }}>Estimate of Quantity of Each Type</th>
          <th style={{ width: "9%" }}>Good Faith Estimate of Market Value*</th>
          <th style={{ width: "9%" }}>Historical Cost When New*</th>
          <th style={{ width: "8%" }}>Year Acquired*</th>
          <th style={{ width: "26%" }}>
            Property Owner Name/Address
            <br />
            (if you manage or control property as a fiduciary)
          </th>
        </tr>
        {rows
          ?.filter((x) => !x.HasActiveSubSegments)
          ?.map((item) => {
            return (
              <tr key={item.Id}>
                <td>{item?.Description}</td>
                <td>{item?.PropertyAddress}</td>
                <td>{item?.Quantity}</td>
                <td>
                  {/*currency*/}
                  {item?.MarketValue}
                </td>
                <td>
                  {/*currency*/}
                  {item?.HistoricCost}
                </td>
                <td>{item?.YearAcquired}</td>
                <td>{item?.PropertyOwnerName}</td>
              </tr>
            );
          })}
        {GetBlankRows(rows?.length, blankRows)}
      </table>
    </div>
  );
};
