import { useEffect, useRef, useState } from "react";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import shared from "../PDF-Shared-style.module.scss";
import classes from "./DisabledVet-Form-PDF-style.module.scss";
import { settingsService } from "src/services/settings.service";
import { IDisabledVetFirstSection } from "src/interfaces/forms/DisableVetForm/disable-vet-form-first-section";
import { IDisabledVetEighthSection } from "src/interfaces/forms/DisableVetForm/disable-vet-form-eighth-section";
import { IDisabledVetFifthSection } from "src/interfaces/forms/DisableVetForm/disable-vet-form-fifth-section";
import { IDisabledVetFourthSection } from "src/interfaces/forms/DisableVetForm/disable-vet-form-fourth-section";
import { IDisabledVetSecondSection } from "src/interfaces/forms/DisableVetForm/disable-vet-form-second-section";
import { IDisabledVetSeventhSection } from "src/interfaces/forms/DisableVetForm/disable-vet-form-seventh-section";
import { IDisabledVetSixthSection } from "src/interfaces/forms/DisableVetForm/disable-vet-form-sixth-section";
import { IDisabledVetThirdSection } from "src/interfaces/forms/DisableVetForm/disable-vet-form-third-section";

interface IFormFields {
  formDataFieldsFirstSection?: IDisabledVetFirstSection;
  formDataFieldsSecondSection?: IDisabledVetSecondSection;
  formDataFieldsThirdSection?: IDisabledVetThirdSection;
  formDataFieldsFourthSection?: IDisabledVetFourthSection;
  formDataFieldsFifthSection?: IDisabledVetFifthSection;
  formDataFieldsSixthSection?: IDisabledVetSixthSection;
  formDataFieldsSeventhSection?: IDisabledVetSeventhSection;
  formDataFieldsEighthSection?: IDisabledVetEighthSection;
  getPdfBase64Url?: (url: string) => void;
}

export default function DisabledVetFormPDFView(props: IFormFields): JSX.Element {
  const {
    formDataFieldsFirstSection,
    formDataFieldsSecondSection,
    formDataFieldsThirdSection,
    formDataFieldsFourthSection,
    formDataFieldsFifthSection,
    formDataFieldsSixthSection,
    formDataFieldsSeventhSection,
    formDataFieldsEighthSection,
    getPdfBase64Url,
  } = props;

  const templateRef = useRef(null);
  const [templateBase64, setTemplateBase64] = useState("");
  const settings = settingsService().settings;

  useEffect(() => {
    drawDOM(templateRef.current as any, { paperSize: "A4" })
      .then((group) => {
        if (templateRef.current) {
          const myReference = templateRef.current as any;
          myReference.style.display = "none";
        }
        return exportPDF(group);
      })
      .then((dataUri) => {
        setTemplateBase64(dataUri);
        if (getPdfBase64Url) {
          getPdfBase64Url(dataUri);
        }
      });
  }, []);

  return (
    <>
      {templateRef && templateBase64 && (
        <iframe
          style={{ width: "100%", height: "100%" }}
          src={templateBase64}
        />
      )}
      <div ref={templateRef} className={`${shared.FormContainer} ${shared.Form}`}>
        <div className={shared.PrintTogether}>
          <div className={`${shared.Row} ${shared.AlignCenter}`}>
            <div className={`${shared.Column8} ${shared.H3}`}>
              Disabled Veteran&apos;s or Survivor&apos;s Exemption Application
            </div>
            <div className={`${shared.Column3RightPadding} ${shared.TextCenter} ${shared.FormVersion}`}>Form 50-135</div>
          </div>

          <div className={`${shared.Row} ${shared.SpaceBetween}`}>
            <div className={shared.Column8}>
              <div className={shared.Value}>
                {settings?.clientDisplayName}
              </div>
              <div className={shared.ValueLabelBelow}>Appraisal District&apos;s Name</div>
            </div>

            <div className={shared.Column3}>
              <div className={shared.Value}>
                {formDataFieldsFourthSection?.PropertyId}
              </div>
              <div className={shared.ValueLabelBelow}>
                Appraisal District Account Number (if known)
              </div>
            </div>
          </div>
          
          <div className={shared.Row}>
            <div className={`${shared.Column5} ${shared.TextSmall}`}>
              Are you filing a late application?
            </div>
            <div className={`${shared.ColumnCheckbox} ${shared.TextRight}`}>
              <i
              className={
                  "fa " +
                  (formDataFieldsSecondSection?.IsLateApplication == true
                  ? "fa-check-square-o"
                  : "fa-square-o")
              }
              ></i>{" "}
              &nbsp; Yes &nbsp; &nbsp;
              <i
              className={
                  "fa " +
                  (!formDataFieldsSecondSection?.IsLateApplication == false
                  ? "fa-check-square-o"
                  : "fa-square-o")
              }
              ></i>{" "}
              &nbsp; No
            </div>
            <div className={shared.Column6}>
              <div className={shared.Value}>
                {formDataFieldsSecondSection?.TaxYears?.join(", ")}
              </div>
              <div className={shared.ValueLabelBelow}>
                Tax Year(s)
              </div>
            </div>
          </div>

          <p>
            <b>GENERAL INFORMATION:&nbsp;</b> Property owners applying for a disabled veteran&apos;s 
            or survivor&apos;s exemption file this form and supporting documentation with the appraisal 
            district in each county in which the property is located (Tax Code Sections 11.22 and 11.43 ).
            <br/> 
            <b> Do not file this document with the Texas Comptroller of Public Accounts.</b>
          </p>
          
          <div className={shared.Step}>SECTION 1: Property Owner/Applicant</div>

          <div className={shared.Row}>
            <div className="column-65-no-padding text-small">
              Did the applicant own the property that is the subject of 
              this application on Jan. 1 of the tax year?
            </div>
            <div className={`${shared.Column3} ${shared.TextRight}`}>
              <i
              className={
                  "fa " +
                  (formDataFieldsSecondSection?.ApplicantOwnedPropertyJan1 == true
                  ? "fa-check-square-o"
                  : "fa-square-o")
              }
              ></i>{" "}
              &nbsp; Yes &nbsp;
              <i
              className={
                  "fa " +
                  (!formDataFieldsSecondSection?.ApplicantOwnedPropertyJan1 == false
                  ? "fa-check-square-o"
                  : "fa-square-o")
              }
              ></i>{" "}
              &nbsp; No
            </div>
          </div>
            
          <div className={shared.Row}>
            <div className={shared.Column6}>
              <div className={shared.Value}>
                {formDataFieldsSecondSection?.PropertyOwnerName}
              </div>
              <div className={shared.ValueLabelBelow}>
                Name of Property Owner
              </div>
            </div>
            <div className={shared.Column6}>
              <div className={shared.Value}>
                {formDataFieldsSecondSection?.PropertyOwnerIdNumber}
              </div>
              <div className={shared.ValueLabelBelow}>
                Driver&apos;s Licence, Personal I.D. Certificate or Social Security Number*
              </div>
            </div>
            <div className={shared.Column12}>
              <div className={shared.Value}>
                  {formDataFieldsSecondSection?.PropertyOwnerPhysicalAddressLine1}{" "}
                  &nbsp;
                  {formDataFieldsSecondSection?.PropertyOwnerPhysicalAddressLine2 && (
                      <span>
                      {formDataFieldsSecondSection?.PropertyOwnerPhysicalAddressLine2}{" "}
                      &nbsp;
                      </span>
                  )}
                  {formDataFieldsSecondSection?.PropertyOwnerPhysicalAddressCity}{" "}
                  &nbsp;
                  {formDataFieldsSecondSection?.PropertyOwnerPhysicalAddressState && `${formDataFieldsSecondSection?.PropertyOwnerPhysicalAddressState},`}
                  &nbsp;
                  {formDataFieldsSecondSection?.PropertyOwnerPhysicalAddressZip} &nbsp;
              </div>
              <div className={shared.ValueLabelBelow}>
                  Physical Address, City, State, ZIP Code
              </div>
            </div>
            <div className={shared.Column6}>
              <div className={shared.Value}>
                  {formDataFieldsSecondSection?.PropertyOwnerPhoneNumber}
              </div>
              <div className={shared.ValueLabelBelow}>
                  Primary Phone Number (area code and number)
              </div>
            </div>
            <div className={shared.Column6}>
              <div className={shared.Value}>
              {formDataFieldsSecondSection?.PropertyOwnerEmailAddress}
              </div>
              <div className={shared.ValueLabelBelow}>Email Address**</div>
            </div>
            <div className={shared.Column12}>
              <p>
                Mailing Address, City, State, ZIP Code (if different from the
                physical address provided above):
              </p>
            </div>
            <div className={shared.Column12}>
              <div className={shared.Value}>
                {formDataFieldsSecondSection?.PropertyOwnerMailingAddressLine1}{" "}
                &nbsp;
                {formDataFieldsSecondSection?.PropertyOwnerMailingAddressLine2 && (
                    <span>
                    {formDataFieldsSecondSection?.PropertyOwnerMailingAddressLine2}{" "}
                    &nbsp;
                    </span>
                )}
                {formDataFieldsSecondSection?.PropertyOwnerMailingAddressCity}{" "}
                &nbsp;
                {formDataFieldsSecondSection?.PropertyOwnerMailingAddressState && `${formDataFieldsSecondSection?.PropertyOwnerMailingAddressState},`}
                &nbsp;
                {formDataFieldsSecondSection?.PropertyOwnerMailingAddressZip} &nbsp;
              </div>
              <div className={shared.ValueLabelBelow}>
                Mailing Address, City, State, ZIP Code
              </div>
            </div>
          </div>

          <div className={shared.Row}>
            <div className={`${shared.Column9} ${shared.TextSmall}`}>
              Are you a Texas resident?
            </div>
            <div className={`${shared.Column2} ${shared.TextRight}`}>
              <i
              className={
                  "fa " +
                  (formDataFieldsSecondSection?.IsTexasResident == true
                  ? "fa-check-square-o"
                  : "fa-square-o")
              }
              ></i>{" "}
              &nbsp; Yes &nbsp; &nbsp;
              <i
              className={
                  "fa " +
                  (!formDataFieldsSecondSection?.IsTexasResident == false
                  ? "fa-check-square-o"
                  : "fa-square-o")
              }
              ></i>{" "}
              &nbsp; No
            </div>
          </div>
          
          <div className={shared.Step}>SECTION 2: Authorized Representative</div>

          <p>
            <b>If you are an individual property owner filing this application on your own behalf, 
            skip to section 3; all other applicants are required to complete section 2.</b>
          </p>
          <p>
            Indicate the basis for your authority to represent the property owner in filing this application. 
          </p>
          
          <div className={shared.Row}>
            <span className={shared.Column12}>
              <i
                className={
                  "fa " +
                  (formDataFieldsThirdSection?.AuthorizedRepresentationBasis === "Attorney"
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
              ></i>
              Attorney for property owner
            </span>
          </div>
          <div className={shared.Row}>
            <span className={shared.Column12}>
              <i
                className={
                  "fa " +
                  (formDataFieldsThirdSection?.AuthorizedRepresentationBasis === "Agent"
                    ? "fa-check-square-o"
                    : "fa-square-o")
                }
              ></i>
              Agent for tax matters appointed under Tax Code Section 1.111 with completed and signed Form 50-162
            </span>
          </div>
          <div className={shared.Row}>
            <span className={shared.Column3}>
                {" "}
                <span>
                  <i
                      className={
                      " fa " +
                      (formDataFieldsThirdSection?.AuthorizedRepresentationBasis === "Other"
                          ? "fa-check-square-o"
                          : "fa-square-o")
                      }
                  ></i>
                  Other and explain basis:
                </span>
            </span>
            <span className={`${shared.Column7} ${shared.Value}`}>
              {formDataFieldsThirdSection?.AuthorizedRepresentationBasisOther}
            </span>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              <div className={shared.Value}>
                {formDataFieldsThirdSection?.RepresentativeName}
              </div>
              <div className={shared.ValueLabelBelow}>
                Name of Authorized Representative
              </div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column4}>
              <div className={shared.Value}>
                {formDataFieldsThirdSection?.RepresentativeTitle}
              </div>
              <div className={shared.ValueLabelBelow}>
                Title of Authorized Representative
              </div>
            </div>
            <div className={shared.Column4SmallPadding}>
              <div className={shared.Value}>
                {formDataFieldsThirdSection?.RepresentativePhoneNumber}
              </div>
              <div className={shared.ValueLabelBelow}>
                Primary Phone Number <i>(area code and number)</i>
              </div>
            </div>
            <div className={shared.Column4}>
              <div className={shared.Value}>
                {formDataFieldsThirdSection?.RepresentativeEmailAddress}
              </div>
              <div className={shared.ValueLabelBelow}>
                Email Address**
              </div>
            </div>
          </div>
          <div className={shared.Row}>
            <div className={shared.Column12}>
              <div className={shared.Value}>
                {formDataFieldsThirdSection?.RepresentativeMailingAddressLine1}{" "}
                &nbsp;
                {formDataFieldsThirdSection?.RepresentativeMailingAddressLine2 && (
                    <span>
                    {formDataFieldsThirdSection?.RepresentativeMailingAddressLine2}{" "}
                    &nbsp;
                    </span>
                )}
                {formDataFieldsThirdSection?.RepresentativeMailingAddressCity}{" "}
                &nbsp;
                {formDataFieldsThirdSection?.RepresentativeMailingAddressState && `${formDataFieldsThirdSection?.RepresentativeMailingAddressState},`}
                &nbsp;
                {formDataFieldsThirdSection?.RepresentativeMailingAddressZip} &nbsp;
              </div>
              <div className={shared.ValueLabelBelow}>
                Mailing Address, City, State, ZIP Code
              </div>
            </div>
          </div>
          
          <div className={shared.Step}>SECTION 3: Property Descriptions</div>
          
          <div className={shared.Row}>
            <div className={shared.Column12}>
              <div className={shared.Value}>
                {formDataFieldsFourthSection?.PropertyAddressLine1}{" "}
                &nbsp;
                {formDataFieldsFourthSection?.PropertyAddressLine2 && (
                  <span>
                  {formDataFieldsFourthSection?.PropertyAddressLine2}{" "}
                  &nbsp;
                  </span>
                )}
                {formDataFieldsFourthSection?.PropertyAddressCity}{" "}
                &nbsp;
                {formDataFieldsFourthSection?.PropertyAddressState && `${formDataFieldsFourthSection?.PropertyAddressState},`}
                &nbsp;
                {formDataFieldsFourthSection?.PropertyAddressZip} &nbsp;
              </div>
              <div className={shared.ValueLabelBelow}>
                Physical Address <i>(i.e. street address, not P.O. Box)</i>, City, State, Zip Code
              </div>
            </div>
          </div>
          
          <div className={shared.Row}>
            <div className={shared.Column5}>
              <div className={shared.Value}>
                {formDataFieldsFourthSection?.PropertyId}
              </div>
              <div className={shared.ValueLabelBelow}>
                Appraisal District Account Number <i>(if known)</i> 
              </div>
            </div>
            <div className={shared.Column6}>
              <div className={shared.Value}>
                {formDataFieldsFourthSection?.ManufacturedMake}{" "}
                {formDataFieldsFourthSection?.ManufacturedModel}{" "}
                {formDataFieldsFourthSection?.ManufacturedIdNumber}
              </div>
              <div className={shared.ValueLabelBelow}>
                Manufactured Home <i>(make, model, and identification number)</i>
              </div>
            </div>
          </div>
          
          <p>
            Legal Description:
          </p>
          <div className={shared.Row}>
            <div className={shared.Column11}>
              <div className={shared.TextArea}>
                {formDataFieldsFourthSection?.PropertyLegalDescription}
              </div>
            </div>
          </div>

        </div>
        <div className={shared.PageBreak}></div>
        <div className={shared.Step}>SECTION 4: Type of Exemption and Qualifcations</div>

        <p>
          Select the exemption for which you are applying.
        </p>
        <span className={shared.Column12}>
          <i
            className={
              "fa " +
              (formDataFieldsFifthSection?.ExemptionType === "Veteran"
                ? "fa-check-square-o"
                : "fa-square-o")
            }
          ></i>
          <b>Veteran classified as disabled by the Veteran&apos;s Administration (VA) or a service branch of the U.S. armed forces</b>
        </span>
        <span className={shared.Column12}>
          <i
            className={
              "fa " +
              (formDataFieldsThirdSection?.AuthorizedRepresentationBasis === "SpouseOrChildOfDeceasedDisabledVeteran"
                ? "fa-check-square-o"
                : "fa-square-o")
            }
          ></i>
          <b>Surviving spouse or child of a deceased disabled veteran</b>
        </span>
        <span className={shared.Column12}>
          <i
            className={
              "fa " +
              (formDataFieldsThirdSection?.AuthorizedRepresentationBasis === "SpouseOrChildOfUSArmedServiceMember"
                ? "fa-check-square-o"
                : "fa-square-o")
            }
          ></i>
          <b>Surviving spouse or child of U.S. armed service member who died on active duty</b>
        </span>
        <div className={shared.Row}>
          <div className={shared.Column6}>
            <div className={shared.Value}>
              {formDataFieldsFifthSection?.VeteransName}
            </div>
            <div className={shared.ValueLabelBelow}>
              Veteran&apos;s Name
            </div>
          </div>
        </div>
        <div className={shared.Row}>
          <div className={shared.Column4}>
            <div className={shared.Value}>
              {formDataFieldsFifthSection?.VeteransBranchOfServices}
            </div>
            <div className={shared.ValueLabelBelow}>
              Branch of Service
            </div>
          </div>
          <div className={shared.Column3SmallPadding}>
            <div className={shared.Value}>
              {formDataFieldsFifthSection?.VeteransDisabilityRating}
            </div>
            <div className={shared.ValueLabelBelow}>
              Disability Rating
            </div>
          </div>
          <div className={shared.Column2SmallPadding}>
            <div className={shared.Value}>
              {formDataFieldsFifthSection?.VeteransAge}
            </div>
            <div className={shared.ValueLabelBelow}>
              Age
            </div>
          </div>
          <div className={shared.Column3}>
            <div className={shared.Value}>
              {formDataFieldsFifthSection?.VeteransSerialNumber}
            </div>
            <div className={shared.ValueLabelBelow}>
              Serial Number
            </div>
          </div>
        </div>
        <div className={shared.Row}>
          <div className={`${shared.Column4} ${shared.TextSmall}`}>
            Does the service-connected disability include:
          </div>
          <div className={`${shared.Column4} ${shared.TextRight}`}>
            <i
            className={
                "fa " +
                (formDataFieldsFifthSection?.DisabilityType === "LossOfLimb"
                ? "fa-check-square-o"
                : "fa-square-o")
            }
            ></i>{" "}
            &nbsp; Loss of use of one or more limbs &nbsp; &nbsp;
            <i
            className={
                "fa " +
                (formDataFieldsFifthSection?.DisabilityType === "Blindness"
                ? "fa-check-square-o"
                : "fa-square-o")
            }
            ></i>{" "}
            &nbsp; Blindness in one or both eyes
          </div>
        </div>
        <div className={shared.Row}>
          <div className={`${shared.Column9} ${shared.TextSmall}`}>
            Are you the surviving spouse of a disabled veteran?
          </div>
          <div className={`${shared.Column2} ${shared.TextRight}`}>
            <i
            className={
                "fa " +
                (formDataFieldsFifthSection?.SurvivingSpouseOfVeteran == true
                ? "fa-check-square-o"
                : "fa-square-o")
            }
            ></i>{" "}
            &nbsp; Yes &nbsp; &nbsp;
            <i
            className={
                "fa " +
                (!formDataFieldsFifthSection?.SurvivingSpouseOfVeteran == false
                ? "fa-check-square-o"
                : "fa-square-o")
            }
            ></i>{" "}
            &nbsp; No
          </div>
        </div>
        <div className={shared.Row}>
          <div className={`${shared.Column9} ${shared.TextSmall}`}>
            If yes, have you remarried?
          </div>
          <div className={`${shared.Column2} ${shared.TextRight}`}>
            <i
            className={
                "fa " +
                (formDataFieldsFifthSection?.SurvivingSpouseRemarried == true
                ? "fa-check-square-o"
                : "fa-square-o")
            }
            ></i>{" "}
            &nbsp; Yes &nbsp; &nbsp;
            <i
            className={
                "fa " +
                (!formDataFieldsFifthSection?.SurvivingSpouseRemarried == false
                ? "fa-check-square-o"
                : "fa-square-o")
            }
            ></i>{" "}
            &nbsp; No
          </div>
        </div>
        <div className={shared.Row}>
          <div className={`${shared.Column9} ${shared.TextSmall}`}>
            Are you the surviving spouse of a U.S. armed service member who died on active duty? 
          </div>
          <div className={`${shared.Column2} ${shared.TextRight}`}>
            <i
            className={
                "fa " +
                (formDataFieldsFifthSection?.SurvivingSpouseOfUSServiceMember == true
                ? "fa-check-square-o"
                : "fa-square-o")
            }
            ></i>{" "}
            &nbsp; Yes &nbsp; &nbsp;
            <i
            className={
                "fa " +
                (!formDataFieldsFifthSection?.SurvivingSpouseOfUSServiceMember == false
                ? "fa-check-square-o"
                : "fa-square-o")
            }
            ></i>{" "}
            &nbsp; No
          </div>
        </div>
        <div className={shared.Row}>
          <div className={`${shared.Column9} ${shared.TextSmall}`}>
            Are you a surviving child of a deceased disabled veteran or U.S. armed 
            service member who died on active duty? 
          </div>
          <div className={`${shared.Column2} ${shared.TextRight}`}>
            <i
            className={
                "fa " +
                (formDataFieldsFifthSection?.SurvivingChild == true
                ? "fa-check-square-o"
                : "fa-square-o")
            }
            ></i>{" "}
            &nbsp; Yes &nbsp; &nbsp;
            <i
            className={
                "fa " +
                (!formDataFieldsFifthSection?.SurvivingChild == false
                ? "fa-check-square-o"
                : "fa-square-o")
            }
            ></i>{" "}
            &nbsp; No
          </div>
        </div>
        <div className={shared.Row}>
          <div className={`${shared.Column2} ${shared.TextSmall}`}>
            If yes:
          </div>
          <div className={`${shared.Column6} ${shared.TextSmall}`}>
            Did the spouse survive the veteran or the U.S. armed service member? 
          </div>
          <div className={`${shared.Column2} ${shared.TextRight}`}>
            <i
            className={
                "fa " +
                (formDataFieldsFifthSection?.SpouseSurviveVeteran == true
                ? "fa-check-square-o"
                : "fa-square-o")
            }
            ></i>{" "}
            &nbsp; Yes &nbsp; &nbsp;
            <i
            className={
                "fa " +
                (!formDataFieldsFifthSection?.SpouseSurviveVeteran == false
                ? "fa-check-square-o"
                : "fa-square-o")
            }
            ></i>{" "}
            &nbsp; No
          </div>
        </div>
        <div className={shared.Row}>
          <div className={`${shared.Column2} ${shared.TextSmall}`}>
            If yes, are you:
          </div>
          <div className={`${shared.Column6} ${shared.TextSmall}`}>
            Under 18 years of age?
          </div>
          <div className={`${shared.Column2} ${shared.TextRight}`}>
            <i
            className={
                "fa " +
                (formDataFieldsFifthSection?.ChildUnder18 == true
                ? "fa-check-square-o"
                : "fa-square-o")
            }
            ></i>{" "}
            &nbsp; Yes &nbsp; &nbsp;
            <i
            className={
                "fa " +
                (!formDataFieldsFifthSection?.ChildUnder18 == false
                ? "fa-check-square-o"
                : "fa-square-o")
            }
            ></i>{" "}
            &nbsp; No
          </div>
        </div>
        <div className={shared.Row}>
          <div className={`${shared.Column2} ${shared.TextSmall}`}>
          </div>
          <div className={`${shared.Column6} ${shared.TextSmall}`}>
            Unmarried?
          </div>
          <div className={`${shared.Column2} ${shared.TextRight}`}>
            <i
            className={
                "fa " +
                (formDataFieldsFifthSection?.ChildUnmarried == true
                ? "fa-check-square-o"
                : "fa-square-o")
            }
            ></i>{" "}
            &nbsp; Yes &nbsp; &nbsp;
            <i
            className={
                "fa " +
                (!formDataFieldsFifthSection?.ChildUnmarried == false
                ? "fa-check-square-o"
                : "fa-square-o")
            }
            ></i>{" "}
            &nbsp; No
          </div>
        </div>
        <div className={shared.Row}>
          <div className={`${shared.Column2} ${shared.TextSmall}`}>
          </div>
          <div className={`${shared.Column6} ${shared.TextSmall}`}>
            Number of qualifying parent&apos;s children who are under 18 and unmarried
          </div>
          <div className={`${shared.Column2} ${shared.TextRight}`}>
            <div className={shared.Value}>
              {formDataFieldsFifthSection?.NumberOfChildren}
            </div>
          </div>
        </div>
        
        <div className={shared.Step}>SECTION 5: Additional Documents to be Provided</div>

        <p>
          Attach documentation from the VA or service branch identifying the most 
          recent disability rating. 
        </p>

        <div className={shared.Step}>SECTION 6: Affirmation and Signature</div>

        <p>
          <b>
            I understand if I make a false statement on this form, I could be found guilty 
            of a Class A misdemeanor or a state jail felony under Penal Code Section 37.10.
          </b>
        </p>

        <br />
        <div className={`${shared.DisplayFlex} ${shared.TextCenter}`}>
          I,
          <div className={shared.Width40}>
            <div className={shared.Value}>
              {" "} {formDataFieldsEighthSection?.Signature}
            </div>
            <div className={`${shared.ValueLabelBelow} ${shared.TextCenter}`}>
              Property Owner, Authorized Representative Name
            </div>
          </div>
          , &nbsp;
          <div className={shared.Width30}>
            <div className={shared.Value}>
              {" "} {formDataFieldsEighthSection?.Title}
            </div>
            <div className={`${shared.ValueLabelBelow} ${shared.TextCenter}`}>
              Title/Authorization
            </div>
          </div>
          , swear or affirm the following:
        </div>
        <div className={shared.TextSmall}>
            <span className="mr-2">1.</span> that each fact contained in this application is true and correct;
        </div>
        <div className={shared.TextSmall}>
          <span className="mr-2">2.</span> that the property described in this application meets the
          qualifications under Texas law for the special appraisal claimed;
        </div>
        <div className={shared.TextSmall}>
          <span className="mr-2">3.</span> that I have read and understand the Notice Regarding Penalties
          for Making or Filing an Application Containing a False Statement;
        </div>
        <br />

        <div className={`${shared.Row} ${shared.SpaceBetween}`}>
          <div className="col-md-8">
            <div className={`${shared.Value} ${shared.TextCenter}`}>
              {formDataFieldsEighthSection?.Signature}
            </div>
            <div className={`${shared.ValueLabelBelow} ${shared.TextCenter}`}>
              Signature of Property Owner or Authorized Representative
            </div>
          </div>
          <div className="col-md-3">
            <div className={`${shared.Value} ${shared.TextCenter}`}>
              {formDataFieldsEighthSection?.SignatureDate
                ? new Date(formDataFieldsEighthSection?.SignatureDate).toDateString()
                : ""}
            </div>
            <div className={`${shared.ValueLabelBelow} ${shared.TextCenter}`}>Date</div>
          </div>
        </div>

        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>

        <p className={shared.TextSmall}>
          * Social security number (SSN) disclosure may be required for tax administration and identification. 
          (42 U.S.C. §405(c)(2)(C)(i); Tax Code §11.43(f )). A driver&apos;s license number, personal identification 
          number or social security number disclosed in an exemption application is confidential and not open to 
          public inspection, except as authorized by Tax Code §11.48(b). 
        </p>

        <p className={shared.TextSmall}>
          ** May be confidential under Government Code §552.137; however, by including the email address on this form, 
          you are affirmatively consenting to its release under the Public Information Act.
        </p>

        <div className={shared.PageBreak}></div>

        <div className={`${shared.Row} ${shared.AlignCenter}`}>
          <div className={`${shared.Column12} ${shared.H3} ${shared.TextCenter}`}>
            Important Information
          </div>
        </div>

        <div className={shared.Row}>
          <div className={`${classes.Column} ${shared.Column6}`}>
            <p>
              <b>
                GENERAL INFORMATION 
              </b>
              <br/>
              This application is for use in claiming a property tax exemption pursuant to Tax Code
              Section 11.22 for property owned by a disabled veteran, the surviving spouse or
              surviving child, with a service-connected disability, or a U.S. armed service member
              who died on active duty
            </p>
            <p>
              <b>
                FILING INSTRUCTIONS 
              </b>
              <br/>
              Property owners applying for a disabled veteran&apos;s or survivor&apos;s exemption may file this
              form and supporting documentation with the appraisal district in the county in which
              the property is located. <b>Do <u>not</u> file this document with the Texas Comptroller
              of Public Accounts.</b> Contact information for appraisal district offices may be found
              on the Comptroller&apos;s website.
            </p>
            <p>
              <b>
                APPLICATION DEADLINES (Tax Code Section 11.43(a-d)):
              </b>
              <br/>
              You must submit the completed application and supporting documents to the
              appraisal district between Jan. 1 and April 30 of the tax year for which you request the
              exemption. For good cause shown, the chief appraiser may extend the deadline for
              filing the application by written order for a single period not to exceed 60 days. The
              chief appraiser may disapprove the application and request additional information. If
              the chief appraiser requests additional information, the information must be furnished
              within 30 days after the date of the request, or the application is denied. For good cause
              shown, the chief appraiser may extend the deadline for furnishing the information by
              written order for a single period not to exceed 15 days. If the chief appraiser denies the
              application, you may protest that determination to the county appraisal review board
              under Tax Code chapter 41.
            </p>
            <p>
              <b>
                LATE APPLICATIONS (Tax Code Section 11.439):
              </b>
              <br/>
              Applicants may file a late application no later than five years after the delinquency date
              for the taxes on the designated property. 
            </p>
            <p>
              <b>
                REQUIRED DOCUMENTATION 
              </b>
              <br/>
              Applicants must provide the following documentation supporting this exemption request:
              <ul>
                <li>
                  attach a copy of property owner&apos;s driver&apos;s license or state-issued personal
                  identification certificate 
                </li>
                <li>
                  documentation indicating the percentage of disability certified by the Veterans
                  Administration or its successor or the branch of the armed services in which the
                  veteran served 
                </li>
              </ul>
            </p>
            <p>
              <b>
                ADDITIONAL INFORMATION REQUEST
              </b>
              <br/>
              Once this exemption is allowed, an application does not have to be filed annually
              unless the ownership of the property changes or qualifications for the exemption
              change. The chief appraiser may require a new application to be filed to confirm
              current eligibility by sending written notice and an application form. 
            </p>
          </div>
          <div className={`${classes.Column} ${shared.Column6}`}>
            <p>
              <b>
                DUTY TO NOTIFY 
              </b>
              <br/>
              If eligibility ends, the property owner must notify the appraisal district in writing
              before May 1 after the eligibility ends. 
            </p>
            <p>
              <b>
                EXEMPTION QUALIFICATIONS (Tax Code Section 11.22): 
              </b>
              <br/>
              <b>Disabled Veteran&apos;s Exemption</b> &nbsp;
              A qualified individual is entitled to an exemption
              from taxation of a portion of the assessed value of one property the applicant owns
              and designates. This application applies to property owned on Jan. 1 of this year.
            </p>
            <p>
              To qualify, an individual must be a Texas resident and possess a veteran&apos;s percentage of
              disability certified by the Veterans&apos; Administration (VA) or its successor or the branch
              of the armed services in which the veteran served. 
            </p>
            <p>
              A qualified applicant can receive this exemption on only one property, which must be
              the same for every taxing unit in which the applicant claims the exemption.
            </p>
            <p>
              A disability rating from 10-29 percent can have and exemption up to $5,000 from the
              property&apos;s assessed value, 30-49 percent up to $7,500, 50-69 percent up to $10,000 and
              70-100 percent up to $12,000.
            </p>
            <table className={shared.Blue}>
              <tr>
                <th className={shared.Width50}>Disability Rating</th>
                <th className={shared.Width50}>Exemption Amount Up To</th>
              </tr>
              <tr>
                <td>10% - 29%</td>
                <td>$5,000 from the property value</td>
              </tr>
              <tr>
                <td>30% - 49%</td>
                <td>$7,500 from the property value</td>
              </tr>
              <tr>
                <td>50% - 69%</td>
                <td>$10,000 from the property value</td>
              </tr>
              <tr>
                <td>70% - 100%</td>
                <td>$12,000 from the property value</td>
              </tr>
            </table>
            <p>
              A disabled veteran may also qualify for an exemption of $12,000 of the assessed value
              of the property if the veteran is age 65 or older with a disability rating of at least 10
              percent; totally blind in one or both eyes; or has lost the use of one or more limbs.
            </p>
            <p>
              <b>Survivor&apos;s Exemption</b> &nbsp;
              (Tax Code Section 11.22(c)(d)): The veteran&apos;s surviving
              spouse is entitled to an exemption from taxation of a portion of the assessed value
              of a property the spouse owns and designates for the exemption. The amount of the
              exemption is the amount of the veteran&apos;s exemption at the time of death. The spouse is
              entitled to this exemption only for as long as the spouse remains unmarried.
            </p>
            <p>
              If the spouse does not survive the veteran, each of the veteran&apos;s surviving children
              who is younger than 18 years of age and unmarried is entitled to an exemption from
              taxation of a portion of the assessed value of a property the child owns and designates
              for the exemption. 
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
