import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import React, {useEffect, useRef, useState} from "react";
import {
    Checkbox,
    CheckboxChangeEvent, Input,
    InputChangeEvent, NumericTextBox, NumericTextBoxChangeEvent, RadioGroup,
    RadioGroupChangeEvent, TextAreaChangeEvent
} from "@progress/kendo-react-inputs";
import {CalendarChangeEvent, DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {ComboBoxChangeEvent, DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {Error, Label} from "@progress/kendo-react-labels";
import classes from "./HomesteadExemption.module.scss";
import {
    IHomesteadExemptionThirdSectionInterface, IPropertyOwners
} from "../../../../../interfaces/forms/HomesteadExemptionForm/homestead-exemption-third-section.interface";
import {UploadFileInfo, UploadFileStatus} from "@progress/kendo-react-upload";
import {SelectedFormType} from "../../../../../context/SelectedForm/selected-form-reducer";
import {GenericActionType} from "../../../../../context/generic-actions.type";
import {IFormEntryModel} from "../../../../../interfaces/form-entry.interface";
import {
    ClearFileArray
} from "../../../../../context/SelectedForm/selected-form-context.helper";
import {IFilePreviewInterface} from "../../../../../interfaces/file-preview.interface";
import PropertyOwnerIdNumberImage from  "../../../../../assets/images/identification-card.png";
import {CustomUpload} from "../CustomInputs/CustomUpload";
import {CheckboxGroup} from "../CustomInputs/CheckBoxes/CheckboxGroup";
import {
    getSupportingDocumentByAccessCodeAndDocumentId,
} from "../../../../../api/form-entries-api";
import {AxiosError, AxiosResponse} from "axios";
import {temporaryError} from "../../../../../context/shared/shared-context.helper";
import {CustomDatePicker} from "../CustomInputs/CustomDatePickers/CustomDatePicker";
import {CustomRowDatePicker} from "../CustomInputs/CustomDatePickers/CustomRowDatePicker";
import {FormValidation} from "../InputValidator";
import {RowPhoneNumberInput} from "../CustomInputs/PhoneNumberInputs/RowPhoneNumberInput";
import {RowEmailInput} from "../CustomInputs/EmailInputs/RowEmailInput";
import {PhoneNumberInput} from "../CustomInputs/PhoneNumberInputs/PhoneNumberInput";
import {EmailInput} from "../CustomInputs/EmailInputs/EmailInput";
import {StatesInput} from "../CustomInputs/StatesInput";
import {ZipCodeInput} from "../CustomInputs/ZipCodeInput";
import {IRowState} from "../CustomInputs/UpdateRowErrorStateHandler";
import { PercentageFormatOptions } from "src/pages/Shared/NumberFormatHelper/NumberFormatHelper";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IHomesteadExemptionThirdSectionInterface) => void;
    formDataFields?:  IHomesteadExemptionThirdSectionInterface,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void,
    formEntry?: IFormEntryModel,
    selectedFormDispatch: (value: GenericActionType<SelectedFormType>) => void,
}

export default function HomesteadExemptionThirdSection(props: GeneralFormType): JSX.Element {
    const {setFormDataFields, formDataFields, formSettings, isAdminPreview, setFormIsValid, formEntry, selectedFormDispatch} = props;
    const MarriedCouplePropertyOwnerType = "MarriedCouple";
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);

    const [isPropertyOwnerSpousePhotoIdUploadValid, setIsPropertyOwnerSpousePhotoIdUploadValid] = useState<boolean>(true);
    const [isUtilityBillUploadValid, setIsUtilityBillUploadValid] = useState<boolean>(true);
    const [isPropertyOwnerPhotoIdUploadValid, setIsPropertyOwnerPhotoIdUploadValid] = useState<boolean>(true);
    const [IsHasMultipleOwnersCheckBoxGroupValid, setIsHasMultipleOwnersCheckBoxGroupValid] = useState<boolean>(true);

    const [UtilityBillFiles, setUtilityBillFiles] = useState<Array<UploadFileInfo>>([]);
    const [PropertyOwnerPhotoIdFiles, setPropertyOwnerPhotoIdFilesFiles] = useState<Array<UploadFileInfo>>([]);
    const [PropertyOwnerSpousePhotoIdFiles, setPropertyOwnerSpousePhotoIdFilesFiles] = useState<Array<UploadFileInfo>>([]);
    const [OwnerPhotoIdFilesPreviews, setOwnerPhotoIdFilesPreviews] = useState<IFilePreviewInterface>({});
    const [OwnerSpousePhotoIdFilesPreviews, setOwnerSpousePhotoIdFilesPreviews] = useState<IFilePreviewInterface>({});

    const [dateErrorsArray, setDateErrorsArray] = useState<Array<IRowState>>([]);
    const [dateOwnerIsValid, setDateOwnerIsValid] = useState<boolean>(false);
    const [dateSpouseIsValid, setDateSpouseIsValid] = useState<boolean>(formDataFields?.PropertyOwnerType !== MarriedCouplePropertyOwnerType);
    const [phoneNumberErrorsArray, setPhoneNumberErrorsArray] = useState<Array<IRowState>>([]);
    const [emailAddressErrorsArray, setEmailAddressErrorsArray] = useState<Array<IRowState>>([]);
    const [propertyOwnerPhoneNumberInputValid, setPropertyOwnerPhoneNumberInputValid] = useState<boolean>(false);
    const [propertyOwnerEmailInputValid, setPropertyOwnerEmailInputValid] = useState<boolean>(false);
    const [applicantStateInputValid, setApplicantStateInputValid] = useState<boolean>(false);
    const [applicantZipCode, setApplicantZipCode] = useState<boolean>(false);
    const [facilityStateInputValid, setFacilityStateInputValid] = useState<boolean>(!formDataFields?.IsResidentOfAFacility);
    const [facilityZipCode, setFacilityZipCode] = useState<boolean>(!formDataFields?.IsResidentOfAFacility);
    const [propertyOwnerSpousePhoneNumberInputValid, setPropertyOwnerSpousePhoneNumberInputValid] = useState<boolean>(formDataFields?.PropertyOwnerType !== MarriedCouplePropertyOwnerType);
    const [propertyOwnerSpouseEmailInputValid, setPropertyOwnerSpouseEmailInputValid] = useState<boolean>(formDataFields?.PropertyOwnerType !== MarriedCouplePropertyOwnerType);
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator } = FormValidation(formRef);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={3} name={"Applicant Information"}/>;

    const radioButtonData = [
        { label: "Single Adult", value: "SingleAdult" },
        { label: "Married Couple", value: "MarriedCouple" },
        { label: "Other (e.g., individual who owns the property with others)", value: "Other" },
    ];

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent
            | ComboBoxChangeEvent
            | NumericTextBoxChangeEvent,
        field: string,
    ) {

        if (field === "PropertyOwnerSpouseBirthDate" ||
            field === "PropertyOwnerBirthDate"){
            setFormDataFields({
                ...formDataFields,
                [field]: event.value.toDateString()
            });
        } else if (field === "PropertyOwnerType"
            && formDataFields?.PropertyOwnerType === MarriedCouplePropertyOwnerType
            && event.value != MarriedCouplePropertyOwnerType){
            setFormDataFields({
                ...formDataFields,
                [field]: event.value,
                PropertyOwnerSpouseName: undefined,
                PropertyOwnerSpouseBirthDate: undefined,
                PropertyOwnerSpouseOwnershipInterest: undefined,
                PropertyOwnerSpouseEmailAddress: undefined,
                PropertyOwnerSpousePhoneNumber: undefined,
                PropertyOwnerSpouseIdNumber: undefined,
                PropertyOwnerSpousePhotoId: undefined,
            });
            setOwnerSpousePhotoIdFilesPreviews({});
            setPropertyOwnerSpousePhotoIdFilesFiles([]);
            setIsPropertyOwnerSpousePhotoIdUploadValid(true);
            setDateSpouseIsValid(true);
            setPropertyOwnerSpousePhoneNumberInputValid(true);
            setPropertyOwnerSpouseEmailInputValid(true);
        } else if (field === "IsExemptFromProvidingACopyOfId" && event.value === true) {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value,
                PropertyOwnerIdNumber: undefined,
            });
            setOwnerPhotoIdFilesPreviews({});
        } else if (field === "IsExemptFromProvidingACopyOfId" && event.value === false) {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value,
                IsResidentOfAFacility: undefined,
                FacilityName: undefined,
                FacilityAddressLine1: undefined,
                FacilityAddressLine2: undefined,
                FacilityAddressCity: undefined,
                FacilityAddressState: undefined,
                FacilityAddressZip: undefined,
                IsCertifiedForAddressConfidentialityProgram: undefined,
            });
        } else if (field === "IsRequestingChiefAppraiserWaiveAddressRequirement" && event.value === false) {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value,
                IsSection521DriversLicenseHolder: undefined,
            });
            setOwnerPhotoIdFilesPreviews({});
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    useEffect(() => {
        return () => {
            setUtilityBillFiles([]);
            setPropertyOwnerPhotoIdFilesFiles([]);
            setPropertyOwnerSpousePhotoIdFilesFiles([]);
            setOwnerPhotoIdFilesPreviews({});
            setOwnerSpousePhotoIdFilesPreviews({});
        }
    }, []);

    useEffect(() => {
       if (formDataFields?.IsExemptFromProvidingACopyOfId
            && PropertyOwnerPhotoIdFiles.length > 0
            && formDataFields?.PropertyOwnerPhotoId?.length
            && formEntry?.accessCode){
           ClearFileArray<IHomesteadExemptionThirdSectionInterface>(
            formEntry.formId,
               selectedFormDispatch,
               formDataFields?.PropertyOwnerPhotoId,
               formEntry.accessCode,
               "PropertyOwnerPhotoId",
               setFormDataFields,
               formDataFields,
               setPropertyOwnerPhotoIdFilesFiles);
       }
    }, [formDataFields?.IsExemptFromProvidingACopyOfId]);

    useEffect(() => {
        if (!formDataFields?.IsActiveDutyMilitary
            && UtilityBillFiles.length > 0
            && formDataFields?.UtilityBill?.length
            && formEntry?.accessCode){
            ClearFileArray<IHomesteadExemptionThirdSectionInterface>(
                formEntry.formId,
                selectedFormDispatch,
                formDataFields?.UtilityBill,
                formEntry.accessCode,
                "UtilityBill",
                setFormDataFields,
                formDataFields,
                setUtilityBillFiles);
        }
    }, [formDataFields?.IsActiveDutyMilitary]);

    useEffect(() => {
        if (formDataFields?.PropertyOwnerType != MarriedCouplePropertyOwnerType
            && PropertyOwnerSpousePhotoIdFiles.length > 0
            && formDataFields?.PropertyOwnerSpousePhotoId?.length
            && formEntry?.accessCode){
            ClearFileArray<IHomesteadExemptionThirdSectionInterface>(
                formEntry.formId,
                selectedFormDispatch,
                formDataFields?.PropertyOwnerSpousePhotoId,
                formEntry?.accessCode,
                "PropertyOwnerSpouseIdNumber",
                setFormDataFields,
                formDataFields,
                setPropertyOwnerSpousePhotoIdFilesFiles);
        }
    }, [formDataFields?.PropertyOwnerType]);

    useEffect(() => {
        if (!formDataFields?.HasMultipleOwners) {
            setFormDataFields({
                ...formDataFields,
                PropertyOwners: undefined
            });
            setDateErrorsArray([]);
        }
    }, [formDataFields?.HasMultipleOwners]);

    useEffect(() => {
        if (formEntry?.id && formDataFields?.PropertyOwnerPhotoId?.length){
            const propertyOwnerPhotoId = formDataFields?.PropertyOwnerPhotoId?.[0];
            if (propertyOwnerPhotoId){
                getSupportingDocumentByAccessCodeAndDocumentId(formEntry.accessCode, propertyOwnerPhotoId.id)
                .then((response: AxiosResponse) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(response.data);
                    reader.onloadend = function() {
                        const base64data = reader.result;

                        const result: IFilePreviewInterface = {
                            uid: base64data?.toString(),
                            isImage: propertyOwnerPhotoId.extension?.match(/\.(jpg|jpeg|png|gif)$/i) ? true : false,
                        }

                        setOwnerPhotoIdFilesPreviews({
                            ...result
                        });

                        setPropertyOwnerPhotoIdFilesFiles([{
                            uid: propertyOwnerPhotoId.id,
                            name: propertyOwnerPhotoId!.name!,
                            extension: propertyOwnerPhotoId.extension,
                            size: propertyOwnerPhotoId.size,
                            status: UploadFileStatus.Initial,
                            progress: 1
                        }])
                    }
                })
                .catch((error: AxiosError) => {
                    temporaryError(selectedFormDispatch, error.response?.data);
                });
            }
        }
    }, [formEntry, formDataFields]);

    useEffect(() => {
        if (formEntry?.id && formDataFields?.PropertyOwnerSpousePhotoId?.length){
            const propertyOwnerSpousePhotoId = formDataFields?.PropertyOwnerSpousePhotoId?.[0];
            if(propertyOwnerSpousePhotoId){
                getSupportingDocumentByAccessCodeAndDocumentId(formEntry.accessCode, propertyOwnerSpousePhotoId.id)
                    .then((response: AxiosResponse) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(response.data);
                        reader.onloadend = function() {
                            const base64data = reader.result;

                            const result: IFilePreviewInterface = {
                                uid: base64data?.toString(),
                                isImage: propertyOwnerSpousePhotoId.extension?.match(/\.(jpg|jpeg|png|gif)$/i) ? true : false,
                            }

                            setOwnerSpousePhotoIdFilesPreviews({
                                ...result
                            });
                        }
                    })
                    .catch((error: AxiosError) => {
                        temporaryError(selectedFormDispatch, error.response?.data);
                    });
            }
        }
    }, [formEntry, formDataFields]);

    useEffect(() => {
        PropertyOwnerPhotoIdFiles
            .filter((file: UploadFileInfo) => !file.validationErrors)
            .forEach((file: UploadFileInfo) => {
                const reader = new FileReader();

                reader.onloadend = (ev: any) => {

                    const result: IFilePreviewInterface = {
                        uid: ev.target.result,
                        isImage: file.extension?.match(/\.(jpg|jpeg|png|gif)$/i) ? true : false,
                    }

                    setOwnerPhotoIdFilesPreviews({
                       ...result
                    });
                };
                if (file && file.getRawFile) {
                    reader.readAsDataURL(file.getRawFile());
                }
            });
    }, [PropertyOwnerPhotoIdFiles]);

    useEffect(() => {
        PropertyOwnerSpousePhotoIdFiles
            .filter((file: UploadFileInfo) => !file.validationErrors)
            .forEach((file: UploadFileInfo) => {
                const reader = new FileReader();

                reader.onloadend = (ev: any) => {

                    const result: IFilePreviewInterface = {
                        uid: ev.target.result,
                        isImage: file.extension?.match(/\.(jpg|jpeg|png|gif)$/i) ? true : false,
                    }

                    setOwnerSpousePhotoIdFilesPreviews({
                        ...result
                    });
                };
                if (file && file.getRawFile) {
                    reader.readAsDataURL(file.getRawFile());
                }
            });
    }, [PropertyOwnerSpousePhotoIdFiles]);

    useEffect(() => {
        if(formDataFields?.PropertyOwnerPhotoId?.length === 0){
            setOwnerPhotoIdFilesPreviews({});
        }

    }, [formDataFields?.PropertyOwnerPhotoId]);

    useEffect(() => {
        if(formDataFields?.PropertyOwnerSpouseIdNumber?.length === 0){
            setOwnerSpousePhotoIdFilesPreviews({});
        }

    }, [formDataFields?.PropertyOwnerSpouseIdNumber]);

    function changeRowDataHandler( event:
                                       | CheckboxChangeEvent
                                       | DatePickerChangeEvent
                                       | TextAreaChangeEvent
                                       | DropDownListChangeEvent
                                       | InputChangeEvent
                                       | RadioGroupChangeEvent
                                       | CalendarChangeEvent
                                       | ComboBoxChangeEvent
                                       | NumericTextBoxChangeEvent,
                                   field: string,
                                   id: number){
        const newItem: IHomesteadExemptionThirdSectionInterface = formDataFields ? JSON.parse(JSON.stringify(formDataFields)) : [];

        let PropertyOwners = newItem.PropertyOwners?.find(x=> x.Id == id);

        if (field === "BirthDate"){
            PropertyOwners = {...PropertyOwners, [field]: event.value.toDateString(), Id: id};
        } else {
            PropertyOwners = {...PropertyOwners, [field]: event.value, Id: id};
        }

        const index = newItem.PropertyOwners?.findIndex(x=>x.Id == id);

        if(index != undefined && newItem.PropertyOwners) {
            newItem.PropertyOwners[index] = PropertyOwners
        }
        setFormDataFields({
            ...newItem
        })
    }

    function addRowHandler(event:  React.MouseEvent<HTMLElement>){
        event.preventDefault();
        const newItem: IHomesteadExemptionThirdSectionInterface = formDataFields ? JSON.parse(JSON.stringify(formDataFields)) : [];
        if(!newItem.PropertyOwners){
            newItem.PropertyOwners = [];
        }
        newItem.PropertyOwners?.push({ Id: newItem.PropertyOwners?.length + 1 });
        setFormDataFields({
            ...newItem,
        });
    }

    function removeRowHandler(event:  React.MouseEvent<HTMLElement>){
        event.preventDefault();
        const newItem: IHomesteadExemptionThirdSectionInterface = formDataFields ? JSON.parse(JSON.stringify(formDataFields)) : [];
        if(newItem && newItem.PropertyOwners){
            newItem.PropertyOwners.pop();
            setFormDataFields({
                ...newItem,
            });
        }
    }

    function changeValidator() {
        if (formRef.current) {

            const isValid = isPropertyOwnerPhotoIdUploadValid &&
                isUtilityBillUploadValid &&
                IsHasMultipleOwnersCheckBoxGroupValid &&
                dateOwnerIsValid &&
                dateSpouseIsValid &&
                !dateErrorsArray.some(x=>x.state === false) &&
                !phoneNumberErrorsArray.some(x=>x.state === false) &&
                !emailAddressErrorsArray.some(x=>x.state === false) &&
                propertyOwnerPhoneNumberInputValid &&
                propertyOwnerEmailInputValid &&
                applicantStateInputValid &&
                applicantZipCode &&
                facilityStateInputValid &&
                facilityZipCode &&
                propertyOwnerSpousePhoneNumberInputValid &&
                propertyOwnerSpouseEmailInputValid &&
                formRef.current.checkValidity();
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch() {
        if (!isFormTouched) {
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator();
    }, [formDataFields,
        formRef.current,
        formRef.current?.elements,
        isPropertyOwnerPhotoIdUploadValid,
        isPropertyOwnerSpousePhotoIdUploadValid,
        isUtilityBillUploadValid,
        IsHasMultipleOwnersCheckBoxGroupValid,
        dateOwnerIsValid,
        dateSpouseIsValid,
        dateErrorsArray,
        phoneNumberErrorsArray,
        emailAddressErrorsArray,
        propertyOwnerPhoneNumberInputValid,
        propertyOwnerEmailInputValid,
        applicantStateInputValid,
        applicantZipCode,
        facilityStateInputValid,
        facilityZipCode,
        propertyOwnerSpousePhoneNumberInputValid,
        propertyOwnerSpouseEmailInputValid
    ]);

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className={classes.container}>
                        <div className={classes.item}>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroup}>
                                    <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                        <p>Owner Information</p>
                                    </div>
                                    <Label className={classes.Label}>
                                        The applicant is the following type of property owner:
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <RadioGroup
                                            name={"PropertyOwnerType"}
                                            disabled={formSettings?.isReadOnly}
                                            data={radioButtonData}
                                            onChange={e => changeValueHandler(e, "PropertyOwnerType")}
                                            value={formDataFields?.PropertyOwnerType}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroupW100}>
                                    <Label className={classes.Label}>
                                        Property Owner/Applicant Name
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <Input
                                            name={"PropertyOwnerName"}
                                            required={true}
                                            className={classes.kTextbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.PropertyOwnerName}
                                            onChange={(e) => changeValueHandler(e, "PropertyOwnerName",)}
                                        />
                                        {!inputValidator("PropertyOwnerName") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                </div>
                                <div className={classes.fieldsGroupW49}>
                                    <Label className={classes.Label}>
                                        Birth Date
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <CustomDatePicker
                                            fieldName={"PropertyOwnerBirthDate"}
                                            required={true}
                                            disabled={formSettings?.isReadOnly}
                                            formDataFields={formDataFields}
                                            setFormDataFields={setFormDataFields}
                                            onlyYear={false}
                                            setIsValidField={setDateOwnerIsValid}
                                            validField={dateOwnerIsValid}
                                            defaultValueDate={formDataFields?.PropertyOwnerBirthDate}
                                        />
                                    </div>
                                </div>
                                <div className={classes.fieldsGroupW49}>
                                    <Label className={classes.Label}>
                                        Ownership Percentage
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <NumericTextBox
                                            name={"PropertyOwnerOwnershipInterest"}
                                            disabled={formSettings?.isReadOnly}
                                            required={true}
                                            className={classes.kTextbox}
                                            placeholder={"Ownership Percentage"}
                                            value={formDataFields?.PropertyOwnerOwnershipInterest}
                                            onChange={(e) => changeValueHandler(e, "PropertyOwnerOwnershipInterest")}
                                            format={PercentageFormatOptions}
                                            spinners={false}
                                        />
                                        {!inputValidator("PropertyOwnerOwnershipInterest") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.itemFields}>
                                <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                    <p>Contact Information</p>
                                </div>
                                <div className={classes.fieldsGroupW100}>
                                    <Label className={classes.Label}>
                                        Email Address
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <EmailInput
                                            required={false}
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"PropertyOwnerEmailAddress"}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                            setIsValidField={setPropertyOwnerEmailInputValid}
                                            validField={propertyOwnerEmailInputValid}
                                        />
                                    </div>
                                </div>
                                <div className={classes.fieldsGroupW100}>
                                    <Label className={classes.Label}>
                                        Phone Number
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <PhoneNumberInput
                                            required={false}
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"PropertyOwnerPhoneNumber"}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                            setIsValidField={setPropertyOwnerPhoneNumberInputValid}
                                            validField={propertyOwnerPhoneNumberInputValid}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.itemFields}>
                                <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                    <p>{"Applicant's Mailing Address"}</p>
                                </div>
                                <div className={classes.fieldsGroupW100}>
                                    <Label className={classes.Label}>
                                        Address Line 1
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <Input
                                            name={"ApplicantAddressLine1"}
                                            required={true}
                                            className={classes.kTextbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.ApplicantAddressLine1}
                                            onChange={(e) => changeValueHandler(e, "ApplicantAddressLine1")}
                                        />
                                        {!inputValidator("ApplicantAddressLine1") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                </div>
                                <div className={classes.fieldsGroupW100}>
                                    <Label className={classes.Label}>
                                        Address Line 2
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <Input
                                            name={"ApplicantAddressLine2"}
                                            className={classes.kTextbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.ApplicantAddressLine2}
                                            onChange={(e) => changeValueHandler(e, "ApplicantAddressLine2")}
                                        />
                                    </div>
                                </div>
                                <div className={classes.fieldsGroupW30}>
                                    <Label className={classes.Label}>
                                      City
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <Input
                                            name={"ApplicantAddressCity"}
                                            required={true}
                                            className={classes.kTextbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.ApplicantAddressCity}
                                            onChange={(e) => changeValueHandler(e, "ApplicantAddressCity")}
                                        />
                                        {!inputValidator("ApplicantAddressCity") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                </div>
                                <div className={classes.fieldsGroupW30}>
                                    <Label className={classes.Label}>
                                        State
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <StatesInput
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"ApplicantAddressState"}
                                            required={true}
                                            validField={applicantStateInputValid}
                                            setIsValidField={setApplicantStateInputValid}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                        />
                                        {!inputValidator("ApplicantAddressState") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                </div>
                                <div className={classes.fieldsGroupW30}>
                                    <Label className={classes.Label}>
                                        Zip
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <ZipCodeInput
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"ApplicantAddressZip"}
                                            required={true}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                            setIsValidField={setApplicantZipCode}
                                            validField={applicantZipCode}
                                        />
                                        {!inputValidator("ApplicantAddressZip") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroupW100}>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            name={"IsActiveDutyMilitary"}
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.IsActiveDutyMilitary}
                                            label={"I am an active duty U.S. armed services member or the spouse of an active duty member."}
                                            onChange={e => changeValueHandler(e, "IsActiveDutyMilitary")}
                                        />
                                    </div>
                                    { formDataFields?.IsActiveDutyMilitary ? <>
                                        <div className={classes.information}>Attach a copy of the
                                                most recent utility bill for the property subject to the claimed exemption.
                                        </div>

                                        <CustomUpload
                                            files={UtilityBillFiles}
                                            setFiles={setUtilityBillFiles}
                                            uploadIsValid={isUtilityBillUploadValid}
                                            setUploadIsValid={setIsUtilityBillUploadValid}
                                            formEntry={formEntry}
                                            formSettings={formSettings}
                                            isAdminPreview={isAdminPreview}
                                            setFormDataFields={setFormDataFields}
                                            formDataFields={formDataFields}
                                            fieldName={"UtilityBill"}
                                            isRequired={false}
                                            selectedFormDispatch={selectedFormDispatch}
                                            multiple={true}
                                            isLabelRequired={false}
                                            labelText={""}
                                        />
                                    </>
                                    :
                                    <ol>
                                        {formDataFields?.UtilityBill?.map(item => {
                                            return <li className={classes.filesList} key={item.id!}>
                                                {item.extension == '.pdf' ?
                                                    <i className={classes.fa + " fas fa-file-pdf"}></i>
                                                    : item.extension == '.doc' ?
                                                        < i className={classes.fa + " fa-solid fa-file-word"}></i>
                                                        :
                                                        <i className={classes.fa + " fa fa-regular fa-file"}></i>
                                                }
                                                <p className={classes.itemData} >Name: {`"${item.name}"`}</p>
                                                <p className={classes.itemData}>Size: {`"${item.size}"`}</p>
                                                <p className={classes.itemData}>Extension: {`"${item.extension}"`}</p>
                                            </li>
                                        })}
                                    </ol>
                                    }
                                </div>
                            </div>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroupW100}>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            name={"IsExemptFromProvidingACopyOfId"}
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.IsExemptFromProvidingACopyOfId}
                                            label={" I am exempt from the requirement to provide a copy of your driver’s license or state-issued personal identification certificate:"}
                                            onChange={e => changeValueHandler(e, "IsExemptFromProvidingACopyOfId")}
                                        />
                                    </div>
                                    {formDataFields?.IsExemptFromProvidingACopyOfId && <>
                                        <div className={classes.itemFields}>
                                            <div className={classes.fieldsGroupW100}>
                                                <div className={classes.inputsGroup}>
                                                    <Checkbox
                                                        name={"IsResidentOfAFacility"}
                                                        disabled={formSettings?.isReadOnly}
                                                        className={classes.Checkbox}
                                                        value={formDataFields?.IsResidentOfAFacility}
                                                        label={"I am a resident of a facility that provides services related to health, infirmity or aging."}
                                                        onChange={e => changeValueHandler(e, "IsResidentOfAFacility")}
                                                    />
                                                </div>
                                                { formDataFields?.IsResidentOfAFacility &&
                                                <div className={classes.itemFields}>
                                                    <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                                        <p>Facility Address</p>
                                                    </div>
                                                    <div className={classes.fieldsGroupW100}>
                                                        <Label className={classes.Label}>
                                                            Facility Name
                                                        </Label>
                                                        <div className={classes.inputsGroup}>
                                                            <Input
                                                                name={"FacilityName"}
                                                                className={classes.kTextbox}
                                                                disabled={formSettings?.isReadOnly}
                                                                value={formDataFields?.FacilityName}
                                                                onChange={(e) => changeValueHandler(e, "FacilityName")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={classes.fieldsGroupW100}>
                                                        <Label className={classes.Label}>
                                                            Address Line 1
                                                        </Label>
                                                        <div className={classes.inputsGroup}>
                                                            <Input
                                                                name={"FacilityAddressLine1"}
                                                                className={classes.kTextbox}
                                                                disabled={formSettings?.isReadOnly}
                                                                value={formDataFields?.FacilityAddressLine1}
                                                                onChange={(e) => changeValueHandler(e, "FacilityAddressLine1")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={classes.fieldsGroupW100}>
                                                        <Label className={classes.Label}>
                                                            Address Line 2
                                                        </Label>
                                                        <div className={classes.inputsGroup}>
                                                            <Input
                                                                name={"FacilityAddressLine2"}
                                                                className={classes.kTextbox}
                                                                disabled={formSettings?.isReadOnly}
                                                                value={formDataFields?.FacilityAddressLine2}
                                                                onChange={(e) => changeValueHandler(e, "FacilityAddressLine2")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={classes.fieldsGroupW30}>
                                                        <Label className={classes.Label}>
                                                            City
                                                        </Label>
                                                        <div className={classes.inputsGroup}>
                                                            <Input
                                                                name={"FacilityAddressCity"}
                                                                className={classes.kTextbox}
                                                                disabled={formSettings?.isReadOnly}
                                                                value={formDataFields?.FacilityAddressCity}
                                                                onChange={(e) => changeValueHandler(e, "FacilityAddressCity")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={classes.fieldsGroupW30}>
                                                        <Label className={classes.Label}>
                                                            State
                                                        </Label>
                                                        <div className={classes.inputsGroup}>
                                                            <StatesInput
                                                                setFormDataFields={setFormDataFields}
                                                                fieldName={"FacilityAddressState"}
                                                                required={false}
                                                                validField={facilityStateInputValid}
                                                                setIsValidField={setFacilityStateInputValid}
                                                                formDataFields={formDataFields}
                                                                isReadOnly={formSettings?.isReadOnly}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={classes.fieldsGroupW30}>
                                                        <Label className={classes.Label}>
                                                            Zip
                                                        </Label>
                                                        <div className={classes.inputsGroup}>
                                                            <ZipCodeInput
                                                                setFormDataFields={setFormDataFields}
                                                                fieldName={"FacilityAddressZip"}
                                                                required={false}
                                                                formDataFields={formDataFields}
                                                                isReadOnly={formSettings?.isReadOnly}
                                                                setIsValidField={setFacilityZipCode}
                                                                validField={facilityZipCode}
                                                            />
                                                        </div>
                                                    </div>
                                                </div> }
                                            </div>
                                        </div>
                                        <div className={classes.itemFields}>
                                            <div className={classes.fieldsGroupW100}>
                                                <div className={classes.inputsGroup}>
                                                    <Checkbox
                                                        name={"IsCertifiedForAddressConfidentialityProgram"}
                                                        disabled={formSettings?.isReadOnly}
                                                        className={classes.Checkbox}
                                                        value={formDataFields?.IsCertifiedForAddressConfidentialityProgram}
                                                        label={"I am certified for participation in the address confidentiality program administered by the Office of the Texas Attorney General under Code of Criminal Procedure Chapter 56, Subchapter B."}
                                                        onChange={e => changeValueHandler(e, "IsCertifiedForAddressConfidentialityProgram")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    }
                                </div>
                            </div>
                            { !formDataFields?.IsExemptFromProvidingACopyOfId &&
                            <div className={classes.itemFields + " " + classes.border0}>
                                <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                    <p>State Issued Personal Identification</p>
                                </div>
                                <div className={classes.information}>Attach a copy of your
                                    driver’s license or state-issued personal identification certificate. The address
                                    listed on your driver’s license or state issued personal identification certificate
                                    must correspond to the address of the property for which an exemption is claimed in
                                    this application.
                                    In certain cases, you are exempt from these requirements or the chief appraiser may
                                    waive the requirements.
                                </div>
                                <div className={classes.fieldsGroupW100}>
                                    <Label className={classes.Label}>
                                        Driver’s License, Personal ID Certificate or Social Security Number
                                        <i className={"fa fa-asterisk text-danger"}></i>
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <Input
                                            required={!formDataFields?.IsExemptFromProvidingACopyOfId}
                                            name={"PropertyOwnerIdNumber"}
                                            className={classes.kTextbox}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.PropertyOwnerIdNumber}
                                            onChange={(e) => changeValueHandler(e, "PropertyOwnerIdNumber")}
                                        />
                                        {!inputValidator("PropertyOwnerIdNumber") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                </div>
                                <div className={classes.fieldsGroupW100}>
                                    <div className={classes.imagePreview}>
                                        {OwnerPhotoIdFilesPreviews.uid && OwnerPhotoIdFilesPreviews.isImage ? (
                                            <img
                                                className={classes.image}
                                                src={OwnerPhotoIdFilesPreviews?.uid}
                                                alt={"image preview"}
                                            />
                                        ) :
                                            <img
                                                className={classes.image}
                                                src={PropertyOwnerIdNumberImage}
                                                alt={"image preview"}
                                            />
                                        }
                                    </div>
                                </div>
                                <div className={classes.fieldsGroupW100}>
                                    <CustomUpload
                                        files={PropertyOwnerPhotoIdFiles}
                                        setFiles={setPropertyOwnerPhotoIdFilesFiles}
                                        uploadIsValid={isPropertyOwnerPhotoIdUploadValid}
                                        setUploadIsValid={setIsPropertyOwnerPhotoIdUploadValid}
                                        formEntry={formEntry}
                                        formSettings={formSettings}
                                        isAdminPreview={isAdminPreview}
                                        setFormDataFields={setFormDataFields}
                                        formDataFields={formDataFields}
                                        fieldName={"PropertyOwnerPhotoId"}
                                        isRequired={true}
                                        selectedFormDispatch={selectedFormDispatch}
                                        multiple={false}
                                        isLabelRequired={false}
                                        labelText={"Attach a copy of the most recent utility bill for the property subject to the claimed exemption."}
                                    />
                                </div>
                            </div> }
                        </div>
                    </div>

                    { (formDataFields?.PropertyOwnerType === MarriedCouplePropertyOwnerType) &&
                    <>
                        <div className={`${classes.alert} ${classes.alertWarning}`}>
                            <i className="fa fa-warning"></i>
                            {"You are filing as a married couple. Please provide your spouse's information below."}
                        </div>
                        <div className={classes.container}>
                            <div className={classes.item}>
                                <div className={classes.itemFields}>
                                    <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Spouse Name
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        </Label>
                                        <div className={classes.inputsGroup}>
                                            <Input
                                                name={"PropertyOwnerSpouseName"}
                                                required={(formDataFields?.PropertyOwnerType === MarriedCouplePropertyOwnerType)}
                                                className={classes.kTextbox}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.PropertyOwnerSpouseName}
                                                onChange={(e) => changeValueHandler(e, "PropertyOwnerSpouseName",)}
                                            />
                                            {!inputValidator("PropertyOwnerSpouseName") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                    </div>
                                    <div className={classes.fieldsGroupW49}>
                                        <Label className={classes.Label}>
                                            Birth Date
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        </Label>
                                        <div className={classes.inputsGroup}>
                                            <CustomDatePicker
                                                fieldName={"PropertyOwnerSpouseBirthDate"}
                                                required={(formDataFields?.PropertyOwnerType === MarriedCouplePropertyOwnerType)}
                                                disabled={formSettings?.isReadOnly}
                                                formDataFields={formDataFields}
                                                setFormDataFields={setFormDataFields}
                                                onlyYear={false}
                                                setIsValidField={setDateSpouseIsValid}
                                                validField={dateSpouseIsValid}
                                                defaultValueDate={formDataFields?.PropertyOwnerSpouseBirthDate}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.fieldsGroupW49}>
                                        <Label className={classes.Label}>
                                            Ownership Percentage
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        </Label>
                                        <div className={classes.inputsGroup}>
                                            <NumericTextBox
                                                name={"PropertyOwnerSpouseOwnershipInterest"}
                                                disabled={formSettings?.isReadOnly}
                                                required={(formDataFields?.PropertyOwnerType === MarriedCouplePropertyOwnerType)}
                                                className={classes.kTextbox}
                                                placeholder={"Ownership Percentage"}
                                                value={formDataFields?.PropertyOwnerSpouseOwnershipInterest}
                                                onChange={(e) => changeValueHandler(e, "PropertyOwnerSpouseOwnershipInterest")}
                                                format={PercentageFormatOptions}
                                                spinners={false}
                                            />
                                            {!inputValidator("PropertyOwnerSpouseOwnershipInterest") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.itemFields}>
                                    <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Email Address
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        </Label>
                                        <div className={classes.inputsGroup}>
                                            <EmailInput
                                                required={(formDataFields?.PropertyOwnerType === MarriedCouplePropertyOwnerType)}
                                                setFormDataFields={setFormDataFields}
                                                fieldName={"PropertyOwnerSpouseEmailAddress"}
                                                formDataFields={formDataFields}
                                                isReadOnly={formSettings?.isReadOnly}
                                                setIsValidField={setPropertyOwnerSpouseEmailInputValid}
                                                validField={propertyOwnerSpouseEmailInputValid}
                                            />
                                            {!inputValidator("PropertyOwnerSpouseEmailAddress") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                    </div>
                                    <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Phone Number
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        </Label>
                                        <div className={classes.inputsGroup}>
                                            <PhoneNumberInput
                                                required={(formDataFields?.PropertyOwnerType === MarriedCouplePropertyOwnerType)}
                                                setFormDataFields={setFormDataFields}
                                                fieldName={"PropertyOwnerSpousePhoneNumber"}
                                                formDataFields={formDataFields}
                                                isReadOnly={formSettings?.isReadOnly}
                                                setIsValidField={setPropertyOwnerSpousePhoneNumberInputValid}
                                                validField={propertyOwnerSpousePhoneNumberInputValid}
                                            />
                                            {!inputValidator("PropertyOwnerSpousePhoneNumber") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.item}>
                                <div className={classes.itemFields + " " + classes.border0}>
                                    <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Spouse Driver’s License, Personal ID Certificate or Social Security Number
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        </Label>
                                        <div className={classes.inputsGroup}>
                                            <Input
                                                name={"PropertyOwnerSpouseIdNumber"}
                                                required={(formDataFields?.PropertyOwnerType === MarriedCouplePropertyOwnerType)}
                                                className={classes.kTextbox}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.PropertyOwnerSpouseIdNumber}
                                                onChange={(e) => changeValueHandler(e, "PropertyOwnerSpouseIdNumber")}
                                            />
                                            {!inputValidator("PropertyOwnerSpouseIdNumber") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                    </div>
                                    <div className={classes.fieldsGroupW100}>
                                        <div className={classes.imagePreview}>
                                            { OwnerSpousePhotoIdFilesPreviews.uid && OwnerSpousePhotoIdFilesPreviews.isImage ? (
                                                    <img
                                                        className={classes.image}
                                                        src={OwnerSpousePhotoIdFilesPreviews?.uid}
                                                        alt={"image preview"}
                                                    />
                                                ) :
                                                <img
                                                    className={classes.image}
                                                    src={PropertyOwnerIdNumberImage}
                                                    alt={"image preview"}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className={classes.fieldsGroupW100}>
                                        <CustomUpload
                                            files={PropertyOwnerSpousePhotoIdFiles}
                                            setFiles={setPropertyOwnerSpousePhotoIdFilesFiles}
                                            uploadIsValid={isPropertyOwnerSpousePhotoIdUploadValid}
                                            setUploadIsValid={setIsPropertyOwnerSpousePhotoIdUploadValid}
                                            formEntry={formEntry}
                                            formSettings={formSettings}
                                            isAdminPreview={isAdminPreview}
                                            setFormDataFields={setFormDataFields}
                                            formDataFields={formDataFields}
                                            fieldName={"PropertyOwnerSpousePhotoId"}
                                            isRequired={(formDataFields?.PropertyOwnerType === MarriedCouplePropertyOwnerType)}
                                            selectedFormDispatch={selectedFormDispatch}
                                            multiple={false}
                                            isLabelRequired={false}
                                            labelText={"Attach a copy of the most recent utility bill for the property subject to the claimed exemption."}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    }
                    <div className={classes.container}>
                        <div className={classes.item}>
                            <div className={classes.itemFields}>
                                <div className={` ${classes.fieldsGroupW100 + " " + classes.flexGroup} `}>
                                    <Label className={`${classes.Label} ${classes.fieldsGroupW49}`}>
                                        Does this property have multiple owners?
                                    </Label>
                                    <div className={`${classes.fieldsGroup}`}>
                                        <CheckboxGroup
                                            isAdminPreview={isAdminPreview}
                                            setFormDataFields={setFormDataFields}
                                            formDataFields={formDataFields}
                                            formSettings={formSettings}
                                            fieldName={"HasMultipleOwners"}
                                            fieldSecondName={"HasMultipleOwnersSecond"}
                                            isRequired={false}
                                            checkboxesIsValid={IsHasMultipleOwnersCheckBoxGroupValid}
                                            setCheckboxesIsValid={setIsHasMultipleOwnersCheckBoxGroupValid}
                                        />
                                        {!IsHasMultipleOwnersCheckBoxGroupValid && (
                                            <Error className={classes.fieldsGroupW100}>{validationMessage}</Error>
                                        )}
                                    </div>
                                    { formDataFields?.HasMultipleOwners && <>
                                        <div className={classes.information + " " + classes.fieldsGroupW100} >Please provide the
                                            following information for each additional person who has an ownership
                                            interest
                                            in the property:
                                            <ul>
                                                <li>property owner’s name</li>
                                                <li>birth date</li>
                                                <li>driver’s license, personal ID certificate or social security
                                                    number
                                                </li>
                                                <li>primary phone number</li>
                                                <li>email address</li>
                                                <li>and percentage of ownership interest in the property</li>
                                            </ul>
                                        </div>

                                        {/* Owner 1 */}
                                        <div className={`${classes.itemFields} ${classes.fieldsGroupW100}`}>
                                            <div className={`${classes.itemTitle} ${classes.addressTitle} ${classes.fieldsGroupW100}`}>
                                                Owner 1
                                            </div>
                                            <div className={classes.fieldsGroupW30}>
                                                <Label className={`${classes.Label}`}>
                                                    Owner Name
                                                </Label>
                                                <div className={classes.inputsGroup}>
                                                    <Input
                                                        className={classes.kTextbox}
                                                        disabled={true}
                                                        value={formDataFields?.PropertyOwnerName}
                                                    />
                                                </div>
                                            </div>
                                            <div className={classes.fieldsGroupW30}>
                                                <Label className={`${classes.Label}`}>
                                                    Email Address
                                                </Label>
                                                <div className={classes.inputsGroup}>
                                                    <Input
                                                        className={classes.kTextbox}
                                                        disabled={true}
                                                        value={formDataFields?.PropertyOwnerEmailAddress}
                                                    />
                                                </div>
                                            </div>
                                            <div className={classes.fieldsGroupW30}>
                                                <Label className={`${classes.Label}`}>
                                                    Personal ID Certificate (DL/SS/etc.)
                                                </Label>
                                                <div className={classes.inputsGroup}>
                                                    <Input
                                                        className={classes.kTextbox}
                                                        disabled={true}
                                                        value={formDataFields?.PropertyOwnerIdNumber}
                                                    />
                                                </div>
                                            </div>
                                            <div className={classes.fieldsGroupW30}>
                                                <Label className={`${classes.Label}`}>
                                                    Phone Number
                                                </Label>
                                                <div className={classes.inputsGroup}>
                                                    <PhoneNumberInput
                                                        required={false}
                                                        value={formDataFields?.PropertyOwnerPhoneNumber}
                                                        isReadOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className={classes.fieldsGroupW30}>
                                                <Label className={`${classes.Label}`}>
                                                    Birth Date
                                                </Label>
                                                <div className={classes.inputsGroup}>
                                                    <CustomDatePicker
                                                        fieldName={"PropertyOwnerBirthDate"}
                                                        required={false}
                                                        disabled={true}
                                                        formDataFields={formDataFields}
                                                        setFormDataFields={setFormDataFields}
                                                        onlyYear={false}
                                                        setIsValidField={setDateOwnerIsValid}
                                                        validField={dateOwnerIsValid}
                                                        defaultValueDate={formDataFields?.PropertyOwnerBirthDate}
                                                    />
                                                </div>
                                            </div>
                                            <div className={classes.fieldsGroupW30}>
                                                <Label className={`${classes.Label}`}>
                                                    Percent Ownership
                                                </Label>
                                                <div className={classes.inputsGroup}>
                                                    <NumericTextBox
                                                        disabled={true}
                                                        className={classes.kTextbox}
                                                        value={formDataFields?.PropertyOwnerOwnershipInterest}
                                                        format={PercentageFormatOptions}
                                                        spinners={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/* Owner 2 */}
                                        { formDataFields?.PropertyOwnerType === MarriedCouplePropertyOwnerType &&
                                            <div className={`${classes.itemFields} ${classes.fieldsGroupW100}`}>
                                                <div className={`${classes.itemTitle} ${classes.addressTitle} ${classes.fieldsGroupW100}`}>
                                                    Owner 2
                                                </div>
                                                <div className={classes.fieldsGroupW30}>
                                                    <Label className={`${classes.Label}`}>
                                                        Owner Name
                                                    </Label>
                                                    <div className={classes.inputsGroup}>
                                                        <Input
                                                            className={classes.kTextbox}
                                                            disabled={true}
                                                            value={formDataFields?.PropertyOwnerSpouseName}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={classes.fieldsGroupW30}>
                                                    <Label className={`${classes.Label}`}>
                                                        Email Address
                                                    </Label>
                                                    <div className={classes.inputsGroup}>
                                                        <Input
                                                            className={classes.kTextbox}
                                                            disabled={true}
                                                            value={formDataFields?.PropertyOwnerSpouseEmailAddress}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={classes.fieldsGroupW30}>
                                                    <Label className={`${classes.Label}`}>
                                                        Personal ID Certificate (DL/SS/etc.)
                                                    </Label>
                                                    <div className={classes.inputsGroup}>
                                                        <Input
                                                            className={classes.kTextbox}
                                                            disabled={true}
                                                            value={formDataFields?.PropertyOwnerSpouseIdNumber}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={classes.fieldsGroupW30}>
                                                    <Label className={`${classes.Label}`}>
                                                        Phone Number
                                                    </Label>
                                                    <div className={classes.inputsGroup}>
                                                        <PhoneNumberInput
                                                            required={false}
                                                            value={formDataFields?.PropertyOwnerSpousePhoneNumber}
                                                            isReadOnly={true}
                                                            setIsValidField={setPropertyOwnerSpousePhoneNumberInputValid}
                                                            validField={propertyOwnerSpousePhoneNumberInputValid}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={classes.fieldsGroupW30}>
                                                    <Label className={`${classes.Label}`}>
                                                        Birth Date
                                                    </Label>
                                                    <div className={classes.w100}>
                                                        <CustomDatePicker
                                                            fieldName={"PropertyOwnerSpouseBirthDate"}
                                                            required={false}
                                                            disabled={true}
                                                            formDataFields={formDataFields}
                                                            setFormDataFields={setFormDataFields}
                                                            onlyYear={false}
                                                            setIsValidField={setDateSpouseIsValid}
                                                            validField={dateSpouseIsValid}
                                                            defaultValueDate={formDataFields?.PropertyOwnerSpouseBirthDate}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={classes.fieldsGroupW30}>
                                                    <Label className={`${classes.Label}`}>
                                                        Percent Ownership
                                                    </Label>
                                                    <div className={classes.inputsGroup}>
                                                        <NumericTextBox
                                                            disabled={true}
                                                            className={classes.kTextbox}
                                                            value={formDataFields?.PropertyOwnerSpouseOwnershipInterest}
                                                            format={PercentageFormatOptions}
                                                            spinners={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    {formDataFields.PropertyOwners?.map((item, index) => {
                                       return <div key={item.Id} className={`${classes.itemFields} ${classes.fieldsGroupW100}`}>
                                           <div className={`${classes.itemTitle} ${classes.addressTitle} ${classes.fieldsGroupW100}`}>
                                               Owner {formDataFields?.PropertyOwnerType === MarriedCouplePropertyOwnerType ? index + 3 : index + 2}
                                           </div>
                                            <div className={classes.fieldsGroupW30}>
                                                <Label className={`${classes.Label}`}>
                                                    Owner Name
                                                </Label>
                                                <div className={classes.inputsGroup}>
                                                    <Input
                                                        className={classes.kTextbox}
                                                        disabled={formSettings?.isReadOnly}
                                                        value={item.OwnerName}
                                                        onChange={(e) => changeRowDataHandler(e, "OwnerName", item.Id)}
                                                    />
                                                </div>
                                            </div>
                                            <div className={classes.fieldsGroupW30}>
                                                <Label className={`${classes.Label}`}>
                                                    Email Address
                                                </Label>
                                                <div className={classes.inputsGroup}>
                                                    <RowEmailInput
                                                        fieldName={"EmailAddress"}
                                                        required={false}
                                                        isReadOnly={formSettings?.isReadOnly}
                                                        formDataFields={formDataFields}
                                                        setFormDataFields={setFormDataFields}
                                                        group={"PropertyOwners"}
                                                        id={item.Id}
                                                        setErrorsArray={setEmailAddressErrorsArray}
                                                        errorsArray={emailAddressErrorsArray}
                                                    />
                                                </div>
                                            </div>
                                            <div className={classes.fieldsGroupW30}>
                                                <Label className={`${classes.Label}`}>
                                                    Personal ID Certificate (DL/SS/etc.)
                                                </Label>
                                                <div className={classes.inputsGroup}>
                                                    <Input
                                                        className={classes.kTextbox}
                                                        disabled={formSettings?.isReadOnly}
                                                        value={item.PersonalIdCertificate}
                                                        onChange={(e) => changeRowDataHandler(e, "PersonalIdCertificate", item.Id)}
                                                    />
                                                </div>
                                            </div>
                                            <div className={classes.fieldsGroupW30}>
                                                <Label className={`${classes.Label}`}>
                                                    Phone Number
                                                </Label>
                                                <div className={classes.inputsGroup}>
                                                    <RowPhoneNumberInput
                                                        fieldName={"PhoneNumber"}
                                                        required={false}
                                                        isReadOnly={formSettings?.isReadOnly}
                                                        formDataFields={formDataFields}
                                                        setFormDataFields={setFormDataFields}
                                                        group={"PropertyOwners"}
                                                        id={item.Id}
                                                        setErrorsArray={setPhoneNumberErrorsArray}
                                                        errorsArray={phoneNumberErrorsArray}
                                                    />
                                                </div>
                                            </div>
                                            <div className={classes.fieldsGroupW30}>
                                                <Label className={`${classes.Label}`}>
                                                    Birth Date
                                                </Label>
                                                <div className={classes.inputsGroup}>
                                                    <CustomRowDatePicker<IHomesteadExemptionThirdSectionInterface>
                                                        fieldName={"BirthDate"}
                                                        required={false}
                                                        disabled={formSettings?.isReadOnly}
                                                        formDataFields={formDataFields}
                                                        setFormDataFields={setFormDataFields}
                                                        onlyYear={false}
                                                        group={"PropertyOwners"}
                                                        defaultValueDate={item.BirthDate ? new Date(item.BirthDate) : null}
                                                        id={item.Id}
                                                        setErrorsArray={setDateErrorsArray}
                                                        errorsArray={dateErrorsArray}
                                                    />
                                                </div>
                                            </div>
                                            <div className={classes.fieldsGroupW30}>
                                                <Label className={`${classes.Label}`}>
                                                    Percent Ownership
                                                </Label>
                                                <div className={classes.inputsGroup}>
                                                    <NumericTextBox
                                                        className={classes.kTextbox}
                                                        disabled={formSettings?.isReadOnly}
                                                        value={item.PercentOwnership}
                                                        onChange={(e) => changeRowDataHandler(e, "PercentOwnership", item.Id)}
                                                        format={PercentageFormatOptions}
                                                        spinners={false}
                                                    />
                                                </div>
                                            </div>
                                        </div> })}
                                        { !formSettings?.isReadOnly &&
                                            <div className={classes.fieldsGroupW100}>
                                                <button className={classes.LightButton}
                                                        disabled={formSettings?.isReadOnly}
                                                        onClick={e => addRowHandler(e)}>
                                                    <i className="fa fas fa-plus"></i>
                                                    Add Row</button>
                                                <button
                                                    className={classes.LightButton}
                                                    disabled={formSettings?.isReadOnly}
                                                    onClick={e => removeRowHandler(e)}>
                                                    <i className="fa fas fa-minus"></i>
                                                    Remove Row</button>
                                            </div> }
                                    </>}
                                </div>
                            </div>
                            <div className={classes.itemFields}>
                                <div className={classes.fieldsGroupW100}>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            name={"IsRequestingChiefAppraiserWaiveAddressRequirement"}
                                            label={"Check this box if you request that the chief appraiser waive the requirement that the address of the property for which the exemption is claimed corresponds to the address listed on your driver’s license or state-issued personal identification certificate."}
                                            disabled={formSettings?.isReadOnly}
                                            onChange={e => changeValueHandler(e, "IsRequestingChiefAppraiserWaiveAddressRequirement")}
                                            value={formDataFields?.IsRequestingChiefAppraiserWaiveAddressRequirement}
                                        />
                                    </div>
                                </div>
                                { formDataFields?.IsRequestingChiefAppraiserWaiveAddressRequirement && <>
                                <div className={`${classes.itemFields} ${classes.fieldsGroupW100}`}>
                                    <div className={classes.fieldsGroupW100}>
                                        <div className={classes.inputsGroup}>
                                            <Checkbox
                                                name={"IsSection521DriversLicenseHolder"}
                                                label={"I hold a driver’s license issued under Transportation Code Section 521.121(c) or 521.1211. Attached is a copy of the application for that license to the Texas Department of Transportation."}
                                                disabled={formSettings?.isReadOnly}
                                                onChange={e => changeValueHandler(e, "IsSection521DriversLicenseHolder")}
                                                value={formDataFields?.IsSection521DriversLicenseHolder}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroupW100}`}>
                                    <div className={classes.fieldsGroupW100}>
                                        <div className={classes.inputsGroup}>
                                            <Checkbox
                                                name={"IsActiveDutyMilitary"}
                                                label={"I am an active duty U.S. armed services member or the spouse of an active duty member."}
                                                disabled={formSettings?.isReadOnly}
                                                onChange={e => changeValueHandler(e, "IsActiveDutyMilitary")}
                                                value={formDataFields?.IsActiveDutyMilitary}
                                            />
                                        </div>
                                    </div>
                                </div>
                                </>}
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
