import { useEffect, useRef, useState } from "react";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import shared from "../PDF-Shared-style.module.scss";
import classes from "./WildlifeManagement-PDF-style.module.scss";
import { settingsService } from "src/services/settings.service";
import { IWildlifeManagementFifthSection } from "src/interfaces/forms/WildlifeManagement/wildlife-management-fifth-section.interface";
import { IWildlifeManagementFirstSection } from "src/interfaces/forms/WildlifeManagement/wildlife-management-first-section.interface";
import { IWildlifeManagementSecondSection } from "src/interfaces/forms/WildlifeManagement/wildlife-management-second-section.interface";
import { IWildlifeManagementSixthSection } from "src/interfaces/forms/WildlifeManagement/wildlife-management-sixth-section.interface";
import { IWildlifeManagementThirdSection } from "src/interfaces/forms/WildlifeManagement/wildlife-management-third-section.interface";
import { IWildlifeManagementFourthSectionHabitatControl } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-habitat-control.interface";
import { IWildlifeManagementFourthSectionErosionControl } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-erosion-control.interface";
import { IWildlifeManagementFourthSectionPredatorControl } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-predator-control.interface";
import { IWildlifeManagementFourthSectionSupplementalFood } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-providing-supplemental-food.interface";
import { IWildlifeManagementFourthSectionSupplementalWater } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-supplemental-water.interface";
import { IWildlifeManagementFourthSectionSupplementalShelter } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-providing-supplemental-shelter.interface";
import { IWildlifeManagementFourthSectionCensus } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-census.interface";

interface IFormFields {
  formDataFieldsFirstSection?: IWildlifeManagementFirstSection;
  formDataFieldsSecondSection?: IWildlifeManagementSecondSection;
  formDataFieldsThirdSection?: IWildlifeManagementThirdSection;  
  formDataFieldsFourthSectionHabitatControl?: IWildlifeManagementFourthSectionHabitatControl;
  formDataFieldsFourthSectionErosionControl?: IWildlifeManagementFourthSectionErosionControl;
  formDataFieldsFourthSectionPredatorControl?: IWildlifeManagementFourthSectionPredatorControl;
  formDataFieldsFourthSectionSupplementalWater?: IWildlifeManagementFourthSectionSupplementalWater;
  formDataFieldsFourthSectionSupplementalFood?: IWildlifeManagementFourthSectionSupplementalFood;
  formDataFieldsFourthSectionSupplementalShelter?: IWildlifeManagementFourthSectionSupplementalShelter;
  formDataFieldsFourthSectionCensus?: IWildlifeManagementFourthSectionCensus;
  formDataFieldsFifthSection?: IWildlifeManagementFifthSection;
  formDataFieldsSixthSection?: IWildlifeManagementSixthSection;
  getPdfBase64Url?: (url: string) => void;
}

export default function WildlifeManagementPDFView(props: IFormFields): JSX.Element {
  const {
    formDataFieldsFirstSection,
    formDataFieldsSecondSection,
    formDataFieldsThirdSection,
    formDataFieldsFourthSectionHabitatControl,
    formDataFieldsFourthSectionErosionControl,
    formDataFieldsFourthSectionPredatorControl,
    formDataFieldsFourthSectionSupplementalWater,
    formDataFieldsFourthSectionSupplementalFood,
    formDataFieldsFourthSectionSupplementalShelter,
    formDataFieldsFourthSectionCensus,
    formDataFieldsFifthSection,
    formDataFieldsSixthSection,
    getPdfBase64Url,
  } = props;

  const templateRef = useRef(null);
  const [templateBase64, setTemplateBase64] = useState("");
  const settings = settingsService().settings;

  useEffect(() => {
    drawDOM(templateRef.current as any, { paperSize: "A4" })
      .then((group) => {
        if (templateRef.current) {
          const myReference = templateRef.current as any;
          myReference.style.display = "none";
        }
        return exportPDF(group);
      })
      .then((dataUri) => {
        setTemplateBase64(dataUri);
        if (getPdfBase64Url) {
          getPdfBase64Url(dataUri);
        }
      });
  }, []);

  return (
    <>
      {templateRef && templateBase64 && (
        <iframe
          style={{ width: "100%", height: "100%" }}
          src={templateBase64}
        />
      )}
      <div ref={templateRef} className={`${shared.FormContainer} ${shared.Form}`}>
          <div className={shared.PrintTogether}>
              <div className={`${shared.Row} ${shared.AlignCenter}`}>
                  <div className={`${shared.Column9} ${shared.H3} ${shared.TextRight}`}>
                    1-D-1 Open Space Agricultural Valuation
                    Wildlife Management Annual Report for the Year(s)
                  </div>
                  <div className={`${shared.Column2} ${shared.H3}`}>
                    <div className={shared.Value}>
                        <br/>
                        {formDataFieldsFirstSection?.TaxYear}
                    </div>
                  </div>
              </div>

              <div className={`${shared.Row} ${shared.AlignCenter}`}>
                <div className={`${shared.Column12} ${shared.TextSmall} ${shared.TextCenter} ${shared.SubTitle}`}>
                  Submit this plan to your County Tax Appraiser, not to Texas Parks and Wildlife Department
                </div>
              </div>

              <div className={shared.Row}>
                <div className={`${shared.ColOffset9} ${shared.Column3}`}>
                    <div className={shared.Value}>
                        {formDataFieldsFirstSection?.AccountNumber}
                    </div>
                    <div className={shared.ValueLabelBelow}>Account Number</div>
                </div>
              </div>

              <div className={shared.Step}>
                Part I. Owner Information
              </div>
              <div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFirstSection?.OwnersName}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                          Owner&apos;s Name
                      </div>
                  </div>
                  <div className={shared.Column12}>
                    <div className={shared.Value}>
                        {formDataFieldsFirstSection?.MailingAddressLine1}{" "}
                        &nbsp;
                        {formDataFieldsFirstSection?.MailingAddressLine2 && (
                            <span>
                            {formDataFieldsFirstSection?.MailingAddressLine2}{" "}
                            &nbsp;
                            </span>
                        )}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Current mailing address
                    </div>
                  </div>
                  <div className={shared.Column12}>
                    <div className={shared.Value}>
                        {formDataFieldsFirstSection?.MailingAddressCity}{" "}
                        &nbsp;
                        {formDataFieldsFirstSection?.MailingAddressState && `${formDataFieldsFirstSection?.MailingAddressState},`}
                        &nbsp;
                        {formDataFieldsFirstSection?.MailingAddressZip} &nbsp;
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      City, town, post office, state and zip code:
                    </div>
                  </div>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFirstSection?.PhoneNumber}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Phone number
                      </div>
                  </div>

                  <div className={shared.Column6}>
                      <div className={shared.Value}>
                          {formDataFieldsFirstSection?.TractName}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Tract Name
                      </div>
                  </div>
                  <div className={shared.Column6}>
                      <div className={shared.Value}>
                          {formDataFieldsFirstSection?.MajorityCounty}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Majority County
                      </div>
                  </div>

                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFirstSection?.AdditionalCountiesIfAny}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Counties (if any)
                      </div>
                  </div>
                </div>                
              </div>

              <div className={shared.Step}>
                Part II. Qualifying Wildlife Management Activities
              </div>
              <div>
                <p>
                  Check the wildlife management practices implemented on the property during the year being
                  reported. A minimum of three practices is required. 
                </p>
                <div className={shared.Row}>
                  <span className="col-md-12">
                    <i className={"fa " +
                      (formDataFieldsSecondSection?.WildlifeManagementPractices?.HabitatControl
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Habitat Control
                  </span>
                  <span className="col-md-12">
                    <i className={"fa " +
                      (formDataFieldsSecondSection?.WildlifeManagementPractices?.ErosionControl
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Predator control 
                  </span>
                  <span className="col-md-12">
                    <i className={"fa " +
                      (formDataFieldsSecondSection?.WildlifeManagementPractices?.PredatorControl
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Erosion Control
                  </span>
                  <span className="col-md-12">
                    <i className={"fa " +
                      (formDataFieldsSecondSection?.WildlifeManagementPractices?.MakingCensusCountsToDeterminePopulation
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Making census counts to determine population
                  </span>
                  <span className="col-md-12">
                    <i className={"fa " +
                      (formDataFieldsSecondSection?.WildlifeManagementPractices?.ProvideSupplementalSuppliesOfWater
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Provide supplemental supplies of water
                  </span>
                  <span className="col-md-12">
                    <i className={"fa " +
                      (formDataFieldsSecondSection?.WildlifeManagementPractices?.ProvideSupplementalSuppliesOfFood
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Provide supplemental supplies of food
                  </span>
                  <span className="col-md-12">
                    <i className={"fa " +
                      (formDataFieldsSecondSection?.WildlifeManagementPractices?.ProvideShelters
                        ? "fa-check-square-o"
                        : "fa-square-o")}></i>
                    Provide shelters
                  </span>
                  
                </div>
              </div>
              
              <div className={shared.Step}>
                Part III. Wildlife Management Association Membership
              </div>
              <div>
                <div className={shared.Row}>
                  <div className={shared.Column9}>
                    Are you a member of a wildlife property association?
                  </div>
                  <div className={`${shared.Column2} ${shared.TextRight}`}>
                      <i className={"fa " +
                          (formDataFieldsThirdSection?.IsMemberOfWildlifePropertyAssociation == true
                          ? "fa-check-square-o" : "fa-square-o")
                      }></i>{" "}
                      &nbsp; Yes &nbsp; &nbsp;
                      <i className={"fa " +
                          (!formDataFieldsThirdSection?.IsMemberOfWildlifePropertyAssociation == false
                          ? "fa-check-square-o" : "fa-square-o")
                      }></i>{" "}
                      &nbsp; No
                  </div>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsThirdSection?.NameOfWildlifePropertyCoOpAssociation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Name of wildlife property co-op/association, if YES is checked.
                      </div>
                  </div>
                </div>
              </div>

              <div className={shared.Step}>
                Part IV. Wildlife Management Activities
              </div>
              <p className={shared.SubHeader}>
                Check the activities you have implemented during the year to support each of the wildlife
                management activities listed in Part II. 
              </p>

              <div className={shared.SubStep}>
                1. HABITAT CONTROL
              </div>

              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.GrazingManagement
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Grazing Management
                  </span>
                </div>
                <br/>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    Check grazing system being utilized.
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.GrazingManagementSystemTypes?.Herd3Pasture
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    1 herd/3 pasture
                  </span>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.GrazingManagementSystemTypes?.Herd4Pasture
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    1 herd/4 pasture
                  </span>
                  <span className={shared.Column5}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.GrazingManagementSystemTypes?.HerdMultiplePasture
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    1 herd/multiple pasture
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.GrazingManagementSystemTypes?.HighIntensityLowFrequency
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    High intensity/low frequency (HILF)
                  </span>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.GrazingManagementSystemTypes?.ShortDurationSystem
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Short duration system
                  </span>
                  <span className={shared.Column5}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.GrazingManagementSystemTypes?.Other
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Other type of grazing system (describe)
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.GrazingManagementSystemTypesOther}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Other type of grazing system (describe)
                      </div>
                  </div>
                </div>                  
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.GrazingManagementAdditionalInformation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Information
                      </div>
                  </div>
                </div>
              </div>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.PrescribedBurning
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Prescribed Burning 
                  </span>
                </div>
                <br/>
                <div className={shared.Row}>
                  <div className={shared.Column6}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.PrescribedBurningAcresToBeBurned}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Acres to be burned
                      </div>
                  </div>
                  <div className={shared.Column6}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.PrescribedBurningPlannedBurnDate}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Planned burn date
                      </div>
                  </div>
                </div>                  
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.PrescribedBurningAdditionalInformation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Information
                      </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.RangeEnhancement
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Range Enhancement (Range Reseeding) 
                  </span>
                </div>
                <br/>
                <div className={shared.Row}>
                  <div className={shared.Column6}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.RangeEnhancementAcresToBeSeeded}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Acres to be seeded
                      </div>
                  </div>
                  <div className={shared.Column6}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.RangeEnhancementDateToBeSeeded}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Date to be seeded
                      </div>
                  </div>
                </div>
                <div className={shared.Row}>                  
                  <span className={shared.Column2}>
                    Seeding Method:
                  </span>
                  <span className={shared.Column2}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.RangeEnhancementSeedingMethods?.Broadcast
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Broadcast
                  </span>
                  <span className={shared.Column2}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.RangeEnhancementSeedingMethods?.Drilled
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Drilled
                  </span>
                  <span className={shared.Column5}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.RangeEnhancementSeedingMethods?.NativeHay
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Native Hay
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.RangeEnhancementSeedingMixtureToBeUsed}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Seeding mixture to be used
                      </div>
                  </div>
                </div>
                <div className={shared.Row}>
                    <div className={shared.Column9}>
                      Fertilized:
                    </div>
                    <div className={`${shared.Column2} ${shared.TextRight}`}>
                        <i className={"fa " +
                            (formDataFieldsFourthSectionHabitatControl?.RangeEnhancementFertilized == true
                            ? "fa-check-square-o" : "fa-square-o")}></i>{" "}
                        &nbsp; Yes &nbsp; &nbsp;
                        <i className={"fa " +
                            (!formDataFieldsFourthSectionHabitatControl?.RangeEnhancementFertilized == false
                            ? "fa-check-square-o" : "fa-square-o")}></i>{" "}
                        &nbsp; No
                    </div>
                </div>
                <div className={shared.Row}>
                    <div className={shared.Column9}>
                      Weed control needed for establishment?
                    </div>
                    <div className={`${shared.Column2} ${shared.TextRight}`}>
                        <i className={"fa " +
                            (formDataFieldsFourthSectionHabitatControl?.RangeEnhancementWeedControlNeeded == true
                            ? "fa-check-square-o" : "fa-square-o")}></i>{" "}
                        &nbsp; Yes &nbsp; &nbsp;
                        <i className={"fa " +
                            (!formDataFieldsFourthSectionHabitatControl?.RangeEnhancementWeedControlNeeded == false
                            ? "fa-check-square-o" : "fa-square-o")}></i>{" "}
                        &nbsp; No
                    </div>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.RangeEnhancementAdditionalInformation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Information
                      </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.BrushManagement
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Brush Management 
                  </span>
                </div>
                <br/>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.BrushManagementAcresToBeTreated}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Acres to be treated
                      </div>
                  </div>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    Check method of brush management:
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.BrushManagementMethodMechanical
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Mechanical
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1SmallPadding}></span>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.BrushManagementMethodMechanicalTypes?.Grubber
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Grubber
                  </span>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.BrushManagementMethodMechanicalTypes?.Chain
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Chain
                  </span>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.BrushManagementMethodMechanicalTypes?.RollerChopperAerator
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Roller chopper/aerator
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1SmallPadding}></span>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.BrushManagementMethodMechanicalTypes?.RhomeDisc
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Rhome disc
                  </span>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.BrushManagementMethodMechanicalTypes?.BrushHogShredder
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Brush hog (shredder)
                  </span>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.BrushManagementMethodMechanicalTypes?.Dozer
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Dozer
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1SmallPadding}></span>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.BrushManagementMethodMechanicalTypes?.HandCuttingChainsaw
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Hand-cutting (chainsaw)
                  </span>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.BrushManagementMethodMechanicalTypes?.HydraulicShears
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Hydraulic shears
                  </span>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.BrushManagementMethodMechanicalTypes?.Other
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Other (describe)
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1SmallPadding}></span>
                  <span className={shared.Column10}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.BrushManagementMethodMechanicalTypesOther}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Other (describe)
                      </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.BrushManagementMethodChemical
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Chemical
                  </span>
                  <span className={shared.Column4}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.BrushManagementMethodChemicalKind}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Kind
                      </div>
                  </span>
                  <span className={shared.Column4}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.BrushManagementMethodChemicalRate}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Rate
                      </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.BrushManagementDesign
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Brush management design:
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1SmallPadding}></span>
                  <span className={shared.Column2}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.BrushManagementDesignTypes?.Block
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Block
                  </span>
                  <span className={shared.Column2}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.BrushManagementDesignTypes?.Mosaic
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Mosaic
                  </span>
                  <span className={shared.Column2}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.BrushManagementDesignTypes?.Strips
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Strips
                  </span>
                  <span className={shared.Column2}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.BrushManagementDesignStripsWidth}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Width
                      </div>
                  </span>
                  <span className={shared.Column2}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.BrushManagementDesignStripsLength}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Length
                      </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.BrushManagementAdditionalInformation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Information
                      </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.FenceModification
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Fence Modification 
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={`${shared.Column2} ${shared.TextRight}`}>
                    Target species:
                  </span>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.FenceModificationSpecies?.PronghornAntelope
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Pronghorn antelope
                  </span>
                  <span className={shared.Column6}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.FenceModificationSpecies?.BighornSheep
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Bighorn sheep
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={`${shared.Column2} ${shared.TextRight}`}>
                    Technique:
                  </span>
                  <span className={shared.Column6}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.FenceModificationBottomOfNetWire
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Fold up bottom of net-wire
                  </span>
                  <span className={shared.Column3}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.FenceModificationBottomOfNetWireGapWidth}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Gap width
                      </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column2}>
                  </span>
                  <span className={shared.Column6}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.FenceModificationReplaceSectionsOfNetWire
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Replace sections of net-wire with barbed wire
                  </span>
                  <span className={shared.Column3}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.FenceModificationReplaceSectionsOfNetWireGapWidth}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Gap width
                      </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column2}>
                  </span>
                  <span className={shared.Column9}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.FenceModificationMilesOfFenceThatWillBeModified}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Miles of fencing that will be modified
                      </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column2}>
                  </span>
                  <span className={shared.Column6}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.FenceModificationReplaceEntireFence
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Replace entire net-wire fence with barbed wire
                  </span>
                  <span className={shared.Column3}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.FenceModificationMilesReplaced}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Miles Replaced
                      </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.FenceModificationAdditionalInformation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Information
                      </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.RiparianManagement
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Riparian management and enhancement 
                  </span>
                </div>
                <br/>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.RiparianManagementFencingOfRiparianArea
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Fencing of riparian area
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={`${shared.Column4} ${shared.TextRight}`}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.RiparianManagementFencingRiparianAreaType === "CompleteFencing"
                        ? "fa-check-circle-o" : "fa-circle-o")}></i>
                    Complete Fencing
                  </span>
                  <span className={shared.Column6}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.RiparianManagementFencingRiparianAreaType === "PartialFencing"
                        ? "fa-check-circle-o" : "fa-circle-o")}></i>
                    Partial Fencing
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.RiparianManagementDefermentFromLivestockGrazing
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Deferment from livestock grazing
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1}></span>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.RiparianManagementDefermentType === "CompleteDeferment"
                        ? "fa-check-circle-o" : "fa-circle-o")}></i>
                    Complete Deferment
                  </span>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.RiparianManagementDefermentType === "PartialDeferment"
                        ? "fa-check-circle-o" : "fa-circle-o")}></i>
                    Partial Deferment
                  </span>
                  <span className={shared.Column4}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.RiparianManagementSeasonDeferred}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Season Deferred
                      </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.RiparianManagementEstablishVegetation
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Establish vegetation
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1}></span>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.RiparianManagementEstablishVegetationTrees
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Trees (list species) 
                  </span>
                  <span className={shared.Column6}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.RiparianManagementTreesListSpecies}
                      </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1}></span>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.RiparianManagementEstablishVegetationShrubs
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Shrubs (list species) 
                  </span>
                  <span className={shared.Column6}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.RiparianManagementShrubsListSpecies}
                      </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1}></span>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.RiparianManagementEstablishVegetationHerbaceousSpecies
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Herbaceous species (list) 
                  </span>
                  <span className={shared.Column6}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.RiparianManagementHerbaceousListSpecies}
                      </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.RiparianManagementAdditionalInformation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Information
                      </div>
                  </div>
                </div>
              </div>
              <br/>
              <br/>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.WetlandEnhancement
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Wetland enhancement
                  </span>
                </div>
                <br/>
                <div className={shared.Row}>
                  <span className={`${shared.Column3} ${shared.TextRight}`}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.WetlandEnhancementTypes?.ProvideSeasonalWater
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Provide seasonal water
                  </span>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.WetlandEnhancementTypes?.ProvidePermanentWater
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Provide permanent water
                  </span>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.WetlandEnhancementTypes?.MoistSoilManagement
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Moist soil management
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={`${shared.Column3} ${shared.TextRight}`}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.WetlandEnhancementTypes?.Other
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Other (describe)
                  </span>
                  <span className={shared.Column8}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.WetlandEnhancementTypesOther}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Other (describe)
                      </div>
                  </span>
                </div>
                <br/>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.WetlandEnhancementAdditionalInformation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Information
                      </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.HabitatProtection
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Habitat Protection for species of concern
                  </span>
                </div>
                <br/>
                <div className={shared.Row}>
                  <span className={shared.Column1SmallPadding}></span>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.HabitatProtectionTypes?.Fencing
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Fencing
                  </span>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.HabitatProtectionTypes?.Firebreaks
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Firebreaks
                  </span>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.HabitatProtectionTypes?.PrescribedBurning
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Prescribed Burning
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1SmallPadding}></span>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.HabitatProtectionTypes?.ControlOfNestParasites
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Control of nest parasites 
                  </span>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.HabitatProtectionTypes?.HabitatManipulation
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Habitat manipulation (thinning, etc.)
                  </span>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.HabitatProtectionTypes?.NativeExoticUngulateControl
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Native/exotic ungulate control
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={`${shared.Column3} ${shared.TextRight}`}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.HabitatProtectionTypes?.Other
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Other (describe)
                  </span>
                  <span className={shared.Column8}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.HabitatProtectionTypesOther}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Other (describe)
                      </div>
                  </span>
                </div>                  
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.HabitatProtectionAdditionalInformation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Information
                      </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.PrescribedControlOfSpecies
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Prescribed Control of Native, Exotic and Feral Species
                  </span>
                </div>
                <br/>
                <div className={shared.Row}>
                  <span className={shared.Column1SmallPadding}></span>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.PrescribedControlOfSpeciesTypes?.Vegetation
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Prescribed control of vegetation 
                  </span>
                  <span className={shared.Column6}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.PrescribedControlOfSpeciesTypes?.Animal
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Prescribed control of animal species
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.PrescribedControlOfSpeciesBeingControlled}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Species being controlled
                      </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.PrescribedControlOfSpeciesMethod}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Method of control
                      </div>
                  </span>
                </div> 
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.PrescribedControlOfSpeciesAdditionalInformation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Information
                      </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.WildlifeRestoration
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Wildlife Restoration
                  </span>
                </div>
                <br/>
                <div className={shared.Row}>
                  <span className={shared.Column1SmallPadding}></span>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.WildlifeRestorationTypes?.Habitat
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Habitat restoration 
                  </span>
                  <span className={shared.Column6}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionHabitatControl?.WildlifeRestorationTypes?.Wildlife
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Wildlife restoration
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.WildlifeRestorationTargetSpecies}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Target species
                      </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.WildlifeRestorationMethod}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Method of restoration
                      </div>
                  </span>
                </div> 
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionHabitatControl?.WildlifeRestorationAdditionalInformation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Information
                      </div>
                  </div>
                </div>
              </div>

              <div className={shared.SubStep}>
                2. EROSION CONTROL
              </div>

              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.PondConstruction
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Pond construction and repair
                  </span>
                </div>
                <br/>
                <div className={shared.Row}>
                  <span className={shared.Column6}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionErosionControl?.PondConstructionSurfaceArea}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Surface area (acres)
                      </div>
                  </span>
                  <span className={shared.Column6}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionErosionControl?.PondConstructionNumberOfCubicYardsOfSoilDisplaced}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Number of cubic yards of soil displaced
                      </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column6}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionErosionControl?.PondConstructionLengthOfDam}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Length of dam (feet)
                      </div>
                  </span>
                  <span className={shared.Column6}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionErosionControl?.PondConstructionPlannedDateOfConstruction}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Planned date of construction
                      </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionErosionControl?.PondConstructionAdditionalInformation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Information
                      </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.GullyShaping
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Gully Shaping
                  </span>
                </div>
                <br/>
                <div className={shared.Row}>
                  <span className={shared.Column6}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionErosionControl?.GullyShapingTotalAcresToBeTreated}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Total acres to be treated
                      </div>
                  </span>
                  <span className={shared.Column6}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionErosionControl?.GullyShapingAcresTreatedAnnually}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Acres treated annually
                      </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column6}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionErosionControl?.GullyShapingSeedingMixUsed}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Seeding mix used for reestablishment of vegetation
                      </div>
                  </span>
                  <span className={shared.Column6}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionErosionControl?.GullyShapingPlannedDateOfConstruction}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Planned date of construction
                      </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionErosionControl?.GullyShapingAdditionalInformation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Information
                      </div>
                  </div>
                </div>
              </div>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.StreamsidePondWetlandRevegetation
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Streamside, pond, and wetland revegetation
                  </span>
                </div>
                <br/>
                <div className={shared.Row}>                        
                  <span className={shared.Column12}>
                    Techniques used:
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1SmallPadding}></span>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.StreamsidePondWetlandRevegetationTechniquesUsed?.NativeHayBales
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Native Hay Bales
                  </span>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.StreamsidePondWetlandRevegetationTechniquesUsed?.Fencing
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Fencing
                  </span>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.StreamsidePondWetlandRevegetationTechniquesUsed?.FilterStrips
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Filter Strips
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1SmallPadding}></span>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.StreamsidePondWetlandRevegetationTechniquesUsed?.SeedingUplandBuffer
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Seeding Upland Buffer
                  </span>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.StreamsidePondWetlandRevegetationTechniquesUsed?.RipRap
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Rip-rap, etc.
                  </span>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.StreamsidePondWetlandRevegetationTechniquesUsed?.StreamCrossings
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Stream Crossings
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={`${shared.Column3} ${shared.TextRight}`}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.StreamsidePondWetlandRevegetationTechniquesUsed?.Other
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Other:
                  </span>
                  <span className={shared.Column8}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionErosionControl?.StreamsidePondWetlandRevegetationTechniquesUsedOther}
                      </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionErosionControl?.StreamsidePondWetlandRevegetationPlannedDateOfConstruction}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Planned date of construction
                      </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionErosionControl?.StreamsidePondWetlandRevegetationAdditionalInformation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Information
                      </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.HerbaceousPlantEstablishment
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Herbaceous and/or woody plant establishment on critical areas (erodible)
                  </span>
                </div>
                <br/>
                <div className={shared.Row}>
                  <span className={shared.Column1SmallPadding}></span>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.HerbaceousPlantEstablishmentTypes?.EstablishWindbreak
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Establish Windbreak
                  </span>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.HerbaceousPlantEstablishmentTypes?.EstablishShrubMottes
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Establish Shrub Mottes
                  </span>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.HerbaceousPlantEstablishmentTypes?.ImprovePlantDiversity
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Improve Plant Diversity
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1SmallPadding}></span>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.HerbaceousPlantEstablishmentTypes?.ImproveWildlifeHabitat
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Improve Wildlife Habitat
                  </span>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.HerbaceousPlantEstablishmentTypes?.ConservationNoTillPractices
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Conservation/No-till Practices
                  </span>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.HerbaceousPlantEstablishmentTypes?.ManageCrpCover
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Manage CRP Cover
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionErosionControl?.HerbaceousPlantEstablishmentAdditionalInformation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Information
                      </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.DikeLeveeConstructionManagement
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Dike/Levee Construction/Management
                  </span>
                </div>
                <br/>
                <div className={shared.Row}>
                  <span className={shared.Column1SmallPadding}></span>
                  <span className={shared.Column5}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.DikeLeveeConstructionManagementTypes?.ReshapingRepairingErosionDamage
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Reshaping/Repairing Erosion Damage 
                  </span>
                  <span className={shared.Column5}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.DikeLeveeConstructionManagementTypes?.RevegetatingStabilizeLeveeAreas
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Revegetating/Stabilize Levee Areas
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1SmallPadding}></span>
                  <span className={shared.Column5}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.DikeLeveeConstructionManagementTypes?.InstallWaterControlStructure
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Install Water Control Structure 
                  </span>
                  <span className={shared.Column5}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.DikeLeveeConstructionManagementTypes?.Fencing
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Fencing
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionErosionControl?.DikeLeveeConstructionManagementAdditionalInformation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Information
                      </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.EstablishWaterDiversion
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Establish Water Diversion 
                  </span>
                </div>
                <br/>
                <div className={shared.Row}>
                  <span className={shared.Column1}>
                    Type:
                  </span>
                  <span className={shared.Column2}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.EstablishWaterDiversionTypes?.Channel
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Channel
                  </span>
                  <span className={shared.Column5}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.EstablishWaterDiversionTypes?.Ridge
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Ridge
                  </span>
                  <span className={shared.Column3}></span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1}>
                    Slope:
                  </span>
                  <span className={shared.Column2}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.EstablishWaterDiversionSlope?.Level
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Level
                  </span>
                  <span className={shared.Column5}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.EstablishWaterDiversionSlope?.Graded
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Graded
                  </span>
                  <span className={shared.Column3}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionErosionControl?.EstablishWaterDiversionLength}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Length (feet)
                      </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column2}>
                    Vegetated:
                  </span>
                  <span className={shared.Column2}>
                    <i className={"fa " +
                      (!formDataFieldsFourthSectionErosionControl?.EstablishWaterDiversionVegetated == false
                        ? "fa-check-circle-o" : "fa-circle-o")}></i>
                    No
                  </span>
                  <span className={shared.Column2}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.EstablishWaterDiversionVegetated == true
                        ? "fa-check-circle-o" : "fa-circle-o")}></i>
                    Yes
                  </span>
                  <span className={shared.Column5}></span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1}>
                    If Yes:
                  </span>
                  <span className={`${shared.Column2NoPadding} ${shared.TextRight}`}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.EstablishWaterDiversionVegetatedType?.Native
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Native:
                  </span>
                  <span className={shared.Column3}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionErosionControl?.EstablishWaterDiversionNative}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Native
                      </div>
                  </span>
                  <span className={`${shared.Column2NoPadding} ${shared.TextRight}`}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionErosionControl?.EstablishWaterDiversionVegetatedType?.Crop
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Crop:
                  </span>
                  <span className={shared.Column3}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionErosionControl?.EstablishWaterDiversionCrop}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                      Crop
                      </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionErosionControl?.EstablishWaterDiversionAdditionalInformation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Information
                      </div>
                  </div>
                </div>
              </div>
              <br/>

              <div className={shared.SubStep}>
                3. PREDATOR CONTROL
              </div>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionPredatorControl?.PredatorControlImportedRedFireAnts
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Imported red fire ants (verify prior to application that product is labeled for pasture use) 
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionPredatorControl?.PredatorControlBirds?.Cowbirds
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Control of cowbirds
                  </span>
                  <span className={shared.Column5}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionPredatorControl?.PredatorControlBirds?.GrackleStarlingHouseSparrow
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Grackle/starling/house sparrow control 
                  </span>
                  <span className={shared.Column3}></span>
                </div>
                <div className={shared.Row}>
                  <span className={`${shared.Column2} ${shared.TextRight}`}>
                    Method of Control:
                  </span>
                  <span className={shared.Column2}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionPredatorControl?.PredatorControlBirdsControlMethods?.Trapping
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Trapping
                  </span>
                  <span className={shared.Column2}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionPredatorControl?.PredatorControlBirdsControlMethods?.Shooting
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Shooting
                  </span>
                  <span className={shared.Column5}></span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column2}></span>
                  <span className={shared.Column2}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionPredatorControl?.PredatorControlBirdsControlMethods?.Baiting
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Baiting
                  </span>
                  <span className={shared.Column2}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionPredatorControl?.PredatorControlBirdsControlMethods?.ScareTactics
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Scare Tactics
                  </span>
                  <span className={shared.Column5}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionPredatorControl?.PredatorControlBirdsControlMethodScareTactics}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Scare Tactics
                      </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column2}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionPredatorControl?.PredatorControlWildBeasts?.Coyotes
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Coyotes
                  </span>
                  <span className={shared.Column2}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionPredatorControl?.PredatorControlWildBeasts?.FeralHogs
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Feral Hogs
                  </span>
                  <span className={shared.Column2}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionPredatorControl?.PredatorControlWildBeasts?.Raccoon
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Raccoon
                  </span>
                  <span className={shared.Column2}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionPredatorControl?.PredatorControlWildBeasts?.Skunk
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Skunk
                  </span>
                  <span className={shared.Column3}></span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column2}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionPredatorControl?.PredatorControlWildBeasts?.Bobcat
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Bobcat
                  </span>
                  <span className={shared.Column2}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionPredatorControl?.PredatorControlWildBeasts?.MountainLion
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Mountain Lion
                  </span>
                  <span className={shared.Column2}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionPredatorControl?.PredatorControlWildBeasts?.RatSnakes
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Rat Snakes
                  </span>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionPredatorControl?.PredatorControlWildBeasts?.FeralCatsDogs
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Feral Cats/Dogs
                  </span>
                  <span className={shared.Column2}></span>
                </div>
                <div className={shared.Row}>
                  <span className={`${shared.Column2} ${shared.TextRight}`}>
                    Method of Control:
                  </span>
                  <span className={shared.Column2}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionPredatorControl?.PredatorControlWildBeastsControlMethods?.Trapping
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Trapping
                  </span>
                  <span className={shared.Column2}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionPredatorControl?.PredatorControlWildBeastsControlMethods?.Shooting
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Shooting
                  </span>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionPredatorControl?.PredatorControlWildBeastsControlMethods?.M44
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    M-44 (licensed applicators)
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column6}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionPredatorControl?.PredatorControlWildBeastsControlMethods?.PoisonCollars
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Poison collars (1080 certified, licensed, applicator)
                  </span>
                  <span className={shared.Column2NoPadding}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionPredatorControl?.PredatorControlWildBeastsControlMethods?.Other
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Other
                  </span>
                  <span className={shared.Column3}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionPredatorControl?.PredatorControlWildBeastsControlMethodOther}
                      </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionPredatorControl?.PredatorControlAdditionalInformation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Information
                      </div>
                  </div>
                </div>
              </div>
              <br/>
              
              <div className={shared.SubStep}>
                4. SUPPLEMENTAL WATER
              </div>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.MarshWetlandRestorationOrDevelopment
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Marsh/Wetland Restoration or Development
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column5}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.MarshWetlandRestorationOrDevelopmentTypes?.GreentreeReservoirs
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Greentree Reservoirs
                  </span>
                  <span className={shared.Column6}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.MarshWetlandRestorationOrDevelopmentTypes?.ShallowRoostPondDevelopment
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Shallow Roost Pond Development 
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionSupplementalWater?.MarshWetlandRestorationOrDevelopmentPlannedDateOfConstruction}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Planned date of construction
                      </div>
                  </div>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionSupplementalWater?.MarshWetlandRestorationOrDevelopmentAdditionalInformation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Information
                      </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflow
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Well/trough/windmill overflow/other wildlife watering facilities
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1NoPadding}></span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowDrillNewWell
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Drill New Well
                  </span>
                  <span className={shared.Column4}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowDrillNewWellDepth}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Depth
                    </div>
                  </span>
                  <span className={shared.Column4}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowDrillNewWellGallonsPerMinute}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Gallons per Minute
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1}></span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowDrillNewWellTypes?.Windmill
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Windmill
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowDrillNewWellTypes?.Pump
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Pump
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowDrillNewWellTypes?.Pipeline
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Pipeline:
                  </span>
                  <span className={shared.Column3}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowDrillNewWellSize}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Size
                    </div>
                  </span>
                  <span className={shared.Column3}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowDrillNewWellLength}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Length
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1NoPadding}></span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowModificationsOfExistingWaterSources
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Modification(s) of existing water source 
                  </span>
                  <span className={shared.Column6}></span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1}></span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowModificationsOfExistingWaterSourcesTypes?.Fencing
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Fencing
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowModificationsOfExistingWaterSourcesTypes?.Overflow
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Overflow
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowModificationsOfExistingWaterSourcesTypes?.TroughModification
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Trough Modification
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowModificationsOfExistingWaterSourcesTypes?.Pipeline
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Pipeline
                  </span>
                  <span className={shared.Column1}></span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column3}></span>
                  <span className={shared.Column8}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowModificationsOfExistingWaterSourcesDistanceBetweenWaterSources}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Distance between water sources (waterers)
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={`${shared.Column12} ${shared.TextLeft}`}>
                    Type of wildlife watering facility:
                  </span>
                </div>

                <div className={shared.Row}>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPvcPipeFacilityNumber
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    PVC pipe facility
                  </span>
                  <span className={shared.Column2NoPadding}>
                    <div className={shared.Value}>
                        # {formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPvcPipeFacilityNumber}
                    </div>
                  </span>
                  <span className={shared.Column1}></span>
                  <span className={shared.Column3NoPadding}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesDrumWithFaucetOrFloat
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Drum with faucet or float
                  </span>
                  <span className={shared.Column2}>
                    <div className={shared.Value}>
                        # {formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesDrumWithFaucetOrFloatNumber}
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesSmallGameGuzzler
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Small game guzzler
                  </span>
                  <span className={shared.Column2NoPadding}>
                    <div className={shared.Value}>
                        # {formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesSmallGameGuzzlerNumber}
                    </div>
                  </span>
                  <span className={shared.Column1}></span>
                  <span className={shared.Column3NoPadding}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesWindmillSupplyPipeDripper
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Windmill supply pipe dripper
                  </span>
                  <span className={shared.Column2}>
                    <div className={shared.Value}>
                        # {formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesWindmillSupplyPipeDripperNumber}
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPlasticContainer
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Plastic container
                  </span>
                  <span className={shared.Column2NoPadding}>
                    <div className={shared.Value}>
                        # {formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPlasticContainerNumber}
                    </div>
                  </span>
                  <span className={shared.Column1}></span>
                  <span className={shared.Column3NoPadding}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesIngroundBowlTrough
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    In-ground bowl trough
                  </span>
                  <span className={shared.Column2}>
                    <div className={shared.Value}>
                        # {formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesIngroundBowlTroughNumber}
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesBigGameGuzzler
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Big game guzzler
                  </span>
                  <span className={shared.Column2NoPadding}>
                    <div className={shared.Value}>
                        # {formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesBigGameGuzzlerNumber}
                    </div>
                  </span>
                  <span className={shared.Column1}></span>
                  <span className={shared.Column3NoPadding}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesInvertedUmbrellaGuzzler
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Inverted umbrella guzzler
                  </span>
                  <span className={shared.Column2}>
                    <div className={shared.Value}>
                        # {formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesInvertedUmbrellaGuzzlerNumber}
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesFlyingSaucerGuzzler
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Flying saucer guzzler
                  </span>
                  <span className={shared.Column2NoPadding}>
                    <div className={shared.Value}>
                        # {formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesFlyingSaucerGuzzlerNumber}
                    </div>
                  </span>
                  <span className={shared.Column1}></span>
                  <span className={shared.Column3NoPadding}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesRanchSpecialtiesGuzzler
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Ranch Specialties guzzler
                  </span>
                  <span className={shared.Column2}>
                    <div className={shared.Value}>
                        # {formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesRanchSpecialtiesGuzzlerNumber}
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesOther
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Other
                  </span>
                  <span className={shared.Column8}>
                    <div className={shared.Value}>
                        # {formDataFieldsFourthSectionSupplementalWater?.WellWindmillOverflowTypeOfWildlifeWateringFacilitiesOtherDescription}
                    </div>
                  </span>
                </div>

                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionSupplementalWater?.MarshWetlandRestorationOrDevelopmentAdditionalInformation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Information
                      </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.SpringDevelopmentEnhancement
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Spring development and/or enhancement
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1NoPadding}></span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.SpringDevelopmentEnhancementTypes?.Fencing
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Fencing
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.SpringDevelopmentEnhancementTypes?.WaterDiversionPipeline
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Water Diversion/Pipeline 
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.SpringDevelopmentEnhancementTypes?.BrushRemoval
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Brush Removal 
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.SpringDevelopmentEnhancementTypes?.SpringCleanOut
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Spring Clean Out 
                  </span>
                  <span className={shared.Column2}></span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column2}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalWater?.SpringDevelopmentEnhancementTypes?.Other
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Other:
                  </span>
                  <span className={shared.Column9}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalWater?.SpringDevelopmentEnhancementOther}
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionSupplementalWater?.SpringDevelopmentEnhancementAdditionalInformation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Information
                      </div>
                  </div>
                </div>
              </div>
              <br/>
              
              <div className={shared.SubStep}>
                5. PROVIDING SUPPLEMENTAL FOOD
              </div>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column1NoPadding}></span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalFood?.SupplementalFoodTypes?.GrazingManagement
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Grazing Management 
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalFood?.SupplementalFoodTypes?.PrescribedBurning
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Prescribed Burning 
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalFood?.SupplementalFoodTypes?.RangeEnhancement
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Range Enhancement 
                  </span>
                  <span className={shared.Column5}></span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column2}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalFood?.FoodPlots
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Food Plots 
                  </span>
                  <span className={shared.Column2}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalFood?.FoodPlotsSize}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Size
                    </div>
                  </span>
                  <span className={shared.Column1NoPadding}>
                    Fenced:
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalFood?.FoodPlotsFenced == true
                        ? "fa-check-circle-o" : "fa-circle-o")}></i>
                    Yes
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (!formDataFieldsFourthSectionSupplementalFood?.FoodPlotsFenced == false
                        ? "fa-check-circle-o" : "fa-circle-o")}></i>
                    No
                  </span>
                  <span className={shared.Column4}></span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column2}>
                    Irrigated:
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalFood?.Irrigated == true
                        ? "fa-check-circle-o" : "fa-circle-o")}></i>
                    Yes
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (!formDataFieldsFourthSectionSupplementalFood?.Irrigated == false
                        ? "fa-check-circle-o" : "fa-circle-o")}></i>
                    No
                  </span>
                  <span className={shared.Column8}></span>
                </div>
                <div className={shared.Row}>
                  <span className={`${shared.Column2} ${shared.TextRight}`}>
                    Plantings:
                  </span>
                  <span className={shared.Column4NoPadding}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalFood?.FoodPlotsPlantingsCoolSeasonAnnualCrops
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                     Cool Season Annual Crops 
                  </span>
                  <span className={shared.Column5}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalFood?.FoodPlotsPlantingsCoolSeasonAnnualCropsDescription}
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column2}></span>
                  <span className={shared.Column4NoPadding}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalFood?.FoodPlotsPlantingsWarmSeasonAnnualCrops
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                     Warm Season Annual Crops 
                  </span>
                  <span className={shared.Column5}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalFood?.FoodPlotsPlantingsWarmSeasonAnnualCropsDescription}
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column2}></span>
                  <span className={shared.Column4NoPadding}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalFood?.FoodPlotsPlantingsAnnualMixOfNativePlants
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                     Annual Mix of Native Plants 
                  </span>
                  <span className={shared.Column5}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalFood?.FoodPlotsPlantingsAnnualMixOfNativePlantsDescription}
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column2}></span>
                  <span className={shared.Column4NoPadding}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalFood?.FoodPlotsPlantingsPerennialMixOfNativePlants
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                     Perennial Mix of Native Plants 
                  </span>
                  <span className={shared.Column5}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalFood?.FoodPlotsPlantingsPerennialMixOfNativePlantsDescription}
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionSupplementalFood?.FoodPlotsAdditionalInformation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Information
                      </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalFood?.FeedersAndMineralSupplementation
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Feeders and Mineral Supplementation
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column2}>
                    Purpose: 
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalFood?.FeedersAndMineralSupplementationPurpose?.Supplementation
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Supplementation 
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalFood?.FeedersAndMineralSupplementationPurpose?.HarvestingOfWildlife
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Harvesting of Wildlife 
                  </span>
                  <span className={shared.Column6}></span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionSupplementalFood?.FeedersAndMineralSupplementationTargetedWildlifeSpecies}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Targeted Wildlife Species
                      </div>
                  </div>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column6}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionSupplementalFood?.FeedersAndMineralSupplementationFeedType}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Feed Type
                      </div>
                  </div>
                  <div className={shared.Column6}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionSupplementalFood?.FeedersAndMineralSupplementationMineralType}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Mineral Type
                      </div>
                  </div>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column6}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionSupplementalFood?.FeedersAndMineralSupplementationFeederType}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Feeder Type
                      </div>
                  </div>
                  <div className={shared.Column6}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionSupplementalFood?.FeedersAndMineralSupplementationNumberOfFeeders}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Number of Feeders
                      </div>
                  </div>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionSupplementalFood?.FeedersAndMineralSupplementationMethodOfMineralDispencing}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Method of Mineral Dispensing
                      </div>
                  </div>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionSupplementalFood?.FeedersAndMineralSupplementationNumberOfMineralLocations}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Number of Mineral Locations
                      </div>
                  </div>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column2}>
                    Year Round:
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalFood?.FeedersAndMineralSupplementationYearRound == true
                        ? "fa-check-circle-o" : "fa-circle-o")}></i>
                    Yes
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (!formDataFieldsFourthSectionSupplementalFood?.FeedersAndMineralSupplementationYearRound == false
                        ? "fa-check-circle-o" : "fa-circle-o")}></i>
                    No
                  </span>
                  <span className={shared.Column6}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalFood?.FeedersAndMineralSupplementationStateWhen}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      If not, state when
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionSupplementalFood?.FeedersAndMineralSupplementationAdditionalInformation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Information
                      </div>
                  </div>
                </div>
              </div>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalFood?.ManagingTamePasture
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Managing tame pasture, old fields and croplands
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1NoPadding}></span>
                  <span className={shared.Column8}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalFood?.ManagingTamePastureTypes?.OverseedingCoolWarmSeasonLegumesGrains
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Overseeding cool and/or warm season legumes and/or small grains 
                  </span>
                  <span className={shared.Column2}></span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1NoPadding}></span>
                  <span className={shared.Column6}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalFood?.ManagingTamePastureTypes?.PeriodicDisturbance
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Periodic disturbance (Discing/Mowing/Shredding) 
                  </span>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalFood?.ManagingTamePastureTypes?.ConservationNoTill
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Conservation/no-till 
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionSupplementalFood?.ManagingTamePastureAdditionalInformation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Information
                      </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalFood?.TransitionManagementOfTameGrass
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Transition management of tame grass monocultures
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1NoPadding}></span>
                  <span className={shared.Column8}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalFood?.TransitionManagementOfTameGrassOverseed25Percent
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Overseed 25% of tame grass pastures with locally adapted legumes 
                  </span>
                  <span className={shared.Column2}></span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1NoPadding}></span>
                  <span className={shared.Column2NoPadding}>
                    Species planted:
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalFood?.TransitionManagementOfTameGrassSpeciesPlanted?.Clover
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Clover 
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalFood?.TransitionManagementOfTameGrassSpeciesPlanted?.Peas
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Peas 
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalFood?.TransitionManagementOfTameGrassSpeciesPlanted?.Vetch
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Vetch 
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalFood?.TransitionManagementOfTameGrassSpeciesPlanted?.Other
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Other:
                  </span>
                  <span className={shared.Column3}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalFood?.TransitionManagementOfTameGrassSpeciesPlantedOther}
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                      <div className={shared.Value}>
                          {formDataFieldsFourthSectionSupplementalFood?.TransitionManagementOfTameGrassAdditionalInformation}
                      </div>
                      <div className={shared.ValueLabelBelow}>
                        Additional Information
                      </div>
                  </div>
                </div>
              </div>
              <br/>

              <div className={shared.SubStep}>
                6. PROVIDING SUPPLEMENTAL SHELTER
              </div>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.NestBoxes
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Nest Boxes
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalShelter?.NestBoxesTargetSpecies}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Target Species
                    </div>
                  </div>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1NoPadding}></span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.NestBoxesCavityType
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Cavity Type
                  </span>
                  <span className={shared.Column2NoPadding}>
                    <div className={shared.Value}>
                        # {formDataFieldsFourthSectionSupplementalShelter?.NestBoxesCavityTypeNumber}
                    </div>
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.NestBoxesBatBoxes
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Bat Boxes
                  </span>
                  <span className={shared.Column2NoPadding}>
                    <div className={shared.Value}>
                        # {formDataFieldsFourthSectionSupplementalShelter?.NestBoxesBatBoxesNumber}
                    </div>
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.NestBoxesRaptorPole
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Raptor Pole
                  </span>
                  <span className={shared.Column2}>
                    <div className={shared.Value}>
                        # {formDataFieldsFourthSectionSupplementalShelter?.NestBoxesRaptorPoleNumber}
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalShelter?.NestBoxesAdditionalInformation}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Additional Information
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.BrushPilesAndSlashRetention
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Brush Piles and Slash Retention
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={`${shared.Column1} ${shared.TextRight}`}>Type:</span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.BrushPilesAndSlashRetentionTypes?.Slash
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Slash
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.BrushPilesAndSlashRetentionTypes?.BrushPiles
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Brush Piles
                  </span>
                  <span className={shared.Column3}></span>
                  <span className={shared.Column4}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalShelter?.BrushPilesAndSlashRetentionNumberPerAcre}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Number Per Acre
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalShelter?.BrushPilesAndSlashRetentionAdditionalInformation}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Additional Information
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.FenceLineManagement
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Fence Line Management
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1NoPadding}></span>
                  <span className={shared.Column4}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalShelter?.FenceLineManagementLength}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Length
                    </div>
                  </span>
                  <span className={shared.Column3}>
                    Initial establishment:
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.FenceLineManagementInitialEstablishment == true
                        ? "fa-check-circle-o" : "fa-circle-o")}></i>
                    Yes
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (!formDataFieldsFourthSectionSupplementalShelter?.FenceLineManagementInitialEstablishment == false
                        ? "fa-check-circle-o" : "fa-circle-o")}></i>
                    No
                  </span>
                  <span className={shared.Column2}></span>
                </div>
                <div className={shared.Row}>
                  <span className={`${shared.Column3} ${shared.TextRight}`}>Plant type established:</span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.FenceLineManagementPlantTypesEstablished?.Trees
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Trees
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.FenceLineManagementPlantTypesEstablished?.Shrubs
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Shrubs
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.FenceLineManagementPlantTypesEstablished?.Forbs
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Forbs
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.FenceLineManagementPlantTypesEstablished?.Grasses
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Grasses
                  </span>
                  <span className={shared.Column3}></span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalShelter?.FenceLineManagementAdditionalInformation}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Additional Information
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column6}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.HayMeadowPastureAndCorplandManagement
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Hay meadow, pasture and cropland management for wildlife
                  </span>
                  <span className={shared.Column6}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalShelter?.HayMeadowPastureAndCorplandManagementAcresTreated}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Acres Treated
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={`${shared.Width20} ${shared.TextCenter}`}>Shelter establishment:</span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.HayMeadowPastureAndCorplandManagementShelterEstablishmentTypes?.RoadsideManagement
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Roadside Management
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.HayMeadowPastureAndCorplandManagementShelterEstablishmentTypes?.TerraceWindBreaks
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Terrace/Wind Breaks
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.HayMeadowPastureAndCorplandManagementShelterEstablishmentTypes?.FieldBorders
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Field Borders
                  </span>
                  <span className={shared.Column3}></span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Width20}></span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.HayMeadowPastureAndCorplandManagementShelterEstablishmentTypes?.Shelterbelts
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Shelterbelts
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.
                        HayMeadowPastureAndCorplandManagementShelterEstablishmentTypes?.ConservationReserveProgramLandsManagement
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Conservation Reserve Program lands management
                  </span>
                  <span className={shared.Column3}></span>
                </div>
                <div className={shared.Row}>
                  <span className={`${shared.Width20} ${shared.TextCenter}`}>Type of vegetation:</span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.HayMeadowPastureAndCorplandManagementVegetationTypes?.Annual
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Annual
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.HayMeadowPastureAndCorplandManagementVegetationTypes?.Perennial
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Perennial
                  </span>
                  <span className={shared.Column6}></span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column6}></span>
                  <span className={shared.Column6}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalShelter?.HayMeadowPastureAndCorplandManagementSpeciesAndPercentOfMixture}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Species and Percent of Mixture
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={`${shared.Column6} ${shared.TextLeft}`}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.DeferredMowing
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Deferred Mowing
                  </span>
                  <span className={shared.Column6}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalShelter?.DeferredMowingPeriodOfDeferment}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Period of Deferment
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={`${shared.Column6} ${shared.TextLeft}`}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.Mowing
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Mowing
                  </span>
                  <span className={shared.Column6}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalShelter?.MowingAcresMowedAnnually}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Acres Mowed Annually
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={`${shared.Column12} ${shared.TextLeft}`}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.NoTillMinimumTill
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    No till/Minimum till
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalShelter?.HalfCuttingTreesOrShrubsAdditionalInformation}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Additional Information
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.HalfCuttingTreesOrShrubs
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Half-cutting Trees or Shrubs
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column6}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalShelter?.HalfCuttingTreesOrShrubsAcreageToBeTreatedAnnually}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Acreage to Be Treated Annually
                    </div>
                  </span>
                  <span className={shared.Column6}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalShelter?.HalfCuttingTreesOrShrubsNumberOfHalfCutsAnnually}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Number of Half-cuts Annually
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalShelter?.HalfCuttingTreesOrShrubsAdditionalInformation}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Additional Information
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.WoodyPlantShrubEstablishment
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Woody Plant/Shrub Establishment
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1NoPadding}></span>
                  <span className={shared.Column2}>Pattern:</span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.WoodyPlantShrubEstablishmentPatterns?.Block
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Block
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.WoodyPlantShrubEstablishmentPatterns?.Mosaic
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Mosaic
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.WoodyPlantShrubEstablishmentPatterns?.Strips
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Strips
                  </span>
                  <span className={shared.Column6}>                    
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalShelter?.WoodyPlantShrubEstablishmentStripsWidth}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Width
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column8}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalShelter?.WoodyPlantShrubEstablishmentAcreageOrLengthEstablishedAnnually}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Acreage or Length Established Annually
                    </div>
                  </span>
                  <span className={shared.Column4}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalShelter?.WoodyPlantShrubEstablishmentSpacing}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Spacing
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalShelter?.WoodyPlantShrubEstablishmentShrubsTreeSpeciesUsed}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Shrub/Tree Species Used
                    </div>
                  </div>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalShelter?.WoodyPlantShrubEstablishmentAdditionalInformation}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Additional Information
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionSupplementalShelter?.NaturalCavitySnagDevelopment
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Natural Cavity/Snag Development
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column4}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalShelter?.NaturalCavitySnagDevelopmentSpeciesOfSnag}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Species of Snag
                    </div>
                  </span>
                  <span className={shared.Column4}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalShelter?.NaturalCavitySnagDevelopmentSizeOfSnags}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Size of Snags
                    </div>
                  </span>
                  <span className={shared.Column4}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalShelter?.NaturalCavitySnagDevelopmentNumberAcre}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Number/Acre
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionSupplementalShelter?.NaturalCavitySnagDevelopmentAdditionalInformation}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Additional Information
                    </div>
                  </div>
                </div>
              </div>
              <br/>

              <div className={shared.SubStep}>
                7. CENSUS
              </div>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column5}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.SpotlightCounts
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Spotlight Counts
                  </span>
                  <span className={shared.Column6}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.SpotlightCountsTargetedSpecies}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Targeted Species
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column6}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.SpotlightCountsLengthOfRoute}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Length of Route
                    </div>
                  </span>
                  <span className={shared.Column6}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.SpotlightCountsVisibilityOfRoute}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Visibility of Route
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column2}>
                      Dates (3 required)
                  </span>
                  <span className={shared.Column3NoPadding}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.SpotlightCountsDateA}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      A
                    </div>
                  </span>
                  <span className={shared.Column3NoPadding}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.SpotlightCountsDateB}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      B
                    </div>
                  </span>
                  <span className={shared.Column3}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.SpotlightCountsDateC}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      C
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.SpotlightCountsAdditionalInformation}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Additional Information
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column5}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.StandardizedIncidentalObservations
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Standardized Incidental Observations
                  </span>
                  <span className={shared.Column6}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.StandardizedIncidentalObservationsTargetedSpecies}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Targeted Species
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column2}>
                      Observations from:
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.StandardizedIncidentalObservationsObservationsFrom?.Feeders
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Feeders
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.StandardizedIncidentalObservationsObservationsFrom?.FoodPlots
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Food Plots
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.StandardizedIncidentalObservationsObservationsFrom?.Blinds
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Blinds
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.StandardizedIncidentalObservationsObservationsFrom?.Vehicle
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Vehicle
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.StandardizedIncidentalObservationsObservationsFrom?.Other
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Other
                  </span>
                  <div className={shared.Column4}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.StandardizedIncidentalObservationsObservationsFromOther}
                    </div>
                  </div>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column3}></span>
                  <span className={shared.Column8}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.StandardizedIncidentalObservationsDates}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Dates
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.StandardizedIncidentalObservationsAdditionalInformation}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Additional Information
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column6}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.StandCountsOfDeer
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Stand counts of deer (5 one hour counts per stand required)
                  </span>
                  <span className={shared.Column5}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.StandCountsOfDeerNumberOfStands}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Number of Stands
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column3}></span>
                  <span className={shared.Column8}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.StandCountsOfDeerDates}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Dates
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.StandCountsOfDeerAdditionalInformation}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Additional Information
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.AerialCounts
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Aerial Counts
                  </span>
                  <span className={shared.Column8}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.AerialCountsSpeciesCounted}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Species Counted
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1}></span>
                  <span className={shared.Column2}>
                      Type of survey:
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.AerialCountsSurveyType?.Helicopter
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Helicopter
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.AerialCountsSurveyType?.FixedWing
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Fixed-wing
                  </span>
                  <span className={shared.Column6}></span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column3}>
                      Percent of area surveyed:
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.AerialCountsPercentOfAreaSurveyed?.Total
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Total
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.AerialCountsPercentOfAreaSurveyed?.Fifty
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    50%
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.AerialCountsPercentOfAreaSurveyed?.Other
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Other:
                  </span>
                  <span className={shared.Column6}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.AerialCountsPercentOfAreaSurveyedOther}
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.AerialCountsAdditionalInformation}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Additional Information
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column4}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.TrackCounts
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Track Counts:
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.TrackCountsTypes?.Predators
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Predators
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.TrackCountsTypes?.Furbearers
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Furbearers
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.TrackCountsTypes?.Deer
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Deer
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.TrackCountsTypes?.Other
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Other:
                  </span>
                  <span className={shared.Column3}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.TrackCountsTypeOther}
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.TrackCountsAdditionalInformation}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Additional Information
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.DaylightDeerHerdCompositionCounts
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Daylight Deer Herd/Wildlife Composition Counts
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column2}>
                    Species:
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.DaylightDeerHerdCompositionCountsTypes?.Deer
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Deer
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.DaylightDeerHerdCompositionCountsTypes?.Turkey
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Turkey
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.DaylightDeerHerdCompositionCountsTypes?.Dove
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Dove
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.DaylightDeerHerdCompositionCountsTypes?.Quail
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Quail
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.DaylightDeerHerdCompositionCountsTypes?.Other
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Other:
                  </span>
                  <span className={shared.Column4}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.DaylightDeerHerdCompositionCountsTypeOther}
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.DaylightDeerHerdCompositionCountsAdditionalInformation}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Additional Information
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <br/>
              <br/>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column6}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.HarvestDataCollectionKeeping
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Harvest Data Collection/Record Keeping:
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.HarvestDataCollectionKeepingSpecies?.Deer
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Deer
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.HarvestDataCollectionKeepingSpecies?.GameBirds
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Game Birds
                  </span>
                  <span className={shared.Column4}></span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1}></span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.HarvestDataCollectionKeepingData?.Age
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Age
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.HarvestDataCollectionKeepingData?.Weight
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Weight
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.HarvestDataCollectionKeepingData?.Sex
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Sex
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.HarvestDataCollectionKeepingData?.AntlerData
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Antler Data
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.HarvestDataCollectionKeepingData?.HarvestDate
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Harvest Date
                  </span>
                  <span className={shared.Column1}></span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.HarvestDataCollectionKeepingAdditionalInformation}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Additional Information
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column12}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.BrowseUtilizationSurveys
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Browse utilization surveys (thirty 12-foot circular plots required)
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.BrowseUtilizationSurveysAdditionalInformation}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Additional Information
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column6}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.CensusOfEndangeredWildlife
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Census of endangered, threatened, or protected wildlife
                  </span>
                  <span className={shared.Column6}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.CensusOfEndangeredWildlifeSpecies}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Species
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1}></span>
                  <span className={shared.Column10}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.CensusOfEndangeredWildlifeMethodAndDates}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Method and Dates
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.CensusOfEndangeredWildlifeAdditionalInformation}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Additional Information
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column6}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.CensusOfNongameWildlife
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Census and monitoring of nongame wildlife species
                  </span>
                  <span className={shared.Column6}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.CensusOfNongameWildlifeSpecies}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Species
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1}></span>
                  <span className={shared.Column10}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.CensusOfNongameWildlifeMethodAndDates}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Method and Dates
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.CensusOfNongameWildlifeAdditionalInformation}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Additional Information
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className={classes.SubSection}>
                <div className={shared.Row}>
                  <span className={shared.Column3}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.MiscellaneousCounts
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Miscellaneous Counts:
                  </span>
                  <span className={shared.Column8}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.MiscellaneousCountsSpeciesBeingCounted}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Species Being Counted
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1}></span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.MiscellaneousCountsTypes?.RemoteDetection
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Remote detection (i.e., cameras)
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.MiscellaneousCountsTypes?.HahnLine
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Hahn (walking) line
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.MiscellaneousCountsTypes?.RoostCounts
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Roost counts
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.MiscellaneousCountsTypes?.BoomingGroundCounts
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Booming ground counts
                  </span>
                  <span className={shared.Column1}></span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1}></span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.MiscellaneousCountsTypes?.TimeAreaCounts
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Time/area counts
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.MiscellaneousCountsTypes?.SongbirdTransectsAndCounts
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Songbird transects and counts
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.MiscellaneousCountsTypes?.QuailCallAndCoveyCounts
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Quail call and covey counts
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.MiscellaneousCountsTypes?.PointCounts
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Point counts
                  </span>
                  <span className={shared.Column1}></span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1}></span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.MiscellaneousCountsTypes?.SmallMammalTraps
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Small mammal traps
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.MiscellaneousCountsTypes?.DriftFencesAndPitfallTraps
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Drift fences and pitfall traps
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.MiscellaneousCountsTypes?.BatDepartures
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Bat departures
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.MiscellaneousCountsTypes?.DoveCallCounts
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Dove call counts
                  </span>
                  <span className={shared.Column1}></span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column1}></span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.MiscellaneousCountsTypes?.ChachalacaCounts
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Chachalaca counts
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.MiscellaneousCountsTypes?.TurkeyHenPoultryCounts
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Turkey hen/poultry counts
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.MiscellaneousCountsTypes?.WaterfowlWaterBirdCounts
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Waterfowl/water bird counts
                  </span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.MiscellaneousCountsTypes?.AlligatorNestCensusCounts
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Alligator nest/census counts
                  </span>
                  <span className={shared.Column1}></span>
                </div>
                <div className={shared.Row}>
                  <span className={shared.Column2}></span>
                  <span className={shared.ColumnCheckbox}>
                    <i className={"fa " +
                      (formDataFieldsFourthSectionCensus?.MiscellaneousCountsTypes?.Other
                        ? "fa-check-square-o" : "fa-square-o")}></i>
                    Other:
                  </span>
                  <span className={shared.Column8}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.MiscellaneousCountsTypesOther}
                    </div>
                  </span>
                </div>
                <div className={shared.Row}>
                  <div className={shared.Column12}>
                    <div className={shared.Value}>
                        {formDataFieldsFourthSectionCensus?.MiscellaneousCountsAdditionalInformation}
                    </div>
                    <div className={shared.ValueLabelBelow}>
                      Additional Information
                    </div>
                  </div>
                </div>
              </div>
              <br/>


              <div className={shared.Step}>
                Part V. Attach copies of supporting documentation such as receipts, maps, photos, etc. Use
                additional pages if necessary.
              </div>
              <p>
                I certify that the above information provided by me is to the best of my knowledge and belief,
                true and complete. 
              </p>

              <div className={`${shared.Row} ${shared.SpaceBetween}`}>
                <div className={shared.Column8}>
                    <div className={shared.Value}>
                        {formDataFieldsSixthSection?.Signature}
                    </div>
                    <div className={`${shared.ValueLabelBelow} ${shared.TextCenter}`}>
                        Signature
                    </div>
                </div>
                <div className={shared.Column3}>
                    <div className={shared.Value}>
                        {formDataFieldsSixthSection?.SignatureDate ? 
                            new Date(formDataFieldsSixthSection?.SignatureDate).toDateString() : ""}
                    </div>
                    <div className={`${shared.ValueLabelBelow} ${shared.TextCenter}`}>Date</div>
                </div>
              </div>
              <br/>
              <br/>

              <div className={shared.Row}>
                <span className={shared.Column1NoPadding}></span>
                <span className={`${shared.Column10} ${classes.Bordered}`}>
                  <p className={classes.Disclaimer}>
                    Texas Parks and Wildlife does not maintain the information collected through this form. This 
                    completed form is only provided to the County Tax Appraiser. Please inquire with your County 
                    Central Appraisal District on any local laws concerning any information collected through this form. 
                  </p>
                </span> 
                <span className={shared.Column1NoPadding}></span>               
              </div>
          </div>
      </div>
    </>
  );
}
