import type { Dispatch } from "react";
import { createContext, useContext, useReducer } from "react";
import type { UserActionType } from "src/context/User/user-actions.type";
import type { initialStateType } from "./user-reducer";
import { initialState, UserReducer } from "./user-reducer";

const UserStateContext = createContext<{
  userState: initialStateType;
  userDispatch: Dispatch<UserActionType>;
}>({
  userState: initialState,
  userDispatch: () => null,
});

export function useUserContext(): {
  userState: initialStateType;
  userDispatch: Dispatch<UserActionType>;
} {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("UserStateContext must be used within a UserProvider");
  }

  return context;
}

export const UserProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [userState, userDispatch] = useReducer(UserReducer, initialState);

  return (
    <UserStateContext.Provider value={{ userState, userDispatch }}>
      {children as React.ReactNode}
    </UserStateContext.Provider>
  );
};
