import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "src/pages/Home/components/Forms/ExemptionPropertyDamagedForm/ExemptionPropertyDamaged.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, RadioGroupChangeEvent, RadioGroup, Input } from "@progress/kendo-react-inputs";
import { FormValidation } from "../InputValidator";
import { IExemptionPropertyDamagedFormFourthSection } from "src/interfaces/forms/ExemptionPropertyDamagedByDisasterForm/exemption-property-damaged-fourth-section.interface";
import { DatePickerChangeEvent, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Error, Label } from "@progress/kendo-react-labels";
import { StatesInput } from "../CustomInputs/StatesInput";
import { ZipCodeInput } from "../CustomInputs/ZipCodeInput";
import { CustomDatePicker } from "../CustomInputs/CustomDatePickers/CustomDatePicker";
import SectionTitle from "../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IExemptionPropertyDamagedFormFourthSection) => void;
    formDataFields?:  IExemptionPropertyDamagedFormFourthSection,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function ExemptionPropertyDamagedFormFourthSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, setFormIsValid, isAdminPreview } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator, setStyles } = FormValidation(formRef);
    const [physicalAddressStateInputValid, setPhysicalAddressStateInputValid] = useState<boolean>(false);
    const [physicalAddressZipCodeInputValid, setPhysicalAddressZipCodeInputValid] = useState<boolean>(false);
    const [dateOwnerIsValid, setDateOwnerIsValid] = useState<boolean>(false);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={4} name={"Questions About Your Property"}/>;
    
    const typeOfPropertyRadioButtonData = [
        { label: "Homestead", value: "Homestead" },
        { label: "Residential", value: "Residential" },
        { label: "Land", value: "Land" },
        { label: "Commercial", value: "Commercial" },
        { label: "Minerals", value: "Minerals" },
        { label: "Agricultural", value: "Agricultural" },
        { label: "Business Personal Property", value: "BusinessPersonalProperty" },
    ];

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string,
        group?: string
    )
    {
        if (field === "DatePropertyPurchased") {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value.toDateString()
            });
        } else if (group) {
            setFormDataFields({
                ...formDataFields,
                [group]: {
                    ...(formDataFields as any)?.[group],
                    [field]: event.value
                }
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }
    
    function changeValidator() {
        if (formRef.current) {
            const isValid = physicalAddressStateInputValid &&
                physicalAddressZipCodeInputValid &&
                dateOwnerIsValid &&
                formRef.current.checkValidity();
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if (!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [
        formDataFields,
        formRef.current,
        physicalAddressStateInputValid,
        physicalAddressZipCodeInputValid,
        dateOwnerIsValid
    ]);

    return (
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                <Label className={classes.Label}>
                                    Indicate type of property
                                </Label>
                                <div className={`${classes.inputsGroup} ${classes.fieldsGroupW100}`}>
                                    <RadioGroup
                                        name={"TypeOfProperty"}
                                        layout={"horizontal"}
                                        disabled={formSettings?.isReadOnly}
                                        data={typeOfPropertyRadioButtonData}
                                        onChange={e => changeValueHandler(e, "TypeOfProperty")}
                                        value={formDataFields?.TypeOfProperty}
                                    />
                                </div>
                            </div>
                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                <div className={classes.fieldsGroupW30}>
                                    <Label className={classes.Label}>
                                        Date you purchased this property
                                        {!formDataFields?.DatePropertyPurchased && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <CustomDatePicker
                                            fieldName={"DatePropertyPurchased"}
                                            required={true}
                                            disabled={formSettings?.isReadOnly}
                                            formDataFields={formDataFields}
                                            setFormDataFields={setFormDataFields}
                                            onlyYear={false}
                                            setIsValidField={setDateOwnerIsValid}
                                            validField={dateOwnerIsValid}
                                            defaultValueDate={formDataFields?.DatePropertyPurchased}
                                        />
                                    </div>
                                </div>
                                <div className={classes.fieldsGroupW60}>
                                    <Label className={classes.Label}>
                                        Type of Disaster (As stated in governor’s declaration)
                                        {!formDataFields?.TypeOfDisaster && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={true}
                                        name={"TypeOfDisaster"}
                                        placeholder={"Type of Disaster (As stated in governor’s declaration)"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.TypeOfDisaster}
                                        onChange={(e)=> changeValueHandler(e, "TypeOfDisaster")}
                                    />
                                    {!inputValidator("TypeOfDisaster") && (
                                        <Error >{validationMessage}</Error>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={`${classes.addressFields} ${classes.fieldsGroup}`}>
                                <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                    <p>Owner Information</p>
                                </div>
                                <div className={classes.itemFields}>
                                    <Label className={classes.Label}>
                                        Address Line 1
                                        {!formDataFields?.PhysicalAddressLine1 && (
                                            <i className={"fa fa-asterisk text-danger"}></i>
                                        )}
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"PhysicalAddressLine1"}
                                        placeholder={"Address Line 1"}
                                        required={true}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.PhysicalAddressLine1}
                                        onChange={(e)=> changeValueHandler(e, "PhysicalAddressLine1")}
                                    />
                                    {!inputValidator("PhysicalAddressLine1") && (
                                        <Error>{validationMessage}</Error>
                                    )}
                                </div>
                                <div className={classes.itemFields}>
                                    <Label className={classes.Label}>
                                        Address Line 2
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"PhysicalAddressLine2"}
                                        placeholder={"Address Line 2"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.PhysicalAddressLine2}
                                        onChange={(e)=> changeValueHandler(e, "PhysicalAddressLine2")}
                                    />
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={`${classes.fieldsGroupW30}`}>
                                        <Label className={classes.Label}>
                                            City
                                            {!formDataFields?.PhysicalAddressCity && (
                                                <i className={"fa fa-asterisk text-danger"}></i>
                                            )}
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            required={true}
                                            name={"PhysicalAddressCity"}
                                            placeholder={"City"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.PhysicalAddressCity}
                                            onChange={(e)=> changeValueHandler(e, "PhysicalAddressCity")}
                                        />
                                        {!inputValidator("PhysicalAddressCity") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                    <div className={`${classes.fieldsGroupW30}`}>
                                        <Label className={classes.Label}>
                                            State
                                            {!formDataFields?.PhysicalAddressState && (
                                                <i className={"fa fa-asterisk text-danger"}></i>
                                            )}
                                        </Label>
                                        <StatesInput
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"PhysicalAddressState"}
                                            required={true}
                                            validField={physicalAddressStateInputValid}
                                            setIsValidField={setPhysicalAddressStateInputValid}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                        />
                                        {!inputValidator("PhysicalAddressState") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                    <div className={`${classes.fieldsGroupW20}`}>
                                        <Label className={classes.Label}>
                                            Zip Code
                                            {!formDataFields?.PhysicalAddressZip && (
                                                <i className={"fa fa-asterisk text-danger"}></i>
                                            )}
                                        </Label>
                                        <ZipCodeInput
                                            setFormDataFields={setFormDataFields}
                                            fieldName={"PhysicalAddressZip"}
                                            placeholder={"Zip"}
                                            required={true}
                                            formDataFields={formDataFields}
                                            isReadOnly={formSettings?.isReadOnly}
                                            setIsValidField={setPhysicalAddressZipCodeInputValid}
                                            validField={physicalAddressZipCodeInputValid}
                                        />
                                        {!inputValidator("PhysicalAddressZip") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${classes.item} ${classes.bordered}`}>
                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                <div className={classes.fieldsGroupW30}>
                                    <Label className={classes.Label}>
                                        Manufactured Home Make
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={false}
                                        name={"ManufacturedHomeMake"}
                                        placeholder={"Manufactured Home Make"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.ManufacturedHomeMake}
                                        onChange={(e)=> changeValueHandler(e, "ManufacturedHomeMake")}
                                    />
                                </div>
                                <div className={classes.fieldsGroupW30}>
                                    <Label className={classes.Label}>
                                        Model
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={false}
                                        name={"Model"}
                                        placeholder={"Model"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.Model}
                                        onChange={(e)=> changeValueHandler(e, "Model")}
                                    />
                                </div>
                                <div className={classes.fieldsGroupW30}>
                                    <Label className={classes.Label}>
                                        ID Number
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={false}
                                        name={"IdNumber"}
                                        placeholder={"ID Number"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.IdNumber}
                                        onChange={(e)=> changeValueHandler(e, "IdNumber")}
                                    />
                                </div>
                            </div>
                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                <div className={classes.fieldsGroupW50}>
                                    <Label className={classes.Label}>
                                        Number of Acres Own and Occupy as Principal Residence
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={false}
                                        name={"Name"}
                                        placeholder={"Acres (not to exceed 20 acres)"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.NumberOfAcres}
                                        onChange={(e)=> changeValueHandler(e, "NumberOfAcres")}
                                    />
                                </div>
                                <div className={classes.fieldsGroupW50}>
                                    <Label className={classes.Label}>
                                        Appraisal District Account Number (if known)
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        required={false}
                                        name={"AppraisalDistrictAccountNumber"}
                                        placeholder={"Appraisal District Account Number (if known)"}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.AppraisalDistrictAccountNumber}
                                        onChange={(e)=> changeValueHandler(e, "AppraisalDistrictAccountNumber")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    )
}