export type IFreeportFormSecondSectionInterface = {
    NotFilingOnOwnBehalf?: boolean;
    AuthorizedRepresentativeType?: string;
    AuthorizedRepresentativeTypeOther?: string;
    AuthorizedRepresentativeName?: string;
    AuthorizedRepresentativeTitle?: string;
    AuthorizedRepresentativePersonalId?: string;
    AuthorizedRepresentativeEmail?: string;
    AuthorizedRepresentativePrimaryPhoneNumber?: string;
    AuthorizedRepresentativeMailingAddressLine1?: string;
    AuthorizedRepresentativeMailingAddressLine2?: string;
    AuthorizedRepresentativeMailingAddressCity?: string;
    AuthorizedRepresentativeMailingAddressState?: string;
    AuthorizedRepresentativeMailingAddressZip?: string;
}

export const FreeportFormSecondSectionInterface = (): IFreeportFormSecondSectionInterface => ({
    NotFilingOnOwnBehalf: undefined,
    AuthorizedRepresentativeType: undefined,
    AuthorizedRepresentativeTypeOther: undefined,
    AuthorizedRepresentativeName: undefined,
    AuthorizedRepresentativeTitle: undefined,
    AuthorizedRepresentativePersonalId: undefined,
    AuthorizedRepresentativeEmail: undefined,
    AuthorizedRepresentativePrimaryPhoneNumber: undefined,
    AuthorizedRepresentativeMailingAddressLine1: undefined,
    AuthorizedRepresentativeMailingAddressLine2: undefined,
    AuthorizedRepresentativeMailingAddressCity: undefined,
    AuthorizedRepresentativeMailingAddressState: undefined,
    AuthorizedRepresentativeMailingAddressZip: undefined
})