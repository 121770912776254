import { IStandardizedIncidentalObservationsTypes,
    IAerialCountsSurveyTypes,
    IAerialCountsPercentOfAreaSurveyed,
    ITrackCountsTypes,
    IDaylightDeerHeerCompositionCountsSpecies,
    IHarvestDataCollectionKeepingSpecies,
    IHarvestDataCollectionKeepingData,
    IMiscellaneousCountsTypes} from "./census.interface-types";

export interface IWildlifeManagementFourthSectionCensus {
    SpotlightCounts?: boolean;
    SpotlightCountsTargetedSpecies?: string;
    SpotlightCountsLengthOfRoute?: string;
    SpotlightCountsVisibilityOfRoute?: string;
    SpotlightCountsDateA?: string;
    SpotlightCountsDateB?: string;
    SpotlightCountsDateC?: string;
    SpotlightCountsAdditionalInformation?: string;
    StandardizedIncidentalObservations?: boolean;
    StandardizedIncidentalObservationsTargetedSpecies?: string;
    StandardizedIncidentalObservationsObservationsFrom?: IStandardizedIncidentalObservationsTypes;
    StandardizedIncidentalObservationsObservationsFromOther?: string;
    StandardizedIncidentalObservationsDates?: string;
    StandardizedIncidentalObservationsAdditionalInformation?: string;
    StandCountsOfDeer?: boolean;
    StandCountsOfDeerNumberOfStands?: number;
    StandCountsOfDeerDates?: string;
    StandCountsOfDeerAdditionalInformation?: string;
    AerialCounts?: boolean;
    AerialCountsSpeciesCounted?: string;
    AerialCountsSurveyType?: IAerialCountsSurveyTypes;
    AerialCountsPercentOfAreaSurveyed?: IAerialCountsPercentOfAreaSurveyed;
    AerialCountsPercentOfAreaSurveyedOther?: string;
    AerialCountsAdditionalInformation?: string;
    TrackCounts?: boolean;
    TrackCountsTypes?: ITrackCountsTypes;
    TrackCountsTypeOther?: string;
    TrackCountsAdditionalInformation?: string;
    DaylightDeerHerdCompositionCounts?: boolean;
    DaylightDeerHerdCompositionCountsTypes?: IDaylightDeerHeerCompositionCountsSpecies;
    DaylightDeerHerdCompositionCountsTypeOther?: string;
    DaylightDeerHerdCompositionCountsAdditionalInformation?: string;
    HarvestDataCollectionKeeping?: boolean;
    HarvestDataCollectionKeepingSpecies?: IHarvestDataCollectionKeepingSpecies;
    HarvestDataCollectionKeepingData?: IHarvestDataCollectionKeepingData;
    HarvestDataCollectionKeepingAdditionalInformation?: string;
    BrowseUtilizationSurveys?: boolean;
    BrowseUtilizationSurveysAdditionalInformation?: string;
    CensusOfEndangeredWildlife?: boolean;
    CensusOfEndangeredWildlifeSpecies?: string;
    CensusOfEndangeredWildlifeMethodAndDates?: string;
    CensusOfEndangeredWildlifeAdditionalInformation?: string;
    CensusOfNongameWildlife?: boolean;
    CensusOfNongameWildlifeSpecies?: string;
    CensusOfNongameWildlifeMethodAndDates?: string;
    CensusOfNongameWildlifeAdditionalInformation?: string;
    MiscellaneousCounts?: boolean;
    MiscellaneousCountsSpeciesBeingCounted?: string;
    MiscellaneousCountsTypes?: IMiscellaneousCountsTypes;
    MiscellaneousCountsTypesOther?: string;
    MiscellaneousCountsAdditionalInformation?: string;
}

export const WildlifeManagementFourthSectionCensusInterface = (): IWildlifeManagementFourthSectionCensus => ({
    SpotlightCounts: undefined,
    SpotlightCountsTargetedSpecies: undefined,
    SpotlightCountsLengthOfRoute: undefined,
    SpotlightCountsVisibilityOfRoute: undefined,
    SpotlightCountsDateA: undefined,
    SpotlightCountsDateB: undefined,
    SpotlightCountsDateC: undefined,
    SpotlightCountsAdditionalInformation: undefined,
    StandardizedIncidentalObservations: undefined,
    StandardizedIncidentalObservationsTargetedSpecies: undefined,
    StandardizedIncidentalObservationsObservationsFrom: undefined,
    StandardizedIncidentalObservationsObservationsFromOther: undefined,
    StandardizedIncidentalObservationsDates: undefined,
    StandardizedIncidentalObservationsAdditionalInformation: undefined,
    StandCountsOfDeer: undefined,
    StandCountsOfDeerNumberOfStands: undefined,
    StandCountsOfDeerDates: undefined,
    StandCountsOfDeerAdditionalInformation: undefined,
    AerialCounts: undefined,
    AerialCountsSpeciesCounted: undefined,
    AerialCountsSurveyType: undefined,
    AerialCountsPercentOfAreaSurveyed: undefined,
    AerialCountsPercentOfAreaSurveyedOther: undefined,
    AerialCountsAdditionalInformation: undefined,
    TrackCounts: undefined,
    TrackCountsTypes: undefined,
    TrackCountsTypeOther: undefined,
    TrackCountsAdditionalInformation: undefined,
    DaylightDeerHerdCompositionCounts: undefined,
    DaylightDeerHerdCompositionCountsTypes: undefined,
    DaylightDeerHerdCompositionCountsTypeOther: undefined,
    DaylightDeerHerdCompositionCountsAdditionalInformation: undefined,
    HarvestDataCollectionKeeping: undefined,
    HarvestDataCollectionKeepingSpecies: undefined,
    HarvestDataCollectionKeepingData: undefined,
    HarvestDataCollectionKeepingAdditionalInformation: undefined,
    BrowseUtilizationSurveys: undefined,
    BrowseUtilizationSurveysAdditionalInformation: undefined,
    CensusOfEndangeredWildlife: undefined,
    CensusOfEndangeredWildlifeSpecies: undefined,
    CensusOfEndangeredWildlifeMethodAndDates: undefined,
    CensusOfEndangeredWildlifeAdditionalInformation: undefined,
    CensusOfNongameWildlife: undefined,
    CensusOfNongameWildlifeSpecies: undefined,
    CensusOfNongameWildlifeMethodAndDates: undefined,
    CensusOfNongameWildlifeAdditionalInformation: undefined,
    MiscellaneousCounts: undefined,
    MiscellaneousCountsSpeciesBeingCounted: undefined,
    MiscellaneousCountsTypes: undefined,
    MiscellaneousCountsTypesOther: undefined,
    MiscellaneousCountsAdditionalInformation: undefined
})