import instance from "src/axios-instance";
import { ISettings } from "src/interfaces/settings.interface";

export function getSettings(): any {
  return instance.get("settings");
}

export function updateSettings(settings: ISettings): any {
  return instance.put("settings", settings);
}
