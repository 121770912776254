import { IBrushPilesAndSlashRetentionTypes,
    IFenceLineManagementPlantTypesEstablished,
    IHayMeadowManagementShelterEstablishmentTypes,
    IHayMeadowManagementVegetationTypes,
    IWoodyPlantShrubEstablishmentPatterns } from "./providing-supplemental-shelter.interface-types";

export interface IWildlifeManagementFourthSectionSupplementalShelter {
    NestBoxes?: boolean;
    NestBoxesTargetSpecies?: string;
    NestBoxesCavityType?: boolean;
    NestBoxesCavityTypeNumber?: number;
    NestBoxesBatBoxes?: boolean;
    NestBoxesBatBoxesNumber?: number;
    NestBoxesRaptorPole?: boolean;
    NestBoxesRaptorPoleNumber?: number;
    NestBoxesAdditionalInformation?: string;
    BrushPilesAndSlashRetention?: boolean;
    BrushPilesAndSlashRetentionTypes?: IBrushPilesAndSlashRetentionTypes;
    BrushPilesAndSlashRetentionNumberPerAcre?: number;
    BrushPilesAndSlashRetentionAdditionalInformation?: string;
    FenceLineManagement?: boolean;
    FenceLineManagementLength?: string;
    FenceLineManagementInitialEstablishment?: boolean;
    FenceLineManagementPlantTypesEstablished?: IFenceLineManagementPlantTypesEstablished;
    FenceLineManagementAdditionalInformation?: string;
    HayMeadowPastureAndCorplandManagement?: boolean;
    HayMeadowPastureAndCorplandManagementAcresTreated?: string;
    HayMeadowPastureAndCorplandManagementShelterEstablishmentTypes?: IHayMeadowManagementShelterEstablishmentTypes;
    HayMeadowPastureAndCorplandManagementVegetationTypes?: IHayMeadowManagementVegetationTypes;
    HayMeadowPastureAndCorplandManagementSpeciesAndPercentOfMixture?: string;
    HayMeadowPastureAndCorplandManagementAdditionalInformation?: string;
    DeferredMowing?: boolean;
    DeferredMowingPeriodOfDeferment?: string;
    Mowing?: boolean;
    MowingAcresMowedAnnually?: number;
    NoTillMinimumTill?: boolean;
    HalfCuttingTreesOrShrubs?: boolean;
    HalfCuttingTreesOrShrubsAcreageToBeTreatedAnnually?: number;
    HalfCuttingTreesOrShrubsNumberOfHalfCutsAnnually?: number;
    HalfCuttingTreesOrShrubsAdditionalInformation?: string;
    WoodyPlantShrubEstablishment?: boolean;
    WoodyPlantShrubEstablishmentPatterns?: IWoodyPlantShrubEstablishmentPatterns;
    WoodyPlantShrubEstablishmentStripsWidth?: string;
    WoodyPlantShrubEstablishmentAcreageOrLengthEstablishedAnnually?: string;
    WoodyPlantShrubEstablishmentSpacing?: string;
    WoodyPlantShrubEstablishmentShrubsTreeSpeciesUsed?: string;
    WoodyPlantShrubEstablishmentAdditionalInformation?: string;
    NaturalCavitySnagDevelopment?: boolean;
    NaturalCavitySnagDevelopmentSpeciesOfSnag?: string;
    NaturalCavitySnagDevelopmentSizeOfSnags?: string;
    NaturalCavitySnagDevelopmentNumberAcre?: string;
    NaturalCavitySnagDevelopmentAdditionalInformation?: string;
}

export const WildlifeManagementFourthSectionSupplementalShelterInterface = (): IWildlifeManagementFourthSectionSupplementalShelter => ({
    NestBoxes: undefined,
    NestBoxesTargetSpecies: undefined,
    NestBoxesCavityType: undefined,
    NestBoxesCavityTypeNumber: undefined,
    NestBoxesBatBoxes: undefined,
    NestBoxesBatBoxesNumber: undefined,
    NestBoxesRaptorPole: undefined,
    NestBoxesRaptorPoleNumber: undefined,
    NestBoxesAdditionalInformation: undefined,
    BrushPilesAndSlashRetention: undefined,
    BrushPilesAndSlashRetentionTypes: undefined,
    BrushPilesAndSlashRetentionNumberPerAcre: undefined,
    BrushPilesAndSlashRetentionAdditionalInformation: undefined,
    FenceLineManagement: undefined,
    FenceLineManagementLength: undefined,
    FenceLineManagementInitialEstablishment: undefined,
    FenceLineManagementPlantTypesEstablished: undefined,
    FenceLineManagementAdditionalInformation: undefined,
    HayMeadowPastureAndCorplandManagement: undefined,
    HayMeadowPastureAndCorplandManagementAcresTreated: undefined,
    HayMeadowPastureAndCorplandManagementShelterEstablishmentTypes: undefined,
    HayMeadowPastureAndCorplandManagementVegetationTypes: undefined,
    HayMeadowPastureAndCorplandManagementSpeciesAndPercentOfMixture: undefined,
    HayMeadowPastureAndCorplandManagementAdditionalInformation: undefined,
    DeferredMowing: undefined,
    DeferredMowingPeriodOfDeferment: undefined,
    Mowing: undefined,
    MowingAcresMowedAnnually: undefined,
    NoTillMinimumTill: undefined,
    HalfCuttingTreesOrShrubs: undefined,
    HalfCuttingTreesOrShrubsAcreageToBeTreatedAnnually: undefined,
    HalfCuttingTreesOrShrubsNumberOfHalfCutsAnnually: undefined,
    HalfCuttingTreesOrShrubsAdditionalInformation: undefined,
    WoodyPlantShrubEstablishment: undefined,
    WoodyPlantShrubEstablishmentPatterns: undefined,
    WoodyPlantShrubEstablishmentStripsWidth: undefined,
    WoodyPlantShrubEstablishmentAcreageOrLengthEstablishedAnnually: undefined,
    WoodyPlantShrubEstablishmentSpacing: undefined,
    WoodyPlantShrubEstablishmentShrubsTreeSpeciesUsed: undefined,
    WoodyPlantShrubEstablishmentAdditionalInformation: undefined,
    NaturalCavitySnagDevelopment: undefined,
    NaturalCavitySnagDevelopmentSpeciesOfSnag: undefined,
    NaturalCavitySnagDevelopmentSizeOfSnags: undefined,
    NaturalCavitySnagDevelopmentNumberAcre: undefined,
    NaturalCavitySnagDevelopmentAdditionalInformation: undefined
})