import type { ISettings } from "src/interfaces/settings.interface";

const SETTINGS_KEY = "settings";

interface SettingsType {
  settings: ISettings;
  clearSettings: () => void;
  setSettings: (setting: ISettings) => void;
}

export const settingsService = (): SettingsType => {
  const settings: ISettings = localStorage.getItem(SETTINGS_KEY)
    ? JSON.parse(localStorage.getItem(SETTINGS_KEY) ?? "")
    : null;

  const clearSettings = (): void => localStorage.removeItem(SETTINGS_KEY);

  function setSettings(settings: ISettings) {
    localStorage.setItem(SETTINGS_KEY, JSON.stringify(settings));
  }

  return {
    settings,
    clearSettings,
    setSettings,
  };
};
