export interface ChangeValueHandlerType{
    eventValue: string,
    setErrors: (item: ErrorState) => void,
    errors: ErrorState,
    required: boolean
}

export interface ErrorState{
    required: boolean,
    pattern: boolean,
}

export const PhoneNumberInputMask = "999-999-9999";
export const PhoneNumberInputPattern = /^(([0-9])){3}-(([0-9])){3}-(([0-9])){4}$/i;

export const PhoneNumberInputErrorsHandler = (props: ChangeValueHandlerType) => {
    const { eventValue, errors, setErrors, required } = props;

    if(!eventValue && required){
        setErrors({ pattern: true, required: true });
    } else if(!eventValue.replace(/[-_]/g, "") && !required){
        setErrors({ pattern: false, required: false });
    } else if (!PhoneNumberInputPattern.test(eventValue)){
        setErrors({ pattern: true, required: true });
    } else {
        setErrors({ pattern: false, required: false });
    }
}
