import { IBPPFormPersonalPropertySegmentsInterface } from "src/interfaces/forms/BPPForm/bpp-form-personal-property-segments.interface";
import shared from "../PDF-Shared-style.module.scss";
import classes from "./BPP-Form-PDF-style.module.scss";
import { BPPFormAssetsTableTotals } from "src/pages/Home/components/Forms/BPPForm/BPPAssets/BPPFormAssetsTableTotals";
import { GetBlankRows } from "../BlankRows";

function getStyles(item: IBPPFormPersonalPropertySegmentsInterface) {
  return item?.removed
    ? classes.AssetRemoved
    : item?.isNew
    ? classes.AssetAdded
    : item?.changed
    ? classes.AssetChanged
    : "";
}

export const BppPdfAssetsTable = (props: {
  assets: IBPPFormPersonalPropertySegmentsInterface[];
}) => {
  const { assets } = props;
  return (
    <>
      <div className={`keepTogether ${shared.Row} ${classes.ScheduleSection}`}>
        <table className={shared.Blue}>
          <thead>
            <tr>
              <th style={{ width: '20%'}}>Asset Type</th>
              <th style={{ width: '20%'}}>Asset Description</th>
              <th style={{ width: '15%'}}>Last Year Market Value</th>
              <th style={{ width: '7%'}}>Asset Year Acquired</th>
              <th style={{ width: '15%'}}>Historical Cost</th>
              <th style={{ width: '15%'}}>Good Faith Estimate</th>
            </tr>
          </thead>
          <tbody>
            {assets
              .filter((x) => !x.HasActiveSubSegments)
              ?.map((item) => {
                return (
                  <tr className={getStyles(item)}>
                    <td>{item.PersonalPropertyType}</td>
                    <td>{item.Description}</td>
                    <td>{item.LastYearAssetValue}</td>
                    <td>{item.YearAcquired}</td>
                    <td>{item.HistoricCost}</td>
                    <td>{item.MarketValue}</td>
                  </tr>
                );
              })}
            {GetBlankRows(assets.length, 3)}
          </tbody>
        </table>
      </div>
      <div className={`keepTogether ${shared.Row} ${classes.ScheduleSection}`}>
        <BPPFormAssetsTableTotals
          className={`${classes.AssetTotals} ${shared.Column12}`}
          assets={assets}
        />
      </div>
    </>
  );
};
