import classes from '../../BPP-Form-PDF-style.module.scss';
import shared from '../../../PDF-Shared-style.module.scss';
import { IBppFormEleventhSectionInterface } from 'src/interfaces/forms/BPPForm/bpp-form-eleventh-section.interface';
import { ScheduleETable } from './ScheduleETable';
import { chunkArray } from 'src/utils/array';
import { ScheduleETableOther } from './ScheduleEOtherTable';

const maxRowsPerPage = 36;

export const ScheduleETable2 = (props: {
  formDataFieldsEleventhSection?: IBppFormEleventhSectionInterface
}) => {
  const { formDataFieldsEleventhSection } = props;

  const computerEquipment = chunkArray(formDataFieldsEleventhSection?.["ScheduleE-ComputerEquipment"] || [], maxRowsPerPage);
  const manufacturing = chunkArray(formDataFieldsEleventhSection?.["ScheduleE-ManufacturingEquipment"] || [], maxRowsPerPage);
  const other = chunkArray(formDataFieldsEleventhSection?.["ScheduleE-Other"] || [], maxRowsPerPage);
  const numberOfTables = Math.max(computerEquipment.length, manufacturing.length, other.length);

  const primaryTable = {
    computerEquipment: computerEquipment[0],
    manufacturing: manufacturing[0],
    other: other[0],
  }

  const additionalTables: Partial<typeof primaryTable>[] = [];

  for (let i = 1; i < numberOfTables; i++) {
    additionalTables.push({
      computerEquipment: computerEquipment[i],
      manufacturing: manufacturing[i],
      other: other[i],
    })
  }

  return (
    <>
      <div className={`keepTogether ${classes.ScheduleSection} ${shared.PrintTogether}`}>
        <div className={shared.Row}>
          <div className={`${shared.Column12} ${classes.TablesContainer}`}>
            <ScheduleETable
              group="ScheduleE-ComputerEquipment"
              name="Computer Equipment"
              rows={primaryTable.computerEquipment}
            />

            <ScheduleETable
              group="ScheduleE-ManufacturingEquipment"
              name="POS/Servers/Mainframes"
              rows={primaryTable.manufacturing}
            />

            <ScheduleETableOther
              name="Other (any other items not listed in other schedules)"
              rows={primaryTable.other}
            />
          </div>
        </div>
      </div>

      {additionalTables.map((table) =>
        <div className={`keepTogether ${classes.ScheduleSection} ${shared.PrintTogether}`}>
          <div className={shared.Row}>
            <div className={`${shared.Column12} ${classes.TablesContainer}`}>
              <ScheduleETable
                group="ScheduleE-FurnitureFixtures"
                name="Furniture and Fixtures"
                rows={table.computerEquipment || []}
              />

              <ScheduleETable
                group="ScheduleE-MachineryEquipment"
                name="Machinery and Equipment"
                rows={table.manufacturing || []}
              />

              <ScheduleETable
                group="ScheduleE-OfficeEquipment"
                name="Office Equipment"
                rows={table.other || []}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
