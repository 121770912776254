import {
  Log,
  User,
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from "oidc-client";
import { globalConfig } from "../configuration/config";
import jwt from "jwt-decode";
import type { User as UserInterface } from "src/interfaces/user.interface";
import SignalrService from "./signalr.service";

export interface UserTokenInfo extends UserInterface {
  numeric_id: number;
}

export default class AuthService {
  static instance: AuthService;

  private _user: UserTokenInfo | undefined;
  private _userManager: UserManager | undefined;
  private signalrService = SignalrService.getInstance();

  static getInstance() {
    if (AuthService.instance == null) {
      AuthService.instance = new AuthService();
    }

    return this.instance;
  }

  public authData: User | null = null;

  public async getToken(): Promise<string | undefined> {
    const user = await this.userManager.getUser();
    if (!user) {
      try {
        return await this.userManager.signinSilent().then((renewedUser) => {
          this.authData = renewedUser;
          return renewedUser?.access_token;
        });
      } catch {
        return undefined;
      }
    } else {
      this.authData = user;
      return user.access_token;
    }
  }

  public async getUserData(): Promise<User | null> {
    this.authData = await this.userManager.getUser();
    return this.authData;
  }

  public login(returnUrl?: string): Promise<void> {
    let redirectUri = this.userManager.settings.redirect_uri;

    if (returnUrl?.includes("forceReturn")) {
      redirectUri = `${redirectUri}?forceReturn=true&forceReturnUrl=${encodeURI(
        returnUrl
      )}`;
    }

    return this.userManager.signinRedirect({ redirect_uri: redirectUri });
  }

  public logout(): Promise<void> {
    const idToken = localStorage.getItem("id_token");
    this._user = undefined;
    this.authData = null;
    this.signalrService.stop();
    return this.userManager.signoutRedirect({ id_token_hint: idToken });
  }

  public clearUser = (): void => {
    this._user = undefined;
    this.authData = null;
  };

  public setUserFromOidc(user: User): void {
    this.authData = user;
    const userInfo = jwt(user.access_token) as UserTokenInfo;
    this.setUser(userInfo);
  }

  public setUser(userInfo: UserTokenInfo): void {
    userInfo.id = parseInt(userInfo.numeric_id.toString()); // numeric_id comes as string for some reason
    userInfo.isAdmin = userInfo.isAdmin?.toString().toLowerCase() === "true";
    userInfo.isSuperAdmin =
      userInfo.isSuperAdmin?.toString().toLowerCase() === "true";
    this._user = userInfo;
  }

  public get isAuthorized(): boolean {
    return !!this.authData;
  }

  public async user(): Promise<UserTokenInfo | undefined> {
    if (this._user) {
      return this._user;
    }

    const user = await this.userManager.getUser();
    if (!user) {
      return undefined;
    }

    this.setUserFromOidc(user);
    return this._user;
  }

  public async getUserRoles(): Promise<string[] | undefined> {
    const user = await this.getUserData();
    const roles = (user as any)?.profile?.['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    return roles || [];
  }

  public get userManager(): UserManager {
    if (this._userManager) {
      return this._userManager;
    }

    const settings: UserManagerSettings = {
      authority: globalConfig.get().applicationManagementPortalRoot,
      client_id: "of",
      redirect_uri: `${globalConfig.get().uiRoot}/signin-callback`,
      response_type: "id_token token",
      scope: "openid profile of",
      post_logout_redirect_uri: `${globalConfig.get().uiRoot}/login`,
      automaticSilentRenew: true,
      silent_redirect_uri: `${globalConfig.get().uiRoot}/silent-refresh.html`,
      userStore: new WebStorageStateStore({ store: localStorage }),
      stateStore: new WebStorageStateStore({ store: localStorage }),
      monitorSession: false,
    };

    this._userManager = new UserManager(settings);

    Log.logger = console;
    Log.level = Log.INFO;

    this.userManager.events.addSilentRenewError(async () => {
      await this.logout();
    });

    return this._userManager;
  }

  /**
   * Check if the given path is a path that does not require sign-in to view
   */
  public isUnathenticatedPath(path: string, search: string) {
    if ([
      "/login",
      "/signin-callback",
      "/signout-callback",
    ].includes(path)) {
      return true;
    }

    // unauthenticated users are allowed to look at forms but they're read-only
    // but if the search query is non-empty this is possibly a form open from an external source (Property Search) in which
    // case, sign in is required
    if (path.startsWith('/home/forms/') && !search) {
      return true;
    }
  }

  public saveCurrentPath() {
    localStorage.setItem("redirect_url", `${location.pathname}${location.search}`);
  }

  public getSavedPath() {
    return localStorage.getItem('redirect_url')
  }

  public clearSavedPath() {
    localStorage.removeItem("redirect_url");
  }
}
