import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

export const DisabledVetDisclaimer = (props: { onClose: () => void }) => {
  const { onClose } = props;
  return (
    <Dialog title="Attention" width={800} closeIcon={false}>
      If you are a disabled veteran who has been awarded by the United States
      Department of Veterans Affairs or its successor 100 percent disability
      compensation due to a service-connected disability and a rating of 100
      percent disabled or of individual unemployability is entitled to an
      exemption from taxation of the total appraised value of the veteran's
      residence homestead. Tax Code Section 11.131
      <br />
      <br />
      Please complete the Homestead Residence Exemption form in this portal and
      NOT the Disabled Veteran’s or Survivor’s Exemption Form. Please contact
      the appraisal district with any questions.
      <DialogActionsBar>
        <div className="d-flex justify-content-center">
          <Button className="k-button" onClick={onClose}>Continue</Button>
        </div>
      </DialogActionsBar>
    </Dialog>
  );
};
