import { IMarshWetlandRestorationOrDevelopmentTypes,
    IDrillNewWellTypes,
    IModificationsOfExistingWaterSourceTypes,
    ISpringDevelopmentEnhancementTypes } from "./supplemental-water.interface-types";

export interface IWildlifeManagementFourthSectionSupplementalWater {
    MarshWetlandRestorationOrDevelopment?: boolean;
    MarshWetlandRestorationOrDevelopmentTypes?: IMarshWetlandRestorationOrDevelopmentTypes;
    MarshWetlandRestorationOrDevelopmentPlannedDateOfConstruction?: string;
    MarshWetlandRestorationOrDevelopmentAdditionalInformation?: string;
    WellWindmillOverflow?: boolean;
    WellWindmillOverflowDrillNewWell?: boolean;
    WellWindmillOverflowDrillNewWellDepth?: string;
    WellWindmillOverflowDrillNewWellTypes?: IDrillNewWellTypes;
    WellWindmillOverflowDrillNewWellGallonsPerMinute?: number;
    WellWindmillOverflowDrillNewWellSize?: string;
    WellWindmillOverflowDrillNewWellLength?: string;
    WellWindmillOverflowModificationsOfExistingWaterSources?: boolean;
    WellWindmillOverflowModificationsOfExistingWaterSourcesTypes?: IModificationsOfExistingWaterSourceTypes;
    WellWindmillOverflowModificationsOfExistingWaterSourcesDistanceBetweenWaterSources?: string;
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPvcPipeFacility?: boolean;
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPvcPipeFacilityNumber?: number;
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesSmallGameGuzzler?: boolean;
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesSmallGameGuzzlerNumber?: number;
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPlasticContainer?: boolean;
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPlasticContainerNumber?: number;
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesBigGameGuzzler?: boolean;
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesBigGameGuzzlerNumber?: number;
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesFlyingSaucerGuzzler?: boolean;
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesFlyingSaucerGuzzlerNumber?: number;
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesDrumWithFaucetOrFloat?: boolean;
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesDrumWithFaucetOrFloatNumber?: number;
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesWindmillSupplyPipeDripper?: boolean;
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesWindmillSupplyPipeDripperNumber?: number;
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesIngroundBowlTrough?: boolean;
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesIngroundBowlTroughNumber?: number;
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesInvertedUmbrellaGuzzler?: boolean;
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesInvertedUmbrellaGuzzlerNumber?: number;
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesRanchSpecialtiesGuzzler?: boolean;
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesRanchSpecialtiesGuzzlerNumber?: number;
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesOther?: boolean;
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesOtherDescription?: string;
    WellWindmillOverflowAdditionalInformation?: string;
    SpringDevelopmentEnhancement?: boolean;
    SpringDevelopmentEnhancementTypes?: ISpringDevelopmentEnhancementTypes;
    SpringDevelopmentEnhancementOther?: string;
    SpringDevelopmentEnhancementAdditionalInformation?: string;
}

export const WildlifeManagementFourthSectionSupplementalWaterInterface = (): IWildlifeManagementFourthSectionSupplementalWater => ({
    MarshWetlandRestorationOrDevelopment: undefined,
    MarshWetlandRestorationOrDevelopmentTypes: undefined,
    MarshWetlandRestorationOrDevelopmentPlannedDateOfConstruction: undefined,
    MarshWetlandRestorationOrDevelopmentAdditionalInformation: undefined,
    WellWindmillOverflow: undefined,
    WellWindmillOverflowDrillNewWell: undefined,
    WellWindmillOverflowDrillNewWellDepth: undefined,
    WellWindmillOverflowDrillNewWellTypes: undefined,
    WellWindmillOverflowDrillNewWellGallonsPerMinute: undefined,
    WellWindmillOverflowDrillNewWellSize: undefined,
    WellWindmillOverflowDrillNewWellLength: undefined,
    WellWindmillOverflowModificationsOfExistingWaterSources: undefined,
    WellWindmillOverflowModificationsOfExistingWaterSourcesTypes: undefined,
    WellWindmillOverflowModificationsOfExistingWaterSourcesDistanceBetweenWaterSources: undefined,
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPvcPipeFacility: undefined,
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPvcPipeFacilityNumber: undefined,
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesSmallGameGuzzler: undefined,
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesSmallGameGuzzlerNumber: undefined,
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPlasticContainer: undefined,
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesPlasticContainerNumber: undefined,
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesBigGameGuzzler: undefined,
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesBigGameGuzzlerNumber: undefined,
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesFlyingSaucerGuzzler: undefined,
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesFlyingSaucerGuzzlerNumber: undefined,
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesDrumWithFaucetOrFloat: undefined,
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesDrumWithFaucetOrFloatNumber: undefined,
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesWindmillSupplyPipeDripper: undefined,
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesWindmillSupplyPipeDripperNumber: undefined,
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesIngroundBowlTrough: undefined,
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesIngroundBowlTroughNumber: undefined,
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesInvertedUmbrellaGuzzler: undefined,
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesInvertedUmbrellaGuzzlerNumber: undefined,
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesRanchSpecialtiesGuzzler: undefined,
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesRanchSpecialtiesGuzzlerNumber: undefined,
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesOther: undefined,
    WellWindmillOverflowTypeOfWildlifeWateringFacilitiesOtherDescription: undefined,
    WellWindmillOverflowAdditionalInformation: undefined,
    SpringDevelopmentEnhancement: undefined,
    SpringDevelopmentEnhancementTypes: undefined,
    SpringDevelopmentEnhancementOther: undefined,
    SpringDevelopmentEnhancementAdditionalInformation: undefined
})