import instance from "src/axios-instance";
import type { ICreateMessage } from "src/interfaces/messages/create-message";
import type { ICreateDialog, IDialog } from "src/interfaces/messages/dialogs/dialog.interface";
import type { IMessagePaging } from "src/interfaces/messages/message-pagination.interface";

export function getDialogs(data: IMessagePaging): any {
  return instance.get(
    `dialogs?searchTerm=${data.searchTerm ?? ""}&searchType=${
      data.searchType ?? ""
    }&size=${data.size ?? ""}&page=${data.page ?? ""}&clientId=${
      data.clientId ?? ""
    }`
  );
}

export function createDialog(
  dialog: ICreateDialog,
  message: ICreateMessage
): any {
  const formData = new FormData();
  Object.keys(dialog).forEach(key => {
    if(Reflect.get(dialog, key)) {
      formData.append(`dialog[${key}]`, `${Reflect.get(dialog, key)}`);
    }
  });
  formData.append("message[date]", message.date);
  formData.append("message[body]", message.body);
  formData.append("message[isRead]", new Boolean(message.isRead).toString());
  formData.append("message[emailSent]", new Boolean(message.emailSent).toString());
  for (const file of message.attachments){
    formData.append('attachments', file);
  }
  return instance.post("dialogs", formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }});
}

export function updateAssignedAdminOfDialog(
  dialogId: number,
  adminId: number
): any {
  return instance.put(`dialogs/${dialogId}/assigned/${adminId ?? ""}`);
}

export function getDialogFormEntryById(formEntryId: number) {
  return instance.get<IDialog[]>(`dialogs/form-entry/${formEntryId}`);
}

export function getDialogsByUserId(userId: number, data: IMessagePaging): any {
  return instance.get(
    `dialogs/user/${userId}?searchTerm=${data.searchTerm ?? ""}&searchType=${
      data.searchType ?? ""
    }&size=${data.size ?? ""}&page=${data.page ?? ""}&clientId=${
      data.clientId ?? ""
    }`
  );
}

export function getCountOfDialogs(): any {
  return instance.get(`dialogs/count`);
}

export function archiveDialog(id: number): any {
  return instance.put(`dialogs/${id}/archive`, {
    id,
  });
}

export function unarchiveDialog(id: number): any {
  return instance.put(`dialogs/${id}/unarchive`, {
    id,
  });
}
