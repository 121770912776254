import {
  action,
  actionError,
  actionPayload,
} from "src/context/generic-actions";

export enum AdminActionsEnum {
  ERROR = "ERROR",
  SUCCESS_MESSAGE = "SUCCESS_MESSAGE",
  IS_LOADING = "IS_LOADING",
  IS_PERMISSIONS_LOADING = "IS_PERMISSIONS_LOADING",
  SELECTED_USER_ID = "SELECTED_USER_ID",
  DIALOG_TYPE = "DIALOG_TYPE",
}

export const Dispatches = {
  requestLogin: action,
  loginError: actionError,
  loginSuccess: actionPayload,
};
