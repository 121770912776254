import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "./FreeportForm.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { DatePickerChangeEvent, CalendarChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, RadioGroupChangeEvent, Checkbox, Input, RadioGroup } from "@progress/kendo-react-inputs";
import { FormValidation } from "../InputValidator";
import { Error, Label } from "@progress/kendo-react-labels";
import { EmailInput } from "../CustomInputs/EmailInputs/EmailInput";
import { PhoneNumberInput } from "../CustomInputs/PhoneNumberInputs/PhoneNumberInput";
import { StatesInput } from "../CustomInputs/StatesInput";
import { ZipCodeInput } from "../CustomInputs/ZipCodeInput";
import { IFreeportFormSecondSectionInterface } from "src/interfaces/forms/FreeportForm/freeport-form-second-section.interface";
import SectionTitle from "../Shared/SectionTitle";
import { typeOfAuthorizedRepresentativeRadioButtonData } from "../Shared/RadioButtonData";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IFreeportFormSecondSectionInterface) => void;
    formDataFields?:  IFreeportFormSecondSectionInterface,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function FreeportFormSecondSection(props: GeneralFormType): JSX.Element {
    const { setFormDataFields, formDataFields, formSettings, setFormIsValid } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const [emailInputValid, setEmailInputValid] = useState<boolean>(false);
    const [phoneNumberInputValid, setPhoneNumberInputValid] = useState<boolean>(false);
    const [mailingStateInputValid, setMailingStateInputValid] = useState<boolean>(false);
    const [mailingZipCode, setMailingZipCode] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator } = FormValidation(formRef);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={2} name={"Authorized Representative"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string,
        group?: string
    ) {
        if (group) {
            setFormDataFields({
                ...formDataFields,
                [group]: {
                    ...(formDataFields as any)?.[group],
                    [field]: event.value
                }
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    const basisOfAuthorityValidator = () => {
        return !!(formDataFields?.AuthorizedRepresentativeType);
    };

    function changeValidator() {
        if (formRef.current) {
            let isSectionValid = true;
            if (formDataFields?.NotFilingOnOwnBehalf) {
                isSectionValid = basisOfAuthorityValidator() &&
                phoneNumberInputValid &&
                emailInputValid &&
                mailingStateInputValid &&
                mailingZipCode;
            }
            const isValid = formRef.current.checkValidity() && isSectionValid;
            setIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function onTouch(){
        if (!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [
        formDataFields,
        formRef.current,
        phoneNumberInputValid,
        emailInputValid,
        mailingStateInputValid,
        mailingZipCode
    ]);

    return (
       <>
            <PanelBar keepItemsMounted={true}>
                <PanelBarItem title={title} expanded={true}>
                    <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                        <div className="container">
                            <div className={classes.item}>
                                <div className={`${classes.itemFields} ${classes.bordered}`}>
                                    <Checkbox
                                        name={"NotFilingOnOwnBehalf"}
                                        className={classes.Checkbox}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.NotFilingOnOwnBehalf}
                                        label={"Check this box if you are NOT an individual property owner filing this application on your own behalf."}
                                        onChange={(e)=> changeValueHandler(e, "NotFilingOnOwnBehalf")}
                                    />
                                </div>
                            </div>
                            {formDataFields?.NotFilingOnOwnBehalf && <>
                                <div className={classes.item}>
                                    <div className={`${classes.itemFields} ${classes.bordered}`}>
                                        <Label className={classes.Label}>
                                            Please indicate the basis for your authority to represent the property owner in filing this application:
                                            {!basisOfAuthorityValidator() && (
                                                <i className={"fa fa-asterisk text-danger"}></i>
                                            )}
                                        </Label>
                                        <div className={`${classes.inputsGroup} ${classes.fieldsGroupW100}`}>
                                            <RadioGroup
                                                name={"AuthorizedRepresentativeType"}
                                                layout={"vertical"}
                                                disabled={formSettings?.isReadOnly}
                                                data={typeOfAuthorizedRepresentativeRadioButtonData}
                                                onChange={e => changeValueHandler(e, "AuthorizedRepresentativeType")}
                                                value={formDataFields?.AuthorizedRepresentativeType}
                                            />                                            
                                            {formDataFields?.AuthorizedRepresentativeType === "Other" &&
                                                <div className={classes.paddingTop}>
                                                    <Label className={classes.Label}>
                                                        Other (specify):
                                                        {!inputValidator("AuthorizedRepresentativeTypeOther") && (
                                                            <i className={"fa fa-asterisk text-danger"}></i>
                                                        )}
                                                    </Label>
                                                    <Input
                                                        className={classes.kTextbox}
                                                        name={"AuthorizedRepresentativeTypeOther"}
                                                        placeholder={"Explain basis"}
                                                        required={true}
                                                        disabled={formSettings?.isReadOnly}
                                                        value={formDataFields?.AuthorizedRepresentativeTypeOther}
                                                        onChange={(e)=> changeValueHandler(e, "AuthorizedRepresentativeTypeOther")}
                                                    />
                                                    {!inputValidator("AuthorizedRepresentativeTypeOther") && (
                                                        <Error >{validationMessage}</Error>
                                                    )}
                                                </div> 
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={`${classes.item} ${classes.bordered}`}>
                                    <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                        Provide the following information for the individual 
                                        with the legal authority to act for the property owner in this matter:
                                    </div>
                                    <div className={classes.itemFields}>
                                        <div className={`${classes.fieldsGroup} ${classes.fieldsGroupW50}`}>
                                            <div className={classes.flexRow}>
                                                <div className={classes.fieldsGroupW50}>
                                                    <Label className={classes.Label}>
                                                        Name of Authorized Representative
                                                    </Label>
                                                    <div className="inputsGroup">
                                                        <Input
                                                            className={classes.kTextbox}
                                                            required={true}
                                                            name={"AuthorizedRepresentativeName"}
                                                            disabled={formSettings?.isReadOnly}
                                                            value={formDataFields?.AuthorizedRepresentativeName}
                                                            onChange={(e)=> changeValueHandler(e, "AuthorizedRepresentativeName")}
                                                        />
                                                        {!inputValidator("AuthorizedRepresentativeName") && (
                                                            <Error >{validationMessage}</Error>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className={classes.fieldsGroupW50}>
                                                    <Label className={classes.Label}>
                                                        Title
                                                    </Label>
                                                    <div className="inputsGroup">
                                                        <Input
                                                            className={classes.kTextbox}
                                                            required={true}
                                                            name={"AuthorizedRepresentativeTitle"}
                                                            disabled={formSettings?.isReadOnly}
                                                            value={formDataFields?.AuthorizedRepresentativeTitle}
                                                            onChange={(e)=> changeValueHandler(e, "AuthorizedRepresentativeTitle")}
                                                        />
                                                        {!inputValidator("AuthorizedRepresentativeTitle") && (
                                                            <Error >{validationMessage}</Error>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={classes.fieldsGroupW100}>
                                            <Label className={classes.Label}>
                                                Email Address
                                            </Label>
                                            <div className="inputsGroup">
                                                <EmailInput
                                                    required={false}
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"AuthorizedRepresentativeEmail"}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                    setIsValidField={setEmailInputValid}
                                                    validField={emailInputValid}
                                                />
                                            </div>
                                        </div>
                                        </div>
                                        <div className={`${classes.fieldsGroupW50} ${classes.column}`}>
                                            <div className={classes.fieldsGroupW100}>
                                                <Label className={classes.Label}>
                                                    Driver’s License, Personal I.D. Certificate or Social Security Number
                                                </Label>
                                                <Input
                                                    className={classes.kTextbox}
                                                    required={true}
                                                    name={"AuthorizedRepresentativePersonalId"}
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.AuthorizedRepresentativePersonalId}
                                                    onChange={(e)=> changeValueHandler(e, "AuthorizedRepresentativePersonalId")}
                                                />
                                                {!inputValidator("AuthorizedRepresentativePersonalId") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                            <div className={classes.fieldsGroupW100}>
                                                <Label className={classes.Label}>
                                                    Primary Phone Number (area code and number)
                                                </Label>
                                                {/* TODO: Add Area Code to Input Mask */}
                                                <PhoneNumberInput
                                                    required={false}
                                                    setFormDataFields={setFormDataFields}
                                                    fieldName={"AuthorizedRepresentativePrimaryPhoneNumber"}
                                                    formDataFields={formDataFields}
                                                    isReadOnly={formSettings?.isReadOnly}
                                                    setIsValidField={setPhoneNumberInputValid}
                                                    validField={phoneNumberInputValid}
                                                />
                                                {!inputValidator("AuthorizedRepresentativePrimaryPhoneNumber") && (
                                                    <Error >{validationMessage}</Error>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${classes.item} ${classes.bordered}`}>
                                    <div className={`${classes.itemTitle} ${classes.addressTitle}`}>
                                        <p>Authorized Representative Mailing Address</p>
                                    </div>
                                    <div className={classes.itemFields}>
                                        <Label className={classes.Label}>
                                            Mailing Address Line 1
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            required={true}
                                            name={"AuthorizedRepresentativeMailingAddressLine1"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.AuthorizedRepresentativeMailingAddressLine1}
                                            onChange={(e)=> changeValueHandler(e, "AuthorizedRepresentativeMailingAddressLine1")}
                                        />
                                        {!inputValidator("AuthorizedRepresentativeMailingAddressLine1") && (
                                            <Error >{validationMessage}</Error>
                                        )}
                                    </div>
                                    <div className={classes.itemFields}>
                                        <Label className={classes.Label}>
                                            Mailing Address Line 2
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"AuthorizedRepresentativeMailingAddressLine2"}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.AuthorizedRepresentativeMailingAddressLine2}
                                            onChange={(e)=> changeValueHandler(e, "AuthorizedRepresentativeMailingAddressLine2")}
                                        />
                                    </div>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                        <div className={classes.fieldsGroupW50}>
                                            <Label className={classes.Label}>
                                                City
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                required={true}
                                                name={"AuthorizedRepresentativeMailingAddressCity"}
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.AuthorizedRepresentativeMailingAddressCity}
                                                onChange={(e)=> changeValueHandler(e, "AuthorizedRepresentativeMailingAddressCity")}
                                            />
                                            {!inputValidator("AuthorizedRepresentativeMailingAddressCity") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                        <div className={classes.fieldsGroupW20}>
                                            <Label className={classes.Label}>
                                                State
                                            </Label>
                                            <StatesInput
                                                setFormDataFields={setFormDataFields}
                                                fieldName={"AuthorizedRepresentativeMailingAddressState"}
                                                required={true}
                                                validField={mailingStateInputValid}
                                                setIsValidField={setMailingStateInputValid}
                                                formDataFields={formDataFields}
                                                isReadOnly={formSettings?.isReadOnly}
                                            />
                                            {!inputValidator("AuthorizedRepresentativeMailingAddressState") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                        <div className={classes.fieldsGroupW20}>
                                            <Label className={classes.Label}>
                                                Zip Code
                                            </Label>
                                            <ZipCodeInput
                                                setFormDataFields={setFormDataFields}
                                                fieldName={"AuthorizedRepresentativeMailingAddressZip"}
                                                required={true}
                                                formDataFields={formDataFields}
                                                isReadOnly={formSettings?.isReadOnly}
                                                setIsValidField={setMailingZipCode}
                                                validField={mailingZipCode}
                                            />
                                            {!inputValidator("AuthorizedRepresentativeMailingAddressZip") && (
                                                <Error >{validationMessage}</Error>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                </>
                            }
                        </div>
                   </form>
                </PanelBarItem>
            </PanelBar>
       </>
    )
}