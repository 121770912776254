import {InputChangeEvent, MaskedTextBox, MaskedTextBoxChangeEvent} from "@progress/kendo-react-inputs";
import classes from "./custom-field.module.scss";
import {useEffect, useState} from "react";
import {Error} from "@progress/kendo-react-labels";

interface ZipCodeInput {
    isReadOnly?: boolean;
    setFormDataFields: (form: any) => void;
    formDataFields?:  any;
    fieldName: string;
    required: boolean;
    setIsValidField?: (item: boolean) => void;
    validField?: boolean;
    placeholder?: string;
}


export function ZipCodeInput(props: ZipCodeInput) :JSX.Element {
    const { isReadOnly, formDataFields, setFormDataFields, fieldName, required, setIsValidField, validField, placeholder} = props;
    const [errors, setErrors] = useState<{required: boolean, pattern: boolean}>({
        pattern: required,
        required: required,
    });
    const [lastValue, setLastValue] = useState<string>('');
    const shortMask = "99999";
    const longMask = "99999-9999";
    const shortPattern = /^(([0-9])){5}$/i;
    const longPattern = /^(([0-9])){5}-(([0-9])){4}$/i;

    useEffect(() => {
        return () => {
            if(setIsValidField){
                setIsValidField(true);
            }
        }
    },[]);

    const changeValueHandler = (
        event: InputChangeEvent | MaskedTextBoxChangeEvent,
        field: string) => {
        const currentValue = event.value.replace(/_/g, "");
        let value = "";

        if (currentValue.length === 5 && lastValue.length === 5 && parseInt(event.nativeEvent.data)){
            value = event.value + "-" + event.nativeEvent.data;
            setFormDataFields({
                ...formDataFields,
                [field]: value
            });
        } else if (currentValue.length === 6 && lastValue.length === 7){
            value = event.value.replace("-", "").replace(/_/g, "");
            setFormDataFields({
                ...formDataFields,
                [field]: value
            });
        } else {
            value = event.value;
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }

        if (shortPattern.test(value) ||
            (longPattern.test(value))){
            setErrors({ pattern: false, required: false });
        } else if (currentValue.replace("-", "").length === 0 && !required){
            setErrors({ pattern: false, required: false });
        } else {
            setErrors({ pattern: true, required: true });
        }

        setLastValue(currentValue);
    }

    useEffect(() => {
        if ((errors.pattern || errors.required) && setIsValidField){
            setIsValidField(false);
        } else if ((!required || (!errors.pattern && !errors.required)) && setIsValidField){
            setIsValidField(true);
        }


        if ((shortPattern.test(formDataFields?.[fieldName]) ||
            (longPattern.test(formDataFields?.[fieldName])))  && setIsValidField) {
            setIsValidField(true);
        }

    }, [errors, formDataFields?.[fieldName]]);

    const maskBuilder = (v: string) => {
        if (!v || v.replace("-", "").replace(/_/g, "").length == 0) {
            return shortMask;
        }
        return (v.length >= 6) ? longMask : shortMask;
    }

    return <>
        <MaskedTextBox
            valid={validField}
            required={required}
            name={fieldName}
            className={!validField ? `${classes.input} ${classes.invalidInput}` : classes.input}
            placeholder={placeholder}
            mask={maskBuilder(formDataFields?.[fieldName])}
            value={formDataFields?.[fieldName]}
            disabled={isReadOnly}
            onChange={(e)=> changeValueHandler(e, fieldName)}
        />
        {errors.pattern && !validField && <Error >{"Please enter valid value!"}</Error>}
    </>
}
