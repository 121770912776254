import {DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {ComboBoxChangeEvent, DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import {
    CheckboxChangeEvent,
    Input,
    InputChangeEvent, NumericTextBox, NumericTextBoxChangeEvent,
    RadioGroupChangeEvent,
    TextAreaChangeEvent,
} from "@progress/kendo-react-inputs";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import classes from "../../BPPForm/BPPForm.module.scss";
import { ITimberlandAppraisalFormFifthSection } from "src/interfaces/forms/TimberlandAppraisalForm/timberland-appraisal-form-fifth-section.interface";

interface GeneralFormType {
    formDataFields: ITimberlandAppraisalFormFifthSection | undefined;
    formSettings: IFormSettingModel | undefined;
    changeValueHandler: (
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | ComboBoxChangeEvent
            | NumericTextBoxChangeEvent,
        field: string,
        group: string,
        id: number
    ) => void;
}

export function NonAgriculturalUseTable(
    props: GeneralFormType
): JSX.Element {

    return (
        <table className="blue">
            <thead>
            <tr>
                <th className="step">
                    Non-Agricultural use
                </th>
                <th>Number of Acres</th>
            </tr>
            </thead>
            <tbody>
            {props.formDataFields?.NonAgriculturalUse?.map((item) => {
                return (
                    <tr key={item.Id}>
                        <td>
                            <Input
                                className={classes.kTextbox}
                                name={"Type"}
                                disabled={props.formSettings?.isReadOnly}
                                value={item.Type}
                                onChange={(e) =>
                                    props.changeValueHandler(
                                        e,
                                        "Type",
                                        "NonAgriculturalUse",
                                        item.Id!
                                    )
                                }
                            />
                        </td>
                        <td>
                            <NumericTextBox
                                name={"Acres"}
                                className={classes.kTextbox}
                                spinners={false}
                                disabled={props.formSettings?.isReadOnly}
                                value={item.Acres}
                                onChange={(e) =>
                                    props.changeValueHandler(
                                        e,
                                        "Acres",
                                        "NonAgriculturalUse",
                                        item.Id!
                                    )
                                }
                            />
                        </td>
                    </tr>
                );
            })}
            </tbody>
        </table>
    );
}
