import moment from "moment";

export const fullDateFormat = "MMM DD, yyyy hh:mm:ss A";
export const publicFormsDateFormat = "MMM DD, yyyy hh:mm";
export const shortDateFormat = "MMM DD, yyyy";
export const formsDateFormat = "{0:MMM dd yyyy}";
export const settingsDateFormat = "EEEE, MMM dd yyyy";
export const withDayDateFormat = "dddd, MMMM DD, YYYY";
export const ddMmmmYyyyDateFormat = "DD MMMM, yyyy";
export const yyyyMmmDdHhMm = "YYYY MMM DD hh:mm: ss";
export const MMMMddDateFormat = "MMMM dd";
export const MMddyyyyDateFormat = "MM/dd/yyyy";

const dateFormats: string[] = [
  "MMMM dd yyyy",
  "MMM dd yyyy HH:mm",
  "MMM dd yyyy hh:mm a",
  "MMM dd yyyy HH:mm:ss",
  "MMM dd yyyy HH:mm:ss a",
  "dd MMM yyyy",
  "dd MMM yyyy HH:mm",
  "dd MMM yyyy HH:mm a",
  "dd MMM yyyy HH:mm:ss",
  "dd MMM yyyy HH:mm:ss a",
  "M/dd/yyyy",
  "M/dd/yyyy HH:mm",
  "M/dd/yyyy HH:mm a",
  "M/dd/yyyy HH:mm:ss",
  "M/dd/yyyy HH:mm:ss a",
  "yyyy",
];

export const dateList = (date: Date) => {
  return dateFormats.map((format) => {
    return {
      text: moment(date).format(format.toUpperCase()),
      value: `{0:${format}}`,
    };
  });
};
