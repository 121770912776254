import {IFormSettingModel} from "../../../../../interfaces/form-settings.interface";
import {useEffect, useRef, useState} from "react";
import {
    CheckboxChangeEvent, 
    InputChangeEvent, RadioGroup,
    RadioGroupChangeEvent, 
    TextAreaChangeEvent
} from "@progress/kendo-react-inputs";
import {CalendarChangeEvent, DatePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {ComboBoxChangeEvent, DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import classes from "./AgForm.module.scss";
import {Label} from "@progress/kendo-react-labels";
import {IAgFormEighthSectionInterface} from "../../../../../interfaces/forms/AgForm/ag-form-eighth-section.interface";
import {CustomDatePicker} from "../CustomInputs/CustomDatePickers/CustomDatePicker";
import SectionTitle from "../Shared/SectionTitle";
import { defaultRadioButtonData } from "../Shared/RadioButtonData";

interface GeneralFormType {
    isAdminPreview?: boolean,
    setFormDataFields: (form: IAgFormEighthSectionInterface) => void;
    formDataFields?:  IAgFormEighthSectionInterface,
    formSettings?: IFormSettingModel,
    setFormIsValid: (value: boolean) => void
}

export default function AgFormEighthSection(props: GeneralFormType): JSX.Element {
    const {setFormDataFields, formDataFields, formSettings, setFormIsValid} = props;

    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} name={"Section 6: Conversion to Timber Production"}/>;

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent
            | ComboBoxChangeEvent,
        field: string,
        eventType?: string
    ) {
        if(eventType === "RadioGroupChangeEvent"){
            setFormDataFields({
                ...formDataFields,
                [field]: JSON.parse(event.value)
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }


    const [validDate, setValidDate] = useState<boolean>(!formDataFields?.LandWasConvertedToTemberAfter1997);

    function changeValidator() {
        if (formRef.current) {
            setIsValid(formRef.current.checkValidity() && validDate);
            setFormIsValid(formRef.current.checkValidity() && validDate);
        }
    }

    function onTouch() {
        if (!isFormTouched) {
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [formDataFields, formRef.current, validDate]);

    return <>
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className="help-text help-info">
                            <div id="undefinedHelpText"><b>Please choose Yes or No for the following questions:</b></div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemFields}>
                                <div className={`${classes.flexGroup} ${classes.fieldsGroupW100}`}>
                                    <Label className={classes.Label}>
                                        Was the land subject to this application converted to timber production after Sept. 1, 1997?
                                    </Label>
                                    <div className="inputsGroup">
                                        <RadioGroup
                                            layout={"horizontal"}
                                            name={"LandWasConvertedToTemberAfter1997"}
                                            disabled={formSettings?.isReadOnly}
                                            data={defaultRadioButtonData}
                                            onChange={e => changeValueHandler(e, "LandWasConvertedToTemberAfter1997", "RadioGroupChangeEvent")}
                                            value={formDataFields?.LandWasConvertedToTemberAfter1997?.toString()}
                                        />
                                    </div>
                                    { formDataFields?.LandWasConvertedToTemberAfter1997 &&
                                    <div className={`${classes.fieldsGroupW100}`}>
                                        <Label className={classes.Label}>
                                            On what date did you convert to timber production?
                                        </Label>
                                        <div className={classes.fieldsGroupW49}>
                                            <CustomDatePicker
                                                fieldName={"DateConvertedToTember"}
                                                required={false}
                                                disabled={formSettings?.isReadOnly}
                                                formDataFields={formDataFields}
                                                setFormDataFields={setFormDataFields}
                                                onlyYear={false}
                                                setIsValidField={setValidDate}
                                                validField={validDate}
                                                defaultValueDate={formDataFields?.DateConvertedToTember}
                                            />
                                        </div>
                                    </div> }
                                </div>
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemFields}>
                                <div className={`${classes.flexGroup} ${classes.fieldsGroupW100}`}>
                                    <Label className={classes.Label}>
                                        Does the property owner wish to have the land subject to this application continue to be appraised as 1-d-1 land?
                                    </Label>
                                    <div className="inputsGroup">
                                        <RadioGroup
                                            layout={"horizontal"}
                                            name={"WantsLandToBeAppraisedAs1D1"}
                                            disabled={formSettings?.isReadOnly}
                                            data={defaultRadioButtonData}
                                            onChange={e => changeValueHandler(e, "WantsLandToBeAppraisedAs1D1", "RadioGroupChangeEvent")}
                                            value={formDataFields?.WantsLandToBeAppraisedAs1D1?.toString()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    </>
}
