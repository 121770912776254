export type IDisabledVetThirdSection = {
    AuthorizedRepresentationBasis?: string;
    AuthorizedRepresentationBasisOther?: string;
    RepresentativeName?: string;
    RepresentativeTitle?: string;
    RepresentativePhoneNumber?: string;
    RepresentativeEmailAddress?: string;
    RepresentativeMailingAddressLine1?: string;
    RepresentativeMailingAddressLine2?: string;
    RepresentativeMailingAddressCity?: string;
    RepresentativeMailingAddressState?: string;
    RepresentativeMailingAddressZip?: string;
}

export const DisabledVetThirdSectionInterface = (): IDisabledVetThirdSection => ({
    AuthorizedRepresentationBasis: undefined,
    AuthorizedRepresentationBasisOther: undefined,
    RepresentativeName: undefined,
    RepresentativeTitle: undefined,
    RepresentativePhoneNumber: undefined,
    RepresentativeEmailAddress: undefined,
    RepresentativeMailingAddressLine1: undefined,
    RepresentativeMailingAddressLine2: undefined,
    RepresentativeMailingAddressCity: undefined,
    RepresentativeMailingAddressState: undefined,
    RepresentativeMailingAddressZip: undefined
})