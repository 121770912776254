import {IFormFilesInterface} from "../Shared/form-files.interface";

export type IBppFormFourteenthSectionInterface = {
    SupportingFiles?: Array<IFormFilesInterface>,
    PriorYearRenditionAdded?: string
}

export const FourteenthSectionInitialState = {
    SupportingFiles: undefined,
    PriorYearRenditionAdded: undefined
}