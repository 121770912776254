import { IBppFormEleventhSectionInterface } from "src/interfaces/forms/BPPForm/bpp-form-eleventh-section.interface";
import shared from "../../../PDF-Shared-style.module.scss";
import classes from "../../BPP-Form-PDF-style.module.scss";
import { GetBlankRows } from "../../../BlankRows";
import { IBPPFormPersonalPropertySegmentsInterface } from "src/interfaces/forms/BPPForm/bpp-form-personal-property-segments.interface";

interface GeneralFormType {
  name: string;
  rows: IBPPFormPersonalPropertySegmentsInterface[] | undefined;
}

export function ScheduleETableOther(props: GeneralFormType) : JSX.Element {
  return (
    <div className={`${shared.Width40} ${shared.InlineBlock}`}>
        <table className={shared.Blue}>
            <tr>
                <th colSpan={4} className={classes.BlueHeader}>
                    { props.name }
                </th>
            </tr>
            <tr>
                <th style={{width:"14%"}}>
                    Year Acquired
                </th>
                <th style={{width:"30%"}}>
                    Description
                </th>
                <th style={{width:"28%"}}>
                    Historical Cost When New<br />(Omit Cents)*
                </th>
                <th style={{width:"28%"}}>
                    Good Faith Estimate of Market Value*
                </th>
            </tr>
            { props.rows?.filter(x => !x.HasActiveSubSegments)?.map(item => {
                return <tr key={item.Id}>
                    <th>
                        {item?.YearAcquired}
                    </th>
                    <td>
                        {item?.Description}
                    </td>
                    <td>
                        {item?.HistoricCost}
                    </td>
                    <td>
                        {item?.MarketValue}
                    </td>
                </tr>
            })}
            {GetBlankRows(props.rows?.length, 4)}
        </table>
    </div>
  );
}
