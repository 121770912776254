import { IGrazingSystemTypes,
    IRangeEnhancementSeedingMethods,
    IBrushManagementMechanicalTypes,
    IBrushManagementDesignTypes,
    IFenceModificationSpecies,
    IWetlandEnhancementTypes,
    IPrescribedControlOfSpeciesTypes,
    IWildlifeRestorationTypes,
    IHabitatProtectionTypes} from "./habitat-control.interface-types";

export interface IWildlifeManagementFourthSectionHabitatControl {
    GrazingManagement?: boolean;
    GrazingManagementSystemTypes?: IGrazingSystemTypes;
    GrazingManagementSystemTypesOther?: string;
    GrazingManagementAdditionalInformation?: string;
    PrescribedBurning?: boolean;
    PrescribedBurningAcresToBeBurned?: number;
    PrescribedBurningPlannedBurnDate?: string;
    PrescribedBurningAdditionalInformation?: string;
    RangeEnhancement?: boolean;
    RangeEnhancementAcresToBeSeeded?: number;
    RangeEnhancementDateToBeSeeded?: string;
    RangeEnhancementSeedingMethods?: IRangeEnhancementSeedingMethods;
    RangeEnhancementSeedingMixtureToBeUsed?: string;
    RangeEnhancementFertilized?: boolean;
    RangeEnhancementWeedControlNeeded?: boolean;
    RangeEnhancementAdditionalInformation?: string;
    BrushManagement?: boolean;
    BrushManagementAcresToBeTreated?: number;
    BrushManagementMethodMechanical?: boolean;
    BrushManagementMethodMechanicalTypes?: IBrushManagementMechanicalTypes;
    BrushManagementMethodMechanicalTypesOther?: string;
    BrushManagementMethodChemical?: boolean;
    BrushManagementMethodChemicalKind?: string;
    BrushManagementMethodChemicalRate?: string;
    BrushManagementDesign?: boolean;
    BrushManagementDesignTypes?: IBrushManagementDesignTypes;
    BrushManagementDesignStripsWidth?: number;
    BrushManagementDesignStripsLength?: number;
    BrushManagementAdditionalInformation?: string;
    FenceModification?: boolean;
    FenceModificationSpecies?: IFenceModificationSpecies;
    FenceModificationBottomOfNetWire?: boolean;
    FenceModificationBottomOfNetWireGapWidth?: string;
    FenceModificationReplaceSectionsOfNetWire?: boolean;
    FenceModificationReplaceSectionsOfNetWireGapWidth?: string;
    FenceModificationMilesOfFenceThatWillBeModified?: string;
    FenceModificationReplaceEntireFence?: boolean;
    FenceModificationMilesReplaced?: number;
    FenceModificationAdditionalInformation?: string;
    RiparianManagement?: boolean;
    RiparianManagementFencingOfRiparianArea?: string;
    RiparianManagementFencingRiparianAreaType?: string;
    RiparianManagementDefermentFromLivestockGrazing?: string;
    RiparianManagementDefermentType?: string;
    RiparianManagementSeasonDeferred?: string;
    RiparianManagementEstablishVegetation?: boolean;
    RiparianManagementEstablishVegetationTrees?: boolean;
    RiparianManagementTreesListSpecies?: string;
    RiparianManagementEstablishVegetationShrubs?: boolean;
    RiparianManagementShrubsListSpecies?: string;
    RiparianManagementEstablishVegetationHerbaceousSpecies?: boolean;
    RiparianManagementHerbaceousListSpecies?: string;
    RiparianManagementAdditionalInformation?: string;
    WetlandEnhancement?: boolean;
    WetlandEnhancementTypes?: IWetlandEnhancementTypes;
    WetlandEnhancementTypesOther?: string;
    WetlandEnhancementAdditionalInformation?: string;
    HabitatProtection?: boolean;
    HabitatProtectionTypes?: IHabitatProtectionTypes;
    HabitatProtectionTypesOther?: string;
    HabitatProtectionAdditionalInformation?: string;
    PrescribedControlOfSpecies?: boolean;
    PrescribedControlOfSpeciesTypes?: IPrescribedControlOfSpeciesTypes;
    PrescribedControlOfSpeciesBeingControlled?: string;
    PrescribedControlOfSpeciesMethod?: string;
    PrescribedControlOfSpeciesAdditionalInformation?: string;
    WildlifeRestoration?: boolean;
    WildlifeRestorationTypes?: IWildlifeRestorationTypes;
    WildlifeRestorationTargetSpecies?: string;
    WildlifeRestorationMethod?: string;
    WildlifeRestorationAdditionalInformation?: string;
}

export const WildlifeManagementFourthSectionHabitatControlInterface = (): IWildlifeManagementFourthSectionHabitatControl => ({
    GrazingManagement: undefined,
    GrazingManagementSystemTypes: undefined,
    GrazingManagementSystemTypesOther: undefined,
    GrazingManagementAdditionalInformation: undefined,
    PrescribedBurning: undefined,
    PrescribedBurningAcresToBeBurned: undefined,
    PrescribedBurningPlannedBurnDate: undefined,
    PrescribedBurningAdditionalInformation: undefined,
    RangeEnhancement: undefined,
    RangeEnhancementAcresToBeSeeded: undefined,
    RangeEnhancementDateToBeSeeded: undefined,
    RangeEnhancementSeedingMethods: undefined,
    RangeEnhancementSeedingMixtureToBeUsed: undefined,
    RangeEnhancementFertilized: undefined,
    RangeEnhancementWeedControlNeeded: undefined,
    RangeEnhancementAdditionalInformation: undefined,
    BrushManagement: undefined,
    BrushManagementAcresToBeTreated: undefined,
    BrushManagementMethodMechanical: undefined,
    BrushManagementMethodMechanicalTypes: undefined,
    BrushManagementMethodMechanicalTypesOther: undefined,
    BrushManagementMethodChemical: undefined,
    BrushManagementMethodChemicalKind: undefined,
    BrushManagementMethodChemicalRate: undefined,
    BrushManagementDesign: undefined,
    BrushManagementDesignTypes: undefined,
    BrushManagementDesignStripsWidth: undefined,
    BrushManagementDesignStripsLength: undefined,
    BrushManagementAdditionalInformation: undefined,
    FenceModification: undefined,
    FenceModificationSpecies: undefined,
    FenceModificationBottomOfNetWire: undefined,
    FenceModificationBottomOfNetWireGapWidth: undefined,
    FenceModificationReplaceSectionsOfNetWire: undefined,
    FenceModificationReplaceSectionsOfNetWireGapWidth: undefined,
    FenceModificationMilesOfFenceThatWillBeModified: undefined,
    FenceModificationReplaceEntireFence: undefined,
    FenceModificationMilesReplaced: undefined,
    FenceModificationAdditionalInformation: undefined,
    RiparianManagement: undefined,
    RiparianManagementFencingOfRiparianArea: undefined,
    RiparianManagementFencingRiparianAreaType: undefined,
    RiparianManagementDefermentFromLivestockGrazing: undefined,
    RiparianManagementDefermentType: undefined,
    RiparianManagementSeasonDeferred: undefined,
    RiparianManagementEstablishVegetation: undefined,
    RiparianManagementEstablishVegetationTrees: undefined,
    RiparianManagementTreesListSpecies: undefined,
    RiparianManagementEstablishVegetationShrubs: undefined,
    RiparianManagementShrubsListSpecies: undefined,
    RiparianManagementEstablishVegetationHerbaceousSpecies: undefined,
    RiparianManagementHerbaceousListSpecies: undefined,
    RiparianManagementAdditionalInformation: undefined,
    WetlandEnhancement: undefined,
    WetlandEnhancementTypes: undefined,
    WetlandEnhancementTypesOther: undefined,
    WetlandEnhancementAdditionalInformation: undefined,
    HabitatProtection: undefined,
    HabitatProtectionTypes: undefined,
    HabitatProtectionTypesOther: undefined,
    HabitatProtectionAdditionalInformation: undefined,
    PrescribedControlOfSpecies: undefined,
    PrescribedControlOfSpeciesTypes: undefined,
    PrescribedControlOfSpeciesBeingControlled: undefined,
    PrescribedControlOfSpeciesMethod: undefined,
    PrescribedControlOfSpeciesAdditionalInformation: undefined,
    WildlifeRestoration: undefined,
    WildlifeRestorationTypes: undefined,
    WildlifeRestorationTargetSpecies: undefined,
    WildlifeRestorationMethod: undefined,
    WildlifeRestorationAdditionalInformation: undefined
})