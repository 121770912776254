import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "../WildlifeManagement.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, RadioGroupChangeEvent, Checkbox, Input } from "@progress/kendo-react-inputs";
import { FormValidation } from "../../InputValidator";
import { CalendarChangeEvent, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { IWildlifeManagementFourthSectionPredatorControl } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-predator-control.interface";
import { Label } from "@progress/kendo-react-labels";
import SectionTitle from "../../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    formSettings?: IFormSettingModel,
    formDataFields?: IWildlifeManagementFourthSectionPredatorControl;
    setFormDataFields: (form: IWildlifeManagementFourthSectionPredatorControl) => void;
    setFormIsValid: (value: boolean) => void;
    setSectionIsValid: (value: boolean) => void;
}

export default function PredatorControlSubSection(props: GeneralFormType): JSX.Element {
    const { formSettings, isAdminPreview, formDataFields, setFormDataFields, setFormIsValid, setSectionIsValid } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator, setStyles } = FormValidation(formRef);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={3} name={"Predator Control"}/>;
    
    function changeValidator() {
        if (formRef.current) {
            const isValid = formRef.current.checkValidity();
            setIsValid(isValid);
            setSectionIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent,
        field: string,
        group?: string
    ) {
        if (group) {
            setFormDataFields({
                ...formDataFields,
                [group]: {
                    ...(formDataFields as any)?.[group],
                    [field]: event.value
                }
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    function onTouch(){
        if (!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [ formDataFields, formRef.current ]);

    return (
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.PredatorControlImportedRedFireAnts}
                                    label={"Imported red fire ants (verify prior to application that product is labeled for pasture use)"}
                                    onChange={(e)=> changeValueHandler(e, "PredatorControlImportedRedFireAnts")}
                                />
                            </div>
                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                <div className={classes.inputsGroup}>
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.PredatorControlBirds?.Cowbirds}
                                        label={"Control of cowbirds"}
                                        onChange={(e)=>
                                            changeValueHandler(e, "Cowbirds", "PredatorControlBirds")}
                                    />
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.PredatorControlBirds?.GrackleStarlingHouseSparrow}
                                        label={"Grackle/starling/house sparrow control"}
                                        onChange={(e)=> changeValueHandler(e, "GrackleStarlingHouseSparrow", "PredatorControlBirds")}
                                    />
                                </div>
                            </div>
                            {(formDataFields?.PredatorControlBirds?.Cowbirds || 
                              formDataFields?.PredatorControlBirds?.GrackleStarlingHouseSparrow) &&
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Method of control:
                                        </Label>
                                    </div>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.PredatorControlBirdsControlMethods?.Trapping}
                                            label={"Trapping"}
                                            onChange={(e)=> changeValueHandler(e, "Trapping", "PredatorControlBirdsControlMethods")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.PredatorControlBirdsControlMethods?.Shooting}
                                            label={"Shooting"}
                                            onChange={(e)=> changeValueHandler(e, "Shooting", "PredatorControlBirdsControlMethods")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.PredatorControlBirdsControlMethods?.Baiting}
                                            label={"Baiting"}
                                            onChange={(e)=> changeValueHandler(e, "Baiting", "PredatorControlBirdsControlMethods")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.PredatorControlBirdsControlMethods?.ScareTactics}
                                            label={"Scare tactics"}
                                            onChange={(e)=> changeValueHandler(e, "ScareTactics", "PredatorControlBirdsControlMethods")}
                                        />
                                    </div>
                                </div>
                            }
                            {(!formDataFields?.PredatorControlBirds?.Cowbirds &&
                              !formDataFields?.PredatorControlBirds?.GrackleStarlingHouseSparrow) &&
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={`${classes.fieldsGroupW25} ${classes.fieldsGroupLeft}`}>
                                        <Label className={classes.Label}>
                                            Scare tactics
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"PredatorControlBirdsControlMethodScareTactics"}
                                            placeholder="Scare tactics"
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.PredatorControlBirdsControlMethodScareTactics}
                                            onChange={(e)=> changeValueHandler(e, "PredatorControlBirdsControlMethodScareTactics")}
                                        />
                                    </div>
                                </div>
                            }
                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                <div className={classes.inputsGroup}>
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.PredatorControlWildBeasts?.Coyotes}
                                        label={"Coyotes"}
                                        onChange={(e)=> changeValueHandler(e, "Coyotes", "PredatorControlWildBeasts")}
                                    />
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.PredatorControlWildBeasts?.FeralHogs}
                                        label={"Feral hogs"}
                                        onChange={(e)=> changeValueHandler(e, "FeralHogs", "PredatorControlWildBeasts")}
                                    />
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.PredatorControlWildBeasts?.Raccoon}
                                        label={"Raccoon"}
                                        onChange={(e)=> changeValueHandler(e, "Raccoon", "PredatorControlWildBeasts")}
                                    />
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.PredatorControlWildBeasts?.Skunk}
                                        label={"Skunk"}
                                        onChange={(e)=> changeValueHandler(e, "Skunk", "PredatorControlWildBeasts")}
                                    />
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.PredatorControlWildBeasts?.Bobcat}
                                        label={"Bobcat"}
                                        onChange={(e)=> changeValueHandler(e, "Bobcat", "PredatorControlWildBeasts")}
                                    />
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.PredatorControlWildBeasts?.MountainLion}
                                        label={"Mountain lion"}
                                        onChange={(e)=> changeValueHandler(e, "MountainLion", "PredatorControlWildBeasts")}
                                    />
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.PredatorControlWildBeasts?.RatSnakes}
                                        label={"Rat snakes"}
                                        onChange={(e)=> changeValueHandler(e, "RatSnakes", "PredatorControlWildBeasts")}
                                    />
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.PredatorControlWildBeasts?.FeralCatsDogs}
                                        label={"Feral cats/dogs"}
                                        onChange={(e)=> changeValueHandler(e, "FeralCatsDogs", "PredatorControlWildBeasts")}
                                    />
                                </div>
                            </div>
                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                <div className={classes.fieldsGroupW100}>
                                    <Label className={classes.Label}>
                                        Method of control:
                                    </Label>
                                </div>
                                <div className={classes.inputsGroup}>
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.PredatorControlWildBeastsControlMethods?.Trapping}
                                        label={"Trapping"}
                                        onChange={(e)=> changeValueHandler(e, "Trapping", "PredatorControlWildBeastsControlMethods")}
                                    />
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.PredatorControlWildBeastsControlMethods?.Shooting}
                                        label={"Shooting"}
                                        onChange={(e)=> changeValueHandler(e, "Shooting", "PredatorControlWildBeastsControlMethods")}
                                    />
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.PredatorControlWildBeastsControlMethods?.M44}
                                        label={"M-44 (licensed applicators)"}
                                        onChange={(e)=> changeValueHandler(e, "M44", "PredatorControlWildBeastsControlMethods")}
                                    />
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.PredatorControlWildBeastsControlMethods?.PoisonCollars}
                                        label={"Poison collars (1080 certified, licensed, applicator)"}
                                        onChange={(e)=> changeValueHandler(e, "PoisonCollars", "PredatorControlWildBeastsControlMethods")}
                                    />
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.PredatorControlWildBeastsControlMethods?.Other}
                                        label={"Other"}
                                        onChange={(e)=> changeValueHandler(e, "Other", "PredatorControlWildBeastsControlMethods")}
                                    />
                                </div>
                                {formDataFields?.PredatorControlWildBeastsControlMethods?.Other && 
                                    <div className={classes.fieldsGroupW100}>
                                    <Label className={classes.Label}>
                                        Other (describe)
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"PredatorControlWildBeastsControlMethodOther"}
                                        placeholder="Other (describe)"
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.PredatorControlWildBeastsControlMethodOther}
                                        onChange={(e)=> changeValueHandler(e, "PredatorControlWildBeastsControlMethodOther")}
                                    />
                                    </div>
                                }
                            </div>
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"PredatorControlAdditionalInformation"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.PredatorControlAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "PredatorControlAdditionalInformation")}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    )
}