import { createContext, useContext, useReducer } from "react";
import type { ContextType } from "./permission-reducer";
import { AdminPermissionReducer, initialState } from "./permission-reducer";

const AdminPermissionStateContext = createContext<ContextType>({
  applicationState: initialState,
  adminPermissionDispatch: () => null,
});

export function useAdminPermissionContext(): ContextType {
  const context = useContext(AdminPermissionStateContext);

  if (context === undefined) {
    throw new Error(
      "useAdminPermissionContext must be used within a AdminPermissionProvider"
    );
  }

  return context;
}

export const AdminPermissionProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [applicationState, adminPermissionDispatch] = useReducer(
    AdminPermissionReducer,
    initialState
  );

  return (
    <AdminPermissionStateContext.Provider
      value={{ applicationState, adminPermissionDispatch }}
    >
      {children as React.ReactNode}
    </AdminPermissionStateContext.Provider>
  );
};
