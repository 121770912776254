import {PanelBarItem} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBarItem";
import {PanelBar} from "@progress/kendo-react-layout/dist/npm/panelbar/PanelBar";
import classes from "../WildlifeManagement.module.scss";
import { useEffect, useRef, useState } from "react";
import { IFormSettingModel } from "src/interfaces/form-settings.interface";
import { CheckboxChangeEvent, TextAreaChangeEvent, InputChangeEvent, RadioGroupChangeEvent, Checkbox, Input, NumericTextBox, NumericTextBoxChangeEvent } from "@progress/kendo-react-inputs";
import { FormValidation } from "../../InputValidator";
import { Calendar, CalendarChangeEvent, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { IWildlifeManagementFourthSectionSupplementalFood } from "src/interfaces/forms/WildlifeManagement/fourth-section/wildlife-management-fourth-section-providing-supplemental-food.interface";
import { CheckboxGroup } from "../../CustomInputs/CheckBoxes/CheckboxGroup";
import { Label } from "@progress/kendo-react-labels";
import { CustomDatePicker } from "../../CustomInputs/CustomDatePickers/CustomDatePicker";
import SectionTitle from "../../Shared/SectionTitle";

interface GeneralFormType {
    isAdminPreview?: boolean,
    formSettings?: IFormSettingModel,
    formDataFields?: IWildlifeManagementFourthSectionSupplementalFood;
    setFormDataFields: (form: IWildlifeManagementFourthSectionSupplementalFood) => void;
    setFormIsValid: (value: boolean) => void;
    setSectionIsValid: (value: boolean) => void;
}

export default function SupplementalFoodSubSection(props: GeneralFormType): JSX.Element {
    const { formSettings, isAdminPreview, formDataFields, setFormDataFields, setFormIsValid, setSectionIsValid } = props;
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const { validationMessage, inputValidator, setStyles } = FormValidation(formRef);
    const [foodPlotsFencedInputValid, setFoodPlotsFencedInputValid] = useState<boolean>(false);
    const [irrigatedInputValid, setIrrigatedInputValid] = useState<boolean>(false);
    const [yearRoundInputValid, setYearRoundInputValid] = useState<boolean>(false);
    const [stateWhenDateIsValid, setStateWhenDateIsValid] = 
        useState<boolean>(!formDataFields?.FeedersAndMineralSupplementationStateWhen);
    const title = <SectionTitle isFormTouched={isFormTouched} isValid={isValid} number={5} name={"Providing Supplemental Food"}/>;
    
    function changeValidator() {
        if (formRef.current) {
            const isValid = formRef.current.checkValidity();
            setIsValid(isValid);
            setSectionIsValid(isValid);
            setFormIsValid(isValid);
        }
    }

    function changeValueHandler(
        event:
            | CheckboxChangeEvent
            | DatePickerChangeEvent
            | TextAreaChangeEvent
            | DropDownListChangeEvent
            | InputChangeEvent
            | RadioGroupChangeEvent
            | CalendarChangeEvent
            | NumericTextBoxChangeEvent,
        field: string,
        group?: string
    ) {
        if (group) {
            setFormDataFields({
                ...formDataFields,
                [group]: {
                    ...(formDataFields as any)?.[group],
                    [field]: event.value
                }
            });
        } else {
            setFormDataFields({
                ...formDataFields,
                [field]: event.value
            });
        }
    }

    function onTouch(){
        if (!isFormTouched){
            setIsFormTouched(true);
        }
    }

    useEffect(() => {
        changeValidator()
    }, [ formDataFields, formRef.current ]);

    return (
        <PanelBar keepItemsMounted={true}>
            <PanelBarItem title={title} expanded={true}>
                <form name={"Name"} className={classes.sectionForm} ref={formRef} onFocus={onTouch}>
                    <div className="container">
                        <div className={classes.item}>
                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                <div className={classes.inputsGroup}>
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.SupplementalFoodTypes?.GrazingManagement}
                                        label={"Grazing management"}
                                        onChange={(e)=>
                                            changeValueHandler(e, "GrazingManagement", "SupplementalFoodTypes")}
                                    />
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.SupplementalFoodTypes?.PrescribedBurning}
                                        label={"Prescribed burning"}
                                        onChange={(e)=> changeValueHandler(e, "PrescribedBurning", "SupplementalFoodTypes")}
                                    />
                                    <Checkbox
                                        disabled={formSettings?.isReadOnly}
                                        className={classes.Checkbox}
                                        value={formDataFields?.SupplementalFoodTypes?.RangeEnhancement}
                                        label={"Range enhancement"}
                                        onChange={(e)=> changeValueHandler(e, "RangeEnhancement", "SupplementalFoodTypes")}
                                    />
                                </div>
                            </div>
                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup} ${classes.fieldsGroupW100}`}>
                                    <div className={classes.fieldsGroupW20}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.FoodPlots}
                                            label={"Food plots"}
                                            onChange={(e)=> changeValueHandler(e, "FoodPlots")}
                                        />
                                    </div>
                                    { formDataFields?.FoodPlots && <>
                                        <div className={classes.fieldsGroupW33}>
                                            <Label className={classes.Label}>
                                                Size
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                name={"FoodPlotsSize"}
                                                placeholder="Size"
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.FoodPlotsSize}
                                                onChange={(e)=> changeValueHandler(e, "FoodPlotsSize")}
                                            />
                                        </div>
                                        <div className={`${classes.fieldsGroupW33}`}>
                                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                                <Label className={`${classes.Label}`}>
                                                    Fenced
                                                </Label>
                                                <div className={classes.checkboxGroup}>
                                                    <CheckboxGroup
                                                        isAdminPreview={isAdminPreview}
                                                        setFormDataFields={setFormDataFields}
                                                        formDataFields={formDataFields}
                                                        formSettings={formSettings}
                                                        fieldName={"FoodPlotsFenced"}
                                                        fieldSecondName={"FoodPlotsFencedSecond"}
                                                        isRequired={false}
                                                        checkboxesIsValid={foodPlotsFencedInputValid}
                                                        setCheckboxesIsValid={setFoodPlotsFencedInputValid}
                                                    />
                                                </div>
                                            </div>
                                        </div></>
                                    }
                                </div>
                                { formDataFields?.FoodPlots && <>
                                    <Label className={classes.Label}>
                                        Plantings:
                                    </Label>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup} ${classes.fieldsGroupW100}`}>
                                        <div className={classes.fieldsGroupW50}>
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.FoodPlotsPlantingsCoolSeasonAnnualCrops}
                                                label={"Cool Season Annual Crops"}
                                                onChange={(e)=> changeValueHandler(e, "FoodPlotsPlantingsCoolSeasonAnnualCrops")}
                                            />
                                        </div>
                                        { formDataFields?.FoodPlotsPlantingsCoolSeasonAnnualCrops &&
                                            <div className={classes.fieldsGroupW50}> 
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"FoodPlotsPlantingsCoolSeasonAnnualCropsDescription"}
                                                    placeholder="Cool Season Annual Crops"
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.FoodPlotsPlantingsCoolSeasonAnnualCropsDescription}
                                                    onChange={(e)=> changeValueHandler(e, "FoodPlotsPlantingsCoolSeasonAnnualCropsDescription")}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup} ${classes.fieldsGroupW100}`}>
                                        <div className={classes.fieldsGroupW50}>
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.FoodPlotsPlantingsWarmSeasonAnnualCrops}
                                                label={"Warm Season Annual Crops"}
                                                onChange={(e)=> changeValueHandler(e, "FoodPlotsPlantingsWarmSeasonAnnualCrops")}
                                            />
                                        </div>
                                        { formDataFields?.FoodPlotsPlantingsWarmSeasonAnnualCrops &&
                                            <div className={classes.fieldsGroupW50}> 
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"FoodPlotsPlantingsWarmSeasonAnnualCropsDescription"}
                                                    placeholder="Warm Season Annual Crops"
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.FoodPlotsPlantingsWarmSeasonAnnualCropsDescription}
                                                    onChange={(e)=> changeValueHandler(e, "FoodPlotsPlantingsWarmSeasonAnnualCropsDescription")}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup} ${classes.fieldsGroupW100}`}>
                                        <div className={classes.fieldsGroupW50}>
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.FoodPlotsPlantingsAnnualMixOfNativePlants}
                                                label={"Annual Mix of Native Plants"}
                                                onChange={(e)=> changeValueHandler(e, "FoodPlotsPlantingsAnnualMixOfNativePlants")}
                                            />
                                        </div>
                                        { formDataFields?.FoodPlotsPlantingsAnnualMixOfNativePlants &&
                                            <div className={classes.fieldsGroupW50}> 
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"FoodPlotsPlantingsAnnualMixOfNativePlantsDescription"}
                                                    placeholder="Annual Mix of Native Plants"
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.FoodPlotsPlantingsAnnualMixOfNativePlantsDescription}
                                                    onChange={(e)=> changeValueHandler(e, "FoodPlotsPlantingsAnnualMixOfNativePlantsDescription")}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className={`${classes.itemFields} ${classes.fieldsGroup} ${classes.fieldsGroupW100}`}>
                                        <div className={classes.fieldsGroupW50}>
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.FoodPlotsPlantingsPerennialMixOfNativePlants}
                                                label={"Perennial Mix of Native Plants"}
                                                onChange={(e)=> changeValueHandler(e, "FoodPlotsPlantingsPerennialMixOfNativePlants")}
                                            />
                                        </div>
                                        { formDataFields?.FoodPlotsPlantingsPerennialMixOfNativePlants &&
                                            <div className={classes.fieldsGroupW50}> 
                                                <Input
                                                    className={classes.kTextbox}
                                                    name={"FoodPlotsPlantingsPerennialMixOfNativePlantsDescription"}
                                                    placeholder="Perennial Mix of Native Plants"
                                                    disabled={formSettings?.isReadOnly}
                                                    value={formDataFields?.FoodPlotsPlantingsPerennialMixOfNativePlantsDescription}
                                                    onChange={(e)=> changeValueHandler(e, "FoodPlotsPlantingsPerennialMixOfNativePlantsDescription")}
                                                />
                                            </div>
                                        }
                                    </div></>
                                }
                            </div>
                            <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                <Label className={`${classes.Label}`}>
                                    Irrigated
                                </Label>
                                <div className={classes.fieldsGroup}>
                                    <div className={classes.checkboxGroup}>
                                        <CheckboxGroup
                                            isAdminPreview={isAdminPreview}
                                            setFormDataFields={setFormDataFields}
                                            formDataFields={formDataFields}
                                            formSettings={formSettings}
                                            fieldName={"Irrigated"}
                                            fieldSecondName={"IrrigatedSecond"}
                                            isRequired={false}
                                            checkboxesIsValid={irrigatedInputValid}
                                            setCheckboxesIsValid={setIrrigatedInputValid}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"FoodPlotsAdditionalInformation"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.FoodPlotsAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "FoodPlotsAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.FeedersAndMineralSupplementation}
                                    label={"Feeders and mineral supplementation"}
                                    onChange={(e)=> changeValueHandler(e, "FeedersAndMineralSupplementation")}
                                />
                            </div>
                            { formDataFields?.FeedersAndMineralSupplementation && <>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Purpose:
                                    </Label>
                                    <div className={classes.inputsGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.FeedersAndMineralSupplementationPurpose?.Supplementation}
                                            label={"Supplementation"}
                                            onChange={(e)=> changeValueHandler(e, "Supplementation", "FeedersAndMineralSupplementationPurpose")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.FeedersAndMineralSupplementationPurpose?.HarvestingOfWildlife}
                                            label={"Harvesting of Wildlife"}
                                            onChange={(e)=> changeValueHandler(e, "HarvestingOfWildlife", "FeedersAndMineralSupplementationPurpose")}
                                        />
                                    </div>
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={classes.Label}>
                                        Targeted wildlife species
                                    </Label>
                                    <Input
                                        className={classes.kTextbox}
                                        name={"FeedersAndMineralSupplementationTargetedWildlifeSpecies"}
                                        placeholder="Targeted wildlife species"
                                        required={false}
                                        disabled={formSettings?.isReadOnly}
                                        value={formDataFields?.FeedersAndMineralSupplementationTargetedWildlifeSpecies}
                                        onChange={(e)=> changeValueHandler(e, "FeedersAndMineralSupplementationTargetedWildlifeSpecies")}
                                    />
                                </div>
                                <div className={`${classes.flexRow} ${classes.paddingLeft}`}>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Feed Type
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"FeedersAndMineralSupplementationFeedType"}
                                            placeholder="Feed Type"
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.FeedersAndMineralSupplementationFeedType}
                                            onChange={(e)=> changeValueHandler(e, "FeedersAndMineralSupplementationFeedType")}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Mineral Type
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"FeedersAndMineralSupplementationMineralType"}
                                            placeholder="Mineral Type"
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.FeedersAndMineralSupplementationMineralType}
                                            onChange={(e)=> changeValueHandler(e, "FeedersAndMineralSupplementationMineralType")}
                                        />
                                    </div>
                                </div>
                                <div className={`${classes.flexRow} ${classes.paddingLeft}`}>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Feeder type
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"FeedersAndMineralSupplementationFeederType"}
                                            placeholder="Feeder type"
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.FeedersAndMineralSupplementationFeederType}
                                            onChange={(e)=> changeValueHandler(e, "FeedersAndMineralSupplementationFeederType")}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Number of feeders
                                        </Label>
                                        <NumericTextBox
                                            name={"FeedersAndMineralSupplementationNumberOfFeeders"}
                                            required={false}
                                            placeholder={"Number of feeders"}
                                            disabled={formSettings?.isReadOnly}
                                            className={!inputValidator("FeedersAndMineralSupplementationNumberOfFeeders") ? 
                                                classes.invalidState + " " + classes.kTextbox : classes.kTextbox}
                                            value={formDataFields?.FeedersAndMineralSupplementationNumberOfFeeders}
                                            onChange={(e) => changeValueHandler(e, "FeedersAndMineralSupplementationNumberOfFeeders")}
                                        />
                                    </div>
                                </div>
                                <div className={`${classes.flexRow} ${classes.paddingLeft}`}>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Method of mineral dispensing
                                        </Label>
                                        <Input
                                            className={classes.kTextbox}
                                            name={"FeedersAndMineralSupplementationMethodOfMineralDispencing"}
                                            placeholder="Method of mineral dispensing"
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            value={formDataFields?.FeedersAndMineralSupplementationMethodOfMineralDispencing}
                                            onChange={(e)=> changeValueHandler(e, "FeedersAndMineralSupplementationMethodOfMineralDispencing")}
                                        />
                                    </div>
                                    <div className={classes.fieldsGroupW50}>
                                        <Label className={classes.Label}>
                                            Number of mineral locations
                                        </Label>
                                        <NumericTextBox
                                            name={"FeedersAndMineralSupplementationNumberOfMineralLocations"}
                                            required={false}
                                            disabled={formSettings?.isReadOnly}
                                            placeholder={"Number of mineral locations"}
                                            className={!inputValidator("FeedersAndMineralSupplementationNumberOfMineralLocations") ? 
                                                classes.invalidState + " " + classes.kTextbox : classes.kTextbox}
                                            value={formDataFields?.FeedersAndMineralSupplementationNumberOfMineralLocations}
                                            onChange={(e) => changeValueHandler(e, "FeedersAndMineralSupplementationNumberOfMineralLocations")}
                                        />
                                    </div>
                                </div>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <Label className={`${classes.Label}`}>
                                        Year Round:
                                    </Label>
                                    <div className={classes.fieldsGroup}>
                                        <div className={classes.checkboxGroup}>
                                            <CheckboxGroup
                                                isAdminPreview={isAdminPreview}
                                                setFormDataFields={setFormDataFields}
                                                formDataFields={formDataFields}
                                                formSettings={formSettings}
                                                fieldName={"FeedersAndMineralSupplementationYearRound"}
                                                fieldSecondName={"FeedersAndMineralSupplementationYearRoundSecond"}
                                                isRequired={false}
                                                checkboxesIsValid={yearRoundInputValid}
                                                setCheckboxesIsValid={setYearRoundInputValid}
                                            />
                                        </div>
                                    </div>
                                    { formDataFields.FeedersAndMineralSupplementationYearRound === false && 
                                        <div className={classes.fieldsGroupW100}>
                                            <Label className={classes.Label}>
                                                State When
                                            </Label>
                                            <CustomDatePicker
                                                fieldName={"FeedersAndMineralSupplementationStateWhen"}
                                                required={false}
                                                disabled={formSettings?.isReadOnly}
                                                formDataFields={formDataFields}
                                                setFormDataFields={setFormDataFields}
                                                onlyYear={false}
                                                setIsValidField={setStateWhenDateIsValid}
                                                validField={stateWhenDateIsValid}
                                                defaultValueDate={formDataFields?.FeedersAndMineralSupplementationStateWhen}
                                            />
                                        </div>
                                    }
                                </div>
                                </>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"FeedersAndMineralSupplementationAdditionalInformation"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.FeedersAndMineralSupplementationAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "FeedersAndMineralSupplementationAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.ManagingTamePasture}
                                    label={"Managing tame pasture, old fields and croplands"}
                                    onChange={(e)=> changeValueHandler(e, "ManagingTamePasture")}
                                />
                            </div>
                            { formDataFields?.ManagingTamePasture && 
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW100}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.ManagingTamePastureTypes?.OverseedingCoolWarmSeasonLegumesGrains}
                                            label={"Overseeding cool and/or warm season legumes and/or small grains"}
                                            onChange={(e)=>
                                                changeValueHandler(e, "OverseedingCoolWarmSeasonLegumesGrains", "ManagingTamePastureTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.ManagingTamePastureTypes?.PeriodicDisturbance}
                                            label={"Periodic disturbance (Discing/Mowing/Shredding)"}
                                            onChange={(e)=> changeValueHandler(e, "PeriodicDisturbance", "ManagingTamePastureTypes")}
                                        />
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.ManagingTamePastureTypes?.ConservationNoTill}
                                            label={"Conservation/no-till"}
                                            onChange={(e)=> changeValueHandler(e, "ConservationNoTill", "ManagingTamePastureTypes")}
                                        />
                                    </div>
                                </div>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"ManagingTamePastureAdditionalInformation"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.ManagingTamePastureAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "ManagingTamePastureAdditionalInformation")}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className={classes.item}>
                            <div className={classes.checkboxGroup}>
                                <Checkbox
                                    disabled={formSettings?.isReadOnly}
                                    className={classes.Checkbox}
                                    value={formDataFields?.TransitionManagementOfTameGrass}
                                    label={"Transition management of tame grass monocultures"}
                                    onChange={(e)=> changeValueHandler(e, "TransitionManagementOfTameGrass")}
                                />
                            </div>
                            { formDataFields?.TransitionManagementOfTameGrass && <>
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.checkboxGroup}>
                                        <Checkbox
                                            disabled={formSettings?.isReadOnly}
                                            className={classes.Checkbox}
                                            value={formDataFields?.TransitionManagementOfTameGrassOverseed25Percent}
                                            label={"Overseed 25% of tame grass pastures with locally adapted legumes"}
                                            onChange={(e)=> changeValueHandler(e, "TransitionManagementOfTameGrassOverseed25Percent")}
                                        />
                                    </div>
                                </div>                            
                                <div className={`${classes.itemFields} ${classes.fieldsGroup}`}>
                                    <div className={classes.fieldsGroupW100}>
                                        <Label className={classes.Label}>
                                            Species Planted:
                                        </Label>
                                        <div className={classes.inputsGroup}>
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.TransitionManagementOfTameGrassSpeciesPlanted?.Clover}
                                                label={"Clover"}
                                                onChange={(e)=> changeValueHandler(e, "Clover", "TransitionManagementOfTameGrassSpeciesPlanted")}
                                            />
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.TransitionManagementOfTameGrassSpeciesPlanted?.Peas}
                                                label={"Peas"}
                                                onChange={(e)=> changeValueHandler(e, "Peas", "TransitionManagementOfTameGrassSpeciesPlanted")}
                                            />
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.TransitionManagementOfTameGrassSpeciesPlanted?.Vetch}
                                                label={"Vetch"}
                                                onChange={(e)=> changeValueHandler(e, "Vetch", "TransitionManagementOfTameGrassSpeciesPlanted")}
                                            />
                                            <Checkbox
                                                disabled={formSettings?.isReadOnly}
                                                className={classes.Checkbox}
                                                value={formDataFields?.TransitionManagementOfTameGrassSpeciesPlanted?.Other}
                                                label={"Other"}
                                                onChange={(e)=> changeValueHandler(e, "Other", "TransitionManagementOfTameGrassSpeciesPlanted")}
                                            />
                                        </div>
                                    </div>
                                    {formDataFields?.TransitionManagementOfTameGrassSpeciesPlanted?.Other && 
                                        <div className={classes.fieldsGroupW100}>
                                            <Label className={classes.Label}>
                                                Other Species Planted (describe)
                                            </Label>
                                            <Input
                                                className={classes.kTextbox}
                                                name={"TransitionManagementOfTameGrassSpeciesPlantedOther"}
                                                placeholder="Other Species Planted (describe)"
                                                disabled={formSettings?.isReadOnly}
                                                value={formDataFields?.TransitionManagementOfTameGrassSpeciesPlantedOther}
                                                onChange={(e)=> changeValueHandler(e, "TransitionManagementOfTameGrassSpeciesPlantedOther")}
                                            />
                                        </div>
                                    }
                                </div></>
                            }
                            <div className={classes.paddingTop}>
                                <Label className={classes.Label}>
                                    Additional Information
                                </Label>
                                <Input
                                    className={classes.kTextbox}
                                    name={"TransitionManagementOfTameGrassAdditionalInformation"}
                                    required={false}
                                    disabled={formSettings?.isReadOnly}
                                    value={formDataFields?.TransitionManagementOfTameGrassAdditionalInformation}
                                    onChange={(e)=> changeValueHandler(e, "TransitionManagementOfTameGrassAdditionalInformation")}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </PanelBarItem>
        </PanelBar>
    )
}