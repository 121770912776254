import {
  action,
  actionError,
  actionPayload,
} from "src/context/generic-actions";

export enum PublicActionsEnum {
  ERROR = "ERROR",
  IS_LOADING = "IS_LOADING",
  DIALOG_TYPE = "DIALOG_TYPE",
  SHOW_CREATE_DIALOG = "SHOW_CREATE_DIALOG",
  SHOW_FORM_DIALOG = "SHOW_FORM_DIALOG",
  SHOW_USER_AGREEMENT = "SHOW_USER_AGREEMENT",
  SHOW_ELECTRONIC_USER_AGREEMENT = "SHOW_ELECTRONIC_USER_AGREEMENT",
  ELECTRONIC_USER_AGREEMENT_PDF = "ELECTRONIC_USER_AGREEMENT_PDF",
  HELP_REQUEST_DIALOG = "HELP_REQUEST_DIALOG",
}

export const Dispatches = {
  requestLogin: action,
  loginError: actionError,
  loginSuccess: actionPayload,
};
